import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, TextField, Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import '../ReportsPageHead/ReportsPageHead.css';
import { TimePicker } from 'antd';
import '../CustomDatePicker/CustomDatePicker.css';
import { ISO_DATE_FORMAT, TIME_FORMAT } from '../../utils/constants';
import {DateFormat, DateFormatCalenderInHistoricReports} from '../../utils/properties';
import moment from 'moment';
interface Props {
	handleDuration: any;
	handleClose: any;
	withTime?: boolean;
	allowDateTillDay?: boolean;
	allowFutureDates?: boolean;
	allowSingleDate?: boolean;
}

const CustomDatePicker = ({ handleDuration, handleClose, withTime, allowDateTillDay, allowFutureDates, allowSingleDate }: Props) => {
	const maxDate = allowDateTillDay ? dayjs() : dayjs().subtract(1, 'days');
	const [fromDateValue, setFromDateValue] = React.useState<Dayjs>(maxDate);
	const [toDateValue, setToDateValue] = React.useState<Dayjs>(maxDate);
	const [singleDateValue, setSingleDateValue] = React.useState<Dayjs>(maxDate);
	const [tabValue, setTabValue] = React.useState('1');
	const [maxRange, setMaxRange] = React.useState<Dayjs>(maxDate);
	const [startTimeValue, setStartTimeValue] = React.useState<Dayjs>(dayjs('00:00:00', TIME_FORMAT));
	const [endTimeValue, setEndTimeValue] = React.useState<Dayjs>(dayjs('23:59:59', TIME_FORMAT));

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		event.stopPropagation();
		setTabValue(newValue);
	};

	const getLabelContent = (fromDateTime: any, toDateTime: any, singleDate: any) => {
		return (
		<>
			{!allowSingleDate && (
				<Grid container alignItems="center" gap={1}>
					<Grid item>
						{/* {fromDateTime.date} */}
						{moment(fromDateTime.date).format(DateFormat)}
						<br />
						{fromDateTime.time}
					</Grid>
					<Grid item>-</Grid>
					<Grid item>
						{/* {toDateTime.date} */}
						{moment(toDateTime.date).format(DateFormat)}
						<br />
						{toDateTime.time}
					</Grid>
				</Grid>
			)}
			{allowSingleDate && (
				<Grid container alignItems="center" gap={1}>
					<Grid item>
						{moment(singleDate.date).format(DateFormat)}
					</Grid>
				</Grid>
			)}
			</>
		);
	};

	const handleSetDuration = () => {
		const fromDateTime = { date: fromDateValue.format(ISO_DATE_FORMAT), time: startTimeValue?.format(TIME_FORMAT) ?? '00:00:00' };
		const toDateTime = { date: toDateValue.format(ISO_DATE_FORMAT), time: endTimeValue?.format(TIME_FORMAT) ?? '00:00:00' };
		const singleDate = { date: singleDateValue.format(ISO_DATE_FORMAT)};
		const formattedFromTime = `${fromDateTime.date} ${fromDateTime.time}`;
		const formattedToTime = `${toDateTime.date} ${toDateTime.time}`;
		if(allowSingleDate){
			handleDuration(getLabelContent(fromDateTime, toDateTime, singleDate), {
				fromTime: singleDateValue.format(ISO_DATE_FORMAT),
				toTime: singleDateValue.format(ISO_DATE_FORMAT)
			});
		}else{
			handleDuration(getLabelContent(fromDateTime, toDateTime, singleDate), {
				fromTime: formattedFromTime,
				toTime: formattedToTime
			});
		}
		handleClose(true);
	};

	const onStartTimeChange = (time: any) => {
		setStartTimeValue(time);
	};

	const onEndTimeChange = (time: any) => {
		setEndTimeValue(time);
	};

	React.useEffect(() => {
		const twoMonthsAfterFromDate: any = fromDateValue.clone().add(2, 'months');
		const minMaxFunc = allowFutureDates ? Math.max : Math.min;
		const newMaxRange = dayjs(minMaxFunc(maxDate.unix() * 1000, twoMonthsAfterFromDate.unix() * 1000));
		setMaxRange(newMaxRange);
		setToDateValue(newMaxRange);
	}, [fromDateValue]);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} className="localization-date-picker">
			<Box className="custom-date-picker-container">
				
				{!allowSingleDate && (
					<TabContext value={tabValue}>
						<Box>
							<TabList onChange={handleChange} aria-label="lab API tabs example" className="date-tabs-container">
								{/* <Tab label={`From: ${fromDateValue.format('DD-MM-YYYY')}`} value="1" className="from-button" />
								<Tab label={`To: ${toDateValue.format('DD-MM-YYYY')}`} value="2" className="to-button" /> */}
								<Tab label={`From: ${fromDateValue.format(DateFormatCalenderInHistoricReports)}`} value="1" className="from-button" />
								<Tab label={`To: ${toDateValue.format(DateFormatCalenderInHistoricReports)}`} value="2" className="to-button" />
							</TabList>
						</Box>
						<Box className="dialog-content">
							<TabPanel value="1">
								<StaticDatePicker
									displayStaticWrapperAs="desktop"
									value={fromDateValue}
									maxDate={allowFutureDates ? null : maxDate}
									onChange={(newValue: any) => {
										setFromDateValue(newValue);
										setTabValue('2');
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
								{withTime && (
									<Grid container sx={{ padding: '0 8px', margin: '10px 0' }} alignItems="center">
										<Grid item>
											<TimePicker value={startTimeValue} onChange={onStartTimeChange} placeholder="Start Time" minuteStep={5} secondStep={5} />
										</Grid>
									</Grid>
								)}
							</TabPanel>
						</Box>
						<Box className="dialog-content">
							<TabPanel value="2">
								<StaticDatePicker
									displayStaticWrapperAs="desktop"
									value={toDateValue}
									minDate={fromDateValue}
									maxDate={maxRange}
									onChange={(newValue: any) => {
										setToDateValue(newValue);
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
								{withTime && (
									<Grid container sx={{ padding: '0 8px', margin: '10px 0' }} alignItems="center">
										<Grid item>
											<TimePicker value={endTimeValue} placeholder="End Time" onChange={onEndTimeChange} minuteStep={5} secondStep={5} />
										</Grid>
									</Grid>
								)}
							</TabPanel>
						</Box>
					</TabContext>
				)}
				{allowSingleDate && (
					<TabContext value={tabValue}>
						<Box>
							<TabList aria-label="Select a date" className="date-tabs-container">
								<Tab label={`Date: ${singleDateValue.format('DD-MM-YYYY')}`} value="1" className="from-button" />
							</TabList>
						</Box>
						<Box className="dialog-content">
							<TabPanel value="1">
								<StaticDatePicker
									displayStaticWrapperAs="desktop"
									value={singleDateValue}
									maxDate={allowFutureDates ? null : maxDate}
									onChange={(newValue: any) => {
										setSingleDateValue(newValue);
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</TabPanel>
						</Box>
					</TabContext>
				)}

				<Divider />
				<Box sx={{ padding: '20px' }}>
					<div className="buttons-container">
						<Button variant="outlined" onClick={() => handleClose()}>
							Cancel
						</Button>
						<Button variant="contained" onClick={handleSetDuration} sx={{ ml: 2 }}>
							set
						</Button>
					</div>
				</Box>
			</Box>
		</LocalizationProvider>
	);
};

export default CustomDatePicker;
