import { Grid } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getValueInStorage, setValueInStorage } from '../../services/storage-service';
import { LOCAL_STORAGE, WIDGET_COMPONENT_MAPPING } from '../../utils/constants';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import "./Dashboard.css"

const ResponsiveGridLayout = WidthProvider(Responsive);

type Props = {
	selectedDashboard: any;
};

function Dashboard({ selectedDashboard }: Props) {
	const navigate = useNavigate();
	const localSelectedDashboard = getValueInStorage(LOCAL_STORAGE.SELECTED_DASHBOARD);
	selectedDashboard = selectedDashboard?.id ? selectedDashboard : localSelectedDashboard || {};
	React.useEffect(() => {
		if (!selectedDashboard?.id) {
			navigate('/preset');
		}
		if (selectedDashboard?.id && !localSelectedDashboard?.id) {
			setValueInStorage(LOCAL_STORAGE.SELECTED_DASHBOARD, selectedDashboard);
		}
	}, []);
	const layouts = selectedDashboard?.dashboard?.layouts?.lg || [];
	return (
		<ResponsiveGridLayout
			className="dashboard-layout"
			layouts={layouts}
			rowHeight={250}
			cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
			breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
		>
			{layouts.map((layout: any, idx: number) => {
				const WidgetToRender = WIDGET_COMPONENT_MAPPING[layout.widgetName];
				if (!WidgetToRender) return null;
				return (
					<div key={idx} data-grid={{ x: layout.x, y: layout.y, w: layout.w, h: layout.h }}>
						<div className='widget-view' style={{ height: '100%', width: '100%', padding: '10px' }}>
							<WidgetToRender {...(layout?.widgetProps || {})} selectedFilters={layout?.widgetFilters || {}} widgetId={layout?.i} />
						</div>
					</div>
				);
			})}
		</ResponsiveGridLayout>
	);
}

export default Dashboard;