import React, { useState, useMemo, useEffect } from 'react';
import { Formik } from 'formik';
import { Button, Grid, IconButton, Stack, TextField, Tooltip, Typography, Divider } from '@mui/material';
import { getBlockNumberGroupDropdown } from '../../../services/api-service';
import OZDialog from "../../../components/admin/common/OZDialog";
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import OZInput from '../../../components/admin/common/OZInput';
import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';


const AddToGroupForm = (props) => {

    const [openEditBlockGroupform, setEditBlockGroupform] = useState(true);
    const [blockgroup, setBlockGroup] = useState([]);

    const closeEditBlockgroupform = () => {
        setEditBlockGroupform(false);
        props.onClose();
    }

    useEffect(() => {
        getBlocknumberGroupOptions();
    }, []);

    async function getBlocknumberGroupOptions() {
        try {
            getBlockNumberGroupDropdown().then(resp => {
                setBlockGroup(resp.Data);
            }).catch(e => {
                showErrorNotification('No BlockNumber Group Data, Check your Internet Connection');
                setBlockGroup([]);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    let buttonStyle = useMemo(() => ({
        borderRadius: '0.5rem',
        height: '2.75rem',
        fontSize: '0.875rem',
        width: '5.6875rem',
        fontWeight: 400
    }), [])

    let cancelButtonStyle = useMemo(
        () => ({
            height: '2.75rem',
            width: "5.6875rem",
            borderRadius: '0.5rem',
            fontSize: "0.875rem",
            fontWeight: 400,
            color: '#3D8BF8',
            border: '0.0625rem solid #008DFF',
            marginRight: '0.9375rem'
        }),
        []
    );

    const DataForm = (formProps) => {
        console.log("in data form", formProps)

        let initData = formProps.data || {

            blockGroups: [],
            description: "",
            name: "",
        };

        return (
            <div className="ozBlockGroupFormAddForm">
                <Formik
                    enableReinitialize
                    initialValues={initData}
                    onSubmit={(formValues) => {
                        console.log("here coming on add", formValues);
                        let flag = 0;
                        if (!formValues.blockGroups.length > 0 && !formValues.name && !formValues.description) {
                            flag = 1;
                            showErrorNotification("Please select any Group Details")
                            return;
                        }

                        if (formValues.name || formValues.description) {
                            if (formValues.blockGroups.length > 0) {
                                flag = 1;
                                showErrorNotification("Please select either New Group or Existing Group Details. Both cannot be selected")
                                return;
                            }
                        }

                        if (!formValues.blockGroups.length > 0) {
                            if (formValues.name && !formValues.description) {
                                flag = 1;
                                showErrorNotification("Please enter Group Description")
                                return;
                            } else if (!formValues.name && formValues.description) {
                                flag = 1;
                                showErrorNotification("Please enter Group name")
                                return;
                            }
                        }

                        if (flag === 0) {
                            // addBlockNumber(formValues); //this commented as having issue in converting date to desired format, for now testing
                            props.updateFunction(formValues);
                            //change above to update function
                        }
                    }} >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit}
                            id="blockGroupForm"
                            className={"ozcwScrollbar oz-add-phone-number-form-container "}
                            style={{
                                width: "29rem",
                                height: '26rem',
                                marginTop: "-12px"
                            }}>
                            <div>
                                <Grid container={true} spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div class='oz-grid-group'>
                                            <div style={{ fontSize: '14px', fontWeight: '1000', paddingBottom: '15px' }}>
                                                Create New Group
                                            </div>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <OZInput
                                                    name="name"
                                                    label="Group Name *"
                                                    placeholder="Enter Name"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '15px' }}>
                                                <OZInput
                                                    name="description"
                                                    label="Group Description *"
                                                    placeholder="Enter Description"
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item sx={{ color: "#99A0A8", fontSize: "1.125rem", fontWeight: 400, marginLeft: "45%" }}>
                                        OR
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div class='oz-grid-group'>
                                            <div style={{ fontSize: '14px', fontWeight: '1000', paddingBottom: '15px' }}>
                                                Add to Existing Group
                                            </div>
                                            <Stack>
                                                <CustomAutoComplete
                                                    mode='Add'
                                                    limitTags={2}
                                                    name="blockGroups"
                                                    labelHeader='Groups *'
                                                    placeholder={"Select Groups"}
                                                    Datas={blockgroup}
                                                    optionLabelName='name'
                                                    showCheckBox={true}
                                                    multiple={true}
                                                    showselectall="true"
                                                    popupIcon={null}
                                                    showAvatar={true}
                                                />
                                            </Stack>
                                        </div>
                                    </Grid>

                                    <Grid container={true} spacing={1} justifyContent={"flex-end"} paddingTop={"2rem"} paddingBottom={"1rem"}>
                                        <Grid item >
                                            <Button
                                                sx={cancelButtonStyle}
                                                onClick={closeEditBlockgroupform}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                sx={{ ...buttonStyle, borderRadius: '0.5rem', backgroundColor: '#3D8BF8', width: "Hug (137px)" }}
                                                type="submit"
                                            >
                                                Update
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    )}
                </Formik>
            </div >
        );
    };
    return (
        <div>
            <OZDialog
                hideActions
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={`Add to Group`}><div
                            className="oz-managenumbers-drawer-title-heading"
                            sx={{
                                fontWeight: 600,
                                fontSize: "1.125rem",
                                width: "11rem",
                                marginLeft: "11px",
                                marginTop: '1px'
                            }}
                            flexGrow={1}>
                            {`Add to Group`}
                        </div>
                        </Tooltip>
                        <IconButton
                            style={{ color: '#99A0A8' }}
                            size='small'
                            className='closeIcon'
                        // onClick={() => toggleDrawer()}
                        ></IconButton>
                    </div>
                }
                open={openEditBlockGroupform}
                onClose={closeEditBlockgroupform}
            >
                <DataForm data={props?.props} disabled={props?.props?.disabled} />
            </OZDialog>
        </div>
    );
};

export default AddToGroupForm;
