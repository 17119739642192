import * as React from 'react';
import { Alert, Snackbar } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import '../Login/Login.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../services/api-service';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import LoadingButton from '@mui/lab/LoadingButton';
import { LOGIN_PAGE_BACKGROUND_IMG_URL } from '../../utils/properties';
import * as _ from "lodash"

function ResetPasswordNew() {
    const [value] = React.useState('2');
    const [user, setUser] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [otp, setOtp] = React.useState('');
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [isPathnameOzonetel, setPathname] = React.useState(false);
    const [headerLogo, setImageLink] = React.useState('');

    const [snackbarMessageSuccess, setSnackbarMessageSuccess] = React.useState('');
    const [openSnackbarSuccess, setOpenSnackbarSuccess] = React.useState(false);

    const navigate = useNavigate();
    let location = useLocation();

    const crexdendoBackgroundStyle = {
		backgroundImage: `url(${process.env.PUBLIC_URL}/img/crexendo_bg.jpg)`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		width: '100%',
		height: '100vh'
	};
    
    function checkPathnameOzonetel() {
        let domain = window.location.href;
        if (domain.includes("engage")) {
            setPathname(false)
        }
        else if (domain.includes("crexendocx")) { setPathname(false) }
        else if (domain.includes("mavenir")) { setPathname(false) }
        else setPathname(true);
    }

    const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser(event.target.value);
    };
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };
    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    };

    const handleVerifyPassword = async (event: any) => {
        event.preventDefault();
        if (value === '2') {
            setLoading(true);

            if (!user?.length && !password.length && !confirmPassword.length) {
                setLoading(false);
                setSnackbarMessage('Please enter username and password and confirm Password!!');
                setOpenSnackbar(true);
                return;
            }
            else {
                if (!password.length) {
                    setLoading(false);
                    setSnackbarMessage('Password is required!!');
                    setOpenSnackbar(true);
                    return;
                }
                if (!user.length) {
                    setLoading(false);
                    setSnackbarMessage('Username is required!!');
                    setOpenSnackbar(true);
                    return;
                }
                if (!confirmPassword.length) {
                    setLoading(false);
                    setSnackbarMessage('Confirm Password is required!!');
                    setOpenSnackbar(true);
                    return;
                }
            }

            if (password !== confirmPassword) {
                setLoading(false);
                setSnackbarMessage('Confirm Password should match Password!!');
                setOpenSnackbar(true);
                return;
            }
            if (!password.match(/^((?!.*[\s])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-_!@#$%^&*()+{}[\]:;<>,.?~]).{7,50})$/)) {
                setLoading(false);
                setSnackbarMessage('The Password must not contain spaces. The Password must be 7 to 50 characters, including an uppercase letter, a lowercase letter, and alphanumeric characters.');
                setOpenSnackbar(true);
                return;
            }

            if (otp === null || otp === "") {
                setLoading(false);
                setSnackbarMessage('Token not found in the URL , Please contact to Admin!!');
                setOpenSnackbar(true);
                return;
            }

            try {
                await resetPassword({ user, password, otp }).then(async (resp) => {
                    if (resp) {
                        if (resp.status === 'error') {
                            setLoading(false);
                            setSnackbarMessage(resp?.message);
                            setOpenSnackbar(true);
                            return;
                        } else if (resp?.status === 'success') {
                            setSnackbarMessageSuccess(resp?.message);
                            setOpenSnackbarSuccess(true);
                            setLoading(false);
                            setTimeout(() => {
                                navigate("/login");
                            }, 1000);
                            return;
                        }
                    }
                }).catch((e) => {
                    setLoading(false);
                    setSnackbarMessage('Invalid Username/password!');
                    setOpenSnackbar(true);
                    return;
                })
            }
            catch (e) {
                setLoading(false);
                setSnackbarMessage('Invalid Username/password!');
                setOpenSnackbar(true);
                return;
            }
        }
    };

    const handleCloseSuccess = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbarSuccess(false);
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        setOtp(token);

        checkPathnameOzonetel()
    }, []);

    async function handleSetPageTitle() {
        let url = window.location.href;
        const titleElement = document.getElementById('title-ozonetel');
        if (url.includes('cxhub')) {
            if (titleElement) {
                titleElement.innerText = 'CxHub';
            }
        }
        if (url.includes('admin')) {
            if (titleElement) {
                titleElement.innerText = 'Admin';
            }
        }
    }

    React.useEffect(() => {
        AdminHeaderLogo()
    }, [])

    function AdminHeaderLogo() {
        let domainNew = window.location.href;
        if (!domainNew.includes("ozonetel")) {
            handleSetPageTitle()
            let endpoint = getEndPoint(window.location.href)
            getHeaderImages(endpoint)

        }
    }
    const getEndPoint = (url: any) => {
        const domain = new URL(url).hostname;
        const domainParts = domain.split(".");
        if (domainParts.length > 1) {
            return domainParts.slice(1).join(".")
        }
        else if (domainParts.length === 1) {
            return domainParts
        } else {
            return null;
        }


    }
    async function getHeaderImages(endpoint: any) {
        fetch(LOGIN_PAGE_BACKGROUND_IMG_URL + endpoint + ".js", {})
            .then(res => res.text())
            .then((result) => {
                if (result) {
                    let res = String(result)
                    const urlRegex = /(https:\/\/\S+)/g;
                    const urls = res.match(urlRegex);
                    if (urls) {
                        urls.map((item: any) => {
                            if (item.includes('logo_')) {
                                setImageLink(item.slice(0, -1))
                                setPathname(false);
                            }
                            if (item.includes('fav_')) {
                                const link = document.querySelector("link[rel='icon']");
                                if (link) {
                                    link.href = item.slice(0, -1);
                                }
                            }
                        })
                    }
                }
                // console.log("final login page logo")
                // console.log(headerLogo)
            })
            .catch(error => {
                // console.log("error in api ")
            });
    }

    return (
        <>
            {isPathnameOzonetel && <img className='green-circle-top-left' src={`${process.env.PUBLIC_URL}/icons/greencircle.svg`} alt="Ozonetel" ></img >}
				{!isPathnameOzonetel && !window.location.href.includes("crexendovip") && <img className='vector-backgroung-img-left' src={`${process.env.PUBLIC_URL}/icons/loginpagevectorsLeft.svg`} alt="Ozonetel" ></img >}
				{!isPathnameOzonetel && !window.location.href.includes("crexendovip") && <img className='vector-backgroung-img-right' src={`${process.env.PUBLIC_URL}/icons/loginpagevectorRight.svg`} alt="Ozonetel" ></img >}
				<div className={isPathnameOzonetel ? "login_container login-container-blue" : "login_container"} style={window.location.href.includes("crexendovip")? crexdendoBackgroundStyle:undefined}>
						<div className="login-left">
							{window.location.href.includes("crexendovip") ?
								<img className="admin-header-logo common-prop-header" src={`${process.env.PUBLIC_URL}/img/crexendo_logo.png`} alt="Crexendo" ></img >
								:
								<img className={window.location.href.includes("ccicx.ccicom") ? "admin-header-logo cci-logo" : "admin-header-logo common-prop-header"} src={!_.isEmpty(headerLogo) ? headerLogo : `${process.env.PUBLIC_URL}/icons/ozonetellogowhite.svg`} alt="Ozonetel" ></img >
							}
							{!window.location.href.includes("crexendovip") &&(
								<div>
									<div className={isPathnameOzonetel ? "login-title login-title-white" : "login-title"}>Modernize your Contact Center Software today!</div>
									<div className={isPathnameOzonetel ? "login-sub-title login-sub-title-white" : "login-sub-title"}>Enterprise-grade contact center suite built for fast-growing businesses</div>
								</div>
							)}
						</div>
                <div className="login-right">
                    <div className="login-form-title">Reset Password</div>
                    <TabContext value={value}>
                        <TabPanel value="2" className="loginTabContainer">
                            <form onSubmit={handleVerifyPassword}>
                                <div>
                                    <div className="ozonetel-form-control">
                                        <label className="form-label">User Name</label>
                                        <input disabled={loading} required type="text" value={user} onChange={handleUserChange} placeholder="Enter User Name" />
                                    </div>
                                    <div className="ozonetel-form-control">
                                        <label className="form-label">Password</label>
                                        <input disabled={loading} required type="password" value={password} onChange={handlePasswordChange} placeholder="Enter Password" />
                                    </div>
                                    <div style={{ fontSize: '12px', display: 'flex', color: '#99A0A8', fontWeight: '400', marginTop: '10px' }}>
                                        <label>Note: The Password must not contain spaces. The Password must be 7 to 50 characters, including an uppercase letter, a lowercase letter, and alphanumeric characters.</label>
                                    </div>
                                    <div className="ozonetel-form-control">
                                        <label className="form-label">Confirm Password</label>
                                        <input disabled={loading} required type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} placeholder="Enter Confirm Password" />
                                    </div>
                                    <div className="ozonetel-form-control-inline">
                                        <LoadingButton disabled={loading} className="loginBtnLg" type="submit" size="large" onClick={handleVerifyPassword} loading={loading} variant="contained">
                                            Verify
                                        </LoadingButton>
                                    </div>
                                </div>
                            </form>
                        </TabPanel>
                    </TabContext >
                </div >
                <Snackbar sx={{ zIndex: 10005 }} open={openSnackbarSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleCloseSuccess}>
                    <Alert
                        iconMapping={{
                            error: <DoDisturbOnIcon style={{ color: 'green' }} />
                        }}
                        onClose={handleCloseSuccess}
                        severity="error"
                    >
                        {snackbarMessageSuccess}
                    </Alert>
                </Snackbar>
                <Snackbar sx={{ zIndex: 10005 }} open={openSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleClose}>
                    <Alert
                        iconMapping={{
                            error: <DoDisturbOnIcon style={{ color: 'red' }} />
                        }}
                        onClose={handleClose}
                        severity="error"
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </div >
        </>
    );
}

export default ResetPasswordNew;
