import CloseIcon from '@mui/icons-material/Close';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import { Button, Grid, IconButton, Menu, MenuItem, Theme, Divider, Stack, Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { bargeIn, bargeInPhoneNumber, getContinuousBargeStatus } from '../../services/api-service';
import '../BargeInDrawer/BargeInDrawer.css';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import kSip from '../../utils/ksip'
import { stringAvatar } from '../admin/common/Avatar';
import { showErrorNotification, showSuccessNotification } from '../admin/common/NotiService';
import { checkRoles } from '../../utils/util';

const useStyles: any = makeStyles((theme: Theme) => ({
	thresholdContainer: {
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column'
	},
	thresholdHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	thresholdHeaderItems: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	headerText: {
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '21px',
		color: '#212121',
		marginLeft: '16px'
	},
	drawerGutter: {
		paddingTop: '74px'
	}
}));
const STATUS = {
	PLACEHOLDER: 'PLACEHOLDER',
	CONNECTING: 'CONNECTING',
	CONNECTED: 'CONNECTED',
	ENDED: 'ENDED'
};
const STATUS_LABEL = {
	[STATUS.PLACEHOLDER]: 'Not connected',
	[STATUS.CONNECTING]: 'Connecting',
	[STATUS.CONNECTED]: 'Connected',
	[STATUS.ENDED]: 'Ended'
};
const BARGEIN_IMAGES = {
	[STATUS.PLACEHOLDER]: 'bargein-placeholder.png',
	[STATUS.CONNECTING]: 'bargein-connecting.jpeg',
	[STATUS.CONNECTED]: 'bargein-connected.jpeg',
	[STATUS.ENDED]: 'bargein-ended.jpeg'
};

type Props = {
	toggleDrawer: any;
	isOpen: boolean | undefined;
	agentDetails?: any;
	callType?: any;
	url?: any;
	SipClient?: any;
};

const BargeInDrawerSip = ({ isOpen, toggleDrawer, agentDetails, callType, url, SipClient }: Props) => {
	const classes = useStyles();
	const { useState, useEffect, useMemo } = React;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [bargeInType, setBargeInType] = useState(callType);
	const [bargeInNumber, setBargeInNumber] = useState('');
	const [sipNumberData, setSipNumberData] = useState<Array<any>>([]);
	const [sipId, setSipId] = useState('');
	const [sipKey, setSipKey] = useState('');
	const [sipCheck, setSipCheck] = useState(false);
	const [bargeInStatus, setBargeInState] = useState(callType === 'SIP' ? STATUS.CONNECTING : STATUS.PLACEHOLDER);
	const open = Boolean(anchorEl);
	const downloadContent = React.useRef<null | HTMLElement>(null);
	const [callTypeTab, setCallTypeTab] = React.useState('4');
	const [apiMessage, setApiMessage] = React.useState('');
	const [callInit, setCallInit] = useState(false);
	const currentUCID = agentDetails?.UCID || '';
	let continuousBarge = agentDetails?.continuousBarge || false;
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [bargeDisabled, setBargeDisabled] = useState(false);

	console.log("what is isOpen:",isOpen);
	console.log("what is toggleDrawer:",toggleDrawer);
	console.log("what is agentDetails:",agentDetails);
	console.log("what is callType:",callType);
	console.log("what is url:",url);
	console.log("what is SipClient:",SipClient);
	

	const sipDisconnect = () => {
		if (kSip && kSip.status()) {
			kSip.hangup();
		}
		sessionStorage.removeItem("bargeInNumber");
		sessionStorage.removeItem("bargeInState");
		sessionStorage.removeItem("bargeInAgentId");
	};
	const sendSIPDTMF = (dtmf: string) => {
		if (kSip && kSip.status()) {
			kSip.sendDTMF(dtmf);
		}
	};


	const setBargeInStatus = (status: any) => {
		console.log("in set status")
		//setting bargein status 
		setBargeInState(status)
		sessionStorage.setItem("bargeInStatus", status);
		sessionStorage.setItem("bargeInAgent", agentDetails.AgentName);

		if(status === STATUS.ENDED){
			continuousBarge = false;
		}
	}

	React.useEffect(() => {
		console.log("agentDetails in useEffect",currentUCID)
		if(callType === 'SIP' || sipId != '' ){
			const handleClientRegistered = (call: any) => {
				console.log("kkssiipp=====>handleClientRegistered===>", call);

				bargeInFunction();
			};

			const handleClientRegistrationFailed = (call: any) => {
				console.log("kkssiipp=====>handleClientRegistrationFailed===>", call);
				setBargeInStatus(STATUS.ENDED);
			};

			const handleNewCall = (call: any) => {
				console.log("kkssiipp=====>handleNewCall===>", call);
				kSip.answer();
			};

			const handleConfirmed = () => { };

			const handleEnded = () => {
				console.log("kkssiipp=====>handleEnded===>");
				kSip.close();
				setBargeInStatus(STATUS.ENDED);
				console.log("kkssiipp=====>handleEnded===>", bargeInStatus);

				sessionStorage.removeItem("bargeInNumber");
				sessionStorage.removeItem("bargeInState");
				sessionStorage.removeItem("bargeInAgentId");
			};

			if (sipId === "" || (continuousBarge && callType === 'SIP')) {
				console.log("what is the sipId:",sipId);
				console.log("what is the continuousBarge:",continuousBarge);
				console.log("what is the callType:",callType);
				let tempurl = url;
				bargeinPhone(tempurl);
			}
			if(callType != 'SIP' && bargeInType === 'SIP' && agentDetails?.AgentState === 'BUSY'){
				setBargeInStatus(STATUS.CONNECTING);
				let sipUrlValue = separateDomainAndPort(url);
				kSip.init(sipUrlValue.domain, sipUrlValue.port, sipId, sipId, true);
				if(continuousBarge && callType === 'Normal') {
                    setBargeInStatus(STATUS.CONNECTED);
                    }

				//directly initiating the sip call
			}

			if ((callType === 'SIP' || bargeInType === 'SIP') && typeof kSip !== "undefined") {

				kSip.on("clientRegistered", handleClientRegistered);
				kSip.on("clientRegistrationFailed", handleClientRegistrationFailed);
				kSip.on("newCall", handleNewCall);
				kSip.on("confirmed", handleConfirmed);
				kSip.on("ended", handleEnded);

				//checking for active call if barge in popup closed withoout closing call
				let prevBargeInStatus = sessionStorage.getItem("bargeInStatus");
				let prevBargeInAgent = sessionStorage.getItem("bargeInAgent");

				if ((prevBargeInAgent === agentDetails.AgentName) && prevBargeInStatus === STATUS.CONNECTED) {
					setBargeInStatus(STATUS.CONNECTED);
				}

				// Cleanup: Remove event listeners when component unmounts or sipId changes
				return () => {
					kSip.off("clientRegistered", handleClientRegistered);
					kSip.off("clientRegistrationFailed", handleClientRegistrationFailed);
					kSip.off("newCall", handleNewCall);
					kSip.off("confirmed", handleConfirmed);
					kSip.off("ended", handleEnded);
				};
			}
		}
	}, [sipId, callType, url, agentDetails]);

	React.useEffect(()=>{
		if(
			bargeInStatus === "CONNECTING" || 
			bargeInStatus === "ENDED" || 
			(bargeInStatus === "CONNECTED" && bargeInType != 'SIP' && callType != 'SIP') || 
			(sessionStorage.getItem('bargeInAgentId') && agentDetails?.AgentUniqueId != sessionStorage.getItem('bargeInAgentId'))
		){
			setButtonDisabled(true);
		}
		else{
			setButtonDisabled(false);
		}

		if(sessionStorage.getItem('bargeInAgentId') && agentDetails?.AgentUniqueId != sessionStorage.getItem('bargeInAgentId')){
			setBargeDisabled(true);
		}else{
			setBargeDisabled(false);
		}
	},[bargeInStatus,agentDetails])

	React.useEffect(()=>{
		if(callType != 'SIP'){
			if(bargeInStatus === STATUS.ENDED && bargeInType != 'SIP' && continuousBarge){
				setBargeInStatus(STATUS.CONNECTING);
				bargeInFunction();
			}
			if(agentDetails?.AgentState === 'ACW'){
				setBargeInStatus(STATUS.ENDED);
			}
		}
	},[agentDetails]);

	// React.useEffect(() => {

	// 	console.log("IntervalID*******************");
	// 	const fetchAgentDetail = async () => {
	// 		try {
	// 			let agentDetail = await getAgentDetailById(agentDetails?.AgentUniqueId);
	// 			if (agentDetail) {
	// 				if (typeof agentDetail === 'string') {
	// 					agentDetail = JSON.parse(agentDetail);
	// 					setAgentState(agentDetail?.AgentState);
	// 				}
	// 			}
	// 		} catch {
	// 			setAgentState(agentDetails?.AgentState);
	// 		}
	// 	};
	
	// 	// Call fetchAgentDetail every 2 seconds
	// 	const intervalId = setInterval(fetchAgentDetail, 2000);
	// 	console.log("IntervalID****intervalId***************",intervalId);
	// 	// Cleanup interval on component unmount
	// 	return () => {
	// 		clearInterval(intervalId); // Clear the interval to stop further API calls
	// 	};
	// }, []);
	  

	// React.useEffect(() => {
	// 	console.log("agentDetails",agentDetails)
	// 	const agentState = agentDetails?.AgentState;
	// 	console.log("agentDetails?.AgentState",agentState)

	// 	if(bargeInStatus === 'ENDED' && agentState === 'BUSY')
	// 	{
	// 		console.log("agentDetails chnaged",agentDetails)
	// 		bargeInFunction();
	// 	}
	// }, [agentDetails]);


	useEffect(() => {
		//clearing bargein data from session storage if reload happens
		const handleBeforeUnload = () => {
			sessionStorage.removeItem("bargeInStatus");
			sessionStorage.removeItem("bargeInAgent");
			sessionStorage.removeItem("bargeInNumber");
			sessionStorage.removeItem("bargeInState");
			sessionStorage.removeItem("bargeInAgentId");
		};
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		}
	})
	const agentAvatar = useMemo(
		() => ({
			width: '100px',
			height: '100px',
			fontWeight: 500,
			color: '#000000',
			fontSize: "50px",
		}),
		[]
	);

	const customerAvatar = useMemo(
		() => ({
			width: '100px',
			height: '100px',
			fontWeight: 500,
			color: '#000000',
			fontSize: "20px",
		}),
		[]
	);

	async function bargeinPhone(url: any) {
		try {
			const rep: any = await bargeInPhoneNumber({ sipurl: url });
			// let data = await getAgentList({ filters, type: filterType });
			console.log("My response is bargeinPhone ====>", rep);
			// let reponse = {"isAvailable":"0","password":"19810","SNO":1,"sip_number":"19810","id":"1","pop_location":"blr-sbc1.ozonetel.com"};
			if (rep) {
				bargeInCall(rep);
			}
		}
		catch (e) {
			showErrorNotification(JSON.stringify(e));
		}
	}

	function separateDomainAndPort(url:any) {
		console.log("correct");
		// Regular expression to match URL with optional port
		const regex = /^([^:\/]+(?:\.[^:\/]+)*)(:(\d+))?/;
		const match = url.match(regex);

		if (match) {
			const domain = match[1];
			const port = match[3] || '442';

			return {
				domain: domain,
				port: port
			};
		} else {
			return {
				domain: null,
				port: null
			};
		}
	}

	const bargeInCall = (sipDetails: any) => {
		let sipBargeEnabled = false;
		if (SipClient) {
			sipBargeEnabled = true;
		}
		console.log("bargeinPhone url is =>", url);
		console.log("bargeinPhone sipBargeEnabled is =>", sipBargeEnabled);
		console.log("bargeinPhone sipId is =>", sipDetails?.sip_number);
		console.log("bargeinPhone SipClient is =>", SipClient);
		console.log("bargeinPhone sipCheck is =>", sipCheck);
		setBargeInNumber(sipDetails?.sip_number);
		setSipId(sipDetails.sip_number);
		let sipUrlValue = separateDomainAndPort(url);
		if (sipBargeEnabled && url !== "" && url !== "0") {
			if (typeof kSip != "undefined" && !kSip.status()) {
				console.log(" bargeinPhone I am hereeeeeeeeeeee");
				
				kSip.init(sipUrlValue.domain, sipUrlValue.port, sipDetails.sip_number, sipDetails.sip_number, true);
			}
		}
		// else {
		// 	bargeInFunction();

		// }
	}

	const handleClose = (type: any = null) => {
		if (type) {
			setBargeInType(type);
		}
		setAnchorEl(null);
	};
	const handleInputChange = (event: any) => {
		setBargeInNumber(event.target.value);
	};
	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		if(callType === 'SIP'){
			setAnchorEl(null);
		}else{
			setAnchorEl(event.currentTarget);
		}
	};
	// const handleBargeIn = async (event: React.SyntheticEvent, value: string) => {
	// 	if(value === "PLACEHOLDER" || value === "ENDED"){
	// 		if (!bargeInNumber?.length) return;
	// 		setBargeInStatus(STATUS.CONNECTING);
	// 		const reponse = await bargeIn({ UCID: agentDetails.UCID, isSip: bargeInType === 'SIP', agentNumber: agentDetails.AgentPhoneNumber, phoneNo: bargeInNumber });
	// 		if (reponse.status === 200) {
	// 			setBargeInStatus(STATUS.CONNECTED);
	// 		} else {
	// 			setBargeInStatus(STATUS.ENDED);
	// 		}
	// 	}else if(value === "CONNECTED"){
	// 		setBargeInStatus(STATUS.ENDED);
	// 	}
	// };
	// const 
	async function bargeInFunction() {
		console.log("bargeinPhone UCID is =>", agentDetails.UCID);
		console.log("bargeinPhone isSip is =>", bargeInType === 'SIP');
		console.log("bargeinPhone agentNumber is =>", agentDetails.AgentPhoneNumber);
		console.log("bargeinPhone phoneNo is =>", bargeInNumber);
		console.log("bargeinPhone phoneNo is =>", sipId);
		console.log("bargeinPhone phoneNo is =>", sipKey);
		const reponse = await bargeIn({ UCID: agentDetails.UCID, isSip: bargeInType === 'SIP', agentNumber: agentDetails.AgentPhoneNumber, phoneNo: callType === ' SIP' ? sipId : bargeInNumber });
		console.log("bargeinPhone reponse===>", reponse);
		if (reponse.Status.toLowerCase() === "success") {
			setBargeInStatus(STATUS.CONNECTED);
			setApiMessage(reponse?.Message);

		} else {
			setBargeInStatus(STATUS.ENDED);
			setApiMessage(reponse?.Message);
		}

	}
	const handleBargeIn = async (value: string) => {
		if(bargeInType === 'SIP'){
			setSipId(bargeInNumber);
		}else{
			setBargeInStatus(STATUS.CONNECTING);
			bargeInFunction();
		}
	};

	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>", newValue);
		setCallTypeTab(newValue);
		sendSIPDTMF(newValue);
	};

	useEffect(() => {
		// setBargeInStatus(STATUS.PLACEHOLDER);
		// setBargeInNumber('');
	}, [agentDetails]);

	React.useEffect(() => {		
		const agentIdPrev = sessionStorage.getItem('bargeInAgentId');
		if(agentIdPrev && agentDetails?.AgentUniqueId === agentIdPrev){
			setBargeInNumber(sessionStorage.getItem('bargeInNumber') || '')
			setBargeInType(sessionStorage.getItem('bargeInState') || callType)
			setSipId(sessionStorage.getItem('bargeInNumber') || '');
		}
	}, [])

	const ConnectionAvatarComp = (details: any) => {
		return (
			<>
				<Grid sx={{ marginTop: '1px', textAlign: 'center', backgroundColor: '#FBFCFC' }} container spacing={6} display='flex' direction={'row'} >
					<Grid item sx={{ marginLeft: '5.5rem' }} >
						<Avatar style={agentAvatar} {...stringAvatar(details?.details?.AgentName)} ></Avatar>
						<div>{details?.details?.AgentName ? details?.details?.AgentName : ""}</div>
					</Grid>
					{/* <Grid item>
						<Avatar style={customerAvatar}>Customer</Avatar>
						<div>+91 8790107294</div>
					</Grid> */}
				</Grid>
				<span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
					<Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginTop: 1 }} />
				</span>
				<Grid sx={{ marginTop: 1, backgroundColor: '#FFF' }} container spacing={2} display='flex' alignItems='center' direction={'row'} >
					{/* <Grid item sx={{marginLeft: '5.5rem'}}>
						<Avatar style={agentAvatar} {...stringAvatar(details?.details?.AgentName)} ></Avatar>
					</Grid> */}
					<Grid item sx={{ marginLeft: '5.5rem' }}>
						<Avatar style={customerAvatar}>Customer</Avatar>
						<div>{details?.details?.CustomerPhoneNumber ? details?.details?.CustomerPhoneNumber : ""}</div>
					</Grid>
				</Grid>
			</>
		)
	}

	return (
		<Drawer open={isOpen} anchor={'right'}>
			<Box sx={{ width: 355, minHeight: 150 }} className={classes.thresholdContainer}>
				<div className={classes.drawerGutter}>
					<div className={classes.thresholdHeader}>
						<div className={classes.thresholdHeaderItems}>
							<GraphicEqIcon color="primary" />
							<span className={classes.headerText}>Voice</span>
						</div>
						<IconButton aria-label="delete" size="small" 
							onClick={() => {
								if(bargeInStatus === "CONNECTED" && bargeInType === 'SIP'){
									sessionStorage.setItem("bargeInNumber",bargeInNumber);
									sessionStorage.setItem("bargeInState",bargeInType);
									sessionStorage.setItem("bargeInAgentId",agentDetails?.AgentUniqueId);
								}
								toggleDrawer(false); 
							}}
						>
							<CloseIcon style={{ fontSize: '22px', color: '#313131' }} />
						</IconButton>
					</div>
				</div>
				<div className="bargein-items-container">
					<div className="call-status-container">
						<b>Call Status:  </b>
						<span className={STATUS_LABEL[bargeInStatus] === STATUS_LABEL[STATUS.CONNECTED] ? 'call-status-connected' : STATUS_LABEL[bargeInStatus] === STATUS_LABEL[STATUS.ENDED] ? 'call-status-ended' : 'call-status'}></span>
						<span>{STATUS_LABEL[bargeInStatus]}</span>
					</div>
					<div className="agent">
						<b>Agent: </b>
						<span className="agent-profile"></span>
						<span>{agentDetails?.AgentName}</span>
					</div>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<b>Type</b>
						</Grid>
						<Grid item>
							<div className="drawer-search-container no-margin">
								<>
									<div className="drawer-search-dd" onClick={bargeDisabled ? undefined : handleMenuOpen}>
										<span>{bargeInType}</span>
									</div>
									<Menu
										id="download-appbar"
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left'
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left'
										}}
										open={open}
										onClose={() => handleClose()}
										className="custom-search-dropdown"
									>
										<MenuItem className="download-item" onClick={() => handleClose('Normal')}>
											Normal
										</MenuItem>
										<MenuItem className="download-item" onClick={() => handleClose('SIP')}>
											SIP
										</MenuItem>
									</Menu>
								</>
								<>
									<div className="drawer-search-bar">
										<input disabled={callType === 'SIP' || bargeDisabled} type={callType === 'SIP' ? "password" : "text"} value={bargeInNumber} onChange={handleInputChange} placeholder="Number" />
									</div>
								</>
							</div>
						</Grid>
					</Grid>
					<span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
						<Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginTop: 1 }} />
					</span>
					{bargeDisabled ?
						<div style={{ fontSize: '12px', fontWeight: '400', color: "#e65b5c"}}>Already barged in for Agent: {sessionStorage.getItem('bargeInAgent')}</div>
					:
						<div style={{ fontSize: '12px', fontWeight: '400', color: bargeInStatus === "CONNECTED" ? "#4f9f52" : bargeInStatus === "ENDED" ? "#e65b5c" : "none" }}>{bargeInStatus === 'ENDED' ? 'Call Ended' : apiMessage}</div>
					}
					<>

					</>
					{bargeInStatus === "CONNECTED" && bargeInType === "SIP" &&
						<>
							<Box ref={downloadContent} sx={{ width: '100%', typography: 'body1' }}>
								<TabContext value={callTypeTab}>
									<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
										<TabList onChange={handleChangeTab} className="customTabs">
											<Tab icon={<img src={`${process.env.PUBLIC_URL}/icons/call_icon_bargein.svg`} alt="info_icon_blue" />} label="Listen" value="4" />
											<Tab icon={<img src={`${process.env.PUBLIC_URL}/icons/training_icon_bargein.svg`} alt="info_icon_blue" />} label="Training" value="5" />
											<Tab icon={<img src={`${process.env.PUBLIC_URL}/icons/table-bargein-icon.svg`} alt="info_icon_blue" />} label="Bargein" value="6" />
										</TabList>
									</Box>
								</TabContext>
							</Box>
							<Stack direction={'row'} sx={{ backgroundColor: '#E5F4FF' }}>
								<img style={{ marginLeft: '5px' }} src={`${process.env.PUBLIC_URL}/icons/info_icon_blue.svg`} alt="info_icon_blue" />
								<div style={{ height: '40px', flexShrink: '0', paddingTop: '8px', fontSize: '14px', marginLeft: '7px' }}>
									{callTypeTab === "4" ? "You can only listen to the call" : callTypeTab === "5" ? "Only agent can hear you." : "Both agent and customer can hear you."}
								</div>
							</Stack>
						</>
					}
					{/* {bargeInType === "Normal" && bargeInStatus !== "CONNECTED" ?
						<div className="bargein-status-container">
							<img src={`${process.env.PUBLIC_URL}/img/${BARGEIN_IMAGES[bargeInStatus]}`} alt="Bargein Status" />
						</div>
						:
						<div>
							hello
						</div>
					} */}
					{bargeInType === "Normal" &&
						<div className="bargein-status-container">
							<img src={`${process.env.PUBLIC_URL}/img/${BARGEIN_IMAGES[bargeInStatus]}`} alt="Bargein Status" />
						</div>
					}
					{bargeInType === "SIP" &&
						<>
							{bargeInStatus !== "CONNECTED" ?
								<div className="bargein-status-container">
									<img src={`${process.env.PUBLIC_URL}/img/${BARGEIN_IMAGES[bargeInStatus]}`} alt="Bargein Status" />
								</div>
								:
								<Box>
									<ConnectionAvatarComp details={agentDetails} />
								</Box>
							}
						</>
					}
				</div>
				<div className={bargeInStatus === "CONNECTED" ? "bargein-items-error" : bargeInStatus === "PLACEHOLDER" ? "bargein-items-footer" : "bargein-items-footer"}>
					<Button variant="contained" 
						disabled={buttonDisabled}
						color={bargeInStatus === "CONNECTED" ? "error" : "primary"} 
						onClick={bargeInStatus === "CONNECTED" ? () => { sipDisconnect(); toggleDrawer(false) } : () => handleBargeIn(bargeInStatus)}
					>
						{bargeInStatus === "CONNECTED" ? "Hang up" : "Call"}
					</Button>
				</div>
			</Box>
		</Drawer>
	);
};
export default BargeInDrawerSip;
