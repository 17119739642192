import { Button, Grid, Typography, Box, TextField, IconButton, Tooltip, Divider } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import { Formik } from "formik";
import { useMemo, useState, useEffect } from "react";
import * as yup from "yup";
import OZDialog from "../../../components/admin/common/OZDialog";
import OZDrawer from "../../../components/admin/common/OZDrawer/OZDrawer";
import OZInput from "../../../components/admin/common/OZInput";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import CustomizedCheckbox from "../../../components/admin/common/CustomCheckBox";
import { DropzoneAreaBase } from "mui-file-dropzone";
import "../../../components/DropZone/DropZone.css";
import { addBlockGroups, addBlockGroupsAsJson, addBlockGroupsById, addBlockNumbers, getBlockNumberGroupDropdown,  addBlockGroupNew, createBlockNumber, uploadBlockNumber, checkBlockNumberExist } from "../../../services/api-service";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import { showErrorNotification, showInfoNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./manageNumber.css";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack } from '@mui/system';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { connect } from "formik";
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import EditExpiryDateBlockGroupForm from "./EditExpiryDateBlockGroupForm";
import OZCheckbox from "../../../components/OZCheckbox/OZCheckbox";
import OZAutocompletes from "../../../components/admin/common/AutoComplete/OZAutocompleteGroups";
import CustomAutoComplete from "../../../components/admin/common/AutoComplete/CustomAutoComplete";
import OZFCheckbox from "../../../components/admin/common/OZFCheckbox";
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";

let uploadedNumbers = [];

const MaterialUIPickers = connect(({ name, onChangefunction, formik, values }) => {
  let newToDate = null;

  if (values?.expiryDate) {
    newToDate = moment(values?.expiryDate, "DD-MM-YYYY HH:mm:ss");
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, setValue] = useState(newToDate);

  const onSelect = (newValue) => {
    setValue(newValue);
    let newFormatedValue = newValue?.format("DD-MM-YYYY HH:mm:ss");
    values.expiryDate = newFormatedValue;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DateTimePicker
          disablePast
          // disableFuture
          value={value}
          views={["year", "month", "day", "hours", "minutes", "seconds"]}
          onChange={onSelect}
          inputFormat="DD-MM-YYYY HH:mm:ss"
          renderInput={(params) => <TextField sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              backgroundColor: '#ffff',
              fontSize: '14px',
              height: '44px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #D6D6D7'
            },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
              // backgroundColor: '#D6D6D7', //TODO
              border: '1px solid #D6D6D7'
            }
          }} {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
})

function CheckboxList(checklistProps) {
  
  console.log("checklistProps",checklistProps)
  const [checked, setChecked] = useState([0]);
  const [newBlockNumbers, setNewBlockNumbers] = useState(checklistProps?.blockgroups);
  const [editBlockForm, setEditBlockForm] = useState(false);
  const [editdata, setEditData] = useState('');

  let blockedNumbers = useMemo(
    () => (checklistProps?.blockgroups
    ),
    [checklistProps?.blockgroups]
  );

  let basicStyle = useMemo(
    () => ({
      fontSize: '0.75rem', fontWeight: 400, color: "#99A0A8"
    }),
    []
  );
  const [searchedNumber, setSearchedNumber] = useState(blockedNumbers)
  useEffect(() => {
    setSearchedNumber(blockedNumbers)
  }, [blockedNumbers]);

  if (checklistProps?.blockgroups) {
    let blockedNumbersLength = searchedNumber?.length;

    const handleOnClickListItem = (selectedValue, id) => () => {
      const currentIndex = checked.indexOf(selectedValue);
      const newChecked = [...checked];
      if (currentIndex === -1) {
        const deletedElement = newBlockNumbers.filter(numbers => {
          return numbers.blockgroups !== selectedValue;
        });
        setNewBlockNumbers(deletedElement)
        newChecked.push(selectedValue);
      } else {
        setNewBlockNumbers([...newBlockNumbers, { "blockedNumber": selectedValue, "id": id }])
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    };

    const handleEditicon = (number, id, fulldetails) => {
      console.log("in handleEditicon")
      setEditBlockForm(true);
      let newData = { name: fulldetails?.name, id: fulldetails?.id, description: fulldetails?.description ? fulldetails?.description : null, expirypropsGroupdate: checklistProps?.groupData };
      setEditData(newData);
    };

    const closeEditBlockGroupForm = (e,) => {
      setEditBlockForm(false);
      setEditData('');
    }

    const onUpdateFunction = (id) => {
      checklistProps.neExpUpdate(id);
    }

    const searchBlockNumber = (searchedValue) => {
      const filteredItems = blockedNumbers.filter((blockedNumberObject) => {
        return blockedNumberObject.name.includes(searchedValue);
      });
      setSearchedNumber(filteredItems)
    };

    function getExpiryTimeDisplay(blockedNumberObject) {
      if (blockedNumberObject?.expiryDate !== null) {
        let value = moment(blockedNumberObject?.expiryDate ? blockedNumberObject?.expiryDate : null);
        if (value !== null) {
          return `Expiry Date: ${value.format("DD-MM-YYYY HH:mm:ss")}`;
        }
      } else {
        return `Expiry Date: Null`;
      }
    }


    return (
      <div>
        {checklistProps?.blockGroupFormMode === 'edit' &&
          <div>
            <div>
              <Typography sx={basicStyle}>Expiry Date *</Typography>
              <MaterialUIPickers name="expiryDate" values={checklistProps?.groupData} />
              <div style={{ marginTop: '10px' }}>
                {/* <OZCheckbox label="Update expiry date for the phone number" id="1" sx={{marginTop:'10px'}}
                // checked={isChecked('1')} onChange={handleOnCheckboxChange} 
                />   */}
                <OZFCheckbox
                  name="checkUpdateExpiry"
                  label="Update expiry date for the phone number"
                  labelPosition="end"
                />
              </div>
            </div>
            <div className="oz-add-block-group-checkbox-table-container">
              <Typography sx={basicStyle}>Groups ({blockedNumbersLength})</Typography>
              {(checked.length > 1) && (blockedNumbersLength > 0) && <DeleteDialog
                title="Delete Group"
                content="This will delete this group permanently. You cannot undo this action."
                imageStyle={{ marginLeft: '7.5rem', marginBottom: "1px" }}
                deleteFunction={() => { checklistProps.groupData.blockgroups = newBlockNumbers; checklistProps.deleteBlockNumer(checklistProps.groupData) }} />
              }
            </div>
          </div>
        }
        {checklistProps?.blockGroupFormMode === 'edit' &&
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                boxShadow: "none",
                borderBottomLeftRadius: "0rem",
                borderBottomRightRadius: "0rem",
                fontFamily: 'Roboto',
                fontSize: '0.875rem',
                fontWeight: 400,
                backgroundColor: '#ffff',
                height: '44px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: '0.0625rem solid #E6E5E6',
              },
              "& .MuiOutlinedInput-root:hover": {
                "& > fieldset": {
                  border: '0.0625rem solid #E6E5E6',
                }
              }
            }}
            fullWidth
            placeholder={'Search Group Names'}
            onChange={(searchVal) => searchBlockNumber(searchVal.target.value)}
          />
        }
        <Box
          sx={{
            alignItems: "center",
            borderLeft: '0.0625rem solid #E6E5E6',
            borderBottom: blockedNumbersLength > 0 ? '0.0625rem solid #E6E5E6' : "0px",
            borderRight: '0.0625rem solid #E6E5E6',
            borderBottomLeftRadius: "0.25rem",
            borderBottomRightRadius: "0.25rem",
            borderTop: checklistProps?.blockGroupFormMode !== 'edit' ? '0.0625rem solid #E6E5E6' : '0rem',
            borderTopLeftRadius: checklistProps?.blockGroupFormMode !== 'edit' ? "0.25rem" : '0rem',
            borderTopRightRadius: checklistProps?.blockGroupFormMode !== 'edit' ? "0.25rem" : '0rem',
            bgcolor: checklistProps?.blockGroupFormMode === 'edit' ? "#FFFFFF" : "#F6F7F9",
            maxHeight: "250px",
            overflowX: "hidden !important",
            overflowY: "scroll"

          }}>

          {/* {searchedNumber?.length > 0 &&
            <Grid sx={{ width: "448.4px" }} container={true}>
              {searchedNumber?.map((blockedNumberObject, index) => {
                return (
                  <div key={index} >
                    <Grid item lg={6}>
                      <ListItemButton
                        onClick={handleOnClickListItem(blockedNumberObject.blockedNumber, blockedNumberObject.id)}
                        sx={{
                          height: '2.75rem',
                          width: blockedNumbersLength > 2 ? checklistProps?.blockGroupFormMode === 'edit' ? '13.625rem' : '216.5px' : '13.50rem',
                          borderRight: index % 2 === 0 ? '0.0625rem solid #E6E5E6' : '0rem',
                          borderBottom: (blockedNumbersLength % 2 === 0 ?
                            (index < blockedNumbersLength - 2)
                              ? '0.0625rem solid #E6E5E6'
                              : '0rem'
                            : (index < blockedNumbersLength - 1)
                              ? '0.0625rem solid #E6E5E6'
                              : '0rem')
                        }}>
                        <CustomizedCheckbox
                          disabled={checklistProps?.blockGroupFormMode !== 'edit'}
                          checked={checklistProps?.blockGroupFormMode === 'edit' ? checked.indexOf(blockedNumberObject.blockedNumber) !== -1 : false}
                          tabIndex={-1}
                          disableRipple />
                        <Typography sx={{ fontSize: '14px', color: '#212121' }}>{blockedNumberObject.blockedNumber} </Typography>
                      </ListItemButton>
                    </Grid>
                  </div>
                );
              })}
            </Grid>} */}

          {searchedNumber?.length > 0 &&
            <>
              {searchedNumber?.map((blockedNumberObject, index) => {
                return (
                  <>
                    <Stack key={index} container spacing={2} display='flex' direction={'row'}>
                      <Grid item xs={7}>
                        <ListItemButton
                          onClick={handleOnClickListItem(blockedNumberObject.name, blockedNumberObject.id)}
                          sx={{
                            height: '3.75rem',
                            width: blockedNumbersLength > 2 ? checklistProps?.blockGroupFormMode === 'edit' ? '22.2rem' : '360.5px' : '22.2rem',
                            // borderRight: index % 2 === 0 ? '0.0625rem solid #E6E5E6' : '0rem',
                            // borderBottom: (blockedNumbersLength % 2 === 0 ?
                            //   (index < blockedNumbersLength - 2)
                            //     ? '0.0625rem solid #E6E5E6'
                            //     : '0rem'
                            //   : (index < blockedNumbersLength - 1)
                            //     ? '0.0625rem solid #E6E5E6'
                            //     : '0rem')
                          }}>
                          <Stack>
                            <Typography sx={{ fontSize: '14px', color: '#212121' }}>{blockedNumberObject.name}</Typography>
                            <Typography sx={{ fontSize: '13px', color: '#99A0A8', opacity: 0.7 }}>{blockedNumberObject.description}</Typography>
                          </Stack>
                        </ListItemButton>
                      </Grid>
                    </Stack>
                    <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                      <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6" }} />
                    </span>
                  </>
                );
              })}
            </>
          }
        </Box>

        {/* {editBlockForm && (
          <div>
            <EditExpiryDateBlockGroupForm
              props={editdata}
              update={onUpdateFunction}
              onClose={closeEditBlockGroupForm}
            />
          </div>
        )} */}

      </div>
    );
  }
  else {
    return (<div></div>)
  }
}

function ViewGroupNumberForm(viewGroupProps) {

  { console.log("viewGroupProps", viewGroupProps) }
  let styleFieldHeader = useMemo(
    () => ({
      fontSize: "0.75rem",
      height: '0.875rem',
      color: '#99A0A8',
      lineHeight: "0.875rem",
    }),
    []
  );

  let styleFieldValue = useMemo(
    () => ({
      height: '1rem',
      fontWeight: 400,
      fontSize: "0.875rem",
      color: '#212121',
      lineHeight: "1rem",
    }),
    []
  );

  let basicStyle = useMemo(
    () => ({
      fontSize: '0.75rem', fontWeight: 400, color: "#99A0A8"
    }),
    []
  );

  function getExpiryTimeDisplay(blockedNumberObject) {
    if (blockedNumberObject?.expiryDate !== null) {
      let value = moment(blockedNumberObject?.expiryDate ? blockedNumberObject?.expiryDate : null);
      if (value !== null) {
        return `${value.format("DD-MM-YYYY HH:mm:ss")}`;
      }
    } else {
      return `Null`;
    }
  }

  return (
    <div className="oz-add-block-group-form-view-container">
      <Grid container={true} >
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ ...styleFieldHeader, width: "5.5625rem", }}>
          Expiry Date
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ ...styleFieldValue, width: "6.625rem", marginTop: '0.5rem' }} >          
          {getExpiryTimeDisplay(viewGroupProps.data)}
        </Grid>
      </Grid>
      <div className="oz-add-block-group-form-view-inner-container">
        <Typography sx={basicStyle}>Groups ({viewGroupProps?.data?.blockgroups?.length})</Typography>
      </div>
      <CheckboxList openAddBlockNumberDialog={viewGroupProps.openAddBlockNumberDialog} blockgroups={viewGroupProps.data.blockgroups} mode='view' />
    </div >
  );
}

const CloudUploadOutlinedIcon = () => {

  return (<img className="oz-block-group-upload-file-image"
    src={`${process.env.PUBLIC_URL}/icons/upload_file.svg`}
    alt="upload"
  ></img>)
};

const AddBlockGroupForm = (blockGroupFormMainProps) => {
  console.log("blockGroupFormMainProps", blockGroupFormMainProps)
  let initMode = blockGroupFormMainProps.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add;
  const [openBlockAddForm, setOpenBlockAddFormState] = useState(true);
  const [blockGroupFormMode, setBlockGroupFormModeState] = useState(initMode);
  const [openOnlyNumbers, setOpenOnlyNumbers] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [valid, setValid] = useState(true);
  const [blockgroup, setBlockGroup] = useState([]);

  let buttonStyle = useMemo(() => ({
    borderRadius: '0.5rem',
    height: '2.75rem',
    fontSize: '0.875rem',
    width: '5.6875rem',
    fontWeight: 400
  }), [])

  let cancelButtonStyle = useMemo(
    () => ({
      height: '2.75rem',
      width: "5.6875rem",
      borderRadius: '0.5rem',
      fontSize: "0.875rem",
      fontWeight: 400,
      color: '#3D8BF8',
      border: '0.0625rem solid #008DFF',
      marginRight: '0.9375rem'
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string("Enter Group Name*")
          .nullable()
          .when('blockGroups', {
            is: (blockGroups) => !blockGroups || blockGroups.length === 0,
            then: yup
            .string("Enter Group Name*")
            .nullable()
            .min(2, "Group Name should be between 2 to 50 characters.")
            .max(50, "Group Name should be between 2 to 50 characters.")
            .matches(
              /^([A-Za-z]+((_|\s)?[A-Za-z\d])*)$/,
              'Group Name seems to be invalid.'
            )
            .required("Group Name is required."),
          }),
        description: yup
          .string("Enter Description*")
          .nullable()
          .when('blockGroups', {
            is: (blockGroups) => !blockGroups || blockGroups.length === 0,
            then: yup
            .string("Enter Description*")
            .nullable()
            .min(2, "Description should be between 2 to 100 characters.")
            .max(100, "Description should be between 2 to 100 characters.")
            .required("Description is required"),
        })       
      }),
    []
  );

  function updateUploadedNumber(data) {
    uploadedNumbers = data
  }

  const clearLocalStorageForBlockNumberForm = () => {
    localStorage.removeItem("BlockNumberString");
    localStorage.removeItem("expiryDate");
    localStorage.removeItem("checkUpdateExpiryAdd");
    
  }

  const clearLocalStorageForBlockGroupForm = () => {
    localStorage.removeItem("BlockGroupDescription");
    localStorage.removeItem("BlockGroupName");
    localStorage.removeItem("BlockGroups");
  }


  useEffect(() => {
    getBlocknumberGroupOptions()
  }, []);

  const [blockedNumberExist, setBlockedNumberExist] = useState(false);

  async function isBlockedNumberExist(blockedNumber){
    const apiResponse = await checkBlockNumberExist(blockedNumber);
    // console.log("apiResponse.isBlockedNumberExist",apiResponse.isBlockedNumberExist)
    //     if(apiResponse){ 
    // console.log("apiResponse.isBlockedNumberExist",apiResponse.isBlockedNumberExist)
    //       setBlockedNumberExist(apiResponse.isBlockedNumberExist);         
    //       return apiResponse.isBlockedNumberExist;
    //     }else{
    //       return false;
    //     }

    if (apiResponse && apiResponse?.isBlockedNumberExist == true) {
      console.log("inside")
      console.log("apiResponse.isBlockedNumberExist",apiResponse.isBlockedNumberExist)
      setBlockedNumberExist(true);
      return true;
    } else {
      setBlockedNumberExist(false);
      return false;
    }
  }

  async function getBlocknumberGroupOptions() {
    try {
      getBlockNumberGroupDropdown().then(resp => {
        setBlockGroup(resp.Data);
      }).catch(e => {
        showErrorNotification('No BlockNumber Group Data, Check your Internet Connection');
        setBlockGroup([]);
      })
    }
    catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  }

  async function saveBlockedNumber(data, date, isUpdate) {
    console.log("uploaded",uploadedNumbers.length)
    console.log("data check save block",data,date,isUpdate)

    localStorage.setItem("BlockNumberString", data ? data : "");
    localStorage.setItem("expiryDate", date ? date : "");
    localStorage.setItem("checkUpdateExpiryAdd", isUpdate === true || isUpdate === 'true' ? true : false);

    if(!uploadedNumbers.length>0){
      if(data == null){
        showErrorNotification("Blocked Number cant be empty");
      }else if(isUpdate == true){
        showErrorNotification("Update checkbox is only applicable when file is uploaded");
      }else{
        const check = await isBlockedNumberExist(data);
        if(!check){
          setShowBlockNumberAddForm(false);
        }
        else{
          showErrorNotification(data+" Block Number already exists");
        }
      }
    }else{
      if ((data !== null && data !== "" && data != undefined) || (date !== null && date !== "" && date != undefined)) {
        showErrorNotification("Please select either Block Number details or upload the file. Both cannot be selected")
      }
      else{
          setShowBlockNumberAddForm(false);
      }
    }
  }

  const [showBlockNumberAddForm, setShowBlockNumberAddForm] = useState(true);

  function onClickPreviousButton(value) {
    setShowBlockNumberAddForm(true)
    console.log("va;ue",value)
    localStorage.setItem("BlockGroups", value?.blockGroups ? JSON.stringify(value.blockGroups) : JSON.stringify([]))
    localStorage.setItem("BlockGroupName", value?.name ? value.name : "")
    localStorage.setItem("BlockGroupDescription", value?.description ? value.description : "")
  }

  function openAddBlockNumberDialog() {
    setOpenOnlyNumbers(true)
  }
  const closeBlockGroupForm = () => {
    blockGroupFormMainProps.onClose();
    uploadedNumbers = []
    setOpenBlockAddFormState(false);
  };

  async function addBlockNumberInAdd(formData) {
    console.log("formData", formData)
    const BlockGroupIds = [];

    //creating new group
    if (formData.name && formData.description) {
      try {
        const blockGroupToCreate = {name:formData.name,description:formData.description}
        
        await addBlockGroupNew(blockGroupToCreate)
          .then((resp) => {
            if (resp?.Status === "Success") {
              BlockGroupIds.push({ id: resp?.Data?.id });
              getBlocknumberGroupOptions();
              clearLocalStorageForBlockGroupForm();
              showSuccessNotification(`Block Group: ${formData.name} Created Successfully`);
            }
            else {
              // console.log(resp.Message)
              // showErrorNotification(JSON.stringify(resp.Message));
              showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
            }
          })
          .catch((e) => {
            console.log(e)
            showErrorNotification(JSON.stringify(e));
          });
      } catch (e) {
        console.log(e)
        showErrorNotification(JSON.stringify(e));
      }
    }else if(formData?.blockGroups?.length>0){
      formData.blockGroups.forEach(item => {
        BlockGroupIds.push({ id: item.id });
      });
    } else {
      showErrorNotification('Group details not found');
    }

    if(BlockGroupIds?.length >0){
      if (uploadedNumbers?.length > 0) {
        const fileName = uploadedNumbers[0]?.file.name;
        const groupIds = BlockGroupIds.length === 1 ? BlockGroupIds[0].id : BlockGroupIds.map(item => item.id).join(',');
        
        var form = new FormData();
        form.append("file", uploadedNumbers[0].file, "AddBlockNumber.xls");
        form.append("data", groupIds);
        try {
          const blockNumberData = {isUpdate:formData.checkUpdateExpiryAdd,body:form}
          await uploadBlockNumber(blockNumberData)
            .then((resp) => {
              if (resp?.Status === "Success") {
                clearLocalStorageForBlockNumberForm();
                if (blockGroupFormMainProps.currentTab === '4') {                
                  blockGroupFormMainProps.updateBlockGroups({});
                }
                closeBlockGroupForm();
                showSuccessNotification(`Block Number: ${formData.blockedNumber} Added Successfully`);
              }
              else {
                // console.log(resp.Message)
                // showErrorNotification(JSON.stringify(resp.Message));
                showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
              }
            })
            .catch((e) => {
              console.log(e)
              showErrorNotification(JSON.stringify(e));
            });
        } catch (e) {
          console.log(e)
          showErrorNotification(JSON.stringify(e));
        }
      }
      else{
        try {
          const blockNumberData = {blockedNumber:formData?.blockedNumber,expiryDate:formData?.expiryDate,blockgroups:BlockGroupIds}
          const dataToApi = {body: blockNumberData, isUpdate: true}
          
          await createBlockNumber(dataToApi)
            .then((resp) => {
              if (resp?.Status === "Success") {
                clearLocalStorageForBlockNumberForm();
                if (blockGroupFormMainProps.currentTab === '4') {
                  blockGroupFormMainProps.updateBlockGroups({});
                }
                
                uploadedNumbers = []
                closeBlockGroupForm();
                showSuccessNotification(`Block Number: ${formData.blockedNumber} Added Successfully`);
              }
              else {
                // console.log(resp.Message)
                // showErrorNotification(JSON.stringify(resp.Message));
                showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
              }
            })
            .catch((e) => {
              console.log(e)
              showErrorNotification(JSON.stringify(e));
            });
        } catch (e) {
          console.log(e)
          showErrorNotification(JSON.stringify(e));
        }
      }
    }

    // if (uploadedNumbers.length > 0) {
    //   const fileName = uploadedNumbers[0]?.file.name
    //   var form = new FormData();
    //   form.append("file", uploadedNumbers[0].file, "AddBlockNumber.xls");
    //   try {
    //     addBlockNumbers(form)
    //       .then((resp) => {
    //         if (resp.Status === "Success") {
    //           showSuccessNotification(`${resp.Message} in Block Group : ${formData.name}`);
    //           delete formData.name;
    //           // formData.blockNumbers = resp.Data //commented for testing purpose
    //           if (blockGroupFormMode === ADMIN_FORM_MODES.add) {
    //             formData.blockNumbers = resp.Data
    //             if (formData.blockNumbers !== null) {
    //               try {
    //                 addBlockGroups(formData)
    //                   .then((resp) => {
    //                     if (resp.Status === "Success") {
    //                       // localStorage.clear()
    //                       clearLocalStorageForAddBlockGroupForm();
    //                       if (blockGroupFormMainProps.currentTab === '4') {
    //                         blockGroupFormMainProps.updateBlockGroups();
    //                       }
    //                       closeBlockGroupForm();
    //                       showSuccessNotification(`Block Group : ${formData.name} Added Successfully`)
    //                     }
    //                     else {
    //                       // console.log(resp.Message)
    //                       // showErrorNotification(JSON.stringify(resp.Message));
    //                       showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
    //                     }
    //                   })
    //                   .catch((e) => {
    //                     console.log(e)
    //                     showErrorNotification(JSON.stringify(e));
    //                   });
    //               } catch (e) {
    //                 console.log(e)
    //                 showErrorNotification(JSON.stringify(e));
    //               }
    //             }
    //             else {
    //               showErrorNotification("block number not available")
    //             }
    //           }
    //           else {
    //             let blockNums = formData.blockNumbers;
    //             let combinedBlockNum = [...blockNums, ...resp.Data]
    //             formData.blockNumbers = combinedBlockNum
    //             try {
    //               addBlockGroupsById(formData)
    //                 .then((resp) => {
    //                   if (resp.Status === "Success") {
    //                     setBlockGroupFormModeState("View")
    //                     setOpenOnlyNumbers(false);
    //                     // localStorage.clear()
    //                     clearLocalStorageForAddBlockGroupForm();
    //                     showSuccessNotification(`Block Group: ${formData.name} Updated Successfully`)
    //                     // blockGroupFormMainProps.updateBlockGroup();
    //                     blockGroupFormMainProps.expUpdate(formData.id);
    //                   }
    //                   else {
    //                     // console.log(resp.Message)
    //                     // showErrorNotification(JSON.stringify(resp.Message));
    //                     showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
    //                   }
    //                 }
    //                 )
    //                 .catch((e) => {
    //                   console.log(e)
    //                   showErrorNotification(JSON.stringify(e));
    //                 });
    //             } catch (e) {
    //               console.log(e)
    //               showErrorNotification(JSON.stringify(e));
    //             }
    //           }
    //           showSuccessNotification(`File: ${fileName} Uploaded Successfully`)
    //         }
    //         else {
    //           showErrorNotification(JSON.stringify(resp.Message));
    //         }
    //       })
    //       .catch((e) => {
    //         showErrorNotification(JSON.stringify(e));
    //       });
    //   } catch (e) {
    //     showErrorNotification(JSON.stringify(e));
    //   }

      
      
    // }
    // else {
    //   try {
    //     let blockedNumber = {};
    //     blockedNumber.blockedNumber = localStorage.getItem("BlockNumberString");
    //     // console.log("here comes in Add", localStorage.getItem('expiryDate'));
    //     let convertedDate = moment(localStorage.getItem("expiryDate"), 'DD-MM-YYYY HH:mm:ss');
    //     blockedNumber.expiryDate = convertedDate.format("YYYY-MM-DD HH:mm:ss");
    //     // console.log("here calling API in Add", blockedNumber)
    //     addBlockGroupsAsJson(blockedNumber)
    //       .then((resp) => {
    //         if (resp.Status === "Success") {
    //           showSuccessNotification(`${resp.Message} in Block Group : ${formData.name}`);
    //           delete formData.name;
    //           // showSuccessNotification(`Block Numbers: ${localStorage.getItem("BlockNumberString")} Added Successfully`)
    //           if (blockGroupFormMode === ADMIN_FORM_MODES.add) {
    //             formData.blockNumbers = resp.Data
    //             if (formData.blockNumbers !== null) {
    //               try {
    //                 addBlockGroups(formData)
    //                   .then((resp) => {
    //                     if (resp.Status === "Success") {
    //                       // localStorage.clear()
    //                       clearLocalStorageForAddBlockGroupForm();
    //                       if (blockGroupFormMainProps.currentTab === '4') {
    //                         blockGroupFormMainProps.updateBlockGroups();
    //                       }
    //                       closeBlockGroupForm();
    //                       showSuccessNotification(`Block Group: ${formData.name} Added Successfully`)
    //                     }
    //                     else {
    //                       // console.log(resp.Message)
    //                       // showErrorNotification(JSON.stringify(resp.Message));
    //                       showErrorNotification(resp?.Message?.description || resp?.Message?.name || resp?.Message);
    //                     }
    //                   })
    //                   .catch((e) => {
    //                     console.log(e)
    //                     showErrorNotification(JSON.stringify(e));
    //                   });
    //               } catch (e) {
    //                 console.log(e)
    //                 showErrorNotification(JSON.stringify(e));
    //               }
    //             }
    //             else {
    //               showErrorNotification("block number not available")
    //             }
    //           }
    //           else {
    //             let blockNums = formData.blockNumbers;
    //             let combinedBlockNum = [...blockNums, ...resp.Data]
    //             formData.blockNumbers = combinedBlockNum
    //             try {
    //               addBlockGroupsById(formData)
    //                 .then((resp) => {
    //                   if (resp.Status === "Success") {
    //                     setBlockGroupFormModeState("View")
    //                     setOpenOnlyNumbers(false);
    //                     // localStorage.clear()
    //                     clearLocalStorageForAddBlockGroupForm();
    //                     showSuccessNotification(`Block Group: ${formData.name} Updated Successfully`)
    //                     // blockGroupFormMainProps.updateBlockGroup();
    //                     blockGroupFormMainProps.expUpdate(formData.id);
    //                   }
    //                   else {
    //                     // console.log(resp.Message)
    //                     // showErrorNotification(JSON.stringify(resp.Message));
    //                     showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
    //                   }
    //                 }
    //                 )
    //                 .catch((e) => {
    //                   console.log(e)
    //                   showErrorNotification(JSON.stringify(e));
    //                 });
    //             } catch (e) {
    //               console.log(e)
    //               showErrorNotification(JSON.stringify(e));
    //             }
    //           }
    //         }
    //         else {
    //           // console.log(resp.Message)
    //           // showErrorNotification(JSON.stringify(resp.Message));
    //           showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
    //         }
    //       })
    //       .catch((e) => {
    //         console.log(e)
    //         showErrorNotification(JSON.stringify(e));
    //       });
    //   } catch (e) {
    //     console.log(e)
    //     showErrorNotification(JSON.stringify(e));
    //   }
    // }
  }


  async function addBlockNumber(formData) {
    if (uploadedNumbers.length > 0) {
      const fileName = uploadedNumbers[0]?.file.name
      var form = new FormData();
      form.append("file", uploadedNumbers[0].file, "AddBlockNumber.xls");
      try {
        addBlockNumbers(form)
          .then((resp) => {
            if (resp.Status === "Success") {
              showSuccessNotification(`${resp.Message} in Block Group : ${formData.name}`);
              delete formData.name;
              // formData.blockNumbers = resp.Data //commented for testing purpose
              if (blockGroupFormMode === ADMIN_FORM_MODES.add) {
                formData.blockNumbers = resp.Data
                if (formData.blockNumbers !== null) {
                  try {
                    addBlockGroups(formData)
                      .then((resp) => {
                        if (resp.Status === "Success") {
                          // localStorage.clear()
                          clearLocalStorageForBlockGroupForm();
                          if (blockGroupFormMainProps.currentTab === '4') {
                            blockGroupFormMainProps.updateBlockGroups({});
                          }
                          closeBlockGroupForm();
                          showSuccessNotification(`Block Group : ${formData.name} Added Successfully`)
                        }
                        else {
                          // console.log(resp.Message)
                          // showErrorNotification(JSON.stringify(resp.Message));
                          showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
                        }
                      })
                      .catch((e) => {
                        console.log(e)
                        showErrorNotification(JSON.stringify(e));
                      });
                  } catch (e) {
                    console.log(e)
                    showErrorNotification(JSON.stringify(e));
                  }
                }
                else {
                  showErrorNotification("block number not available")
                }
              }
              else {
                let blockNums = formData.blockNumbers;
                let combinedBlockNum = [...blockNums, ...resp.Data]
                formData.blockNumbers = combinedBlockNum
                try {
                  addBlockGroupsById(formData)
                    .then((resp) => {
                      if (resp.Status === "Success") {
                        setBlockGroupFormModeState("View")
                        setOpenOnlyNumbers(false);
                        // localStorage.clear()
                        clearLocalStorageForBlockGroupForm();
                        showSuccessNotification(`Block Group: ${formData.name} Updated Successfully`)
                        // blockGroupFormMainProps.updateBlockGroup();
                        blockGroupFormMainProps.expUpdate(formData.id);
                      }
                      else {
                        // console.log(resp.Message)
                        // showErrorNotification(JSON.stringify(resp.Message));
                        showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
                      }
                    }
                    )
                    .catch((e) => {
                      console.log(e)
                      showErrorNotification(JSON.stringify(e));
                    });
                } catch (e) {
                  console.log(e)
                  showErrorNotification(JSON.stringify(e));
                }
              }
              showSuccessNotification(`File: ${fileName} Uploaded Successfully`)
            }
            else {
              showErrorNotification(JSON.stringify(resp.Message));
            }
          })
          .catch((e) => {
            showErrorNotification(JSON.stringify(e));
          });
      } catch (e) {
        showErrorNotification(JSON.stringify(e));
      }
    }
    else {
      try {
        let blockedNumber = {};
        blockedNumber.blockedNumber = localStorage.getItem("BlockNumberString");
        // console.log("here comes in update", localStorage.getItem('expiryDate'));
        blockedNumber.expiryDate = localStorage.getItem('expiryDate');
        // console.log("here calling API in Update", blockedNumber)

        // let blockedNumber = {};
        // blockedNumber.blockedNumber = localStorage.getItem("BlockNumberString");
        // let localDate = localStorage.getItem("expiryDate");
        // let PaExpiryDate = moment(localDate, 'DD-MM-YYYY HH:mm:ss');
        // console.log("here now", localStorage.getItem("expiryDate"));
        // console.log("here comes", PaExpiryDate);
        // let convertedDate = PaExpiryDate.format("YYYY-MM-DD HH:mm:ss");
        // console.log("now here comes", convertedDate);
        // blockedNumber.expiryDate = convertedDate;
        // console.log("here calling API", blockedNumber)
        addBlockGroupsAsJson(blockedNumber)
          .then((resp) => {
            if (resp.Status === "Success") {
              showSuccessNotification(`${resp.Message} in Block Group : ${formData.name}`);
              delete formData.name;
              // showSuccessNotification(`Block Numbers: ${localStorage.getItem("BlockNumberString")} Added Successfully`)
              if (blockGroupFormMode === ADMIN_FORM_MODES.add) {
                formData.blockNumbers = resp.Data
                if (formData.blockNumbers !== null) {
                  try {
                    addBlockGroups(formData)
                      .then((resp) => {
                        if (resp.Status === "Success") {
                          // localStorage.clear()
                          clearLocalStorageForBlockGroupForm();
                          if (blockGroupFormMainProps.currentTab === '4') {
                            blockGroupFormMainProps.updateBlockGroups({});
                          }
                          closeBlockGroupForm();
                          showSuccessNotification(`Block Group: ${formData.name} Added Successfully`)
                        }
                        else {
                          // console.log(resp.Message)
                          // showErrorNotification(JSON.stringify(resp.Message));
                          showErrorNotification(resp?.Message?.description || resp?.Message?.name || resp?.Message);
                        }
                      })
                      .catch((e) => {
                        console.log(e)
                        showErrorNotification(JSON.stringify(e));
                      });
                  } catch (e) {
                    console.log(e)
                    showErrorNotification(JSON.stringify(e));
                  }
                }
                else {
                  showErrorNotification("block number not available")
                }
              }
              else {
                let blockNums = formData.blockNumbers;
                let combinedBlockNum = [...blockNums, ...resp.Data]
                formData.blockNumbers = combinedBlockNum
                try {
                  addBlockGroupsById(formData)
                    .then((resp) => {
                      if (resp.Status === "Success") {
                        setBlockGroupFormModeState("View")
                        setOpenOnlyNumbers(false);
                        // localStorage.clear()
                        clearLocalStorageForBlockGroupForm();
                        showSuccessNotification(`Block Group: ${formData.name} Updated Successfully`)
                        // blockGroupFormMainProps.updateBlockGroup();
                        blockGroupFormMainProps.expUpdate(formData.id);
                      }
                      else {
                        // console.log(resp.Message)
                        // showErrorNotification(JSON.stringify(resp.Message));
                        showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
                      }
                    }
                    )
                    .catch((e) => {
                      console.log(e)
                      showErrorNotification(JSON.stringify(e));
                    });
                } catch (e) {
                  console.log(e)
                  showErrorNotification(JSON.stringify(e));
                }
              }
            }
            else {
              // console.log(resp.Message)
              // showErrorNotification(JSON.stringify(resp.Message));
              showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
            }
          })
          .catch((e) => {
            console.log(e)
            showErrorNotification(JSON.stringify(e));
          });
      } catch (e) {
        console.log(e)
        showErrorNotification(JSON.stringify(e));
      }
    }
    uploadedNumbers = []
  }

  async function updateBlockGroup(formData) {
    try {
      addBlockGroupsById(formData)
        .then((resp) => {
          if (resp.Status === "Success") {
            blockGroupFormMainProps.updateBlockGroup({});
            showSuccessNotification(`Block Group: ${formData.name} Updated Successfully`)
            closeBlockGroupForm();
          }
          else {
            // console.log(resp.Message)
            showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message);
            // showErrorNotification(JSON.stringify(resp.Message));
          }
        }
        )
        .catch((e) => {
          console.log(e)
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      console.log(e)
      showErrorNotification(JSON.stringify(e));
    }
    uploadedNumbers = [];
  }

  const getValidationInViewMode = (e, value) => {
    blockGroupFormMainProps.data.blockedNumber = value;
    const regex = /^([A-Za-z]+((_|\s)?[A-Za-z\d])*)$/;
    const isMatch = regex.test(value);

    const digitCount = value.length;

    // Check the conditions for validation
    const isValidLength = value.length >= 2 && value.length <= 50;
    const isValidDigitCount = digitCount >= 2 && digitCount <= 50;

    // Final validation check
    if (isMatch && isValidLength && isValidDigitCount) {
      setValid(true);
    } else {
      setValid(false);
    }
  }

  // function UploadPhoneNumber(uploadNumberProps) {
  //   let initData = {
  //     blockNumbers: [],
  //     description: "",
  //     name: "",
  //     expiryDate: (localStorage.getItem(("expiryDate")) || localStorage.getItem(("expiryDate")) !== null) ? localStorage.getItem(("expiryDate")) : null,
  //     blockNumber: (localStorage.getItem(("BlockNumberString")) || localStorage.getItem(("BlockNumberString")) !== null) ? localStorage.getItem(("BlockNumberString")) : "",
  //     checkUpdateExpiryAdd: (localStorage.getItem(("checkUpdateExpiryAdd")) || localStorage.getItem(("checkUpdateExpiryAdd")) !== null) ?  (localStorage.getItem('checkUpdateExpiryAdd') === true || localStorage.getItem('checkUpdateExpiryAdd') === "true") ? true : false : false,
  //   };

  //   const [fileUploaded, setUpload] = useState(false)
  //   const [fileObjsUploaded, setFileObjsUploaded] = useState(uploadedNumbers.length > 0 ? uploadedNumbers : [])
  //   const maxSize = 25 * 1024 * 1024;

  //   const onDownload = () => {
  //     const link = document.createElement("a");
  //     link.download = `block_unblock_number_sample.xls`;
  //     link.href = `${process.env.PUBLIC_URL}/sample_files/block_unblock_number_sample.xls`;
  //     link.click();
  //   };

  //   function checkVariant(message, variant) {
  //     if (variant === "success") {
  //       showSuccessNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
  //       setUpload(true)
  //     }
  //     else if (variant === "error") {
  //       showErrorNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
  //     }
  //     else {
  //       showInfoNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
  //       setUpload(false)
  //     }
  //   }



  //   function SuccessIcon() {
  //     return (
  //       <IconButton sx={{ width: "200px", marginTop: "20px" }}>
  //         <CheckCircleIcon sx={{
  //           color: "#3DABF8",
  //           width: "200px"
  //         }} />
  //       </IconButton>
  //     )
  //   }

  //   return (
  //     <Formik
  //       initialValues={initData}
  //       onSubmit={(formValues) => {
  //         // console.log("here coming the when update", formValues);
  //         // let CDate = moment(new Date()).format("DD-MM-YYYY HH:mm:ss");
  //         // if (CDate > formValues?.expiryDate) {
  //         //   showErrorNotification('Expiry date cannot be less than the Current Date Time');
  //         //   return;
  //         // } else {
  //         //   if (formValues?.expiryDate !== null || formValues?.expiryDate !== "") {
  //         //     let newExpiryDate = moment(formValues?.expiryDate, 'DD-MM-YYYY HH:mm:ss');
  //         //     formValues.expiryDate = newExpiryDate.format("YYYY-MM-DD HH:mm:ss")
  //         //   } else {
  //         //     formValues.expiryDate = null;
  //         //   }
  //         //   saveBlockedNumber(formValues.blockNumber, formValues.expiryDate)
  //         //   addBlockNumber(uploadNumberProps.data)
  //         // }
  //         let flag = 0;
  //         if (formValues.expiryDate === 'Invalid Date') {
  //           flag = 1;
  //           showErrorNotification("Invalid Expiry date, Please check the date passed");
  //           return;
  //         }
  //         console.log("formValues.expiryDate", formValues.expiryDate)
  //         if (formValues.expiryDate === "" || formValues.expiryDate === null || formValues.expiryDate === 'Invalid Date') {
  //           formValues.expiryDate = null;
  //         } else if (formValues.expiryDate !== null || formValues.expiryDate !== "" || formValues.expiryDate !== 'Invalid Date') {
  //           // let CDate = moment(new Date()).format("DD-MM-YYYY HH:mm:ss");
  //           let CDate = new Date().toString();
  //           let EEPirydate = moment(formValues.expiryDate, 'DD-MM-YYYY HH:mm:ss').toDate();
  //           const timestampCDate = new Date(CDate).getTime();
  //           const timestampEEPiryDate = new Date(EEPirydate).getTime();
  //           if (timestampCDate > timestampEEPiryDate) {
  //             flag = 1;
  //             showErrorNotification("Expiry date cannot be less than the Current Date Time");
  //             return;
  //           } else {
  //             let newExpiryDate = moment(formValues?.expiryDate, 'DD-MM-YYYY HH:mm:ss');
  //             formValues.expiryDate = newExpiryDate.format("YYYY-MM-DD HH:mm:ss");
  //           }
  //         }

  //         //   // if (CDate > formValues?.expiryDate || date1CC.isAfter(date2Expi)) {
  //         //   //   console.log("here on check", CDate);
  //         //   //   flag = 1;
  //         //   //   showErrorNotification("Expiry date cannot be less than the Current Date Time");
  //         //   //   return null;
  //         //   // } else {
  //         //   //   // console.log("going to checkkkkkk", CDate, formValues);
  //         //   //   let newExpiryDate = moment(formValues?.expiryDate, 'DD-MM-YYYY HH:mm:ss');
  //         //   //   formValues.expiryDate = newExpiryDate.format("YYYY-MM-DD HH:mm:ss");
  //         //   // }
  //         // }
  //         console.log("session",localStorage.getItem("checkUpdateExpiryAdd"))
  //         console.log("formValues.checkUpdateExpiryAdd",formValues.checkUpdateExpiryAdd)
  //         if (flag === 0) {
  //           saveBlockedNumber(formValues.blockNumber, formValues.expiryDate, formValues.checkUpdateExpiryAdd)
  //           addBlockNumber(uploadNumberProps.data)
  //         }
  //       }} >
  //       {(blockGroupFormProps) => (
  //         <form
  //           onSubmit={blockGroupFormProps.handleSubmit}
  //           id="uploadForm"
  //           className="ozcwScrollbar oz-add-phone-number-form-container">
  //           <div>
  //             <Grid container={true} spacing={2} alignItems="center"
  //               justifyContent="center">
  //               <Grid item xs={12} sm={12} md={12} lg={12}>
  //                 <OZInput
  //                   name="blockNumber"
  //                   label="Block Number *"
  //                   placeholder="Enter Number [ Ex. 9937XXXXXX, 9439XXXXXX, 8125XXXXXX ]"
  //                 />
  //               </Grid>
  //               <Grid item xs={12} sm={12} md={12} lg={12}>
  //                 <label style={{ fontWeight: 400, fontSize: "12px" }}>Expiry Date *</label>
  //                 <MaterialUIPickers name="expiryDate" values={blockGroupFormProps?.values} />
  //               </Grid>
  //               <Grid item sx={{ color: "#99A0A8", fontSize: "1.125rem", fontWeight: 400, }}>
  //                 OR
  //               </Grid>
  //               <Grid item xs={12} sm={12} md={12} lg={12}>
  //                 <DropzoneAreaBase
  //                   maxFileSize={maxSize}
  //                   Icon={fileUploaded ? SuccessIcon : CloudUploadOutlinedIcon}
  //                   dropzoneParagraphClass="dropzone-text"
  //                   dropzoneClass="drop-zone-area"
  //                   previewGridProps={{
  //                     item: {
  //                       position: 'absolute',
  //                       display: 'flex',
  //                       overflow: "hidden",
  //                       //flexWrap: "wrap",
  //                       alignItems: 'end',
  //                       height: '40px',
  //                     },
  //                     container: { spacing: 1, direction: 'row' }
  //                   }}
  //                   dropzoneText={fileUploaded ? "" :
  //                     <span>Drag and Drop the file to upload or <span style={{ color: '#3D8BF8', fontSize: '12px', fontWeight: '400', cursor: 'pointer' }}>Browse</span></span>}
  //                   acceptedFiles={['application/vnd.ms-excel']}
  //                   onAdd={(files) => {
  //                     if (files?.length > 0) {
  //                       setFileObjsUploaded([files[0]])
  //                     }
  //                     updateUploadedNumber(files);
  //                   }}
  //                   onDrop={(files) => { console.log("ondrop", files) }}
  //                   onAlert={(message, variant) => { checkVariant(message, variant); }}
  //                   initialFiles={uploadedNumbers}
  //                   onDelete={(fileObj) => { console.log('Removed File:', fileObj); uploadedNumbers = []; setFileObjsUploaded([]) }}
  //                   fileObjects={fileObjsUploaded}
  //                   filesLimit={3}
  //                   useChipsForPreview={true}
  //                   previewText="Selected files"
  //                   showAlerts={false}
  //                 />
  //               </Grid>
  //               <div style={{ color: '#99A0A8', fontSize: '12px', fontWeight: '400', paddingTop: '5px' }}>
  //                 Only XLS Format is accepted.Download XLS Sample from
  //                 <span style={{ color: '#3D8BF8', fontSize: '12px', fontWeight: '400', cursor: 'pointer' }} onClick={onDownload}> Here.</span>
  //               </div>
  //               <div style={{ color: '#99A0A8', fontSize: '12px', fontWeight: '400', paddingTop: '15px' }}>
  //                 <b>Note:</b>
  //                 <span style={{ color: '#3D8BF8', fontSize: '12px', fontWeight: '400', cursor: 'pointer' }} onClick={onDownload}> Here.</span>
  //               </div>
  //             </Grid>
  //             <Grid container={true} spacing={1} justifyContent={"flex-end"} paddingTop={"6.4rem"}>
  //               <Grid item >
  //                 <Button
  //                   variant="outlined"
  //                   sx={cancelButtonStyle}
  //                   onClick={() => { setOpenOnlyNumbers(false) }}>
  //                   Cancel
  //                 </Button>
  //               </Grid>
  //               <Grid item>
  //                 <Button
  //                   variant="contained"
  //                   sx={buttonStyle}
  //                   type="submit"
  //                 >
  //                   Save
  //                 </Button>
  //               </Grid>
  //             </Grid>
  //           </div>
  //         </form>
  //       )
  //       }
  //     </Formik >
  //   )
  // }

  const DataForm = (formProps) => {
    console.log("in data form", formProps)
    
    console.log("update",localStorage.getItem("checkUpdateExpiryAdd"))
    let initData = formProps.data || {
      blockNumbers: [],
      blockGroups: !showBlockNumberAddForm ? (localStorage.getItem("BlockGroups") !== null ?JSON.parse(localStorage.getItem("BlockGroups")) : []):[],
      expiryDate: (showBlockNumberAddForm || localStorage.getItem(("expiryDate")) !== null) ? localStorage.getItem("expiryDate") : null,
      description: !showBlockNumberAddForm ? localStorage.getItem("BlockGroupDescription") : "",
      name: !showBlockNumberAddForm ? localStorage.getItem("BlockGroupName") : "",
      blockedNumber: (showBlockNumberAddForm || localStorage.getItem(("BlockNumberString")) !== null) ? localStorage.getItem("BlockNumberString") : "",
      checkUpdateExpiryAdd: (localStorage.getItem('checkUpdateExpiryAdd') === true || localStorage.getItem('checkUpdateExpiryAdd') === 'true') ? true : false

    };
    const [fileUploaded, setUpload] = useState(uploadedNumbers.length > 0 ? true : false)
    const [fileObjsUploaded, setFileObjsUploaded] = useState(uploadedNumbers.length > 0 ? uploadedNumbers : [])
    const maxSize = 25 * 1024 * 1024;

    const onDownload = () => {
      const link = document.createElement("a");
      link.download = `block_unblock_number_sample.xls`;
      link.href = `${process.env.PUBLIC_URL}/sample_files/block_unblock_number_sample.xls`;
      link.click();
    };

    function checkVariant(message, variant) {
      if (variant === "success") {
        showSuccessNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
        setUpload(true)
      }
      else if (variant === "error") {
        showErrorNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
      }
      else {
        showInfoNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
        setUpload(false)
      }
    }

    const MaterialUIPickers = connect(({ name, onChangefunction, formik, values }) => {
      let newToDate = null;

      if (values?.expiryDate) {
        newToDate = moment(values?.expiryDate, "DD-MM-YYYY HH:mm:ss");
      }
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [value, setValue] = useState(newToDate);

      const onSelect = (newValue) => {
        setValue(newValue);
        let newFormatedValue = newValue?.format("DD-MM-YYYY HH:mm:ss");
        values.expiryDate = newFormatedValue;
      };

      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DateTimePicker
              disablePast
              // disableFuture
              value={value}
              views={["year", "month", "day", "hours", "minutes", "seconds"]}
              onChange={onSelect}
              inputFormat="DD-MM-YYYY HH:mm:ss"
              renderInput={(params) => <TextField sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#ffff',
                  fontSize: '14px',
                  height: '44px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #D6D6D7'
                },
                '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                  // backgroundColor: '#D6D6D7', //TODO
                  border: '1px solid #D6D6D7'
                }
              }} {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      );
    })

    function SuccessIcon() {
      return (
        <IconButton sx={{ marginTop: "20px" }}>
          <CheckCircleIcon sx={{
            color: "#3DABF8",
          }} />
        </IconButton>
      )
    }

    useEffect(() => {
      getGroupsList();
    }, [])

    const [groupList, setGroupList] = useState([]);

    async function getGroupsList() {
      const apiResponse = await getBlockNumberGroupDropdown();

      if (apiResponse.Data && apiResponse.Data.length > 0) {
        setGroupList(apiResponse.Data);
      } else {
        setGroupList([]);
      }
    };
    return (
      <div className="ozBlockGroupFormAddForm">
        <Formik
          enableReinitialize
          initialValues={initData}
          validationSchema={validationSchema}
          onSubmit={(formValues) => {
            console.log("here coming on add", formValues.values);
            // if (blockGroupFormMode === ADMIN_FORM_MODES.add) {
            //   console.log("here the filnal", formValues);
            //   let CDate = moment(new Date()).format("DD-MM-YYYY HH:mm:ss");
            //   if (CDate > formValues?.expiryDate) {
            //     showErrorNotification('Expiry date cannot be less than the Current Date Time');
            //     return;
            //   } else {
            //     if (formValues?.expiryDate !== null || formValues?.expiryDate !== "") {
            //       let newExpiryDate = moment(formValues?.expiryDate, 'DD-MM-YYYY HH:mm:ss');
            //       formValues.expiryDate = newExpiryDate.format("YYYY-MM-DD HH:mm:ss")
            //     } else {
            //       formValues.expiryDate = null;
            //     }
            //     addBlockNumber(formValues);
            //   }
            // }
            if (blockGroupFormMode === ADMIN_FORM_MODES.add) {
              let flag = 0;
              if (formValues.expiryDate === 'Invalid Date') {
                flag = 1;
                showErrorNotification("Invalid Expiry date, Please check the date passed");
                return;
              }
              if (formValues.expiryDate === "" || formValues.expiryDate === null || formValues.expiryDate === 'Invalid Date') {
                formValues.expiryDate = null;
              } else if (formValues.expiryDate !== null || formValues.expiryDate !== "" || formValues.expiryDate !== 'Invalid Date') {
                const moment = require('moment');
                let expiryDate = formValues.expiryDate;
                let CDate = moment().format('YYYY-MM-DD HH:mm:ss');
                let parsedExpiryDate;
                if (moment(expiryDate, 'DD-MM-YYYY HH:mm:ss', true).isValid()) {
                  parsedExpiryDate = moment(expiryDate, 'DD-MM-YYYY HH:mm:ss');
                } else if (moment(expiryDate, 'YYYY-MM-DD HH:mm:ss', true).isValid()) { // Check if expiryDate matches 'YYYY-MM-DD HH:mm:ss' format
                  parsedExpiryDate = moment(expiryDate, 'YYYY-MM-DD HH:mm:ss');
                } else {
                  // Handle invalid date format
                  console.error('Invalid date format:', expiryDate);
                  showErrorNotification("Expiry date cannot be less than the Current Date Time");
                  return;
                }
                // Format the parsed date to the desired format 'YYYY-MM-DD HH:mm:ss'
                let EEPirydate = parsedExpiryDate.format('YYYY-MM-DD HH:mm:ss');
                const timestampCDate = new Date(CDate).getTime();
                const timestampEEPiryDate = new Date(EEPirydate).getTime();
                //new doe for testing purpose:::::::::::://
                if (timestampCDate > timestampEEPiryDate) {
                  flag = 1;
                  showErrorNotification("Expiry date cannot be less than the Current Date Time");
                  return;
                } else {
                  formValues.expiryDate = EEPirydate;
                }
              }
              if (!formValues.blockGroups.length > 0 && !formValues.name && !formValues.description) {
                flag = 1;
                showErrorNotification("Please select any Group Details")
                return;
              }
              
              if(formValues.name || formValues.description){
                if (formValues.blockGroups.length > 0) {
                  flag = 1;
                  showErrorNotification("Please select either New Group or Existing Group Details. Both cannot be selected")
                  return;
                }
              }

              if (!formValues.blockGroups.length > 0) {
                if (formValues.name && !formValues.description) {
                  flag = 1;
                  showErrorNotification("Please enter Group Description")
                  return;
                } else if (!formValues.name && formValues.description) {
                  flag = 1;
                  showErrorNotification("Please enter Group name")
                  return;
                }

              }

              if (flag === 0) {
                // addBlockNumber(formValues); //this commented as having issue in converting date to desired format, for now testing
                addBlockNumberInAdd(formValues);
              }
            }
            else {
              if (valid === true) {
                updateBlockGroup(formValues);
              }
            }
          }} >
          {(blockGroupFormProps) => (
            <form
              onSubmit={blockGroupFormProps.handleSubmit}
              id="blockGroupForm"
              className={"ozcwScrollbar oz-add-phone-number-form-container " + (blockGroupFormMode === ADMIN_FORM_MODES.edit ? "oz-add-block-group-form-edit-container" : "oz-add-block-group-form-view-container")}
              style={{
                width: "29rem",
                height: (showBlockNumberAddForm || openOnlyNumbers) ? '26rem' : '20.4rem',
                marginTop: showBlockNumberAddForm ? "-12px" : "15px"
              }}>
              <div>
                <Grid container={true} spacing={2} alignItems="center"
                  justifyContent="center">
                  {((blockGroupFormMode === "Create" && showBlockNumberAddForm)) &&
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <OZInput
                        name="blockedNumber"
                        label="Block Number *"
                        onBlur={(event) => {
                          const value = event.target.value;
                          localStorage.setItem("BlockNumberString", value ? value : "");
                          setBlockedNumberExist(false)
                      }}
                        placeholder="Enter Number [ Ex. 9937XXXXXX, 9439XXXXXX, 8125XXXXXX ]"
                        error={blockedNumberExist}
                        helperText={blockedNumberExist ? "This Block Number already exists" : ""}
                      />
                    </Grid>}
                  {((blockGroupFormMode === "Create" && showBlockNumberAddForm)) &&
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <label style={{ fontWeight: 400, fontSize: "12px" }}>Expiry Date</label>
                      <MaterialUIPickers name="expiryDate" values={blockGroupFormProps?.values} />
                    </Grid>
                  }

                  {((blockGroupFormMode === "Create" && showBlockNumberAddForm)) &&
                    <Grid item sx={{ color: "#99A0A8", fontSize: "1.125rem", fontWeight: 400, }}>
                      OR
                    </Grid>}

                  {((blockGroupFormMode === "Create" && showBlockNumberAddForm)) &&
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DropzoneAreaBase
                          maxFileSize={maxSize}
                          Icon={fileUploaded ? SuccessIcon : CloudUploadOutlinedIcon}
                          dropzoneParagraphClass="dropzone-text"
                          dropzoneClass="drop-zone-area"
                          previewGridProps={{
                            item: {
                              position: 'absolute',
                              display: 'flex',
                              overflow: "hidden",
                              //flexWrap: "wrap",
                              alignItems: 'end',
                              height: '40px',
                            },
                            container: { spacing: 1, direction: 'row' }
                          }}
                          dropzoneText={fileUploaded ? "" :
                            <span>Drag and Drop the file to upload or <span style={{ color: '#3D8BF8', fontSize: '14px', fontWeight: '500', cursor: 'pointer' }}>Browse</span></span>}
                          acceptedFiles={['application/vnd.ms-excel']}
                          onAdd={(files) => {
                            if (files?.length > 0) {
                              setFileObjsUploaded([files[0]])
                            }
                            updateUploadedNumber(files);
                          }}
                          onDrop={(files) => { console.log("ondrop", files) }}
                          onAlert={(message, variant) => { checkVariant(message, variant); }}
                          initialFiles={uploadedNumbers}
                          onDelete={(fileObj) => { console.log('Removed File:', fileObj); uploadedNumbers = []; setFileObjsUploaded([]) }}
                          fileObjects={fileObjsUploaded}
                          filesLimit={3}
                          useChipsForPreview={true}
                          previewText="Selected files"
                          showAlerts={false}
                        />
                        <div style={{marginTop:'10px'}}>
                        {/* <OZCheckbox 
                          label="Update the existing expiry date across block number groups" 
                          id="isuploadUpdate"
                          name="isUploadUpdate" 
                          sx={{marginTop:'10px'}}
                          onChange={onUploadCheck}
                        // checked={isChecked('1')} onChange={handleOnCheckboxChange} 
                        />   */}
                        <OZFCheckbox
                            name="checkUpdateExpiryAdd"
                            label="Update the existing expiry date across block number groups"
                            labelPosition="end"
                        />
                        </div>
                      </Grid>

                      <Grid item class='oz-note' sx={{ fontWeight: 400, textAlign: 'left' }}>
                        <div style={{ fontSize: '14px', fontWeight: '400', paddingTop: '10px', marginLeft: '5px' }}>
                          <b>Note:</b>
                          <div style={{ paddingTop: '10px' }}>*A phone number can only have single expiry date across block number groups</div>
                          <div style={{ paddingTop: '10px' }}>*File should be XLS Format. To download sample
                            <span style={{ color: '#3D8BF8', fontSize: '14px', fontWeight: '500', cursor: 'pointer' }} onClick={onDownload}> Click Here.</span>
                          </div>
                        </div>
                      </Grid>

                    </>
                  }
                </Grid>

                {((blockGroupFormMode === "Create" && showBlockNumberAddForm)) &&
                  <Grid container={true} spacing={1} justifyContent={"flex-end"} paddingTop={"2rem"} paddingBottom={"1rem"}>
                    <Grid item >
                      <Button
                        variant="outlined"
                        sx={cancelButtonStyle}
                        onClick={openOnlyNumbers ? () => { setOpenOnlyNumbers(false); clearLocalStorageForBlockGroupForm();clearLocalStorageForBlockNumberForm(); } : () => { closeBlockGroupForm(); clearLocalStorageForBlockGroupForm();clearLocalStorageForBlockNumberForm(); }}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      {console.log("blockGroupFormProps.values.name", blockGroupFormProps.values)}
                      <Button
                        variant="contained"
                        sx={buttonStyle}
                        onClick={() => saveBlockedNumber(blockGroupFormProps.values.blockedNumber, blockGroupFormProps.values.expiryDate,blockGroupFormProps.values.checkUpdateExpiryAdd)}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>}
                <Grid container={true} spacing={2}>
                  {!showBlockNumberAddForm && blockGroupFormMode === ADMIN_FORM_MODES.add &&
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div class='oz-grid-group'>
                          <div style={{ fontSize: '14px', fontWeight: '1000', paddingBottom: '15px' }}>
                            Create New Group
                          </div>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <OZInput
                              name="name"
                              label="Group Name *"
                              placeholder="Enter Name"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '15px' }}>
                            <OZInput
                              name="description"
                              label="Group Description *"
                              placeholder="Enter Description"
                            />
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item sx={{ color: "#99A0A8", fontSize: "1.125rem", fontWeight: 400, marginLeft: "45%" }}>
                        OR
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div class='oz-grid-group'>
                          <div style={{ fontSize: '14px', fontWeight: '1000', paddingBottom: '15px' }}>
                            Add to Existing Group
                          </div>
                          <Stack>
                            <CustomAutoComplete
                              mode='Add'
                              limitTags={2}
                              name="blockGroups"
                              labelHeader='Groups *'
                              placeholder={"Select Groups"}
                              Datas={blockgroup}
                              optionLabelName='name'
                              showCheckBox={true}
                              multiple={true}
                              showselectall="true"
                              popupIcon={null}
                              showAvatar={true}
                            />
                          </Stack>
                        </div>
                      </Grid>
                    </>
                  }

                  {blockGroupFormMode === ADMIN_FORM_MODES.add && !showBlockNumberAddForm &&
                    <Grid item>
                      <Button onClick={() => { onClickPreviousButton(blockGroupFormProps.values) }} sx={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        lineHeight: '1rem',
                        letterSpacing: '0em',
                        color: '#536580',
                        position: 'absolute',
                        bottom: 34
                      }}>Previous Step</Button>
                    </Grid>
                  }

                  {blockGroupFormMode === ADMIN_FORM_MODES.edit &&
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CheckboxList groupData={blockGroupFormMainProps.data} openAddBlockNumberDialog={formProps?.openAddBlockNumberDialog} deleteBlockNumer={blockGroupFormMainProps.deleteBlockNumer} neExpUpdate={blockGroupFormMainProps.expUpdate} blockgroups={formProps?.data?.blockgroups} blockGroupFormMode='edit' />
                    </Grid>
                  }

                  {blockGroupFormMode === ADMIN_FORM_MODES.edit &&
                    <div className="oz-add-block-group-form-edit-button-container">
                      <Button
                        sx={cancelButtonStyle}
                        onClick={closeBlockGroupForm}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ ...buttonStyle, borderRadius: '0.5rem', backgroundColor: '#3D8BF8', width: "Hug (137px)" }}
                        type="submit"
                      >
                        Save Changes
                      </Button>
                    </div>}
                </Grid>
              </div>
            </form>
          )}
        </Formik>
      </div >
    );
  };

  return (
    <div>
      {(blockGroupFormMode === ADMIN_FORM_MODES.add || openOnlyNumbers) &&
        <OZDialog
          title="Add Block Numbers"
          hideActions={(showBlockNumberAddForm || openOnlyNumbers) ? true : false}
          saveButtonProps={openOnlyNumbers ? {} : {
            form: 'blockGroupForm',
            type: 'submit'
          }}
          open={openBlockAddForm}
          mode={blockGroupFormMode}
          onClose={openOnlyNumbers ? () => { setOpenOnlyNumbers(false); clearLocalStorageForBlockGroupForm();clearLocalStorageForBlockNumberForm(); uploadedNumbers = [] } : () => {
            closeBlockGroupForm(); clearLocalStorageForBlockGroupForm();clearLocalStorageForBlockNumberForm();
            ;
          }}
        >
          {openOnlyNumbers && clearLocalStorageForBlockGroupForm() && clearLocalStorageForBlockNumberForm()
          }
          {openOnlyNumbers ? 
            console.log("upload phone number comp need")
            // <UploadPhoneNumber data={blockGroupFormMainProps.data} />
            :
            <DataForm openAddBlockNumberDialog={openAddBlockNumberDialog} />}
        </OZDialog>
      }

      {(blockGroupFormMode === "View" || blockGroupFormMode === "Edit") &&
        <div>
          <OZDrawer
            open={openBlockAddForm}
            title={
              <div className={"oz-add-phone-number-form-drawer-header-container " + (blockGroupFormMode === "View" ? "oz-add-phone-number-form-drawer-header-view-container" : "oz-add-phone-number-form-drawer-header-edit-container")}>
                {/* {blockGroupFormMode === "View" && <img className="oz-add-phone-number-form-drawer-header-morevert" style={{ marginRight: '0.5rem', marginLeft: '0.625rem' }}
                src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                alt="edit"
              ></img>} */}

                {blockGroupFormMode === "View" ?
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <img className="delMoreIcon"
                      src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="option"
                      style={{ marginRight: "10px", marginLeft: "5px" }}
                    >
                    </img> */}
                    <Tooltip title={blockGroupFormMainProps.data.blockedNumber}><div
                      className="oz-managenumbers-drawer-title-heading"
                      sx={{
                        fontWeight: 600,
                        fontSize: "1.125rem",
                        width: "11rem",
                        marginLeft: "10px",
                        marginTop: '1px'
                      }}
                      flexGrow={1}>
                      {blockGroupFormMainProps.data.blockedNumber}
                    </div>
                    </Tooltip>
                  </div> :
                  <Tooltip title={blockGroupFormMainProps.data.blockedNumber}><div
                    className="oz-managenumbers-drawer-title-heading"
                    sx={{
                      fontWeight: 600,
                      fontSize: "1.125rem",
                      width: "11rem",
                      marginLeft: "11px",
                      marginTop: '1px'
                    }}
                    flexGrow={1}>
                    {"Edit " + blockGroupFormMainProps.data.blockedNumber}
                  </div>
                  </Tooltip>
                }

              {(newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full')) &&
              <>
                {blockGroupFormMode === "Edit" &&
                  <Typography
                    sx={{
                      fontSize: '0.875rem', marginLeft: "auto", fontWeight: 400, color: "#3D8BF8", cursor: "pointer"
                    }}
                    onClick={() => setBlockGroupFormModeState('View')}>
                    Discard
                  </Typography>
                }
              </>
              }
              </div>
            }
            onClose={closeBlockGroupForm}
            onEdit={() => {
              setOpenEditDialog(true)
              // setBlockGroupFormModeState(ADMIN_FORM_MODES.edit)
            }}
            mode={blockGroupFormMode} >
            {blockGroupFormMode === ADMIN_FORM_MODES.view ?
              <ViewGroupNumberForm openAddBlockNumberDialog={openAddBlockNumberDialog} data={blockGroupFormMainProps.data} />
              :
              <DataForm openAddBlockNumberDialog={openAddBlockNumberDialog} editMode={true} data={blockGroupFormMainProps.data} />
            }
          </OZDrawer>
          {openEditDialog && (
            <div>
              {console.log("blockGroupFormMainProps", blockGroupFormMainProps.data)}
              <EditExpiryDateBlockGroupForm
                props={blockGroupFormMainProps.data}
                // update={onUpdateFunction}
                // onClose={closeEditBlockGroupForm}
                onClose={() => {
                  setOpenEditDialog(false)
                  blockGroupFormMainProps.updateBlockGroup && blockGroupFormMainProps.updateBlockGroup({})
                  blockGroupFormMainProps.expUpdate && blockGroupFormMainProps.expUpdate(blockGroupFormMainProps.data.id)
                }}
              // update={blockGroupFormMainProps.data}
              />
            </div>
          )}
        </div>
      }
    </div >
  );
};

export default AddBlockGroupForm;