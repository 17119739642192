import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// import { ReactComponent as FilterAltOutlinedIcon } from '../../../../src/static/icons/filter.svg';
import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
import { Chip, Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import Tab from '@mui/material/Tab';
import * as React from 'react';
import { getActiveCalls, getCPaaSCallStatus, getCallStatusData } from '../../../services/api-service';
import { filterTypes, WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
// import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import '../CPaaSCallStatus/CPaaSCallStatus.css';
// import { secondsToMinSec } from '../../../utils/util';

let interval: any;

export default function CPaaSCallStatus() {
	const { useState, useRef } = React;
	// const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [callType, setCallType] = React.useState('all');
	const downloadContent = useRef<null | any>(null);
	const [callStatusPulse, setCallStatusPulse] = useState<any>({});
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	// const [filterType, setFilterType] = useState<any>('1');

	// const handleChange = (event: React.SyntheticEvent, newValue: string) => {
	// 	if(newValue === callType) return;
	// 	setCallType(newValue);
	// };

	// const toggleDrawer = (isOpen: boolean) => {
	// 	setIsDrawerOpen(isOpen);
	// };

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = callStatusFilterPulse.filter((item: any) => item.id !== id);
		setCallStatusFilterPulse(newFilter);
	};

	const clearFilter = () => {
		console.info('Clear all filter.');
		setCallStatusFilterPulse([]);
	};

	const [callStatusFilterPulse, setCallStatusFilterPulse] = useState<any>([]);

	const filterItems = callStatusFilterPulse.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const getCallStatus = async (filters = '0') => {
		const data: any = await getCPaaSCallStatus();
        setCallStatusPulse(data);
		// const filterKey = filterTypes.find((item: any) => item.id === +filterType) ?? filterTypes[0];
		// const filterValueKey = `${filterKey.value}Name`;
		// const totalValueData = data.find((item: any) => item[filterValueKey] === '0');
		// if (callStatusFilterPulse.length <= 2) {
		// 	const historicData = await getCallStatusData({ filters, type: filterType, callType, dateTypeId: '1' });
		// 	const totalHistoricValueData = historicData.find((item: any) => item[filterValueKey] === '0');
		// 	if (totalValueData) {
		// 		totalValueData.averageHandlingTimeTicker = +totalHistoricValueData?.AverageHandlingTime
		// 			? +(((+totalValueData.AverageHandlingTime - +totalHistoricValueData.AverageHandlingTime) / +totalHistoricValueData.AverageHandlingTime) * 100).toFixed(2)
		// 			: 0;
		// 		totalValueData.averageAnswerTimeTicker = +totalHistoricValueData?.AverageAnswerTime
		// 			? +(((+totalValueData.AverageAnswerTime - +totalHistoricValueData.AverageAnswerTime) / +totalHistoricValueData.AverageAnswerTime) * 100).toFixed(2)
		// 			: 0;
		// 		const activeCalls = await getActiveCalls();
		// 		totalValueData.activeCalls = activeCalls.ActiveCallsInIVR;
		// 	}
		// 	setCallStatusPulse(totalValueData ?? {});
		// } else {
		// 	const columns = [
		// 		{ name: filterKey.label, key: filterValueKey },
		// 		{ name: 'AHT', key: 'AverageHandlingTime', subTitle: totalValueData.AverageHandlingTime },
		// 		{ name: 'ATT', key: 'AverageTalkTime', subTitle: totalValueData.AverageTalkTime },
		// 		{ name: 'AWT', key: 'AverageWrapupTime', subTitle: totalValueData.AverageWrapupTime },
		// 		{ name: 'Avg. Hold Time', key: 'AverageHoldTime', subTitle: totalValueData.AverageHoldTime },
		// 		{ name: 'Avg. Answer Time', key: 'AverageAnswerTime', subTitle: totalValueData.AverageAnswerTime },
		// 		{ name: 'Active Calls', key: 'TotalCalls', subTitle: totalValueData.TotalCalls },
		// 		{ name: 'Total Answered', key: 'AnsweredCalls', subTitle: totalValueData.AnsweredCalls },
		// 		{ name: 'Total Abandoned', key: 'UnansweredCalls', subTitle: totalValueData.UnansweredCalls }
		// 	];
		// 	const actualData = data.filter((item: any) => item[filterValueKey] !== '0');
		// 	setRowData(actualData);
		// 	setColumnData(columns);
		// }
	};

	// const handleApplyFilters = ({ selectedFilters, filterType: newFilterType }: any) => {
	// 	setCallStatusFilterPulse(selectedFilters);
	// 	setFilterType(newFilterType);
	// 	toggleDrawer(false);
	// };

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		const filterIds = callStatusFilterPulse.length ? callStatusFilterPulse.map((item: any) => item.id).join(',') : '0';
		getCallStatus(filterIds);
		if (interval) {
			clearInterval(interval);
		}

		interval = setInterval(() => getCallStatus(filterIds), WIDGET_REFRESH_INTERVAL.CPAAS_CALL_STATUS);
	}, [callStatusFilterPulse, callType]);

	return (
		<Card className="customCardPulse" ref={downloadContent}>
			<div className="card-header border-none">
				<div className="card-title">
					CPaaS Call Status <div className="card-title-badge">Live</div>
				</div>
				<div className="card-actions">
					<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={'CPaaS Call Status'}></WidgetDownload>
					{/* <div className="card-icon" onClick={() => toggleDrawer(true)}>
						<Badge badgeContent={callStatusFilterPulse.length} color="primary">
							<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</Badge>
					</div> */}
				</div>
			</div>
			{/* <FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={callStatusFilterPulse} /> */}

			<Box ref={downloadContent} sx={{ width: '100%', typography: 'body1' }}>
				<TabContext value={callType}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						{/* <TabList onChange={handleChange} className="customTabs">
							<Tab label="All" value="all" />
							<Tab label="Inbound" value="inbound" />
							<Tab label="Outbound" value="outbound" />
						</TabList> */}
					</Box>
					<div className="customTabPanel">
						<Grid container spacing={2}>
							{callStatusFilterPulse.length > 0 && (
								<Grid item xs={12}>
									<div className="filter-outer">
										<div className="filter-container">
											<Stack direction="row" spacing={1}>
												{filterItems}
											</Stack>
										</div>
										<div className="filter-remove" onClick={clearFilter}>
											Remove
										</div>
									</div>
								</Grid>
							)}
							{callStatusFilterPulse.length > 2 ? (
								<OZTable rows={rowData} columns={columnData}></OZTable>
							) : (
								<>
									<Grid item xs={3}>
										<div className="widget-inner-card">
											<Grid item xs={12}>
												<div className="widget-values-container widget-values-sm" style={{paddingLeft: '60px'}}>
													<div className="widget-caption">Live Calls
                                                        <img style={{ paddingBottom: "7px" , marginLeft: '4px'}}
                                                            src={`${process.env.PUBLIC_URL}/icons/exclipse_green_dot.svg`}
                                                            alt="edit"
                                                        ></img>
                                                    </div>
													<div className="widget-values">
                                                        <div className="widget-value widget-value-md-pulse">{callStatusPulse.TotalLiveCalls || '0'}</div>
													</div>
												</div>
											</Grid>
										</div>
									</Grid>
									<Grid item xs={6}>
										<div className="widget-inner-card">
											<Grid xs={12} container spacing={1} direction="row" justifyContent="space-between" alignItems="center">
												<Grid item xs={6}>
													<div className="widget-values-container widget-values-sm">
														<div className="widget-caption">Registered Calls</div>
														<div className="widget-values">
															<div className="widget-value widget-value-md-pulse" style={{marginLeft: '5px'}}>{callStatusPulse.TotalCalls || '0'}</div>
														</div>
													</div>
												</Grid>
												<Grid item xs={6}>
													<div className="widget-values-container widget-values-inline">
														<div className="widget-values-row">
															<div className="widget-caption">Answered</div>
															<div className="widget-value">{callStatusPulse.TotalAnsweredCalls || '0'}</div>
														</div>
														<div className="widget-values-row">
															<div className="widget-caption">Unanswered</div>
															<div className="widget-value">{callStatusPulse.TotalUnansweredCalls || '0'}</div>
														</div>
														<div className="widget-values-row">
															<div className="widget-caption">Connectivity %</div>
															<div className="widget-value">{callStatusPulse.ConnectivityRatio || '0'}</div>
														</div>
													</div>
												</Grid>
											</Grid>
										</div>
									</Grid>
									<Grid item xs={3}>
										<div className="widget-inner-card">
											<Grid item xs={12}>
												<div className="widget-values-container-non-register widget-values-sm" style={{marginLeft: '25px'}}>
													<div className="widget-caption">Non-Registered</div>
													<div className="widget-values">
                                                        <div className="widget-value widget-value-md-pulse">{callStatusPulse.TotalUnregistered || '0'}</div>
													</div>
												</div>
											</Grid>
										</div>
									</Grid>
								</>
							)}
						</Grid>
					</div>
				</TabContext>
			</Box>
		</Card>
	);
}
