import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import AgentCurrentState from '../../components/widgets/AgentCurrentState/AgentCurrentState';
import AgentList from '../../components/widgets/AgentList/AgentList';
import AgentPerformance from '../../components/widgets/AgentPerformance/AgentPerformance';
import AgentStateCounter from '../../components/widgets/AgentStateCounter/AgentStateCounter';
import CallStatus from '../../components/widgets/CallStatus/CallStatus';
import ComparativeCallTrends from '../../components/widgets/ComparativeCallTrends/ComparativeCallTrends';
import CSAT from '../../components/widgets/CSAT/CSAT';
import DataSummary from '../../components/widgets/DataSummary/DataSummary';
import DropRatio from '../../components/widgets/DropRatio/DropRatio';
import FeedbackSurvey from '../../components/widgets/FeedbackSurvey/FeedbackSurvey';
import HourlyCallSummary from '../../components/widgets/HourlyCallSummary/HourlyCallSummary';
import LiveCalls from '../../components/widgets/LiveCalls/LiveCalls';
import OccupancyCall from '../../components/widgets/OccupancyvsCall/OccupancyvsCall';
import OutboundCampaignStatus from '../../components/widgets/OutboundCampaignStatus/OutboundCampaignStatus';
import Pacing from '../../components/widgets/Pacing/Pacing';
import QueueCounters from '../../components/widgets/QueueCounters/QueueCounters';
import QueueCountersDigital from '../../components/widgets/QueueCountersDigital/QueueCountersDigital';
import SkillSummary from '../../components/widgets/SkillSummary/SkillSummary';
import SLA from '../../components/widgets/SLA/SLA';
import TopDisposition from '../../components/widgets/TopDisposition/TopDisposition';
import WidgetsCarousel from '../../components/widgets/WidgetsCarousel/WidgetsCarousel';
import HourlyDigitalSummary from '../../components/widgets/HourlyDigitalSummary/HourlyDigitalSummary';
import ActivityLog from '../../components/widgets/ActivityLog/ActivityLog';
import { checkRoles } from '../../utils/util';
import InternalApiUtilization from '../../components/widgets/InternalApiUtilization/InternalApiUtilization';

function Playground() {
	return (
		<>
			<div>Dashboard</div>
			<br />
			<AudioPlayer idx={0} />
			<WidgetsCarousel />
			<br />
			<SLA></SLA>
			<br />
			<CallStatus></CallStatus>
			<br />
			<AgentStateCounter></AgentStateCounter>
			<br />
			<AgentCurrentState></AgentCurrentState>
			<br />
			<QueueCounters></QueueCounters>
			<br />
			<QueueCountersDigital></QueueCountersDigital>
			<br />
			<TopDisposition></TopDisposition>
			<br />
			<HourlyCallSummary></HourlyCallSummary>
			<br />
			<HourlyDigitalSummary></HourlyDigitalSummary>
			<br />
			<SkillSummary></SkillSummary>
			<br />
			<AgentPerformance></AgentPerformance>
			<br />
			<CSAT></CSAT>
			<br />
			<OccupancyCall></OccupancyCall>
			<br />
			<ComparativeCallTrends></ComparativeCallTrends>
			<br />
			<DropRatio></DropRatio>
			<br />
			<OutboundCampaignStatus></OutboundCampaignStatus>
			<br />
			<LiveCalls></LiveCalls>
			<br />
			<Pacing></Pacing>
			<br />
			<AgentList></AgentList>
			<br />
			<DataSummary></DataSummary>
			<br />
			<FeedbackSurvey></FeedbackSurvey>
			<br />
			<ActivityLog></ActivityLog>
			<br />
			{checkRoles("ROLE_ADMIN") && <InternalApiUtilization></InternalApiUtilization>}
			<br />
		</>
	);
}

export default Playground;
