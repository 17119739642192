import React, { useState, useMemo, useEffect } from "react";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { Button, Stack, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./Music.css";
import { getMusic, getMusicById, deleteMusicById } from "../../../services/api-service";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import MusicForm from "./MusicForm";
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";

const MusicFile = () => {

    const AudiourlRenderer = (props) => {
        const [musicAnchorEl, setMusicAnchorEl] = useState(null);
        const anchorRef = React.useRef();
        const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);

        const onClickDelete = () => {
            setMusicAnchorEl(null);
            setDeleteConfirmationDialog(true);
        };

        const onMorevertIconClick = () => {
            setTimeout(() => setMusicAnchorEl(anchorRef?.current), 1);
        };

        const onMenuClose = () => {
            setMusicAnchorEl(null);
        };

        const onClickDeleteDialogClose = () => {
            setDeleteConfirmationDialog(false);
        };

        const onClickDeleteDialogDelete = () => {
            deletemusic(props?.data?.id);
            setDeleteConfirmationDialog(false);
        };

        const styleListItemButton = useMemo(
            () => ({
                "&.MuiButtonBase-root": {
                    color: '#212121',
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                    height: '16px',
                    lineHeight: "14px",
                },
                '&:hover': {
                    borderRadius: "4px",
                },
            }),
            []
        );

        return (
            <div className="oz-music-noOfSkill-container">
                <label>{props?.value}</label>
                {(newAuthPages(NewAdminPages.MUSIC_FILE) || newAuthSubAdminAccess(NewAdminPages.MUSIC_FILE, 'Full')) && (
                    <img className="musicMorevertIcon"
                        onClick={onMorevertIconClick}
                        src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
                    >
                    </img>
                )}
                <div ref={anchorRef}>
                    <Menu anchorEl={musicAnchorEl} open={Boolean(musicAnchorEl)} onClose={onMenuClose} sx={{
                        maxHeight: "70vh",
                        "& .MuiPaper-root": {
                            backgroundColor: "white",
                            minWidth: "20px",
                        },
                        "& .MuiMenu-paper": {
                            backgroundColor: "white",
                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                            border: "0.0625rem solid #E6E5E6",
                            borderRadius: "12px",
                            marginLeft: '-0.3625rem',
                        },
                    }}>
                        <MenuItem onClick={onClickDelete} sx={{ ...styleListItemButton }}>Delete Music</MenuItem>
                    </Menu>
                    {openDeleteConfirmationDialog &&
                        <DeleteDialog title={"Delete Music"} content={" This will delete this " + props?.data?.name + " music permanently. You cannot undo this action."} onClose={onClickDeleteDialogClose}
                            open={true} deleteFunction={onClickDeleteDialogDelete} />
                    }
                </div>
            </div>
        );
    };

    const [musicForm, setMusicForm] = useState({ isNew: false, show: false });
    const [musicList, setMusicList] = useState([]);
    const [gridRef, setGridRef] = useState(null);
    const [onRowclickMusicData, setOnRowclickMusicData] = useState({});
    const [loading, setLoading] = useState(false)

    const showMusicForm = () => {
        setMusicForm({ isNew: true, show: true });
    };

    const closeMusicForm = () => {
        setMusicForm({ isNew: false, show: false });
        getMusicList();
    };

    let buttonStyle = useMemo(
        () => ({
            borderRadius: "8px",
            height: "30px",
            fontSize: "14px",
            fontWeight: 400,
            width: "149px",
            backgroundcolor: "#3D8BF8",
            lineHeight: "16.41px",
        }),
        []
    );

    useEffect(() => {
        getMusicList();
        localStorage.removeItem('musicData');
    }, []);

    const GetNameRenderer = (props) => {
        return (
            <span style={{ color: '#3D8BF8', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{props?.value}</span>
        )
    }

    async function getMusicList() {
        localStorage.removeItem('musicData');
        setLoading(true);
        try {
            getMusic().then((resp) => {
                if (resp) {
                    setMusicList(resp.Data);
                }
                setLoading(false);
            }).catch((e) => {
                setMusicList([]);
                setLoading(false);
            });
        } catch (e) {
            showErrorNotification("Error in music list");
            setLoading(false);
        }
    };

    async function deletemusic(dataId) {
        setLoading(true);
        try {
            deleteMusicById(dataId).then((resp) => {
                if (resp.Status === "Success") {
                    getMusicList();
                    showSuccessNotification(resp.Message);
                    setLoading(false);
                }
                else {
                    showErrorNotification(resp.Message);
                    setLoading(false);
                }
            }).catch((e) => {
                showErrorNotification("Error deleting music");
                setLoading(false);
            });
        } catch (e) {
            showErrorNotification("Error in music delete");
            setLoading(false);
        }
    };

    const columnDefs = [
        {
            field: "name",
            headerName: "Name",
            cellRenderer: GetNameRenderer,
            maxWidth: 350,
        },
        { field: "audioUrl", headerName: "Audio URL", cellRenderer: AudiourlRenderer },
    ];

    function onCLickRow(rowItem) {
        setLoading(true);
        setOnRowclickMusicData({});
        setMusicForm({ isNew: false, show: true });
        try {
            getMusicById(rowItem.data.id).then((resp) => {
                if (resp) {
                    setOnRowclickMusicData(resp.Data[0]);
                }
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                setOnRowclickMusicData({});
            });
        } catch (e) {
            showErrorNotification("Error in music list ");
            setLoading(false);
        }
    };

    return (
        <div className="ozAdminMusicContainer">
            <Grid sx={{ marginBottom: "10px" }}>
                <Typography className="oz-page-header"> Configurations </Typography>
            </Grid>
            <Stack direction="row" sx={{ marginBottom: "16px" }} justifyContent="space-between">
                <Typography className="oz-page-heading">Music Files</Typography>
                {/* <Button
                    sx={buttonStyle}
                    variant="contained"
                    startIcon={<AddBoxOutlinedIcon />}
                    onClick={showMusicForm}
                >
                    Add Music
                </Button> */}
                {(newAuthPages(NewAdminPages.MUSIC_FILE) || newAuthSubAdminAccess(NewAdminPages.MUSIC_FILE, 'Full')) && (
                    <Button
                        sx={buttonStyle}
                        variant="contained"
                        startIcon={<AddBoxOutlinedIcon />}
                        onClick={showMusicForm}
                    >
                        Add Music
                    </Button>
                )}
            </Stack>
            {musicForm.show && musicForm.isNew && <MusicForm updateName={getMusicList} onClose={closeMusicForm} />}
            <OZGridToolbar title='List of Music Files' searchPlaceholder='Search' rowCount={musicList.length} dataGridRef={gridRef} fileName='Music' />
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                (<>
                    <OZDataGrid
                        rowClickCallback={onCLickRow}
                        height="26.3rem"
                        data={musicList}
                        columns={columnDefs}
                        setGridRef={setGridRef}
                    />

                    {musicForm.show && !musicForm.isNew && (
                        <MusicForm openDialog={true} updateName={getMusicList} onClose={closeMusicForm} data={onRowclickMusicData} editAccess={(newAuthPages(NewAdminPages.MUSIC_FILE) || newAuthSubAdminAccess(NewAdminPages.MUSIC_FILE, 'Full') || newAuthSubAdminAccess(NewAdminPages.MUSIC_FILE, 'Edit'))} />
                    )}
                </>
                )
            }
        </div >
    );
};

export default MusicFile;