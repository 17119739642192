import { MapContainer, TileLayer, Marker, } from 'react-leaflet';
import { Typography } from '@mui/material';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";

const icon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
});

const LocationMapView = ({ data = {} }) => {
    const { latitude, longitude, address, url } = JSON.parse(data);
    const position = [latitude, longitude];

    return (latitude && longitude) ?
        <a href={`https://google.com/maps/search/${latitude},${longitude}`} target="_blank" rel="noreferrer">
            <MapContainer
                style={{
                    minWidth: "300px",
                    height: address || url ? "220px" : '250px',
                    borderRadius: "8px",
                    overflow: "hidden",
                }}
                preferCanvas={true}
                attributionControl={false}
                zoomControl={false}
                center={position}
                zoom={10}
                minZoom={10}
                maxZoom={10}
                dragging={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position} icon={icon}>
                </Marker>
            </MapContainer>
            {url && <a style={{ fontSize: "12px", display: "block", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }} href={url} title={url} target="_blank" rel="noreferrer">{url}</a>}
            {address && <Typography>{address}</Typography>}
        </a>
        :
        <Typography>Unable to process location data</Typography>
}

export default LocationMapView;