import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextareaAutosize,
	Theme
} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { getComparativeCallTrendsDrawerDeets, top5Outliers } from '../../services/api-service';
import '../BreakdownDrawer/BreakdownDrawer.css';

const useStyles: any = makeStyles((theme: Theme) => ({
	breakdownContainer: {
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column'
	},
	breakdownHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	headerText: {
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '21px',
		color: '#212121'
	},
	drawerGutter: {
		paddingTop: '74px'
	},
	paper: { width: '498px' },
	customiseButton: {
		borderRadius: '8px !important',
		padding: '0.2rem 1rem !important'
	},
	sendButton: {
		borderRadius: '8px !important',
		padding: '0.2rem 1rem !important',
		backgroundColor: '#3D8BF8 !important'
	},
	addagentsButton: {
		justifyContent: 'center !important',
		alignItems: 'center !important',
		padding: '6px 16px !important',

		left: '270px !important',

		backgroundColor: '#008DFF !important',
		/* Primary/Brand_color */

		border: '1px solid #3D8BF8 !important',
		borderRadius: '8px !important',
		color: 'white !important'
	}
}));

const columns = [
	{ name: 'Name', key: 'AgentName', align: 'left' },
	{ name: 'ASA', key: 'AvgPickupTime', align: 'left' },
	{ name: 'AHT', key: 'AvgHoldTime', align: 'left' },
	{ name: 'ACW', key: 'AvgHandlingTime', align: 'left' },
	{ name: 'Avg. hold time', key: 'AvgWrapupTime', align: 'left' }
];

type Props = {
	toggleDrawer: any;
	isOpen: boolean | undefined;
	title?: string;
	thresholdItems?: any[];
	widgetName?: string;
	hour?: any;
	dateTypeId?: any;
	filterIds?: any;
	filterType?: any;
	callType?: any;
};

const BreakDownDrawer = ({ isOpen, toggleDrawer, widgetName, hour, dateTypeId, filterIds, filterType, callType }: Props) => {
	const classes = useStyles();
	console.log(hour, dateTypeId);
	const [open, setOpen] = React.useState(false);
	const [isInput, setIsInput] = React.useState(false);
	const [alertSelectedUser, setAlertSelectedUser] = React.useState();
	const [tableValues, setTableValues] = React.useState([]);
	const [drawerValues, setDrawerValues] = React.useState<any>({});

	const handleClickOpen = (name: any) => {
		setAlertSelectedUser(name);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCustom = () => {
		setIsInput(true);
	};

	const handleCancel = () => {
		setIsInput(false);
	};

	const retriveTableData = async (filters: '0', type: '1', callType: 'All', dateTypeId: 0, hour: '11') => {
		const data: any = await top5Outliers({ filters, type, callType, dateTypeId, hour });
		setTableValues(data);
	};
	const retriveDrawerData = async (filters: '0', type: '1', callType: 'All', dateTypeId: 0, hour: '11') => {
		const apiResponse: any = await getComparativeCallTrendsDrawerDeets({filters, type, callType, dateTypeId, hour});
		setDrawerValues(apiResponse[0]);
	};
	

	useEffect(() => {
		retriveTableData(filterIds, filterType, callType, dateTypeId, hour);
		if(widgetName === 'COMPARATIVE_CALL_TRENDS'){
			retriveDrawerData(filterIds, filterType, callType, dateTypeId, hour);
		}
	}, []);

	return (
		<Drawer open={isOpen} anchor={'right'} onClose={() => toggleDrawer(false)}>
			<Box sx={{ width: 480 }} className={classes.breakdownContainer}>
				<div className={classes.drawerGutter}>
					<div className={classes.breakdownHeader}>
						<div>
							<b className="drawer-title">
								{widgetName === 'CSAT' && 'CSAT breakdown'} {widgetName === 'COMPARATIVE_CALL_TRENDS' && 'Call trends breakdown'}{' '}
								{widgetName === 'OCCUPANCY_CALL' && 'Call trends breakdown'} {widgetName === 'OUTBOUND_CALL_CAMPAIGN' && 'Campaign Breakdown'}{' '}
							</b>
							<span className="drawer-title-badge">Live</span>
						</div>
						<IconButton aria-label="delete" size="small" onClick={() => toggleDrawer(false)}>
							<CloseIcon style={{ fontSize: '22px', color: '#99a0a8' }} />
						</IconButton>
					</div>
					<div>
						<span className="drawer-subtitle">{(dateTypeId == 2 ? "7 Day Average" : (dateTypeId == 1 ? "Yesterday" : "Today"))} | {drawerValues?.CallHour}</span>
					</div>
					<hr style={{ marginTop: '20px' }} />
				</div>
				{widgetName === 'OCCUPANCY_CALL' ? (
					<Grid container spacing={1} columns={16}>
						<Grid item xs={13}>
							<span className="drawer-names">Total agents</span>
						</Grid>
						<Grid item xs={2}>
							<span className="drawer-values"> 250</span>
						</Grid>
						<Grid item xs={13}>
							<span className="drawer-names">Occupancy rate</span>
						</Grid>
						<Grid item xs={2}>
							<span className="drawer-values"> 62%</span>
						</Grid>
						<Grid item xs={13}>
							<span className="drawer-names"> Call Volume</span>
						</Grid>
						<Grid item xs={2}>
							<span className="drawer-values">3000</span>
						</Grid>
						<Grid item xs={13}>
							<span className="drawer-names"> Calls in Queue</span>
						</Grid>
						<Grid item xs={2}>
							<span className="drawer-values"> 860</span>
						</Grid>
						<Grid item xs={13}>
							<span className="drawer-names"> Avg Queue time</span>
						</Grid>
						<Grid item xs={2}>
							<span className="drawer-values"> 00:30:00</span>
						</Grid>
					</Grid>
				) : (
					<Grid container spacing={1} columns={16}>
						<Grid item xs={13}>
							<span className="drawer-names">Answered calls</span>
						</Grid>
						<Grid item xs={2}>
							<span className="drawer-values">{drawerValues?.AnsweredCalls}</span>
						</Grid>
						<Grid item xs={13}>
							<span className="drawer-names">Unanswered calls</span>
						</Grid>
						<Grid item xs={2}>
							<span className="drawer-values">{drawerValues?.UnansweredCalls}</span>
						</Grid>
						<Grid item xs={13}>
							<span className="drawer-names">Agents available</span>
						</Grid>
						<Grid item xs={2}>
							<span className="drawer-values">{drawerValues?.AgentsAvailable}</span>
						</Grid>
					</Grid>
				)}
				<Divider style={{ marginTop: '20px' }} />
				{widgetName === 'OCCUPANCY_CALL' || widgetName === 'COMPARATIVE_CALL_TRENDS' ? (
					<>
						<Grid container spacing={2} columns={16} style={{ marginTop: '10px' }}>
							<Grid item xs={11}>
								<b>Top 5 outliers</b>
							</Grid>
							<Grid item xs={5}>
								<Button style={{ color: '#008DFF' }} onClick={() => handleClickOpen('All')}>
									{' '}
									Send alert to all
								</Button>
							</Grid>
						</Grid>
						<div style={{ marginTop: '20px' }}>
							<TableContainer sx={{ borderRadius: '12px' }} className="drawer-table">
								<Table sx={{ minWidth: 416 }}>
									<TableHead style={{ background: '#F0F1F2' }}>
										<TableRow>
											{columns?.map((column, idx) => (
												<TableCell key={idx} sx={{ maxWidth: column.key === 'AgentName' ? '7rem' : '4rem' }}>
													{column.name}
												</TableCell>
											))}
											<TableCell sx={{ maxWidth: '5rem' }}>Send alert</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{tableValues.map((row: any, rowIdx: number) => (
											<TableRow key={rowIdx}>
												{columns?.map((column, columnIdx) => (
													<TableCell
														sx={{
															maxWidth: column.key === 'AgentName' ? '7rem' : '55px',
															overflow: 'hidden',
															backgroundColor: (row[column.key]?.value || row[column.key]) === '0' ? '#F2C2C2' : 'unset'
														}}
														key={columnIdx}
														component={columnIdx === 0 ? 'th' : 'td'}
														className={`${row[column.key]?.warning}`}
													>
														{row[column.key]?.value || row[column.key]}
														{row[column.key]?.badge && <span className="badge">{row[column.key]?.badge || ''}</span>}
													</TableCell>
												))}
												<TableCell>
													<IconButton aria-label="delete" size="small" onClick={() => handleClickOpen(row['AgentName'])}>
														<NotificationsOutlinedIcon style={{ fontSize: '22px', color: '#008DFF' }} />
													</IconButton>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							<Divider style={{ marginTop: '20px' }} />
						</div>
					</>
				) : (
					''
				)}
				<div style={{ marginTop: '20px' }}>
					<b>Suggestion</b>
				</div>
			</Box>
			{widgetName === 'OCCUPANCY_CALL' && (
				<div className="drawer-text-container">
					<div className="drawer-info">
						<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
							<Grid item xs={1}>
								<InfoOutlinedIcon />
							</Grid>
							<Grid item>
								<span>Call volume has increased in Support skill</span>
							</Grid>
						</Grid>
					</div>
				</div>
			)}
			{widgetName === 'CSAT' && (
				<>
					<div className="drawer-text-container1">
						<div className="drawer-info">
							<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
								<Grid item xs={1}>
									<InfoOutlinedIcon />
								</Grid>
								<Grid item>
									<span>ASA needs to be improved</span>
								</Grid>
							</Grid>
						</div>
					</div>
					<div className="drawer-text-container2">
						<div className="drawer-info">
							<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
								<Grid item xs={1}>
									<InfoOutlinedIcon />
								</Grid>
								<Grid item>
									<span>ASA needs to be improved</span>
								</Grid>
							</Grid>
						</div>
					</div>
					<div className="drawer-text-container3">
						<div className="drawer-info">
							<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
								<Grid item xs={1}>
									<InfoOutlinedIcon />
								</Grid>
								<Grid item xs={10}>
									<span>Survey Count is low due to lack of response from customers</span>
								</Grid>
							</Grid>
						</div>
					</div>
				</>
			)}
			{widgetName === 'COMPARATIVE_CALL_TRENDS' && (
				<>
					<div className="drawer-text-container">
						<div className="drawer-info">
							<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
								<Grid item xs={1}>
									<InfoOutlinedIcon />
								</Grid>
								<Grid item xs={8}>
									<span>Unanswered call have been increased due to unavailablity of agents</span>
									<div>
										<Button className={classes.addagentsButton}>Add agents</Button>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</>
			)}
			{widgetName === 'OUTBOUND_CALL_CAMPAIGN' && (
				<>
					<div className="drawer-text-container">
						<div className="drawer-info">
							<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
								<Grid item xs={1}>
									<InfoOutlinedIcon />
								</Grid>
								<Grid item xs={8}>
									<span>Change campaign priorities</span>
									<div>
										<Button className={classes.addagentsButton}>Campaign config</Button>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</>
			)}
			{open && (
				<div>
					<Dialog open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
						<DialogTitle id="alert-dialog-title">
							<IconButton
								aria-label="close"
								onClick={handleClose}
								sx={{
									position: 'absolute',
									right: 8,
									top: 8
								}}
							>
								<CloseIcon />
							</IconButton>
							Notification to {alertSelectedUser}
							<div style={{ color: '#686868', fontSize: '12px' }}>You can customize this notification</div>
						</DialogTitle>
						<DialogContent dividers>
							<DialogContentText id="alert-dialog-description">
								{isInput ? (
									<TextareaAutosize aria-label="empty textarea" style={{ width: 458, height: 56, background: '#F8F8F8', borderRadius: '5px', resize: 'none' }} />
								) : (
									'Need to improve ASA and AHT'
								)}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							{isInput ? (
								<Button className={classes.customiseButton} variant="outlined" onClick={handleCancel}>
									Cancel
								</Button>
							) : (
								<Button className={classes.customiseButton} variant="outlined" onClick={handleCustom}>
									Customize
								</Button>
							)}
							<Button className={classes.sendButton} onClick={handleClose}>
								<span style={{ color: 'white' }}>Send</span>
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			)}
		</Drawer>
	);
};

export default BreakDownDrawer;
