import { Box, Chip, Grid, Stack } from '@mui/material';
import { FILTER_LABELS } from '../../utils/constants';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { makeStyles } from '@mui/styles';

type Props = {
	maxItems?: any;
	selectedFilters?: any;
	onDelete?: any;
};

const useStyles: any = makeStyles(() => ({
	filterNumbers: {
		backgroundColor: '#008EFF !important',
		color: '#FFFFFF !important'
	}
}));

const FilterItems = ({ maxItems = 3, selectedFilters, onDelete }: Props) => {
	const classes = useStyles();
	const handleFilterDelete = (item: any) => {
		if (onDelete) {
			onDelete(item);
		}
	};

	let filterItems;
	if (Array.isArray(selectedFilters)) {
		filterItems = (
			<>
				{selectedFilters.slice(0, maxItems).map((item: any, index: number) => (
					<Chip
						key={index}
						label={item.label ? item.label : item.name || item}
						color="primary"
						variant="outlined"
						onDelete={() => handleFilterDelete(item)}
						deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />}
					/>
				))}
				{selectedFilters.length > maxItems && <Chip label={`+${selectedFilters.length - maxItems}`} color="primary" variant="filled" className={classes.filterNumbers} />}
			</>
		);
	} else {
		filterItems = Object.keys(selectedFilters).map((key: any) => {
			if (Array.isArray(selectedFilters[key])) {
				return <Chip key={key} label={`${FILTER_LABELS[key] || key.toUpperCase()}(${selectedFilters[key].length})`} color="primary" variant="outlined" />;
			} else if (typeof selectedFilters[key] === 'string') {
				return <Chip key={key} label={`${FILTER_LABELS[key] || key.toUpperCase()}(${selectedFilters[key]})`} color="primary" variant="outlined" />;
			} else {
				return <Chip key={key} label={`${FILTER_LABELS[key] || key.toUpperCase()}(${selectedFilters[key].name})`} color="primary" variant="outlined" />;
			}
		});
	}

	return (
		<Box className="customTabPanel">
			<Grid container>
				<Grid item xs={12}>
					<div className="filter-outer">
						<div className="filter-container">
							<Stack direction="row" spacing={1}>
								{filterItems}
							</Stack>
						</div>

						{/* <div className="filter-remove" onClick={clearFilter}>
 							Remove
 						</div> */}
					</div>
				</Grid>
			</Grid>
		</Box>
	);
};
export default FilterItems;
