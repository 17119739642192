import * as React from 'react';
import { Badge, Button, Grid, Box, Stack , Chip} from '@mui/material';
import LightDarkSwitch from '../../components/LightDarkSwitch/LightDarkSwitch';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { WIDGET_REFRESH_INTERVAL } from '../../utils/constants';
import '../Wallboard/Wallboard.css';
import '../../components/widgets/TopWidget/TopWidget.css';
import WallboardSkillTable from '../../components/WallboardSkillTable/WallboardSkillTable';
import WallboardWidgetsSidebar from '../../components/WallboardWidgetsSidebar/WallboardWidgetsSidebar';
import { agentStateCounts, callCounts, getFilters, getQueueCounterForTvMonitor, getTickerData, top3Agents, top5Skills } from '../../services/api-service';
import { useRef, useState, useEffect } from 'react';
import { secondsToTime } from '../../utils/util';
import FilterDrawer from '../../components/FilterDrawer/FilterDrawer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const downIcon = <img src={`${process.env.PUBLIC_URL}/icons/up-green-arrow.svg`} alt="red arrow" style={{ height: 12, width: 12 }} />;
const upIcon = <img src={`${process.env.PUBLIC_URL}/icons/down-red-arrow.svg`} alt="green arrow" />;

type props = {
	wallboardOnEdit: boolean;
};

export default function Wallboard({ wallboardOnEdit }: props) {
	const [mode, setMode] = useState(false);
	const isEditing = wallboardOnEdit;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [hideWidget, setHideWidget] = useState({
		CALLS_IN_QUEUE: false,
		LONGEST_WAIT_TIME: false,
		SKILL_TABLE: false,
		TOTAL_AGENTS: false,
		TOTAL_CALLS: false,
		READY_PAUSE: false,
		TOP_AGENTS: false
	});
	const [agentStateList, setAgentStateList] = useState<any>({});
	const [callCountsObj, setCallCountsObj] = useState<any>({});
	const [top3AgentsList, setTop3AgentsList] = useState<any>([]);
	const [topTickerData, setTopTickerData] = useState<any>([]);
	const [longestWaitTime, setLongestWaitTime] = useState<any>('');
	const [queueCount, setQueueCount] = useState<any>({});
	const [wallBoardFilter, setWallBoardFilter] = useState<Array<any>>([]);
	const [filterType, setFilterType] = useState<any>('1');
	const [isFilterDrawerOpen, setFilterIsDrawerOpen] = useState<boolean | undefined>(false);

	const wallboardContainer: any = useRef(null);
	const showSectionOne = !hideWidget.CALLS_IN_QUEUE || !hideWidget.LONGEST_WAIT_TIME || !hideWidget.SKILL_TABLE;
	const showSectionTwo = !hideWidget.TOTAL_CALLS || !hideWidget.TOTAL_AGENTS;
	const [isFullScreen, setIsFullScreen] = useState(false);

	const allWidgetsVisible =
		!hideWidget.CALLS_IN_QUEUE &&
		!hideWidget.LONGEST_WAIT_TIME &&
		!hideWidget.SKILL_TABLE &&
		!hideWidget.TOTAL_AGENTS &&
		!hideWidget.TOTAL_CALLS &&
		!hideWidget.READY_PAUSE &&
		!hideWidget.TOP_AGENTS;

	const addWidgetClass = allWidgetsVisible ? 'uneditable-button' : '';

	const getTickerIcon = (value: number) => {
		if (+value < 0) {
			return `${process.env.PUBLIC_URL}/icons/down-red-arrow.svg`;
		}
		return `${process.env.PUBLIC_URL}/icons/up-green-arrow.svg`;
	};

	const showMonitorTicker = () => {
		// const percentNumber = parseInt(row.percent.slice(1, 3));
		// const percentColor = percentNumber > 0 ? 'percent-green' : 'percent-red';
		// const statsIcon = percentNumber > 0 ? 'down-red-arrow' : 'up-green-arrow';
		return (
			<>
				<Grid item className="grid-border-item">
					<div className="wallboard-grid-item-container">
						<p className="wallboard-grid-item-heading">SLA :</p>
						<p className="percent down-red-arrow">{topTickerData.SLAPerc} %</p>
						<img src={getTickerIcon(topTickerData.SLAPerc)} alt="green arrow" style={{ marginRight: 78 }} />
					</div>
				</Grid>
				<Grid item className="grid-border-item">
					<div className="wallboard-grid-item-container">
						<p className="wallboard-grid-item-heading">ASA :</p>
						<p className="percent down-red-arrow">{topTickerData.ASA} %</p>
						<img src={getTickerIcon(topTickerData.ASA)} alt="green arrow" style={{ marginRight: 78 }} />
					</div>
				</Grid>
				<Grid item className="grid-border-item">
					<div className="wallboard-grid-item-container">
						<p className="wallboard-grid-item-heading">Abandoned :</p>
						<p className="percent down-red-arrow">{topTickerData.AbandonedPerc} %</p>
						<img src={getTickerIcon(topTickerData.AbandonedPerc)} alt="green arrow" style={{ marginRight: 78 }} />
					</div>
				</Grid>
				<Grid item className="grid-border-item">
					<div className="wallboard-grid-item-container">
						<p className="wallboard-grid-item-heading">Skill queue :</p>
						<p className="percent down-red-arrow">{topTickerData.QueuePerc} %</p>
						<img src={getTickerIcon(topTickerData.QueuePerc)} alt="green arrow" style={{ marginRight: 78 }} />
					</div>
				</Grid>
			</>
		);
	};

	const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
		const widgetData: any = JSON.parse(event.dataTransfer?.getData('widgetData') || '{}');
		if (!widgetData.widgetName) {
			return;
		}
		const newHiddenWidgets: any = { ...hideWidget };
		newHiddenWidgets[widgetData.widgetName] = false;
		setHideWidget(newHiddenWidgets);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleSetMode = (boolValue: boolean) => {
		setMode(boolValue);
	};

	const removeCard = (widgetName: string) => {
		const newHideWidgetStatus: any = { ...hideWidget };
		newHideWidgetStatus[widgetName] = true;
		setHideWidget(newHideWidgetStatus);
	};

	// //------------this is done when fullScreen is there--------------- //
	useEffect(() => {
		let intervalId : any;
		if(isFullScreen){
			intervalId = setInterval(() => {
				// console.log('now i am running');
				// setHideFiltericon(true);
			}, 2000);
		}else{
			// setHideFiltericon(false);
		}

		return () => clearInterval(intervalId);
	}, [isFullScreen]);


	const handleFullScreenChange = () => {
        const fullScreenElement = document.fullscreenElement;
        setIsFullScreen(!!fullScreenElement);
    };


	useEffect(() => {
        // Add event listener for full screen change
        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitRequestFullscreen', handleFullScreenChange);
        document.addEventListener('msRequestFullscreen', handleFullScreenChange);

        // Clean up function to remove event listener when the component unmounts
        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitRequestFullscreen', handleFullScreenChange);
            document.removeEventListener('msRequestFullscreen', handleFullScreenChange);
        };
    }, []);

	// //------------this is done when fullScrenn is there--------------- //

	// const handleFullScreen = () => {
	// 	const element = wallboardContainer?.current;
	// 	if(element){
	// 		if(document.fullscreenElement === null){
	// 			console.log('heere i cam eto make it full');
	// 			setHideFiltericon(true);
	// 			if (element.requestFullscreen) {
	// 				element.requestFullscreen();
	// 			} else if (element.webkitRequestFullscreen) {
	// 				/* Safari */
	// 				element.webkitRequestFullscreen();
	// 			} else if (element.msRequestFullscreen) {
	// 				/* IE11 */
	// 				element.msRequestFullscreen();
	// 			}
	// 		}else{
	// 			setHideFiltericon(false);
	// 			console.log('Screen is already full.');
	// 		}
	// 	}
	// };

	const handleFullScreen = () => {
		const element = wallboardContainer?.current;
		if (element.requestFullscreen) {
			element.requestFullscreen();
		} else if (element.webkitRequestFullscreen) {
			/* Safari */
			element.webkitRequestFullscreen();
		} else if (element.msRequestFullscreen) {
			/* IE11 */
			element.msRequestFullscreen();
		}
	};
	const handleAddWidget = () => {
		if (allWidgetsVisible) {
			setIsDrawerOpen(false);
		} else {
			setIsDrawerOpen(true);
		}
	};

	const getAgentStateCountApi = async (filters = '0') => {
		const agentStateData: any = await agentStateCounts({ filters, type: filterType});
		setAgentStateList(agentStateData[0]);
	};

	const getCallCountsApi = async (filters = '0') => {
		const callCountsdata: any = await callCounts({filters, type: filterType});
		setCallCountsObj(callCountsdata[0]);
	};

	const getTop5SkillsApi = async (filters = '0') => {
		const top5SkillsData: any = await top5Skills({filters, type: filterType});
		let highestLongestWaitTimeObj = top5SkillsData.reduce((maxObj: any, currentObj: any) => {
			if (parseFloat(currentObj.LongestWaitTime) > parseFloat(maxObj.LongestWaitTime)) {
				return currentObj;
			}
			return maxObj;
		}, top5SkillsData[0]);
		let highestLongestWaitTime = secondsToTime(parseFloat(highestLongestWaitTimeObj?.LongestWaitTime));
		setLongestWaitTime(highestLongestWaitTime);
	};

	const getQueueCount = async (filters = '0') => {
		const data: any = await getQueueCounterForTvMonitor({ filters, type: filterType, ChannelType:'0' });
		setQueueCount(data?.length ? data[0] : 0);
	};

	const getTop3AgentsApi = async (filters = '0') => {
		const top3AgentsData: any = await top3Agents({filters, type: filterType});
		setTop3AgentsList(top3AgentsData);
	};
	const getTickerPercentage = (value: number, lastValue: number) => {
		if (+value && +lastValue) {
			return +(((+value - +lastValue) / +lastValue) * 100).toFixed(2);
		}
		return 0;
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = wallBoardFilter.filter((item: any) => item.id !== id);
		setWallBoardFilter(newFilter);
	};

	const filterItems = wallBoardFilter.map((item: any, index: number) => {
		return (
			mode ?
				<Chip key={index} label={item.label} color="primary" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} /> 
				:
				<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
		);
	});

	const clearFilter = () => {
		console.info('Clear all filter.');
		setWallBoardFilter([]);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType }: any) => {
		setWallBoardFilter(selectedFilters);
		setFilterType(newFilterType);
		toggleDrawerFilter(false);
	};

	const toggleDrawerFilter = (isOpen: boolean) => {
		setFilterIsDrawerOpen(isOpen);
	};

	const getMoniterTickerData = async (filters = '0') => {
		const [tickerData = {}]: any = (await getTickerData({filters, type: filterType})) || [];
		const [historicTickerData = {}]: any = (await getTickerData({ filters, dateType: 1, type: filterType })) || [];
		tickerData.SLAPerc = getTickerPercentage(tickerData?.SLAPerc, historicTickerData?.SLAPerc);
		tickerData.AbandonedPerc = getTickerPercentage(tickerData?.AbandonedPerc, historicTickerData?.AbandonedPerc);
		tickerData.QueuePerc = getTickerPercentage(tickerData?.QueuePerc, historicTickerData?.QueuePerc);
		tickerData.ASA = getTickerPercentage(tickerData?.ASA, historicTickerData?.ASA);
		setTopTickerData(tickerData);
	};

	const getWidgetData = (filterIds: any) => {
		getAgentStateCountApi(filterIds);
		getCallCountsApi(filterIds);
		getTop3AgentsApi(filterIds);
		getMoniterTickerData(filterIds);
		getTop5SkillsApi(filterIds);
		getQueueCount(filterIds);
	};

	useEffect(() => {
		const filterIds = wallBoardFilter.length ? wallBoardFilter.map((item: any) => item.id).join(',') : '0';
		getWidgetData(filterIds);
		const interval: any = setInterval(() => {
			getWidgetData(filterIds);
		}, WIDGET_REFRESH_INTERVAL.WALLBOARD);
		return () => clearInterval(interval);
	}, [wallBoardFilter]);

	const toggleClass = mode
		? {
			wallboardBgColor: 'wallboard-container-dark',
			cardContainerColor: 'container-dark',
			subContainerColor: 'red-container',
			tableHeaderBg: 'wallboard-table-head-dark',
			tableHeadColor: 'table-head-color-dark',
			rowCellColor: 'row-cell-color-dark',
			badge: {
				gold: 'gold-badge-dark.svg',
				silver: 'silver-badge-dark.svg',
				bronze: 'bronze-badge-dark.svg'
			},
			goldBadgeHolder: 'gold-badge-holder-dark',
			topWidget: 'wallboard-top-widget-container-dark',
			topWidgetHead: ''
		}
		: {
			wallboardBgColor: 'wallboard-container-light',
			cardContainerColor: 'container-light',
			subContainerColor: 'rose-container',
			tableHeaderBg: 'wallboard-table-head-light',
			tableHeadColor: 'table-head-color-light',
			rowCellColor: 'row-cell-color-light',
			badge: {
				gold: 'gold-badge-light.svg',
				silver: 'silver-badge-light.svg',
				bronze: 'bronze-badge-light.svg'
			},
			goldBadgeHolder: '',
			topWidget: ''
		};

	return (
		<>
			<Grid container direction="row" justifyContent="space-between" alignItems="center" className="tv-monitor-container">
				<Grid item>
					<span className="tv-monitor-heading">TV Monitor</span>
				</Grid>
				<Grid item>
					{isEditing ? (
						<>
							<Button className={addWidgetClass} variant="outlined" startIcon={<WidgetsOutlinedIcon />} onClick={handleAddWidget}>
								Add Widget
							</Button>
							<Button variant="contained" style={{ marginLeft: '12px' }}>
								Save
							</Button>
						</>
					) : (
						<>
							<Button onClick={handleFullScreen} variant="outlined" startIcon={<FullscreenIcon />}>
								Full Screen
							</Button>
						</>
					)}
				</Grid>
			</Grid>
			<WallboardWidgetsSidebar isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} hiddenWidgets={hideWidget}></WallboardWidgetsSidebar>
			<div ref={wallboardContainer} className={`wallboard-container ${toggleClass.wallboardBgColor}`}>
				<Grid container justifyContent="flex-end">
					<Grid item>
						<LightDarkSwitch mode={mode} handleSetMode={handleSetMode} />
					</Grid>
					{!isFullScreen &&
					<div className='filter-icon' onClick={() => toggleDrawerFilter(true)}>
						<Badge badgeContent={wallBoardFilter.length} color="primary">
							<img
                                src={mode ? `${process.env.PUBLIC_URL}/icons/filter_dark_mode.svg` : `${process.env.PUBLIC_URL}/icons/filter.svg`}
                                alt="edit"
                            />
						</Badge>
					</div>
					}
				</Grid>
				<FilterDrawer isOpen={isFilterDrawerOpen} toggleDrawer={toggleDrawerFilter} applyFilters={handleApplyFilters} allowedFilters={['Campaign','Skill']} existingSelectedFilters={wallBoardFilter} />
				<Box sx={{width: '100%', typography: 'body1' }}>
					{wallBoardFilter.length > 0 && (
						<Box className='customTabPanel'>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className="filter-outer-wallboard">
										<div className={mode ? "filter-container-wallboard-dark" : "filter-container-wallboard"}>
											<Stack direction="row" spacing={1}>
												{filterItems}
											</Stack>
										</div>
										<div className={mode ? "filter-remove-dark" : "filter-remove"} onClick={clearFilter}>
											Remove
										</div>
									</div>
								</Grid>
							</Grid>
						</Box>
					)}
				</Box>

				<Grid item xs={12} style={{ paddingTop: 10, marginRight: 23 }}>
					<Grid container direction="row" alignItems="center" className={`wallboard-top-widget-container ${toggleClass.topWidget}`}>
						<Grid item className="img-border">
							{upIcon}
							{downIcon}
						</Grid>
						<Grid item className="stats-height" style={{ flexGrow: 1, alignItems: 'center' }}>
							<Grid container direction="row" justifyContent="space-around" style={{ marginTop: 4 }}>
								{showMonitorTicker()}
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid className="wallboard-widgets-grid" container onDrop={handleOnDrop} onDragOver={(e) => e.preventDefault()} direction="row" style={{ paddingTop: 21 }}>
					{showSectionOne && (
						<Grid item xs={4.7}>
							<Grid container direction="column">
								<Grid item xs={12}>
									{!hideWidget['CALLS_IN_QUEUE'] && (
										<Grid container alignItems="center" className={`${toggleClass.cardContainerColor} calls-queue-container`} style={{ marginBottom: 21, position: 'relative' }}>
											{isEditing && (
												<Grid item className="wallboard-delete-icon">
													<img src={`${process.env.PUBLIC_URL}/icons/wallboard-delete-icon.svg`} alt="remove" onClick={() => removeCard('CALLS_IN_QUEUE')} />
												</Grid>
											)}
											<Grid item className="queue-icon-container" justifyContent="center" alignItems="center">
												<img src={`${process.env.PUBLIC_URL}/icons/queue-icon.svg`} alt="queue" width="48" height="56" />
											</Grid>
											<Grid item className="card-content-container">
												<span className="all-cards-heading">Calls in Queue</span>
												{/* <span className="all-cards-count">{queueCount || '-'}</span> */}
												<span className="all-cards-count">{queueCount?.CallsInQueue || '-'}</span>
											</Grid>
										</Grid>
									)}

									{!hideWidget['LONGEST_WAIT_TIME'] && (
										<Grid container alignItems="center" className={`${toggleClass.cardContainerColor} calls-queue-container`} style={{ position: 'relative', marginBottom: 21 }}>
											{isEditing && (
												<Grid item className="wallboard-delete-icon">
													<img src={`${process.env.PUBLIC_URL}/icons/wallboard-delete-icon.svg`} alt="remove" onClick={() => removeCard('LONGEST_WAIT_TIME')} />
												</Grid>
											)}
											<Grid item className="queue-icon-container" justifyContent="center" alignItems="center">
												<img src={`${process.env.PUBLIC_URL}/icons/wait-time-icon.svg`} alt="queue" width="48" height="56" />
											</Grid>
											<Grid item className="card-content-container">
												<span className="all-cards-heading">Longest wait time</span>
												<span className="all-cards-count">{longestWaitTime || '-'}</span>
											</Grid>
										</Grid>
									)}
								</Grid>
								<Grid item xs={12}>
									{!hideWidget['SKILL_TABLE'] && (
										<Grid container style={{ position: 'relative' }} className={`${toggleClass.cardContainerColor} table-main-container`}>
											{isEditing && (
												<Grid item className="wallboard-delete-icon">
													<img src={`${process.env.PUBLIC_URL}/icons/wallboard-delete-icon.svg`} alt="remove" onClick={() => removeCard('SKILL_TABLE')} />
												</Grid>
											)}
											<Grid item xs={12}>
												<span className="table-heading">Top 5 Calls in Queue</span>
											</Grid>
											<Grid item xs={11.5} style={{ marginTop: 8, position: 'absolute', top: 55, left: 20 }}>
												<WallboardSkillTable toggleClass={toggleClass} />
											</Grid>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					)}

					{showSectionTwo && (
						<Grid item xs={3.5} style={{ marginLeft: 26 }}>
							<Grid container direction="column">
								{!hideWidget['TOTAL_AGENTS'] && (
									<Grid item xs={12} className={`${toggleClass.cardContainerColor} total-agents-main-container`} style={{ marginBottom: 21, height: 284.62, position: 'relative' }}>
										{isEditing && (
											<Grid item className="wallboard-delete-icon">
												<img src={`${process.env.PUBLIC_URL}/icons/wallboard-delete-icon.svg`} alt="remove" onClick={() => removeCard('TOTAL_AGENTS')} />
											</Grid>
										)}
										<Grid container alignItems="center" className="total-agents-container">
											<Grid item className="queue-icon-container" justifyContent="center" alignItems="center">
												<img src={`${process.env.PUBLIC_URL}/icons/total-agents-icon.svg`} alt="queue" width="48" height="56" />
											</Grid>
											<Grid item className="card-content-container">
												<span className="all-cards-heading">Total Agents</span>
												<span className="all-cards-count">{agentStateList?.TotalAgents || '-'}</span>
											</Grid>
										</Grid>
										<Grid container className="sub-main-container">
											<Grid item className="logged-in-container" xs={5.5}>
												<span className="all-cards-heading">Logged In</span>
												<span className="all-cards-count">{agentStateList?.LoggedIn || '-'}</span>
											</Grid>
											<Grid item className={`logged-in-container ${toggleClass.subContainerColor}`} xs={6.5}>
												<span className="all-cards-heading">Logged Out</span>
												<span className="all-cards-count">{agentStateList?.LoggedOut || '-'}</span>
											</Grid>
										</Grid>
									</Grid>
								)}
								{!hideWidget['TOTAL_CALLS'] && (
									<Grid item xs={12} className={`${toggleClass.cardContainerColor} total-agents-main-container`} style={{ height: 329.09, position: 'relative' }}>
										{isEditing && (
											<Grid item className="wallboard-delete-icon">
												<img src={`${process.env.PUBLIC_URL}/icons/wallboard-delete-icon.svg`} alt="remove" onClick={() => removeCard('TOTAL_CALLS')} />
											</Grid>
										)}

										<Grid container alignItems="center" className="total-agents-container">
											<Grid item className="queue-icon-container" justifyContent="center" alignItems="center">
												<img src={`${process.env.PUBLIC_URL}/icons/total-calls-icon.svg`} alt="queue" width="48" height="56" />
											</Grid>
											<Grid item className="card-content-container">
												<span className="all-cards-heading">Total Calls</span>
												<span className="all-cards-count">{callCountsObj?.TotalCalls || '-'}</span>
											</Grid>
										</Grid>
										<Grid container className="sub-main-container" style={{ height: 185.25 }}>
											<Grid item className="logged-in-container" xs={5.5}>
												<span className="all-cards-heading">Answered</span>
												<span className="all-cards-count">{callCountsObj?.Answered || '-'}</span>
											</Grid>
											<Grid item className={`logged-in-container ${toggleClass.subContainerColor}`} xs={6.5}>
												<span className="all-cards-heading">Abandoned</span>
												<span className="all-cards-count">{callCountsObj?.Abandoned || '-'}</span>
											</Grid>
										</Grid>
									</Grid>
								)}
							</Grid>
						</Grid>
					)}

					<Grid item xs={3} style={{ marginLeft: 26 }}>
						<Grid container direction="column">
							{!hideWidget['READY_PAUSE'] && (
								<Grid item className={`${toggleClass.cardContainerColor} total-agents-main-container ready-pause-main-container`} style={{ marginBottom: 21, position: 'relative' }}>
									{isEditing && (
										<Grid item className="wallboard-delete-icon">
											<img src={`${process.env.PUBLIC_URL}/icons/wallboard-delete-icon.svg`} alt="remove" onClick={() => removeCard('READY_PAUSE')} />
										</Grid>
									)}
									<Grid container className="ready-pause-container">
										<Grid item className="ready-container">
											<span className="all-cards-heading">Ready</span>
											<span className="all-cards-count">{agentStateList?.Ready || '-'}</span>
										</Grid>
										<Grid item className="ready-container">
											<span className="all-cards-heading">Pause</span>
											<span className="all-cards-count">{agentStateList?.Pause || '-'}</span>
										</Grid>
									</Grid>
									<Grid container className="ready-pause-container">
										<Grid item className="ready-container">
											<span className="all-cards-heading">Busy</span>
											<span className="all-cards-count">{agentStateList?.Busy || '-'}</span>
										</Grid>
										<Grid item className="ready-container">
											<span className="all-cards-heading">ACW</span>
											<span className="all-cards-count">{agentStateList?.ACW || '-'}</span>
										</Grid>
									</Grid>
								</Grid>
							)}
							{!hideWidget['TOP_AGENTS'] && (
								<Grid item className={`${toggleClass.cardContainerColor} total-agents-main-container`} style={{ height: 335, position: 'relative' }}>
									{isEditing && (
										<Grid item className="wallboard-delete-icon">
											<img src={`${process.env.PUBLIC_URL}/icons/wallboard-delete-icon.svg`} alt="remove" onClick={() => removeCard('TOP_AGENTS')} />
										</Grid>
									)}
									<Grid item xs={12} className="top-agents-heading">
										<span className="top-agent-style">Top Agents</span>
									</Grid>
									<Grid container className="top-agents-names-container">
										<Grid item className="agent-name-container" xs={12}>
											<img src={`${process.env.PUBLIC_URL}/icons/${toggleClass.badge.gold}`} alt="gold" />
											<span className={`gold-badge-holder ${toggleClass.goldBadgeHolder}`}>{top3AgentsList[0]?.Agent || '-'}</span>
										</Grid>
										<Grid item className="agent-name-container" xs={12}>
											<img src={`${process.env.PUBLIC_URL}/icons/${toggleClass.badge.silver}`} alt="silver" />
											<span className={`gold-badge-holder ${toggleClass.goldBadgeHolder}`}>{top3AgentsList[1]?.Agent || '-'}</span>
										</Grid>
										<Grid item className="agent-name-container" xs={12}>
											<img src={`${process.env.PUBLIC_URL}/icons/${toggleClass.badge.bronze}`} alt="bronze" />
											<span className={`gold-badge-holder ${toggleClass.goldBadgeHolder}`}>{top3AgentsList[2]?.Agent || '-'}</span>
										</Grid>
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</div>
		</>
	);
}
