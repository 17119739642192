import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Badge, Box, Card, Chip, Divider, Grid, Menu, MenuItem, Stack, Theme } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { getSkillSummary } from '../../../services/api-service';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import { makeStyles } from '@mui/styles';

const useStyles: any = makeStyles((theme: Theme) => ({
	legend: {
		width: '100%',
		height: '45px',
		padding: '0 15px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginLeft: '500px'
	},
	subheaderText: {
		color: '#99A0A8',
		marginLeft: '50px'
	},
	subheaderNumber: {
		fontWeight: '500',
		fontSize: '18px',
		color: ' #212121',
		marginLeft: '10px'
	},
	subHeader: {
		marginTop: '15px',
		marginBottom: '25px'
	}
}));

function FeedbackSurvey() {
	const { useState, useRef } = React;
	const downloadContent = useRef<null | HTMLElement>(null);
	const chartElement: any = useRef(null);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [feedbackSurveyFilter, setFeedbackSurveyFilter] = useState<any>([]);
	const [filterType, setFilterType] = useState<any>('1');
	const [duration, setDuration] = useState<string>('Year');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const clearFilter = () => {
		setFeedbackSurveyFilter([]);
	};

	const classes = useStyles();

	const handleFilterDelete = (id: Number) => {
		const newFilter = feedbackSurveyFilter.filter((item: any) => item.id !== id);
		setFeedbackSurveyFilter(newFilter);
	};

	const options = {
		title: '',
		credits: false,
		yAxis: {
			title: {
				text: 'NPS %'
			}
		},

		xAxis: {
			title: {
				text: ' Month ➡',
				style: { fontWeight: 'bold' }
			},
			categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
		},

		legend: false,

		plotOptions: {
			series: {
				label: {
					connectorAllowed: false
				}
			}
		},

		series: [
			{
				data: [27.6, 28.8, 21.7, 34.1, 29.0, 28.4, 45.6, 51.7, 39.0, 60.0, 28.6, 32.1]
			}
		]
	};

	const filterItems = feedbackSurveyFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const retrieveTableData = async (filters = '0') => {
		const rows: any[] = await getSkillSummary({ filters, type: filterType });
		const columns = [
			{ name: 'Agent', key: 'Agent' },
			{ name: 'Skills', key: 'SkillName' },
			{ name: 'CSAT', key: 'CSAT' },
			{ name: 'ASA', key: 'AverageSpeedToAnswer' },
			{ name: 'AHT', key: 'AverageHandlingTime' },
			{ name: 'Avg. handling time', key: 'AverageHandlingTime' },
			{ name: 'Interactions', key: 'Interactions' },
			{ name: 'Responses', key: 'Responses' }
		];
		setRowData(rows);
		setColumnData(columns);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	React.useEffect(() => {
		const filterIds = feedbackSurveyFilter.length ? feedbackSurveyFilter.map((item: any) => item.id).join(',') : '0';
		retrieveTableData(filterIds);
	}, [feedbackSurveyFilter]);

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType }: any) => {
		setFeedbackSurveyFilter(selectedFilters);
		setFilterType(newFilterType);
		toggleDrawer(false);
	};
	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		if (event.currentTarget) setAnchorEl(event.currentTarget);
	};
	const open = Boolean(anchorEl);

	const handleDuration = (type: string = '') => {
		if (type) {
			setDuration(type);
		}
		setAnchorEl(null);
	};

	return (
		<>
			<Card className="customCard">
				<div className="card-header">
					<div className="card-title">NPS Stats</div>
					<div className="card-actions">
						<div>
							<div onClick={handleMenuOpen}>
								{duration}
								<KeyboardArrowDownOutlinedIcon style={{ color: '#536580', fontSize: '10px' }} />
							</div>
							<Menu
								id="download-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right'
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
								open={open}
								onClose={() => handleDuration()}
								className="custom-dropdown"
							>
								<MenuItem className="download-item" onClick={() => handleDuration('Year')}>
									Year
								</MenuItem>
								<MenuItem className="download-item" onClick={() => handleDuration('Yesterday')}>
									Yesterday
								</MenuItem>
								<MenuItem className="download-item" onClick={() => handleDuration('Same day last week')}>
									Same day last week
								</MenuItem>
							</Menu>
						</div>
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={'NPS Stats'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={feedbackSurveyFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
					</div>
				</div>
				<div className={classes.legend}>
					<Grid container direction="row" alignItems="center">
						<Grid item xs={0.5} className="legend-icon">
							<span className="legend-icon-hr"></span>
						</Grid>
						<Grid item xs={0.5}>
							<span>NPS%</span>
						</Grid>
						<Grid item xs={0.6} className="legend-icon">
							<div className="legend-icon-dottedlines"></div>
						</Grid>
						<Grid item xs={0.6}>
							Target
						</Grid>
					</Grid>
				</div>
				<Divider></Divider>
				<div className={classes.subHeader}>
					<span className={classes.subheaderText}>Total NPS</span>
					<span className={classes.subheaderNumber}>77%</span>
				</div>
				<Box ref={downloadContent} sx={{ width: '100%', typography: 'body1' }}>
					{feedbackSurveyFilter.length > 0 && (
						<Box className="customTabPanel">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className="filter-outer">
										<div className="filter-container">
											<Stack direction="row" spacing={1}>
												{filterItems}
											</Stack>
										</div>
										<div className="filter-remove" onClick={clearFilter}>
											Remove
										</div>
									</div>
								</Grid>
							</Grid>
						</Box>
					)}
					<HighchartsReact ref={chartElement} highcharts={Highcharts} options={options} />
				</Box>
			</Card>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={feedbackSurveyFilter} />
		</>
	);
}
export default FeedbackSurvey;
