import React, { useState, useMemo, useEffect } from "react";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import { Tooltip, Typography } from "@mui/material";
import { Button, Stack, Grid } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import GroupsForm from "./GroupsForm";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./Group.css";
import { getgroups, deletegroupById, getgroupById } from "../../../services/api-service";
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";

function Groups() {

  const NameRenderer = (props) => {
    return (
      <Tooltip title={(props?.data?.GroupName) ? props?.data?.GroupName : ''}>
        <span className='oz-group-name-container'>{props.value}</span>
      </Tooltip>
    );
  };

  const DescriptionRenderer = (props) => {
    return (
      <span className="oz-group-description-container">{props.value}</span>
    );
  };

  const NoOfMembersRenderer = (props) => {

    const [groupsAnchorEl, setGroupsAnchorEl] = React.useState(null);
    const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const anchorRef = React.useRef();
    const [openRenameDialog, setRenameDialog] = useState(false);
    const [groupItem, setGroupItem] = useState();

    const onClickMenu = () => {
      setTimeout(() => setGroupsAnchorEl(anchorRef?.current), 1);
      try {
        getgroupById(props.data.SeqID)
          .then((resp) => {
            setGroupItem(resp.Data[0]);
          })
          .catch((e) => {
            setGroupItem({});
          });
      } catch (e) {
        showErrorNotification("Error in group list ");
      }
    };

    const onMenuClose = () => {
      setGroupsAnchorEl(null);
    };

    const onClickDeleteDialogDelete = () => {
      deleteGroup(props.data.SeqID);
      setDeleteConfirmationDialog(false);
    };

    const onClickDeleteDialogClose = () => {
      setDeleteConfirmationDialog(false);
    };

    const onClickDelete = () => {
      setGroupsAnchorEl(null);
      setDeleteConfirmationDialog(true);
    };

    const onClickRename = () => {
      setGroupsAnchorEl(null);
      setRenameDialog(true);
    };

    const styleListItemButton = useMemo(
      () => ({
        "&.MuiButtonBase-root": {
          color: '#212121',
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          height: '16px',
          lineHeight: "14px",
        },
        '&:hover': {
          borderRadius: "4px",
        },
      }),
      []
    );

    return (
      <div className='oz-group-members-container' >
        <label>{props.value}</label>
        {/* {authPage(AdminPages.GROUPS_EDIT) && */}
        {(newAuthPages(NewAdminPages.GROUPS) || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Full')) &&
          <img className="groupsMorevertIcon"
            onClick={onClickMenu}
            src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
          />
        }
        <div ref={anchorRef}>
          <Menu anchorEl={groupsAnchorEl} open={Boolean(groupsAnchorEl)} onClose={onMenuClose} sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#FFFFFF",
              width: "133px",
              height: "62px",
            },
            "& .MuiMenu-paper": {
              backgroundColor: "#FFFFFF",
              boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
              border: "0.0625rem solid #E6E5E6",
              borderRadius: "12px",
              marginLeft: "-0.3625rem",
              marginTop: "0rem",
            },
          }}>
            <MenuItem onClick={onClickRename} sx={{ ...styleListItemButton, marginTop: "1px" }}>Rename Group </MenuItem>
            <MenuItem onClick={onClickDelete} sx={{ ...styleListItemButton, marginTop: '9px' }}>Delete Group</MenuItem>
          </Menu>
          {openDeleteConfirmationDialog &&
            <DeleteDialog
              title={"Delete Group"}
              content={" This will delete this " + props.data.GroupName + " group permanently. You cannot undo this action."}
              onClose={onClickDeleteDialogClose}
              open={true} deleteFunction={onClickDeleteDialogDelete} />
          }
          {openRenameDialog &&
            <GroupsForm onClose={() => { setRenameDialog(false) }} data={groupItem} mode={"Rename"} groupsCallBack={groupsCallBack} />
          }
        </div>
      </div>
    );
  };

  const [groupForm, setGroupForm] = useState({ isNew: false, show: false });
  const [groupItem, setGroupItem] = useState({});
  const [gridRef, setGridRef] = useState(null);
  const [groupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getGroupsList();
  }, []);

  async function getGroupsList() {
    setLoading(true);
    try {
      getgroups()
        .then((resp) => {
          setGroupList(resp.Data);
          setLoading(false);
        })
        .catch((e) => {
          setGroupList([]);
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in groups list");
      setLoading(false);
    }
  };

  async function deleteGroup(dataId) {
    try {
      deletegroupById(dataId)
        .then((resp) => {
          if (resp.Status === "Success") {
            showSuccessNotification(resp.Message);
            getGroupsList();
          }
          else {
            showWarningNotification(resp.Message);
          }
        })
        .catch((e) => {
          showErrorNotification("Error deleting group");
        });
    } catch (e) {
      showErrorNotification("Error in group delete");
    }
  };

  async function onClickRow(rowItem) {
    setGroupItem({});
    setGroupForm({ isNew: false, show: true });
    setLoading(true);
    try {
      getgroupById(rowItem.data.SeqID)
        .then((resp) => {
          setGroupItem(resp.Data[0]);
          setLoading(false);
        })
        .catch((e) => {
          setGroupItem({});
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in group list ");
      setLoading(false)
    }
  };

  const columnDefs = [
    {
      field: "GroupName",
      headerName: "Group Name",
      cellRenderer: NameRenderer,

    },
    { field: "GroupDescription", headerName: "Description", cellRenderer: DescriptionRenderer },
    {
      field: "AgentCount",
      headerName: "No. of Members",
      cellRenderer: NoOfMembersRenderer,
    },
  ];

  const showGroupsForm = () => {
    setGroupForm({ isNew: true, show: true });
  };

  const closeGroupsForm = () => {
    setGroupForm({ isNew: false, show: false });
  };

  function groupsCallBack(type, id) {
    if (type === "listFunction") {
      getGroupsList();
      if (id !== null) {
        try {
          setLoading(true);
          getgroupById(id)
            .then((resp) => {
              setGroupItem(resp.Data[0]);
              setLoading(false);
            })
            .catch((e) => {
              setGroupItem({});
              setLoading(false);
            });
        } catch (e) {
          showErrorNotification("Error in group list ");
          setLoading(false);
        }
      }
    }
    if (type === "deleteFunction") {
      deleteGroup(id);
    }
  };

  let buttonStyle = useMemo(
    () => ({
      borderRadius: "8px",
      height: "30px",
      fontSize: "14px",
      fontWeight: 400,
      width: "149px",
      backgroundcolor: "#3D8BF8",
      lineHeight: "16.41px",
    }),
    []
  );

  return (
    <div className="ozAdminGroupsContainer">
      <Grid sx={{ marginBottom: "10px" }}>
        <Typography className="oz-page-header" > People </Typography>
      </Grid>
      <Stack direction="row" sx={{ marginBottom: "16px" }} justifyContent="space-between">
        <Typography className="oz-page-heading"> Groups </Typography>
        {/* {authPage(AdminPages.GROUPS_EDIT) && */}
        {(newAuthPages(NewAdminPages.GROUPS) || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Full')) &&
          <Button
            sx={buttonStyle}
            variant="contained"
            startIcon={<AddBoxOutlinedIcon />}
            onClick={showGroupsForm}
          >
            Create Group
          </Button>
        }
      </Stack>
      {groupForm.show && groupForm.isNew && (<GroupsForm onClose={closeGroupsForm} mode={"Create"} groupsCallBack={groupsCallBack} />)}
      {/* {groupForm.show && !groupForm.isNew && (<GroupsForm data={groupItem || {}} onClose={closeGroupsForm} groupsCallBack={groupsCallBack} />)} */}
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        (
          <>{groupForm.show && !groupForm.isNew && (<GroupsForm data={groupItem || {}} onClose={closeGroupsForm} groupsCallBack={groupsCallBack} />)}</>
        )
      }
      <OZGridToolbar
        modifyHeaderName={['Description', 'No. of Members']}
        modifyHeaderNameLocation={['B1', 'C1']}
        // modifyHeaderName='No. of Members'
        // modifyHeaderNameLocation='C1'
        title='List of Groups'
        searchPlaceholder='Group Name'
        rowCount={groupList.length}
        dataGridRef={gridRef}
        fileName='Groups'
      />
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        (
          <OZDataGrid height="26.3rem" data={groupList} columns={columnDefs} rowClickCallback={onClickRow} setGridRef={setGridRef} />
        )
      }
    </div>
  );
};

export default Groups;