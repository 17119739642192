import React, { useState, UseEffect } from 'react';
import { Stack, Menu, MenuItem, Box, Button } from '@mui/material';
import AudioPlayer from '../../../AudioPlayer/AudioPlayer';

const AudioPlayerSeparateComp = ({ row, column, rowIdx }) => {
    const [anchorAudio, setAnchorAudio] = useState(null);
    const open = Boolean(anchorAudio);

    const handleClick = (event: any) => {
        setAnchorAudio(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorAudio(null);
    }


    const audioPlayerPopup = (index: any, url: any) => {
        return (
            index !== 0 && <MenuItem key={index}>
                <AudioPlayer url={url} idx={index + 1} />
            </MenuItem>
        )
    }

    return (
        <>
            <Stack minWidth={'fit-content'} sx={{}}>
                {Array.isArray(row[column.key]) ? (
                    row[column.key]?.length > 1 && (
                        <Box display={'flex'}>
                            <div style={{ marginRight: '12px' }}>
                                <AudioPlayer url={row[column.key][0]} idx={rowIdx} />
                            </div>
                            <Button size="small" variant="contained" onClick={handleClick}>
                                +{row[column.key].length - 1}
                            </Button>
                            <Menu anchorEl={anchorAudio} open={open} onClose={handleClose}>
                                {row[column.key]?.map(
                                    (url: any, index: any) =>
                                        audioPlayerPopup(index, url)
                                )}
                            </Menu>
                        </Box>
                    )
                ) : (
                    <AudioPlayer url={row[column.key]} idx={rowIdx} />
                )}
            </Stack>
        </>
    )
}

export default AudioPlayerSeparateComp;
