import React, { useEffect, useState } from 'react';
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import { Button, Tooltip } from '@mui/material';
import { enableClickToCall, getCampiagnsByPluginType } from '../../../services/api-service';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../../components/admin/common/NotiService';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';

export default function CampaingsIntegration() {

  const [campaingsIntegrationList, setCampaingsIntegrationList] = useState([]);
  const [loading, setLoading] = useState();

  const generateclick2callRenderer = (props) => {

    async function onClickGenerateClick2Call() {
      setLoading(true);
      try {
        enableClickToCall(props.data.campaignId)
          .then((resp) => {
            if (resp.Status === "Success") {
              showSuccessNotification(`ClickToCall Enabled for Campaign ${props.data.campaignName}`);
              setLoading(false);
            }
            else {
              showErrorNotification(`Error in  Enabling ClickToCall for Campaign ${props.data.campaignName}.`, resp.Message);
              setLoading(false);
            }
          })
          .catch((e) => {
            showErrorNotification(`Error in Enabling ClickToCall for Campaign ${props.data.campaignName}`);
            setLoading(false);
          });
      } catch (e) {
        showErrorNotification(`Error in  ClickToCall Enabling for Campaign ${props.data.campaignName}`);
        setLoading(false);
      }
    }

    return (
      <div className='editButtonPosition'>
        {/* <label>{props.value}</label> */}
        <Tooltip title={(props.value) ? props.value : ''}>
          <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", cursor: "pointer" }}>{props.value}</span>
        </Tooltip>
        <Button variant="text" onClick={onClickGenerateClick2Call} className="generateClick2CallButton">Generate Click2call</Button>
      </div>
    );
  };

  useEffect(() => {
    getCampaignList();
  }, []);

  const ColumnRenderer = (props) => {
    return (
      <Tooltip title={(props.value) ? props.value : ''}>
        <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", cursor: "pointer" }}>{props.value}</span>
      </Tooltip>
    );
  };

  const columnDefs = ([
    { field: 'campaignName', headerName: 'Campaign Name', maxWidth: 150, cellRenderer: ColumnRenderer },
    { field: 'did', headerName: 'DID', maxWidth: 150, cellRenderer: ColumnRenderer },
    { field: 'position', headerName: 'Positions', maxWidth: 100, cellRenderer: ColumnRenderer },
    { field: 'createdDate', headerName: 'Craeted On', minWidth: 200, cellRenderer: ColumnRenderer },
    { field: 'modifiedDate', headerName: 'Modified On', minWidth: 200, cellRenderer: ColumnRenderer },
    { field: 'campaignType', headerName: 'Campaign Type', cellRenderer: generateclick2callRenderer, minWidth: 350 },
  ]);

  async function getCampaignList() {
    try {
      getCampiagnsByPluginType("Zoho")
        .then((resp) => {
          if (resp.Status === "Success") {
            setCampaingsIntegrationList(resp.Data);
          }
          else {
            showWarningNotification(resp.Message);
          }
        })
        .catch((e) => {
          setCampaingsIntegrationList([]);
        });
    } catch (e) {
      showErrorNotification("Error in campaigns list");
    }
  };

  return (
    <div className="ozAdminSubIntegrationContainer">
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        <OZDataGrid height="26.3rem" data={campaingsIntegrationList} columns={columnDefs} />
      }
    </div>
  );
};