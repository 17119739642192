import { useMemo, useState } from 'react';
import * as yup from 'yup';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import { Formik } from 'formik';
import OZInput from '../../../components/admin/common/OZInput';
import { Button, Grid } from '@mui/material';
import { editIntegrations, saveIntegrations } from '../../../services/api-service';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';

const BaltoIntegration = (mainprops) => {

    console.log(mainprops);

    const validationSchema = useMemo(
        () =>
            yup.object({
                authToken: yup
                    .string()
                    .required("Auth Token is Required"),
            }),
        []
    );

    const [loading, setLoading] = useState(false);
    const [refreshForm, setRefreshForm] = useState(false);

    const onBtnDisconnect = () => {
        mainprops.setBaltoIntegration(false);
    };

    const handleCancel = () => {
        setRefreshForm(prevState => !prevState)
    };

    async function saveBalto(values) {

        setLoading(true)
        console.log(values)

        let data = {
            authToken: values.authToken,
            integrationName: "Balto"
        }
        try {
            saveIntegrations(data)
                .then((resp) => {
                    if (resp.Status === "success") {
                        showSuccessNotification("Balto Integration is Saved Successfully")
                        setLoading(false);
                        mainprops.getUserIntegrationsData()
                        mainprops.setBaltoIntegration(false)
                    }
                    else {
                        showErrorNotification("Error Saving Balto Integration ");
                        setLoading(false)
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in Saving Balto Integration ");
                    setLoading(false)
                    console.log(e)
                });
        } catch (e) {
            showErrorNotification("Error in Saving Balto Integration ");
            setLoading(false)
        }
    }

    async function editBalto(values) {

        setLoading(true);
        console.log(values);

        let changeInBaltoIntegrationData = {
            ...mainprops?.baltoData,
            authToken: values.authToken,
            integrationName: "Balto"
        }

        try {
            editIntegrations(changeInBaltoIntegrationData)
                .then((resp) => {
                    if (resp.Status === "success") {
                        showSuccessNotification("Balto Integration is Edited Successfully")
                        setLoading(false);
                        mainprops.getUserIntegrationsData()
                        mainprops.setBaltoIntegration(false)
                    }
                    else {
                        showErrorNotification("Error Editing Balto Integration");
                        setLoading(false)
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in Editing Balto Integration");
                    setLoading(false)
                    console.log(e)
                });
        } catch (e) {
            showErrorNotification("Error in Editing Balto Integration");
            setLoading(false)
        }
    }


    const DataForm = () => {
        let initData = {
            authToken: (mainprops?.baltoData !== null && mainprops?.baltoData?.authToken !== null && mainprops?.baltoData?.authToken !== undefined) ? mainprops.baltoData.authToken : '',
        }

        console.log(initData)

        return (
            <div>
                <Formik
                    key={refreshForm}
                    initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (mainprops?.baltoData === null) {
                            console.log(values)
                        }
                        else {
                            console.log(values)
                        }
                    }}
                >
                    {
                        (props) => (
                            <div className="oz-admin-integration-Idesk-page">
                                <Grid sx={{ marginBottom: "10px" }}>
                                    <div onClick={onBtnDisconnect} className="oz-page-header"> Settings / Integrations / balto settings </div>
                                </Grid>

                                <div className="oz-admin-integration-Idesk-IdeskName">
                                    Balto Settings
                                </div>

                                <form
                                    onSubmit={props.handleSubmit}
                                >
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="authToken"
                                                placeholder="Auth Token"
                                                label="Auth Token *"
                                            />
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", marginTop: "80px", marginBottom: "80px" }}>
                                        <Button variant='contained' autoFocus sx={{ marginRight: "10px" }} type="submit">
                                            Save
                                        </Button>
                                        <Button variant='outlined' autoFocus onClick={() => handleCancel()}>
                                            Cancel
                                        </Button>
                                    </div>

                                </form>
                            </div>
                        )
                    }
                </Formik>
            </div>
        )
    }

    return (
        <>
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                <DataForm />
            }
        </>
    )
}

export default BaltoIntegration