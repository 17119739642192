import React, { useState } from 'react';
import { FormControl, MenuItem, Select, Typography, IconButton } from '@mui/material';
import { connect } from "formik";

const CustomSelectCampaign = connect(({ name, label, options, onChange, placeholder, height, type, width, fontSize, border, disabled, showCheckbox, showChip, optionLabel, formik, mode }) => {

    const [selected, setSelected] = useState('')

    const DropDownIcon = () => {
        return (<IconButton sx={{
            position: 'absolute !important', right: '0', pointerEvents: 'none !important'
        }}><img style={{ paddingRight: "5px" }}
            src={`${process.env.PUBLIC_URL}/icons/downArrowDropDown.svg`}
            alt="edit"
        ></img></IconButton>)
    };

    const customRenderValue = (selected) => {

        const placeHolderStyle = {
            fontSize: fontSize || "14px",
            fontWeight: 400,
            heigth: '16px',
            marginTop: "2px",
            color: mode === "Add" ? '#99A0A8' : '#000000'
        };

        const selectedValueStyle = {
            fontSize: fontSize || "14px",
            fontWeight: 400,
            heigth: '16px',
            marginTop: "2px",
            color: "#212121"
        }

        const Placeholder = <Typography style={placeHolderStyle
        } > {placeholder}</Typography >
        const Selected = <Typography style={selectedValueStyle
        } > {selected}</Typography >

        return (selected === "" || selected === undefined) ? Placeholder : Selected
    }

    function updateCampaign(event) {
        setSelected(event.target.value)
        onChange(event.target.value);
    }

    return (

        <FormControl sx={{}}>
            <Select
                sx={{
                    height: height || '44px',
                    // width: width || "200px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: '8px',
                    marginTop: '24px',

                    '& .MuiOutlinedInput-notchedOutline': {
                        border: border || "1px solid #D6D6D",
                        borderRadius: '8px',
                        fontSize: fontSize || '14px',
                    },
                }}
                IconComponent={DropDownIcon}
                placeholder={placeholder}
                displayEmpty
                name={name}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            width: '200px',
                            border: "1px solid #D6D6D",
                            borderRadius: "8px",
                            '& .MuiMenuItem-root': {
                                bgcolor: "white",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#212121"
                            },
                            '& .MuiMenuItem-root:hover': {
                                color: '#3D8BF8'
                            },
                        },
                    },
                }}

                value={selected}
                SelectDisplayProps={{
                }}
                onChange={(event) => updateCampaign(event)}
                renderValue={(selected) => customRenderValue(selected)}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                {options.map((option, index) => {
                    return (<MenuItem key={index} value={option}>
                        {option}
                    </MenuItem>)
                })}
            </Select>
        </FormControl>

    );
})

export default CustomSelectCampaign;