import React, { useState, useMemo, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Grid, Typography, Stack, Step, Stepper, StepLabel, Divider } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import Box from '@mui/material/Box';
import OZSelect from '../../../components/admin/common/OZSelect';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import { addNewMarketingCampaign, addNewMarketingFormDigitalCampaign, updateMarketingDigitalCampaign, updateMarketingCampaign } from '../../../services/api-service';
import { getSkillsDropdown, getIvrFlowChatDropdown, getSubUserDropdown, getDispositionsDropdown, getPushDataMarketingDropdown, getSendSmsMarketingDropdown, getPluginDropdown, getDIDInMissedCallDropdown } from '../../../services/api-service';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';
import SimpleSwitch from '../../../components/admin/common/OZSwitch/SimpleSwitch';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from "@mui/lab/TabList";
import PopupState from 'material-ui-popup-state';
import CopyCampaignForm from './CopyCampaignForm';
import LockedAndStatusConfirmationDialog from '../../../components/admin/common/OZLockedAndStatusDialog';
import { OZCustomToggleButton } from "../../../components/admin/common/OZCustomToggleButton";

import { enableIntegrationById, disableIntegrationById } from '../../../services/api-service';
import { checkRoles } from '../../../utils/util';
// import FacebookIntegrationForm from './FacebookIntegration';
import { authPage, AdminPages, newAuthPages, NewAdminPages, newAuthSubAdminAccess } from '../../../services/page-service';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';

const MarketingCampaignForm = (props) => {

    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add

    const [mode, setMode] = useState(initMode);
    const [value, setValue] = useState('0');
    // const [open, setState] = useState(true);

    const [skillOption, setSkillOption] = useState([]);
    const [pluginOption, setpluginOption] = useState([]);
    const [ivrFlowChatOption, setIvrflowChatOption] = useState([]);
    const [subUserOption, setSubUserOption] = useState([]);
    const [dispositonsOption, setDispositonsOption] = useState([]);
    const [pushDataOption, setPushDataOption] = useState([]);
    const [sendSmsOption, setSendSmsOption] = useState([]);
    const [didInMissedCallOption, setDidInMissedCallOption] = useState([]);
    const [copyCampaign, setCopyCampaign] = useState(false);
    const [loading, setLoading] = useState((mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? true : false);
    const [newLoading, setNewLoading] = useState(false);

    const validationSchema = useMemo(() => (yup.object({
        campaignName: yup
            .string()
            .min(2, "Campaign Name should be between 2 to 50 alphanumeric characters long and allows special characters like .,@,_,-")
            .max(50, "Campaign Name should be between 2 to 50 alphanumeric characters long and allows special characters like .,@,_,-")
            .matches(/^([A-Za-z0-9]((\.|@|_|-)?[A-Za-z0-9])+)$/, "Campaign Name should be between 2 to 50 alphanumeric characters long and allows special characters like .,@,_,-")
            .required("Campaign Name is required"),
        did: yup.mixed()
            .when("campaignType.id", {
                is: type => type === 'Chat',
                then: yup
                    .string("Enter Campaign Identifier")
                    .nullable()
                    .test("maxDigits",
                        "Campaign Identifier should be between 4 to 12 digits long and allows prefix +",
                        (number) => String(number).length >= 4 && String(number).length <= 13)
                    .matches(/^(\+)?[0-9]{0,12}$/, 'Campaign Identifier should be between 4 to 12 digits long and allows prefix +')
                    .required("Campaign Identifier is required"),
                otherwise: yup.mixed()
                    .notRequired()
                    .when("campaignType.id", {
                        is: type => type === 'Send SMS',
                        then: yup
                            .string()
                            .typeError('Sender ID must be in uppercase.')
                            .matches(/^[A-Z]*$/, 'Sender ID must be in uppercase.')
                            .required("Sender ID is required")
                            .min(6, "Sender Id length is invalid.It should be of 6 characters")
                            .max(6, "Sender Id length is invalid.It should be of 6 characters"),
                        otherwise: yup
                            .mixed()
                            .notRequired()
                            .when("campaignType.id", {
                                is: type => type === 'Missed Call',
                                then: yup
                                    .mixed()
                                    .required("DID is required."),
                                otherwise: yup
                                    .mixed()
                                    .notRequired()
                            })
                    })
            }),
        ruleNot: yup
            .number()
            .typeError('The value must be a number')
            .when('campaignType.id', {
                is: id => id === 'Chat',
                then: yup
                    .number()
                    .min(1, 'Number of concurrent chats should be between 1 and 20.')
                    .max(20, 'Number of concurrent chats should be between 1 and 20.')
                    .typeError('The value must be a number')
                    .integer('The value must be a number')
                    .required('Number of concurrent chats is required.'),
                otherwise: yup.number().notRequired().nullable()
            }),
        dispositions: yup
            .array()
            .when('campaignType.id', {
                is: id => id === 'Chat',
                then: yup
                    .array()
                    .of(yup.object())
                    .required("Dispositions is required.")
                    .min(1, "Dispositions must have at least 1 item."),
                otherwise: yup.array().notRequired().nullable()
            }),
        callbackUrl: yup
            .string("URL to PUSH is required.")
            .when('campaignType.id', {
                is: id => id === 'Push Data',
                then: yup
                    .string("URL to PUSH is required.")
                    .required("URL to PUSH is required.")
                    .nullable(),
                otherwise: yup.string().notRequired().nullable()
            }),
        reqType: yup
            .string("Method is required.")
            .when('campaignType.id', {
                is: id => id === 'Push Data',
                then: yup
                    .string("Method is required.")
                    .required("Method is required.")
                    .nullable(),
                otherwise: yup.string().notRequired().nullable()
            }),
        smsText: yup
            .string("SMS Message is required.")
            .when('campaignType.id', {
                is: id => id === 'Send SMS',
                then: yup
                    .string("SMS Message is required.")
                    .required("SMS Message is required.")
                    .nullable(),
                otherwise: yup.string().notRequired().nullable()
            }),
        skills: yup
            .array()
            .when('campaignType.id', {
                is: id => id === 'Chat',
                then: yup
                    .array()
                    .of(yup.object())
                    .required("Skills is required.")
                    .min(1, "Skills must have at least 1 item."),
                otherwise: yup.array().notRequired().nullable()
            }),
        ivrFlow: yup
            .object()
            .when('campaignType.id', {
                is: id => id === 'Chat',
                then: yup
                    .object()
                    .nullable()
                    .required("IVR Flow is required."),
                otherwise: yup.object().notRequired().nullable()
            }),
        screenPopUrl: yup
            .object()
            .when('popUrlAt.id', {
                is: id => id === 'Plugin',
                then: yup
                    .object()
                    .nullable()
                    .required("Plugin Name is required."),
                otherwise: yup.object().notRequired().nullable()
            }),
        /*  actionCampaigns: yup
             .object()
             .when('actions', {
                 is: actions => actions.includes("PUSH Data"),
                 then: yup
                     .object()
                     .nullable()
                     .required("PUSH Data Field is required."),
                 otherwise: yup.object().notRequired().nullable()
             }),
         actionCampaignsPushData: yup
             .object()
             .when('actions', {
                 is: actions => actions.includes("PUSH Data"),
                 then: yup
                     .object()
                     .nullable()
                     .required("PUSH Data Field is required."),
                 otherwise: yup.object().notRequired().nullable()
             }),
         actionCampaignsSendSms: yup
             .object()
             .when('actions', {
                 is: actions => actions.includes('Send SMS'),
                 then: yup
                     .object()
                     .nullable()
                     .required("Send SMS Field is required."),
                 otherwise: yup.object().notRequired().nullable()
             }),
         */
    })), []);

    const closeMarketingCampaignForm = () => {
        // localStorage.clear();
        localStorage.removeItem("MarketingForm");
        // setState(false)
        props.onClose();
    }

    useEffect(() => {
        if (mode !== ADMIN_FORM_MODES.view) {
            getSkillsOptions()
            getIvrFlowChatOptions()
            getSubUserOptions()
            getDispositionsOptions()
            getPushDataOptions()
            getSendSmsOptions()
            getPluginOptions()
            getDidInMissecCallOptions()
        }
    }, [mode])

    async function addMarketingCampaignForm(data) {
        data.popUrlAt = data.popUrlAt.value;
        if (data.campaignType === "MissedCall") {
            data.actionCampaigns = []
            if (data.actionCampaignsPushData !== null) {
                data.actionCampaigns.push(data.actionCampaignsPushData)
            }
            if (data.actionCampaignsSendSms !== null) {
                data.actionCampaigns.push(data.actionCampaignsSendSms)
            }
            delete data.actionCampaignsSendSms;
            delete data.actionCampaignsPushData;
        } else {
            if (data.actionCampaigns !== null) {
                data.actionCampaigns = [data.actionCampaigns];
                delete data.actions
            }
            if (data.actions === null) {
                data.actionCampaigns = [];
            }
        }
        if (data?.actions) {
            delete data.actions;
        }
        console.log("data in add", data)
        //else {
        //     data.actionCampaigns = [];
        // }
        // if (!data.ivrFlow) {
        //     data.ivrFlow = null;
        // }
        // if (data.dispositions === '') {
        //     data.dispositions = [];
        // }
        // data.smsText = "Welcome to ozonetel";
        try {
            addNewMarketingCampaign(data).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(`${data.campaignName} Campaign is Created Successfully`);
                    props.updateCampaignMarketing();
                    closeMarketingCampaignForm();
                    setNewLoading(false);
                } else {
                    showErrorNotification(resp?.Message);
                    setNewLoading(false);
                    // showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function addMarketingFormDigitalCampaign(data) {
        data.popUrlAt = data.popUrlAt.value;
        if (data.actionCampaigns === '' || data.actionCampaigns === null) {
            delete data.actionCampaigns;
        }
        try {
            addNewMarketingFormDigitalCampaign(data).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(`${data.campaignName} Campaign is Created Successfully`);
                    props.updateCampaignMarketing();
                    closeMarketingCampaignForm();
                    setNewLoading(false);
                } else {
                    showErrorNotification(resp?.Message);
                    setNewLoading(false);
                    // showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function updateMarketingDigitalCampaignForm(data, id) {
        data.popUrlAt = data.popUrlAt.value;
        try {
            updateMarketingDigitalCampaign(data, id).then(resp => {
                if (resp.Status === "Success") {
                    showSuccessNotification(`${data.campaignName} Campaign is Updated Successfully`);
                    props.updateCampaignMarketing();
                    closeMarketingCampaignForm();
                    setNewLoading(false);
                } else {
                    showErrorNotification(resp?.Message);
                    setNewLoading(false);
                    // showErrorNotification(JSON.stringify(resp.Message));
                }

            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function updateMarketingCampaignForm(data, id) {
        data.popUrlAt = data.popUrlAt.value;
        if (data.campaignType === "MissedCall") {
            data.actionCampaigns = []
            if (data.actionCampaignsPushData !== null) {
                data.actionCampaigns.push(data.actionCampaignsPushData)
            }
            if (data.actionCampaignsSendSms !== null) {
                data.actionCampaigns.push(data.actionCampaignsSendSms)
            }
            delete data.actionCampaignsSendSms;
            delete data.actionCampaignsPushData;
        }
        else {
            if (data?.actionCampaigns?.length !== 0 || data.actionCampaigns === null) {
                data.actionCampaigns = [data.actionCampaigns];
            }
            if (data.actions === null) {
                data.actionCampaigns = [];
            }
        }
        if (data?.actions) {
            delete data.actions;
        }
        console.log("values from chat edit form ", data, ":::::id also:::::", id);
        try {
            updateMarketingCampaign(data, id).then(resp => {
                if (resp.Status === "Success") {
                    showSuccessNotification(`${data.campaignName} Campaign is Updated Successfully`);
                    props.updateCampaignMarketing();
                    closeMarketingCampaignForm();
                    setNewLoading(false);
                } else {
                    showErrorNotification(resp?.Message);
                    setNewLoading(false);
                    // showErrorNotification(JSON.stringify(resp.Message));
                }

            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getPluginOptions() {
        setNewLoading(true);
        try {
            getPluginDropdown().then(resp => {
                setpluginOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No Plugin Name, Check your Internet Connection');
                setpluginOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getDidInMissecCallOptions() {
        setNewLoading(true);
        try {
            getDIDInMissedCallDropdown().then(resp => {
                setDidInMissedCallOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No DID , Check your Internet Connection');
                setDidInMissedCallOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getSkillsOptions() {
        setNewLoading(true);
        try {
            getSkillsDropdown().then(resp => {
                setSkillOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                setSkillOption([]);
                showErrorNotification('No Skills Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getIvrFlowChatOptions() {
        setNewLoading(true);
        try {
            getIvrFlowChatDropdown().then(resp => {
                setIvrflowChatOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No IvrFlow Chat, Check your Internet Connection');
                setIvrflowChatOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getDispositionsOptions() {
        setNewLoading(true);
        try {
            getDispositionsDropdown().then(resp => {
                setDispositonsOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No Dispositions, Check your Internet Connection');
                setDispositonsOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getSubUserOptions() {
        setNewLoading(true);
        try {
            getSubUserDropdown().then(resp => {
                setSubUserOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                setSubUserOption([]);
                showErrorNotification('No Users data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getPushDataOptions() {
        setNewLoading(true);
        try {
            getPushDataMarketingDropdown().then(resp => {
                setPushDataOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No Push Data, Check your Internet Connection');
                setPushDataOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getSendSmsOptions() {
        setNewLoading(true);
        try {
            getSendSmsMarketingDropdown().then(resp => {
                setSendSmsOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No Send SMS Data, Check your Internet Connection');
                setSendSmsOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getEnableIntegrationById(id) {
        try {
            console.log("id coming here", id);
            enableIntegrationById(id).then(resp => {
                if (resp.Status === "Success") {
                    showSuccessNotification(JSON.stringify(resp.Message));
                } else {
                    showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function getDisableIntegrationById(id) {
        try {
            console.log("id coming here", id);
            disableIntegrationById(id).then(resp => {
                if (resp.Status === "Success") {
                    showSuccessNotification(JSON.stringify(resp.Message));
                } else {
                    showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    const openCopyCampaign = () => {
        setCopyCampaign(true);
    }

    const closeCopyCampaignForm = (props) => {
        setCopyCampaign(false);
    }

    const onClickEdit = () => {
        setMode(ADMIN_FORM_MODES.edit);
    }

    const onClickDiscardEdit = () => {
        setMode(ADMIN_FORM_MODES.view);
    }

    const DataForm = (props) => {
        if (props?.data?.campaignType) {
            const campaignType = [{ id: 'Chat', value: 'Chat' }, { id: 'Missed Call', value: 'MissedCall' }, { id: 'Push Data', value: 'PushData' }, { id: 'Send SMS', value: 'SMS_OUT' }];
            campaignType.forEach(item => {
                if (item.value === props.data.campaignType) {
                    props.data.campaignType = item;
                }
            })
        }
        if (props?.data?.campaignType?.value === 'PushData') {
        }
        else if (props?.data?.campaignType?.value === 'Chat') {
            const popUrlAt = [{ id: 'Client Side', value: 0 }, { id: 'Plugin', value: 4 }];
            popUrlAt.forEach(item => {
                if (item.value === props.data.popUrlAt) {
                    props.data.popUrlAt = item;
                }
            })
        }
        else if (props?.data?.campaignType?.value === 'MissedCall') {
            const popUrlAt = [{ id: 'None', value: 0 }, { id: 'Plugin', value: 4 }];
            popUrlAt.forEach(item => {
                if (item.value === props.data.popUrlAt) {
                    props.data.popUrlAt = item;
                }
            })
            if (props?.data?.actionCampaigns?.length > 0) {
                props.data.actions = [];
                props.data.actionCampaignsPushData = null;
                props.data.actionCampaignsSendSms = null;
                props.data.actionCampaigns.forEach((actionCampaign) => {
                    if (actionCampaign.campaignType === "PushData") {
                        props.data.actionCampaignsPushData = actionCampaign;
                        props.data.actions.push("PUSH Data");
                    }
                    else if (actionCampaign.campaignType === "SMS_OUT") {
                        props.data.actions.push("Send SMS");
                        props.data.actionCampaignsSendSms = actionCampaign;
                    }
                })
            }
            else {
                props.data.actions = [];
                props.data.actionCampaignsPushData = null;
                props.data.actionCampaignsSendSms = null;
            }
        }
        else if (props?.data?.campaignType?.value === 'SMS_OUT') {
            if (props?.data?.actionCampaigns[0]?.campaignType) {
                props.data.actionCampaigns = props.data.actionCampaigns[0];
            }
        }

        let initData = props.data || {
            campaignName: "",
            campaignType: { id: 'Missed Call', value: 'MissedCall' },
            users: [],
            did: "",
            ivrFlow: null,
            ruleNot: null,
            screenPopUrl: null,
            callbackUrl: "",
            popUrlAt: "",
            dynamicDID: [],
            fallbackDynamicDID: [],
            dispositions: [],
            skills: [],
            reqType: null,
            actions: [],
            actionCampaigns: null,
            actionCampaignsPushData: null,
            actionCampaignsSendSms: null,
            smsText: null,
            position: "READY",
        };
        setLoading(false);

        //for  stepper
        const [steps] = useState(["Basic Settings", "General Information", "Skills"]);
        const [activeStep, setActiveStep] = useState(0);
        // const [completed, setCompleted] = useState({});
        const [completed] = useState({});
        const [addbuttonInSendSms, setAddbuttonInSendSms] = useState(false)
        const [addButtonInMissed, setAddButtonInMissed] = useState(mode === "Create" ? [] : props?.data?.actions !== undefined ? props?.data?.actions : []);
        // const [facebook, setFacebook] = useState(false);

        const handleChange = (event, newValue, props) => {
            localStorage.setItem('MarketingForm', JSON.stringify(props.values))
            setValue(newValue);
        }

        const handleNext = () => {
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed,
                    // find the first step that has been completed
                    steps.findIndex((step, i) => !(i in completed))
                    : activeStep + 1;
            setActiveStep(newActiveStep);
        };

        const handleStep = (step) => () => {
            setActiveStep(step);
        };

        const totalSteps = () => {
            return steps.length;
        };

        const completedSteps = () => {
            return Object.keys(completed).length;
        };

        const isLastStep = () => {
            return activeStep === totalSteps() - 1;
        };

        const allStepsCompleted = () => {
            return completedSteps() === totalSteps();
        };

        let formHeading = useMemo(
            () => ({
                fontWeight: '400',
                fontSize: '22px',
                color: '#212121',
                fontStyle: 'normal',
                fontFamily: 'Roboto',
            }),
            []
        );

        let formHeadingGeneral = useMemo(
            () => ({
                marginTop: '30px',
                fontWeight: '400',
                fontSize: '22px',
                color: '#212121',
                fontStyle: 'normal',
                fontFamily: 'Roboto',
            }),
            []
        );

        let stepperStyle = useMemo(
            () => ({
                paddingLeft: '1.25rem',
                paddingRight: '1.25rem',
                paddingTop: '1.25rem',
            }),
            []
        );

        let logoInnerCardStyle = useMemo(
            () => ({
                backgroundColor: '#FFFFFF',
                border: '4px',
                height: '7.75rem',
                width: '7.75rem',
                marginLeft: '27px',
                marginTop: '25px',
            }),
            []
        );

        let logoOuterCardStyle = useMemo(
            () => ({
                paddingTop: '1px',
                backgroundColor: ' #F6F7F9',
                border: '4px',
                height: '11.25rem',
                width: '11.25rem',
            }),
            []
        );

        let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400, color: '#3D8BF8' }), [])

        const cancel_Save_InViewButtonStyle = useMemo(
            () => ({
                borderRadius: '8px',
                fontWeight: '400',
                fontSize: '14px',
            }),
            []
        );

        // const handleSwitch = (event, value) => {
        //     console.log("event", event, "value ", value);
        //     if (value === 'facebook') {
        //         setToggleSelectStyle('facebook');
        //         setIconChecked({ facebook: event.target.checked, twitter: false });
        //     } else if (value === 'twitter') {
        //         setToggleSelectStyle('twitter');
        //         setIconChecked({ facebook: false, twitter: event.target.checked });
        //     }
        // };

        // const closeFacebookIntegrationDialogue = () => {
        //     setFacebook(false);
        // }

        const HandleSwitch = (event, value, id) => {
            if (event.target.checked === true) {
                console.log("value coming here ", value, "::::event:::>", event, "::::id:::>>", id);
                if (value === 'facebook') {
                    console.log("yes i cam here ", value);
                    // setFacebook(true);
                    setFacebookIcon(event.target.checked);
                } else if (value === 'twitter') {
                    setTwitterIcon(event.target.checked);
                }
                getEnableIntegrationById(id);
            }
            if (event.target.checked === false) {
                if (value === 'facebook') {
                    setFacebookIcon(event.target.checked);
                } else if (value === 'twitter') {
                    setTwitterIcon(event.target.checked);
                }
                getDisableIntegrationById(id);
            }
        };

        const defaultIconTextStyle = useMemo(
            () => ({
                marginTop: '15px',
                color: '#7C878B',
                marginLeft: '28px',
                fontSize: '14px',
                fontWeight: '500',
                cursor: "pointer",
            }),
            []
        );
        const defaultIconTwitterTextStyle = useMemo(
            () => ({
                marginTop: '20px',
                color: '#7C878B',
                marginLeft: '37px',
                fontSize: '14px',
                fontWeight: '500',
            }),
            []
        );

        const activeIconTextStyle = useMemo(
            () => ({
                marginTop: '15px',
                color: '#3D8BF8',
                marginLeft: '28px',
                fontSize: '14px',
                fontWeight: '500',
            }),
            []
        );
        const activeIconTwitterTextStyle = useMemo(
            () => ({
                marginTop: '20px',
                color: '#3D8BF8',
                marginLeft: '37px',
                fontSize: '14px',
                fontWeight: '500',
            }),
            []
        );

        const activeToggleStyle = useMemo(
            () => ({
                marginLeft: '8px',
                color: '#3D8BF8',
                fontSize: '12px',
                fontWeight: '500',
            }),
            []
        );

        const dafaultToggleStyle = useMemo(
            () => ({
                marginLeft: '8px',
                fontSize: '12px',
                fontWeight: '500',
                color: '#D6D6D7',
            }),
            []
        );

        const tabButtonStyle = useMemo(
            () => ({
                color: "#212121",
                fontFamily: "Roboto",
                fontSize: " 0.75rem",
                fontWeight: 400,
                letterSpacing: "0em",
                backgroundColor: "#F5F8FA",
                width: "5.8125rem",
                height: "1.875rem",
                borderRadius: 0,
                '&.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: '#FFFF',
                },
            }),
            []
        );

        let copyStyle = useMemo(
            () => ({
                paddingLeft: '20px',
                fontSize: '14px',
                flex: 'right',
                color: '#536580',
            }),
            []
        );

        // const [iconChecked, setIconChecked] = useState({ facebook: false, twitter: false });
        // const [toggleSelectStyle, setToggleSelectStyle] = useState('');
        const [facebookIcon, setFacebookIcon] = useState(false);
        const [twitterIcon, setTwitterIcon] = useState(false);

        const handleClickInSendSms = (value) => {
            setAddbuttonInSendSms(value)
        }

        const handleClickButtonInMissed = (value) => {
            if (!addButtonInMissed?.includes(value)) {
                setAddButtonInMissed([...addButtonInMissed, ...[value]]);
            }
        }

        const handleRemoveInSendsms = () => {
            setAddbuttonInSendSms(false)
        }

        const handleRemoveInMissed = (value) => {
            if (addButtonInMissed?.includes(value)) {
                let newFilteredValue = addButtonInMissed.filter((filterVal) => {
                    return !(filterVal === value)
                })
                setAddButtonInMissed(newFilteredValue)
            }
        }

        const [leaveCampaign, setLeaveCampaign] = useState(false);

        const LeaveCampaign = (props) => {
            const closeLeaveCampaign = () => {
                setOpenLeaveCampaign(false);
                props.onClose();
            }
            const [openLeaveCampaign, setOpenLeaveCampaign] = useState(true);

            return (
                <div>
                    <LockedAndStatusConfirmationDialog
                        hideActions
                        title={
                            <div>
                                <div style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
                                    <label>Leave Campaign?</label>
                                </div>
                            </div>
                        }
                        content={
                            <>
                                <div style={{ fontSize: '0.75rem', color: '#000000', fontWeight: '400' }}>
                                    Your entries will be lost when you leave this page.
                                </div>
                                <div style={{ fontSize: '0.75rem', color: '#000000', fontWeight: '400' }}>
                                    Are you sure you want leave the campaign ?
                                </div>
                                <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 1 }} justifyContent='end'>
                                    <Grid item >
                                        <Button style={cancel_Save_InViewButtonStyle} variant='outlined' type="submit" onClick={closeLeaveCampaign}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item >
                                        <Button style={cancel_Save_InViewButtonStyle} onClick={closeMarketingCampaignForm} variant="contained" type="submit">
                                            Yes, Leave Campaign
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        open={openLeaveCampaign}
                        onClose={closeLeaveCampaign}
                    >
                    </LockedAndStatusConfirmationDialog>
                </div>
            )
        }

        const openLeaveCampaignForm = () => {
            setLeaveCampaign(true);
        }

        const closeLeaveCampaignForm = () => {
            setLeaveCampaign(false);
        }


        const BasicSettings = (props) => {
            const basicProps = props?.props;
            if (basicProps?.values?.actionCampaigns?.campaignType === "PushData") {
                basicProps.values.actions = ['PUSH Data'];
                handleClickInSendSms(basicProps.values.actions[0]);
            }
            if (basicProps?.values?.campaignType?.id === "Send SMS" && basicProps?.values?.actions?.length > 1) {
                basicProps.values.actions = ['PUSH Data'];
            }

            return (
                <Stack>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={formHeading} flexGrow={1} >Basic Settings</Typography>

                        {mode !== ADMIN_FORM_MODES.add && (
                            <div>
                                <Grid container direction='row'>
                                {/* {authPage(AdminPages.MARKETING_CAMP_EDIT) && ( */}
                                    {(newAuthPages(NewAdminPages.MARKETING_PAGE) || newAuthSubAdminAccess(NewAdminPages.MARKETING_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MARKETING_PAGE, 'Full') ) && (
                                        <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                            <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit Basic Settings' : 'Discard Edit'}</label>
                                        </div>
                                    )}
                                    {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full')) && (
                                    <>
                                    {
                                        <PopupState variant="popover" popupId="demo-popup-menu" >
                                            {(popupState) => (
                                                <React.Fragment>
                                                    <div style={copyStyle} onClick={openCopyCampaign}>
                                                        <label style={{ cursor: "pointer" }}>Copy Campaign</label>
                                                    </div>
                                                    {copyCampaign && (
                                                        <div>
                                                            <CopyCampaignForm
                                                                onClose={closeCopyCampaignForm}
                                                                id={props?.props?.values?.campaignId}
                                                                campaignName={props?.props?.values?.campaignName}
                                                                type={props?.props?.values?.campaignType.value}
                                                                updateCopyCampaignMarketing={closeCopyCampaignForm}
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </PopupState>
                                    }
                                    </>
                                    )}
                                </Grid>
                            </div>
                        )}
                    </div >
                    <Grid style={{ marginTop: '8px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                        {basicProps?.values?.campaignType?.id !== 'Chat' &&
                            <Grid item xs={6} >
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit}
                                    name="campaignName"
                                    label="Campaign Name *"
                                    placeholder="Campaign Name"
                                />
                            </Grid>
                        }
                        {basicProps?.values?.campaignType?.id === 'Chat' &&
                            <Grid item xs={6} >
                                <OZInput
                                    disabled={mode === ADMIN_FORM_MODES.view || basicProps?.values?.position !== "READY"}
                                    name="campaignName"
                                    label="Campaign Name *"
                                    placeholder="Campaign Name"
                                />
                            </Grid>
                        }
                        <Grid item xs={6} >
                            <OZSelect
                                disabled={mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit}
                                name="campaignType"
                                label="Campaign Type *"
                                optionLabel='id'
                                // options={[{ id: 'Chat', value: 'Chat' }, { id: 'Missed Call', value: 'MissedCall' }, { id: 'Push Data', value: 'PushData' }, { id: 'Send SMS', value: 'SMS_OUT' }]}
                                options={[{ id: 'Missed Call', value: 'MissedCall' }, { id: 'Push Data', value: 'PushData' }, { id: 'Send SMS', value: 'SMS_OUT' }]}
                                placeholder="Please Select"
                            />
                        </Grid>
                    </Grid>
                    {(basicProps?.values?.campaignType?.id === "Chat") && (
                        <div>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                {localStorage.getItem('parentUser') === 'null' &&
                                    (checkRoles("ROLE_ADMIN") || checkRoles("ROLE_CAMPAIGNSUB")) &&
                                    <Grid item xs={6} >
                                        <CustomAutoComplete
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            mode='Add'
                                            name="users"
                                            limitTags={2}
                                            placeholder={"Please Select"}
                                            Datas={subUserOption}
                                            labelHeader='Sub Admin Assigned '
                                            optionLabelName='username'
                                            showCheckBox={true}
                                            multiple={true}
                                            showAvatar={true}
                                            showAvatarInChip
                                            showselectall="true"
                                            popupIcon={null}
                                        />
                                    </Grid>}
                                <Grid item xs={6} >
                                    <OZInput
                                        disabled={mode === ADMIN_FORM_MODES.view || basicProps?.values?.position !== "READY"}
                                        name="did"
                                        // label="DID*"
                                        label='Campaign Identifier *'
                                        placeholder="Campaign Identifier"
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ marginTop: '20px', fontSize: '18px', color: '#212121' }}>
                                <label>Integrate</label>
                            </div>
                            <Grid container style={{ marginTop: '20px', fontSize: '14px', color: '#212121' }} display='flex' direction={'row'} spacing={3} >
                                {checkRoles("ROLE_FBCHAT") &&
                                    <Grid item >
                                        <div style={logoOuterCardStyle}>
                                            <div style={logoInnerCardStyle}>
                                                <img src={`${process.env.PUBLIC_URL}/icons/facebook.svg`} alt='facebook' style={{ marginLeft: '48px', marginTop: '28px' }} ></img>
                                                {/* <div style={(toggleSelectStyle === 'facebook' && iconChecked.facebook) ? activeIconTextStyle : defaultIconTextStyle}> */}
                                                <div style={facebookIcon ? activeIconTextStyle : defaultIconTextStyle}>
                                                    Facebook
                                                </div>
                                            </div>
                                            {/* <div style={{ marginLeft: 45, display: 'flex', alignContent: 'center', marginTop: 7 }}> */}
                                            {/* <SimpleSwitch disabled={mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.add} flexGrow={1} checked={iconChecked.facebook} onChange={(e) => handleSwitch(e, 'facebook')} /> */}
                                            {/* <Switch checked={iconChecked.facebook} onChange={(e) => handleSwitch(e, 'facebook')} /> */}
                                            {/* <div style={(toggleSelectStyle === 'facebook' && iconChecked.facebook) ? activeToggleStyle : dafaultToggleStyle}>
                                                    {(toggleSelectStyle === 'facebook' && iconChecked.facebook) ? 'Activated' : 'Activate'}
                                                </div> */}
                                            {/* </div> */}

                                            {/* <PopupState variant="popover" popupId="demo-popup-facebook" >
                                                {(popupState) => (
                                                    <React.Fragment>
                                                        <div style={{ marginLeft: 45, display: 'flex', alignContent: 'center', marginTop: 7 }}>
                                                            <SimpleSwitch disabled={mode === ADMIN_FORM_MODES.view} flexGrow={1} checked={facebookIcon} onChange={(e) => HandleSwitch(e, 'facebook', basicProps?.values?.campaignId)} />
                                                            <div style={facebookIcon ? activeToggleStyle : dafaultToggleStyle}>
                                                                {facebookIcon ? 'Activated' : 'Activate'}
                                                            </div>
                                                        </div>

                                                        {facebook && (
                                                            <div>
                                                                <FacebookIntegrationForm onClose={closeFacebookIntegrationDialogue} />
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </PopupState> */}

                                            <div style={{ marginLeft: 45, display: 'flex', alignContent: 'center', marginTop: 7 }}>
                                                <SimpleSwitch disabled={mode === ADMIN_FORM_MODES.view} flexGrow={1} checked={facebookIcon} onChange={(e) => HandleSwitch(e, 'facebook', basicProps?.values?.campaignId)} />
                                                <div style={facebookIcon ? activeToggleStyle : dafaultToggleStyle}>
                                                    {facebookIcon ? 'Activated' : 'Activate'}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>}
                                {checkRoles("ROLE_TWITTER") &&
                                    <Grid item>
                                        <div style={logoOuterCardStyle}>
                                            <div style={logoInnerCardStyle}>
                                                <img src={`${process.env.PUBLIC_URL}/icons/twitter.svg`} alt='twitter' style={{ marginLeft: '42px', marginTop: '28px' }} ></img>
                                                {/* <div style={(toggleSelectStyle === 'twitter' && iconChecked.twitter) ? activeIconTwitterTextStyle : defaultIconTwitterTextStyle}> */}
                                                <div style={twitterIcon ? activeIconTwitterTextStyle : defaultIconTwitterTextStyle}>
                                                    Twitter
                                                </div>
                                            </div>
                                            {/* <div style={{ marginLeft: 45, display: 'flex', alignContent: 'center', marginTop: 7 }}> */}
                                            {/* <SimpleSwitch disabled={mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.add} flexGrow={1} checked={iconChecked.twitter} onChange={(e) => handleSwitch(e, 'twitter')} /> */}
                                            {/* <Switch checked={iconChecked.twitter} onChange={(e) => handleSwitch(e, 'twitter')} /> */}
                                            {/* <div style={(toggleSelectStyle === 'twitter' && iconChecked.twitter) ? activeToggleStyle : dafaultToggleStyle}>
                                                    {(toggleSelectStyle === 'twitter' && iconChecked.twitter) ? 'Activated' : 'Activate'}
                                                </div> */}
                                            {/* </div> */}
                                            <div style={{ marginLeft: 45, display: 'flex', alignContent: 'center', marginTop: 7 }}>
                                                <SimpleSwitch disabled={mode === ADMIN_FORM_MODES.view} flexGrow={1} checked={twitterIcon} onChange={(e) => HandleSwitch(e, 'twitter', basicProps?.values?.campaignId)} />
                                                <div style={twitterIcon ? activeToggleStyle : dafaultToggleStyle}>
                                                    {twitterIcon ? 'Activated' : 'Activate'}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>}
                            </Grid >
                        </div>
                    )}

                    {basicProps?.values?.campaignType?.id === "Missed Call" && (
                        <div>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_CAMPAIGNSUB")) &&
                                    <Grid item xs={6} >
                                        <CustomAutoComplete
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            mode='Add'
                                            name="users"
                                            limitTags={2}
                                            placeholder={"Please Select"}
                                            Datas={subUserOption}
                                            labelHeader='Sub Admin Assigned '
                                            optionLabelName='username'
                                            showCheckBox={true}
                                            multiple={true}
                                            showAvatar={true}
                                            showAvatarInChip
                                            showselectall="true"
                                            popupIcon={null}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={6} >
                                    {/* <OZInput
                                        disabled={mode === ADMIN_FORM_MODES.view || basicProps?.values?.position !== "READY"}
                                        name="did"
                                        label="DID*"
                                        placeholder="DID No."
                                    /> */}
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view || basicProps?.values?.position !== "READY"}
                                        name="did"
                                        label="DID *"
                                        optionLabel='did'
                                        options={didInMissedCallOption}
                                        placeholder="DID No."
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )}

                    {basicProps?.values?.campaignType?.id === "Send SMS" && (
                        <div>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={6} >
                                    {/* <OZInput
                                        name="senderId"
                                        label="Sender ID *"
                                        placeholder="Sender ID"
                                    /> */}
                                    <OZInput
                                        disabled={mode === ADMIN_FORM_MODES.view || basicProps?.values?.position !== "READY"}
                                        name="did"
                                        label="Sender ID *"
                                        placeholder="Sender ID"
                                    />
                                </Grid>
                            </Grid>
                            <Typography sx={formHeadingGeneral} alignSelf='center'>General Information</Typography>
                            <Grid style={{ fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={6} style={{ marginTop: '8px' }} >
                                    <OZInput
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name='smsText'
                                        height='6.625rem'
                                        label='SMS Message *'
                                        placeholder='Type Message Here'
                                    />
                                </Grid>
                            </Grid>
                            <Grid style={{ fontSize: '12px', marginTop: '10px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={6} style={{ marginTop: '16px' }} >
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name='actions'
                                        multiSelect
                                        label="Actions"
                                        options={['PUSH Data']}
                                        placeholder="Actions"
                                    />
                                    {basicProps.values?.actions?.includes('PUSH Data') && (
                                        <Button
                                            disabled={addbuttonInSendSms === "PUSH Data"}
                                            size='small'
                                            sx={buttonStyle}
                                            startIcon={<AddBoxOutlinedIcon />}
                                            onClick={() => handleClickInSendSms((basicProps.values?.actions[0]))}
                                        >
                                            {(basicProps.values?.actions?.includes('PUSH Data')) ? 'Add PUSH Data' : 'Add SEND SMS'}
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {basicProps.values?.actions?.includes('PUSH Data') && addbuttonInSendSms === "PUSH Data" && (
                                        <div>
                                            <div style={{ display: 'flex', alignContent: 'center', marginTop: '12px' }}>
                                                <Typography sx={{
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    fontSize: '12px',
                                                    color: '#212121',
                                                    paddingBottom: '6px',
                                                }} flexGrow={1} > PUSH Data *</Typography>
                                                <img style={{ paddingBottom: '6px', cursor: "pointer" }} onClick={(mode === ADMIN_FORM_MODES.view) ? null : () => { handleRemoveInSendsms(addbuttonInSendSms); basicProps.values.actionCampaigns.campaignType = null; basicProps.values.actions = null }} src={`${process.env.PUBLIC_URL}/icons/remove.svg`} alt='remove'></img>
                                            </div>
                                            <CustomAutoComplete
                                                name='actionCampaigns'
                                                placeholder="Please Select"
                                                disabled={mode === ADMIN_FORM_MODES.view}
                                                mode='Add'
                                                Datas={pushDataOption}
                                                optionLabelName='campaignName'
                                            />
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </div >
                    )}

                    {basicProps?.values?.campaignType?.id === "Push Data" && (
                        <div>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={6} >
                                    <OZInput
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name="callbackUrl"
                                        label="URL to PUSH *"
                                        placeholder="URL to PUSh"
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name='reqType'
                                        label="Method *"
                                        options={['GET', 'POST']}
                                        placeholder="Please Select"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )}

                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? { marginTop: 8 } : { marginTop: 8, marginBottom: '10px' }} justifyContent='end'>
                        <Grid item>
                            {(basicProps?.values?.campaignType?.id === "Push Data" || basicProps?.values?.campaignType?.id === "Send SMS" || basicProps?.values?.campaignType?.id === "Missed Call" || basicProps?.values?.campaignType?.id === "Chat") && (
                                <Button style={cancel_Save_InViewButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={openLeaveCampaignForm} variant="outlined" type="submit">
                                    Cancel
                                </Button>
                            )}
                        </Grid>
                        {leaveCampaign && (
                            <LeaveCampaign onClose={closeLeaveCampaignForm} />
                        )}
                        <Grid item >
                            {(basicProps?.values?.campaignType?.id === "Push Data" || basicProps?.values?.campaignType?.id === "Send SMS") && (
                                <Button style={cancel_Save_InViewButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} variant="contained" onClick={() => {
                                    if (!basicProps.isValid) {
                                        basicProps.handleSubmit();
                                        showErrorNotification("Please fill all the mandatory field")
                                    }
                                }}
                                    type={(basicProps.isValid) ? "submit" : null} form="campaignMarketing-form">
                                    {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Save'}
                                </Button>
                            )}
                            {(basicProps?.values?.campaignType?.id === "Missed Call" || basicProps?.values?.campaignType?.id === "Chat") && (
                                <Button style={cancel_Save_InViewButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={(mode === ADMIN_FORM_MODES.add) ? handleNext : null} variant="contained" type="submit" form="campaignMarketing-form">
                                    {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Next'}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Stack >
            );
        }

        const GeneralInformation = (props) => {
            const generalProps = props?.props;

            if (mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) {
                if (generalProps?.data?.actions) {
                    generalProps.data.actions = [];
                }
                if (generalProps?.values?.actionCampaignsPushData?.campaignType === 'PushData') {
                    if (!generalProps?.values?.actions.includes("PUSH Data")) {
                        generalProps.values.actions.push('PUSH Data');
                    }
                    handleClickButtonInMissed('PUSH Data');
                }
                if (generalProps?.values?.actionCampaignsSendSms?.campaignType === "SMS_OUT") {
                    if (!generalProps?.values?.actions.includes("Send SMS")) {
                        generalProps.values.actions.push('Send SMS');
                    }
                    handleClickButtonInMissed("Send SMS");
                }
            }
            return (
                <Stack>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={formHeading} flexGrow={1}>General Information</Typography>

                        {mode !== ADMIN_FORM_MODES.add && (
                            <div>
                                {/* {authPage(AdminPages.MARKETING_CAMP_EDIT) && ( */}
                                {(newAuthPages(NewAdminPages.MARKETING_PAGE) || newAuthSubAdminAccess(NewAdminPages.MARKETING_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MARKETING_PAGE, 'Full')) && (
                                    <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                        <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit' : 'Discard Edit'}</label>
                                    </div>

                                )}
                            </div>
                        )}
                    </div>
                    {(generalProps?.values?.campaignType?.id === "Chat") && (
                        <div>
                            <Grid style={{ marginTop: '8px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_IVRDESIGNER_ALL")) &&
                                    <Grid item xs={6} >
                                        <OZSelect
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="ivrFlow"
                                            label="IVR Flow *"
                                            optionLabel='flowName'
                                            // options={[{ flowId: 21425, flowName: 'variables' }]}
                                            options={ivrFlowChatOption}
                                            placeholder="Select IVR Flow"
                                        />
                                    </Grid>}
                                <Grid item xs={6} >
                                    <OZInput
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name='ruleNot'
                                        label="Number of concurrent chats *"
                                        placeholder="Per agents"
                                    />
                                </Grid>
                            </Grid>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                {(checkRoles("ROLE_CBK_CAMPAIGN") || checkRoles("ROLE_ADMIN")) &&
                                    <Grid item xs={6} >
                                        <OZInput
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="callbackUrl"
                                            label="URL to Push"
                                            placeholder="URL to Push"
                                        />
                                    </Grid>}
                                <Grid item xs={6} >
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name='popUrlAt'
                                        label="Hit ScreenPop URL at"
                                        optionLabel='id'
                                        options={[{ id: 'Client Side', value: 0 }, { id: 'Plugin', value: 4 }]}
                                        placeholder="Please Select"
                                    />
                                </Grid>
                            </Grid>

                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={6} >
                                    {generalProps?.values?.popUrlAt?.id !== 'Plugin' && (
                                        <OZInput
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="screenPopUrl"
                                            label="ScreenPop URL"
                                            placeholder="ScreenPop URL"
                                        />
                                    )}
                                    {generalProps?.values?.popUrlAt?.id === 'Plugin' && (
                                        // <OZInput
                                        //     disabled={mode === ADMIN_FORM_MODES.view}
                                        //     name="screenPopUrl"
                                        //     label="ScreenPop URL"
                                        //     placeholder="ScreenPop URL"
                                        // />
                                        <OZSelect
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name='screenPopUrl'
                                            label="Plugin Name"
                                            optionLabel='name'
                                            options={pluginOption}
                                            placeholder="Please Select"
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={6} >
                                    {/* <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name="dispositions"
                                        label="Dispositions *"
                                        multiSelect={true}
                                        showCheckbox={true}
                                        showChip={true}
                                        optionLabel='reason'
                                        options={dispositonsOption}
                                        // options={[{ id: 119475, reason: 'Break' }, { id: 119458, reason: 'disp1' }]}
                                        placeholder="Please Select"
                                    /> */}
                                    <CustomAutoComplete
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        mode='Add'
                                        limitTags={2}
                                        name="dispositions"
                                        labelHeader='Dispositions *'
                                        placeholder={"Please Select"}
                                        Datas={dispositonsOption}
                                        optionLabelName='reason'
                                        showCheckBox={true}
                                        multiple={true}
                                        showselectall="true"
                                        popupIcon={null}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )}

                    {generalProps?.values?.campaignType?.id === "Missed Call" && (
                        <div>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={6} >
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name='popUrlAt'
                                        label="Hit ScreenPop URL at"
                                        optionLabel='id'
                                        options={[{ id: 'None', value: 0 }, { id: 'Plugin', value: 4 }]}
                                        placeholder="Please Select"
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    {generalProps?.values?.popUrlAt?.id === 'Plugin' && (
                                        <OZSelect
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            name="screenPopUrl"
                                            label="Plugin Name *"
                                            placeholder="Please select"
                                            optionLabel='name'
                                            options={pluginOption}
                                        // options={['Zoho', 'IDesk']}
                                        />
                                    )}
                                </Grid>

                            </Grid>
                            <Grid style={{ fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={6} style={{ marginTop: '16px' }} >
                                    <OZSelect
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        name="actions"
                                        label="Actions"
                                        multiSelect
                                        options={['PUSH Data', 'Send SMS']}
                                        placeholder="Please Select"
                                    />
                                    {generalProps?.values?.actions?.includes('PUSH Data') && (
                                        <Button
                                            disabled={addButtonInMissed?.includes("PUSH Data")}
                                            size='small'
                                            sx={buttonStyle}
                                            startIcon={<AddBoxOutlinedIcon />}
                                            onClick={() => { handleClickButtonInMissed("PUSH Data"); }}
                                        >
                                            Add PUSH Data
                                        </Button>
                                    )}
                                    {generalProps?.values?.actions?.includes('Send SMS') && (
                                        <Button
                                            disabled={addButtonInMissed?.includes("Send SMS")}
                                            size='small'
                                            sx={buttonStyle}
                                            startIcon={<AddBoxOutlinedIcon />}
                                            onClick={() => handleClickButtonInMissed("Send SMS")}
                                        >
                                            Add Send SMS
                                        </Button>
                                    )}
                                </Grid>
                                {generalProps?.values?.actions?.includes("PUSH Data") && addButtonInMissed?.includes("PUSH Data") && (
                                    <Grid item xs={6}>
                                        <div style={{ display: 'flex', alignContent: 'center', marginTop: '12px' }}>
                                            <Typography sx={{
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                color: '#212121',
                                                paddingBottom: '6px',
                                            }} flexGrow={1} > PUSH Data *</Typography>
                                            <img style={{ paddingBottom: '6px', cursor: "pointer" }} onClick={(mode === ADMIN_FORM_MODES.view) ? null : (() => {
                                                handleRemoveInMissed("PUSH Data");
                                                if (generalProps?.values?.actions?.length > 1) {
                                                    let temp = generalProps.values.actions.filter((value) => { return value !== "PUSH Data" });
                                                    generalProps.values.actions = temp
                                                }
                                                else {
                                                    generalProps.values.actions = null
                                                }
                                                generalProps.values.actionCampaignsPushData = null
                                            })} src={`${process.env.PUBLIC_URL}/icons/remove.svg`} alt='remove2'></img>
                                        </div>
                                        <CustomAutoComplete
                                            name='actionCampaignsPushData'
                                            placeholder="Please Select"
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            mode='Add'
                                            Datas={pushDataOption}
                                            optionLabelName='campaignName'
                                        />
                                    </Grid>
                                )}
                                {generalProps?.values?.actions?.includes("Send SMS") && addButtonInMissed?.includes("Send SMS") && generalProps?.values?.actions.includes("PUSH Data") && addButtonInMissed.includes("PUSH Data") &&
                                    <Grid item xs={6}>
                                        <input
                                            hidden
                                        >
                                        </input>
                                    </Grid>
                                }
                                {generalProps?.values?.actions?.includes("Send SMS") && addButtonInMissed?.includes("Send SMS") && (
                                    <Grid item xs={6}>
                                        <div style={{ display: 'flex', alignContent: 'center', marginTop: '12px' }}>
                                            <Typography sx={{
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                color: '#212121',
                                                paddingBottom: '6px',
                                            }} flexGrow={1} > Send SMS *</Typography>
                                            <img style={{ paddingBottom: '6px', cursor: "pointer" }} onClick={(mode === ADMIN_FORM_MODES.view) ? null : (() => {
                                                handleRemoveInMissed("Send SMS");
                                                if (generalProps?.values?.actions?.length > 1) {
                                                    let temp = generalProps?.values?.actions?.filter((value) => { return value !== "Send SMS" });
                                                    generalProps.values.actions = temp
                                                }
                                                else {
                                                    generalProps.values.actions = null
                                                }
                                                generalProps.values.actionCampaignsSendSms = null
                                            })} src={`${process.env.PUBLIC_URL}/icons/remove.svg`} alt='remove3'></img>
                                        </div>
                                        <CustomAutoComplete
                                            name='actionCampaignsSendSms'
                                            placeholder="Please Select"
                                            disabled={mode === ADMIN_FORM_MODES.view}
                                            mode='Add'
                                            Datas={sendSmsOption}
                                            optionLabelName='campaignName'
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    )}
                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? { marginTop: 12 } : { marginTop: 12, marginBottom: '10px' }} justifyContent='end'>
                        <Grid item>
                            {(generalProps?.values?.campaignType?.id === "Missed Call" || generalProps?.values?.campaignType?.id === "Chat") && (
                                <Button style={cancel_Save_InViewButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={openLeaveCampaignForm} variant="outlined" type="submit">
                                    Cancel
                                </Button>
                            )}
                        </Grid>
                        {leaveCampaign && (
                            <LeaveCampaign onClose={closeLeaveCampaignForm} />
                        )}
                        <Grid item >
                            {generalProps?.values?.campaignType?.id === "Missed Call" && (
                                <Button style={cancel_Save_InViewButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={() => {
                                    if (!generalProps.isValid) {
                                        generalProps.handleSubmit();
                                        showErrorNotification("Please fill all the mandatory field")
                                    }
                                }}
                                    variant="contained" type={(generalProps.isValid) ? "submit" : null} form="campaignMarketing-form">
                                    {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Save'}
                                </Button>
                            )}
                            {generalProps?.values?.campaignType?.id === "Chat" && (
                                <Button style={cancel_Save_InViewButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={(mode === ADMIN_FORM_MODES.add) ? handleNext : null} variant="contained" type="submit" form="campaignMarketing-form">
                                    {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Next'}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Stack >
            );
        }

        const Skills = (props) => {
            const skillProps = props?.props;
            return (
                <Stack>
                    <div style={{ display: 'flex', alignContent: 'center' }}>
                        <Typography sx={formHeading} flexGrow={1}>Skills *</Typography>

                        {mode !== ADMIN_FORM_MODES.add && (
                            <div>
                                {/* {authPage(AdminPages.MARKETING_CAMP_EDIT) && ( */}
                                {(newAuthPages(NewAdminPages.MARKETING_PAGE) || newAuthSubAdminAccess(NewAdminPages.MARKETING_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MARKETING_PAGE, 'Full')) && (
                                    <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                        <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit' : 'Discard Edit'}</label>
                                    </div>

                                )}
                            </div>
                        )}

                    </div>
                    {skillProps?.values?.campaignType?.id === "Chat" && (
                        <div>
                            <div style={{ marginTop: '2px', fontSize: '14px', display: 'flex', color: '#99A0A8' }}>
                                <span style={{ opacity: '0.4' }}>Please select Skill to add</span>
                            </div>
                            <div style={{ minHeight: '22.0625rem', marginTop: 25, border: '1px solid #D6D6D7', borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
                                <div style={{ paddingTop: '5px', paddingLeft: '15px', fontSize: '14px', fontWeight: '400', color: '#212121' }}>
                                    {skillProps?.values?.skills?.length + ' Selected...'}
                                </div>
                                <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                                    <Divider sx={{ borderColor: "#D6D6D7" }} />
                                </span>
                                <div style={{ marginTop: '20px', marginLeft: '17px', minHeight: '2.75rem', paddingRight: '17px' }}>
                                    <CustomAutoComplete
                                        disabled={mode === ADMIN_FORM_MODES.view}
                                        // mode='Edit'
                                        // temporaryMode='Edit'
                                        limitTags={5}
                                        mode='Add'
                                        name="skills"
                                        placeholder={"Search Skill Name"}
                                        Datas={skillOption}
                                        optionLabelName='skillName'
                                        showCheckBox={true}
                                        multiple={true}
                                        showselectall="true"
                                        popupIcon={null}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {skillProps?.values?.campaignType?.id === "Chat" && (
                        <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? { marginTop: 1 } : { marginTop: 1, marginBottom: '10px' }} justifyContent='end'>
                            <Grid item>
                                <Button style={cancel_Save_InViewButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={openLeaveCampaignForm} variant="outlined" type="submit">
                                    Cancel
                                </Button>
                            </Grid>
                            {leaveCampaign && (
                                <LeaveCampaign onClose={closeLeaveCampaignForm} />
                            )}
                            <Grid item >
                                <Button style={cancel_Save_InViewButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={() => {
                                    if (!skillProps.isValid) {
                                        skillProps.handleSubmit();
                                        showErrorNotification("Please fill all the mandatory field")
                                    }
                                }}
                                    variant="contained" type={(skillProps.isValid) ? "submit" : null} form="campaignMarketing-form">
                                    {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Stack >
            )
        }

        const StepperIC = (props) => {
            let iconColor = props.active || props.completed ? '#3D8BF8' : '#7A7A7A'
            return (
                <div style={{
                    paddingTop: '2.5px', border: `1px dashed ${iconColor}`,
                    borderRadius: '50%',
                    height: '24px',
                    width: '24px',
                    color: iconColor,
                    fontWeight: '400',
                    fontSize: '12px', textAlign: 'center',
                    cursor: "pointer",
                }}>
                    {props.icon}
                </div>
            )
        }

        function getInitData() {
            // if (mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) {
            let localData = localStorage.getItem('MarketingForm');
            if (localData) {
                return JSON.parse(localData);
            } else {
                return initData;
            }
            // } else {
            //     let localData = localStorage.getItem('MarketingForm');
            //     if (localData) {
            //         return JSON.parse(localData);
            //     } else {
            //         return initData;
            //     }
            // }
        }

        return (
            <div className='oz-campaign-add-form'>
                <Formik
                    validateOnMount
                    initialValues={getInitData()}
                    // initialValues={(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? (JSON.parse(localStorage.getItem('MarketingForm')) || initData) : initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (mode === ADMIN_FORM_MODES.add || mode === ADMIN_FORM_MODES.edit) {
                            localStorage.setItem('MarketingForm', JSON.stringify(values));
                        }
                        let saveData = structuredClone(values);
                        saveData.dynamicDID = saveData?.dynamicDID?.length !== 0 ? saveData?.dynamicDID?.map(item => item.dynamicDID).join(',') : "";
                        saveData.fallbackDynamicDID = saveData?.fallbackDynamicDID?.length !== 0 ? saveData?.fallbackDynamicDID?.map(item => item.fallbackDynamicDID).join(',') : "";
                        if (saveData.campaignType.id === 'Chat' || saveData.campaignType.id === 'Missed Call'
                            || saveData.campaignType.id === 'Send SMS' || saveData.campaignType.id === 'Push Data') {
                            if (saveData.campaignType.id === 'Chat') {
                                if (saveData?.skills?.length === 0) {
                                    showErrorNotification("Please pass at least one valid Skill");
                                    return
                                }
                                if (saveData?.ivrFlow?.length === 0 || saveData.ivrFlow === null || saveData?.dispositions?.length === 0 || saveData.dispositions === ''
                                    || saveData.ruleNot === null || saveData.ruleNot === '' || saveData.did === null || saveData.did === '') {
                                    showErrorNotification("Please pass Mandatory Parameters for Campaign type Chat");
                                    return
                                }
                            }
                            else if (saveData.campaignType.id === 'Missed Call') {
                                if (saveData.did === '' || saveData.did === null) {
                                    showErrorNotification("Please pass Mandatory Parameters for Campaign type Missed Call");
                                    return
                                }
                                if (saveData.popUrlAt.id === 'Plugin') {
                                    if (saveData.screenPopUrl === null || saveData?.screenPopUrl?.length === 0 || saveData.screenPopUrl === '') {
                                        showErrorNotification("Please pass Mandatory Parameters for Campaign type Missed Call");
                                        return
                                    }
                                }
                                if (saveData.actions === 'PUSH Data' || saveData.actions === 'Send SMS') {
                                    if (saveData.actionCampaigns === null) {
                                        showErrorNotification("Please pass Mandatory Parameters for Campaign type Missed Call");
                                        return
                                    }
                                }
                            }
                            else if (saveData.campaignType.id === 'Send SMS') {
                                if (saveData.did === '' || saveData.did === null || saveData.smsText === '' || saveData.smsText === null) {
                                    showErrorNotification("Please pass Mandatory Parameters for Campaign type Send SMS");
                                    return
                                }
                                if (saveData.actions === 'PUSH Data') {
                                    if (saveData.actionCampaigns === null) {
                                        showErrorNotification('Please pass Mandatory Parameters for Campaign type Send SMS');
                                        return
                                    }
                                }
                            }
                            else if (saveData.campaignType.id === 'Push Data') {
                                if (saveData.callbackUrl === '' || saveData.reqType === '' || saveData.reqType === null) {
                                    showErrorNotification("Please pass Mandatory Parameters for Campaign type Push Data");
                                    return
                                }
                            }
                        }

                        if (mode === ADMIN_FORM_MODES.add) {
                            setNewLoading(true);
                            if (saveData.campaignType.id === 'Chat') {
                                console.log("Chat");
                                saveData.campaignType = saveData.campaignType.value;
                                if (saveData.popUrlAt.id === 'Plugin') {
                                    saveData.screenPopUrl = saveData.screenPopUrl.id;
                                }
                                console.log("values added in add functon", saveData);
                                addMarketingFormDigitalCampaign(saveData);
                            }
                            else {
                                if (saveData.campaignType.id === 'Missed Call') {
                                    console.log("Missed Call");
                                    saveData.campaignType = saveData.campaignType.value;
                                    saveData.did = saveData.did.did;
                                    delete saveData.smsText;
                                    if (saveData.popUrlAt.id === 'Plugin') {
                                        saveData.screenPopUrl = saveData.screenPopUrl.id;
                                    }
                                }
                                else if (saveData.campaignType.id === 'Send SMS') {
                                    console.log("Send SMS");
                                    saveData.campaignType = saveData.campaignType.value;
                                }
                                else if (saveData.campaignType.id === 'Push Data') {
                                    console.log("Push Data");
                                    saveData.campaignType = saveData.campaignType.value;
                                    delete saveData.users;
                                    delete saveData.did;
                                    delete saveData.ruleNot;
                                    delete saveData.screenPopUrl;
                                    delete saveData.skills;
                                    delete saveData.actions;
                                    delete saveData.smsText;
                                }
                                delete saveData.ivrFlow;
                                delete saveData.dispositions;
                                addMarketingCampaignForm(saveData);
                            }
                        }

                        if (mode === ADMIN_FORM_MODES.edit) {
                            setNewLoading(true);
                            console.log("form is in Edit mode", JSON.stringify(saveData));
                            if (saveData.campaignType.id === 'Chat') {
                                saveData.campaignType = saveData.campaignType.value;
                                let id = saveData.campaignId;
                                if (saveData.popUrlAt.id === 'Plugin') { //this is written when error is coming in eDIT MODE
                                    saveData.screenPopUrl = saveData.screenPopUrl.id;
                                }
                                updateMarketingDigitalCampaignForm(saveData, id);
                            }
                            else {
                                if (saveData.campaignType.id === 'Missed Call') {
                                    saveData.campaignType = saveData.campaignType.value;
                                    if (saveData.did.did) {
                                        saveData.did = saveData.did.did;
                                    }
                                    if (saveData.popUrlAt.id === 'Plugin') {
                                        saveData.screenPopUrl = saveData.screenPopUrl.id;
                                    } else {
                                        saveData.screenPopUrl = null;
                                    }
                                    // saveData.popUrlAt = saveData.popUrlAt.value;
                                    // if (saveData.actionCampaigns.length) {
                                    //     console.log("i am here ");
                                    //     saveData.actionCampaigns = [saveData.actionCampaigns];
                                    // } else {
                                    //     console.log("i am here2222222 ");
                                    //     saveData.actionCampaigns = [];
                                    // }
                                }
                                if (saveData.campaignType.id === 'Send SMS') {
                                    saveData.campaignType = saveData.campaignType.value;
                                    // if (saveData.actionCampaigns) {
                                    //     saveData.actionCampaigns = [saveData.actionCampaigns];
                                    // } else {
                                    //     saveData.actionCampaigns = [];
                                    // }
                                }
                                if (saveData.campaignType.id === 'Push Data') {
                                    saveData.campaignType = saveData.campaignType.value;
                                    saveData.did = null;
                                }

                                let id = saveData.campaignId;
                                updateMarketingCampaignForm(saveData, id);
                            }
                        }
                    }}
                >
                    {
                        props => (
                            <div>
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) && (
                                    <div>
                                        <form onSubmit={props.handleSubmit} id='campaignMarketing-form' className='ozcw-scrollbar' style={{
                                            maxHeight: 'auto',
                                            overflowY: 'auto',
                                            padding: '5px'
                                        }}>
                                            {loading ?
                                                (
                                                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <LoadingScreen />
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div style={{ marginTop: '18px' }}>
                                                        <TabContext value={value}>
                                                            <Box sx={{ borderColor: 'divider', borderRadius: '0px 0px 3px 3px' }} >
                                                                <TabList
                                                                    TabIndicatorProps={{
                                                                        style: { backgroundColor: "#008EFF", marginBottom: "1.125rem" },
                                                                    }}
                                                                    value={value}
                                                                    onChange={(e, newvalue) => handleChange(e, newvalue, props)}
                                                                >
                                                                    <OZCustomToggleButton label="Settings" value="0" sx={{ ...tabButtonStyle, borderRight: ((props?.values?.campaignType?.value === "SMS_OUT") || (props?.values?.campaignType?.value === "PushData")) ? "1px solid #DBE1E6" : 0 }}>Settings</OZCustomToggleButton>
                                                                    {(props?.values?.campaignType?.value !== "SMS_OUT") && (props?.values?.campaignType?.value !== "PushData") && <OZCustomToggleButton label="General Info" value="1" sx={{ ...tabButtonStyle, borderRight: (props?.values?.campaignType?.value === "MissedCall") ? '1px solid #DBE1E6' : 0 }}>General Info</OZCustomToggleButton>}
                                                                    {(props?.values?.campaignType?.value === "Chat") && < OZCustomToggleButton label="Skills" value="2" sx={{ ...tabButtonStyle }}>Skills</OZCustomToggleButton>}
                                                                </TabList>
                                                            </Box>
                                                            <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                                                                <Divider sx={{ borderColor: "#E6E5E6", width: '60vw', marginTop: '-19px' }} />
                                                            </span>
                                                            {/* <div style={{ display: 'flex', alignContent: 'center' }}> */}
                                                            <div style={{ width: '60vw', minHeight: '23rem', borderRadius: '0px 0px 8px 8px', background: '#FFFFFF' }}>
                                                                <TabPanel value="0">
                                                                    <BasicSettings props={props} />
                                                                </TabPanel>
                                                                <TabPanel value="1">
                                                                    <GeneralInformation props={props} />
                                                                </TabPanel>
                                                                <TabPanel value="2">
                                                                    <Skills props={props} />
                                                                </TabPanel>
                                                            </div>
                                                        </TabContext>
                                                    </div>
                                                )
                                            }
                                            {newLoading && (
                                                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                                    <LoadingScreen />
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                )
                                }

                                {
                                    mode === ADMIN_FORM_MODES.add && (
                                        < div >
                                            <div >
                                                <Box sx={{ borderRadius: '0px 0px 3px 3px', width: '35rem', marginTop: 3 }}>
                                                    <Stepper activeStep={activeStep}>
                                                        {steps.map((label, index) => {
                                                            if ((props.values.campaignType.value === "PushData") || (props.values.campaignType.value === "SMS_OUT")) {
                                                                if (index === 0) {
                                                                    return (<Step key={label} completed={completed[index]}>
                                                                        <StepLabel color='inherit' onClick={handleStep(index)} StepIconComponent={StepperIC}>
                                                                            {label}
                                                                        </StepLabel>
                                                                    </Step>)
                                                                }
                                                            }
                                                            else if ((props.values.campaignType.value === "MissedCall")) {
                                                                if (index < 2) {
                                                                    return (<Step key={label} completed={completed[index]}>
                                                                        <StepLabel color='inherit' onClick={handleStep(index)} StepIconComponent={StepperIC}>
                                                                            {label}
                                                                        </StepLabel>
                                                                    </Step>)
                                                                }
                                                            }
                                                            else {
                                                                return (<Step key={label} completed={completed[index]}>
                                                                    <StepLabel color='inherit' onClick={handleStep(index)} StepIconComponent={StepperIC}>
                                                                        {label}
                                                                    </StepLabel>
                                                                </Step>)
                                                            }
                                                            return null;
                                                        })}
                                                    </Stepper>
                                                </Box>
                                            </div>
                                            <form onSubmit={props.handleSubmit} id='campaignMarketing-form' className='ozcw-scrollbar' style={{
                                                maxHeight: 'auto',
                                                overflowY: 'auto',
                                                padding: '5px'
                                            }}>
                                                <div style={{ width: '60vw', minHeight: '22rem', borderRadius: '8px', marginTop: 4, backgroundColor: '#FFFFFF' }}>
                                                    <React.Fragment >
                                                        {activeStep === 0 && (
                                                            <div style={stepperStyle}>
                                                                < BasicSettings props={props} />
                                                            </div>
                                                        )}
                                                        {activeStep === 1 && props.values.campaignType.value !== "SMS_OUT" && props.values.campaignType.value !== "PushData" && (
                                                            < div style={stepperStyle}>
                                                                <GeneralInformation props={props} />
                                                            </div>
                                                        )}
                                                        {activeStep === 2 && (
                                                            <div style={stepperStyle}>
                                                                <Skills props={props} />
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                </div>
                                            </form>
                                            {newLoading && (
                                                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                                    <LoadingScreen />
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                            </div >
                        )
                    }
                </Formik >
            </div >
        );
    }
    return (
        <div onClose={closeMarketingCampaignForm}
            mode={mode}
        >
            {mode === ADMIN_FORM_MODES.add && (
                <div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} alignSelf='center' onClick={closeMarketingCampaignForm}>{'Campaign / ' + mode + ' Campaign'}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px',
                                color: '#000000',
                            }} flexGrow={1} alignSelf='center'>{mode + ' Marketing Campaign'}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.view && (
                <div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} onClick={closeMarketingCampaignForm} alignSelf='center'>{'Campaign /  Marketing Campaign'}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px'
                            }} flexGrow={1} alignSelf='center'>{props.data.campaignName}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.edit && (
                <div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} alignSelf='center' onClick={closeMarketingCampaignForm}>{'Campaign /  Marketing Campaign'}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px'
                            }} flexGrow={1} alignSelf='center'>{props.data.campaignName}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.add && <DataForm />}
            {mode === ADMIN_FORM_MODES.view && <DataForm data={props.data} />}
            {mode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}

        </div>
    );
};

export default MarketingCampaignForm;
