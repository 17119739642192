import { Box, Grid } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import BreakDownDrawer from '../../BreakdownDrawer/BreakdownDrawer';

function ORVSCV() {
	const { useState, useRef } = React;
	const downloadContent = useRef<null | HTMLElement>(null);
	const [isBreakdownDrawerOpen, setIsBreakdownDrawerOpen] = useState<boolean | undefined>(false);

	const chartElement: any = useRef(null);

	const toggleBreakdownDrawer = (isOpen: boolean, data: any = {}) => {
		setIsBreakdownDrawerOpen(isOpen);
	};

	const options = {
		credits: { enabled: false },
		chart: {
			zoomType: 'xy'
		},
		title: '',
		xAxis: [
			{
				title: {
					text: 'Time ➡',
					style: {
						fontWeight: 'bold'
					}
				},
				plotLines: [
					{
						label: 'Target: 88%',
						color: 'red', // Color value
						dashStyle: 'dot', // Style of the plot line. Default to solid
						value: 14, // Value of where the line will appear
						width: 2 // Width of the line
					}
				],
				categories: ['06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				crosshair: true
			}
		],
		yAxis: [
			{
				// Primary yAxis
				labels: {
					style: {
						color: '#99A0A8'
					}
				},
				title: {
					text: 'Occupancy rate',
					style: {
						color: '#99A0A8'
					}
				}
			},
			{
				// Secondary yAxis
				title: {
					text: 'Total interaction volume',
					style: {
						color: '#99A0A8'
					}
				},
				labels: {
					style: {
						color: '#99A0A8'
					}
				},
				plotLines: [
					{
						label: 'Target: 88%',
						color: '#008DFF', // Color value
						dashStyle: 'dot', // Style of the plot line. Default to solid
						value: 50, // Value of where the line will appear
						width: 2 // Width of the line
					}
				],
				opposite: true
			}
		],
		tooltip: {
			shared: true
		},
		legend: false,
		series: [
			{
				name: 'Occupancy Rate',
				type: 'column',
				yAxis: 1,
				color: '#E3ECFD',
				data: [27.6, 28.8, 21.7, 34.1, 29.0, 28.4, 45.6, 51.7, 39.0, 60.0, 28.6, 32.1, 60.0, 28.6, 32.1, 0, 0],
				pointWidth: 10
			},
			{
				name: 'Call Volume',
				color: '#3296FB',
				data: [-13.6, -14.9, -5.8, -0.7, -13.6, -0.7, 14.5, 10.8, 5.8, -0.7, -11.0, -16.4, -13.6, -14.9, -5.8, 0, 0],
				point: {
					events: {
						click: function ({ point }: any) {
							toggleBreakdownDrawer(true);
						}
					}
				}
			}
		]
	};

	React.useEffect(() => {
		const resizeListener = () => {
			chartElement?.current?.chart?.reflow();
		};
		window.addEventListener('widgetResize', resizeListener);
		return () => {
			window.removeEventListener('widgetResize', resizeListener);
		};
	}, []);

	return (
		<>
			<div className="card-header">
				<Grid container direction="row" alignItems="center">
					<Grid item xs={0.4} className="legend-icon">
						<span className="legend-icon-hr"></span>
					</Grid>
					<Grid item xs={1}>
						<span>Call Volume</span>
					</Grid>
					<Grid item xs={0.2} className="legend-icon">
						<span className="legend-icon-rectangle"></span>
					</Grid>
					<Grid item xs>
						Occupancy Rate
					</Grid>
				</Grid>
			</div>
			<Box ref={downloadContent} sx={{ pt: 2, pl: 2 }}></Box>
			<HighchartsReact ref={chartElement} highcharts={Highcharts} options={options} />
			{isBreakdownDrawerOpen && <BreakDownDrawer isOpen={isBreakdownDrawerOpen} toggleDrawer={toggleBreakdownDrawer} widgetName={'OCCUPANCY_CALL'} />}
		</>
	);
}
export default ORVSCV;
