import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMemo, useState } from "react";

import { connect } from "formik";

const LockedAndStatusConfirmationDialog = connect(({ updateFunction, open, title, content, style, ...additionalProps }) => {

    const [openConfirmationDialog, setOpenConfirmationDialogState] = useState(false);

    const handleOnCloseConfirmationDialog = () => {
        setOpenConfirmationDialogState(false);
    };

    let dialogTextStyle = useMemo(
        () => ({
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 400,
            color: '#000000'
        }),
        []
    );

    let cancelButtonStyle = useMemo(
        () => ({
            height: '2.75rem',
            width: "5.6875rem",
            borderRadius: '0.5rem',
            fontSize: "0.875rem",
            fontWeight: 400,
            marginRight: '0.9375rem'
        }),
        []
    );

    return (
        <div style={style}>
            <Dialog sx={{
                '& .MuiDialog-container': { alignItems: 'baseline', marginTop: "47px" },
                "& .MuiDialog-paper": {
                    width: '458px',
                    borderRadius: "12px",
                    border: "1px solid #E6E5E6",
                    backgroundColor: '#FFFFFF',
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.04)"
                }
            }} open={open || openConfirmationDialog} onClose={additionalProps?.onClose || handleOnCloseConfirmationDialog}>
                <DialogTitle sx={{
                    ...dialogTextStyle,
                    fontSize: '1.125rem',
                    lineHeight: '1.375rem',
                }} >{title}</DialogTitle>
                <DialogContent>
                    < DialogContentText sx={{
                        ...dialogTextStyle,
                        fontSize: '0.875rem',
                        lineHeight: '1.0625rem',
                    }} >
                        {content}
                    </DialogContentText>
                </DialogContent>
                {!additionalProps.hideActions &&
                    <DialogActions sx={{ marginBottom: '10px' }}>
                        <Button sx={{
                            ...cancelButtonStyle, color: '#3D8BF8',
                            border: '0.0625rem solid #008DFF',
                        }} onClick={additionalProps?.onClose || handleOnCloseConfirmationDialog} variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            sx={{
                                ...cancelButtonStyle, backgroundColor: '#3D8BF8',
                                border: '0.0625rem solid #3D8BF8',
                                '&:hover': {
                                    backgroundColor: '#3D8BF8',
                                },
                            }}
                            onClick={updateFunction}
                            autoFocus
                            variant="contained">
                            Confirm
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </div >
    );
})

export default LockedAndStatusConfirmationDialog; 