import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from "@mui/material/Tooltip";
import { useMemo, useState } from "react";

function DeleteDialog(additionalProps) {

    const [openDeleteDialog, setOpenDeleteDialogState] = useState(false);

    const handleOnClickDeleteDialog = () => {
        setOpenDeleteDialogState(true);
    };

    const handleOnCloseDeleteDialog = () => {
        setOpenDeleteDialogState(false);
        additionalProps?.onClose()
    };

    let dialogTextStyle = useMemo(
        () => ({
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 400,
            color: '#000000'
        }),
        []
    );

    let cancelButtonStyle = useMemo(
        () => ({
            height: '2.75rem',
            width: "5.6875rem",
            borderRadius: '0.5rem',
            fontSize: "0.875rem",
            fontWeight: 400,
            marginRight: '0.9375rem'
        }),
        []
    );

    /*  function onCloseDialog() {
         additionalProps.onClose()
     } */

    function onClickSubmit() {
        additionalProps.deleteFunction()
        additionalProps.onClose()
    }

    return (
        <div style={additionalProps.style}>
            {(!additionalProps.open) && <Tooltip title="Delete" onClick={handleOnClickDeleteDialog}>
                {additionalProps.buttonIcon || <img style={additionalProps.imageStyle}
                    src={`${process.env.PUBLIC_URL}/icons/delete_button.svg`}
                    alt="delete"
                ></img>
                }
            </Tooltip>
            }
            <Dialog
                BackdropProps={{ style: { backgroundColor: (additionalProps.backdrop) ? 'transparent' : null } }}
                sx={{
                    '& .MuiDialog-container': { alignItems: 'baseline', marginTop: "47px" },
                    "& .MuiDialog-paper": {
                        width: '458px',
                        borderRadius: "12px",
                        border: "1px solid #E6E5E6",
                        backgroundColor: '#FFFFFF',
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.04)"
                    }
                }}
                open={additionalProps.open || openDeleteDialog} onClose={handleOnCloseDeleteDialog}>
                <DialogTitle sx={{
                    ...dialogTextStyle,
                    fontSize: '1.125rem',
                    lineHeight: '1.375rem',
                }} >{additionalProps.title}</DialogTitle>
                <DialogContent>
                    < DialogContentText sx={{
                        ...dialogTextStyle,
                        fontSize: '0.875rem',
                        lineHeight: '1.0625rem',
                    }} >
                        {additionalProps.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ marginBottom: '10px' }}>
                    <Button sx={{
                        ...cancelButtonStyle, color: '#3D8BF8',
                        border: '0.0625rem solid #008DFF',
                    }} onClick={additionalProps?.onClose || handleOnCloseDeleteDialog} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            ...cancelButtonStyle, backgroundColor: additionalProps.mode === "Add" ? "#3D8BF8" : '#E65B5C',
                            border: additionalProps.mode === "Add" ? "0.0625rem solid #3D8BF8" : '0.0625rem solid #E65B5C',
                            '&:hover': {
                                backgroundColor: additionalProps.mode === "Add" ? "#3D8BF8" : '#E65B5C',
                            },
                        }}
                        onClick={onClickSubmit}
                        autoFocus
                        variant="contained">
                        {additionalProps.mode === "Add" ? "Confirm" : "Delete"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default DeleteDialog; 
