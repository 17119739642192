import React, { useState } from 'react';
import { getValueInStorage, setValueInStorage } from '../services/storage-service';
import { LOCAL_STORAGE } from '../utils/constants';

export const OZContext = React.createContext<any>({});
export const OZContextProvider = ({ children }: any) => {
	const localDashboardTabs = getValueInStorage(LOCAL_STORAGE.DASHBOARD_TABS);
	const localSelectedDashboard = getValueInStorage(LOCAL_STORAGE.SELECTED_DASHBOARD);
	const [selectedDashboard, setSelectedDashboard] = useState<any>(localSelectedDashboard || {});
	const [dashboardTabs, setDashboardTabs] = useState<any[]>(localDashboardTabs || []);
	const [isLoaderOpen, setIsLoaderOpen] = useState<boolean>(false);
	const defaultValue = {
		selectedDashboard,
		setSelectedDashboard,
		dashboardTabs,
		setDashboardTabs,
		isLoaderOpen,
		setIsLoaderOpen
	};
	React.useEffect(() => {
		setValueInStorage(LOCAL_STORAGE.DASHBOARD_TABS, dashboardTabs);
	}, [dashboardTabs]);
	return <OZContext.Provider value={defaultValue}>{children}</OZContext.Provider>;
};
