import React, { useState, useEffect } from 'react'
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import { Button, Stack, Tooltip } from '@mui/material';
import CustomSelect from '../Users/CustomSelect';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../../components/admin/common/NotiService';
import { getDidListByIntegration, getZohoMappings, saveZohoDidMapping } from '../../../services/api-service';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';

export default function VoicemailsIntegration() {

  const [voiceMailIntegrationList, setVoiceMailIntegrationList] = useState([]);
  console.log(voiceMailIntegrationList)
  const [didZohoData, setDidZohoData] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    getZohoMappingsDetails();
  }, []);

  useEffect(() => {
    getDIDList();
  }, [didZohoData])

  async function getZohoMappingsDetails() {
    setLoading(true);
    try {
      getZohoMappings()
        .then((resp) => {
          if (resp.Status === "Success") {
            let zohoData = resp.Data;
            let filteredZohoDidMap = {};
            Object.entries(zohoData.zohoDIDMap).forEach(([key, value]) => {
              if (value !== "-1" && value !== -1) {
                filteredZohoDidMap[key] = value;
              }
            });
            setDidZohoData({
              zohoDIDMap: filteredZohoDidMap,
              zohoUsers: zohoData.zohoUsers
            });
            const zohoUsersValues = Object.values(zohoData.zohoUsers);
            const updatedOptions = ["DeSelect", ...zohoUsersValues];
            setOptions(updatedOptions);
          }
          else {
            showErrorNotification("Zoho Mappings Cannot Be Fetched .", resp.Message);
            setLoading(false);
          }
        })
        .catch((e) => {
          showErrorNotification("Error in fetching Zoho Mappings");
          // setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in getting Zoho Mappings");
      setLoading(false);
    }
  };

  const [options, setOptions] = useState([])

  const ZohoUserRenderer = (props) => {

    const [zohoUser, setZohoUser] = useState(props.data.zohoUser);
    const [isEditMode, setEditMode] = useState(false);

    const toggleEditMode = () => {
      setEditMode(!isEditMode);
      setZohoUser(props.data.zohoUser);
    };

    async function saveDidZohoMapping() {
      const zohoUserVal = zohoUser;
      let result = null;
      for (const [key, value] of Object.entries(didZohoData.zohoUsers)) {
        if (value === zohoUserVal) {
          result = key;
          break;
        }
      }
      if (zohoUserVal === "DeSelect") {
        result = "-1";
      }
      let data = {
        caDid: props.data.DID,
        zohoDid: result
      }
      try {
        setLoading(true);
        saveZohoDidMapping(data)
          .then((resp) => {
            if (resp.Status === "Success") {
              if (zohoUserVal !== "DeSelect") {
                showSuccessNotification(`DID ${props.data.DID} is Successfully Mapped with zohoUser ${zohoUser}`);
              }
              if (zohoUserVal === "DeSelect") {
                showSuccessNotification(`DID ${props.data.DID} is Successfully UnMapped`);
              }
              setEditMode(false);
              getZohoMappingsDetails();
            }
            else {
              showErrorNotification("Error in save of Zoho Did Mapping.", resp.Message);
              setLoading(false);
            }
          })
          .catch((e) => {
            showErrorNotification("Error in saving Zoho Did Mapping");
            // setLoading(false);
          });
      } catch (e) {
        showErrorNotification("Error in Zoho Did Mapping Saving");
        setLoading(false);
      }
    }

    return (
      <>
        {!isEditMode &&
          <div className='editButtonPosition'>
            <div>{zohoUser}</div>
            <Button variant="text" onClick={toggleEditMode} className="editButton"> Edit </Button>
          </div>
        }
        {isEditMode &&
          <>
            <Stack direction="row">
              <CustomSelect
                options={options}
                file="integration"
                placeholder={zohoUser}
                onChange={setZohoUser}
              />
              <Stack direction="row" spacing="20px">
                <Button variant="text" onClick={toggleEditMode} className='discardButton'> Discard </Button>
                <Button variant="contained" className={(zohoUser !== "" && props.data.zohoUser !== zohoUser) ? (zohoUser === "DeSelect") ? (props.data.zohoUser !== "") ? 'saveButton' : 'notAllowSaveButton' : 'saveButton' : 'notAllowSaveButton'} onClick={(zohoUser !== "" && props.data.zohoUser !== zohoUser) ? (zohoUser === "DeSelect") ? (props.data.zohoUser !== "") ? saveDidZohoMapping : null : saveDidZohoMapping : null} size="small"> Save </Button>
              </Stack>
            </Stack>
          </>
        }
      </>
    );
  };

  const ColumnRenderer = (props) => {
    return (
      <Tooltip title={(props.value) ? props.value : ''}>
        <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", cursor: "pointer" }}>{props.value}</span>
      </Tooltip>
    );
  };

  const columnDefs = ([
    { field: 'DID', headerName: 'DID', maxWidth: 320, cellRenderer: ColumnRenderer },
    { field: 'zohoUser', headerName: 'Zoho User', cellRenderer: ZohoUserRenderer },
  ]);

  async function getDIDList() {
    try {
      getDidListByIntegration("Zoho")
        .then((resp) => {
          if (resp.Status === "Success") {
            let arrayOfObjects = resp.Data
            const updatedArrayOfObjects = arrayOfObjects.map(obj => {
              const modifiedDID = obj.DID.toLowerCase().replace(/[a-z]/g, char => char.toLowerCase());
              const zohoUser = didZohoData.zohoDIDMap.hasOwnProperty(modifiedDID) ?
                (didZohoData.zohoUsers.hasOwnProperty(didZohoData.zohoDIDMap[modifiedDID]) ?
                  didZohoData.zohoUsers[didZohoData.zohoDIDMap[modifiedDID]] : "") : "";
              return {
                ...obj,
                zohoUser: zohoUser
              };
            });
            setVoiceMailIntegrationList(updatedArrayOfObjects);
            setLoading(false);
          }
          else {
            showWarningNotification(resp.Message);
            setLoading(false);
          }
        })
        .catch((e) => {
          setVoiceMailIntegrationList({});
          // setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in did list");
      setLoading(false);
    }
  };

  return (
    <div className="ozAdminSubIntegrationContainer">
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        <OZDataGrid height="26.3rem" data={voiceMailIntegrationList} columns={columnDefs} />
      }
    </div>
  );
};