import React, { useEffect, useState } from "react";
import './WorkHourSettings.css';
import { Autocomplete, FormHelperText, Grid, IconButton, TextField, Typography } from "@mui/material";
import TimePicker from "rc-time-picker";
import moment from 'moment';
import { showErrorNotification } from "../../../components/admin/common/NotiService";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import { connect, getIn } from 'formik';


const WorkHoursSettings = connect(({props, formik, mode}) => {
    // console.log("here prosp coming ", props, mode);
    const daysOfWeek = ['All','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

      const [workDays, setWorkDays] = useState(props?.values?.workDays || []);
      const [usedDays, setUsedDays] = useState([]);
      const [disableAddDayButton, setDisableAddDayButton] = useState(false);
      const [disableAddTimeSlotButton, setDisableAddTimeSlotButton] = useState(false);

    useEffect(() => {
        const extractedDays = workDays.reduce((acc, workDay) => {
            return acc.concat(workDay.days || []);
        }, []);
        setUsedDays([...new Set(extractedDays)]);
    }, [workDays]);

    useEffect(() => {
        if(formik?.errors?.workDays){
            formik?.errors?.workDays?.map((item) => {
                setDisableAddDayButton(item?.hasOwnProperty('days') ? true : false);
                setDisableAddTimeSlotButton(item?.hasOwnProperty('timeSlots') ? true : false);
            })
        }else{
            setDisableAddDayButton(false);
            setDisableAddTimeSlotButton(false);
        }
    }, [formik?.errors]);

    const validateWorkDays = (workDays) => {
        let validationErrors = [];
    
        workDays.forEach((workDay, dayIndex) => {
            if (!workDay.days || workDay.days.length === 0 || workDay.days === "") {
                validationErrors.push(`Please select Days at ${dayIndex + 1} index`);
                return;
            }
            let previousEndTime = null;
    
            const sortedTimeSlots = [...workDay.timeSlots].sort((a, b) => {
                return moment(a.fromTime, 'HH:mm:ss').diff(moment(b.fromTime, 'HH:mm:ss'));
            });
    
            sortedTimeSlots.forEach((slot, slotIndex) => {
                const fromTime = moment(slot.fromTime, 'HH:mm:ss');
                const toTime = moment(slot.toTime, 'HH:mm:ss');
    
                if (!fromTime.isBefore(toTime)) {
                    validationErrors.push(`FromTime must be earlier than toTime of Day ${dayIndex + 1} index at slot ${slotIndex + 1} index`);
                } 
                else if (previousEndTime && fromTime.isBefore(previousEndTime)) {
                    validationErrors.push(`FromTime overlaps with the previous time slot of Day ${dayIndex + 1} index at slot ${slotIndex + 1} index`);
                }
    
                previousEndTime = toTime;
            });
        });
    
        return validationErrors;
    };

    useEffect(() => {
        const errors = validateWorkDays(workDays);
        if (errors.length > 0) {
            showErrorNotification(errors);
        } else {
            // console.log("No validation errors!");
        }
    }, [workDays]);
    
    const addWorkDay = () => {
        if (usedDays.length < 7) {
            const newWorkDays = [
                ...workDays,
                { days: [], timeSlots: [{ fromTime: '00:00:00', toTime: '00:00:00' }] }
            ];
            setWorkDays(newWorkDays);
            props.setFieldValue('workDays', newWorkDays);
        }
    
    };

    // Handle deleting a work day
    const deleteWorkDay = (index) => {
        const updatedWorkDays = [...workDays];
        const removedDay = updatedWorkDays[index]?.days || [];
        updatedWorkDays.splice(index, 1);
        setWorkDays(updatedWorkDays);
        setUsedDays(usedDays.filter((day) => !removedDay.includes(day)));

        props.setFieldValue('workDays', updatedWorkDays);
    };

    const handleDayChange = (index, selectedDays) => {
        const updatedWorkDays = [...workDays];
        const otherSelectedDays = updatedWorkDays
        .filter((_, i) => i !== index)
        .reduce((acc, day) => acc.concat(day.days), []);
        
        const availableDays = daysOfWeek.filter(day => !otherSelectedDays.includes(day) && day !== 'All');

        if (selectedDays.includes('All')) {
            if(updatedWorkDays[index].days.length === availableDays.length){
                updatedWorkDays[index].days = [];
            }else{
                updatedWorkDays[index].days = availableDays;
            }
        } else {
            const validDays = selectedDays.filter(day => !otherSelectedDays.includes(day));
            updatedWorkDays[index].days = validDays;
        }

        setWorkDays(updatedWorkDays);
        props.setFieldValue('workDays', updatedWorkDays);
    
        const allSelectedDays = updatedWorkDays.reduce((acc, day) => acc.concat(day.days), []);
        setUsedDays([...new Set(allSelectedDays)]);
    };

    const addTimeSlot = (index) => {
        const updatedWorkDays = [...workDays];
        updatedWorkDays[index].timeSlots.push({ fromTime: "00:00:00", toTime: "00:00:00" });
        setWorkDays(updatedWorkDays);
        props.setFieldValue('workDays', updatedWorkDays);
    };
    
    const deleteTimeSlot = (dayIndex, slotIndex) => {
        const updatedWorkDays = [...workDays];
        updatedWorkDays[dayIndex].timeSlots.splice(slotIndex, 1);

        if (updatedWorkDays[dayIndex].timeSlots.length === 0) {
            deleteWorkDay(dayIndex);
        } else {
            setWorkDays(updatedWorkDays);
            props.setFieldValue('workDays', updatedWorkDays);
        }
    };
    
    // Handle time change
    const handleTimeChange = (dayIndex, slotIndex, type, value) => {
        const updatedWorkDays = [...workDays];
        updatedWorkDays[dayIndex].timeSlots[slotIndex][type] = value;
        setWorkDays(updatedWorkDays);
        props.setFieldValue('workDays', updatedWorkDays);
        props?.validateForm();
    };

    return (
        <Grid item xs={12} className="schedule-container">
            <div style={{display:'flex', alignItems: 'center'}}>
                <Typography flexGrow={1} fontSize={"16px"} fontWeight={500} color={"#546881"}>
                    Work Hours Schedule
                </Typography>
                <div>
                    <img
                        className={disableAddDayButton || mode === ADMIN_FORM_MODES.view || props?.values?.position === "RUNNING" || props?.values?.dncEnabled || usedDays?.length >= 7 ? 'image-icon-disable' : ''}
                        style={{ marginTop: '5px', paddingLeft: '15px', cursor: disableAddDayButton || mode === ADMIN_FORM_MODES.view || props?.values?.position === "RUNNING" || props?.values?.dncEnabled || usedDays?.length >= 7 ? null : 'pointer'}}
                        src={`${process.env.PUBLIC_URL}/icons/anotherlevelblue.svg`}
                        alt="addCallWork"
                        onClick={disableAddDayButton || mode === ADMIN_FORM_MODES.view || props?.values?.position === "RUNNING" || props?.values?.dncEnabled || usedDays?.length >= 7 ? null : addWorkDay}
                    >
                    </img>
                </div>
            </div>

            {
                workDays.map((workDay, dayIndex) => {
                    const daysField = `workDays[${dayIndex}].days`;
                    const timeSlotsField = `workDays[${dayIndex}].timeSlots`;

                    const errorDays = getIn(formik.errors, daysField);
                    const touchDays = getIn(formik.touched, daysField);

                    const errorTimeSlots = getIn(formik.errors, timeSlotsField);
                    const touchTimeSlots = getIn(formik.touched, timeSlotsField);

                    return (
                        <div key={dayIndex} className="work-day">
                            <div className="work-day-header">
                                <Autocomplete
                                    disabled={mode === ADMIN_FORM_MODES.view || props?.values?.position === "RUNNING" || props?.values?.dncEnabled}
                                    multiple
                                    options={daysOfWeek.filter(day => !usedDays.includes(day))}
                                    getOptionLabel={(option) => option}
                                    // limitTags={2}
                                    value={workDay.days || []}
                                    onChange={(e, selectedDays) => handleDayChange(dayIndex, selectedDays)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            sx={{
                                                minWidth: "250px",
                                                maxWidth: '300px',
                                                // paddingRight:"10px",
                                                '& .MuiOutlinedInput-root': {
                                                    minHeight: "44px",
                                                    fontSize: "12px",
                                                    borderRadius: '8px',
                                                },
                                                // '& .MuiAutocomplete-input': {
                                                //     display: (props?.mode !== ADMIN_FORM_MODES.add) ? 'none' : 'block'
                                                // },
                                                // "& .MuiOutlinedInput-root.Mui-focused": {
                                                //     "& > fieldset": props?.mode === ADMIN_FORM_MODES.add ? {
                                                //         borderBottomLeftRadius: "0px", borderBottomRightRadius: '0px', border: "1px solid #E5E5E5"
                                                //     } : { border: "1px solid #E5E5E5" },
                                                // },
                                                // "& .MuiOutlinedInput-root:hover": {
                                                //     "& > fieldset": {
                                                //         border: "1px solid #E5E5E5"
                                                //     }
                                                // },
                                                // "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                                                //     textOverflow: "ellipsis"
                                                // }
                                            }}
                                            size="small"
                                            fullWidth
                                            InputLabelProps={{ shrink: false }}
                                        />
                                    )}
                                />
                                <img
                                    className={(touchTimeSlots && errorTimeSlots) || disableAddTimeSlotButton || props?.values?.position === "RUNNING" || props?.values?.dncEnabled ? 'image-icon-disable' : ''}
                                    style={{  marginTop: '0px', paddingLeft: '5px', height: '25px', width: '36px', cursor: (touchTimeSlots && errorTimeSlots) || disableAddTimeSlotButton || props?.values?.position === "RUNNING" || props?.values?.dncEnabled ? null : 'pointer'}}
                                    src={`${process.env.PUBLIC_URL}/icons/AddIconRound.svg`}
                                    alt="addTimeSlot"
                                    onClick={() => (touchTimeSlots && errorTimeSlots) || disableAddTimeSlotButton || props?.values?.position === "RUNNING" || props?.values?.dncEnabled ? null : addTimeSlot(dayIndex)}
                                >
                                </img>
                            </div>
                            <FormHelperText error>{touchDays && errorDays}</FormHelperText>

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div flexGrow={1}>
                                    {workDay.timeSlots.map((slot, slotIndex) => {
                                        const fromTimeField = `workDays[${dayIndex}].timeSlots[${slotIndex}].fromTime`;
                                        const toTimeField = `workDays[${dayIndex}].timeSlots[${slotIndex}].toTime`;
                                        // const errorFromTime = getIn(formik.errors, fromTimeField);
                                        // const touchFromTime = getIn(formik.touched, fromTimeField);

                                        // const errorToTime = getIn(formik.errors, toTimeField);
                                        // const touchToTime = getIn(formik.touched, toTimeField);

                                        return (
                                            <>
                                            <div key={slotIndex} className="time-slot">
                                                <div className="custom-time-picker">
                                                    <TimePicker
                                                        disabled={mode === ADMIN_FORM_MODES.view || props?.values?.position === "RUNNING" || props?.values?.dncEnabled}
                                                        format="HH:mm:ss"
                                                        showSecond={true}
                                                        use12Hours={false}
                                                        value={slot.fromTime ? moment(slot.fromTime, 'HH:mm:ss') : null}
                                                        onChange={(value) => handleTimeChange(dayIndex, slotIndex, 'fromTime', value ? value.format('HH:mm:ss') : null)}
                                                        placeholder="From Time"
                                                        name={fromTimeField}
                                                        id={fromTimeField}
                                                        popupStyle={{
                                                            borderRadius: '8px',
                                                            overflow: 'hidden',
                                                            zIndex: '9999',
                                                        }}
                                                    />
                                                </div>
                                                <div style={{paddingRight: '50px'}}>
                                                    to
                                                </div>
                                                <div className="custom-time-picker">
                                                    <TimePicker
                                                        disabled={mode === ADMIN_FORM_MODES.view || props?.values?.position === "RUNNING" || props?.values?.dncEnabled}
                                                        format="HH:mm:ss"
                                                        showSecond={true}
                                                        use12Hours={false}
                                                        value={slot.toTime ? moment(slot.toTime, 'HH:mm:ss') : null}
                                                        onChange={(value) => handleTimeChange(dayIndex, slotIndex, 'toTime', value ? value.format('HH:mm:ss') : null)}
                                                        placeholder="To Time"
                                                        popupStyle={{
                                                            borderRadius: '8px',
                                                            overflow: 'hidden',
                                                            zIndex: '9999',
                                                        }}
                                                        name={toTimeField}
                                                        id={toTimeField}
                                                    />
                                                </div>
                                                <img
                                                    className={(touchTimeSlots && errorTimeSlots) || disableAddTimeSlotButton || props?.values.position === "RUNNING" || props?.values.dncEnabled ? 'image-icon-disable' : ''}
                                                    // style={{ marginTop: '-2px', paddingLeft: '5px', cursor: 'pointer'}}
                                                    src={`${process.env.PUBLIC_URL}/icons/deleteIconGrey.svg`}
                                                    alt="delete"
                                                    onClick={() => mode === ADMIN_FORM_MODES.view || props?.values?.position === "RUNNING" || props?.values?.dncEnabled ? null : deleteTimeSlot(dayIndex, slotIndex)}
                                                >
                                                </img>
                                            </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                            <div>
                                <FormHelperText error>{touchTimeSlots && errorTimeSlots}</FormHelperText>
                            </div>
                        </div>
                    );
                })
            }
        </Grid>
    );
});

export default WorkHoursSettings;
