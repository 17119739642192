import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, IconButton, Link, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import React from 'react';
import OZCheckbox from '../OZCheckbox/OZCheckbox';

import '../CarouselWidgetDrawer/CarouselWidgetDrawer.css';
import { WIDGET_LIST, WIDGET_NAMES } from '../../utils/constants';
import { checkRoles } from '../../utils/util';

const useStyles: any = makeStyles((theme: Theme) => ({
	widgetItemsContainer: {
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column'
	},
	filterHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	headerText: {
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '21px',
		color: '#212121'
	},
	drawerGutter: {
		paddingTop: '74px'
	}
}));

type Props = {
	toggleDrawer: any;
	isOpen: boolean | undefined;
	selectWidgets: any;
};

const CarouselWidgetDrawer = ({ isOpen, toggleDrawer, selectWidgets }: Props) => {
	const classes = useStyles();
	const { useState } = React;
	const [selectedWidgets, setSelectedWidgets] = useState<any[]>([]);
	const [searchText, setSearchText] = useState<string>('');

	const handleOnCheckboxChange = (event: any) => {
		if (event?.target?.checked && selectedWidgets.length >= 5) return;
		if (event?.target?.checked && event?.target?.value) {
			const existingSelectedFilters: any = [...selectedWidgets];
			existingSelectedFilters.push(event.target.value);
			setSelectedWidgets(existingSelectedFilters);
		} else {
			const newSelectedFilters: any = selectedWidgets.filter((item) => item.id !== event?.target?.value?.id);
			setSelectedWidgets(newSelectedFilters);
		}
	};

	const isChecked = (item: any) => {
		return selectedWidgets.some((filterItem) => item.widgetName === filterItem.id);
	};
	const withoutCarousel = (WIDGET_LIST).filter((item: any) => item.widgetName !== WIDGET_NAMES.CAROUSEL);
	const finalWidgetList = searchText.length ? withoutCarousel.filter((item: any) => item.label.toLowerCase().includes(searchText.toLowerCase())) : withoutCarousel;
	const widgetItemListElements = finalWidgetList?.map((item: any, index: number) => (
		<Grid key={index} item xs={6} className="filter-checkbox-col">
			<OZCheckbox checked={isChecked(item)} label={item.label} id={item.widgetName} item={item} onChange={handleOnCheckboxChange} />
		</Grid>
	));

	const handleSearchTextChange = (event: any) => {
		setSearchText(event?.target?.value);
	};

	const handleOnApply = () => {
		selectWidgets(selectedWidgets);
	};

	return (
		<Drawer open={isOpen} anchor={'right'} onClose={() => toggleDrawer(false)}>
			<Box sx={{ width: 455 }} className={classes.widgetItemsContainer}>
				<div className={classes.drawerGutter}>
					<div className={classes.filterHeader}>
						<span className={classes.headerText}>Carousel</span>
						<IconButton aria-label="delete" size="small" onClick={() => toggleDrawer(false)}>
							<CloseIcon style={{ fontSize: '22px', color: '#99a0a8' }} />
						</IconButton>
					</div>
					<div className="drawer-search-container">
						<>
							<div className="drawer-search-bar">
								<input type="text" placeholder="Search" value={searchText} onChange={handleSearchTextChange} />
								<img src={`${process.env.PUBLIC_URL}/img/search-icon.svg`} width="12" alt="Widget Search"></img>
							</div>
						</>
					</div>
				</div>
				<div>
					<Grid container spacing={2}>
						<Grid item xs={6}></Grid>
						<Grid item xs={6}>
							<span className="widget-select-info">{selectedWidgets.length} of 5 selected</span>
						</Grid>
					</Grid>
				</div>
				<div className="filter-items-container">
					<Grid container spacing={2}>
						<>{widgetItemListElements}</>
					</Grid>
				</div>
				<div className="filter-items-footer">
					<Button variant="contained" onClick={handleOnApply}>
						Apply
					</Button>
					<div className="footer-line">
						<img src={`${process.env.PUBLIC_URL}/img/info-icon.svg`} width="12" alt="Info"></img> Only 5 widgets can be on carousel
					</div>
				</div>
			</Box>
		</Drawer>
	);
};

export default CarouselWidgetDrawer;
