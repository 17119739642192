import React, { useState, useMemo, useEffect } from 'react'
import { whatsAppIntegrationviaFacebook, whatsAppDIDURL, getDidDropdown, getSecondaryDidDropdown, saveDigitalCampaigns } from '../../../services/api-service';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { Formik } from 'formik';
import OZDialog from '../../../components/admin/common/OZDialog';
import OZSelect from '../../../components/admin/common/OZSelect';
import * as yup from 'yup';
import { Button } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import { Grid } from "@mui/material";
import OzTbAudioPlayer from '../../../components/admin/common/OZAudioPlayer/OzTbAudioPlayer';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
// import dummyAudio from './dummyaudio.mp3'

const WhatsAppIntegrationForm = (mainprops) => {
    const [didList, setDidList] = useState([]);
    const [didWhatsAppIntegrationViaOwnWhatsApp, setDidWhatsAppIntegrationViaOwnWhatsApp] = useState(false);
    let clickedRevoke = false;
    const [revokeDID, setRevokeDID] = useState('');
    const [revokeConfirmationdia, setRevokeConfirmationdia] = useState(false);
    const [didOnOtpScreen, setDidOnOtpScreen] = useState('');
    const [otpAudioScreen, setOtpAudioScreen] = useState(false);
    const [audiourl, setAudioUrl] = useState('');
    const [oncloseConfirmationdia, setOncloseConfirmationdia] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDidList();
    }, []);

    async function getDidList() {
        try {
            getSecondaryDidDropdown()
                .then((resp) => {
                    const didArray = resp.Data !== null ? JSON.parse(resp.Data).map(item => item?.DID) : [];
                    // console.log(didArray)
                    const alreadyAssignedDIDs = mainprops.alreadyAssignedDIDs;
                    // Filter out DIDs that are not present in alreadyAssignedDIDs
                    const filteredDIDs = didArray.filter(did => !alreadyAssignedDIDs.includes(did));
                    // console.log(filteredDIDs)
                    setDidList(filteredDIDs)
                })
                .catch((e) => {
                    console.log(e)
                    setDidList([]);
                });
        } catch (e) {
            showErrorNotification("Error in fetching did list");
            setDidList([]);
        }
    };

    const setClickedRevoke = (value) => {
        clickedRevoke = value
    };

    const validationSchemaOnRevoke = useMemo(
        () =>
            yup.object({
                did: yup
                    .string()
                    .required("did is required")
            }),
        []
    );

    const validationSchema = useMemo(
        () =>
            yup.object({
                did: yup
                    .string()
            }),
        []
    );

    const validationSchemaForSaveOldWhatsAppIntegration = useMemo(
        () =>
            yup.object({
                did: yup
                    .string()
                    .required("DID or UUID is required")
                    .matches(/^[\d+]{1,1}[\d]{3,12}$/, 'Did should be between 4 to 12 digits long and allows prefix +')
                    .test('is-unique', 'DID already taken', function (value) {
                        return !mainprops.alreadyAssignedDIDs.includes(value);
                    }),
            }),
        [mainprops.alreadyAssignedDIDs]
    );

    async function saveDigitalCampaignOldIntegration(values) {
        setLoading(true);
        console.log(values);
        let data = {
            campaignId: mainprops.campaignId,
            clientId: `whatsapp:${values.did}`,
            integrationName: "WhatsappChat",
        }
        try {
            saveDigitalCampaigns(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification("Whatsapp Integration Of Digital Campaign is Saved Successfully")
                        setLoading(false);
                        mainprops.getDigitalCampaignDetails()
                        mainprops.close()
                    }
                    else {
                        showErrorNotification("Error Saving Digital Campaign Integration");
                        setLoading(false)
                        mainprops.getDigitalCampaignDetails();
                        mainprops.close()
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error Saving Digital Campaign Integration");
                    setLoading(false)
                    console.log(e)
                    mainprops.getDigitalCampaignDetails();
                    mainprops.close()
                });
        } catch (e) {
            showErrorNotification("Error Saving Digital Campaign Integration");
            setLoading(false)
            mainprops.getDigitalCampaignDetails();
            mainprops.close()
        }
    }

    const DataForm = (formProps) => {

        let initData = {
            did: otpAudioScreen ? didOnOtpScreen : ""
        };

        const whatsAppDIDFunction = (whatsAppDID, data) => {
            const url = `whatsAppOtp.html ? ajax = true` // this should be replaced by http request
            let JSONdata = JSON.stringify(data);
            try {
                whatsAppDIDURL(url, JSONdata)
                    .then((response) => {
                        console.log(`revoke api response:${response}`)
                        if (response && response.otpResponse) {
                            let resp = JSON.parse(data.otpResponse);
                            let status = resp.status;
                            let message = resp.message;
                            if (status === "success") {
                                if (JSON.stringify(data.otpAction === "revoke")) {
                                    mainprops.close();
                                }

                                // Need to remove the below if integrate with did dosent require otp verification screen

                                else if (JSON.stringify(data.otpAction === "set")) {
                                    whatsAppDIDFunction(whatsAppDID, {
                                        did: whatsAppDID,
                                        otpAction: "fetch"
                                    })
                                    setDidOnOtpScreen(whatsAppDID);
                                }

                                // Below is the code if integrate with did dosent require otp verification screen

                                // else if (whatsAppDID === didOnOtpScreen && JSON.stringify(data.otpAction === "set")) {
                                //     whatsAppDIDFunction(whatsAppDID, {
                                //         did: whatsAppDID,
                                //         otpAction: "fetch"
                                //     })
                                // }

                                // else if (whatsAppDID !== didOnOtpScreen && JSON.stringify(data.otpAction === "set")) {
                                //     mainprops.close()
                                // }

                                else if (JSON.stringify(data.otpAction === "fetch")) {
                                    if (message !== false || message !== "false") {
                                        setAudioUrl(message);
                                        console.log(`audioUrl: ${audiourl}`);
                                        if (otpAudioScreen === false) {
                                            setOtpAudioScreen(true);
                                        }
                                    }
                                }
                            }
                        }
                    }).catch((e) => {
                        showErrorNotification(`api not Successfull: ${e}`)
                    })
            }
            catch (e) {
                showErrorNotification(`api failed:${e}`)
            }
        };

        const facebookLogin = (whatsAppDID) => {
            window.FB.login(function (response) {
                console.log("response:", response);
                if (response.status === 'connected') {
                    console.log('Facebook login succeeded');
                    if (response.authResponse) {
                        const accessToken = response.authResponse.accessToken;
                        console.log(`accessToken is: ${accessToken}`)
                        const campaignId = mainprops.campaignId;
                        const url = (whatsAppDID !== undefined) ? `integrateWhatsApp.html?ajax=true&campaignId=${campaignId}&access_token=${accessToken}&called_number=${whatsAppDID}` : `integrateWhatsApp.html?ajax=true&campaignId=${campaignId}&access_token=${accessToken}&called_number=''`; // this should be replaced by http request
                        try {
                            whatsAppIntegrationviaFacebook(url).then(response => {
                                console.log(`whatsAppIntegration via facebook api response:${response}`)
                                if (response.status.toLowerCase() === "success") {
                                    console.log('whatsApp Integration via facebook success')
                                    if (whatsAppDID !== '') {
                                        whatsAppDIDFunction(whatsAppDID, {
                                            did: whatsAppDID,
                                            otpAction: "set"
                                        });
                                    }
                                    if (whatsAppDID === '' || whatsAppDID === undefined || whatsAppDID === null) {
                                        let whatsAppDID = ''; // here I think I need to give the bussiness whatsApp number connected with facebook since no did is selected 
                                        // setDidOnOtpScreen(whatsAppDID)  // need to do like this if integrate with did 
                                        whatsAppDIDFunction(whatsAppDID, {
                                            did: whatsAppDID,
                                            otpAction: "set"
                                        })
                                    }
                                }
                                else {
                                    showErrorNotification('whatsApp Integration via facebook not Successfull');
                                }
                            }).catch(e => {
                                showErrorNotification('whatsApp Integration via facebook failed');
                            })
                        }
                        catch (e) {
                            showErrorNotification('whatsApp Integration via facebook failed')
                        }
                    }
                    else {
                        console.log("Error in getting authResponse")
                    }

                } else {
                    console.error('Facebook login failed, response: ', response);
                }
            }, {
                scope: 'business_management,whatsapp_business_management,public_profile',
                extras: {
                    feature: 'whatsapp_embedded_signup'
                }
            });
        };

        const getValidationSchema = () => {
            if (mainprops.whatsAppOlderConfigDialog) {
                return validationSchemaForSaveOldWhatsAppIntegration
            } else {
                if (clickedRevoke) {
                    return validationSchemaOnRevoke
                } else {
                    return validationSchema
                }
            }
        };

        return (
            <div>
                <Formik
                    initialValues={initData}
                    validationSchema={getValidationSchema}
                    onSubmit={(values) => {
                        if (values.did === '' && !clickedRevoke && !mainprops.whatsAppOlderConfigDialog) {
                            setDidWhatsAppIntegrationViaOwnWhatsApp(true)
                        }
                        else {
                            if (mainprops.whatsAppOlderConfigDialog === true) {
                                saveDigitalCampaignOldIntegration(values)
                            }
                            else if (clickedRevoke) {
                                setRevokeDID(values.did);
                                setRevokeConfirmationdia(true);
                            }
                            else {
                                facebookLogin(values.did);
                            }
                        }
                    }}
                >
                    {
                        (props) => (
                            <div>
                                <form
                                    onSubmit={props.handleSubmit}
                                    id="whatsAppIntegrationForm"
                                    style={{
                                        width: "400px",
                                    }}
                                >
                                    {!didWhatsAppIntegrationViaOwnWhatsApp && !mainprops.whatsAppOlderConfigDialog && !oncloseConfirmationdia && !revokeConfirmationdia &&
                                        <div>
                                            <div style={{ display: "flex", wordBreak: "break-all !important", whiteSpace: "normal !important" }}>
                                                Note: Incoming calls to the DID are temporarily blocked until the WhatsApp integration is complete. Keep this window open and avoid refreshing until the process is completed.
                                            </div>
                                            {!otpAudioScreen &&
                                                <div style={{ marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <OZSelect
                                                        name="did"
                                                        label="DID *"
                                                        options={didList}
                                                        placeholder="DID No."
                                                    />
                                                </div>
                                            }
                                        </div>
                                    }
                                    {didWhatsAppIntegrationViaOwnWhatsApp && !mainprops.whatsAppOlderConfigDialog && !oncloseConfirmationdia && !otpAudioScreen && !revokeConfirmationdia &&
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                Do you want to integrate with your own whatsapp number ?
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", gap: 20 }} >
                                                <Button variant='outlined' onClick={() => { facebookLogin(); setDidWhatsAppIntegrationViaOwnWhatsApp(false) }}>
                                                    yes
                                                </Button>
                                                <Button variant='contained' onClick={() => { setDidWhatsAppIntegrationViaOwnWhatsApp(false) }}>
                                                    No
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                    {revokeConfirmationdia && !mainprops.whatsAppOlderConfigDialog && !oncloseConfirmationdia &&
                                        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                Are you sure you want to revoke this action?
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", gap: 20 }} >
                                                <Button variant='outlined' onClick={() => { setRevokeConfirmationdia(false) }}>
                                                    cancel
                                                </Button>
                                                <Button variant='contained' onClick={() => {
                                                    whatsAppDIDFunction(revokeDID, {
                                                        did: revokeDID,
                                                        otpAction: "revoke"
                                                    })
                                                }}>
                                                    confirm
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                    {otpAudioScreen && !mainprops.whatsAppOlderConfigDialog && !revokeConfirmationdia && !oncloseConfirmationdia &&
                                        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                                            <div>
                                                <div style={{ marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Grid container={true} spacing={2}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <OZInput
                                                                name='did'
                                                                label='DID *'
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div style={{ marginTop: "20px", display: "flex", flexDirection: "column" }}>
                                                    <div style={{ fontSize: "12px", fontWeight: 400, marginBottom: "8px", color: "#212121" }}>Play the recording to listen OTP</div>
                                                    <OzTbAudioPlayer audio={audiourl} />
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", gap: 20 }} >
                                                <Button variant='outlined' onClick={() => { setRevokeConfirmationdia(true); setRevokeDID(initData.did) }}>
                                                    Revoke
                                                </Button>
                                                <Button variant='contained' onClick={() => {
                                                    whatsAppDIDFunction(initData.did, {
                                                        did: initData.did,
                                                        otpAction: "fetch"
                                                    })
                                                }}>
                                                    Resend OTP Link
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                    {!mainprops.whatsAppOlderConfigDialog && oncloseConfirmationdia && !revokeConfirmationdia &&
                                        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                Closing the dialog will impact your integration flow
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", gap: 20 }} >
                                                <Button variant='outlined' onClick={() => { mainprops.close() }}>
                                                    ok
                                                </Button>
                                                <Button variant='contained' onClick={() => { setOncloseConfirmationdia(false) }}>
                                                    cancel
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                    {mainprops.whatsAppOlderConfigDialog && !oncloseConfirmationdia && !otpAudioScreen && !revokeConfirmationdia && !didWhatsAppIntegrationViaOwnWhatsApp &&
                                        <div>
                                            <OZInput
                                                name="did"
                                                placeholder="DID No. or UUID No."
                                                label="DID or UUID *"
                                            />
                                        </div>
                                    }
                                </form>
                            </div>
                        )}
                </Formik>
            </div >
        )
    };

    return (
        <>{loading ?
            (
                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                    <LoadingScreen />
                </div>
            ) :
            (
                !mainprops.whatsAppOlderConfigDialog ?
                    (
                        <OZDialog open={mainprops.open} onClose={() => { setOncloseConfirmationdia(true) }} title={revokeConfirmationdia ? "Revoke Confirmation" : oncloseConfirmationdia ? "Close Confirmation" : didWhatsAppIntegrationViaOwnWhatsApp ? "Integrate With Own WhatsAppNumber Confirmation" : "WhatsApp Integration"} saveButtonProps={{ form: 'whatsAppIntegrationForm', type: 'submit', label: 'Integrate' }}
                            cancelButtonProps={{ form: 'whatsAppIntegrationForm', type: 'submit', label: "Revoke" }} hideActions={(didWhatsAppIntegrationViaOwnWhatsApp || oncloseConfirmationdia || otpAudioScreen || revokeConfirmationdia) ? true : false}
                            form='whatsAppIntegrationForm' revokefunction={() => { setClickedRevoke(true) }} hideCross={(oncloseConfirmationdia || didWhatsAppIntegrationViaOwnWhatsApp || revokeConfirmationdia) ? true : false}>
                            <DataForm />
                        </OZDialog>
                    ) :
                    (
                        mainprops.whatsAppOlderConfigDialog &&
                        <OZDialog open={mainprops.open} onClose={mainprops.close} title="Old Configurations" saveButtonProps={{ form: 'whatsAppIntegrationForm', type: 'submit' }}>
                            <DataForm />
                        </OZDialog>
                    )
            )
        }
        </>
    )
}

export default WhatsAppIntegrationForm