import { Box } from '@mui/material';
import Card from '@mui/material/Card';

type Props = {
	name: string;
};
export default function Dummy({ name }: Props) {
	return (
		<Card className="customCard">
			<div className="card-header border-none">
				<div className="card-title">Unknown Widget</div>
			</div>
			<Box sx={{ pt: 2, pl: 2 }}>
				<h2 className="text-center">Unknown Widget "{name}"!!</h2>
			</Box>
		</Card>
	);
}
