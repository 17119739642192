import React, { useState, useMemo, useEffect } from "react";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { Button, Stack, Grid } from "@mui/material";
import DispositionForm from "./DispositionForm";
import "./Disposition.css";
import { Typography } from "@mui/material";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import { getdisposition, deletedispositionById, importDispositions, getDispositionById } from "../../../services/api-service";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import { showErrorNotification, showSuccessNotification, showWarningNotification, } from "../../../components/admin/common/NotiService";
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";

function Disposition() {

  const ReasonRenderer = (props) => {

    const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [dispositionAnchorEl, setDispositionAnchorEl] = useState(null);
    const anchorRef = React.useRef();
    const [openRenameDialog, setRenameDialog] = useState(false);

    const onClickRename = () => {
      setDispositionAnchorEl(null);
      setRenameDialog(true);
    };

    const onClickDelete = (e) => {
      setDispositionAnchorEl(null);
      setDeleteConfirmationDialog(true);
    };

    const onMorevertIconClick = () => {
      setTimeout(() => setDispositionAnchorEl(anchorRef?.current), 1);
    };

    const onMenuClose = () => {
      setDispositionAnchorEl(null);
    };

    const onClickDeleteDialogClose = () => {
      setDeleteConfirmationDialog(false);
    };

    const onClickDeleteDialogDelete = () => {
      deletedisposition(props.data.id);
      setDeleteConfirmationDialog(false);
    };

    const styleListItemButton = useMemo(
      () => ({
        "&.MuiButtonBase-root": {
          color: '#212121',
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          height: '16px',
          lineHeight: "14px",
        },
        '&:hover': {
          borderRadius: "4px",
        },
      }),
      []
    );

    return (
      <div className="oz-disposition-reason-container">
        <label>{props.value}</label>
        {/* {authPage(AdminPages.DISPOSITIONS_EDIT) &&
          <img className="dispositionsMorevertIcon"
            onClick={onMorevertIconClick}
            src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
          >
          </img>
        } */}
        {(newAuthPages(NewAdminPages.DISPOSITIONS_PAGE) || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Edit')) &&
          <img className="dispositionsMorevertIcon"
            onClick={onMorevertIconClick}
            src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
          >
          </img>
        }
        <div ref={anchorRef}>
          <Menu anchorEl={dispositionAnchorEl} open={Boolean(dispositionAnchorEl)} onClose={onMenuClose} sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#FFFFFF",
              width: "133px",
              height: "62px",
            },
            "& .MuiMenu-paper": {
              backgroundColor: "#FFFFFF",
              boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
              border: "0.0625rem solid #E6E5E6",
              borderRadius: "12px",
              marginLeft: "-0.3625rem",
              marginTop: "0rem",
            },
          }}>
            <MenuItem onClick={onClickRename} sx={{ ...styleListItemButton, marginTop: "1px" }}>Rename Reason </MenuItem>
            <MenuItem onClick={onClickDelete} sx={{ ...styleListItemButton, marginTop: '9px' }} disabled={!(newAuthPages(NewAdminPages.DISPOSITIONS_PAGE) || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Full'))}>Delete Reason</MenuItem>
          </Menu>
          {openDeleteConfirmationDialog &&
            <DeleteDialog title={"Delete Disposition"} content={" This will delete this " + props.data.reason + " disposition permanently. You cannot undo this action."} onClose={onClickDeleteDialogClose}
              open={true} deleteFunction={onClickDeleteDialogDelete} />
          }
          {openRenameDialog &&
            <DispositionForm updateReason={getDispositonList} onClose={() => { setRenameDialog(false) }} data={props.data} editAccess={(newAuthPages(NewAdminPages.DISPOSITIONS_PAGE) || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Edit'))} />
          }
        </div>
      </div>
    );
  };

  const [dispositionForm, setDispositionForm] = useState({ isNew: false, show: false });
  const [dispositionList, setDispositionList] = useState([]);
  const [onRowclickDispositionData, setOnRowclickDispositionData] = useState(null);
  const [gridRef, setGridRef] = useState(null);
  const [loading, setLoading] = useState(false);

  const showDispositionForm = () => {
    setDispositionForm({ isNew: true, show: true });
  };

  const closeDispositionForm = () => {
    setDispositionForm({ isNew: false, show: false });
  };

  let buttonStyle = useMemo(
    () => ({
      borderRadius: "8px",
      height: "30px",
      fontSize: "14px",
      fontWeight: 400,
      width: "149px",
      backgroundcolor: "#3D8BF8",
      lineHeight: "16.41px",
    }),
    []
  );

  useEffect(() => {
    getDispositonList();
  }, []);

  async function getDispositonList() {
    setLoading(true);
    try {
      getdisposition()
        .then((resp) => {
          setDispositionList(resp.Data);
          setLoading(false);
        })
        .catch((e) => {
          setDispositionList([]);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      showErrorNotification("Error in disposiiton list");
    }
  };

  async function deletedisposition(dataId) {
    try {
      deletedispositionById(dataId)
        .then((resp) => {
          if (resp.Status === "Success") {
            getDispositonList();
            showSuccessNotification(resp.Message);
          }
          else {
            showWarningNotification(resp.Message);
          }
        })
        .catch((e) => {
          showErrorNotification("Error deleting disposition");
        });
    } catch (e) {
      showErrorNotification("Error in disposition delete");
    }
  };

  const columnDefs = [
    {
      field: "reason",
      headerName: "Reasons",
      cellRenderer: ReasonRenderer,
    },
  ];

  function onClickSampleDownload() {
    const link = document.createElement("a");
    link.download = `disposition_upload_sample.xls`;
    link.href = `${process.env.PUBLIC_URL}/sample_files/disposition_upload_sample.xls`;
    link.click();
  };

  function onCLickRow(rowItem) {
    setOnRowclickDispositionData({});
    setDispositionForm({ isNew: false, show: true });
    try {
      getDispositionById(rowItem.data.id)
        .then((resp) => {
          setOnRowclickDispositionData(resp.Data[0]);
        })
        .catch((e) => {
          setOnRowclickDispositionData({});
        });
    } catch (e) {
      showErrorNotification("Error in disposition list ");
    }
  };

  return (
    <div className="ozAdminDispositionsContainer">
      <Grid sx={{ marginBottom: "10px" }}>
        <Typography className="oz-page-header"> Configurations </Typography>
      </Grid>
      <Stack direction="row" sx={{ marginBottom: "16px" }} justifyContent="space-between">
        <Typography className="oz-page-heading"> Dispositions </Typography>
        {/* {authPage(AdminPages.DISPOSITIONS_EDIT) &&
          <Button
            sx={buttonStyle}
            variant="contained"
            startIcon={<AddBoxOutlinedIcon />}
            onClick={showDispositionForm}
          >
            New Reason
          </Button>
        } */}
        {(newAuthPages(NewAdminPages.DISPOSITIONS_PAGE) || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Full')) &&
          <Button
            sx={buttonStyle}
            variant="contained"
            startIcon={<AddBoxOutlinedIcon />}
            onClick={showDispositionForm}
          >
            New Reason
          </Button>
        }
      </Stack>
      {dispositionForm.show && dispositionForm.isNew && (<DispositionForm disposition={getDispositonList} onClose={closeDispositionForm} />)}
      {dispositionForm.show && !dispositionForm.isNew && (
        <DispositionForm openDialog={true} updateReason={getDispositonList} onClose={closeDispositionForm} data={onRowclickDispositionData} editAccess={(newAuthPages(NewAdminPages.DISPOSITIONS_PAGE) || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Edit'))} />
      )}
      {/* <OZGridToolbar title='List of Dispositions' searchPlaceholder='Reasons' rowCount={dispositionList.length} dataGridRef={gridRef} fileName='Dispositions' showImport={authPage(AdminPages.DISPOSITIONS_EDIT)} importProps={{
        label: "Bulk Import Reasons", importFunction: importDispositions, fileName: "Disposition.xls", onClickSampleDownload: onClickSampleDownload
      }} refreshGrid={getDispositonList} /> */}
      <OZGridToolbar title='List of Dispositions' searchPlaceholder='Reasons' rowCount={dispositionList.length} dataGridRef={gridRef} fileName='Dispositions' showImport={(newAuthPages(NewAdminPages.DISPOSITIONS_PAGE) || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'data'))} importProps={{
        label: "Bulk Import Reasons", importFunction: importDispositions, fileName: "Disposition.xls", onClickSampleDownload: onClickSampleDownload
      }} refreshGrid={getDispositonList} />
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        (<>
          <OZDataGrid rowClickCallback={onCLickRow} height="26.3rem" data={dispositionList} columns={columnDefs} setGridRef={setGridRef} />
        </>
        )
      }
    </div>
  );
};

export default Disposition;