import {  Typography } from "@mui/material";
import './header.css'

const WidgetHeader = () => {        

    return (
        <div className="ozcw-widget-header">                        
            <Typography className="ozcw-widget-title" >Broadcast</Typography>                            
        </div>
    )
}

export default WidgetHeader