import { Formik } from 'formik'
import * as Yup from "yup";
import React, { useEffect, useMemo, useState } from 'react'
import OZInput from '../../../components/admin/common/OZInput';
import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';
import OZFCheckbox from '../../../components/admin/common/OZFCheckbox';
import OZDialog from '../../../components/admin/common/OZDialog';
import { Box, Skeleton, Typography } from '@mui/material';
import OzDatePicker from '../../../components/admin/common/OzDatePicker';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import { getCampaignOutboundListForAdmin, saveHoliday, updateHolidayById } from '../../../services/api-service';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';

export default function HolidaysForm({
    changeHolidayFormStatus,
    holidayFormStatus,
    holidayDate,
    campaign,
    holidayEditData,
    refreshRows,
    editAccess,
}) {
    const mode = holidayFormStatus ? holidayFormStatus === ADMIN_FORM_MODES.add ? ADMIN_FORM_MODES.add : ADMIN_FORM_MODES.edit : ADMIN_FORM_MODES.view;
    const editAccessInt = (mode === ADMIN_FORM_MODES.edit && editAccess === true || (mode === ADMIN_FORM_MODES.add));
    const [addDialog, setAddDialog] = useState(true);
    const [campaignList, setCampaignList] = useState([]);
    const [loading, setLoading] = useState(false);

    const getOutboundCampaigns = async () => {
        setLoading(true);
        try {
            const resp = await getCampaignOutboundListForAdmin();
            if (resp && resp.Data && resp.Data.length) {
                setCampaignList(resp.Data.reduce((acc, item) => ([...acc, { id: item.campaignId, campaignName: item.campaignName }]), []))
            }
            setLoading(false);
        }
        catch (e) {
            console.log(e)
            setLoading(false);
        }
    }

    const handleCloseForm = () => {
        changeHolidayFormStatus(false);
        setAddDialog(false);
    }

    useEffect(() => {
        getOutboundCampaigns();
    }, [])

    return (
        <OZDialog
            title={mode === ADMIN_FORM_MODES.add ? 'Add Holiday' : 'Edit Holiday'}
            saveButtonProps={{ form: 'holidayForm', type: 'submit' }}
            hideSaveButton={!(editAccessInt) || false}
            open={addDialog}
            mode={holidayFormStatus}
            onClose={handleCloseForm}
        >
            <FormikForm handleCloseForm={handleCloseForm} refreshRows={refreshRows} mode={mode} holidayEditData={holidayEditData} campaignList={campaignList} loading={loading} />
        </OZDialog>
    )
}

const FormikForm = ({
    mode,
    holidayEditData,
    campaignList,
    loading,
    refreshRows,
    handleCloseForm
}) => {
    const [formLoader, setFormLoader] = useState(false)
    let initialValues = mode === ADMIN_FORM_MODES.add ? {
        holidayName: "",
        holidayDate: "",
        campaign: [],
        recurring: false
    } : holidayEditData

    const validationSchema = useMemo(() => (
        Yup.object().shape({
            holidayName: Yup.string()
          .min(3, "Holiday Name should be between 3 to 50 alphanumeric characters long allows special characters like _ and Holiday Name cannot start and end with Special characters.")
          .max(50, "Holiday Name should be between 3 to 50 alphanumeric characters long allows special characters like _ and Holiday Name cannot start and end with Special characters.")
          .matches(/^([A-Za-z0-9]((_)*?[A-Za-z0-9])+)$/, 'Holiday Name  should be between 3 to 50 alphanumeric characters long allows special characters like _ and Holiday Name cannot start and end with Special characters.')
          .required("Holiday Name is required"),
            holidayDate: Yup.date().required('Holiday Date is required'),
            campaign: Yup.array().min(1, 'Campaign is required'),
            recurring: Yup.boolean().required('Is Recurring is required')
        })
    ), [])

    function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const handleFormSubmit = async (values) => {
        setFormLoader(true)
        try {
            let valuesCopy = { ...values }
            if (typeof values.holidayDate === 'object') {
                valuesCopy.holidayDate = formatDate(values.holidayDate)
            }
            if (valuesCopy.campaign && valuesCopy.campaign.length) {
                valuesCopy.campaign = valuesCopy.campaign.reduce((acc, item) => ([...acc, { campaignId: item.id, campaignName: item.campaignName }]), [])
            }
            console.log("holiday form save payload", valuesCopy, mode)
            if (mode === ADMIN_FORM_MODES.add) {
                const resp = await saveHoliday(valuesCopy);
                if (resp && resp.Status === "Success") {
                    refreshRows();
                    showSuccessNotification("Holiday saved successfully");
                    handleCloseForm();
                } else {
                    showErrorNotification(resp.Message || "Failed to save holiday");
                }
            } else {
                const resp = await updateHolidayById({ ...valuesCopy, id: holidayEditData.id });
                if (resp && resp.Status === "Success") {
                    refreshRows();
                    showSuccessNotification("Holiday updated successfully");
                    handleCloseForm();
                } else {
                    showErrorNotification(resp.Message || "Failed to update holiday");
                }
            }
            setFormLoader(false)
        }
        catch (e) {
            console.log(e)
            showErrorNotification("Failed to save holiday");
            setFormLoader(false)
        }
    }

    return <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
    >
        {formik => (
            <form
                onSubmit={formik.handleSubmit}
                id='holidayForm'
                className='ozcwScrollbar oz-admin-holidays-form'
            >
                <Box>
                    <OZInput
                        label='Holiday Name *'
                        name='holidayName'
                        placeholder='Enter Name'
                    />
                </Box>
                <Box>
                    <OzDatePicker
                        label='Date *'
                        name='holidayDate'
                        placeholder='Enter Date'
                    />
                </Box>
                <Box>
                    {loading ?
                        <Box>
                            <Typography fontSize='12px' marginBottom={'6px'} marginLeft='1px' color={'#212121'}>
                                Campaign *
                            </Typography>
                            <Skeleton variant="rectangular" height={44} sx={{ borderRadius: "8px" }} />
                        </Box>
                        : (campaignList && campaignList.length) ? <CustomAutoComplete
                            limitTags={2}
                            mode='Add'
                            name="campaign"
                            placeholder={"Select Campaigns"}
                            labelHeader='Campaign *'
                            Datas={(campaignList && campaignList.length) ? campaignList : []}
                            optionLabelName='campaignName'
                            showCheckBox={true}
                            multiple={true}
                            showselectall="true"
                            uniqueOptionsRequired={false}
                        /> : null
                    }
                </Box>
                <Box className='oz-admin-holidays-form-checkbox-container'>
                    <OZFCheckbox
                        name='recurring'
                        label='Annually Recurring'
                        labelPosition="end"
                    />
                </Box>
                {formLoader && <Box className='oz-admin-holiday-form-loader'>
                    <LoadingScreen />
                </Box>}
            </form>
        )}

    </Formik>
}