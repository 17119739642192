import { Fab } from "@mui/material"
import { ReactComponent as ChatLauncher } from '../../../static/icons/chatlauncher.svg';
import { ReactComponent as Close } from '../../../static/icons/closewidget.svg';
import './launcher.css';

const WidgetLauncher = ({ open, toggleWidget, messages }) => {

    return (
        <div className="ozcw-widget-launcher" onClick={toggleWidget}>
            <Fab className={open ? "ozcw-launcher-close-icon" : ""} color={open ? "" : "primary"} sx={{ backgroundColor: open ? '#FFFFFF' : '#008DFF' }} size="small" aria-label={open ? "Close chat" : "Open chat"}>
                {open ? <Close /> : <ChatLauncher />}
            </Fab>
        </div>
    )
}

export default WidgetLauncher;