import { Button, Grid } from "@mui/material";
import { FieldArray } from "formik";
import { useState } from "react";
import CustomAutoComplete from "../../../components/admin/common/AutoComplete/CustomAutoComplete";
import OZInput from "../../../components/admin/common/OZInput";


export function AgentList(agentListProps) {

    const [agentListValues, setAgentListValues] = useState(() => {
        if (agentListProps.autoCompleteMode === "Add") {
            return [agentListProps.agentDataList]
        }
        else {
            if (agentListProps?.formikValues?.values?.agentSkillList.length > 0) {
                let previousSelectedagentLists = structuredClone(agentListProps.formikValues.values.agentSkillList);
                let selectedagents = previousSelectedagentLists.map((agent) => (
                    agent.agentList.map((item) => {
                        return (item);
                    })
                )).flat(1);
                return agentListProps?.formikValues?.values?.agentSkillList.map((agents) => {
                    let newOptions = agentListProps.agentDataList.filter(agent => selectedagents.every(selectedAgent => selectedAgent.id !== agent.id));
                    return [...agents.agentList, ...newOptions,]
                })
            }
            else {
                return []
            }
        }
    });

    // eslint-disable-next-line no-unused-vars
    const [previousSelectedAgentList, setPreviousSelectedAgentList] = useState([])

    function handleChangeAgents(index, selected, createNewagentlist, removedOption, deleteagentLsit, isRemoveagentList) {

        let previousSelectedagentLists = structuredClone(agentListProps.formikValues.values.agentSkillList);
        let selectedagents = previousSelectedagentLists.map((agent) => (
            agent.agentList.map((item) => {
                return (item);
            })
        )).flat(1);

        if (selected.length > 0) {
            selectedagents = [selectedagents, selected].flat(1)
        }
        let newOptions = agentListProps.agentDataList.filter(agent => selectedagents.every(selectedAgent => selectedAgent.id !== agent.id));
        if (createNewagentlist === "removeOption") {
            newOptions = [...newOptions, ...removedOption]
        }
        let localagentListValues = structuredClone(agentListValues)
        if (createNewagentlist) {
            localagentListValues = [...localagentListValues, newOptions]
        }
        let newagentlist = localagentListValues.map((singleagentList, subindex) => {
            if (index === subindex) {
                return singleagentList;
            }
            else {
                if (previousSelectedagentLists[subindex] === undefined) {
                    return newOptions
                } else {
                    return [previousSelectedagentLists[subindex].agentList, newOptions].flat(1);
                }
            }
        })
        if (isRemoveagentList) {
            newagentlist.splice(index, 1)
        }
        setAgentListValues(newagentlist);
        setPreviousSelectedAgentList(selectedagents);
    };

    return (
        <FieldArray name="agentSkillList">
            {({ replace, remove, push }) => (
                <div>
                    {agentListProps.formikValues.values.agentSkillList.length > 0 && agentListProps.formikValues.values.agentSkillList.map((agent, index) => (
                        <div key={index}>
                            <Grid container spacing={2} marginBottom='15px'  >
                                <Grid item xs={agentListProps.autoCompleteMode === "Add" ? 1.7 : 1.7}    >
                                    <OZInput
                                        name={`agentSkillList.${index}.priority`}
                                        label="Priority"
                                        type="text"

                                        placeholder="1"
                                    />
                                </Grid>

                                <Grid item xs={agentListProps.autoCompleteMode === "Add" ? 4.6 : 9.5} style={{ width: "341px" }} >
                                    <CustomAutoComplete
                                        name={`agentSkillList.${index}.agentList`}
                                        labelHeader="Agents"
                                        limitTags={2}
                                        showAvatar={agentListProps.mode === "Edit"}
                                        placeholder={agentListProps.autoCompletePlaceHolder}
                                        selectedValues={agentListProps.autoCompleteMode === "Add" ? "" : agent.agentList}
                                        Datas={agentListValues[index]?.length > 0 ? agentListValues[index] : []}
                                        mode={agentListProps.autoCompleteMode}
                                        optionLabelName={"agentName"}
                                        optionId='agentId'
                                        showCheckBox={agentListProps.mode === "Create" ? true : true}
                                        multiple={true}
                                        showselectall={agentListValues[index]?.length > 1 ? "true" : false}
                                        autocompleteTextFieldWidth={"360px"}
                                        autoCompleteWidth={"321px"}
                                        onChangeValue={{ "handleChangeDatasList": handleChangeAgents, "index": index, "createNewDatasList": false }}
                                    />
                                </Grid>
                                {agentListProps.formikValues.values.agentSkillList.length > 1 && index > 0 &&
                                    <div style={{
                                        paddingLeft: agentListProps.mode === "Create" ? "150px" : "3px", marginTop: "47px", cursor: "pointer",
                                    }} >
                                        <img onClick={() => {
                                            remove(index)
                                            handleChangeAgents(index, [], "removeOption", agentListProps.formikValues.values.agents[index].agentList.length > 0 ? agentListProps.formikValues.values.agents[index].agentList : [], true)
                                        }}
                                            src={`${process.env.PUBLIC_URL}/icons/deleteIconGrey.svg`}
                                            alt="remove"
                                        ></img>
                                    </div>
                                }
                            </Grid>

                            {(agentListProps.formikValues.values.agentSkillList.length === (index + 1)) &&
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        variant="text"
                                        sx={{
                                            fontSize: "14px", fontWeight: 400,
                                            color: agentListProps.mode === 'Create' ? '#536580' : '#3D8BF8',
                                            padding: "0px 0px 0px 0px",
                                            marginLeft: "1px"
                                        }}
                                        onClick={() => {
                                            push({ priority: '', agentList: [] });
                                            handleChangeAgents(index, [], true);
                                        }}
                                    >
                                        <img style={{ paddingRight: "6px", paddingBottom: "3px" }}
                                            src={agentListProps.mode === 'Create' ? `${process.env.PUBLIC_URL}/icons/anotherlevel.svg` : `${process.env.PUBLIC_URL}/icons/anotherlevelblue.svg`}
                                            alt="edit"
                                        ></img>
                                        Another level</Button>
                                </div>}
                        </div>
                    ))
                    }
                </div>
            )}
        </FieldArray>
    )
}

