import React, { useState, useMemo, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Grid, Button } from "@mui/material";
import OZInput from "../../../components/admin/common/OZInput";
import OZDialog from "../../../components/admin/common/OZDialog";
import { saveSipNumber, updateSipNumberById, getsipLocation } from "../../../services/api-service";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import OZSelect from '../../../components/admin/common/OZSelect';
import OZFCheckbox from "../../../components/admin/common/OZFCheckbox";

const SipNumberForm = (props) => {
    let initMode = props.data ? ADMIN_FORM_MODES.edit : ADMIN_FORM_MODES.add
    const editAccess = (initMode === ADMIN_FORM_MODES.edit && props?.editAccess === true || (initMode === ADMIN_FORM_MODES.add));
    const [showSipNumberForm, setSipNumberFormState] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [mode, setMode] = useState(initMode);
    const [newLoading, setNewLoading] = useState(false);

    const formButtonStyle = useMemo(
        () => ({
            borderRadius: '8px',
            fontWeight: '400',
            fontSize: '14px',
        }),
        []
    );

    const validationSchema = useMemo(
        () =>
            yup.object({
                sipNumber: yup
                    .string()
                    .test("maxDigits",
                        "Sip Number should be between 3 to 17 characters.",
                        (number) => String(number).length >= 2 && String(number).length <= 17)
                    .matches(/^(?:\+\d{2,16}|\d{3,17})$/, "Sip Number should be between 3 to 16 digits long and allows prefix +")
                    .required("Sip Number is required"),
                sipLocation: yup
                    .object()
                    .required("Sip Location is required.")
            }),
        []
    );

    const closeSipNumberForm = () => {
        localStorage.removeItem('sipNumberData');
        props.onClose();
        setSipNumberFormState(false);
    };

    async function addSipNumber(data) {
        try {
            saveSipNumber(data).then((resp) => {
                if (resp.response === "success") {
                    showSuccessNotification(resp?.responseMessage);
                    props.updateName();
                    closeSipNumberForm();
                    setNewLoading(false);
                }
                else {
                    showErrorNotification(resp?.responseMessage);
                    setNewLoading(false);
                }
            }).catch((e) => {
                showErrorNotification("Error adding Sip");
                setNewLoading(false);
            });
        } catch (e) {
            showErrorNotification("Error in Sip adding ");
            setNewLoading(false);
        }
    };

    async function updatesipNumber(data) {
        try {
            updateSipNumberById(data).then((resp) => {
                if (resp.response === "success") {
                    closeSipNumberForm();
                    props.updateName();
                    showSuccessNotification(resp?.responseMessage);
                    setNewLoading(false);
                }
                else {
                    showErrorNotification(resp?.responseMessage);
                    setNewLoading(false);
                }
            }).catch((e) => {
                showErrorNotification("Error updating sip");
                setNewLoading(false);
            });
        } catch (e) {
            showErrorNotification("Error in sip update");
            setNewLoading(false);
        }
    };

    const DataForm = (formProps) => {

        const [sipLocationOption, setSipLocationOption] = useState([]);

        useEffect(() => {
            getSipLocationOptions();
        }, [formProps]);

        let initData = formProps.editData || {
            sipNumber: "",
            sipLocation: [],
        };

        function getInitData() {
            let localData = localStorage.getItem('sipNumberData');
            if (localData) {
                return JSON.parse(localData);
            } else {
                return initData;
            }
        }



        async function getSipLocationOptions() {
            try {
                getsipLocation().then(resp => {
                    //  alert("::::::::"+resp.Data[0].skillName);
                    //alert(resp.Data);
                    const sipList = resp.Data.map(item => item);
                    // alert(sipList);
                    setSipLocationOption(sipList);
                }).catch(e => {
                    setSipLocationOption([]);
                    showErrorNotification('No Sip Location Data, Check your Internet Connection');
                })
            }
            catch (e) {
                showErrorNotification('Something went wrong, contact to Admin');
                setSipLocationOption([]);
            }
        }


        return (
            <div className="ozAddLocationform">
                <Formik
                    validateOnMount
                    initialValues={getInitData()}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (mode === ADMIN_FORM_MODES.edit || mode === ADMIN_FORM_MODES.add) {
                            localStorage.setItem('sipNumberData', JSON.stringify(values));
                        }
                        let saveData = structuredClone(values);
                        if (mode === ADMIN_FORM_MODES.add) {
                            setNewLoading(true);
                            addSipNumber(saveData);
                        }
                        else {
                            setNewLoading(true);
                            updatesipNumber(saveData);
                        }
                    }}
                >
                    {
                        (props) => (
                            <div>
                                <form
                                    onSubmit={props.handleSubmit}
                                    id="sipNumberForm"
                                    style={{
                                        width: "464px",
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <OZInput
                                                name="sipNumber"
                                                placeholder="Enter Sip Number"
                                                label="Sip Number *"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <OZSelect
                                                name="sipLocation"
                                                options={sipLocationOption}
                                                optionLabel="location"
                                                label="Sip Location *"
                                                placeholder="Sip Location"
                                            />

                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 1 }} justifyContent='end'>
                                        <Grid item>
                                            <Button style={formButtonStyle} onClick={closeSipNumberForm} variant="outlined">
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item >
                                            {editAccess && (
                                                <Button style={formButtonStyle} variant="contained" onClick={() => {
                                                    if (!props.isValid) {
                                                        props.handleSubmit();
                                                        showErrorNotification("Please Check the values and fill all the mandatory field");
                                                    }
                                                }} type="submit" form="sipNumberForm">
                                                    Save
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {newLoading &&
                                        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                            <LoadingScreen />
                                        </div>
                                    }
                                </form>
                            </div>
                        )}
                </Formik>
            </div>
        );
    };

    return (
        <div>
            <OZDialog
                hideActions
                title={
                    <label style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
                        {mode === ADMIN_FORM_MODES.add ? "Add Sip Number" : `Edit Sip Number`}
                    </label>
                }
                open={showSipNumberForm}
                onClose={closeSipNumberForm}
            >
                {mode === ADMIN_FORM_MODES.view && <DataForm editData={props.data} />}
                {mode === ADMIN_FORM_MODES.add && <DataForm />}
                {mode === ADMIN_FORM_MODES.edit && <DataForm editData={props.data} />}
            </OZDialog>
        </div>
    );
};

export default SipNumberForm;