import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useRadioGroup } from '@mui/material/RadioGroup';
import PropTypes from 'prop-types';
import { FormControl , Grid} from '@mui/material';

const RadioGroupComponent = (props) => {
    console.log("ere came ", props);
    const [radioValueAll, setRadioValueAll] = useState(props?.aggregateValue === 'All' ? "Aggregate" : '');
    const [radioValueManual, setRadioValueManual] = useState("");
    const [radioValueProgressive, setRadioValueProgressive] = useState('');
    const [radioValuePreview, setRadioValuePreview] = useState('');
    const [radioValuePredictive, setRadioValuePredictive] = useState('');

    const [selectedValueManual, setSelectedValueManual] = useState('');
    const [selectedValuePreview, setSelectedValuePreview] = useState('');
    const [selectedValueProgressive, setSelectedValueProgressive] = useState('');
    const [selectedValuePredictive, setSelectedValuePredictive] = useState('');
    const [selectedValueAggregate, setSelectedValueAggregate] = useState(props?.aggregateValue === 'All' ? "Aggregate" : '');

    const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
        ({ theme, checked }) => ({
            '.MuiFormControlLabel-label': checked && {
                color: '#3D8BF8',
            },
        }),
    );

    function MyFormControlLabel(props) {
        const radioGroup = useRadioGroup();

        let checked = false;

        if (radioGroup) {
            checked = radioGroup.value === props.value;
        }

        return <StyledFormControlLabel checked={checked} {...props} />;
    }

    MyFormControlLabel.propTypes = {
        /**
         * The value of the component.
         */
        value: PropTypes.any,
    };

    const handleRadioValueAll = (e, type) => {
        setRadioValueAll(type);
        setSelectedValueAggregate(type);
        setSelectedValueManual('');
        setSelectedValuePredictive('');
        setSelectedValuePreview('');
        setSelectedValueProgressive('');

        setRadioValueManual('');
        setRadioValuePredictive('');
        setRadioValuePreview('');
        setRadioValueProgressive('')
        let tempValue = {
            Aggregate: type
        }
        props.radioChanged(tempValue);
    }

    const handleRadioValueOthers = (e, type) => {
        let tempValue = '';
        switch(type) {
            case 'Manual':
                setRadioValueManual(type);
                if(selectedValueManual === type){
                    setSelectedValueManual('');
                }else{
                    setSelectedValueManual(type);
                }
                tempValue = {
                    Manual: type,
                    Preview: radioValuePreview,
                    Predictive: radioValuePredictive,
                    Progressive: radioValueProgressive
                }
              break;
            case 'Preview':
                setRadioValuePreview(type);
                if(selectedValuePreview === type){
                    setSelectedValuePreview('');
                }else{
                    setSelectedValuePreview(type);
                }
                tempValue = {
                    Manual: radioValueManual,
                    Preview: type,
                    Predictive: radioValuePredictive,
                    Progressive: radioValueProgressive
                }
              break;
            case 'Predictive':
                setRadioValuePredictive(type);
                if(selectedValuePredictive === type){
                    setSelectedValuePredictive('');
                }else{
                    setSelectedValuePredictive(type);
                }
                tempValue = {
                    Manual: radioValueManual,
                    Preview: radioValuePreview,
                    Predictive: type,
                    Progressive: radioValueProgressive
                }
                break;
            case 'Progressive':
                setRadioValueProgressive(type);
                if(selectedValueProgressive === type){
                    setSelectedValueProgressive('');
                }else{
                    setSelectedValueProgressive(type);
                }
                tempValue = {
                    Manual: radioValueManual,
                    Preview: radioValuePreview,
                    Predictive: radioValuePredictive,
                    Progressive: type
                }
                break;
            default:
        }
        setRadioValueAll('');
        setSelectedValueAggregate('');
        props.radioChanged(type);
    }

    const DataForm = ({props}) => {

        return(
            <>
                <FormControl style={{ marginLeft: '10px' }}>
                    <div style={{display: 'flex', alignContent: 'center'}}>
                        <Grid container sx={12} spacing={2} direction='row'>
                            <Grid item style={{cursor: 'pointer'}} onClick={(e) => handleRadioValueAll(e, 'Aggregate')}>
                                <span style={{ color: selectedValueAggregate === 'Aggregate' || props?.aggregateValue === 'All' ? '#C39D80' : '#D6D9DC', paddingRight: '5px'}}>●</span> 
                                    Aggregate
                            </Grid>
                            <Grid item style={{cursor: 'pointer'}} onClick={(e) => handleRadioValueOthers(e, 'Preview')}>
                                <span style={{ color: selectedValuePreview === 'Preview' ? '#3D8BF8' : '#D6D9DC', paddingRight: '5px'}}>●</span>
                                Preview
                            </Grid>
                            <Grid item>
                                <div style={{cursor: 'pointer', marginLeft: '2px'}} onClick={(e) => handleRadioValueOthers(e, 'Manual')} >
                                    <span style={{ color: selectedValueManual === 'Manual' ? '#E65B5C' : '#D6D9DC', paddingRight: '5px'}}>●</span> 
                                    Manual
                                </div>
                            </Grid>
                            <Grid item>
                                <div style={{cursor: 'pointer'}} onClick={(e) => handleRadioValueOthers(e, 'Progressive')} >
                                    <span style={{ color: selectedValueProgressive === 'Progressive' ? '#4F9F52' : '#D6D9DC', paddingRight: '5px'}}>●</span>
                                    Progressive
                                </div>
                            </Grid>
                            <Grid item>
                                <div style={{cursor: 'pointer'}} onClick={(e) => handleRadioValueOthers(e, 'Predictive')} >
                                    <span style={{color: selectedValuePredictive === 'Predictive' ? '#E7AF5B' : '#D6D9DC', paddingRight: '5px'}}>●</span>
                                    Predictive
                                </div>
                            </Grid>

                        </Grid>
                    </div>
                </FormControl>
            </>
        )
    }
    return (
        <div>
            <DataForm props={props}/>
        </div>
    );
}

export default RadioGroupComponent;
