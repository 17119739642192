import React from 'react';
import { TextField, Typography, Divider } from '@mui/material';
import { connect, getIn } from "formik";


const OZInputBulkCampaign = connect(({ type = "text", name, label, onDeleteIcon, height, width, formik, ...additionalProps }) => {

    const error = getIn(formik.errors, name);
    const touch = getIn(formik.touched, name);

    const handleDeleteIcon = (event, name) => {
        onDeleteIcon(name);
    }

    return (
        <React.Fragment>
            {label && <Typography htmlFor={name}
                fontSize='12px'
                fontWeight={400}
                lineHeight='14px'
                marginBottom={'6px'}
                marginLeft='1px'
                color={'#99A0A8'}
            >
                {label}
            </Typography>}
            <TextField
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        backgroundColor: '#ffff',
                        fontSize: '12px',
                        height: height || '30px',
                        width: '326px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #D6D6D7'
                    },
                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        backgroundColor: '#D6D6D7',
                        border: '1px solid #D6D6D7'
                    }
                }}
                type={type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getIn(formik.values, name)}
                error={touch && error}
                helperText={touch && error}
                name={name}
                id={name}
                {...additionalProps}
                InputProps={{
                    endAdornment: (
                        < div style={{ display: 'flex', alignContent: 'center', backgroundColor: '#FFFFFF', borderRadius: '8px' }}
                        // onClick={() => { alert("alskjdkajsd") }}
                        >
                            <Divider sx={{ color: '#99A0A8', height: '29px' }} orientation="vertical" />
                            <img
                                style={{ paddingLeft: '10px', paddingBottom: '2px' }}
                                src={`${process.env.PUBLIC_URL}/icons/deleteIconGrey.svg`}
                                alt="edit"
                                onClick={(e) => handleDeleteIcon(e, label)}
                            />
                        </div>
                    )
                }}
            />

        </React.Fragment >
    );
})

export default OZInputBulkCampaign;