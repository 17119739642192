import { FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import OZCheckbox from '../../../../OZCheckbox/OZCheckbox';
import WidgetSearch from '../../../../Widget-Search/Widget-Search';
import './FilterDrawer.css';
import { FixedSizeList as List } from 'react-window';

type Props = {
	selectedFilters?: any;
	handleOnCheckboxChange?: any;
	filterList?: any;
	groupName?: any;
	handleDurationinSecs?: any;
	singleSelect?: any;
	handleOnRadioChange?: any;
	handleDurationInputChange?: any;
	durationValue? : any;//::::::::::::::::::::for Duration testing:::::::::::::::::::::::::::://
	durationinSecs? : any//::::::::::::::::::::for Duration testing:::::::::::::::::::::::::::://
};

const FilterAccordianContent = ({ filterList, groupName, selectedFilters, handleOnCheckboxChange, handleDurationinSecs, singleSelect, handleOnRadioChange, handleDurationInputChange, durationValue, durationinSecs }: Props) => { //::::::::::::::::::::for Duration testing:::::::::::::::::::::::::::://
	const [filterItems, setFilterItems] = useState<any>(filterList);
	// const [onClickNew, setOnClickNew] = useState<any>(false);
	const [newValue, setNewValue] = useState<any>(durationValue);
	//::::::::::::::::::::for Duration testing:::::::::::::::::::::::::::://
	// const [durationinSecs, setDurationinSecs] = useState<any>('');
	// const [durationValue, setDurationValue] = useState('');
	//::::::::::::::::::::for Duration testing:::::::::::::::::::::::::::://

	const handleSearch = (event: any) => {
		const searchText = event?.target?.value;
		if (searchText?.length) {
			// const filteredData = filterList.filter((item: any) => item.name.toLowerCase().includes(searchText.toLowerCase()));
			const filteredData = filterList.filter((item: any) => item.name !== "" && item.name !== null && item.name && item.name.toLowerCase().includes(searchText.toLowerCase()));
			setFilterItems(filteredData);
		} else {
			setFilterItems(filterList);
		}
	};

	const finalCallTheInput = (value : any) => {
		handleDurationInputChange(value);
	}

	const changeEvent = (event: any) => {
		if(event === ''){
			finalCallTheInput('');
			// handleDurationInputChange(event);
		}
        setNewValue(event);
    }

	const handleOnChange = (event: any) => {
		//::::::::::::::::::::for Duration testing:::::::::::::::::::::::::::://
		// setDurationinSecs(event.target.value);
		//::::::::::::::::::::for Duration testing:::::::::::::::::::::::::::://
		handleDurationinSecs(event.target.value);
	};

	// const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	//::::::::::::::::::::for Duration testing:::::::::::::::::::::::::::://
	// 	// setDurationValue(event.target.value);
	// 	//::::::::::::::::::::for Duration testing:::::::::::::::::::::::::::://
	// 	handleDurationInputChange(event.target.value);
	// };

	const isChecked = (item: any, groupName: string) => {
		if (selectedFilters[groupName]?.length) {
			return selectedFilters[groupName].some((filterItem: any) => item.id === filterItem.id || item.value === filterItem.id);
		} else {
			return false;
		}
	};

	const getFilterItemListElements = (filterGroup: any[], groupName: string) => {
		if (groupName === 'durationinsecs') {
			return (
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div className="ozonetel-form-control" style={{ marginLeft: '20px' }}>
						<label className="form-label">Duration(in sec)</label>
						<Select value={durationinSecs} onChange={handleOnChange} style={{ width: '200px', height: '45px' }}>
							{filterGroup.map((duration: any) => (
								<MenuItem key={duration.name} value={duration.value}>
									{duration.name}
								</MenuItem>
							))}
						</Select>
					</div>
					{durationinSecs !== 'All' &&
						<div style={{ marginLeft: '8px', marginTop: '21px' }}>
							<label style={{ fontSize: '12px', fontWeight: '400'}}>Enter Value*</label>
							<TextField
								sx={{
									'& .MuiOutlinedInput-root': {
										borderRadius: '8px',
										backgroundColor: '#ffff',
										fontSize: '12px',
										height: '46px',
										width: '180px',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										border: '1px solid #D6D6D7'
									},
									'& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
										backgroundColor: '#D6D6D7',
										border: '1px solid #D6D6D7'
									}
								}}
								type='number'
								onChange={(event) => {changeEvent(event?.target?.value)}}
								onBlur={(event) => {finalCallTheInput(event?.target?.value)}}
								onKeyDown={(event) => {
									if (event.key === 'Enter' || event.key === 'Tab') {
										// console.log("yes it came on pressing enter", event, newValue);
										event.preventDefault(); // Prevent form submission (if inside a form)
										finalCallTheInput(newValue);
									}
								}}
								value={newValue}
								id='outlined-basic'
								// InputProps={{
								// 	endAdornment: (
								// 		< div style={{ display: 'flex', alignContent: 'center', backgroundColor: '#FFFFFF', borderRadius: '8px' }}
								// 		>
								// 			<Divider sx={{ color: '#99A0A8', height: '29px' }} orientation="vertical" />
								// 			{onClickNew ? (
								// 				<img
								// 					style={{ paddingLeft: '10px', paddingBottom: '2px', cursor: 'pointer' }}
								// 					src={`${process.env.PUBLIC_URL}/icons/checkbox_after_green.svg`}
								// 					alt="newImage"
								// 					onClick={(e) => {
								// 						setTimeout(() => {
								// 							setOnClickNew(false);
								// 						}, 1000);
								// 					}}
								// 				/>
								// 			) : (
								// 				<Tooltip title={newValue === '' ? `Please enter any value` : ""}>
								// 					<img
								// 						style={{ paddingLeft: '10px', paddingBottom: '2px', cursor: newValue === '' ? '' : "pointer"}}
								// 						src={`${process.env.PUBLIC_URL}/icons/checkbox-tick-icon.svg`}
								// 						alt="tick"
								// 						onClick={(e) => {
								// 							if(newValue !== ''){
								// 								setOnClickNew(true);
								// 								setTimeout(() => {
								// 									setOnClickNew(false);
								// 									handleDurationInputChange(newValue);
								// 								}, 1000);
								// 							}
								// 						}}
								// 					/>
								// 				</Tooltip>
								// 			)}
								// 		</div>
								// 	)
								// }}
							/>
						</div>
					}
				</div>
			);
		}

		if (singleSelect?.includes(groupName)) {
			return (
				<FormControl>
					<RadioGroup
						onChange={(e: any) => {
							handleOnRadioChange(e, groupName);
						}}
						value={selectedFilters[groupName]?.id}
						style={{ marginLeft: '30px' }}
					>
						{filterGroup.map((item: any) => (
							<FormControlLabel key={item.id || item.value} value={item.id || item.value} control={<Radio />} label={item.name} name={item.name} />
						))}
					</RadioGroup>
				</FormControl>
			);
		}

		// return filterGroup?.map((item: any, index: number) => (
		// 	<Grid key={index} item xs={6} className="filter-checkbox-col">
		// 		<OZCheckbox
		// 			checked={isChecked(item, groupName)}
		// 			label={item.name}
		// 			group={groupName}
		// 			id={item.id || item.value}
		// 			onChange={(e: any) => {
		// 				handleOnCheckboxChange(e);
		// 			}}
		// 		/>
		// 	</Grid>
		// ));

		const renderFilterItem = ({ index, style }: any) => {
			const item = filterGroup[index];
			return (
				<div style={style}>
				<Grid container alignItems="center" className="filter-checkbox-col" spacing={2} xs={10} sx={{marginLeft: 4}}>
					<Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
					<OZCheckbox
						checked={isChecked(item, groupName)}
						label={item.name}
						group={groupName}
						id={item.id || item.value}
						onChange={(e: any) => handleOnCheckboxChange(e)}
					/>
					</Grid>
				</Grid>
				</div>
			);
		};


		// const renderFilterItem = ({ index, style }: any) => {
		// 	const item1 = filterGroup[index * 2];
		// 	const item2 = filterGroup[index * 2 + 1];
		
		// 	return (
		// 		<div style={style}>
		// 			<Grid container alignItems="center" className="filter-checkbox-col" spacing={2} xs={10} sx={{ marginLeft: 4 }}>
		// 				{item1 && (
		// 					<Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
		// 						<OZCheckbox
		// 							checked={isChecked(item1, groupName)}
		// 							label={item1?.name}
		// 							group={groupName}
		// 							id={item1?.id || item1?.value}
		// 							onChange={(e: any) => handleOnCheckboxChange(e)}
		// 						/>
		// 					</Grid>
		// 				)}
		// 				{item2 && (
		// 					<Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
		// 						<OZCheckbox
		// 							checked={isChecked(item2, groupName)}
		// 							label={item2?.name}
		// 							group={groupName}
		// 							id={item2?.id || item2?.value}
		// 							onChange={(e: any) => handleOnCheckboxChange(e)}
		// 						/>
		// 					</Grid>
		// 				)}
		// 			</Grid>
		// 		</div>
		// 	);
		// };

		// const totalItems = Math.ceil(filterGroup.length / 2);
		// const listHeight = totalItems; // Assuming 50px is the row height
		// console.log("here listheight", listHeight);
		return (
			<>
				<List
					// height={listHeight > 150 ? 150 : listHeight}
					height={400}
					itemCount={filterGroup.length}
					itemSize={50}
					width={'100%'}
				>
					{renderFilterItem}
				</List>
			</>
		)
	};
	return (
		<div>
			{groupName !== 'durationinsecs' && (
				<Grid item>
					<WidgetSearch onTextChange={handleSearch}></WidgetSearch>
				</Grid>
			)}

			<div>
				<Box className="filter-accordion-items-container" sx={{ marginTop: '10px' }}>
					<Grid container spacing={2}>
						{getFilterItemListElements(filterItems, groupName)}
					</Grid>
				</Box>
			</div>
		</div>
	);
};
export default FilterAccordianContent;
