import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';



const BootstrapDialog = styled(Dialog)(({ theme, props }) => ({
    '& .MuiDialog-paper': {
        position: 'absolute',
        top: '3rem',
        minWidth: '20rem',
        // minHeight: '20rem',
        borderRadius: '12px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)'
    },
    '& .MuiDialogContent-root': {
        padding: props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? '10px 24pxpx' : '0px',
    },
    '& .MuiDialogActions-root': {
        padding: '24px',
    },
    '& .MuiDialogActions-root>:not(:first-of-type)': {
        marginLeft: '24px !important'
    }
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, showNewConnectionButton, onClickNewConnectionButton, ...other } = props;



    return (
        <DialogTitle sx={{ m: 0, padding: (props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm") ? '24px 16px 24px 24px' : '6px 6px 8px 12px', display: 'flex' }} {...other}>
            {children}
            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                {showNewConnectionButton &&
                    <div style={{ minWidth: "150px", color: "#008DFF", fontWeight: 400, fontSize: "16px", cursor: 'pointer' }} onClick={onClickNewConnectionButton}>
                        + New Connection
                    </div>
                }
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            color: '#99A0A8',
                        }}
                    >
                        <img src={`${process.env.PUBLIC_URL}/icons/close.svg`} alt='x' ></img>
                    </IconButton>
                ) : null}
            </div>
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function OZDialog(props) {
    const [open, setOpen] = useState(props.open);
    const hideCross = props.hideCross || false
    const hideActions = props.hideActions || false;
    const hideCancelButton = props.hideCancelButton || false;
    const hideSaveButton = props.hideSaveButton || false;
    const disableButton = props.disabled || false;
    const disabledMessage = props.disabledMessage;
    const showNewConnectionButton = props.showNewConnectionButton || false
    let isTemplate = props.formType ? true : false

    const handleSave = () => {
        if (props.onSave) {
            props.onSave()
        }
        handleClose()

    };
    const handleClose = (event, reason) => {
        if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
            return
        }
        if (props.onClose) {
            props.onClose()
        }
        if (props.form !== "whatsAppIntegrationForm") {
            setOpen(false);
        }
    };
    const getTitleFontColor = () => {
        if (isTemplate) {
            let fontcolor = {
                fontWeight: '400',
                fontSize: '14px',
                color: '#99A0A8'
            }
            return fontcolor;

        } else {
            let fontcolor = { color: props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? '#212121' : '#546881' }
            return fontcolor;
        }
    }
    const onClickNewConnectionButton = (event, reason) => {
        props.onClickNewConnectionButton()
    };

    let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? '44px' : '36px', fontSize: props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? '14px' : '10px', width: props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? '91px' : '100px', fontWeight: 400 }), [])
    let buttonStyleForSsoLoginAndCloudStorageCancleButton = useMemo(() => ({ borderRadius: '8px', height: props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? '44px' : '36px', fontSize: props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? '14px' : '10px', width: props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? '91px' : '100px', fontWeight: 400 }), [])

    const handleCancelClick = () => {
        if (props.form === "whatsAppIntegrationForm") {
            props.revokefunction()
        }
        else if (props.form === "cloudStorageForm") {
            props.onClickCancelOnCloudStorage()
        }
        else {
            handleClose()
        }
        // props.form === "" ? props.revokefunction : handleClose
    }
    return (
        <BootstrapDialog
            onClose={(event, reason) => { handleClose(event, reason) }}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth='lg'
            props={props}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={(hideCross) ? null : handleClose} form={props.form} showNewConnectionButton={(showNewConnectionButton)} onClickNewConnectionButton={showNewConnectionButton ? onClickNewConnectionButton : null}>
                <div style={{ display: "block", width: "100%" }}>
                    <Typography fontWeight={props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? '400' : '500'} fontSize={props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? '18px' : '16px'} color={getTitleFontColor} flexGrow={1} marginTop={props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? "0px" : "8px"}>{props.title}</Typography>
                    <Typography fontWeight='400' fontSize='18px' color='#212121' >{props.subTitle}</Typography>
                </div>

            </BootstrapDialogTitle>

            <DialogContent>
                {props.children}
            </DialogContent>

            {!hideActions &&
                <DialogActions>
                    {!hideCancelButton && <Button variant='outlined' sx={props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? buttonStyle : { ...buttonStyleForSsoLoginAndCloudStorageCancleButton, color: "#727272", borderColor: "#727272" }} onClick={handleCancelClick}
                        form={(props.form === "whatsAppIntegrationForm") ? props.cancelButtonProps?.form : null}
                        type={(props.form === "whatsAppIntegrationForm") ? props.cancelButtonProps?.type : null}>
                        {props.cancelButtonProps?.label || 'Cancel'}
                    </Button>
                    }

                    {(!hideSaveButton && props.saveButtonProps?.type === 'submit') && <Button autoFocus variant='contained'
                        sx={{ ...buttonStyle, width: props.form !== "ssoLoginForm" && props.form !== "cloudStorageForm" ? '78px' : "100px" }}
                        form={props.saveButtonProps?.form || null}
                        type={props.saveButtonProps?.type || null}>
                        {props.saveButtonProps?.label || 'Save'}
                    </Button>
                    }

                    {(!hideSaveButton && props.saveButtonProps?.type !== 'submit') &&



                        <Tooltip title={disableButton ? disabledMessage : ""}><Button autoFocus variant='contained'
                            sx={{ ...buttonStyle, width: '78px' }}
                            onClick={disableButton ? null : handleSave} >
                            {props.saveButtonProps?.label || 'Save'}
                        </Button></Tooltip>



                    }



                </DialogActions>
            }

        </BootstrapDialog >
    );
}
