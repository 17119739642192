import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { Button, Grid, TextField, Snackbar, Alert } from '@mui/material';
import OZDialog from '../../../components/admin/common/OZDialog';
import Divider from '@mui/material/Divider';
import { getBulkCampaignDataDownload, getNewCampaignDispositonsDropdown, getNewCampaignReasonsDropdown } from '../../../services/api-service';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack } from '@mui/system';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import OZFCheckbox from '../../../components/admin/common/OZFCheckbox';
import OZSelect from '../../../components/admin/common/OZSelect';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../../components/admin/common/NotiService';
// import OZGridToolbar from '../../../components/admin/common/OZDataGrid/OZGridToolbar';
// import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
// import { downloadData } from '../../../components/admin/common/OZDataGrid/GridUtil';
// import { checkRoles } from '../../../utils/util';
import { connect } from "formik";
// import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import DownloadFileConfirmationForm from './DownloadFileConfirmation';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

const MaterialUIPickers = connect(({ name, onChangefunction, formik }) => {
    const [value, setValue] = useState(null);

    const onSelect = (newValue) => {
        setValue(newValue);
        let newFormatedValue = newValue.format("DD-MM-YYYY HH:mm:ss");
        formik.setFieldValue(name, newFormatedValue);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DateTimePicker
                    disableFuture
                    views={["year", "month", "day", "hours", "minutes", "seconds"]}
                    disabled={formik?.values?.enableDateFilter === false}
                    inputFormat="DD-MM-YYYY HH:mm:ss"
                    // value={value}
                    value={formik.values[name] !== '' ? moment(formik.values[name], "DD-MM-YYYY HH:mm:ss") : null}
                    onChange={onSelect}
                    renderInput={(params) => <TextField sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                            backgroundColor: '#ffff',
                            fontSize: '14px',
                            height: '44px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #D6D6D7'
                        },
                        '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                            // backgroundColor: '#D6D6D7', //TODO
                            border: '1px solid #D6D6D7'
                        }
                    }} {...params} />}
                />
            </Stack>
        </LocalizationProvider>
    );
})

const useStyles = makeStyles({
    span: {
      textDecoration: 'none',
      cursor: 'pointer',
      color: '#3d8bf8',
      marginLeft: '15px',
      fontSize: '12px',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });

// const CampaignDataTabNew = (props) => {
//     let finalColumnHeaderList = [];

//     const [rowData, setRowData] = useState([]);
//     const [totalData, setTotalData] = useState([]);
//     const [columnData, setColumnData] = useState([]);

//     const [pageNumber, setPageNumber] = useState("0");
//     const [pageSize, setPageSize] = useState("10");
//     const [finalBody] = useState(props.body);

//     //from the existing Component
//     const [downloadOptionsMenuAnchor, setDownloadOptionsMenuAnchor] = React.useState(null);
//     const anchorRef = React.useRef();

//     useEffect(() => {
//         getCampaignRegenerateData(finalBody, props.id, props?.data);
//     }, [pageNumber, pageSize]);

//     const DateFormatter = (params) => {
//         const date = new Date(params.value);
//         const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
//         return date.toLocaleString("en-GB", options);
//     }

//     async function getDynamicColumn(rowData, props) {
//         let columnList = [];
//         let newColumnHeaderOptionsList = [];
//         let newHeaderValue;
//         let columnHeaderOptions = props.previewDataMap.value;
//         newColumnHeaderOptionsList = columnHeaderOptions.split(",");
//         newColumnHeaderOptionsList.map((headerValue) => {
//             if (headerValue === "PhoneNumber") {
//                 if (checkRoles("ROLE_CUST_MASKING")) {
//                     newHeaderValue = headerValue;
//                 }
//             }
//             else if (headerValue !== "PhoneNumber") {
//                 newHeaderValue = headerValue;
//             }
//             columnList.push({ field: newHeaderValue, headerName: newHeaderValue });
//         })
//         columnList.push({ field: 'disposition', headerName: 'Disposition' });
//         columnList.push({ field: 'Tried', headerName: 'Tries' });
//         columnList.push({ field: 'Status', headerName: 'Status' });
//         columnList.push({ field: 'Comments', headerName: 'Comments' });
//         columnList.push({ field: 'dialStatus', headerName: 'DialStatus' });
//         if (props.retryRules === true) {
//             columnList.push({ field: 'DialCounter', headerName: 'DialCounter' });
//         }
//         columnList.push({
//             field: 'DateAdded', headerName: 'DateCreated', cellRenderer: DateFormatter,
//         });
//         columnList.push({
//             field: 'DateUpdated', headerName: 'DateUpdated', cellRenderer: DateFormatter,
//         });
//         finalColumnHeaderList = columnList;
//     }

//     const getPageNumber = (value) => {
//         setPageNumber(value);
//         finalBody.pageNumber = value.toString();
//     }
//     const getPageSize = (value) => {
//         setPageSize(value);
//         finalBody.pageSize = value.toString();
//     }

//     async function getCampaignRegenerateData(body, id, props) {
//         try {
//             getCampaignResetDataWithMappingColumns(body, id).then(resp => {
//                 if (resp.Status === 'Success') {
//                     if (resp.Message.length !== 0) {
//                         setRowData(resp.Message.content);
//                         setTotalData(resp.Message.totalElements);
//                         getDynamicColumn(resp.Message.content, props);
//                         setColumnData(finalColumnHeaderList);
//                     }
//                     else {
//                         showErrorNotification(`No Data Available to show in (${props.campaignName}) Campaign`);
//                     }
//                 }
//                 else {
//                     showErrorNotification(JSON.stringify(resp.Message));
//                 }
//             }).catch(e => {
//                 showErrorNotification(JSON.stringify(e));
//                 setRowData({})
//             })
//         }
//         catch (e) {
//             showErrorNotification(JSON.stringify(e));
//         }
//     }

//     const handleDownloadMenu = (event) => {
//         setDownloadOptionsMenuAnchor(event.currentTarget);
//         setTimeout(() => setDownloadOptionsMenuAnchor(anchorRef?.current), 1);
//     };

//     const closeDownloadMenu = () => {
//         setDownloadOptionsMenuAnchor(null);
//     };

//     const onClickDownload = (type) => {
//         if (props.rowCount === 0) {
//             return;
//         }
//         downloadData(type, props?.data?.campaignName)
//         closeDownloadMenu()
//     }

//     return (
//         <div style={{ minWidth: '1150px' }}>
//             <Grid container spacing={2} direction={'row'} flexGrow={1} sx={{ marginTop: 1 }} alignItems="center" justifyContent='space-between' >
//                 <div onClick={props.closeDataGrid} style={{ marginLeft: '15px', marginBottom: '-25px' }} >
//                     <img
//                         src={`${process.env.PUBLIC_URL}/icons/backIcon.svg`}
//                         alt="Back"
//                     ></img>
//                     <Button variant='text' style={{ color: "#536580" }}>Back</Button>
//                 </div>
//                 <Grid item xs={12}>
//                     <OZGridToolbar
//                         // rowCount={rowData.length}
//                         rowCount={totalData}
//                         hideSearch={true}
//                         hideDownload={true}
//                     />
//                     <OZDataGrid
//                         doPagination
//                         totalData={totalData}
//                         pageSizeClicked={getPageSize}
//                         pageNumberClicked={getPageNumber}
//                         gridLayout={true}
//                         columns={columnData}
//                         data={rowData}
//                         height="19.3rem" />
//                 </Grid>
//             </Grid>
//             <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 13, paddingBottom: '15px' }} alignItems="center" justifyContent='end'>
//                 <Grid item>
//                     <Button style={props.formButtonStyle} onClick={props.closeDownloadCampaignDataform} variant="outlined" >
//                         Cancel
//                     </Button>
//                 </Grid>
//                 <Grid item>
//                     <Button style={props.formButtonStyle} onClick={handleDownloadMenu} variant="contained">
//                         Download
//                     </Button>
//                     <div ref={anchorRef}>
//                         <Menu
//                             anchorEl={downloadOptionsMenuAnchor}
//                             open={Boolean(downloadOptionsMenuAnchor)}
//                             onClose={closeDownloadMenu}
//                             MenuListProps={{
//                                 'aria-labelledby': 'basic-button',
//                             }}
//                             sx={{
//                                 "& .MuiPaper-root": {
//                                     backgroundColor: "white",
//                                     width: "103px",
//                                 },
//                                 "& .MuiMenu-paper": {
//                                     backgroundColor: "white",
//                                     boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
//                                     border: "0.0625rem solid #E6E5E6",
//                                     borderRadius: "12px",
//                                 },
//                             }}
//                         >
//                             <MenuItem dense key={'excel'} onClick={() => onClickDownload('excel')}>Excel</MenuItem>
//                             <MenuItem dense key={'csv'} onClick={() => onClickDownload('csv')}>CSV</MenuItem>
//                             <MenuItem dense key={'pdf'} onClick={() => onClickDownload('pdf')}>PDF</MenuItem>
//                         </Menu>
//                     </div>

//                 </Grid>
//             </Grid>
//         </div>
//     );
// };


const BulkCampaignDownloadDataForm = (props) => {
    // let fullDataById = props?.data;
    const classes = useStyles();
    const [openBulkCampaignDownloadDataForm, setBulkCampaignDownloadDataForm] = useState(true);
    // const [openDownloadDataGrid, setDownloadDataGrid] = useState(false);
    // const [newBody, setNewBody] = useState([]);
    const [reasonsList, setReasonsList] = useState([]);
    const [dispositonsList, setDispositonsList] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [disabledByLoading, setDisabledByLoading] = useState(false);

    const [openfileConfirmationTab, setOpenfileConfirmationTab] = useState(false);

    const [snackbarMessageSuccess, setSnackbarMessageSuccess] = useState('');
	const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
	const [snackbarMessageError, setSnackbarMessageError] = useState('');
	const [openSnackbarError, setOpenSnackbarError] = useState(false);

    const [fullValue, setFullValue] = useState([]);

    const closeBulkCampaignDownloadDataForm = () => {
        localStorage.removeItem('downloadForm');
        setBulkCampaignDownloadDataForm(false);
        props.onClose();
    }

    useEffect(() => {
        getReasonsListDropDown(props?.list);
        getDispositonsListDropDown(props?.list);
    }, []);

    async function getReasonsListDropDown(id) {
        let body = {
            "campaignIds": id
        }
        try {
            getNewCampaignReasonsDropdown(body).then(resp => {
                if (resp.Status === "Success") {
                    const tempReasonList = [{ dialStatus: 'All' }, ...resp?.Data];
                    let newReasonlist = tempReasonList.map((item, index) => ({
                        ...item,
                        id: index + 3
                    }))
                    setReasonsList(newReasonlist);
                } else {
                    setReasonsList([]);
                    showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                setReasonsList([]);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    async function getDispositonsListDropDown(id) {
        let body = {
            "campaignIds": id
        }
        try {
            getNewCampaignDispositonsDropdown(body).then(resp => {
                if (resp.Status === "Success") {
                    const tempDisposition = [{ disposition: 'All' }, ...resp?.Data];
                    let newDispositionlist = tempDisposition.map((item, index) => ({
                        ...item,
                        id: index + 2
                    }))
                    setDispositonsList(newDispositionlist);
                } else {
                    setDispositonsList([]);
                    showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                setDispositonsList([]);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    const handleClickonLink = () => {

        localStorage.removeItem('downloadForm');
        setBulkCampaignDownloadDataForm(false);

        props.link();
    }

    const handleCloseSuccess = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbarSuccess(false);
	};

	const handleCloseSnackbarError = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbarError(false);
	};

    function getmessageCustom(){
		return(
			<>
			<label>{snackbarMessageSuccess}<span className={classes.span} onClick={(e) => handleClickonLink(e)}>Click here to Navigate to "DownloadData Tab"</span></label>
			</>
		)
	}

    async function getBulkCampaignData(body, id, fileFormat) {
        setButtonDisabled(true);
        delete body.enableDateFilter;
        body['campaignIds'] = id;
        delete body.pageSize;
        delete body.pageNumber;
        delete body.agent;
        delete body.priority;
        delete body.skill;
        if (body?.fromDate === null) {
            body.fromDate = "";
        }
        if (body?.toDate === null) {
            body.toDate = "";
        }
        body.format = fileFormat
        try {
            getBulkCampaignDataDownload(body, id).then(resp => {
                if (resp?.Status === 'Success') {
                    setButtonDisabled(false)
                    setOpenSnackbarSuccess(true);
                    setSnackbarMessageSuccess(resp.Message);
                    // showSuccessNotification(`${resp.Message} Please Go To DownloadData Tab for Download Link ${<span onClick={handleClickonLink}>hello</span>}`);
                    // closeBulkCampaignDownloadDataForm();
                }
                else {
                    setButtonDisabled(false)
                    // showErrorNotification(resp.Message);
                    setOpenSnackbarError(true);
                    setSnackbarMessageError(resp.Message);
                }
            }).catch(e => {
                setButtonDisabled(false)
                // showErrorNotification(JSON.stringify(e));
                setOpenSnackbarError(true);
                setSnackbarMessageError(JSON.stringify(e));
            })
        }
        catch (e) {
            setButtonDisabled(false)
            // showErrorNotification(JSON.stringify(e));
            setOpenSnackbarError(true);
            setSnackbarMessageError(JSON.stringify(e));
        }
    }

    const openFileConfirmationPop = (e, fullValue) => {
        let flag = 0;

        if (fullValue.enableDateFilter === false) {
            fullValue.toDate = null;
            fullValue.fromDate = null;
        }

        if (fullValue.enableDateFilter === true) {
            if (fullValue.toDate === null && fullValue.fromDate === null) {
                flag = 1;
                showWarningNotification("toDate and fromdate cannot be empty");
                return;
            }
            else {
                if (fullValue.fromDate !== null) {
                    if (fullValue.toDate === null) {
                        flag = 1;
                        showWarningNotification("toDate should be greater than fromData");
                        return;
                    }
                }
                if (fullValue.toDate !== null) {
                    if (fullValue.fromDate === null) {
                        flag = 1;
                        showWarningNotification("fromDate Should not be empty");
                        return;
                    }
                }

                if (fullValue.toDate === "Invalid Date" || fullValue.fromDate === "Invalid Date") {
                    showErrorNotification("Invalid date, Please check the date passed");
                    return;
                }

                if (fullValue.fromDate !== null && fullValue.toDate !== null) {
                    let CDate = new Date().toString();
                    let NstartDate = moment(fullValue.fromDate, 'DD-MM-YYYY HH:mm:ss').toDate();
                    let NEndDate = moment(fullValue.toDate, 'DD-MM-YYYY HH:mm:ss').toDate();
                    let timestampCDate = new Date(CDate).getTime();
                    let timestampNstartDate = new Date(NstartDate).getTime();
                    let timestampNEndDate = new Date(NEndDate).getTime();
                    if (timestampCDate <= timestampNstartDate) {
                        showErrorNotification("FromDate should not be greater than Current Date");
                        return
                    }
                    if (timestampCDate <= timestampNEndDate) {
                        showErrorNotification("ToDate should not be greater than Current Date");
                        return
                    }
                    if (timestampNstartDate >= timestampNEndDate) {
                        showErrorNotification("ToDate should be greater than FromData");
                        return
                    }
                }
            }
        }
        if (flag === 1) {
            return;
        }

        localStorage.setItem('downloadForm', JSON.stringify(fullValue));
        setFullValue(fullValue);
        setOpenfileConfirmationTab(true);
    }

    const closeFileConfirmationPop = (e) => {
        setOpenfileConfirmationTab(false);
    }

    const DataForm = (data) => {
        const finalValueToDownlaod = useRef('gz');

        if (props?.disabled || props?.list === {}) {
            setDisabledByLoading(true);
        } else {
            setDisabledByLoading(false);
        }
        let initData = {
            status: "All",
            dateSortBy: "DateCreated",
            dispositionName: "All",
            dialStatus: { dialStatus: 'All' },
            fromDate: null,
            toDate: null,

            //previewData
            enableDateFilter: false,
            disposition: { disposition: 'All' },
            skill: "",
            agent: "",
            priority: "",
            pageNumber: "0",
            pageSize: "10",
        };

        const formButtonStyle = useMemo(
            () => ({
                borderRadius: '8px',
                fontWeight: '400',
                fontSize: '14px',
            }),
            []
        );

        const hereOnDownloadClick = (value) => {
            finalValueToDownlaod.current = value;
        }

        function getInitData() {
            let localData = localStorage.getItem('downloadForm');
            if (localData) {
                return JSON.parse(localData);
            } else {
                return initData;
            }
        }

        const handleSubmitInSingleCampaign = (values) => {
            localStorage.setItem('downloadForm', JSON.stringify(values));

            let saveData = structuredClone(values);
            let flag = 0;
            let id = props?.list;
            delete saveData.dispositionName;
            if (saveData.enableDateFilter === true) {
                if (saveData.toDate === null && saveData.fromDate === null) {
                    flag = 1;
                    showWarningNotification("toDate and fromdate cannot be empty");
                    return;
                }
                else {
                    if (saveData.fromDate !== null) {
                        if (saveData.toDate === null) {
                            flag = 1;
                            showWarningNotification("toDate should be greater than fromData");
                            return;
                        }
                    }
                    if (saveData.toDate !== null) {
                        if (saveData.fromDate === null) {
                            flag = 1;
                            showWarningNotification("fromDate Should not be empty");
                            return;
                        }
                    }

                    if (saveData.toDate === "Invalid Date" || saveData.fromDate === "Invalid Date") {
                        showErrorNotification("Invalid date, Please check the date passed");
                        return;
                    }

                    // if (saveData.toDate <= saveData.fromDate) {
                    //     flag = 1;
                    //     showWarningNotification("toDate should be greater than fromData");
                    //     return;
                    // }

                    if (saveData.fromDate !== null && saveData.toDate !== null) {
                        let CDate = new Date().toString();
                        let NstartDate = moment(saveData.fromDate, 'DD-MM-YYYY HH:mm:ss').toDate();
                        let NEndDate = moment(saveData.toDate, 'DD-MM-YYYY HH:mm:ss').toDate();
                        let timestampCDate = new Date(CDate).getTime();
                        let timestampNstartDate = new Date(NstartDate).getTime();
                        let timestampNEndDate = new Date(NEndDate).getTime();
                        if (timestampCDate <= timestampNstartDate) {
                            showErrorNotification("FromDate should not be greater than Current Date");
                            return
                        }
                        if (timestampCDate <= timestampNEndDate) {
                            showErrorNotification("ToDate should not be greater than Current Date");
                            return
                        }
                        if (timestampNstartDate >= timestampNEndDate) {
                            showErrorNotification("ToDate should be greater than FromData");
                            return
                        }
                    }
                }
            }
            if (flag === 1) {
                return;
            }

            if(saveData.enableDateFilter === true){
                if (saveData.dateSortBy === "DateUpdated") {
                    saveData.dateSortBy = "2";
                } else if (saveData.dateSortBy === "DateCreated") {
                    saveData.dateSortBy = "1";
                }
            }else if(saveData.enableDateFilter === false){
                // delete saveData.enableDateFilter;
                saveData.dateSortBy = '0';
            }

            saveData.dialStatus = saveData.dialStatus.dialStatus;
            saveData.disposition = saveData.disposition.disposition;

            getBulkCampaignData(saveData, id, finalValueToDownlaod.current);

        }

        return (
            <>
                <div>
                    <Formik
                        // initialValues={initData}
                        initialValues={getInitData()}
                        onSubmit={(values) => {
                            localStorage.setItem('downloadForm', JSON.stringify(values));
                            let saveData = structuredClone(values);
                            let flag = 0;
                            let id = props?.list;
                            delete saveData.dispositionName;
                            if (saveData.enableDateFilter === true) {
                                if (saveData.toDate === null && saveData.fromDate === null) {
                                    flag = 1;
                                    showWarningNotification("toDate and fromdate cannot be empty");
                                    return;
                                }
                                else {
                                    if (saveData.fromDate !== null) {
                                        if (saveData.toDate === null) {
                                            flag = 1;
                                            showWarningNotification("toDate should be greater than fromData");
                                            return;
                                        }
                                    }
                                    if (saveData.toDate !== null) {
                                        if (saveData.fromDate === null) {
                                            flag = 1;
                                            showWarningNotification("fromDate Should not be empty");
                                            return;
                                        }
                                    }

                                    if (saveData.toDate === "Invalid Date" || saveData.fromDate === "Invalid Date") {
                                        showErrorNotification("Invalid date, Please check the date passed");
                                        return;
                                    }

                                    // if (saveData.toDate <= saveData.fromDate) {
                                    //     flag = 1;
                                    //     showWarningNotification("toDate should be greater than fromData");
                                    //     return;
                                    // }

                                    if (saveData.fromDate !== null && saveData.toDate !== null) {
                                        let CDate = new Date().toString();
                                        let NstartDate = moment(saveData.fromDate, 'DD-MM-YYYY HH:mm:ss').toDate();
                                        let NEndDate = moment(saveData.toDate, 'DD-MM-YYYY HH:mm:ss').toDate();
                                        let timestampCDate = new Date(CDate).getTime();
                                        let timestampNstartDate = new Date(NstartDate).getTime();
                                        let timestampNEndDate = new Date(NEndDate).getTime();
                                        if (timestampCDate <= timestampNstartDate) {
                                            showErrorNotification("FromDate should not be greater than Current Date");
                                            return
                                        }
                                        if (timestampCDate <= timestampNEndDate) {
                                            showErrorNotification("ToDate should not be greater than Current Date");
                                            return
                                        }
                                        if (timestampNstartDate >= timestampNEndDate) {
                                            showErrorNotification("ToDate should be greater than FromData");
                                            return
                                        }
                                    }
                                    // delete saveData.enableDateFilter;
                                }
                            }
                            if (flag === 1) {
                                return;
                            }

                            if(saveData.enableDateFilter === true){
                                if (saveData.dateSortBy === "DateUpdated") {
                                    saveData.dateSortBy = "2";
                                } else if (saveData.dateSortBy === "DateCreated") {
                                    saveData.dateSortBy = "1";
                                }
                            }else if(saveData.enableDateFilter === false){
                                // delete saveData.enableDateFilter;
                                saveData.dateSortBy = '0';
                            }

                            saveData.dialStatus = saveData.dialStatus.dialStatus;
                            saveData.disposition = saveData.disposition.disposition;

                            getBulkCampaignData(saveData, id, finalValueToDownlaod.current);
                            // setDownloadDataGrid(true);
                            // setNewBody(saveData);
                        }}
                    >
                        {(props) => (
                            < form
                                onSubmit={props.handleSubmit}
                                id="downloadData-form"
                                className="ozcw-scrollbar"
                                style={{
                                    maxHeight: '70vh'
                                }}
                            >
                                {disabledByLoading && (
                                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                        <LoadingScreen />
                                    </div>
                                )}
                                <div style={{ minHeight: '23rem' }}>
                                    {/* {!openDownloadDataGrid && */}
                                    <Grid container={true} spacing="20px" marginTop={"5px"}>
                                        <Grid item xs={12}>
                                            <OZFCheckbox
                                                disabled={disabledByLoading}
                                                labelPosition="end"
                                                name="enableDateFilter"
                                                label="Enable Date Filter"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label style={{ fontWeight: 400, fontSize: "12px" }}>{(props?.values?.enableDateFilter === false) ? "From Date" : "From Date *"}</label>
                                            <MaterialUIPickers name="fromDate" props={props?.values} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label style={{ fontWeight: 400, fontSize: "12px" }}>{(props?.values?.enableDateFilter === false) ? "To Date" : "To Date *"}</label>
                                            <MaterialUIPickers name="toDate" props={props?.values} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <OZSelect
                                                disabled={disabledByLoading}
                                                name="status"
                                                label="Status"
                                                placeholder={"Select"}
                                                options={["All", "Fail", "Success", "Pending"]}
                                            />
                                        </Grid>
                                        {props?.values?.enableDateFilter === true &&
                                            <Grid item xs={6} >
                                                <OZSelect
                                                    disabled={disabledByLoading}
                                                    name="dateSortBy"
                                                    label="Sort By"
                                                    placeholder="search"
                                                    options={["DateCreated", "DateUpdated"]}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={6}>
                                            {/* <CustomAutoComplete
                                                mode='Add'
                                                name="disposition"
                                                limitTags={2}
                                                labelHeader='Disposition'
                                                placeholder={"Select"}
                                                Datas={dispositonsList}
                                                optionLabelName='disposition'
                                                showCheckBox={true}
                                                // multiple={true}
                                                showselectall="true"
                                                popupIcon={null}
                                            /> */}
                                            <OZSelect
                                                disabled={disabledByLoading}
                                                name="disposition"
                                                label="Disposition"
                                                optionLabel='disposition'
                                                options={dispositonsList}
                                                placeholder="Select"
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            {/* <CustomAutoComplete
                                                mode='Add'
                                                name="dialStatus"
                                                limitTags={2}
                                                labelHeader='Dial Status'
                                                placeholder={"Dial Status"}
                                                Datas={reasonsList}
                                                optionLabelName='dialStatus'
                                                showCheckBox={true}
                                                // multiple={true}
                                                showselectall="true"
                                                popupIcon={null}
                                            /> */}
                                            <OZSelect
                                                disabled={disabledByLoading}
                                                name="dialStatus"
                                                label="Dial Status"
                                                optionLabel='dialStatus'
                                                options={reasonsList}
                                                placeholder="Select"
                                            />
                                        </Grid>
                                        <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 5 }} justifyContent='end'>
                                            <Grid item >
                                                {data.multipleCampaign && 
                                                    <Button disabled={buttonDisabled || disabledByLoading} style={formButtonStyle} type="submit" variant="contained" form="downloadData-form" >
                                                        Download Data
                                                    </Button>
                                                }
                                                {!data.multipleCampaign && 
                                                    <Button disabled={buttonDisabled || disabledByLoading} style={formButtonStyle} variant="contained" onClick={(e) => openFileConfirmationPop(e, props?.values)} >
                                                        Download Data
                                                    </Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* } */}

                                    {openfileConfirmationTab &&
                                        <DownloadFileConfirmationForm
                                        onClose={(e) => closeFileConfirmationPop(e)}
                                        open={true}
                                        onDownload={(e) => {hereOnDownloadClick(e); handleSubmitInSingleCampaign(fullValue)}}
                                        // onDownload={(e) => {hereOnDownloadClick(e); props?.handleSubmit()}}
                                        />
                                    }

                                    {/* {openDownloadDataGrid && (
                                        <div>
                                            <Grid container spacing={2} direction={'row'} flexGrow={1} alignItems="center" justifyContent='space-between' >
                                                <Grid item xs={12}>
                                                    <CampaignDataTabNew
                                                        body={newBody}
                                                        id={data?.data}
                                                        data={fullDataById}
                                                        formButtonStyle={formButtonStyle}
                                                        closeDataGrid={closeDataGrid}
                                                        closeDownloadCampaignDataform={closeDownloadCampaignDataform}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )} */}
                                </div>
                            </form>
                        )}
                    </Formik>

                </div >
                <Snackbar sx={{ zIndex: 10005 }} open={openSnackbarSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleCloseSuccess}>
                    <Alert
                        iconMapping={{
                            error: <DoDisturbOnIcon style={{ color: 'green' }} />
                        }}
                        onClose={handleCloseSuccess}
                        severity="error"
                    >
                        {getmessageCustom()}
                    </Alert>
                </Snackbar>
                <Snackbar sx={{ zIndex: 10005 }} open={openSnackbarError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleCloseSnackbarError}>
                    <Alert
                        iconMapping={{
                            error: <DoDisturbOnIcon style={{ color: 'red' }} />
                        }}
                        onClose={handleCloseSnackbarError}
                        severity="error"
                    >
                        {snackbarMessageError}
                    </Alert>
                </Snackbar>
            </>
        );
    };
    return (
        <div>
            <OZDialog
                hideActions
                title={
                    <label style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
                        {props?.multipleCampaign ? 'Bulk Download Campaign Data' : 'Download Campaign Data'}
                    </label>
                }
                open={openBulkCampaignDownloadDataForm}
                onClose={closeBulkCampaignDownloadDataForm}
            >
                <Divider style={{ color: "#E6E5E6" }}></Divider>
                {<DataForm data={props?.props} multipleCampaign={props?.multipleCampaign} />}
            </OZDialog>
        </div>
    );
};

export default BulkCampaignDownloadDataForm;