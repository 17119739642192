import CloseIcon from '@mui/icons-material/Close';
import { Grid, Theme, Button, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import React from 'react';
import '../NotificationDrawer/NotificationDrawer.css';

const useStyles: any = makeStyles((theme: Theme) => ({
	thresholdContainer: {
		paddingTop: '1rem',
		display: 'flex',
		flexDirection: 'column'
	},
	thresholdHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
        padding: '0px 21px 31px 20px',
	},
	headerText: {
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '21px',
		color: '#212121'
	},
	drawerGutter: {
		paddingTop: '74px'
	}
}));




const notificationData: any = [
    {
        title: "Queue Threshold",
        message: "Caller ID 404 is in queue from AUS1_4420398495 for more than 00:08:30 ",
        type: "WARNING",
        notificationTime: "10mins Ago"
    },
    {
        title: "ACW Threshold",
        message: "Rakesh kumar is in ACW for more than 00:04:30 ",
        type: "WARNING",
        notificationTime: "03:36 PM"
    },
    {
        title: "SLA Achieved",
        message: "General skill has achieved SLA target of 80%.",
        type: "SUCCESS",
        notificationTime: "Yesterday, 07:42 PM",
    }
]
const warningImg: string = `${process.env.PUBLIC_URL}/img/warning-icon.png`;
const successImg: string = `${process.env.PUBLIC_URL}/img/success-icon.png`;

type Props = {
	toggleNotificationDrawer: any;
	isOpen: boolean | undefined;
};

const NotificationDrawer = ({ isOpen, toggleNotificationDrawer }: Props) => {
	const classes = useStyles();
    const [settingsOpen,setSettingsOpen ] = React.useState(false);
    const [isThDropVisible, setIsThDropVisible] = React.useState(false);

    const handleDropdown = () => {
        setIsThDropVisible(!isThDropVisible)
    }

    const handleSettings = () => {
        setSettingsOpen(!settingsOpen)
    }

    const closeSettings = () => {
        setSettingsOpen(false)
    }

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 15,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 12,
          height: 12,
          borderRadius: 6,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
      }));
      

	return (
		<Drawer open={isOpen} anchor={'right'} onClose={() => toggleNotificationDrawer(false)}>
			<Box sx={{ width: 456 }} className={classes.thresholdContainer}>
                {!settingsOpen ? 
				<Grid container className={classes.drawerGutter}>
					<Grid container className={`${classes.thresholdHeader} header-border`}>
                        <Grid item>
						<span className={classes.headerText}>Notifications</span>
                        </Grid>
                        <Grid item>
						<img src={`${process.env.PUBLIC_URL}/icons/settings-icon.svg`} alt='settings'onClick={handleSettings} style={{cursor: "pointer"}}/>
                        </Grid>
					</Grid>

                    <Grid item className='today-container' xs={12}>
                        <span>Today</span>
                    </Grid>

                    {notificationData.map((each: any)=>{
                       const notificationHeading: any = each.type === "WARNING" ? "notification-warning": "notification-success"
                       const notificationImg: any = each.type === "WARNING" ? warningImg : successImg
                       return (
                        <Grid item xs={12} key={each.title} className="each-notification-container">
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container justifyContent='space-between'>
                                    <Grid item>
                                        <Grid container>
                                            <Grid item>
                                                <div className='notification-bubble'></div>
                                            </Grid> 
                                            <Grid item>
                                                <img src={notificationImg} alt='warning'/>
                                            </Grid>
                                            <Grid item>
                                                <span className={`notification-heading ${notificationHeading}`}>{each.title}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <span className='notification-time'>{each.notificationTime}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <span className='notification-message'>{each.message}</span>
                            </Grid>
                            {each.type === "WARNING" && 
                                <Grid item xs={12}>
                                    <Grid container justifyContent='flex-end'>
                                        <Grid item>
                                            <Button variant="contained">Assign Agent</Button>
                                        </Grid>
                                    </Grid>                               
                                </Grid>
                            }  
                        </Grid>
                        </Grid>
                       )
                        
                })}
				</Grid>
                :
                <Grid container className={classes.drawerGutter}>
					<Grid container className={`${classes.thresholdHeader} header-border`}>
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <img src={`${process.env.PUBLIC_URL}/icons/side-arrow.svg`} alt='back arrow' style={{padding: "2px 8px 0px 0px", cursor: "pointer"}} onClick={closeSettings}/>
                                </Grid>
                                <Grid item>
                                    <span className={classes.headerText}>Notifications Settings</span>
                                </Grid>
                            </Grid>
						
                        </Grid>
                        <Grid item>
						<IconButton aria-label="delete" size="small" onClick={() => closeSettings()}>
							<CloseIcon style={{ fontSize: '22px', color: '#99a0a8', cursor: "pointer" }} />
						</IconButton>
                        </Grid>
					</Grid>
                    <Grid item xs={12} className="threshold-settings-container">
                        <Grid container justifyContent='space-between'>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <img src={`${process.env.PUBLIC_URL}/img/threshold-icon.png`} alt='threshold' />
                                    </Grid>
                                    <Grid item>
                                        <span className='threshold-style'>Threshold</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <img src={`${process.env.PUBLIC_URL}/icons/dropdown-icon.svg`} alt='dropdown' style={{cursor: "pointer"}} onClick={handleDropdown}/>
                            </Grid>
                        </Grid>
                    </Grid>

            {/* Threshold dropdown */}
                {isThDropVisible && 
                    <>
                        <Grid item xs={12} className="threshold-settings-container all-container-shadow">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item >
                                            <span className='threshold-style'>All</span>
                                        </Grid>
                                        <Grid item style={{paddingTop: 5}}>
                                            <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="threshold-settings-container threshold-each-container">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item >
                                            <span className='each-container-heading'>ACW</span>
                                        </Grid>
                                        <Grid item style={{paddingTop: 5}}>
                                            <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="threshold-settings-container threshold-each-container">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item >
                                            <span className='each-container-heading'>Pause</span>
                                        </Grid>
                                        <Grid item style={{paddingTop: 5}}>
                                            <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="threshold-settings-container threshold-each-container">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item >
                                            <span className='each-container-heading'>Busy</span>
                                        </Grid>
                                        <Grid item style={{paddingTop: 5}}>
                                            <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="threshold-settings-container threshold-each-container">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item >
                                            <span className='each-container-heading'>AHT</span>
                                        </Grid>
                                        <Grid item style={{paddingTop: 5}}>
                                            <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="threshold-settings-container threshold-each-container">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item >
                                            <span className='each-container-heading'>Queue</span>
                                        </Grid>
                                        <Grid item style={{paddingTop: 5}}>
                                            <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="threshold-settings-container threshold-each-container see-all-container">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container justifyContent='flex-end'>
                                        <Grid item >
                                            <span className='threshold-style see-all'>See all</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }


                    <Grid item xs={12} className="threshold-settings-container">
                        <Grid container justifyContent='space-between'>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <img src={`${process.env.PUBLIC_URL}/img/settings-success-icon.png`} alt='success' />
                                    </Grid>
                                    <Grid item>
                                        <span className='threshold-style'>Success</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <img src={`${process.env.PUBLIC_URL}/icons/dropdown-icon.svg`} alt='dropdown' style={{cursor: "pointer"}}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="threshold-settings-container">
                        <Grid container justifyContent='space-between'>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <img src={`${process.env.PUBLIC_URL}/img/system-icon.png`} alt='threshold' />
                                    </Grid>
                                    <Grid item>
                                        <span className='threshold-style'>System</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <img src={`${process.env.PUBLIC_URL}/icons/dropdown-icon.svg`} alt='dropdown' style={{cursor: "pointer"}}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                }
			</Box>
		</Drawer>
	);
};

export default NotificationDrawer;
