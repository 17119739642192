import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Button, Divider, FormControl, Grid, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { FieldArray } from 'formik';
import { useMemo, useState, useEffect } from 'react';
import OZInput from '../../../components/admin/common/OZInput';
import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';
import RemoveIcon from '@mui/icons-material/Remove';
import OZSelect from '../../../components/admin/common/OZSelect';
import { showErrorNotification } from '../../../components/admin/common/NotiService';

export default function DispositionRetryConfig(props) {
    // console.log("values in Disposition based retry", props);

    const [disableButton, setDisableButton] = useState(false);
    const [disableSave, setDisableSave] = useState(false);

    //::::::::::::::new logic for temp:::::::::::::::::::://
    const dialTempDataForSuccess = [{ id: 'answered', label: 'Answered' }]
    const dialTempDataForFail = [{ id: 'busy', label: 'Busy' }, { id: 'congestion', label: 'Congestion' }, { id: 'noanswer', label: 'NoAnswer' }, { id: 'noresponse', label: 'NoResponse' },
                        { id: 'normalcallclearing', label: 'NormalCallClearing' }, { id: 'normalunspecified', label: 'NormalUnspecified' },
                        { id: 'not_answered', label: 'Not_Answered' }, { id: 'notanswered', label: 'NotAnswered' }, { id: 'user_disconnected', label: 'user_disconnected' },
                        { id: 'rejected', label: 'Rejected' }, { id: 'unknown', label: 'Unknown' }, { id: 'others', label: 'Others' }]

    //::::::::::::::new logic for temp:::::::::::::::::::://

    useEffect(() => {
        checkDisableCondition(props?.formikProps?.values?.retryConditions, props?.dialStatusData, props?.dispositions);
    }, [props]);

    // useEffect(() => {
    //     checkMutuallyConditions(props?.formikProps?.values?.retryConditions);
    // }, [props]);

    useEffect(() => {
        const showErrorOnCallStatus = checkCallStatusConditions(props?.formikProps?.values?.retryConditions);
        if(showErrorOnCallStatus.found === true && showErrorOnCallStatus.message === false){
            showErrorNotification(`Please check the Condition for Call Status at Case ${showErrorOnCallStatus.index +1}`);
            setDisableSave(true);
        }else if(showErrorOnCallStatus.found === true && showErrorOnCallStatus.message === true){//this is new written for the Call Status condition new:::::::::::::::://
            showErrorNotification(`Please select the Condition for Call Status at Case ${showErrorOnCallStatus.index +1}`);
            setDisableSave(true);
        }else{
            setDisableSave(false);
            const shouldShowNotification = checkMutuallyConditions(props?.formikProps?.values?.retryConditions);
            if (shouldShowNotification) {
                showErrorNotification("Redundant condition found, Please check your configurations");
            }
        }
    }, [props?.formikProps?.values?.retryConditions]);

    const checkCallStatusConditions = (values) => {
        let tFlag = 0;
        const searchValue = { id: 'answered', label: 'Answered' };

        //this is new written for the Call Status condition new:::::::::::::::://
        for (let i = 0; i< values?.length; i++){
            const tempitem = values[i];

            if(!tempitem.callStatus){
                tFlag = 1;
            }
            if(tFlag === 1){
                return { index: i, found: true, message: true };
            }
        }//this is new written for the Call Status condition new:::::::::::::::://

        if(tFlag === 0){
        
        for (let i = 0; i < values.length; i++) {
            const item = values[i];

            // if(!item.callStatus){
            //     console.log("not here item");
            // }else{
            
            if (item.callStatus === 'Fail') {
                const customerStatusParam = item.parameters.find(param => param.type === 'Customer Status');
                
                if (customerStatusParam) {
                    const valueFound = customerStatusParam.value.find(value => 
                        value.id === searchValue.id && value.label === searchValue.label
                    );
    
                    if (valueFound) {
                        return { index: i, found: true, message: false  };
                    }
                }
            }

            if(item.callStatus === 'Success'){
                const customerStatusParam = item.parameters.find(param => param.type === 'Customer Status');

                if(customerStatusParam){
                    const valueFound = customerStatusParam.value.find(value =>
                        value.id !== searchValue.id && value.label !== searchValue.label
                    );
    
                    if (valueFound) {
                        return { index: i, found: true, message: false };
                    }
                }
            }
            // }
        }
        return { index: -1, found: false, message: false };
        }
    }

    const checkMutuallyConditions = (values) => {

        let newparseData = structuredClone(values);

        let flag = 0;

        let newConvertedData = [];

        //form here we will remove parameters :::::::::::::://
        const convertedData = newparseData?.map((item) => {
            const dispositions = item?.parameters?.find((param) => param.type === "Dispositions");
            const dialStatus = item?.parameters?.find((param) => param.type === "Customer Status");

            return {
                dispositions: dispositions?.value || [],
                dialStatus: dialStatus?.value || [],
            };
        });

        newparseData?.map((item, itemIndex) => {
            const data = {
                ...convertedData[itemIndex],
                condition: `${item.parameters[0].condition}`
            }
            newConvertedData.push(data);
        })
        //::::::::::till here parameters removed :::::::::::::::::::://

        //::::::::::::::::::::::from here making in array of strings::::::::::::::::::::::::::::::::///
        function convertDialStatus(dialStatus) {
            return dialStatus.map(status => {
                return `${status.id}`;
            });
        }

        function convertDispositions(dispositions) {
            return dispositions.map((dispo, dispoIndex) => {
                return `${dispo.reason}`;
            });
        }

        const transformedData = newConvertedData?.map(item => {
            if (item.hasOwnProperty("dialStatus") && item.dialStatus.length !== 0) {
                item.dialStatus = convertDialStatus(item.dialStatus);
            }
            if (item.hasOwnProperty('dispositions') && item.dispositions.length !== 0) {
                item.dispositions = convertDispositions(item.dispositions);
            }
            return item;
        });

        // console.log("here comes compare format", transformedData);
        //::::::::::::::::::::::Till here  making in array of strings ::::::::::::::::::::::::::::::::///


        //::::::::::::::::Login for Mutually Exclusive conditions :::::::::::::::::::::::::::::://

        for (let i = 0; i < transformedData.length; i++) {
            for (let j = i + 1; j < transformedData.length; j++) {
                const dispositionsEqual = transformedData[j].dispositions.some(element => transformedData[i].dispositions.includes(element));
                const dialStatusEqual = transformedData[j].dialStatus.some(element => transformedData[i].dialStatus.includes(element));
                if (transformedData[i].condition === "AND") {
                    if (dispositionsEqual && dialStatusEqual) {
                        showErrorNotification("Reduntant condition found, Please check your configurations");
                        flag = 1;
                        setDisableSave(true);
                        return null;
                    } else {
                        // console.log("Go ahead");
                        // showSuccessNotification("Go Ahead");
                        setDisableSave(false);
                    }
                } else if (transformedData[i].condition === "OR") {
                    const dispositionsEqual = transformedData[j].dispositions.some(element => transformedData[i].dispositions.includes(element));
                    const dialStatusEqual = transformedData[j].dialStatus.some(element => transformedData[i].dialStatus.includes(element));
                    if (dispositionsEqual || dialStatusEqual) {
                        showErrorNotification("Reduntant condition found, Please check your configurations");
                        flag = 1;
                        setDisableSave(true);
                        return null;
                    } else {
                        // console.log("Go ahead");
                        // showSuccessNotification("Go Ahead");
                        setDisableSave(false);
                    }
                }
                else {
                    // console.log("Go ahead");
                    // showSuccessNotification("Go Ahead");
                    setDisableSave(false);
                }
            }
            if (flag === 1) {
                return null;
            }
        }
        if (flag === 1) {
            return true;
        }
    }

    const checkDisableCondition = (values, dialData, dispositionData) => {
        let newbool = null;
        let newboolDispo = null;
        values?.map((item) => {
            item?.parameters?.map((paraItem) => {
                if (paraItem.type === "Customer Status") {
                    if (newbool === null) {
                        newbool = paraItem?.value?.length;
                    } else if (newbool !== null) {
                        if (newbool < paraItem?.value?.length) {
                            newbool = paraItem?.value?.length;
                        } else if (newbool === paraItem?.value?.length) {
                            newbool = paraItem?.value?.length;
                        } else if (newbool > paraItem?.value?.length) {
                            return newbool;
                        }
                    }
                }
                if (paraItem.type === "Dispositions") {
                    if (newboolDispo === null) {
                        newboolDispo = paraItem?.value?.length;
                    } else if (newboolDispo !== null) {
                        if (newboolDispo < paraItem?.value?.length) {
                            newboolDispo = paraItem?.value?.length;
                        } else if (newboolDispo === paraItem?.value?.length) {
                            newboolDispo = paraItem?.value?.length;
                        } else if (newboolDispo > paraItem?.value?.length) {
                            return newboolDispo;
                        }
                    }
                }
                return null;
            })
            return null;
        })
        if (newbool >= dialData?.length && newboolDispo >= dispositionData?.length) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }

    let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400 }), []);
    let removeButtonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400, color: '#536580' }), []);

    const [previousSelectedDialStatus, setPreviousSelectedDialStatus] = useState([])

    // function handleChangeDialStatus(index, selected, createNewdialStatuslist, removedOption, deletedialStatusLsit, isRemovedialStatusList) {

    //     let previousSelecteddialStatusLists = structuredClone(props.formikProps.values.retryConditions);
    //     let selecteddialStatus = previousSelecteddialStatusLists.map((retryCondition) => (
    //         retryCondition.dialStatus.map((item) => {
    //             return (item);
    //         })
    //     )).flat(1);

    //     if (selected?.length > 0) {
    //         selecteddialStatus = [selecteddialStatus, selected].flat(1)
    //     }
    //     let newOptions = props.dialStatusData.filter(dialStatus => selecteddialStatus.every(selectedDialStatus => dialStatus.id !== selectedDialStatus.id));
    //     if (createNewdialStatuslist === "removeOption") {
    //         newOptions = [...newOptions, ...removedOption]
    //     }
    //     let localdialStatusListValues = structuredClone(dialListtValues)
    //     if (createNewdialStatuslist) {
    //         localdialStatusListValues = [...localdialStatusListValues, newOptions]
    //     }
    //     let newdialStatuslist = localdialStatusListValues.map((singledialStatusList, subindex) => {
    //         if (index === subindex) {
    //             return singledialStatusList;
    //         }
    //         else {
    //             if (previousSelecteddialStatusLists[subindex] === undefined) {
    //                 return newOptions
    //             } else {
    //                 return [previousSelecteddialStatusLists[subindex].dialStatus, newOptions].flat(1);
    //             }
    //         }
    //     })
    //     if (isRemovedialStatusList) {
    //         newdialStatuslist.splice(index, 1)
    //     }
    //     setDialListValues(newdialStatuslist);
    //     setPreviousSelectedDialStatus(selecteddialStatus);
    // };

    function handleRetryButton(retryConditions: any) {
        if (retryConditions?.noOfTries >= 40 || retryConditions?.config?.length >= 40) {
            return true;
        } else {
            return false;
        }
    }

    function handleAddButton() {
        let tempLen = null;
        let temp = props.formikProps.values.retryConditions.map((value) => {
            tempLen = tempLen + value?.dialStatus?.length;
        })
        if (tempLen >= props?.dialStatusData?.length || disableButton || props?.formikProps?.values?.retryConditions?.length >= 10) {
            return true
        }
        else {
            return false
        }
    }

    const formButtonStyle = useMemo(
        () => ({
            borderRadius: '8px',
            fontWeight: '400',
            fontSize: '14px',
        }),
        []
    );

    function getOptions(Condition, index, paramIndex) {
        let allOptions = ['Customer Status', 'Dispositions'];
        let tempOptionsPresent = structuredClone(Condition);
        let optionPresent = tempOptionsPresent[index]?.parameters?.map(item => item.type && item.type);

        const newOptions = allOptions?.filter(filterItem => !optionPresent.includes(filterItem));
        return newOptions;
    }

    const handleParamRemoveDialStatus = (props, fieldArrayHelpers, index, retryConditions, param, paramIndex) => {
        if (props?.retryConditions[index]?.parameters?.length === 2) {
            let removeCondition = structuredClone(props);

            removeCondition?.retryConditions[index]?.parameters.splice(paramIndex, 1);

            removeCondition?.retryConditions[index]?.parameters.map((item) => {
                item.condition = "";
                return null;
            })

            fieldArrayHelpers.replace(index,
                removeCondition?.retryConditions[index]
            );
        }
    }

    const handleChange = (e, props, fieldArrayHelpers, index, retryConditions, param, paramIndex) => {
        if (props?.retryConditions[index]?.parameters?.length < 2) {
            if (param?.value?.length === 0) {
                showErrorNotification(`Please select any value in Case ${index} for ${param?.type} `);
                return null;
            } else if (param?.value?.length !== 0) {
                let newvalues = structuredClone(props);

                const modifiedData = newvalues?.retryConditions[index]?.parameters.map((item, indexOfItem) => {
                    let tempArray = {};
                    if (indexOfItem === paramIndex) {
                        if (item?.type === "Customer Status") {
                            item.condition = `${e.target.value}`;
                            const data = {
                                "type": "Dispositions",
                                "value": [],
                                "condition": "",
                            }
                            tempArray = data;
                        }
                        if (item?.type === "Dispositions") {
                            item.condition = `${e.target.value}`;
                            const data = {
                                "type": "Customer Status",
                                "value": [],
                                "condition": "",
                            }
                            tempArray = data;
                        }
                    }
                    return tempArray;
                });

                newvalues?.retryConditions[index].parameters.push(modifiedData[0]);
                fieldArrayHelpers.replace(index, newvalues?.retryConditions[index]);
            }
        }
    };

    const handleForCallStatusChange = (e, props, fieldArrayHelpers, index) => {
        let newValue = structuredClone(props);
        const modifiedData = newValue?.retryConditions?.map((item, itemIndex) => {
            let tempArray = {};
            if(itemIndex === index){
                if(e.target.value === 'Success'){
                    const data = {
                        callStatus : 'Success',
                        config : item?.config,
                        noOfTries: item?.noOfTries,
                        parameters: item?.parameters,
                    }
                    tempArray = data;
                }else{
                    const data = {
                        callStatus : 'Fail',
                        config : item?.config,
                        noOfTries: item?.noOfTries,
                        parameters: item?.parameters,
                    }
                    tempArray = data;
                }
            }else{
                const data = {
                    callStatus : item?.callStatus,
                    config : item?.config,
                    noOfTries: item?.noOfTries,
                    parameters: item?.parameters,
                }
                tempArray = data;
            }
            return tempArray;
        });

        newValue.retryConditions[index].callStatus = modifiedData[index]?.callStatus;
        fieldArrayHelpers.replace(index, newValue?.retryConditions[index]);
    };

    return (
        <>
            <FieldArray name="retryConditions">
                {(fieldArrayHelpers) =>
                    <Grid style={{ marginTop: '4px' }} container spacing={2} display='flex' direction={'row'} >
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', alignContent: 'center' }}>
                                <Typography sx={{
                                    fontStyle: 'Roboto',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    color: '#212121',
                                }} >Retry Conditions</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} >
                            {props?.formikProps?.values?.retryConditions?.length > 0 && props?.formikProps?.values.retryConditions.map((retryConditions, index) => (
                                <div key={index} >
                                    {/* {console.log("here new arraya is coming ", props?.formikProps?.values?.retryConditions, retryConditions)} */}
                                    <div style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px', marginTop: '10px', backgroundColor: '#FBFCFC', borderRadius: '8px', border: '1px solid #D6D6D7' }}>
                                        <div style={{ display: 'flex' }}>
                                            <Typography sx={{
                                                fontStyle: 'Roboto',
                                                fontWeight: 600,
                                                fontSize: '14px',
                                                color: '#212121',
                                            }} flexGrow={1} >{`Case ${index + 1}`}</Typography>
                                            <Button
                                                disabled={(props?.formikProps?.values?.retryConditions?.length > 1 && index > 0) ? false : handleAddButton()}
                                                name={(props?.formikProps?.values?.retryConditions?.length > 1 && index > 0) ? 'removeButton' : 'AddButton'}
                                                style={{ marginTop: '-4px' }}
                                                sx={(props?.formikProps?.values?.retryConditions?.length > 1 && index > 0) ? removeButtonStyle : buttonStyle}
                                                startIcon={(props?.formikProps?.values?.retryConditions?.length > 1 && index > 0) ? (<img src={`${process.env.PUBLIC_URL}/icons/remove.svg`} alt='remove2'></img>) : <AddBoxOutlinedIcon />}
                                                onClick={() => {
                                                    if (props?.formikProps?.values?.retryConditions?.length > 1 && index > 0) {
                                                        fieldArrayHelpers.remove(index)
                                                        // handleChangeDialStatus(index, [], "removeOption", props?.formikProps?.values?.retryConditions[index]?.dialStatus?.length > 0 ? props.formikProps.values.retryConditions[index].dialStatus : [], true)
                                                    }
                                                    else {
                                                        if (props?.formikProps?.values?.retryConditions?.length < 10) {
                                                            fieldArrayHelpers.push({
                                                                "parameters": [
                                                                    {
                                                                        "type": "Customer Status",
                                                                        "value": [],
                                                                        "condition": ""
                                                                    }
                                                                ],
                                                                "config": [
                                                                    {
                                                                        "intervalDays": 0,
                                                                        "intervalHours": 0,
                                                                        "intervalMinutes": 0,
                                                                        "retryCount": 1
                                                                    }
                                                                ],
                                                                "noOfTries": 1,
                                                                "callStatus": 'Fail',
                                                            })
                                                        }
                                                        // handleChangeDialStatus(index, [], true);
                                                    }
                                                }}
                                            >
                                                {(props?.formikProps?.values?.retryConditions?.length > 1 && index > 0) ? '' : 'Add'}
                                            </Button>
                                        </div>

                                        <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                                            <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginTop: '8px' }} />
                                        </span>

                                        <Grid container xs={12} direction={'row'} sx={{marginTop: '15px'}}>
                                            <Grid item xs={2}>
                                                <OZSelect
                                                    disabled={true}
                                                    // name={"CallStatus"}
                                                    placeholder={<Typography style={{ color: 'black' }} aria-disabled>Call Status</Typography>}
                                                    leftradius={true}
                                                    retry
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                {/* <OZSelect
                                                    // disabled={basicProps?.values?.position === "RUNNING"}
                                                    name={`retryConditions.${index}.callStatus`}
                                                    // label="Campaign Type *"
                                                    optionLabel='label'
                                                    // options={['Success', 'Fail']}
                                                    options={[{ id: 'success', label: 'Success' }, { id: 'fail', label: 'Fail' }]}
                                                    placeholder="Please Select"
                                                /> */}

                                                <Select
                                                    sx={{ border: '#D6D6D7', borderRadius: '0px 8px 8px 0px', height: '44px', minWidth: '380px' }}
                                                    value={props?.formikProps?.values?.retryConditions[index]?.callStatus || ''}
                                                    name={`retryConditions.${index}.callStatus`}
                                                    onChange={(e) => handleForCallStatusChange(e, props?.formikProps?.values, fieldArrayHelpers, index)}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value='Success'>Success</MenuItem>
                                                    <MenuItem value='Fail'>Fail</MenuItem>
                                                </Select>
                                            </Grid>
                                            {/* <Grid item xs={1.7} sx={{marginLeft: '45px'}}>
                                                <OZSelect
                                                    disabled={true}
                                                    // name={"CallStatus"}
                                                    placeholder={<Typography style={{ color: 'black' }} aria-disabled>AND</Typography>}
                                                    // leftradius={true}
                                                    // retry
                                                />
                                            </Grid> */}
                                        </Grid>

                                        <FieldArray name="retryConditions">
                                            {(paramsArrayHelpers) => (
                                                retryConditions?.parameters?.map((param, paramIndex) => (
                                                    <>
                                                    {param.type !== 'Call Status' &&
                                                    <div key={paramIndex}>

                                                        <Stack style={{ fontSize: '12px', marginTop: '15px', marginBottom: '25px' }} container spacing={2} display='flex' direction={'column'}>
                                                            <Grid container direction={'row'}>
                                                                <Tooltip title={param?.value?.length !== 0 ? `Please first remove the selected value for Case ${index} of ${param?.type}` : ""}>
                                                                    <Grid item xs={2}>
                                                                        <OZSelect
                                                                            disabled={param?.value?.length !== 0}
                                                                            name={`retryConditions.${index}.parameters.${paramIndex}.type`}
                                                                            // options={allOptions}
                                                                            options={getOptions(props?.formikProps?.values?.retryConditions, index, paramIndex)}
                                                                            placeholder="Please Select"
                                                                            leftradius={true}
                                                                            retry
                                                                        />
                                                                    </Grid>
                                                                </Tooltip>
                                                                <Tooltip title={param?.type === "Customer Status" && (!props?.formikProps?.values?.retryConditions[index]?.callStatus || props?.formikProps?.values?.retryConditions[index]?.callStatus === "") ? `Please select Call Status Value in case ${index+1}` : ''}>
                                                                <Grid item xs={4.5}>
                                                                    {param?.type === "Customer Status" ?
                                                                        <CustomAutoComplete
                                                                            mode='Add'
                                                                            name={`retryConditions.${index}.parameters.${paramIndex}.value`}
                                                                            limitTags={1}
                                                                            placeholder={"Select value"}
                                                                            // Datas={(dialListtValues[index]?.length > 0) ? dialListtValues[index] : []}
                                                                            // Datas={props?.dialStatusData}
                                                                            // Datas={props?.formikProps?.values?.retryConditions[index]?.callStatus === "Fail" ?
                                                                            //         props?.dialStatusData?.filter(item => item.id !== 'answered' && item.label !== 'Answered') :
                                                                            //         props?.formikProps?.values?.retryConditions[index]?.callStatus === "Success" ?
                                                                            //         props?.dialStatusData?.filter(item => item?.label === "Answered" && item?.id === 'answered') :
                                                                            //         props?.dialStatusData}//commented for trial purpose
                                                                            Datas={props?.formikProps?.values?.retryConditions[index]?.callStatus === "Fail" ? dialTempDataForFail : props?.formikProps?.values?.retryConditions[index]?.callStatus === "Success" ? dialTempDataForSuccess : props?.dialStatusData}
                                                                            optionLabelName='label'
                                                                            showCheckBox={true}
                                                                            multiple={true}
                                                                            popupIcon={null}
                                                                            // showselectall={dialListtValues[index]?.length > 1 ? "true" : false}
                                                                            showselectall={true}
                                                                            rightradius={true}
                                                                            postCallWork
                                                                            retry
                                                                        // onChangeValue={{ "handleChangeDatasList": handleChangeDialStatus, "index": index, "createNewDatasList": false }}
                                                                        />
                                                                        :
                                                                        <CustomAutoComplete
                                                                            mode='Add'
                                                                            name={`retryConditions.${index}.parameters.${paramIndex}.value`}
                                                                            limitTags={1}
                                                                            placeholder={"Select value"}
                                                                            Datas={props?.dispositions}
                                                                            optionLabelName='reason'
                                                                            showCheckBox={true}
                                                                            multiple={true}
                                                                            popupIcon={null}
                                                                            showselectall={true}
                                                                            rightradius={true}
                                                                            postCallWork
                                                                            retry
                                                                        />
                                                                    }
                                                                </Grid>
                                                                </Tooltip>
                                                                <Grid item xs={1.7}>
                                                                    <FormControl sx={{ minWidth: 150 }}>
                                                                        {paramIndex === 1 ?
                                                                            <Tooltip title='Select Operation Not allowed'>
                                                                                <Select
                                                                                    disabled={true}
                                                                                    sx={{ border: '#D6D6D7', borderRadius: '8px', height: '44px' }}
                                                                                    name={`retryConditions.${index}.parameters.${paramIndex}.condition`}
                                                                                    displayEmpty
                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                >
                                                                                </Select>
                                                                            </Tooltip>
                                                                            :
                                                                            <Select
                                                                                sx={{ border: '#D6D6D7', borderRadius: '8px', height: '44px' }}
                                                                                value={props?.formikProps?.values?.retryConditions[index]?.parameters[0]?.condition || ''}
                                                                                name={`retryConditions.${index}.parameters.${paramIndex}.condition`}
                                                                                onChange={(e) => handleChange(e, props?.formikProps?.values, fieldArrayHelpers, index, retryConditions, param, paramIndex)}
                                                                                displayEmpty
                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                            >
                                                                                <MenuItem value='OR'>OR</MenuItem>
                                                                                <MenuItem value='AND'>AND</MenuItem>
                                                                            </Select>
                                                                        }
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid item xs={1.5} sx={{ marginLeft: '30px' }}>
                                                                    <Button
                                                                        sx={{ border: '1px solid #D6D6D7', borderRadius: '8px', height: '44px' }}
                                                                        startIcon={<RemoveIcon style={{ marginLeft: '7px' }} color="action" />}
                                                                        onClick={(e) => { handleParamRemoveDialStatus(props?.formikProps?.values, fieldArrayHelpers, index, retryConditions, param, paramIndex) }}
                                                                    >
                                                                    </Button>
                                                                </Grid>

                                                            </Grid>
                                                        </Stack>
                                                    </div>
                                                    }
                                                    </>
                                                ))
                                            )}
                                        </FieldArray>

                                        <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                                            <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginTop: '8px' }} />
                                        </span>

                                        {props.formikProps.values.retryConditions[index].config.map((val, subIndex) => (
                                            <div key={subIndex} style={{ marginTop: '15px', height: '44px', backgroundColor: '#FBFCFC', borderRadius: '8px', display: 'flex', alignContent: 'center', marginBottom: '10px' }}>
                                                <div>
                                                    <Typography sx={{
                                                        fontStyle: 'normal',
                                                        fontWeight: 400,
                                                        fontSize: '14px',
                                                        color: '#212121',
                                                        paddingTop: '10px',
                                                        paddingLeft: '10px',
                                                    }} flexGrow={1} >
                                                        <label style={{ fontWeight: '600' }}>{subIndex + 1}{subIndex === 0 ? 'st' : subIndex === 1 ? 'nd' : subIndex === 2 ? "rd" : "th"}</label> retry interval after</Typography>
                                                </div>
                                                <Grid item xs={1.1} style={{ marginLeft: '10px', paddingTop: '7px' }}>
                                                    <Tooltip title='Interval Days in retry Configuration cannot be empty and should be between 0 and 20'>
                                                        <div style={{ display: 'flex', alignContent: 'center' }}>
                                                            <OZInput flexGrow={1}
                                                                name={`retryConditions.${index}.config.${subIndex}.intervalDays`}
                                                                height='28px'
                                                                placeholder="0"
                                                                type="number"
                                                            />
                                                            <label style={{ paddingLeft: '12px', paddingTop: '2px' }}>Days</label>
                                                        </div>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={1.2} style={{ marginLeft: '10px', paddingTop: '7px' }}>
                                                    <Tooltip title="Interval Hours in retry Configuration cannot be empty and should be between 0 and 23">
                                                        <div style={{ display: 'flex', alignContent: 'center' }}>
                                                            <OZInput flexGrow={1}
                                                                name={`retryConditions.${index}.config.${subIndex}.intervalHours`}
                                                                height='28px'
                                                                placeholder="0"
                                                                type="number"
                                                            />
                                                            <label style={{ paddingLeft: '12px', paddingTop: '2px' }}>Hours</label>
                                                        </div>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={1.4} style={{ marginLeft: '10px', paddingTop: '7px' }}>
                                                    <Tooltip title="Interval Minutes in retry Configuration cannot be empty and should be between 0 and 59">
                                                        <div style={{ display: 'flex', alignContent: 'center' }}>
                                                            <OZInput flexGrow={1}
                                                                name={`retryConditions.${index}.config.${subIndex}.intervalMinutes`}
                                                                height='28px'
                                                                placeholder="0"
                                                                type="number"
                                                            />
                                                            <div style={{ paddingLeft: '12px', paddingTop: '2px' }}>Minutes</div>
                                                        </div>
                                                    </Tooltip>
                                                </Grid>
                                                <div style={{ paddingTop: '7px', marginLeft: '400px', paddingRight: '5px' }}>
                                                    <Button
                                                        disabled={(retryConditions?.config?.length > 1 && subIndex > 0) ? false : handleRetryButton(retryConditions)}
                                                        name={(retryConditions?.config?.length > 1 && subIndex > 0) ? 'removeButton' : 'anotherButton'}
                                                        style={(retryConditions?.config?.length > 1 && subIndex > 0) ? { marginTop: '2px' } : { marginTop: '0px' }}
                                                        sx={(retryConditions?.config?.length > 1 && subIndex > 0) ? removeButtonStyle : buttonStyle}
                                                        startIcon={(retryConditions?.config?.length > 1 && subIndex > 0) ? (<img src={`${process.env.PUBLIC_URL}/icons/remove.svg`} alt='remove2'></img>) : <AddBoxOutlinedIcon />}
                                                        onClick={() => {
                                                            if (retryConditions?.config?.length > 1 && subIndex > 0) {
                                                                retryConditions.config.splice(subIndex, 1)
                                                                fieldArrayHelpers.replace(index, {
                                                                    parameters: retryConditions.parameters,
                                                                    config: retryConditions.config,
                                                                    noOfTries: props?.formikProps?.values?.retryConditions[index].config?.length,
                                                                    callStatus: retryConditions.callStatus,
                                                                })
                                                            }
                                                            else {
                                                                if (retryConditions?.noOfTries < 40) {
                                                                    fieldArrayHelpers.replace(index, {
                                                                        callStatus: retryConditions.callStatus,
                                                                        parameters: retryConditions.parameters,
                                                                        noOfTries: props?.formikProps?.values?.retryConditions[index].config?.length + 1,
                                                                        config: [...retryConditions.config, {
                                                                            intervalDays: 0,
                                                                            intervalHours: 0,
                                                                            intervalMinutes: 0,
                                                                            retryCount: props?.formikProps?.values?.retryConditions[index].config?.length + 1
                                                                        }]
                                                                    })
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {(retryConditions?.config?.length > 1 && subIndex > 0) ? "" : " Another Retry"}
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </Grid>
                    </Grid>
                }
            </FieldArray >
            <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 8 }} justifyContent='end'>
                <Grid item>
                    <Button
                        style={formButtonStyle}
                        onClick={props.onCancel}
                        variant="outlined">
                        Cancel
                    </Button>
                </Grid>
                <Grid item >
                    <Button
                        disabled={disableSave}
                        style={formButtonStyle}
                        onClick={props.onSave}
                        variant="contained">
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}
