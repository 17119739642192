import React, { useEffect, useMemo, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactComponent as Preview } from "./../../MFAicons/previousButton.svg";
import { ReactComponent as LockIcon } from "./../../MFAicons/lockIcon.svg";
import { ReactComponent as NoEmailIcon } from "./../../MFAicons/emailNotFound.svg";

import { Box, Button, Typography } from '@mui/material';

const RequestOTP = (props) => {
  const [otpMethod, setOtpMethod] = useState('sms');
  const [loading, setLoading] = useState(false);
  
  let buttonStyle = useMemo(() => ({cursor:"pointer",width:"100%", borderRadius: '8px', height: '44px', fontSize: '14px', fontWeight: 400,fontSize:"16px", backgroundColor: '#3D8BF8' }), []);

  const handleClose = () => {
    props.onClose();
  }
  const handleRequestOtp = async () => {
    setLoading(true);
    props.onRequest(otpMethod);
  }

  return (
    
    <div className="request-otp-container">
      <Box className="oz-tb-otp-header">
        <Button className='oz-tb-otp-verify-back-button'
        onClick={handleClose}>
                <Preview className='oz-tb-otp-verification-back-button-icon'/><label style={{marginLeft:"5px"}}>back</label>
        </Button>
        <Typography className="oz-tb-otp-header-label">Request OTP</Typography>
      </Box>
      
      <Box className="oz-tb-otp-lock-icon">
        <LockIcon />
      </Box>
      <Typography className='oz-tb-otp-receive-otp-label'>Select to receive OTP code</Typography>
      <div className="otp-methods">
       <Box className='d-flex'>
          <input
            type="radio"
            value="sms"
            checked={otpMethod === 'sms'}
            onChange={() => setOtpMethod('sms')}
          />
          <Typography className="oz-tb-otp-to-sms">SMS</Typography>
        </Box>
         <Box className='d-flex'>
          <input
            type="radio"
            value="email"
            checked={otpMethod === 'email'}
            onChange={() => setOtpMethod('email')}
          />
        <Typography className="oz-tb-otp-to-email">E-mail</Typography>
        </Box>
      </div>

      <LoadingButton 
          sx={buttonStyle}
          type="submit" 
          size="large" 
          onClick={handleRequestOtp}
          loading={loading} 
          variant="contained">
          { "Request for OTP"}
       </LoadingButton>
    </div>
  );
};

export default RequestOTP;