/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import { debug, UA, WebSocketInterface } from 'jssip';

interface KsipEventHandlers {
  [key: string]: (request: any) => void;
}

interface KsipClientEventHandlers {
  [key: string]: (transport: any) => void;
}

let kSipEvents: { [key: string]: Function[] } = {};
let kSipClient: any = null;
let localStream: any = null;
let remoteStream: any = null;
let kSipSession: any = null;

const setSdp = (body: string) => {
  console.log("kSip->setSdp->", body);
  const lbody = body.split("\n");
  let tempbody: string | null = null;
  for (let i = 0; i < lbody.length; i++) {
    console.log("kSip->setSdp->for->" + lbody[i]);
    if (!lbody[i].indexOf("a=crypto:1")) {
      continue;
    }
    if (!tempbody) {
      tempbody = lbody[i];
    } else {
      tempbody += "\n" + lbody[i];
    }
  }
  console.log("kSip->setSdp->return->", tempbody);
  return tempbody;
};

const sessionEventHandlers: KsipEventHandlers = {
  "sdp": (request) => {
    console.log("kSip->sessionEventHandlers->sdp->", request);
    request.sdp = setSdp(request.sdp);
    // kSip.trigger("sdp", [request])
  },
  "peerconnection": (connection) => {
    console.log("kSip->sessionEventHandlers->peerconnection->", connection);
    connection.peerconnection.onaddstream = sessionEventHandlers["addstream"];
    // kSip.trigger("peerconnection", [connection])
  },
  "connecting": (request) => {
    console.log("kSip->sessionEventHandlers->connecting->", request);
    kSip.trigger("connecting", [request]);
  },
  "sending": (request) => {
    console.log("kSip->sessionEventHandlers->sending->", request);
    kSip.trigger("sending", [request]);
  },
  "progress": (response) => {
    console.log("kSip->sessionEventHandlers->progress->", response);
    kSip.trigger("progress", [response]);
  },
  "accepted": (response) => {
    console.log("kSip->sessionEventHandlers->accepted->", response);
    kSip.trigger("accepted", [response]);
  },
  "confirmed": (response) => {
    console.log("kSip->sessionEventHandlers->confirmed->", response);
    kSip.trigger("confirmed", [response]);
  },
  "ended": (response) => {
    console.log("kSip->sessionEventHandlers->ended->", response);
    kSip.trigger("ended", [response]);
  },
  "failed": (response) => {
    console.log("kSip->sessionEventHandlers->failed->", response);
    kSip.trigger("failed", [response]);
  },
  "addstream": (response) => {
    console.log("kSip->sessionEventHandlers->addstream->", response);
    remoteStream = document.getElementById("kookoo_remote_view");
    console.log("kSip->sessionEventHandlers->addstream->remoteStream->", remoteStream);
    if (!remoteStream) {
      const body = document.getElementsByTagName("body")[0];
      remoteStream = document.createElement("audio");
      remoteStream.setAttribute("id", "kookoo_remote_view");
      remoteStream.setAttribute("hidden", "true");
      remoteStream.setAttribute("autoplay", "true");
      body.appendChild(remoteStream);
    }
    // remoteStream = JsSIP.rtcninja.attachMediaStream(remoteStream, response.stream)
    remoteStream.srcObject = kSipSession.connection.getRemoteStreams()[0];
    remoteStream.play();
    // kSip.trigger("addstream", [response])
  }
};

const options = {
  "eventHandlers": sessionEventHandlers,
  "extraHeaders": ["X-Foo: foo", "X-Bar: bar"],
  "mediaConstraints": {"audio": true, "video": false},
  "pcConfig": {rtcpMuxPolicy: "negotiate"},
  "rtcOfferConstraints": {
    offerToReceiveAudio: 1,
    offerToReceiveVideo: 0
  }
};

const clientEventHandlers: KsipClientEventHandlers = {
  "connecting": (transport) => {
    console.log("kSip->clientEventHandlers->connecting->", transport);
    kSip.trigger("clientConnecting", [transport]);
  },
  "connected": (transport) => {
    console.log("kSip->clientEventHandlers->connected->", transport);
    kSip.trigger("clientConnected", [transport]);
  },
  "disconnected": (transport) => {
    console.log("kSip->clientEventHandlers->disconnected->", transport);
    kSip.trigger("clientDisconnected", [transport]);
  },
  "newRTCSession": (call) => {
    console.log("kSip->clientEventHandlers->newRTCSession->", call);
    kSipSession = call.session;
    const events = Object.keys(sessionEventHandlers);
    events.forEach((event) => {
      kSipSession.on(event, sessionEventHandlers[event]);
    });
    kSip.trigger("newCall", [call]);
  },
  "newMessage": (message) => {
    console.log("kSip->clientEventHandlers->newMessage->", message);
    kSip.trigger("newMessage", [message]);
  },
  "registered": (response) => {
    console.log("kSip->clientEventHandlers->registered->", response);
    kSip.trigger("clientRegistered", [response]);
  },
  "unregistered": (response) => {
    console.log("kSip->clientEventHandlers->unregistered->", response);
    kSip.trigger("clientUnRegistered", [response]);
  },
  "registrationFailed": (response) => {
    console.log("kSip->clientEventHandlers->registrationFailed->", response);
    kSip.trigger("clientRegistrationFailed", [response]);
  }
};

const kSip = {
  init(ip: string, port: string, number: string, password: string, secured: boolean) {
    console.log("kSip->init->", kSipClient, ip, port, number, password, secured);
    if (kSipClient !== null && kSipClient.isConnected()) {
      return false;
    }
    debug.enable('JsSIP:*');
    const protocol = "ws" + (secured ? "s" : "") + "://";
    kSipClient = new UA({
      "sockets": [new WebSocketInterface(protocol + ip + ":" + port)],
      // "ws_servers": "wss://" + ip + ":" + port,
      "uri": "sip:" + number + "@" + ip,
      "password": password,
      "registrar_server": "sip:" + ip,
      "authorization_user": number,
      "session_timers": false
    });
    const events = Object.keys(clientEventHandlers);
    events.forEach((event) => {
      kSipClient.on(event, clientEventHandlers[event]);
    });
    kSipClient.start();
    console.log("kSip->init->clientStart->", kSipClient);
    // JsSIP.rtcninja.getUserMedia({ video: false, audio: true }, function(event) {
    //     console.log("kSip->init->clientStart->rtcninja->getUserMedia->then->", event)
    // }, function(error) {
    //     console.error("kSip->init->clientStart->rtcninja->getUserMedia->catch->", error)
    //     throw error
    // })
  },
  status() {
    return kSipClient && kSipClient.isConnected();
  },
  close() {
    console.log("kSip->close->");
    if (kSipClient && kSipClient.isConnected()) {
      kSipClient.stop();
      kSipClient = null;
      console.log("kSip->close->stopped");
    }
  },
  sendDTMF(dtmf: string) {
    console.log("kSip->sendDTMF->", dtmf);
    const dtmfOptions = {
      'duration': 160,
      'interToneGap': 1200,
      'extraHeaders': options.extraHeaders
    };
    kSipSession.sendDTMF(dtmf, dtmfOptions);
  },
  answer() {
    console.log("kSip->answer->", kSipSession);
    if (kSipSession) {
      kSipSession.answer(options);
    }
  },
  hangup() {
    console.log("kSip->hangup->", kSipSession);
    if (kSipSession) {
      kSipSession.terminate();
    }
  },
  on(eventName: string, callback: Function) {
    console.log("kSip->on->", kSipEvents, eventName, callback);

    if (kSipEvents[eventName] === undefined) {
      kSipEvents[eventName] = [];
    }
    kSipEvents[eventName].push(callback);
  },
  off(eventName: string, callback?: Function) {
    console.log("kSip->off->", kSipEvents, eventName, callback);
    if (kSipEvents[eventName] && callback) {
      const index = kSipEvents[eventName].indexOf(callback);
      console.log("kSip->off->", eventName, callback, index);
      if (index !== -1) {
        kSipEvents[eventName].splice(index, 1);
      }
    }
  },
  trigger(eventName: string, options: any[]) {
    console.log("kSip->trigger->", eventName, options, kSipEvents);
    if (kSipEvents[eventName]) {
      kSipEvents[eventName].forEach((callback) => {
        console.log("kSip->trigger->", eventName, options, callback);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        callback ? callback.apply(window, options) : null;
      });
    }
  }
};

export default kSip;
