import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
	defaultUserIcon: {
		minWidth: '1.8rem',
		minHeight: '1.8rem',
		backgroundColor: '#F2C2C2',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: '400',
		fontSize: '10px',
		textAlign: 'center'
	},
	userIcon: {
		backgroundColor: 'unset'
	}
});

const getRandomColor = () => {
	return `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.4)`;
};
type Props = {
	word: string;
};

function DefaultUserIcon({ word }: Props) {
	const classes = useStyles();
	const [backgroundColor, setBackgroundColor] = useState(getRandomColor());

	const initialLetters = word?.length ? `${word.split(' ')[0][0].toUpperCase()}${word.split(' ')[1] ? word.split(' ')[1][0].toUpperCase() : ''}` : '-';

	useEffect(() => {
		setBackgroundColor(getRandomColor());
	}, [word]);

	return (
		<Box className={classes.defaultUserIcon} style={{ backgroundColor: backgroundColor }}>
			{initialLetters}
		</Box>
	);
}

export default DefaultUserIcon;
