import { Box, Drawer, Grid } from '@mui/material';
import '../ChatHistoryDrawer/ChatHistoryDrawer.css';
import moment from 'moment';
import { DateFormat, DateTimeFormat } from '../../utils/properties';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Linkify from 'react-linkify';
import LocationMapView from './LocationMapView';
import CarouselPreview from './CarouselPreview';

	type Props = {
		isChatOpen: boolean | undefined;
		toggleChatDrawer: any;
		chatData: { transcript: any[]; SessionId: string ; rowN: any};
		liveReport?: any;
		chatDigital?: any;
	};

	type Convertprops = {
		data?: any;
		agent?: any;
	};

	type chatAgentProps = {
		data?: any;
		DateTimeFormat?: any;
	};

	type chatNonAgentProps = {
		data?: any;
		DateTimeFormat?: any;
	};

	type Buttonprops = {
		data?: any;
		isAgent?: any;
	};

	type listprops = {
		data?: any;
		isAgent?: any;
	};

	type interactiveListprops = {
		data?: any;
		isAgent?: any;
	};
	
	type interactiveButtonprops = {
		data?: any;
		isAgent?: any;
	};

	type textFormatProps = {
		data?: any;
		isAgent?: any;
	};

	type videoFormatProps = {
		data?: any;
		isAgent?: any;
	};

	type audioFormatProps = {
		data?: any;
		isAgent?: any;
	};

	type imageFormatProps = {
		data?: any;
		isAgent?: any;
	};


function ChatHistoryDrawer({ isChatOpen, toggleChatDrawer, chatData , liveReport, chatDigital}: Props) {
    // console.log("in transcript drawer", chatData);

	const useStyles = makeStyles((theme) => ({
        nodeView: {
			color: '#3D4C5E',
			backgroundColor: '#FFFFFF',
			padding: '5px',
			borderRadius: '4px'
        },
		formViewOuter: {
			width: '300px',
			minHeight: '150px',
			maxHeight: '200px'
        },
		boxView: {
			width: '300px',
			height: '250px'
		},
		caption: {
			marginTop: '6px',
			fontWeight: 500
		},
		carousel: {
			width: '550px',
			height: '320px'
		}
    }));
    const classes = useStyles();

	const ConvertFormat = ({data, agent} : Convertprops) => {
		// console.log('data came here ConvertFormat comp', data);
		const parsedData = JSON.parse(data);
		return (
		<div>
			{Object.entries(parsedData).map(([key, value]) => (
			<div key={key}>
				<span>{key}:</span>
				{/* <span>{value}</span> */}
			</div>
			))}
		</div>
		);
	};

	const ButtonRender = ({data, isAgent} : Buttonprops) => {
		return (
			<div>
				<Linkify
					componentDecorator={(decoratedHref, decoratedText, key) => (
						<a href={decoratedHref}  rel="noopener noreferrer" key={key} target="_blank">
						{decoratedText}
						</a>
					)}
				>
					<p>{data?.title}</p>
				</Linkify>
				{data?.choices?.map((item: any) => {
					return (
						<div key={item.id}>
						<div style={{ display: 'block', marginBottom: '5px' }}>
							<button style={{
							backgroundColor: "#008dff",
							width: "100%",
							border: "1px solid transparent",
							color: "white",
							fontSize: "15px",
							borderRadius: "5px",
							}}>
							<Linkify
								componentDecorator={(decoratedHref, decoratedText, key) => (
								<a href={decoratedHref}  rel="noopener noreferrer" key={key} target="_blank">
									{decoratedText}
								</a>
								)}
							>
								{item?.title}
							</Linkify>
							</button>
						</div>
						</div>
					)
				})}
			</div>
		);
	};

	const InteractiveButtonView = ({data, isAgent} : interactiveButtonprops) => {
		// console.log("it came to button type", data);
		// if(data?.hasOwnProperty('choices')){
		// 	console.log("here section is there ", data);
		// }else{
		// 	console.log("here section is not there ", data);
		// }
		return (
			<div>
				<Linkify
					componentDecorator={(decoratedHref, decoratedText, key) => (
						<a href={decoratedHref}  rel="noopener noreferrer" key={key} target="_blank">
						{decoratedText}
						</a>
					)}
				>
					<p>{data?.title}</p>
				</Linkify>
				{data?.choices?.map((item: any) => {
					return (
						<div key={item.id}>
						<div style={{ display: 'block', marginBottom: '5px' }}>
							<button style={{
							backgroundColor: "#008dff",
							width: "100%",
							border: "1px solid transparent",
							color: "white",
							fontSize: "15px",
							borderRadius: "5px",
							}}>
							<Linkify
								componentDecorator={(decoratedHref, decoratedText, key) => (
								<a href={decoratedHref}  rel="noopener noreferrer" key={key} target="_blank">
									{decoratedText}
								</a>
								)}
							>
								{item?.title}
							</Linkify>
							</button>
						</div>
						</div>
					)
				})}
			</div>
		);
	};

	const FormNewNodeView = ({data, isAgent} : interactiveButtonprops) => {

		return (
			<div className={classes.nodeView}>
				{`${data?.data?.title}`}
				<Grid container xs={12} spacing={2} direction='row' sx={{marginTop: '10px'}}>
					<ul>
						{data?.data?.fields?.map((subItem: any, index: any) => (
							<li key={index}><Linkify>{`${subItem?.key?.charAt(0)?.toUpperCase() + subItem?.key?.slice(1)}`}</Linkify></li>
						))}
					</ul>
				</Grid>
			</div>
		);
	};

	const FormNodeView = ({data, isAgent} : interactiveButtonprops) => {

		return (
			<div className={classes.nodeView}>
				Demo has been Scheduled for this details:
				<Grid container xs={12} spacing={2} direction='row' sx={{marginTop: '10px'}}>
					{Object.keys(data).map((keys) => (
						<React.Fragment key={keys}>
							<Grid item xs={6} sx={{color: '#47586E'}}>
								{`${keys.charAt(0).toUpperCase() + keys.slice(1).replace('_', ' ')}`}:
							</Grid>
							<Grid item xs={6} sx={{color: '#909DAD' , wordBreak: 'break-word' }}>
								{data[keys]}
							</Grid>
						</React.Fragment>
					))}
				</Grid>
			</div>
		);
	};

	const ListView = ({data, isAgent} : listprops) => {
		// const row = data?.sections[0]?.choices;
		return (
			<div>
				{
				<Linkify>
					<label className={isAgent === true ? 'chat-History-agent' : 'chat-History-user'}>
					{data?.title}
					</label>
				</Linkify>
				}
				{
				<ul>
					{data?.sections[0]?.choices?.map((e: any, index: any) => (
					<li key={index}><Linkify>{e?.title}</Linkify></li>
					))}
				</ul>
				}
			</div>
		);
	};

	const InteractiveListView = ({data, isAgent} : interactiveListprops) => {
		// console.log("here coming in list view", data);
		if(data?.hasOwnProperty('sections')){
			const row = data?.sections[0]?.choices;
		}
		// const row = data?.sections[0]?.choices;
		return (
			<div>
				{
				<Linkify>
					<label className={isAgent === true ? 'chat-History-agent' : 'chat-History-user'}>
					{data?.title}
					</label>
				</Linkify>
				}
				{
				<ul>
					{data?.sections[0]?.choices?.map((e: any, index: any) => (
					<li key={index}><Linkify>{e?.title}</Linkify></li>
					))}
				</ul>
				}
			</div>
		);
	};

	const AudioFormatChangeBot = ({data, isAgent}: audioFormatProps) => {
		// const lines = data.split(' | ');

		let flag: any = 0;
		let lines : any;
		let Oline : any;

		if(data?.link?.includes('|')){
			lines = data?.link?.split(' | ');
			flag = 1;
		}else{
			Oline = data?.link;
		}

		return (
			<div>
			{flag === 1 ? 
				<>
					{lines.map((line: any, index: any) => (
						<>
						{line !== '-' && line !== "" &&
							<React.Fragment key={index}>
								<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
									<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
										{decoratedText}
									</a>
								)}>
									<audio className={isAgent === true ? "chat-History-agent" : "chat-History-user"} style={{ cursor: 'pointer' }} controls>
										<source src={line} type="video/mp4" />
										Your browser does not support the video tag.
									</audio>
								</Linkify>
								<div className={classes.caption}>{data?.caption ? data?.caption : ''}</div>
							<br />
							</React.Fragment>
						}
						</>
					))}
				</>
				:
				<>
					<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
						<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
							{decoratedText}
						</a>
					)}>
						<audio className={isAgent === true ? "chat-History-agent" : "chat-History-user"} style={{ cursor: 'pointer' }} controls>
							<source src={Oline} type="video/mp4" />
							Your browser does not support the video tag.
						</audio>
					</Linkify>
					<div className={classes.caption}>{data?.caption ? data?.caption : ''}</div>
				</>
			}
			</div>
		);
  	};

	const AudioFormatChange = ({data, isAgent}: audioFormatProps) => {
		const lines = data.split(' | ');

		return (
			<div>
			 {lines.map((line: any, index: any) => (
				<>
				{line !== '-' && line !== "" &&
					<React.Fragment key={index}>
						<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
							<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
								{decoratedText}
							</a>
						)}>
							<audio className={isAgent === true ? "chat-History-agent" : "chat-History-user"} style={{ cursor: 'pointer' }} controls>
								<source src={line} type="video/mp4" />
								Your browser does not support the video tag.
							</audio>
						</Linkify>
						<div className={classes.caption}>{data?.caption ? data?.caption : ''}</div>
					<br />
					</React.Fragment>
				}
				</>
			))}
			</div>
		);
  	};

	const ImageFormatChange = ({data, isAgent}: imageFormatProps) => {
		let flag: any = 0;
		let lines : any;
		let Oline : any;

		if(typeof data === 'string'){
			if(data.includes('|')){
				lines = data.split(' | ');
				flag = 1;
			}else{
				Oline = data;
			}
		}else if(typeof data !== 'string'){
			if(data?.link?.includes('|')){
				lines = data?.link?.split(' | ');
				flag = 1;
			}else{
				Oline = data?.link;
			}
		}

		return (
			<div>
			{flag === 1 ?
			<>
				{lines.map((line: any, index: any) => (
					<>
					{line !== '-' && line !== "" &&
						<React.Fragment key={index}>
							<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
								<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
									{decoratedText}
								</a>
							)}>
								<img
									className="chat-history-img"
									src={line}
									alt="edit"
								/>
							</Linkify>
							<div className={classes.caption}>{data?.caption ? data?.caption : ''}</div>
						<br />
						</React.Fragment>
					}
					</>
				))}
			</>
			:
			<>
				<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
					<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
						{decoratedText}
					</a>
				)}>
					<img
						className="chat-history-img"
						src={Oline}
						alt="edit"
					/>
				</Linkify>
				<div className={classes.caption}>{data?.caption ? data?.caption : ''}</div>
			</>
			}
			</div>
		);
  	};

	const VideoFormatChangeBot = ({data, isAgent}: videoFormatProps) => {
		// const lines = data.replace(/[| -]/g, '');
		let flag: any = 0;
		let lines : any;
		let Oline : any;

		if(data?.link?.includes('|')){
			lines = data?.link?.split(' | ');
			flag = 1;
		}else{
			Oline = data?.link;
		}

		return (
			<div>
			{flag === 1 ?
			<>
				{lines.map((line: any, index: any) => (
					<>
					{line !== '-' && line !== "" &&
						<React.Fragment>
							<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
								<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
									{decoratedText}
								</a>
							)}>
								<video className='chat-history-video' style={{ cursor: 'pointer'}} controls>
									<source src={line} type="video/mp4" />
								</video>
							</Linkify>
							<div className={classes.caption}>{data?.caption ? data?.caption : ''}</div>
						<br />
						</React.Fragment>
					}
					</>
				))}
			</>
			:
			<>
				<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
					<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
						{decoratedText}
					</a>
				)}>
					<video className='chat-history-video' style={{ cursor: 'pointer'}} controls>
						<source src={Oline} type="video/mp4" />
					</video>
				</Linkify>
				<div className={classes.caption}>{data?.caption ? data?.caption : ''}</div>
			</>
			}
			</div>
		);
  	};

	const VideoFormatChange = ({data, isAgent}: videoFormatProps) => {
		// const lines = data.replace(/[| -]/g, '');
		// const lines = data.split(' | ');

		let flag: any = 0;
		let lines : any;
		let Oline : any;

		if(data.includes('|')){
			lines = data.split(' | ');
			flag = 1;
		}else{
			Oline = data;
		}

		return (
			<div>
			{flag === 1 ?
			<>
				{lines.map((line: any, index: any) => (
					<>
					{line !== '-' && line !== "" &&
						<React.Fragment>
							<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
								<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
									{decoratedText}
								</a>
							)}>
								<video className='chat-history-video' style={{ cursor: 'pointer'}} controls>
									<source src={line} type="video/mp4" />
								</video>
							</Linkify>
							<div className={classes.caption}>{data?.caption ? data?.caption : ''}</div>
						<br />
						</React.Fragment>
					}
					</>
				))}
			</>
			:
			<>
				<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
					<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
						{decoratedText}
					</a>
				)}>
					<video className='chat-history-video' style={{ cursor: 'pointer'}} controls>
						<source src={Oline} type="video/mp4" />
					</video>
				</Linkify>
				<div className={classes.caption}>{data?.caption ? data?.caption : ''}</div>
			</>
			}
			</div>
		);
  	};

	// const QuestionFormatChange = ({data, isAgent}: textFormatProps) => {

	// 	let lines: any;
	// 	let flag = 0;
	// 	if(data.hasOwnProperty('data')){
	// 		lines = data?.data?.split('\\n');
	// 		flag =1;
	// 	}else{
	// 		lines = data.split('\\n');

	// 	}

	// 	return (
	// 		<div>
	// 			{flag === 1 && typeof data === 'object' ?
	// 			<>
	// 				{lines.map((line: any, index: any) => (
	// 					<React.Fragment key={index}>
	// 						<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
	// 							<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
	// 								{decoratedText}
	// 							</a>
	// 						)}>
	// 							<label className={isAgent === true ? "chat-History-agent" : "chat-History-user"} style={{ cursor: 'pointer' }}>
	// 								{line}
	// 							</label>
	// 						</Linkify>
	// 					<br />
	// 					</React.Fragment>
	// 				))}
	// 			</>
	// 			:
	// 			<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
	// 				<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
	// 					{decoratedText}
	// 				</a>
	// 			)}>
	// 				<label className={isAgent === true ? "chat-History-agent" : "chat-History-user"} style={{ cursor: 'pointer' }}>
	// 					{lines}
	// 				</label>
	// 			</Linkify>
	// 			}
	// 		</div>
	// 	);
  	// };

	const TextFormatChange = ({ data, isAgent }: textFormatProps) => {

		let lines : any;

		if(typeof data === 'string'){
			lines = data?.split('\\n');
		}else if(typeof data === 'object'){
			lines = data?.link?.split('\\n');
		}
		// const lines = data.split('\\n');
	
		const isHTML = (content: string) => /<\/?[a-z][\s\S]*>/i.test(content);
	
		const renderPlainText = (content: string) => {
			return (
				<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
					<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
						{decoratedText}
					</a>
				)}>
					<label className={isAgent ? "chat-History-agent" : "chat-History-user"} style={{ cursor: 'pointer' }}>
						{content}
					</label>
				</Linkify>
			);
		};
	
		const renderHTMLContent = (content: string) => {
			return <div dangerouslySetInnerHTML={{ __html: content }} />;
		};
	
		return (
			<div>
				{lines.map((line: any, index: any) => (
					<React.Fragment key={index}>
						{isHTML(line) ? renderHTMLContent(line) : renderPlainText(line)}
						<br />
						<div className={classes.caption}>{data?.caption ? data?.caption : ''}</div>
					</React.Fragment>
				))}
			</div>
		);
	};

	const QuestionFormatChange = ({data, isAgent}: textFormatProps) => {

		let lines: any;
		let flag = 0;
		if(data.hasOwnProperty('data')){
			lines = data?.data?.split('\\n');
			flag =1;
		}else{
			lines = data.split('\\n');

		}
		const isHTML = (content: string) => /<\/?[a-z][\s\S]*>/i.test(content);

		// return (
		// 	<div>
		// 		{flag === 1 && typeof data === 'object' ?
		// 		<>
		// 			{lines.map((line: any, index: any) => (
		// 				<React.Fragment key={index}>
		// 					<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
		// 						<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
		// 							{decoratedText}
		// 						</a>
		// 					)}>
		// 						<label className={isAgent === true ? "chat-History-agent" : "chat-History-user"} style={{ cursor: 'pointer' }}>
		// 							{line}
		// 						</label>
		// 					</Linkify>
		// 				<br />
		// 				</React.Fragment>
		// 			))}
		// 		</>
		// 		:
		// 		<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
		// 			<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
		// 				{decoratedText}
		// 			</a>
		// 		)}>
		// 			<label className={isAgent === true ? "chat-History-agent" : "chat-History-user"} style={{ cursor: 'pointer' }}>
		// 				{lines}
		// 			</label>
		// 		</Linkify>
		// 		}
		// 	</div>
		// );

		const renderHTMLContent = (content: string) => {
			return <div dangerouslySetInnerHTML={{ __html: content }} />;
		};

		const renderPlainText = (content: string) => {
			return (
				<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
					<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
						{decoratedText}
					</a>
				)}>
					<label className={isAgent ? "chat-History-agent" : "chat-History-user"} style={{ cursor: 'pointer' }}>
						{content}
					</label>
				</Linkify>
			);
		};


		return (
			<div>
				{flag === 1 && typeof data === 'object' ?
					<>
						{lines.map((line: any, index: any) => (
							<React.Fragment key={index}>
								{isHTML(line) ? renderHTMLContent(line) : renderPlainText(line)}
								<br />
							</React.Fragment>
						))}
					</>
					:
					<>
						<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
							<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
								{decoratedText}
							</a>
						)}>
							<label className={isAgent ? "chat-History-agent" : "chat-History-user"} style={{ cursor: 'pointer' }}>
								{isHTML(lines) ? renderHTMLContent(lines) : renderPlainText(lines)}
							</label>
						</Linkify>
					</>
				}
			</div>
		);
  	};

	// const TextFormatChange = ({data, isAgent}: textFormatProps) => {
	// 	const lines = data.split('\\n');

	// 	return (
	// 		<div>
	// 		{lines.map((line: any, index: any) => (
	// 			<React.Fragment key={index}>
	// 				<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
	// 					<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
	// 						{decoratedText}
	// 					</a>
	// 				)}>
	// 					<label className={isAgent === true ? "chat-History-agent" : "chat-History-user"} style={{ cursor: 'pointer' }}>
	// 						{line}
	// 					</label>
	// 				</Linkify>
	// 			<br />
	// 			</React.Fragment>
	// 		))}
	// 		</div>
	// 	);
  	// };

	const ChatNonAgentComp = ({data, DateTimeFormat} : chatNonAgentProps) => {

		return (
			<>
				<Grid container justifyContent="flex-end" alignItems="center">
					<Grid item className="customer-time">
						{moment(new Date(data.ts)).format(DateTimeFormat)}
					</Grid>
					<Grid item className="message-container-customer customer-container">
						{(data?.type === "text" || data?.type === "Text" || data?.type?.includes("textagentId") ||
						data?.type?.includes("reply") || data?.type?.includes("agentConnected") ||
						data?.type?.includes("disconnect") || data?.type?.includes("application") || data?.type?.includes("document")) ?
							(
								<TextFormatChange data={data?.msg} isAgent={data?.agent} />
							)
							: data?.type?.toLowerCase().includes("image") ?
							(
								<ImageFormatChange data={data?.msg} isAgent={data?.agent} />
								// <img
								// 	className="chat-history-img"
								// 	src={data?.msg}
								// 	alt="edit"
								// />
							)
							: data?.type?.toLowerCase().includes("question") ?
							(
								<QuestionFormatChange data={data?.msg} isAgent={data?.agent} />
							)
							: data?.type?.toLowerCase() === "storeUserInfo" || data.type.toLowerCase() === "collectUserInfo" ?
								<ConvertFormat data={data?.msg} agent={data?.agent} />
							: data?.type?.toLowerCase() === "List" && data?.type?.toLowerCase() !== "interactive" ?
								<ListView data={data?.msg} isAgent={data?.agent} />
							: data?.msg?.type?.toLowerCase() === "button" && data?.type?.toLowerCase() !== "interactive" ?
								<ButtonRender data={data?.msg} isAgent={data?.agent} />
							: data?.type?.toLowerCase().includes("audio") && typeof data?.msg === 'string' ?
							(
								<AudioFormatChange data={data?.msg} isAgent={data?.agent} />
							)
							: data?.type?.toLowerCase().includes("audio") && typeof data?.msg !== 'string' ?
							(
								<AudioFormatChangeBot data={data?.msg} isAgent={data?.agent} />
							)
							: data?.type?.toLowerCase().includes("video") && typeof data?.msg === 'string' ?
							(
								<div className={classes.boxView}>
									<>
									<VideoFormatChange data={data?.msg} isAgent={data?.agent} />
								</>
								</div>
							)
							: data?.type?.toLowerCase().includes("video") && typeof data?.msg !== 'string' ?
							(
								<div className={classes.boxView}>
									<VideoFormatChangeBot data={data?.msg} isAgent={data?.agent} />
								</div>
							)
							: data?.type?.toLowerCase().includes("location") ?
							(
								<div className={classes.boxView}>
									<LocationMapView data={data?.msg} />
								</div>
							)
							: data?.type?.toLowerCase() === "interactive" && data?.msg?.type?.toLowerCase() === 'list' ?
							(
								<InteractiveListView data={data?.msg} isAgent={data?.agent} />
							)
							: data?.type?.toLowerCase() === "interactive" && data?.msg?.type?.toLowerCase() === 'button' ?
							(
								<InteractiveButtonView data={data?.msg} isAgent={data?.agent} />
							)
							: data?.type?.toLowerCase() === "interactive" && data?.msg?.type?.toLowerCase() === 'carousel' ?
                            (
                                <div className={classes.carousel}>
                                    <CarouselPreview data={data?.msg?.data?.data} />
                                </div>
                            )
							: data?.type?.toLowerCase() === "formreply" ?
							(
								<div className={classes.formViewOuter}>
									<FormNodeView data={data?.msg} isAgent={data?.agent} />
								</div>
							)
							: data?.type?.toLowerCase() === "form" ?
							(
								<div className={classes.formViewOuter}>
									<FormNewNodeView data={data?.msg} isAgent={data?.agent} />
								</div>
							)
							:
							(
								<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
									<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
										{decoratedText}
									</a>
								)}>
									<label className="chat-History-user" style={{ cursor: 'pointer' }}>
										{JSON.stringify(data?.msg)}
									</label>
								</Linkify>
							)
						}
					</Grid>
				</Grid>
			</>
		)

	}

	const ChatAgentComp = ({data, DateTimeFormat} : chatAgentProps) => {

		return (
			<>
				<Grid container alignItems="center">
					<Grid item className="message-container">
						{(data?.type === "text" || data?.type === "Text" || data?.type?.includes("textagentId") ||
							data?.type?.includes("reply") || data?.type?.includes("agentConnected")||
							data?.type?.includes("disconnect") || data?.type?.includes("application") || data?.type?.includes("document")) ?
							(
								<TextFormatChange data={data?.msg} isAgent={data?.agent} />
							)
							: data?.type?.toLowerCase().includes("image") ?
							(
								<ImageFormatChange data={data?.msg} isAgent={data?.agent} />
								// <img
								// 	className="chat-history-img"
								// 	src={data?.msg}
								// 	alt="edit"
								// />
							)
							: data?.type?.toLowerCase().includes("question") ?
							(
								<QuestionFormatChange data={data?.msg} isAgent={data?.agent} />
							)
							: data?.type?.toLowerCase() === "storeUserInfo" || data.type.toLowerCase() === "collectUserInfo" ?
								<ConvertFormat data={data?.msg} agent={data?.agent} />
							: data?.type?.toLowerCase() === "List" && data?.type?.toLowerCase() !== "interactive" ?
								<ListView data={data?.msg} isAgent={data?.agent} />
							: data?.msg?.type?.toLowerCase() === "button" && data?.type?.toLowerCase() !== "interactive" ?
								<ButtonRender data={data?.msg} isAgent={data?.agent} />
							: data?.type?.toLowerCase().includes("audio") && typeof data?.msg === 'string' ?
							(
								<AudioFormatChange data={data?.msg} isAgent={data?.agent} />
							)
							: data?.type?.toLowerCase().includes("audio") && typeof data?.msg !== 'string' ?
							(
								<AudioFormatChangeBot data={data?.msg} isAgent={data?.agent} />
							)
							: data?.type?.toLowerCase().includes("video") && typeof data?.msg === 'string' ?
							(
								<div className={classes.boxView}>
									<VideoFormatChange data={data?.msg} isAgent={data?.agent} />
								</div>
							)
							: data?.type?.toLowerCase().includes("video") && typeof data?.msg !== 'string' ?
							(
								<div className={classes.boxView}>
									<>
									<VideoFormatChangeBot data={data?.msg} isAgent={data?.agent} />
								</>
								</div>
							)
							: data?.type?.toLowerCase().includes("location") ?
							(
								<div className={classes.boxView}>
									<LocationMapView data={data?.msg} />
								</div>
							)
							: data?.type?.toLowerCase() === "interactive" && data?.msg?.type?.toLowerCase() === 'list' ?
							(
								<InteractiveListView data={data?.msg} isAgent={data?.agent} />
							)
							: data?.type?.toLowerCase() === "interactive" && data?.msg?.type?.toLowerCase() === 'button' ?
							(
								<InteractiveButtonView data={data?.msg} isAgent={data?.agent} />
							)
							: data?.type?.toLowerCase() === "interactive" && data?.msg?.type?.toLowerCase() === 'carousel' ?
                            (
                                <div className={classes.carousel}>
                                    <CarouselPreview data={data?.msg?.data?.data} />
                                </div>
                            )
							: data?.type?.toLowerCase() === "formreply" ?
							(
								<div className={classes.formViewOuter}>
									<FormNodeView data={data?.msg} isAgent={data?.agent} />
								</div>
							)
							: data?.type?.toLowerCase() === "form" ?
							(
								<div className={classes.formViewOuter}>
									<FormNewNodeView data={data?.msg} isAgent={data?.agent} />
								</div>
							)
							: 
							(
								<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
									<a href={decoratedHref} rel="noopener noreferrer" key={key} target="_blank">
										{decoratedText}
									</a>
								)}>
									<label className="chat-History-agent" style={{ cursor: 'pointer' }}>
										{JSON.stringify(data?.msg)}
									</label>
								</Linkify>
							)
						}
					</Grid>
					<Grid item className="message-time">
						{moment(new Date(+data.ts)).format(DateTimeFormat)}
					</Grid>
				</Grid>
			</>
		)

	}

	const agentDetails = chatData?.transcript?.find((eachMsg: any) => eachMsg.agent === 'true' || eachMsg.agent === true);
	return (
		<Drawer open={isChatOpen} anchor={'right'} onClose={() => toggleChatDrawer(false)}>
			<Box sx={{ maxWidth:700,minWidth: 500, marginTop: '87px', padding: '7px' }} className="chat-history-main-container">
				<Grid container className="chat-history-heading-container">
					<Grid container justifyContent="space-between">
						<Grid item className="chat-history-heading">
							<span>Chat History</span>
						</Grid>
						<Grid item onClick={() => toggleChatDrawer(false)}>
							<img src={`${process.env.PUBLIC_URL}/icons/close.svg`} alt="close" />
						</Grid>
					</Grid>
					<div className="agent-name-style">
						<b>Agent - </b>
						{/* {agentDetails?.agentId} */}
                        {liveReport ? chatData?.rowN?.AgentID.value : chatDigital ? chatData?.rowN?.AgentID : chatData?.rowN?.agentID}
					</div>
				</Grid>
				<Grid container spacing={1} justifyContent="space-between" alignItems="center" className="date-container">
					{chatDigital ?
						<Grid item>{moment(new Date(chatData?.rowN?.ChatDate)).format(DateFormat)}</Grid>
						:
						<Grid item>
							<Grid item>{liveReport ? chatData?.rowN?.StartTime : chatData?.rowN?.startTime}</Grid>
						</Grid>
					}
					<Grid item>
						Session Id - <span className="session-id">{chatData.SessionId}</span>
					</Grid>
				</Grid>

                {chatData?.transcript?.map((data: any, idx: number) => (
                    <div className="chat-box">
                        {
                            (data?.agent === true || data?.agent === 'true') && data?.type != null && data?.msg != null ?
                            (
								<>
                                <div>
									<div key={idx} className="agent-chat-container">
										<div className="message-sender">{data?.agentId === null || data?.agentId === '' ? 'Agent' : data?.agentId}</div>
										<ChatAgentComp DateTimeFormat={DateTimeFormat} data={data}/>
									</div>
                                </div>
								</>
                            )
                            : (data.agent === false || data?.agent === 'false') && (data.bot === false ||data.bot === 'false' ) && (data.botHist === true ||data.botHist === 'true' ) && data.type != null && data.msg != null ? //customer
                            (
                                <>
                                <div >
									<div key={idx} className="agent-chat-container">
										<div className="message-sender">Bot Hist</div>
										<ChatAgentComp DateTimeFormat={DateTimeFormat} data={data}/>
									</div>
                                </div>
                                </>
                            )
							: (data.agent === false ||data.agent === 'false') && (data.bot === true ||data.bot === 'true' ) && (data.botHist === false ||data.botHist === 'false' ) && data.type != null && data.msg != null ? //customer
                            (
                                <>
                                <div>
									<div key={idx} className="agent-chat-container">
										<div className="message-sender">CHAT BOT</div>
										<ChatAgentComp DateTimeFormat={DateTimeFormat} data={data}/>
									</div>
                                </div>
                                </>
                            )
                            : (data.agent === false || data.agent === 'false') && (data.bot === false || data.bot === 'false') && (data.botHist === false || data.botHist === 'false') && data.type != null && data.msg != null && //customer
                            (
                                <>
                                <div>
									<div key={idx} className="customer-chat-container">
										<div className="message-sender customer">Customer</div>
										<ChatNonAgentComp data={data} DateTimeFormat={DateTimeFormat}/>
									</div>
                                </div>
                                </>
                            )
                        }
                    </div>
                ))}
			</Box>
		</Drawer>
	);
}

export default ChatHistoryDrawer;
