import React from 'react';
import {Rating } from '@mui/material';

type  RatingProps = {
	defaultValue: any;
	row?: any;
	handleOn? :any;
    disabled? : any;
    name?: string | null | undefined;
}

const OZRatingComponent = ({defaultValue, row, handleOn, disabled, name} : RatingProps) => {
	// const [value , setValue] = React.useState<any>(defaultValue || 0);
	const [valueRating , setValueRating] = React.useState<any>(name === 'LiveReport' ? defaultValue : 0);

	const handleClickRating = (event: any, row: any) => {
		setValueRating((prev :any)=>{
			if(parseInt(prev) === parseInt(event?.target?.value)){
				handleOn(0, row);
				return 0;
			}else{
				handleOn(parseInt(event?.target?.value), row);
				return parseInt(event?.target?.value);
			}
		})
	}

	return (
		<>
		{(row?.status !== "Answered" && row?.status !== "answered" ) && (row?.Status !== "Answered" && row?.Status !== "answered" ) ?
		<>
		</>
		:
		<>
			{name === 'LiveReport' ?
				(
					<Rating name="size-small" value={valueRating} precision={0.5} size="small" onChange={(event: any) => handleClickRating(event, row)} disabled={(name !== 'LiveReport') ? true : false}/>
				)
			:
				(
					// <Rating name="size-small" value={parseInt(defaultValue !== '' ? defaultValue : 0)} precision={0.5} size="small" onChange={(event: any) => handleClickRating(event, row)} disabled={(name !== 'LiveReport') ? true : false}/>
					<Rating name="size-small" value={parseFloat(defaultValue !== '' ? defaultValue : 0)} precision={0.5} size="small" onChange={(event: any) => handleClickRating(event, row)} disabled={(name !== 'LiveReport') ? true : false}/>
				)
			}
			{/* <Rating name="size-small" value={parseInt(defaultValue !== '' ? defaultValue : 0)} precision={0.5} size="small" onChange={(event: any) => handleClickRating(event, row)} disabled={(name !== 'LiveReport') ? true : false}/> */}
			</>
		}
		</>
	)
};

export default OZRatingComponent;