import { ReactComponent as FilterAltOutlinedIcon } from "../../../static/icons/filter.svg";
import Card from "@mui/material/Card";
import { styled, darken } from "@mui/material/styles";
import * as React from "react";
import FilterDrawer from "../../FilterDrawer/FilterDrawer";
import WidgetDownload from "../../Widget-Download/Widget-Download";
import WidgetGraph from "../../Widget-Graph/Widget-Graph";
import WidgetSearch from "../../Widget-Search/Widget-Search";

import {
  Box,
  Chip,
  Grid,
  IconButton,
  Popover,
  Stack,
  Theme,
  Typography,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import {
  getAgentDrawerDeets,
  getAgentEvents,
  getChatStatus,
  getAgentStateDetail,
  releaseChat,
  getChatStatusHistoryDetails,
  getWidgetConfig
} from "../../../services/api-service";
import "../AgentList/AgentList.css";
import BargeInDrawer from "../../BargeInDrawer/BargeInDrawer";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import AgentDetailsDrawer from "../../AgentDetailsDrawer/AgentDetailsDrawer";
import { ReactComponent as DragIcon } from "../../../static/icons/drag.svg";
import {
  STATUS_CLASS_MAP,
  WIDGET_REFRESH_INTERVAL,
} from "../../../utils/constants";
// import ThresholdDrawer from "../../ThresholdDrawer/ThresholdDrawer";
import { AccessTime } from "@mui/icons-material";
import {
  mapRowDataWithThreshold,
  secondsToMinSec,
  secondsToTime,
  checkDataAccessFilters,
  determineFilterType,
  checkRoles
} from "../../../utils/util";
// import TranscriptDrawer from "../../TranscriptDrawer/TranscriptDrawer";
import ChatHistoryDrawer from "../../ChatHistoryDrawer/ChatHistoryDrawer";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import Tab from "@mui/material/Tab";

interface Column {
  id:
  | "AgentName"
  | "agentAction"
  | "CampaignName"
  | "AgentMode"
  | "CustomerPhoneNumber"
  | "CallType"
  | "Skill"
  | "LastCall"
  | "AgentState"
  | "Since"
  | "Details"
  | "SkillName"
  | "AgentID"
  | "HoldDuration"
  | "CallStatus"
  | "State"
  | "EmailID"
  | "CampaignName"
  | "PhoneNumber"
  | "UUI"
  | "SessionID"
  | "Duration"
  | "Action"
  | "Transcript"
  | "ChatState"
  | "EndChat"
  | "ChannelType";
  label: string;
  minWidth?: number;
  draggable?: boolean;
  width?: number;
  align?: "right" | "center";
  type?: string;
}

const useStyles: any = makeStyles((theme: Theme) => ({
  popoverContent: {
    minWidth: 350,
    fontSize: "12px",
    lineHeight: 2,
  },
  dragdropItemsWrapper: {
    display: "flex",
    height: "100%",
    width: "100%",
    margin: "0 1rem",
    alignItems: "center",
  },
}));

const DetailsIconButton = styled(IconButton)(
  ({ theme }) => `
  background-color: ${theme.palette.primary.main};
  color: white;
  padding: 0.1rem;

  :hover {
    background-color: ${darken(theme.palette.primary.main, 0.2)};
  }
`
);

const thresholdItemsDefault = [
  {
    label: "Idle",
    key: "AgentState",
    value: "IDLE",
    fields: [
      { label: "Duration", key: "Since", value: "", condition: "GREATER_THAN" },
    ],
  },
  {
    label: "Busy",
    key: "AgentState",
    value: "BUSY",
    fields: [
      { label: "Duration", key: "Since", value: "", condition: "GREATER_THAN" },
      {
        label: "Hold Time",
        key: "HoldDuration",
        value: "",
        condition: "GREATER_THAN",
      },
    ],
  },
  {
    label: "ACW",
    key: "AgentState",
    value: "ACW",
    fields: [
      { label: "Duration", key: "Since", value: "", condition: "GREATER_THAN" },
      {
        label: "Hold Time",
        key: "HoldDuration",
        value: "",
        condition: "GREATER_THAN",
      },
    ],
  },
  {
    label: "Calling",
    key: "AgentState",
    value: "CALLING",
    fields: [
      { label: "Duration", key: "Since", value: "", condition: "GREATER_THAN" },
      {
        label: "Hold Time",
        key: "HoldDuration",
        value: "",
        condition: "GREATER_THAN",
      },
    ],
  },
  {
    label: "Pause",
    key: "AgentState",
    value: "PAUSE",
    fields: [
      { label: "Duration", key: "Since", value: "", condition: "GREATER_THAN" },
      {
        label: "Hold Time",
        key: "HoldDuration",
        value: "",
        condition: "GREATER_THAN",
      },
    ],
  },
  {
    label: "Ready",
    key: "AgentState",
    value: "READY",
    fields: [
      { label: "Duration", key: "Since", value: "", condition: "GREATER_THAN" },
    ],
  },
  {
    label: "AUX",
    key: "AgentState",
    value: "AUX",
    fields: [
      { label: "Duration", key: "Since", value: "", condition: "GREATER_THAN" },
    ],
  },
];
const defaultColumns: readonly Column[] = [
  {
    id: "SessionID",
    label: "Session ID",
    draggable: false,
    minWidth: 150,
    width: 200,
  },
  {
    id: "CampaignName",
    label: "Campaign Name",
    draggable: false,
    minWidth: 150,
    width: 150,
  },
  {
    id: "SkillName",
    label: "Skill",
    align: "center",
    minWidth: 80,
    width: 120,
  },
  { id: 'AgentID', label: 'Agent ID', minWidth: 120, width: 120 },
  // { id: "AgentID", label: "Agent ID", minWidth: 120, width: 120 },
  { id: "AgentName", label: "Agent Name", minWidth: 120, width: 120 },
  { id: "agentAction", label: "", minWidth: 30, width: 120 },

  { id: 'ChatState', label: 'State', align: 'center', minWidth: 50, width: 120 },
  {
    id: "EmailID",
    label: "Email ID",
    align: "center",
    minWidth: 120,
    width: 260,
  },

  {
    id: "PhoneNumber",
    label: "Phone",
    draggable: false,
    minWidth: 50,
    width: 150,
  },
  {
    id: "UUI",
    label: "UUI",
    draggable: false,
    minWidth: 50,
    width: 150,
  },

  { id: "Duration", label: "Duration", minWidth: 65, width: 120, type: "TIME" },
  {
    id: "Action",
    label: "Action",
    minWidth: 100,
    width: 100
  },
  // { id: 'Transcript', label: 'Transcript',  minWidth: 30, width: 120, align: 'center' },
  // { id: 'EndChat', label: 'End Chat',  minWidth: 30, width: 120 },

  // { id: 'CustomerPhoneNumber', label: 'Customer No.', align: 'center', minWidth: 50, width: 120 },
  // { id: 'AgentMode', label: 'Mode', draggable: true, align: 'center', minWidth: 30, width: 120 },

  // { id: 'CallStatus', label: 'Last Call', align: 'center', minWidth: 50, width: 120 },
  // { id: 'AgentState', label: 'State', draggable: true, align: 'center', minWidth: 50, width: 120 },
  // { id: 'Since', label: 'Duration', align: 'center', minWidth: 30, width: 120, type: 'TIME' },
  // { id: 'Details', label: 'Details', align: 'center', minWidth: 30, width: 120 }

  // { id: 'holdTime', label: 'Hold Time', minWidth: 65 },
  // { id: 'AgentState', label: 'Status', minWidth: 50 },
  // { id: 'Since', label: 'Duration', minWidth: 50 },
  // { id: 'adminAction', label: 'Admin Action', minWidth: 185 },
  // { id: 'hamburger', label: '', minWidth: 20 },
];

const defaultDownloadColumns: readonly Column[] = [
  {
    id: "SessionID",
    label: "Session ID",
    draggable: false,
    minWidth: 150,
    width: 200,
  },
  {
    id: "CampaignName",
    label: "Campaign Name",
    draggable: false,
    minWidth: 150,
    width: 150,
  },
  {
    id: "SkillName",
    label: "Skill",
    align: "center",
    minWidth: 80,
    width: 120,
  },
  { id: 'AgentID', label: 'Agent ID', minWidth: 120, width: 120 },
  { id: "AgentName", label: "Agent Name", minWidth: 120, width: 120 },
  {
    id: "EmailID",
    label: "Email ID",
    align: "center",
    minWidth: 120,
    width: 260,
  },
  {
    id: "ChatState",
    label: "State",
    draggable: false,
    minWidth: 50,
    width: 150,
  },
  {
    id: "PhoneNumber",
    label: "Phone",
    draggable: false,
    minWidth: 50,
    width: 150,
  },
  {
    id: "UUI",
    label: "UUI",
    draggable: false,
    minWidth: 50,
    width: 150,
  },
  { id: "Duration", label: "Duration", minWidth: 65, width: 120, type: "TIME" },
];

const defaultWithDataAccessColumns: readonly Column[] = [
  {
    id: "SessionID",
    label: "Session ID",
    draggable: false,
    minWidth: 150,
    width: 200,
  },
  {
    id: "CampaignName",
    label: "Campaign Name",
    draggable: false,
    minWidth: 150,
    width: 150,
  },
  {
    id: "SkillName",
    label: "Skill",
    align: "center",
    minWidth: 80,
    width: 120,
  },
  { id: 'AgentID', label: 'Agent ID', minWidth: 120, width: 120 },
  { id: "AgentName", label: "Agent Name", minWidth: 120, width: 120 },
  { id: "agentAction", label: "", minWidth: 30, width: 120 },

  { id: 'ChatState', label: 'State', align: 'center', minWidth: 50, width: 120 },
  {
    id: "EmailID",
    label: "Email ID",
    align: "center",
    minWidth: 120,
    width: 260,
  },

  {
    id: "PhoneNumber",
    label: "Phone",
    draggable: false,
    minWidth: 50,
    width: 150,
  },
  {
    id: "UUI",
    label: "UUI",
    draggable: false,
    minWidth: 50,
    width: 150,
  },

  { id: "Duration", label: "Duration", minWidth: 65, width: 120, type: "TIME" },
  { id: "ChannelType", label: "Channel", minWidth: 65, width: 120 },
  {
    id: "Action",
    label: "Action",
    minWidth: 100,
    width: 100
  },
];

const defaultDownloadWithDataAccessColumns: readonly Column[] = [
  {
    id: "SessionID",
    label: "Session ID",
    draggable: false,
    minWidth: 150,
    width: 200,
  },
  {
    id: "CampaignName",
    label: "Campaign Name",
    draggable: false,
    minWidth: 150,
    width: 150,
  },
  {
    id: "SkillName",
    label: "Skill",
    align: "center",
    minWidth: 80,
    width: 120,
  },
  { id: 'AgentID', label: 'Agent ID', minWidth: 120, width: 120 },
  { id: "AgentName", label: "Agent Name", minWidth: 120, width: 120 },
  {
    id: "EmailID",
    label: "Email ID",
    align: "center",
    minWidth: 120,
    width: 260,
  },
  {
    id: "ChatState",
    label: "State",
    draggable: false,
    minWidth: 50,
    width: 150,
  },
  {
    id: "PhoneNumber",
    label: "Phone",
    draggable: false,
    minWidth: 50,
    width: 150,
  },
  {
    id: "UUI",
    label: "UUI",
    draggable: false,
    minWidth: 50,
    width: 150,
  },
  { id: "ChannelType", label: "Channel", minWidth: 65, width: 120 },
  { id: "Duration", label: "Duration", minWidth: 65, width: 120, type: "TIME" },
];

let interval: any;

export default function ChatStatusDigital(props: any) {
  const { useState, useRef, useEffect } = React;
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
  const [isTranscriptDrawerOpen, setIsTranscriptDrawerOpen] = useState<
    boolean | undefined
  >(false);
  const [rowData, setRowData] = useState<Array<any>>([]);
  const [filteredRowData, setFilteredRowData] = useState<Array<any>>([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>(defaultColumns);
  const [downloadColumns, setDownloadColumns] = useState<any>(defaultDownloadColumns);
  const [isBargeInOpen, setIsBargeInOpen] = useState<boolean>(false);
  const [isAgentDetailsDrawerOpen, setIsAgentDetailsDrawerOpen] =
    useState<boolean>(false);
  const [eleRefPoint, setElRefPoint] = useState<any>(null);
  const [selectedAgent, setSelectedAgent] = useState<any>(null);
  const [allowedFilters, setAllowedFilters] = useState<string[]>(['Campaign', 'Skill', 'Group']);
  const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || checkDataAccessFilters() ? 2 : 1);
  const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
  const [agentListFilter, setAgentListFilter] = useState<any>(props?.selectedFilters?.filters || []);
  const [groupingColumns, setGroupingColumns] = useState<any>([]);
  const [groupedData, setGroupedData] = useState<any>({});
  const downloadContent = useRef<null | any>(null);
  const [responseLength, setresponseLength] = useState<Number>(0);
  const [isThresholdDrawerOpen, setIsThresholdDrawerOpen] = useState<
    boolean | undefined
  >(false);
  const [selectedAgentEventDetails, setSelectedAgentEventDetails] =
    useState<any>(null);
  const [isEventsPopoverOpen, setIsEventsPopoverOpen] =
    useState<boolean>(false);
  const [thresholdItems, setThresholdItems] = useState<Array<any>>(
    thresholdItemsDefault
  );
  const [drawerDetails, setDrawerDetails] = useState<any>({});
  const [transcriptDrawerDetails, setTranscriptDrawerDetails] = useState<any>({});
  const [searchText, setSearchText] = useState<any>({});
  const [callType, setCallType] = React.useState("all");
  const [openAlert, setOpenAlert] = React.useState(false);
  const [endChatDetails, setEndChatDetails] = React.useState<any>([]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F0F1F2",
      color: "#000000",
      padding: "10px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px 16px",
    },
  }));
  const agentDetailsItems = [
    { label: "Total Login", key: "TotalLoginDuration" },
    { label: "Last Logout", key: "LastLogoutTime" },
    { label: "Calls Offered", key: "TotalCalls" },
    { label: "Answered", key: "AnsweredCalls" },
    { label: "Abandoned", key: "AbandonedCalls" },
    { label: "Average Hold Time", key: "AverageHoldDuration" },
    { label: "AHT", key: "AverageHandlingDuration" },
    { label: "ASA", key: "AverageSpeedToAnswer" },
    { label: "ACW", key: "WrapupDuration" },
    { label: "Pause Time", key: "TotalPauseDuration" },
  ];

  useEffect(() => {
    if(checkRoles("ROLE_DATA_ACCESS")){
      setColumns(defaultWithDataAccessColumns);
      setDownloadColumns(defaultDownloadWithDataAccessColumns);
    }else{
      setColumns(defaultColumns);
      setDownloadColumns(defaultDownloadColumns);
    }
  }, []);

  const getPopoverContent = () => {
    return (
      <Box sx={{ p: 2 }} className={classes.popoverContent}>
        <div>
          {agentDetailsItems.map((item: any, idx: number) => (
            <Grid
              key={idx}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={7}>
                <b>{item.label}</b>
              </Grid>
              <span>{selectedAgent?.[item.key] || 0}</span>
            </Grid>
          ))}
        </div>
      </Box>
    );
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf0f280",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const handleOnPopoverClose = () => {
    setElRefPoint(null);
    setSelectedAgent(null);
    setIsPopoverOpen(false);
  };
  const handleDownload = (type: string) => {
    console.log(type);
  };

  const toggleDrawer = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen);
  };

  const toggleTranscriptDrawer = (isOpen: boolean) => {
    console.log(isOpen);
    setIsTranscriptDrawerOpen(isOpen);
  };

  const getTableData = async (filters = "0") => {
    let data = await getChatStatus({ filters, type: filterType, callType });
    console.log(data);

    // data = data.map((data : any) => {
    //  console.log(data);
    //  let rowD = { ...data };
    //  rowD = { ...rowD,
    //    EndChat: <span className="icon-col-sm">
    //          <IconButton disableRipple onClick={(event) => toggleTranscriptDrawer(true)}>
    //            <img src={`${process.env.PUBLIC_URL}/icons/end-chat.svg`} width="20" alt="EndChat"></img>
    //          </IconButton>
    //        </span>,

    //    Transcript:<span className="icon-col-sm">
    //            <IconButton disableRipple onClick={(event) => toggleTranscriptDrawer(true)}>
    //              <img src={`${process.env.PUBLIC_URL}/icons/transcript.svg`} width="20" alt="Tanscript"></img>
    //            </IconButton>
    //          </span>,

    // };

    //  return rowD;
    // })

    const updatedRowData = mapRowDataWithThreshold(thresholdItems, data);
    // for (const row of updatedRowData) {

    //  const skills = row.AgentSkills?.split(',') || [];
    //  const tempSkills = [...skills];
    //  if (skills.length > 3) {
    //    row.SkillsFormatted = (
    //      <div>
    //        {skills.slice(0, 3).join(',')}
    //        <span key="badge" className="badge" title={tempSkills.slice(3).join(',')}>
    //          {`+${skills.length - 3}`}
    //        </span>
    //      </div>
    //    );
    //  } else {
    //    row.SkillsFormatted = skills.join(',');
    //  }
    // }
    setRowData(updatedRowData);
    if (searchText.length) {
      const filteredRows = updatedRowData.filter((item: any) =>
        item.AgentName.toLowerCase().includes(searchText)
      );
      setFilteredRowData(filteredRows);
    } else {
      setFilteredRowData(updatedRowData);
    }
  };

  const agentDetails = async (event: any, agent: any) => {
    const agentData = await getAgentStateDetail({
      agentId: agent.AgentUniqueId,
    });
    setSelectedAgent(
      agentData.find((eachAgent) => eachAgent.AgentName === agent.AgentId)
    );
    setElRefPoint({ top: event.clientY, left: event.clientX });
    setIsPopoverOpen(true);
  };

  // const agentStates = (agent: any) => {
  //  console.log(agent);
  // };

  const agentEvents = async (event: any, agent: any) => {
    setSelectedAgent(agent);
    let response = await getAgentEvents({ agentId: agent.AgentIncID });
    response = response.map((dataItem) => ({
      ...dataItem,
      StartTime: dataItem.StartTime
        ? `${dataItem.StartTime.toString().split(" ")[0]}T${dataItem.StartTime.toString().split(" ")[1]
        }`
        : "",
      EndTime: dataItem.EndTime
        ? `${dataItem.EndTime.split(" ")[0]}T${dataItem.EndTime.toString().split(" ")[1]
        }`
        : "",
    }));
    setSelectedAgentEventDetails(response);
    setElRefPoint({ top: event.clientY, left: event.clientX });
    setIsEventsPopoverOpen(true);
  };

  const toggleBargeInDrawer = () => {
    setIsBargeInOpen(!isBargeInOpen);
  };

  const agentDetailsDrawer = async (agent: any) => {
    const data = await getAgentDrawerDeets({
      filters: "0",
      type: filterType,
      agentId: agent.AgentUniqueId,
    });
    setDrawerDetails(data);
    setSelectedAgent(agent);
    toggleAgentDetailsDrawer();
  };

  const agentClick = async (action: string, agent: any) => {
    if (action === "Tanscript") {
      setTranscriptDrawerDetails({});
      const chatDetails: any = await getChatStatusHistoryDetails({ SessionId: agent?.SessionID });
      setTranscriptDrawerDetails({ transcript: chatDetails, SessionId: agent?.SessionID, rowN: agent });
      // setTranscriptDrawerDetails(agent);
      toggleTranscriptDrawer(true);
    } else if (action === "EndChat") {
      setOpenAlert(true);
      setEndChatDetails(agent);
      // setEndChatDetails(agent);
    }
  };

  const toggleAgentDetailsDrawer = () => {
    setIsAgentDetailsDrawerOpen(!isAgentDetailsDrawerOpen);
  };

  const handleFilterDelete = (id: Number) => {
    const newFilter = agentListFilter.filter((item: any) => item.id !== id);
    setAgentListFilter(newFilter);
    props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
  };

  const clearFilter = () => {
    console.info("Clear all filter.");
    setAgentListFilter([]);
    props?.handleFilterChange?.([], filterSection, props?.widgetId);
  };

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    item: any
  ) => {
    event.dataTransfer?.setData("agentData", JSON.stringify(item));
  };

  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const agentData: any = JSON.parse(
      event.dataTransfer?.getData("agentData") || "{}"
    );
    if (!groupingColumns.find((col: any) => col.id === agentData.id)) {
      setGroupingColumns([...groupingColumns, agentData]);
    }
    return;
  };

  const handleFilterExistingData = (filteredData: any) => {
		setAgentListFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

  const handleApplyFilters = ({
    selectedFilters,
    filterType: newFilterType,
    filterSection: newfilterSection
  }: any) => {
    setAgentListFilter(selectedFilters);
    setFilterType(newFilterType);
    setFilterSection(newfilterSection);
    props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
    toggleDrawer(false);
  };

  React.useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(()	=> {
    const fetchWidgetConfig = async () => {
      try {
        if(checkDataAccessFilters()) {
          let widgetConfig = await getWidgetConfig('135', 'widget');
          if (widgetConfig) {
						if (typeof widgetConfig === 'string') {
							widgetConfig = JSON.parse(widgetConfig);
						}
						// Check the condition and set the appropriate filters
						const filters = widgetConfig.data && checkDataAccessFilters()
										? widgetConfig.data
										: widgetConfig.config;
		
						if (filters) {
							const filtersArray = filters.split(',');
							setAllowedFilters(filtersArray);
							const determinedFilterType = determineFilterType(filtersArray);
							if (determinedFilterType !== undefined) {
								setFilterType(determinedFilterType);
							}
						}
					}
        }
      } catch (error) {
        console.error('Error fetching widget config:', error);
      }
    };
      fetchWidgetConfig();
  }, []);

  React.useEffect(() => {
    const filterIds = (agentListFilter.length && !(responseLength === agentListFilter.length)) ? agentListFilter.map((item: any) => item.id).join(',') : '0';
    // const filterIds = agentListFilter.length? agentListFilter.map((item: any) => item.id).join(","): "0";
    getTableData(filterIds);
    if (interval) {
      clearInterval(interval);
    }

    interval = setInterval(
      () => getTableData(filterIds),
      WIDGET_REFRESH_INTERVAL.AGENT_LIST
    );
  }, [agentListFilter, thresholdItems, searchText, callType]);

  // const groupColumns = (rowData: any, column: any) => {
  //  return rowData.reduce((acc: any, value: any) => {
  //    (acc[value[column.id]] = acc[value[column.id]] || []).push(value);
  //    return acc;
  //  }, {});
  // };

  React.useEffect(() => {
    if (groupingColumns.length && filteredRowData.length) {
      let groupedData = {};
      for (const data of filteredRowData) {
        // groupedData = groupColumns(rowData, data);

        groupingColumns
          .reduce((groupAcc: any, column: any, groupIdx: number) => {
            const key = data[column.id];
            groupAcc[key] =
              groupAcc[key] ||
              (groupIdx === groupingColumns.length - 1 ? [] : {});
            return groupAcc[key];
          }, groupedData)
          .push(data);
      }
      setGroupedData(groupedData);
    }
  }, [groupingColumns, filteredRowData]);

  const filterItems = agentListFilter.map((item: any, index: number) => (
    <Chip
      key={index}
      label={item.label}
      color="primary"
      variant="outlined"
      onDelete={() => handleFilterDelete(item.id)}
      deleteIcon={<CloseOutlinedIcon style={{ fontSize: "12px" }} />}
    />
  ));

  const handleOnEventsPopoverClose = () => {
    setElRefPoint(null);
    setSelectedAgent(null);
    setIsEventsPopoverOpen(false);
  };

  const formatAMPM = (date: Date) => {
    if (date) {
      var hours = date.getHours();
      var minutes: any = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    } else {
      return "";
    }
  };

  const getTimeDifferenceInString = (higherDate: string, lowerDate: string) => {
    const seconds =
      (new Date(higherDate).getTime() - new Date(lowerDate).getTime()) / 1000;
    if (!isNaN(seconds)) {
      if (seconds > 60) {
        const minutes = seconds / 60;
        if (minutes > 60) {
          const hours = minutes / 60;
          return `${hours.toFixed(1)} Hrs`;
        } else {
          return `${Math.floor(minutes)} Mins`;
        }
      } else {
        return `${seconds} Secs`;
      }
    } else {
      return "";
    }
  };

  const getColorByAgentEvent = (event: string) => {
    return event.toLowerCase() === "calling"
      ? "yellow"
      : event.toLowerCase() === "aux"
        ? "green"
        : event.toLowerCase() === "release"
          ? "red"
          : event.toLowerCase() === "idle"
            ? "orange"
            : event.toLowerCase() === "incall"
              ? "lightgreen"
              : "";
  };

  // function getColorByStatus (value : string) {
  //  if(value.toLowerCase() === 'aux'){
  //    return(
  //      <div style={{color: '#4F9F52'}}>
  //        {value}
  //      </div>
  //    )
  //  }else if(value.toLowerCase() === 'calling'){
  //    return(
  //      <div style={{color: '#3296FB'}}>
  //        {value}
  //      </div>
  //    )
  //  }else if(value.toLowerCase() === 'idle'){
  //    return(
  //      <div style={{color: '#A4A4C9'}}>
  //        {value}
  //      </div>
  //    )
  //  }else if(value.toLowerCase() === 'busy'){
  //    return(
  //      <div style={{color: '#E65B5C'}}>
  //        {value}
  //      </div>
  //    )
  //  }else if(value.toLowerCase() === 'pause'){
  //    return(
  //      <div style={{color: '#536580'}}>
  //        {value}
  //      </div>
  //    )
  //  }else if(value.toLowerCase() === 'acw'){
  //    return(
  //      <div style={{color: '#E0A751'}}>
  //        {value}
  //      </div>
  //    )
  //  }else if(value.toLowerCase() === 'ready'){
  //    return(
  //      <div style={{color: '#FFD993'}}>
  //        {value}
  //      </div>
  //    )
  //  }else if(value.toLowerCase() === 'exception'){
  //    return(
  //      <div style={{color: '#EBADAD'}}>
  //        {value}
  //      </div>
  //    )
  //  }else if(value.toLowerCase() === 'logout'){
  //    return(
  //      <div style={{color: '#9CEDE9'}}>
  //        {value}
  //      </div>
  //    )
  //  }
  // };

  const getEventsPopoverContent = () => {
    return (
      <Box sx={{ p: 2 }} className={classes.popoverContent}>
        <div>
          <h3>{selectedAgent?.AgentName}</h3>
          <Stack direction={"column"} gap={1}>
            {selectedAgentEventDetails?.map((item: any, idx: number) => (
              <Box
                key={idx}
                display={"flex"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display={"flex"} gap={1}>
                  {
                    <Box
                      sx={{
                        background: getColorByAgentEvent(item.Event),
                        width: "2px",
                        height: "2rem",
                        borderRadius: "4px",
                      }}
                    ></Box>
                  }
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Stack direction={"row"} gap={5}>
                      <Typography
                        sx={{
                          minWidth: "5rem",
                          fontWeight: "500",
                          fontSize: "12px",
                          color: "rgba(33, 33, 33, 0.7)",
                        }}
                      >
                        {item.Event.toUpperCase()}
                      </Typography>

                      {item?.StartTime && item?.EndTime && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#99A0A8",
                          }}
                        >
                          <AccessTime
                            sx={{ color: "inherit", fontSize: "18px" }}
                          />

                          <Typography
                            sx={{ color: "inherit", fontSize: "inherit" }}
                          >
                            {getTimeDifferenceInString(
                              item?.EndTime,
                              item?.StartTime
                            )}
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#99A0A8",
                      }}
                    >
                      {formatAMPM(new Date(item?.StartTime))}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
        </div>
      </Box>
    );
  };

  const getTableRows = (data: any) => {
    return (
      <>
        {data.map((row: any, rowIdx: number) => {
          return (
            <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.SNO}>
              {groupingColumns.map((groupName: any, idx: number) => {
                return (
                  <StyledTableCell
                    key={groupName + idx}
                    align="left"
                  ></StyledTableCell>
                );
              })}
              {columns.map((column: any) => {
                const value: any = row[column.id];
                if ((column.id === "agentAction") && (row.AgentName !== "" || row.AgentID !== "")) {
                  return (
                    <StyledTableCell
                      key={column.id + rowIdx}
                      align={column.align}
                    >
                      <span className="icon-row">
                        {/* <span className="icon-col-sm">
                          <IconButton disableRipple onClick={(event) => agentDetails(event, row)}>
                            <img src={`${process.env.PUBLIC_URL}/icons/table-info-icon.svg`} width="20" alt="Info"></img>
                          </IconButton>
                        </span> */}
                        <span className="icon-col-sm">
                          <IconButton
                            disableRipple
                            onClick={(event) => agentEvents(event, row)}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons/table-graph-icon.svg`}
                              width="20"
                              alt="Graph"
                            ></img>
                          </IconButton>
                        </span>
                      </span>
                    </StyledTableCell>
                  );
                } else if (column.id === "Action") {
                  return (
                    <StyledTableCell key={column.id + rowIdx} align={"center"}>
                      <span className="icon-row">
                        <span className="icon-col">
                          <IconButton
                            disableRipple
                            onClick={() => agentClick("Tanscript", row)}
                            aria-label="delete"
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons/transcript.svg`}
                              width="20"
                              alt="Tanscript"
                            ></img>
                          </IconButton>
                        </span>
                        <span className="icon-col">
                          <IconButton
                            disableRipple
                            onClick={() => agentClick("EndChat", row)}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons/end-chat.svg`}
                              width="20"
                              alt="EndChat"
                            ></img>
                          </IconButton>
                        </span>
                      </span>
                    </StyledTableCell>
                  );
                } else if (column.id === "Details") {
                  return (
                    <StyledTableCell
                      key={column.id + rowIdx}
                      align={"center"}
                      width={column.width}
                    >
                      <DetailsIconButton
                        color="primary"
                        onClick={() => agentDetailsDrawer(row)}
                      >
                        <ArrowRightAltIcon sx={{ fontSize: "20px" }} />
                      </DetailsIconButton>
                    </StyledTableCell>
                  );
                } else {
                  return (
                    <StyledTableCell
                      key={column.id + rowIdx}
                      align={column.align}
                      width={column.width}
                      sx={{
                        maxWidth: column.width,
                      }}
                      className={`${row[column.id]?.warning || ""}`}
                    >
                      {(column.type === "MIN_SEC" &&
                        secondsToMinSec(
                          row[column.id]?.value || row[column.id]
                        )) ||
                        (column.type === "TIME" &&
                          secondsToTime(
                            row[column.id]?.value || row[column.id]
                          )) ||
                        value}
                    </StyledTableCell>
                  );
                }
              })}
            </StyledTableRow>
          );
        })}
      </>
    );
  };

  function getColor(numOfSteps: number = 1, step: number = 0) {
    // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
    // Adam Cole, 2011-Sept-14
    // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
    var r = 0,
      g = 0,
      b = 0;
    var h = step / numOfSteps;
    var i = ~~(h * 6);
    var f = h * 6 - i;
    var q = 1 - f;
    switch (i % 6) {
      case 0:
        r = 1;
        g = f;
        b = 0;
        break;
      case 1:
        r = q;
        g = 1;
        b = 0;
        break;
      case 2:
        r = 0;
        g = 1;
        b = f;
        break;
      case 3:
        r = 0;
        g = q;
        b = 1;
        break;
      case 4:
        r = f;
        g = 0;
        b = 1;
        break;
      case 5:
        r = 1;
        g = 0;
        b = q;
        break;
    }
    var c =
      "#" +
      ("00" + (~~(r * 255)).toString(16)).slice(-2) +
      ("00" + (~~(g * 255)).toString(16)).slice(-2) +
      ("00" + (~~(b * 255)).toString(16)).slice(-2);
    return c;
  }

  const getRows: any = (data: any, index: number) => {
    if (!Array.isArray(data)) {
      return Object.entries(data).map(([groupName, groupRowData]) => {
        const extraCells = [];

        for (let i = 0; i < index; i++) {
          extraCells.push(<TableCell key={i}></TableCell>);
        }

        return (
          <>
            <TableRow
              className="rowsGroupWrapper"
              sx={{
                // border: '2px solid #badaf2',
                // display: 'inline-block',
                width: "100%",
                height: "22px",
                backgroundColor: getColor(groupingColumns.length, index) + "4d",
                // minHeight: '1rem',
                // paddingTop: '1rem',
              }}
            >
              {extraCells}
              <TableCell
                colSpan={columns.length + groupingColumns.length}
                sx={{ padding: "5px 16px", background: "transparent" }}
              >
                {groupName}
              </TableCell>
            </TableRow>
            {getRows(groupRowData, index + 1)}
          </>
        );
      });
    } else {
      return <TableBody>{getTableRows(data)}</TableBody>;
    }
  };

  const getGroupedRows = () => {
    if (groupingColumns.length) {
      return getRows(groupedData, 0);
    } else {
      return <TableBody>{getTableRows(filteredRowData)}</TableBody>;
    }
  };

  const handleOnSearch = (event: any) => {
    setSearchText(
      event.target.value.length ? event.target.value.toLowerCase() : ""
    );
    if (event?.target?.value?.length) {
      const searchText = event.target.value.toLowerCase();
      const filteredRows = rowData.filter((item: any) =>
        item.AgentName.toLowerCase().includes(searchText)
      );
      setFilteredRowData(filteredRows);
    } else {
      setFilteredRowData(rowData);
    }
  };
  const toggleThresholdDrawer = (isOpen: boolean, data: any = null) => {
    setIsThresholdDrawerOpen(isOpen);
    if (data) {
      setThresholdItems(data);
    }
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  const handleRelease = async () => {
    await releaseChat({ sessionID: endChatDetails.SessionID });
    setOpenAlert(false);
  };

  // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  //   setCallType(newValue);
  // };

  function getRowData(row: any) {
    row = row?.map((item: any) => {
      let rowObj = {
        ...item,
        Duration: secondsToTime(item?.Duration),
      };
      return rowObj;
    });
    return row;
  }

  return (
    <Card className="customCard">
      <div className="card-header">
        <div className="card-title">
          Digital Status
          <span className="card-title-badge">Live</span>
          {/* <div className="card-title-badge">Live</div> */}
        </div>
        <div className="card-actions">
          <WidgetSearch onTextChange={handleOnSearch}></WidgetSearch>
          <WidgetDownload
            element={downloadContent}
            handleDownload={handleDownload}
            name={'Digital Status'}
            xlsx
            rowData={getRowData(rowData)}
            columns={downloadColumns}
          ></WidgetDownload>
          <div className="card-icon" onClick={() => toggleDrawer(true)}>
            <Badge badgeContent={agentListFilter.length} color="primary">
              <FilterAltOutlinedIcon
                fontSize="small"
                style={{ color: "#536580" }}
              />
            </Badge>
            {/* <FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} /> */}
          </div>
          {/* <div onClick={() => toggleThresholdDrawer(true, null)}>
            <WidgetGraph></WidgetGraph>
          </div> */}
        </div>
      </div>

      {/* <Box ref={downloadContent} sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={callType}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} className="customTabs">
                <Tab label="All" value="all" /> 
                <Tab label="Agent" value="agent" />
                <Tab label="User" value="user" />
              </TabList>
            </Box>
          </TabContext>
        </Box> */}

      {/* <TranscriptDrawer
        isOpen={isTranscriptDrawerOpen}
        toggleDrawer={toggleTranscriptDrawer}
        applyFilters={handleApplyFilters}
        existingSelectedFilters={agentListFilter}
        data={transcriptDrawerDetails}
      /> */}
      <ChatHistoryDrawer isChatOpen={isTranscriptDrawerOpen} toggleChatDrawer={toggleTranscriptDrawer} chatData={transcriptDrawerDetails} chatDigital />

      <FilterDrawer
        isOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        applyFilters={handleApplyFilters}
        existingSelectedFilters={agentListFilter}
        additionalApiParams={{ campaignType: 'Chat' }}
        setResponseLength={setresponseLength}
        allowedFilters={allowedFilters}
        existingFilterCategory={filterType} 
        applyFilterOnExistingData={handleFilterExistingData}
      />

{/* <FilterDrawer
        isOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        applyFilters={handleApplyFilters}
        existingSelectedFilters={agentListFilter}
        additionalApiParams={{ campaignType: 'Chat' }}
        setResponseLength={setresponseLength}
        existingFilterCategory={filterType} 
        applyFilterOnExistingData={handleFilterExistingData}
      /> */}

      <Box sx={{ width: "100%", typography: "body1" }}>
        {/* <Box sx={{ pt: 2, pl: 2 }}> */}
        {agentListFilter.length > 0 && (
          <Box className="customTabPanel">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="filter-outer">
                  <div className="filter-container">
                    <Stack direction="row" spacing={1}>
                      {filterItems}
                    </Stack>
                  </div>
                  <div className="filter-remove" onClick={clearFilter}>
                    Remove
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      {/* <div className="search-drag-drop" onDrop={handleOnDrop} onDragOver={(e) => e.preventDefault()}>
        {groupingColumns.length > 0 && (
          <Box className={classes.dragdropItemsWrapper}>
            <div className="filter-outer">
              <div className="filter-container">
                <Stack direction="row" spacing={1}>
                  {groupingColumns.map((item: any, index: number) => (
                    <Chip
                      key={index}
                      label={item.label}
                      color="primary"
                      variant="outlined"
                      onDelete={() => {
                        setGroupingColumns((prev: any) => prev.filter((_: any) => _.id !== item.id));
                      }}
                      deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />}
                    />
                  ))}
                </Stack>
              </div>
              <div className="filter-remove" onClick={() => setGroupingColumns([])}>
                Remove
              </div>
            </div>
          </Box>
        )} */}
      {/* <Typography
          component={'span'}
          color="#99A0A8"
          position="absolute"
          // top={'50'}
          // left={'50'}
          fontSize={'12px'}
        > */}
      {/* {groupingColumns.length < 3 && 'Drag and drop the metrics for grouping '} */}
      {/* <span>
            (Currently you don’t have any metrics which can be grouped)
          </span> */}
      {/* </Typography> */}
      {/* </div> */}
      <div className="card-table-container">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer
            className="custom-table-scroll"
            sx={{ maxHeight: 300 }}
            ref={downloadContent}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {groupingColumns.map((groupName: any, idx: number) => {
                    return (
                      <StyledTableCell
                        key={groupName + idx}
                        align="left"
                      ></StyledTableCell>
                    );
                  })}
                  {columns.map((column: any, idx: any) => {
                    if (column.id === "Action") {
                      return (
                        <StyledTableCell
                          key={column.id + idx}
                          align="center"
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                          <span className="icon-row">
                            <span className="icon-col">Transcript</span>
                            <span className="icon-col">EndChat</span>
                          </span>
                        </StyledTableCell>
                      );
                    } else if (column.draggable) {
                      return (
                        <StyledTableCell
                          key={column.id + idx}
                          className="draggable-table-cell"
                          align={"left"}
                          draggable={true}
                          sx={{
                            width: column.width,
                            whiteSpace: "nowrap",
                            maxWidth: column.width,
                          }}
                          onDragStart={(event) =>
                            handleDragStart(event, {
                              label: column.label,
                              id: column.id,
                            })
                          }
                        >
                          <Typography display={"flex"} alignItems={"center"}>
                            <span style={{ padding: "0 0.2rem" }}>
                              <DragIcon />
                            </span>
                            {column.label}
                          </Typography>
                        </StyledTableCell>
                      );
                    } else {
                      return (
                        <StyledTableCell
                          key={column.id + idx}
                          align={column.align}
                          style={{
                            width: column.width,
                            whiteSpace: "nowrap",
                            maxWidth: "10vw",
                          }}
                        >
                          {column.label}
                        </StyledTableCell>
                      );
                    }
                  })}
                </TableRow>
              </TableHead>
              {/* <TableBody> */}
              {getGroupedRows()}
              {/* </TableBody> */}
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <Popover
        open={isPopoverOpen}
        onClose={handleOnPopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={eleRefPoint}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        {getPopoverContent()}
      </Popover>
      <Popover
        open={isEventsPopoverOpen}
        onClose={handleOnEventsPopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={eleRefPoint}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        {getEventsPopoverContent()}
      </Popover>
      <BargeInDrawer
        isOpen={isBargeInOpen}
        toggleDrawer={toggleBargeInDrawer}
        agentDetails={selectedAgent}
      />
      <AgentDetailsDrawer
        isOpen={isAgentDetailsDrawerOpen}
        toggleDrawer={toggleAgentDetailsDrawer}
        selectedAgent={selectedAgent}
        agentDetails={drawerDetails}
      />
      {/* <ThresholdDrawer
        isOpen={isThresholdDrawerOpen}
        title={"Set"}
        toggleDrawer={toggleThresholdDrawer}
        thresholdItems={thresholdItems}
      /> */}
      <Dialog open={openAlert} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'End Chat'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{`Are you sure you want to end this chat ${endChatDetails?.SessionID}`}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="buttons-container">
            <Button variant="outlined" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleRelease} sx={{ ml: 2 }}>
              End Chat
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </Card>
  );
}