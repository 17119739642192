import { TextField, Divider } from '@mui/material';
import React from 'react';


type CommentPros = {
    defaultValue?: any;
    handleValueInComment?: any;
    row?: any;
    name?: any | string | undefined;
}

const OZRatingCommentNew = ({defaultValue, handleValueInComment, row, name}: CommentPros) => {

    const [newValue, setNewValue] = React.useState<any>(defaultValue);
    const [onClickNew, setOnClickNew] = React.useState<any>(false);
    // const [newValue, setNewValue] = React.useState<any>('');

    const changeEvent = (event: any) => {
        setNewValue(event);
    }

    return (
        <>
            {name !== 'LiveReport' && name !== 'VoiceMailReport' ?
                <div>{(defaultValue) ? defaultValue : ''}</div>
                : (row?.status !== "Answered" && row?.status !== "answered" ) && (row?.Status !== "Answered" && row?.Status !== "answered" )  ?
                    <>
                    </>    
                : 
                <>
                    {/* <input
                        type='text'
                        autoFocus
                        defaultValue={defaultValue}
                        // onChange={(event) => {handleValueInComment(event?.target?.value, row); console.log("event here in comment", event?.target?.value)}}
                        onChange={(event) => {chaneEvent(event?.target?.value); console.log("event here in comment", event?.target?.value)}}
                    />
                    <img
                        style={{ marginTop: '2px', cursor:'pointer', height: '13px', width: '13px'}}
                        src={`${process.env.PUBLIC_URL}/icons/checkbox-tick-icon.svg`}
                        alt="tick"
                        onClick={(e) => handleValueInComment(newValue, row)}
                    >
                    </img> */}
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                backgroundColor: '#ffff',
                                fontSize: '12px',
                                height: '30px',
                                width: '226px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #D6D6D7'
                            },
                            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                                backgroundColor: '#D6D6D7',
                                border: '1px solid #D6D6D7'
                            }
                        }}
                        type='text'
                        onChange={(event) => {changeEvent(event?.target?.value); console.log("event here in comment", event?.target?.value)}}
                        value={newValue}
                        id={name}
                        InputProps={{
                            endAdornment: (
                                < div style={{ display: 'flex', alignContent: 'center', backgroundColor: '#FFFFFF', borderRadius: '8px' }}
                                >
                                    <Divider sx={{ color: '#99A0A8', height: '29px' }} orientation="vertical" />
                                    {onClickNew ? (
                                        <img
                                            style={{ paddingLeft: '10px', paddingBottom: '2px', cursor: 'pointer' }}
                                            src={`${process.env.PUBLIC_URL}/icons/checkbox_after_green.svg`}
                                            alt="newImage"
                                            onClick={(e) => {
                                                setTimeout(() => {
                                                    setOnClickNew(false);
                                                }, 1000);
                                            }}
                                        />
                                    ) : (
                                        <img
                                            style={{ paddingLeft: '10px', paddingBottom: '2px', cursor: 'pointer' }}
                                            src={`${process.env.PUBLIC_URL}/icons/checkbox-tick-icon.svg`}
                                            alt="tick"
                                            onClick={(e) => {
                                                handleValueInComment(newValue, row);
                                                setOnClickNew(true);
                                                setTimeout(() => {
                                                    setOnClickNew(false);
                                                }, 1000);
                                            }}
                                        />
                                    )}
                                </div>
                            )
                        }}
                    />
                </>
            }
         </>
    )
};

export default OZRatingCommentNew;