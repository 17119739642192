import React, { useMemo, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { connect, getIn } from "formik";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Popper from "@mui/material/Popper";
import Avatar from '@mui/material/Avatar';
import "../../../../pages/admin/admin.css";
import { Chip, FormHelperText, FormControl, Box, Typography, Tooltip } from "@mui/material";
import CustomizedCheckbox from "../CustomCheckBox";
import { stringAvatar } from "../Avatar";

const Autocompletes = connect(
  ({
    Datas,
    PlaceHolder,
    formik,
    name,
    optionLabelName,
    optionId,
    mode,
    ...additionalprops
  }) => {

    const listRef = useRef(null);
    const [limit, setLimit] = useState(10);

    const handleScroll = () => {
      const l = limit;
      setLimit(l + 5);
    };

    const handleClose = () => {
      setLimit(10); // Reset the limit to 10 when the dropdown list closes
    };

    const PopperMy = useMemo(
      () => (props) => {
        return (
          <Popper {...props} sx={{
            paddingTop: "8px", paddingBottom: "8px", color: "#212121",
            "& .MuiAutocomplete-listbox": {
              "& li": {
                paddingLeft: mode === "Edit" ? "0px" : "8px",
                maxWidth: "100% !important",
                wordBreak: "break-all !important",
                whiteSpace: "normal !important",
                textOverflow: "inherit !important"
              },
              "& .MuiAutocomplete-option[aria-selected='true']": {
                backgroundColor: "transparent !important",
              },
            },
            '& .MuiAutocomplete-paper': {
              boxShadow: 'none',
              border: "1px solid #E5E5E5",
              borderRadius: mode === "Edit" ? '0px' : "8px",
              borderBottom: mode === "Edit" ? '0px' : "1px solid #E5E5E5",
              borderRight: mode === "Edit" ? '0px' : "1px solid #E5E5E5",
              borderLeft: mode === "Edit" ? '0px' : "1px solid #E5E5E5",
              borderTop: mode === "Edit" ? "0px" : "1px solid #E5E5E5",
              height: mode === "Edit" ? "290px" : "",
            }, "& .MuiAutocomplete-noOptions": {
              fontFamily: 'Roboto',
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#212121"
            }
          }} />
        );
      },
      [],
    );

    const selected = getIn(formik?.values, name);
    function getSelected() { return selected };

    const selectAll = selected?.length === Datas?.length;
    const error = getIn(formik.errors, name);
    const [inputValue, setInputValue] = useState("");
    const [oldArray, setOldArray] = useState((selected?.length > 0) ? selected : []);

    const getTouchedStatus = () => {
      let touched = getIn(formik.touched, name);
      if (touched) {
        return touched.length === 0
      } else {
        return touched
      }
    }

    const makeNewArray = (oldData: any) => {
      if (oldArray === null || oldArray.length < 1) {
        // console.log("data here ", oldData);
        return oldData;
      } else {
        const newnew = oldArray.concat(oldData);
        const uniqueIds = [];
        const result = newnew.filter(obj => {
          if (!uniqueIds.includes(obj.id)) {
            uniqueIds.push(obj.id);
            return true;
          }
          return false;
        })
        return result;
      }

    }

    const touch = getTouchedStatus();

    return (
      <>
        {mode === "Edit" &&
          <Box sx={{
            border: 1,
            borderRadius: "8px",
            paddingTop: "8px",
            paddingLeft: "15px",
            paddingBottom: "8px",
            borderColor: "#D6D6D7",
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            marginTop: "-30px",
          }}
          >
            <label
              sx={{
                color: "#212121",
                fontWeight: 400,
                fontSize: "14px",
                marginLeft: "10px",
              }}
            >
              {selected.length} Selected...
            </label>
          </Box>
        }
        <Box
          sx={(mode === "Edit") ? {
            border: 1,
            borderRadius: "8px",
            paddingTop: "15px",
            paddingLeft: "15px",
            paddingBottom: "305px",
            borderColor: "#D6D6D7",
            borderTop: 0,
            paddingRight: "15px",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          } : {}}
        >
          <Autocomplete
            sx={{
              "& .css-5dy5t1-MuiInputBase-root-MuiOutlinedInput-root": {
                borderRadius: "8px",
                minHeight: "44px",
                fontSize: "14px",
                borderColor: "#E6E5E6"
              },
              '& 	.MuiAutocomplete-listbox': {
                scrollbarColor: 'rgb(190, 190, 190) rgb(240, 240, 240)',
                scrollbarWidth: 'thin',
                borderRadius: "8px"
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: "8px",
                minHeight: "44px",
                fontSize: "14px",
                borderColor: "#E6E5E6"
              }
            }}
            className="ozcw-scrollbar"
            noOptionsText={"No matching result found!"}
            multiple={true}
            size="small"
            id={name}
            options={Datas}
            value={selected}

            filterOptions={(options, params) => {
              let filtered = createFilterOptions({
                matchFrom: 'any',
                limit: limit,
              })(options, params);

              let selectedFiltered = createFilterOptions({//for the selected list also to be filtered
                matchFrom: 'any',
                limit: limit,
              })((selected || []), params);

              let sortedOptions = filtered
              let uniqueOptions = filtered
              if (selected?.length > 0) {
                // sortedOptions = [...selected, ...filtered]
                sortedOptions = [...selectedFiltered, ...filtered]//for the selected list also to be filtered
                uniqueOptions = [...new Map(sortedOptions.map((item) => [item["id"], item])).values()];
              }
              if (filtered.length > 0) {
                filtered = [{ id: 0, [optionLabelName]: "Select All" }, ...uniqueOptions]
              }
              return filtered;
            }}

            autoComplete={true}
            renderTags={(value) => {
              return (
                <div style={{
                  display: "flex",
                  alignItems: 'center',
                  alignContent: "center",
                }}>
                  <Typography component={'div'}>
                    {selected.map(
                      (selectedvalues, index) =>
                        (index < 3 && (<Chip key={`${selectedvalues[optionLabelName] + index}`} label={selectedvalues[optionLabelName]} size="small" className="oz-customAutoComplete-chip-style" />))
                    )}
                  </Typography>
                  <Typography sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#212121",
                    paddingRight: '5px',
                    paddingLeft: "5px"
                  }}>
                    {(selected.length > 3) ? "..." : ""}
                  </Typography>
                  <Typography sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#99A0A8",
                  }}>
                    {(selected.length > 3) ? (("+" + (selected.length - 3))) : ""}
                  </Typography>
                </div>
              )
            }}

            onChange={(e, value) => {
              if (value.find((option) => option.id === 0)) {
                const filterInputDatas = Datas.filter((value) => value[optionLabelName].includes(inputValue));
                const newArray = makeNewArray(filterInputDatas);
                setOldArray(newArray);

                if (selected.length === newArray.length) {
                  formik.setFieldValue(name, []);
                  setOldArray([]);
                } else {
                  formik.setFieldValue(name, newArray);
                }

                // if (selected.length === Datas.length) {
                //   formik.setFieldValue(name, []);
                // } else {
                //   formik.setFieldValue(name, Datas);
                // }
              } else {
                if (selected.length === Datas.length) {
                } else {
                }
                formik.setFieldValue(name, value);
              }
            }}

            disableCloseOnSelect={true}

            getOptionLabel={
              ((options) => options[optionLabelName])
            }

            PopperComponent={PopperMy}
            isOptionEqualToValue={(options, value) => options[optionId || optionLabelName] === value[optionId || optionLabelName]}

            ListboxProps={{
              ref: listRef,
              onScroll: handleScroll,
              sx: {
                maxHeight: "280px",
              }
            }}

            onClose={handleClose}

            onInputChange={(e, v) => {
              setInputValue(v);
            }}

            renderOption={(props, options, { selected }) => (
              <Tooltip title={options[optionLabelName]}>
                <div key={options[optionLabelName] + options.id} >
                  <li {...props} key={options[optionLabelName] + options.id}>
                    <div style={{ alignItems: "center" }} key={options[optionLabelName]}>
                      <CustomizedCheckbox
                        sx={{ paddingLeft: mode === "Edit" ? "2px" : "" }}
                        checked={(options.id === 0) ? selectAll : selected}
                      />
                    </div>
                    {
                      <>
                        {options?.id !== 0 && <Avatar style={{ width: "24px", height: "24px", fontSize: "14px", color: "#212121", marginLeft: "4px" }} {...stringAvatar(options[optionLabelName])}></Avatar>}
                        <span style={{ marginLeft: "8px", color: "#212121", fontWeight: 400, fontSize: "14px", lineHeight: "16.41px", }}>{options[optionLabelName]}</span>
                      </>
                    }
                  </li>
                  {getSelected()?.length > 0 && getSelected()?.length < Datas?.length && props["data-option-index"] === getSelected()?.length &&
                    <div style={{
                      border: "1px solid #E6E5E6",
                      height: "5px !important",
                      marginLeft: "5px"
                    }}>
                    </div>
                  }
                </div>
              </Tooltip>
            )}

            renderInput={(params) => (
              <div>
                <FormControl component="fieldset"
                  error={touch & Boolean(error) ? true : false}
                  fullWidth
                >
                  <TextField error={touch & Boolean(error) ? true : false} {...params} placeholder={PlaceHolder} />
                  <FormHelperText>{touch & Boolean(error) ? error : null}</FormHelperText>
                </FormControl>
              </div>
            )}
          />
        </Box>
      </>
    );
  }
);

export default Autocompletes;