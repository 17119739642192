import React, { useState, useMemo, useEffect } from "react";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { Button, Stack, Grid } from "@mui/material";
import "./SIPLocation.css";
import { Typography } from "@mui/material";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import { showErrorNotification } from "../../../components/admin/common/NotiService";
import { getsipLocation, getsipLocationById } from "../../../services/api-service";
import SIPLocationForm from "./SIPLocationForm";
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";

function SIPLocation() {

    const [sipLocationForm, setSipLocationForm] = useState({ isNew: false, show: false });
    const [sipLocationList, setSipLocationList] = useState([]);
    const [sipLocationItem, setSipLocationItem] = useState({});
    const [gridRef, setGridRef] = useState(null);
    const [loading, setLoading] = useState(false);

    const showSipLocationForm = () => {
        setSipLocationForm({ isNew: true, show: true });
    };

    const closeSipLocationForm = () => {
        setSipLocationForm({ isNew: false, show: false });
    };

    const SipUrlRenderer = (props) => {
        return (
            <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{props.value}</span>
        );
    };

    let buttonStyle = useMemo(
        () => ({
            borderRadius: "8px",
            height: "30px",
            fontSize: "14px",
            fontWeight: 400,
            width: "181px",
            backgroundcolor: "#3D8BF8",
            lineHeight: "16.41px",
        }),
        []
    );

    useEffect(() => {
        getSipLocationList();
    }, []);

    async function getSipLocationList() {
        setLoading(true);
        try {
            getsipLocation()
                .then((resp) => {
                    setSipLocationList(resp.Data);
                    setLoading(false);
                })
                .catch((e) => {
                    setSipLocationList([]);
                    setLoading(false);
                });
        } catch (e) {
            showErrorNotification("Error in sipLocation list");
            setLoading(false);
        }
    };

    async function onClickRow(rowItem) {
        setSipLocationItem({});
        setSipLocationForm({ isNew: false, show: true });
        setLoading(true);
        try {
            getsipLocationById(rowItem.data.id)
                .then((resp) => {
                    setSipLocationItem(resp.Data[0]);
                    setLoading(false);
                })
                .catch((e) => {
                    setSipLocationItem({});
                    setLoading(false);
                });
        } catch (e) {
            showErrorNotification("Error in sipLocation list ");
            setLoading(false);
        }
    };

    const columnDefs = [
        {
            field: "location",
            headerName: "Name",
        },
        {
            field: "sipUrl",
            headerName: "SIP Server Url",
            cellRenderer: SipUrlRenderer,
        },
    ];

    return (
        <div className="ozAdminSipLocationContainer">
            <Grid sx={{ marginBottom: "10px" }}>
                <Typography className="oz-page-header"> Configurations </Typography>
            </Grid>
            <Stack direction="row" sx={{ marginBottom: "16px" }} justifyContent="space-between">
                <Typography className="oz-page-heading"> SIP Locations </Typography>
                {/* {authPage(AdminPages.SIPLOCATIONS_VIEW) &&
                    <Button
                        sx={buttonStyle}
                        variant="contained"
                        startIcon={<AddBoxOutlinedIcon />}
                        onClick={showSipLocationForm}
                    >
                        New SIP Location
                    </Button>
                } */}
                {(newAuthPages(NewAdminPages.SIP_LOCATION) || newAuthSubAdminAccess(NewAdminPages.SIP_LOCATION, 'Full')) &&
                    <Button
                        sx={buttonStyle}
                        variant="contained"
                        startIcon={<AddBoxOutlinedIcon />}
                        onClick={showSipLocationForm}
                    >
                        New SIP Location
                    </Button>
                }
            </Stack>
            {sipLocationForm.show && sipLocationForm.isNew && (<SIPLocationForm onClose={closeSipLocationForm} SIPLocation={getSipLocationList} />)}
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                (
                    <>
                        {sipLocationForm.show && !sipLocationForm.isNew && (<SIPLocationForm data={sipLocationItem || {}} onClose={closeSipLocationForm} SIPLocation={getSipLocationList} editAccess={(newAuthPages(NewAdminPages.SIP_LOCATION) || newAuthSubAdminAccess(NewAdminPages.SIP_LOCATION, 'Full') || newAuthSubAdminAccess(NewAdminPages.SIP_LOCATION, 'Edit'))} />)}
                    </>
                )
            }
            <OZGridToolbar title='List of SIP Locations' rowCount={sipLocationList.length} searchPlaceholder='SIP Location name' dataGridRef={gridRef} fileName='SIP Locations' />
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                (<>
                    <OZDataGrid height="26.3rem" data={sipLocationList} columns={columnDefs} setGridRef={setGridRef} rowClickCallback={onClickRow} />
                </>
                )
            }
        </div>
    );
};

export default SIPLocation;