import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Badge, Chip, Grid, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { getActiveCalls, getCallStatusData, getWidgetConfig } from '../../../services/api-service';
import { filterTypes, WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import '../CallStatus/CallStatus.css';
import { checkDataAccessFilters, checkRoles, determineFilterType, secondsToMinSec } from '../../../utils/util';
import CallStatusProgressBar from './CallStatusProgressBar';

let interval: any;

export default function CallStatus(props: any) {
	const { useState, useRef } = React;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [callType, setCallType] = React.useState('all');
	const [responseLength, setresponseLength] = useState<Number>(0);
	const downloadContent = useRef<null | any>(null);
	const [callStatus, setCallStatus] = useState<any>({});
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [allowedFilters, setAllowedFilters] = useState<string[]>(['Campaign', 'Skill', 'Group']);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || 1);
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [abandonedCallsSeparate, setAbandonedCallsSeparate] = useState<any>({outbound: 0, inbound: 0});
	const [answeredCallsSeparate, setAnsweredCallsSeparate] = useState<any>({outbound: 0, inbound: 0});
	const [totalCallsSeparate, setTotalCallsSeparate] = useState<any>({outbound: 0, inbound: 0});

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setCallType(newValue);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = callStatusFilter.filter((item: any) => item.id !== id);
		setCallStatusFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId)
	};

	const clearFilter = () => {
		console.info('Clear all filter.');
		setCallStatusFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId)
	};

	const [callStatusFilter, setCallStatusFilter] = useState<any>(props?.selectedFilters?.filters || []);

	const filterItems = callStatusFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const getCallStatus = async (filters = '0') => {
		const data: any = await getCallStatusData({ filters, type: filterType, callType, dateTypeId: '0' });
		const filterKey = filterTypes.find((item: any) => item.id === +filterType) ?? filterTypes[0];
		const filterValueKey = `${filterKey.value}Name`;
		const totalValueData = data.find((item: any) => item[filterValueKey] === '0');
		setTotalCallsSeparate({outbound: parseInt(totalValueData?.TotalOutboundCalls || 0) || 0, inbound: parseInt(totalValueData?.TotalInboundCalls || 0) || 0});
		setAnsweredCallsSeparate({outbound: parseInt(totalValueData?.TotalOutboundAnsweredCalls || 0) || 0, inbound: parseInt(totalValueData?.TotalInboundAnsweredCalls || 0) || 0});
		setAbandonedCallsSeparate({outbound: parseInt(totalValueData?.TotalOutboundUnAnsweredCalls || 0) || 0, inbound: parseInt(totalValueData?.TotalInboundUnAnsweredCalls || 0) || 0});
		if (callStatusFilter.length <= 2) {
			const historicData = await getCallStatusData({ filters, type: filterType, callType, dateTypeId: '1' });
			const totalHistoricValueData = historicData.find((item: any) => item[filterValueKey] === '0');
			if (totalValueData) {
				totalValueData.averageHandlingTimeTicker = +totalHistoricValueData?.AverageHandlingTime
					? +(((+totalValueData.AverageHandlingTime - +totalHistoricValueData.AverageHandlingTime) / +totalHistoricValueData.AverageHandlingTime) * 100).toFixed(2)
					: 0;
				totalValueData.averageAnswerTimeTicker = +totalHistoricValueData?.AverageAnswerTime
					? +(((+totalValueData.AverageAnswerTime - +totalHistoricValueData.AverageAnswerTime) / +totalHistoricValueData.AverageAnswerTime) * 100).toFixed(2)
					: 0;
				const activeCalls = await getActiveCalls();
				totalValueData.activeCalls = activeCalls.ActiveCallsInIVR;
			}
			setCallStatus(totalValueData ?? {});
		} else {
			const columns = [
				{ name: filterKey.label, key: filterValueKey },
				{ name: 'AHT', key: 'AverageHandlingTime', subTitle: totalValueData.AverageHandlingTime },
				{ name: 'ATT', key: 'AverageTalkTime', subTitle: totalValueData.AverageTalkTime },
				{ name: 'AWT', key: 'AverageWrapupTime', subTitle: totalValueData.AverageWrapupTime },
				{ name: 'Avg. Hold Time', key: 'AverageHoldTime', subTitle: totalValueData.AverageHoldTime },
				{ name: 'Avg. Answer Time', key: 'AverageAnswerTime', subTitle: totalValueData.AverageAnswerTime },
				{ name: 'Active Calls', key: 'TotalCalls', subTitle: totalValueData.TotalCalls },
				{ name: 'Total Answered', key: 'AnsweredCalls', subTitle: totalValueData.AnsweredCalls },
				{ name: 'Total Abandoned', key: 'UnansweredCalls', subTitle: totalValueData.UnansweredCalls }
			];
			const actualData = data.filter((item: any) => item[filterValueKey] !== '0');
			setRowData(actualData);
			setColumnData(columns);
		}
	};
	
	const handleFilterExistingData = (filteredData: any) => {
		setCallStatusFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setCallStatusFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId)
		toggleDrawer(false);
	};

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(()	=> {
		const fetchWidgetConfig = async () => {
			try {
				if(checkDataAccessFilters()) {
					let widgetConfig = await getWidgetConfig('105', 'widget');
					if (widgetConfig) {
						if (typeof widgetConfig === 'string') {
							widgetConfig = JSON.parse(widgetConfig);
						}
						// Check the condition and set the appropriate filters
						const filters = widgetConfig.data && checkDataAccessFilters()
										? widgetConfig.data
										: widgetConfig.config;
		
						if (filters) {
							const filtersArray = filters.split(',');
							setAllowedFilters(filtersArray);
							const determinedFilterType = determineFilterType(filtersArray);
							if (determinedFilterType !== undefined) {
								setFilterType(determinedFilterType);
							}
						}
					}
				}
			} catch (error) {
				console.error('Error fetching widget config:', error);
			}
		};
	fetchWidgetConfig();
}, []);

	React.useEffect(() => {
		// const filterIds = (agentListFilter.length && !(responseLength === agentListFilter.length)) ? agentListFilter.map((item: any) => item.id).join(',') : '0';
		const filterIds = (callStatusFilter.length && !(responseLength === callStatusFilter.length)) ? callStatusFilter.map((item: any) => item.id).join(',') : '0';
		getCallStatus(filterIds);
		if (interval) {
			clearInterval(interval);
		}

		interval = setInterval(() => getCallStatus(filterIds), WIDGET_REFRESH_INTERVAL.CALL_STATUS);
	}, [callStatusFilter, callType]);

	return (
		<Card className="customCard">
			<div className="card-header border-none">
				<div className="card-title">
					Call Status <div className="card-title-badge">Live</div>
				</div>
				<div className="card-actions">
					<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={'Call Status'}></WidgetDownload>
					<div className="card-icon" onClick={() => toggleDrawer(true)}>
						<Badge badgeContent={callStatusFilter.length} color="primary">
							<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</Badge>
					</div>
				</div>
			</div>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={callStatusFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/>

			<Box ref={downloadContent} sx={{ width: '100%', typography: 'body1' }}>
				<TabContext value={callType}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} className="customTabs">
							<Tab label="All" value="all" />
							<Tab label="Voice" value="voice" />
							<Tab label="Manual" value="manual" />
							<Tab label="Preview" value="preview" />
							<Tab label="Progressive" value="progressive" />
						</TabList>
					</Box>
					<div className="customTabPanel">
						{!checkRoles("ROLE_DATA_ACCESS") &&
							<Grid container spacing={2}>
								{callStatusFilter.length > 0 && (
									<Grid item xs={12}>
										<div className="filter-outer">
											<div className="filter-container">
												<Stack direction="row" spacing={1}>
													{filterItems}
												</Stack>
											</div>
											<div className="filter-remove" onClick={clearFilter}>
												Remove
											</div>
										</div>
									</Grid>
								)}
								{callStatusFilter.length > 2 ? (
									<OZTable rows={rowData} columns={columnData}></OZTable>
								) : (
									<>
										<Grid item xs={12}>
											<div className="widget-inner-card">
												<Grid container direction="row" justifyContent="space-between" alignItems="center">
													<Grid item xs={5}>
														<div className="widget-values-container">
															<div className="widget-caption">Average Handling Time</div>
															<div className="widget-values">
																<div className="widget-value">{secondsToMinSec(callStatus.AverageHandlingTime) || '-'}</div>
																<div className={`widget-value-update ${callStatus.averageHandlingTimeTicker < 0 ? '' : 'red'}`}>
																	{callStatus.averageHandlingTimeTicker < 0 ? (
																		<img src={`${process.env.PUBLIC_URL}/icons/down-green-arrow.svg`} width="11" alt="Average Handling Time"></img>
																	) : (
																		<img src={`${process.env.PUBLIC_URL}/icons/up-red-arrow.svg`} width="11" alt="Average Handling Time"></img>
																	)}{' '}
																	{`${callStatus.averageHandlingTimeTicker || 0}%`}
																</div>
															</div>
														</div>
													</Grid>
													<Grid item xs={7}>
														<Grid container spacing={2}>
															<Grid item xs={6}>
																<div className="widget-values-container widget-values-sm">
																	<div className="widget-caption">Avg. Talk Time</div>
																	<div className="widget-values">
																		<div className="widget-value">{secondsToMinSec(callStatus.AverageTalkTime) || '-'}</div>
																	</div>
																</div>
															</Grid>
															<Grid item xs={6}>
																<div className="widget-values-container widget-values-sm">
																	<div className="widget-caption">Avg. Hold Time</div>
																	<div className="widget-values">
																		<div className="widget-value">{secondsToMinSec(callStatus.AverageHoldTime) || '-'}</div>
																	</div>
																</div>
															</Grid>
															<Grid item xs={6}>
																<div className="widget-values-container widget-values-sm">
																	<div className="widget-caption">Avg. Wrap-up-time</div>
																	<div className="widget-values">
																		<div className="widget-value">{secondsToMinSec(callStatus.AverageWrapupTime) || '-'}</div>
																	</div>
																</div>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</div>
										</Grid>
										<Grid item xs={5}>
											<div className="widget-inner-card">
												<Grid item xs={12}>
													<div className="widget-values-container widget-values-sm">
														<div className="widget-caption">Average Answer Time</div>
														<div className="widget-values">
															<div className="widget-value widget-value-md">{secondsToMinSec(callStatus.AverageAnswerTime) || '-'}</div>
															<div className={`widget-value-update ${callStatus.averageAnswerTimeTicker < 0 ? '' : 'red'}`}>
																{callStatus.averageAnswerTimeTicker < 0 ? (
																	<img src={`${process.env.PUBLIC_URL}/icons/down-green-arrow.svg`} width="11" alt="Average Handling Time"></img>
																) : (
																	<img src={`${process.env.PUBLIC_URL}/icons/up-red-arrow.svg`} width="11" alt="Average Handling Time"></img>
																)}{' '}
																{`${callStatus.averageAnswerTimeTicker || 0}%`}
															</div>
														</div>
													</div>
												</Grid>
											</div>
										</Grid>
										<Grid item xs={7}>
											<div className="widget-inner-card">
												<Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="center">
													<Grid item xs={6}>
														<div className="widget-values-container widget-values-sm">
															<div className="widget-caption">Total Calls</div>
															<div className="widget-values">
																<div className="widget-value">{callStatus.TotalCalls || '-'}</div>
															</div>
														</div>
													</Grid>
													<Grid item xs={6}>
														<div className="widget-values-container widget-values-inline">
															<div className="widget-values-row">
																<div className="widget-caption">Total Answered</div>
																<div className="widget-value">{callStatus.AnsweredCalls || '-'}</div>
															</div>
															<div className="widget-values-row">
																<div className="widget-caption">Total Abandoned</div>
																<div className="widget-value">{callStatus.UnansweredCalls || '-'}</div>
															</div>
															<div className="widget-values-row">
																<div className="widget-caption">Active Calls</div>
																<div className="widget-value">{callStatus.activeIvrCalls || '0'}</div>
															</div>
														</div>
													</Grid>
												</Grid>
											</div>
										</Grid>
									</>
								)}
							</Grid>
						}

						{checkRoles("ROLE_DATA_ACCESS") &&
							<Grid container spacing={2}>
								{callStatusFilter.length > 0 && (
									<Grid item xs={12}>
										<div className="filter-outer">
											<div className="filter-container">
												<Stack direction="row" spacing={1}>
													{filterItems}
												</Stack>
											</div>
											<div className="filter-remove" onClick={clearFilter}>
												Remove
											</div>
										</div>
									</Grid>
								)}
								{callStatusFilter.length > 2 ? (
									<OZTable maxHeight={callStatusFilter.length > 0 ? 270 : 360} rows={rowData} columns={columnData}></OZTable>
								) : (
									<>
										<Grid item xs={12}>
											<div className="widget-inner-card">
												<Grid container direction="row" justifyContent="space-between" alignItems="center">
													<Grid item xs={5}>
														{callType === "all" &&
															<Grid container spacing={2} direction={'column'}>
																<Grid item xs={6}>
																	<div className="widget-values-container">
																		<div className="widget-caption">Average Handling Time</div>
																		<div className="widget-values">
																			<div className="widget-value">{secondsToMinSec(callStatus.AverageHandlingTime) || '-'}</div>
																			<div className={`widget-value-update ${callStatus.averageHandlingTimeTicker < 0 ? '' : 'red'}`}>
																				{callStatus.averageHandlingTimeTicker < 0 ? (
																					<img src={`${process.env.PUBLIC_URL}/icons/down-green-arrow.svg`} width="11" alt="Average Handling Time"></img>
																				) : (
																					<img src={`${process.env.PUBLIC_URL}/icons/up-red-arrow.svg`} width="11" alt="Average Handling Time"></img>
																				)}{' '}
																				{`${callStatus.averageHandlingTimeTicker || 0}%`}
																			</div>
																		</div>
																	</div>
																</Grid>
																<Grid item xs={6}>
																	<div className="widget-values-container">
																		<div className="widget-caption">
																		<img
																			style={{ marginTop: '0px', paddingRight: '5px' }}
																			src={`${process.env.PUBLIC_URL}/icons/GreenDotIcon.svg`}
																			alt="edit"
																		>
																		</img>
																			Active Calls
																		</div>
																		<div className="widget-values">
																			<div className="widget-value" style={{marginLeft: 2}}>{callStatus.activeIvrCalls || '0'}</div>
																		</div>
																	</div>
																</Grid>
															</Grid>
														}
														{callType !== "all" &&
															<div className="widget-values-container">
																<div className="widget-caption">Average Handling Time</div>
																<div className="widget-values">
																	<div className="widget-value">{secondsToMinSec(callStatus.AverageHandlingTime) || '-'}</div>
																	<div className={`widget-value-update ${callStatus.averageHandlingTimeTicker < 0 ? '' : 'red'}`}>
																		{callStatus.averageHandlingTimeTicker < 0 ? (
																			<img src={`${process.env.PUBLIC_URL}/icons/down-green-arrow.svg`} width="11" alt="Average Handling Time"></img>
																		) : (
																			<img src={`${process.env.PUBLIC_URL}/icons/up-red-arrow.svg`} width="11" alt="Average Handling Time"></img>
																		)}{' '}
																		{`${callStatus.averageHandlingTimeTicker || 0}%`}
																	</div>
																</div>
															</div>
														}
													</Grid>
													<Grid item xs={7}>
														<Grid container spacing={2}>
															<Grid item xs={6}>
																<div className="widget-values-container widget-values-sm">
																	<div className="widget-caption">Avg. Talk Time</div>
																	<div className="widget-values">
																		<div className="widget-value">{secondsToMinSec(callStatus.AverageTalkTime) || '-'}</div>
																	</div>
																</div>
															</Grid>
															<Grid item xs={6}>
																<div className="widget-values-container widget-values-sm">
																	<div className="widget-caption">Avg. Hold Time</div>
																	<div className="widget-values">
																		<div className="widget-value">{secondsToMinSec(callStatus.AverageHoldTime) || '-'}</div>
																	</div>
																</div>
															</Grid>
															<Grid item xs={6}>
																<div className="widget-values-container widget-values-sm">
																	<div className="widget-caption">Avg. Wrap-up-time</div>
																	<div className="widget-values">
																		<div className="widget-value">{secondsToMinSec(callStatus.AverageWrapupTime) || '-'}</div>
																	</div>
																</div>
															</Grid>
															{callType === "all" &&
																<Grid item xs={6}>
																	<div className="widget-values-container widget-values-sm">
																		<div className="widget-caption">Average Answer Time</div>
																		<div className="widget-values">
																			<div className="widget-value">{secondsToMinSec(callStatus.AverageAnswerTime) || '-'}</div>
																		</div>
																	</div>
																</Grid>
															}
														</Grid>
													</Grid>
												</Grid>
											</div>
										</Grid>

										{/*::::::::::::::::::::::: new implementation::::::::::::::::::::::: */}

										{callType === "all" &&
											<Grid item xs={12}>
												<div className="widget-inner-card">
													<Grid container direction="column" spacing={2} sx={{padding: '10px'}}>
														<Grid spacing={1} container item alignItems="center">
															<Grid item xs={4}>
																<Typography sx={{fontSize: '12px', color: '#99A0A8'}} variant="body1">
																	Total Calls
																</Typography>
															</Grid>
															<Grid item xs={5}>
																<div className="bar-container">
																	<CallStatusProgressBar data={totalCallsSeparate} />
																</div>
															</Grid>
														</Grid>

														<Grid spacing={1} container item alignItems="center">
															<Grid item xs={4}>
																<Typography sx={{fontSize: '12px', color: '#99A0A8'}} variant="body1">
																	Connected Calls
																</Typography>
															</Grid>
															<Grid item xs={5}>
																<div className="bar-container">
																	<CallStatusProgressBar data={answeredCallsSeparate} />
																</div>
															</Grid>
															<Grid item xs={2} sx={{marginLeft: 5}}>
																<Grid container direction="column" sx={{flexGrow: 1}}>
																	<Grid item container alignItems="center">
																		<Grid item>
																		<Grid item sx={{
																			width: 8,
																			height: 8,
																			backgroundColor: '#3296FB',
																			borderRadius: '50%',
																			marginRight: 1,
																		}} />
																		</Grid>
																	<Grid item sx={{fontSize: '12px', color: '#99A0A8'}}>Inbound</Grid>
																</Grid>
																<Grid item container alignItems="center" sx={{ marginTop: 0 }}>
																	<Grid item>
																		<Grid item sx={{
																			width: 8,
																			height: 8,
																			backgroundColor: '#E7AF5B',
																			borderRadius: '50%',
																			marginRight: 1,
																		}} />
																		</Grid>
																		<Grid item sx={{fontSize: '12px', color: '#99A0A8'}}>Outbound</Grid>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>

														<Grid spacing={1} container item alignItems="center">
															<Grid item xs={4}>
																<Typography sx={{fontSize: '12px', color: '#99A0A8'}} variant="body1">
																	Abandoned Calls
																</Typography>
															</Grid>
															<Grid item xs={5}>
																<div className="bar-container">
																	<CallStatusProgressBar data={abandonedCallsSeparate}/>
																</div>
															</Grid>
														</Grid>
													</Grid>
												</div>
											</Grid>
										}

										{/*::::::::::::::::::::::: new implementation::::::::::::::::::::::: */}
										{callType !== 'all' &&
											<>
												<Grid item xs={5}>
													<div className="widget-inner-card">
														<Grid item xs={12}>
															<div className="widget-values-container widget-values-sm">
																<div className="widget-caption">Average Answer Time</div>
																<div className="widget-values">
																	<div className="widget-value widget-value-md">{secondsToMinSec(callStatus.AverageAnswerTime) || '-'}</div>
																	<div className={`widget-value-update ${callStatus.averageAnswerTimeTicker < 0 ? '' : 'red'}`}>
																		{callStatus.averageAnswerTimeTicker < 0 ? (
																			<img src={`${process.env.PUBLIC_URL}/icons/down-green-arrow.svg`} width="11" alt="Average Handling Time"></img>
																		) : (
																			<img src={`${process.env.PUBLIC_URL}/icons/up-red-arrow.svg`} width="11" alt="Average Handling Time"></img>
																		)}{' '}
																		{`${callStatus.averageAnswerTimeTicker || 0}%`}
																	</div>
																</div>
															</div>
														</Grid>
													</div>
												</Grid>
												<Grid item xs={7}>
													<div className="widget-inner-card">
														<Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="center">
															<Grid item xs={6}>
																<div className="widget-values-container widget-values-sm">
																	<div className="widget-caption">Total Calls</div>
																	<div className="widget-values">
																		<div className="widget-value">{callStatus.TotalCalls || '-'}</div>
																	</div>
																</div>
															</Grid>
															<Grid item xs={6}>
																<div className="widget-values-container widget-values-inline">
																	<div className="widget-values-row">
																		<div className="widget-caption">Total Answered</div>
																		<div className="widget-value">{callStatus.AnsweredCalls || '-'}</div>
																	</div>
																	<div className="widget-values-row">
																		<div className="widget-caption">Total Abandoned</div>
																		<div className="widget-value">{callStatus.UnansweredCalls || '-'}</div>
																	</div>
																	<div className="widget-values-row">
																		<div className="widget-caption">Active Calls</div>
																		<div className="widget-value">{callStatus.activeIvrCalls || '0'}</div>
																	</div>
																</div>
															</Grid>
														</Grid>
													</div>
												</Grid>
											</>
										}
									</>
								)}
							</Grid>
						}
					</div>
				</TabContext>
			</Box>
		</Card>
	);
}
