import WidgetHeader from "./header/Header";
import WidgetFooter from "./footer/Footer";
import Conversation from "./conversation/Conversation"
import './widget.css'

function Widget({ messages, open, setMessages, getMessages }) {

    return (
        open &&
        <div className="ozcw-widget">
            <WidgetHeader />
            <Conversation messages={messages} />
            <WidgetFooter getMessages={getMessages} messages={messages} setMessages={setMessages} />
        </div >
    )
}

export default Widget