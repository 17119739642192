import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Grid, } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import { addEditPlan } from '../../../services/api-service';
import OZDialog from "../../../components/admin/common/OZDialog";
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';

const CopyPlanForm = (props) => {
    const [openCopyPlanForm, setCopyCampaignForm] = useState(true);
    const [loading, setLoading] = useState(false);
    const [disabledByLoading, setDisabledByLoading] = useState(false);

    const validationSchema = useMemo(() => (yup.object({
        name: yup
        .string('Enter Plan Name')
        .test("maxDigits",
          "Plan Name should be between 3 to 50 alphanumeric characters long and allows special characters like .,@,_,-",
          (number) => String(number).length >= 3 && String(number).length <= 50)
        .matches(/^([A-Za-z\d]((\.|@|_|\-)?[A-Za-z\d])+)$/, "Plan Name should be between 3 to 50 alphanumeric characters long and allows special characters like .,@,_,-")
        .required('Plan Name is required'),
    })), []);

    const closeCopyPlanForm = () => {
        setCopyCampaignForm(false);
        props.onClose();
    }

    const formButtonStyle = useMemo(
        () => ({
            borderRadius: '8px',
            fontWeight: '400',
            fontSize: '14px',
        }),
        []
    );

    async function saveCopy(data, fullData) {
        setLoading(true);
        let finalData = fullData;
        finalData.name = data.name;
        delete finalData.createdDate;
        delete finalData.updatedDate;
        delete finalData.id;
        try {
          await addEditPlan(finalData)
            .then((resp) => {
              console.log("Status", resp)
              if (resp?.Status === "Success") {
                showSuccessNotification(`Plan ${finalData.name} Created Successfully`)
                props?.updateFunction();
                closeCopyPlanForm();
              }
              else {
                showErrorNotification(resp?.Message?.name || "Failed to create the Plan");
              }
            }).catch((e) => {
                showErrorNotification("Failed to create the Plan");
            });
        } catch (e) {
            showErrorNotification("Failed to create the Plan");
        }
        setLoading(false);
      };

    const DataForm = ({props}) => {
        if (!props) {
            setDisabledByLoading(true);
        } else {
            setDisabledByLoading(false);
        }
        let initData = {}
        initData.name = props?.name + '_Copy';

        return (
            <div >
                <Formik
                    initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        let saveData = structuredClone(values);
                        saveCopy(saveData, props);
                    }}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit} id="copyPlan-Form" className='ozcw-scrollbar' style={{
                                maxHeight: '80vh',
                                overflowY: 'auto',
                            }}>
                            <div style={{width: '30rem'}}>
                            <Grid container spacing={3} flexGrow={1}>
                                <Grid item xs={12} >
                                    <OZInput
                                        name="name"
                                        label="Plan Name *"
                                        placeholder="Plan Name"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 3 }} justifyContent='end'>
                                <Grid item>
                                    <Button style={formButtonStyle} variant='outlined' onClick={closeCopyPlanForm}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item >
                                    <Button style={formButtonStyle} variant="contained" type="submit" form='copyPlan-Form'>
                                        Copy Plan
                                    </Button>
                                </Grid>
                            </Grid>
                            {disabledByLoading && (
                                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                    <LoadingScreen />
                                </div>
                            )}
                            {loading &&
                                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                    <LoadingScreen />
                                </div>
                            }
                            </div>
                        </form>
                    )}
                </Formik>
            </div >
        );
    }
    return (
        <div>
            <OZDialog
                hideActions
                title={
                    <div>
                        <div style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
                            <label>Copy Plan</label>
                        </div>
                    </div>
                }
                open={openCopyPlanForm}
                onClose={closeCopyPlanForm}
            >
                <DataForm props={props?.props} />
            </OZDialog>

        </div>
    );
};

export default CopyPlanForm;
