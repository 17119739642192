import { useEffect, useMemo, useRef, useState } from "react";
import { showErrorNotification, showSuccessNotification } from "../../components/admin/common/NotiService";
import OZDataGrid from "../../components/admin/common/OZDataGrid/OZDataGrid";
import { Box, Button, Menu, MenuItem, Stack, Tab, Typography } from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { deleteWidgetById, getDashboardWidgetById, getReportDetails, getWidgetDetails } from "../../services/api-service";
import OZGridToolbar from "../../components/admin/common/OZDataGrid/OZGridToolbar";
import DeleteDialog from "../../components/admin/common/CustomDeleteDialog";
import './adminstaration.css'
import WidgetForm from "./addWidget/WidgetForm";
import ReportForm from "./addWidget/ReportForm";

export default function WidgetDetails() {


    const [widgetgridRef, setWidgetGridRef] = useState(null);
    const [widgetrowData, setWidgetRowData] = useState(null);
    const [openDrawer, setDrawerState] = useState(false);
    const [openDailogState, setDailogState] = useState(false);
    const [showdashboardAddForm, setShowDashBoardAddForm] = useState({ isNew: false, show: false })




    const [widgetData, setwidgetData] = useState([]);
    useEffect(() => {
        getWidgetDataList();
    }, [])

    const [reportsgridRef, setreportsGridRef] = useState(null);
    const [reportsrowData, setreportsRowData] = useState(null);

    const [reportsLiveData, setreportsLiveData] = useState([]);
    useEffect(() => {
        getReportsLiveDataList();
    }, [])






    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const showReportAddForm = () => {
        setShowDashBoardAddForm({ isNew: true, show: true });
        setDailogState(true);
        setDrawerState(false);
    };


    const handleCloseForm = () => {
        setShowDashBoardAddForm(false);
        setShowDashBoardAddForm({ isNew: false, show: false });
    }

    async function getWidgetDataList() {
        try {
            getWidgetDetails()
                .then((resp) => {
                    setwidgetData(resp.Data)
                })
                .catch((e) => {
                    setwidgetData({})
                })
        }
        catch (e) {
            showErrorNotification("Error in the WidgetList");

        }

    }


    async function getReportsLiveDataList() {
        try {
            getReportDetails()
                .then((resp) => {
                    setreportsLiveData(resp.Data)
                })
                .catch((e) => {
                    setreportsLiveData({})
                })
        }
        catch (e) {
            showErrorNotification("Error in the Live Reports List");

        }
    }




    async function onClickRow(rowItem) {
        setDailogState(false);
        setDrawerState(true);
        setShowDashBoardAddForm({ isNew: false, show: true });
        try {
            const response = await getDashboardWidgetById(rowItem.data.widgetID, 'widget', 'list');
            setWidgetRowData(response.Data)
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function onClickRowReport(rowItem) {
        setDailogState(false);
        setDrawerState(true);
        setShowDashBoardAddForm({ isNew: false, show: true });
        try {
            const response = await getDashboardWidgetById(rowItem.data.reportID, 'report', 'list');
            setreportsRowData(response.Data)
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }


    async function deleteDashboardWigdet(id, name) {
        setDailogState(false);
        setDrawerState(true);
        setShowDashBoardAddForm({ isNew: false, show: false });
        try {
            const response = await deleteWidgetById(id, 'delete', 'widget');
            if (response.Status === "Success") {
                showSuccessNotification(`Widget ${name} has been deleted Successfully`);
                getWidgetDataList();
            } else {
                showErrorNotification(JSON.stringify(response.Message));
            }
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }


    async function deleteDashboardReport(id, name) {
        setDailogState(false);
        setDrawerState(true);
        setShowDashBoardAddForm({ isNew: false, show: false });
        try {
            const response = await deleteWidgetById(id, 'delete', 'report');
            if (response.Status === "Success") {
                showSuccessNotification(`Report ${name} has been deleted Successfully`);
                getReportsLiveDataList();
            } else {
                showErrorNotification(JSON.stringify(response.Message));
            }
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }



    const MouseEnterWidget = (props) => {
        const [ivranchorEl, setAnchorEl] = useState(false);
        const open = Boolean(ivranchorEl);
        const anchorRef = useRef();
        const [deleteMode, setDeleteMode] = useState(false);

        const morevertclick = () => {
            setTimeout(() => setAnchorEl(anchorRef?.current), 1);
        };
        const handleClose = () => {
            setAnchorEl(false);
        };


        const onClickDelete = () => {
            setAnchorEl(false);
            setDeleteMode(true);
        };
        const onClickDialogDelete = () => {
            let id = props?.data?.widgetID;
            let name = props?.data?.widgetName;
            deleteDashboardWigdet(id, name);
            setDeleteMode(false);
        };
        const onClickDialogClose = () => {
            setDeleteMode(false);
        };

        const styleMenuItemButton = useMemo(
            () => ({
                "&.MuiButtonBase-root": {
                    color: '#212121',
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                    height: '18px',
                    lineHeight: "14px",
                },
                '&:hover': {
                    borderRadius: "8px",
                },
            }),
            []
        );

        return (
            <div className="oz-administration-grid-cellrenderer-container">
                <Typography
                    sx={{
                        fontfamily: "Roboto",
                        fontstyle: "normal",
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#536580",
                        cursor: "pointer"
                    }}>
                    {(props?.data.widgetLocation === 0) ? "DashboardDB"
                        : (props?.data.widgetLocation === 1) ? "MDL"
                            : (props?.data.widgetLocation === 2) ? "RedisConnection"
                                : (props?.data.widgetLocation === 3) ? "CloudagentDB"
                                    : "UpdateMonitor"}
                </Typography>

                <img className="gridMorevertIcon" ref={anchorRef} style={{ marginTop: "2px", marginLeft: '0.625rem', alignSelf: 'center' }}
                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                    alt="edit"
                    onClick={morevertclick}
                ></img>
                <div ref={anchorRef}>
                    <Menu anchorEl={ivranchorEl} open={open} onClose={handleClose} sx={{
                        maxHeight: "70vh",
                        "& .MuiPaper-root": {
                            backgroundColor: "white",
                            width: "133px",
                        },
                        "& .MuiMenu-paper": {
                            backgroundColor: "white",
                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                            border: "0.0625rem solid #E6E5E6",
                            borderRadius: "12px",
                            marginLeft: "-1.5625rem",
                        },
                    }} >
                        <MenuItem sx={{ ...styleMenuItemButton, marginTop: "7px" }} onClick={onClickDelete}>Delete</MenuItem>
                    </Menu>
                </div>
                {
                    deleteMode && <DeleteDialog title={"Delete Widget Name"} content={"This will delete this Widget Name:" + props.data.widgetName + " permanently. You cannot undo this action."} onClose={onClickDialogClose}
                        open={true} deleteFunction={onClickDialogDelete} />
                }

            </div>

        );
    };

    const widgetcolumnDefs = ([
        { headerName: 'Widget ID', field: 'widgetID' },
        { headerName: 'Widget Name', field: 'widgetName' },
        { headerName: 'Widget Query', field: 'widgetQuery' },
        { headerName: 'Widget Parameters', field: 'widgetParams' },
        { headerName: 'Widget Location', field: 'widgetLocation', cellRenderer: MouseEnterWidget }


    ])

    const MouseEnterReport = (props) => {
        const [ivranchorEl, setAnchorEl] = useState(false);
        const open = Boolean(ivranchorEl);
        const anchorRef = useRef();
        const [deleteMode, setDeleteMode] = useState(false);

        const morevertclick = () => {
            setTimeout(() => setAnchorEl(anchorRef?.current), 1);
        };
        const handleClose = () => {
            setAnchorEl(false);
        };


        const onClickDelete = () => {
            setAnchorEl(false);
            setDeleteMode(true);
        };
        const onClickDialogDelete = () => {
            let id = props?.data?.reportID;
            let name = props?.data?.reportName;
            deleteDashboardReport(id, name);
            setDeleteMode(false);
        };
        const onClickDialogClose = () => {
            setDeleteMode(false);
        };

        const styleMenuItemButton = useMemo(
            () => ({
                "&.MuiButtonBase-root": {
                    color: '#212121',
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                    height: '18px',
                    lineHeight: "14px",
                },
                '&:hover': {
                    borderRadius: "8px",
                },
            }),
            []
        );

        return (
            <div className="oz-administration-grid-cellrenderer-container">
                <Typography
                    sx={{
                        fontfamily: "Roboto",
                        fontstyle: "normal",
                        fontWeight: 500,
                        fontSize: "12px",
                        color: "#536580",
                        cursor: "pointer"
                    }}>
                    {(props?.data.reportLocation === 0) ? "DashboardDB"
                        : (props?.data.reportLocation === 1) ? "MDL"
                            : (props?.data.reportLocation === 2) ? "RedisConnection"
                                : (props?.data.reportLocation === 3) ? "CloudagentDB"
                                    : "UpdateMonitor"}
                </Typography>

                <img className="gridMorevertIcon" ref={anchorRef} style={{ marginTop: "2px", marginLeft: '0.625rem', alignSelf: 'center' }}
                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                    alt="edit"
                    onClick={morevertclick}
                ></img>
                <div ref={anchorRef}>
                    <Menu anchorEl={ivranchorEl} open={open} onClose={handleClose} sx={{
                        maxHeight: "70vh",
                        "& .MuiPaper-root": {
                            backgroundColor: "white",
                            width: "133px",
                        },
                        "& .MuiMenu-paper": {
                            backgroundColor: "white",
                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                            border: "0.0625rem solid #E6E5E6",
                            borderRadius: "12px",
                            marginLeft: "-1.5625rem",
                        },
                    }} >
                        <MenuItem sx={{ ...styleMenuItemButton, marginTop: "7px" }} onClick={onClickDelete}>Delete</MenuItem>
                    </Menu>
                </div>
                {
                    deleteMode && <DeleteDialog title={"Delete Report Name"} content={"This will delete this Report Name:" + props.data.reportName + " permanently. You cannot undo this action."} onClose={onClickDialogClose}
                        open={true} deleteFunction={onClickDialogDelete} />
                }

            </div>

        );
    };


    const reportsLivecolumnDefs = ([
        { headerName: 'Report ID', field: 'reportID' },
        { headerName: 'Report Name', field: 'reportName' },
        { headerName: 'Report Query', field: 'reportQuery' },
        { headerName: 'Report Parameters', field: 'reportParams' },
        { headerName: 'Report Location', field: 'reportLocation', cellRenderer: MouseEnterReport }


    ])


    let buttonStyle = useMemo(
        () => ({
            borderRadius: "8px",
            height: "30px",
            fontSize: "11px",
            fontWeight: 400,
            width: '8rem',

        }),
        []
    );


    return (
        <div className="oz-administration-container">
            <Typography
                sx={{
                    fontweight: '400',
                    fontSize: '12px',
                    marginBottom: "5px"
                }}
                flexGrow={1}
                alignSelf="center"
            >
                Administration
            </Typography>
            <Typography sx={{
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '28px'
            }} flexGrow={1} alignSelf='center'>API Configuration</Typography>
            <Stack direction="row" sx={{ marginBottom: 2 }} spacing={1}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Widgets" value="1" />
                                <Tab label="Reports" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <OZGridToolbar title="List of Widgets"
                                searchPlaceholder='Widget'
                                rowCount={widgetData.length}
                                dataGridRef={widgetgridRef}
                                hideDownload={true} />
                            <OZDataGrid
                                columns={widgetcolumnDefs}
                                data={widgetData}
                                setGridRef={setWidgetGridRef}
                                height="26.3rem"
                                rowClickCallback={onClickRow} />

                            {showdashboardAddForm.show && showdashboardAddForm.isNew && (
                                <WidgetForm
                                    Report={getWidgetDataList}
                                    delFunction={deleteDashboardWigdet}
                                    onClose={handleCloseForm}
                                    popClose={openDailogState}
                                    drawerClose={openDrawer}
                                />
                            )}
                            {showdashboardAddForm.show && !showdashboardAddForm.isNew && (
                                <WidgetForm
                                    Report={getWidgetDataList}
                                    delFunction={deleteDashboardWigdet}
                                    onClose={handleCloseForm}
                                    data={widgetrowData || []}
                                    popClose={openDailogState}
                                    drawerClose={openDrawer}
                                />
                            )}
                        </TabPanel>
                        <TabPanel value="2">
                            <OZGridToolbar title="List of Reports" searchPlaceholder='Report' rowCount={reportsLiveData.length} dataGridRef={reportsgridRef} hideDownload={true} />
                            <OZDataGrid
                                columns={reportsLivecolumnDefs}
                                data={reportsLiveData}
                                setGridRef={setreportsGridRef}
                                height="26.3rem"
                                rowClickCallback={onClickRowReport} />
                            {showdashboardAddForm.show && showdashboardAddForm.isNew && (
                                <ReportForm
                                    Report={getReportsLiveDataList}
                                    delFunction={deleteDashboardReport}
                                    onClose={handleCloseForm}
                                    popClose={openDailogState}
                                    drawerClose={openDrawer}
                                />
                            )}
                            {showdashboardAddForm.show && !showdashboardAddForm.isNew && (
                                <ReportForm
                                    Report={getReportsLiveDataList}
                                    delFunction={deleteDashboardReport}
                                    onClose={handleCloseForm}
                                    data={reportsrowData || []}
                                    popClose={openDailogState}
                                    drawerClose={openDrawer}
                                />
                            )}
                        </TabPanel>
                    </TabContext>
                </Box>
                <Button
                    sx={buttonStyle}
                    className="Add-button"
                    variant="contained"
                    startIcon={<AddBoxOutlinedIcon />}
                    onClick={showReportAddForm}
                >
                    Add API
                </Button>
            </Stack>


        </div>)

}