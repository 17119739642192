import CloseIcon from '@mui/icons-material/Close';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import { Button, Grid, IconButton, Menu, MenuItem, Theme, Divider,Stack, Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { bargeIn } from '../../services/api-service';
import '../BargeInDrawer/BargeInDrawer.css';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import { stringAvatar } from '../admin/common/Avatar';

const useStyles: any = makeStyles((theme: Theme) => ({
	thresholdContainer: {
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column'
	},
	thresholdHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	thresholdHeaderItems: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	headerText: {
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '21px',
		color: '#212121',
		marginLeft: '16px'
	},
	drawerGutter: {
		paddingTop: '74px'
	}
}));
const STATUS = {
	PLACEHOLDER: 'PLACEHOLDER',
	CONNECTING: 'CONNECTING',
	CONNECTED: 'CONNECTED',
	ENDED: 'ENDED'
};
const STATUS_LABEL = {
	[STATUS.PLACEHOLDER]: 'Not connected',
	[STATUS.CONNECTING]: 'Connecting',
	[STATUS.CONNECTED]: 'Connected',
	[STATUS.ENDED]: 'Ended'
};
const BARGEIN_IMAGES = {
	[STATUS.PLACEHOLDER]: 'bargein-placeholder.png',
	[STATUS.CONNECTING]: 'bargein-connecting.jpeg',
	[STATUS.CONNECTED]: 'bargein-connected.jpeg',
	[STATUS.ENDED]: 'bargein-ended.jpeg'
};

type Props = {
	toggleDrawer: any;
	isOpen: boolean | undefined;
	agentDetails?: any;
};

const BargeInDrawer = ({ isOpen, toggleDrawer, agentDetails }: Props) => {
	console.log("here are values ", isOpen, toggleDrawer, agentDetails);
	const classes = useStyles();
	const { useState, useEffect ,useMemo} = React;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [bargeInType, setBargeInType] = useState('Normal');
	const [bargeInNumber, setBargeInNumber] = useState('');
	const [bargeInStatus, setBargeInStatus] = useState(STATUS.PLACEHOLDER);
	const open = Boolean(anchorEl);
	const downloadContent = React.useRef<null | HTMLElement>(null);
	const [callTypeTab, setCallTypeTab] = React.useState('listen');

	const agentAvatar = useMemo(
		() => ({
			width: '100px',
			height: '100px',
			fontWeight: 500,
			color: '#000000', 
			fontSize: "70px" ,
		}),
		[]
	);

	const customerAvatar = useMemo(
		() => ({
			width: '100px',
			height: '100px',
			fontWeight: 500,
			color: '#000000', 
			fontSize: "20px" ,
		}),
		[]
	);

	const handleClose = (type: any = null) => {
		if (type) {
			setBargeInType(type);
		}
		setAnchorEl(null);
	};
	const handleInputChange = (event: any) => {
		setBargeInNumber(event.target.value);
	};
	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleBargeIn = async (event: React.SyntheticEvent, value: string) => {
		if(value === "PLACEHOLDER" || value === "ENDED"){
			if (!bargeInNumber?.length) return;
			setBargeInStatus(STATUS.CONNECTING);
			const reponse = await bargeIn({ UCID: agentDetails.UCID, isSip: bargeInType === 'SIP', agentNumber: agentDetails.AgentPhoneNumber, phoneNo: bargeInNumber });
			if (reponse.status === 200) {
				setBargeInStatus(STATUS.CONNECTED);
			} else {
				setBargeInStatus(STATUS.ENDED);
			}
		}else if(value === "CONNECTED"){
			setBargeInStatus(STATUS.ENDED);
		}
	};

	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setCallTypeTab(newValue);
	};

	useEffect(() => {
		setBargeInStatus(STATUS.PLACEHOLDER);
		setBargeInNumber('');
	}, [agentDetails]);

	const ConnectionAvatarComp = (details: any) => {
		console.log("here are the detaisl coming ", details?.details);
		return (
			<>
				<Grid sx={{marginTop: '1px', textAlign: 'center', backgroundColor: '#FBFCFC'}} container spacing={6} display='flex' direction={'row'} >
					<Grid item sx={{marginLeft: '1.5rem'}} >
						<Avatar style={agentAvatar} {...stringAvatar(details?.details?.AgentName)} ></Avatar>
						<div>{details?.details?.AgentName}</div>
					</Grid>
					<Grid item>
						<Avatar style={customerAvatar}>Customer</Avatar>
						<div>+91 8790107294</div>
					</Grid>
				</Grid>
				<span style={{ flexGrow: 1, alignSelf: "flex-end"}}>
					<Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6",marginTop: 1}} />
				</span>
				<Grid sx={{marginTop: 1, backgroundColor: '#FFF'}} container spacing={2} display='flex' alignItems='center' direction={'row'} >
					<Grid item sx={{marginLeft: '5.5rem'}}>
						<Avatar style={agentAvatar} {...stringAvatar(details?.details?.AgentName)} ></Avatar>
					</Grid>
				</Grid>
			</>
		)
	}

	return (
		<Drawer open={isOpen} anchor={'right'} onClose={() => toggleDrawer(false)}>
			<Box sx={{ width: 355, minHeight: 150 }} className={classes.thresholdContainer}>
				<div className={classes.drawerGutter}>
					<div className={classes.thresholdHeader}>
						<div className={classes.thresholdHeaderItems}>
							<GraphicEqIcon color="primary" />
							<span className={classes.headerText}>Voice</span>
						</div>
						<IconButton aria-label="delete" size="small" onClick={() => toggleDrawer(false)}>
							<CloseIcon style={{ fontSize: '22px', color: '#99a0a8' }} />
						</IconButton>
					</div>
				</div>
				<div className="bargein-items-container">
					<div className="call-status-container">
						<b>Call Status: </b>
						<span className="call-status"></span>
						<span>{STATUS_LABEL[bargeInStatus]}</span>
					</div>
					<div className="agent">
						<b>Agent: </b>
						<span className="agent-profile"></span>
						<span>{agentDetails?.AgentName}</span>
					</div>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<b>Type</b>
						</Grid>
						<Grid item>
							<div className="drawer-search-container no-margin">
								<>
									<div className="drawer-search-dd" onClick={handleMenuOpen}>
										<span>{bargeInType}</span>
									</div>
									<Menu
										id="download-appbar"
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left'
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left'
										}}
										open={open}
										onClose={() => handleClose()}
										className="custom-search-dropdown"
									>
										<MenuItem className="download-item" onClick={() => handleClose('Normal')}>
											Normal
										</MenuItem>
										<MenuItem className="download-item" onClick={() => handleClose('SIP')}>
											SIP
										</MenuItem>
									</Menu>
								</>
								<>
									<div className="drawer-search-bar">
										<input  value={bargeInNumber} onChange={handleInputChange} placeholder="Number" />
									</div>
								</>
							</div>
						</Grid>
					</Grid>
					<span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
						<Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6" , marginTop:1}} />
					</span>
					<>
					{console.log("here e", bargeInStatus)}
					</>
					{bargeInStatus === "CONNECTED" && bargeInType === "SIP" &&
					<>
						<Box ref={downloadContent} sx={{ width: '100%', typography: 'body1' }}>
							<TabContext value={callTypeTab}>
								<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
									<TabList onChange={handleChangeTab} className="customTabs">
										<Tab icon={<img src={`${process.env.PUBLIC_URL}/icons/call_icon_bargein.svg`} alt="info_icon_blue"/>} label="Listen" value="listen" />
										<Tab icon={<img src={`${process.env.PUBLIC_URL}/icons/training_icon_bargein.svg`} alt="info_icon_blue"/>} label="Training" value="training" />
										<Tab icon={<img src={`${process.env.PUBLIC_URL}/icons/table-bargein-icon.svg`} alt="info_icon_blue"/>} label="Bargein" value="bargein" />
									</TabList>
								</Box>
							</TabContext>
						</Box>
						<Stack direction={'row'} sx={{backgroundColor: '#E5F4FF'}}>
							<img src={`${process.env.PUBLIC_URL}/icons/info_icon_blue.svg`} alt="info_icon_blue" />
							<div style={{ height: '40px', flexShrink: '0', paddingTop: '8px', fontSize: '14px'}}>
								{callTypeTab === "listen" ? "You can only listen to the call" : callTypeTab === "training" ? "Only agent can hear you." : "Both agent and customer can hear you."}
							</div>
						</Stack>
					</>
					} 
					{/* {bargeInType === "Normal" && bargeInStatus !== "CONNECTED" ?
						<div className="bargein-status-container">
							<img src={`${process.env.PUBLIC_URL}/img/${BARGEIN_IMAGES[bargeInStatus]}`} alt="Bargein Status" />
						</div>
						:
						<div>
							hello
						</div>
					} */}
					{bargeInType === "Normal" &&
						<div className="bargein-status-container">
							<img src={`${process.env.PUBLIC_URL}/img/${BARGEIN_IMAGES[bargeInStatus]}`} alt="Bargein Status" />
						</div>
					}
					{bargeInType === "SIP" &&
						<>
						{bargeInStatus !== "CONNECTED" ?
							<div className="bargein-status-container">
								<img src={`${process.env.PUBLIC_URL}/img/${BARGEIN_IMAGES[bargeInStatus]}`} alt="Bargein Status" />
							</div>
							:
							<Box>
								<ConnectionAvatarComp details={agentDetails}/>
							</Box>
						}
						</>
					}
				</div>
				{/* {bargeInStatus === STATUS.PLACEHOLDER && (
					<div className="bargein-items-footer">
						<Button variant="contained" onClick={() => handleBargeIn()}>
							Call
						</Button>
					</div>
				)} */}
				<div className={bargeInStatus === "CONNECTED" ? "bargein-items-error" : bargeInStatus === "PLACEHOLDER" ? "bargein-items-footer" : "bargein-items-footer"}>
					<Button variant="contained" disabled={bargeInStatus === "CONNECTING"} color={bargeInStatus === "CONNECTED" ? "error" : "primary"} onClick={(e) => handleBargeIn(e,bargeInStatus)}>
						{bargeInStatus === "CONNECTED" ? "Hang up" : "Call"}
					</Button>
				</div>
			</Box>
		</Drawer>
	);
};

export default BargeInDrawer;
