import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 15
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(9px)'
		}
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(12px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#177ddc'
			}
		}
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(['width'], {
			duration: 200
		})
	},
	'& .MuiSwitch-track': {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
		boxSizing: 'border-box'
	}
}));

export default function LightDarkSwitch(props: any) {
	const { mode, handleSetMode } = props;
	const className = mode ? 'dark-mode' : 'light-mode';
	const opacity = mode ? 'light-opacity' : '';
	const handleToggle = () => {
		handleSetMode(!mode);
	};

	return (
		<FormGroup>
			<Stack direction="row" spacing={1} alignItems="center" sx={{ marginTop: 1 }}>
				<Typography className={`${className} ${opacity}`}>light</Typography>
				<AntSwitch onChange={handleToggle} inputProps={{ 'aria-label': 'ant design' }} />
				<Typography className={className} style={{ marginRight: 23 }}>
					Dark
				</Typography>
			</Stack>
		</FormGroup>
	);
}
