import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 4,
    width: 16,
    height: 16,
    // boxShadow:
    //     theme.palette.mode === 'dark'
    //         ? '0 0 0 1px #99A0A8'
    //         : 'inset 0 0 0 1px #99A0A8, inset 0 -1px 0 #99A0A8',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'transparent',

    // backgroundImage:
    //     theme.palette.mode === 'dark'
    //         ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
    //         : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    // '.Mui-focusVisible &': {
    //     outline: '2px auto rgba(19,124,189,.6)',
    //     outlineOffset: 2,
    // },
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            `url(${process.env.PUBLIC_URL}/icons/checkbox_before.svg)`,
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        // boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'transparent',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: 'transparent',
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            `url(${process.env.PUBLIC_URL}/icons/checkbox_after.svg)`,
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});


function CustomizedCheckbox(props) {
    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'oz-customized-chbx' }}
            {...props}
        />
    );
}

export default CustomizedCheckbox;