import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import Card from '@mui/material/Card';
import * as React from 'react';
import {runningStatusCalls, runningStatusChats } from '../../../services/api-service';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';

import { Badge, Box, Chip, Stack, Grid, ToggleButton, ToggleButtonGroup, Theme } from '@mui/material';
import { WIDGET_REFRESH_INTERVAL, dummypieChart, dummypieChartDigital } from '../../../utils/constants';
import '../LocationQueueCounter/LocationQueueCounter.css';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { makeStyles } from '@mui/styles';

let interval: any;

const useStyles: any = makeStyles((theme: Theme) => ({
	boundButton: {
		padding: '5px !important',
		minWidth: 70
	},
}));

export default function RunningStatus(props: any) {
    const classes = useStyles();
	const { useState, useRef } = React;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || '1');
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [runningStatusFilter, setRunningStatusFilter] = useState<Array<any>>(props?.selectedFilters?.filters || []);
	const downloadContent = useRef<null | any>(null);

    const [graphData, setGraphData] = useState<any>([]);
    const [bound, setBound] = useState<any>('call');

	const chartElement: any = useRef(null);

	const additionalApiParams = {campaignType: 'chat'};

	const formatGraphData = (data: any[]) => {
		let graphData : any = [];
        if(bound === 'call'){
            graphData = data?.map(item => 
                item.name === 'busy' ? 
                    { name: 'Connected', y: item.y, color: item.color } 
                : item.name === 'Calling' ? 
                    { name: 'Calling', y: item.y, color: item.color } 
                : item.name === 'CallsinQueue' ?
                    { name: 'Calls in Queue', y: item.y, color: item.color }
                : item.name === 'ActiveCallsinIVR' ? 
                    { name: 'Active Calls in IVR', y: item.y, color: item.color }
                : ''
            );
        }else if(bound === 'digital') {
            graphData = data?.map(item => 
                item.name === 'busy' ? 
                    { name: 'Connected', y: item.y, color: item.color } 
                : item.name === 'ChatsInQueue' ? 
                    { name: 'Chats In Queue', y: item.y, color: item.color } 
				: item.name === 'bot' ?
                    { name: 'BOT', y: item.y, color: item.color }
                : ''
            );
        }

        return graphData;
    };

	const newFilteredData = (data: any[]) => {
	}

	const retriveGraphData = async (filters = '0') => {
        let data : any = [];
        if(bound === 'call'){
			let tempData : any = [];
			tempData = await runningStatusCalls({ filters, type: filterType});
			if(tempData[0]?.Busy && parseInt(tempData[0]?.Busy) > 0){
				let Ntemp = { name: 'busy', y: parseInt(tempData[0].Busy), color: '#24528D'};
				data.push(Ntemp);
			}
			if(tempData[0]?.Calling && parseInt(tempData[0]?.Calling) > 0){
				let Ntemp = { name: 'Calling', y: parseInt(tempData[0].Calling), color: '#70DAA9'};
				data.push(Ntemp);
			}
			if(tempData[0]?.CallsInQueue && parseInt(tempData[0]?.CallsInQueue) > 0){
				let Ntemp = { name: 'CallsinQueue', y: parseInt(tempData[0].CallsInQueue), color: '#008DFF'};
				data.push(Ntemp);
			}
			if(tempData[0]?.activeIvrCalls && parseInt(tempData[0]?.activeIvrCalls) > 0){
				let Ntemp = { name: 'ActiveCallsinIVR', y: parseInt(tempData[0].activeIvrCalls), color: '#B9D8FB'};
				data.push(Ntemp);
			}
            // data = dummypieChart;

        }else if(bound === 'digital'){
            // data = dummypieChartDigital;
			let tempData1 : any = [];
			tempData1 = await runningStatusChats({ filters});
			if(tempData1[0]?.connected && parseInt(tempData1[0]?.connected) > 0){
				let Ntemp = { name: 'busy', y: parseInt(tempData1[0].connected), color: '#B9D8FB'};
				data.push(Ntemp);
			}
			if(tempData1[0]?.queue && parseInt(tempData1[0]?.queue) > 0){
				let Ntemp = { name: 'ChatsInQueue', y: parseInt(tempData1[0].queue), color: '#536580'};
				data.push(Ntemp);
			}
			if(tempData1[0]?.bot && parseInt(tempData1[0]?.bot) > 0){
				let Ntemp = { name: 'bot', y: parseInt(tempData1[0].bot), color: '#36D5AE'};
				data.push(Ntemp);
			}
        }

		let graphData  = formatGraphData(data);
		setGraphData(graphData);
		chartElement?.current?.chart?.redraw();
	};

    const chartOptionsPie = {
		chart: {
			type: 'pie',
			// animation: {
			// 	duration: 2000
			// },
			events: {
                render: function (this: any) {
                    const chart = this;
                    const total = Math.round(chart.series[0].data.reduce((sum: any, point: any) => sum + (point as Highcharts.Point).y, 0));
                    const chartCenterX = chart.plotWidth / 2 + chart.plotLeft;
                    const chartCenterY = chart.plotHeight / 2 + chart.plotTop;
                    if (!chart.customText) {
                        chart.customText = chart.renderer.text(
                            `Total<br/>  ${total}`,
                            chartCenterX,
                            chartCenterY
                            // chart.plotWidth / 2 + chart.plotLeft,
                            // chart.plotHeight / 2 + chart.plotTop
                        ).css({
                            color: '#000000',
                            fontSize: '24px',
                            textAlign: 'center',
							fontWeight: '400',
                        }).attr({
                            align: 'center',
                            zIndex: 5
                        }).add();
                    } else {
                        chart.customText.attr({
                            text: `Total<br/> ${total}`
                        });
                    }

                    // Update text position
                    const bbox = chart.customText.getBBox();
                    chart.customText.attr({
                        x: chartCenterX - bbox.width / 25,
                        y: chartCenterY - bbox.height / 10 // Adjust the text's y position if needed
                    });
                }
            }
		},
		title: {
			text: ''
		},
		credits: {
			enabled: false
		},
		series: [{
			enableMouseTracking: false,
			innerSize: '40%',
			type: 'pie',
			// animation: {
			// 	duration: 2000
			// },
			colorByPoint: true,
			data: graphData
		}],
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: true,
					format: '<b>{point.y:.0f}</b>',
					distance: -40, // Adjust the distance of the data label from the pie slice
					style: {
						color: '#FFFFFF',
						textOutline: 'none',
						fontSize: '14px',
						fontWeight: '600',
					}
				},
				showInLegend: true,
				center: ['50%', '50%'],
				size: '80%'
			}
		},
		legend: {
			enabled: true,
			align: 'right',
			layout: 'vertical',
			verticalAlign: 'middle',
			itemMarginTop: 5,
			itemMarginBottom: 5,
			itemStyle: {
				fontSize: '14px'
			}
		},
		tooltip: {
			headerFormat: '<span style="font-size:10px">{series.name}</span><br/>',
			pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}</b><br/>'
		}
	};

	const chartOptionsPieChats = {
		chart: {
			type: 'pie',
			// animation: {
			// 	duration: 2000
			// },
			events: {
                render: function (this: any) {
                    const chart = this;
                    const total = Math.round(chart.series[0].data.reduce((sum: any, point: any) => sum + (point as Highcharts.Point).y, 0));
                    const chartCenterX = chart.plotWidth / 2 + chart.plotLeft;
                    const chartCenterY = chart.plotHeight / 2 + chart.plotTop;
                    if (!chart.customText) {
                        chart.customText = chart.renderer.text(
                            `Total<br/> ${total}`,
                            // chart.plotWidth / 2 + chart.plotLeft,
                            // chart.plotHeight / 2 + chart.plotTop
                            chartCenterX,
                            chartCenterY
                        ).css({
                            color: '#000000',
                            fontSize: '24px',
                            textAlign: 'center',
							fontWeight: '400',
                        }).attr({
                            align: 'center',
                            zIndex: 5
                        }).add();
                    } else {
                        chart.customText.attr({
                            text: `Total<br/> ${total}`
                        });
                    }
                    // Update text position
                    const bbox = chart.customText.getBBox();
                    chart.customText.attr({
                        x: chartCenterX - bbox.width / 25,
                        y: chartCenterY - bbox.height / 10 // Adjust the text's y position if needed
                    });
                }
            }
		},
		title: {
			text: ''
		},
		credits: {
			enabled: false
		},
		series: [{
			enableMouseTracking: false,
			innerSize: '40%',
			type: 'pie',
			// animation: {
			// 	duration: 2000
			// },
			colorByPoint: true,
			data: graphData
		}],
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: true,
					format: '<b>{point.y:.0f}</b>',
					distance: -40, // Adjust the distance of the data label from the pie slice
					style: {
						color: '#FFFFFF',
						textOutline: 'none',
						fontSize: '14px',
						fontWeight: '600',
					}
				},
				showInLegend: true,
				center: ['50%', '50%'],
				size: '80%'
			}
		},
		legend: {
			enabled: true,
			align: 'right',
			layout: 'vertical',
			verticalAlign: 'middle',
			itemMarginTop: 5,
			itemMarginBottom: 5,
			itemStyle: {
				fontSize: '14px'
			}
		},
		tooltip: {
			headerFormat: '<span style="font-size:10px">{series.name}</span><br/>',
			pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}</b><br/>'
		}
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = runningStatusFilter.filter((item: any) => item.id !== id);
		setRunningStatusFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const clearFilter = () => {
		console.info('Clear all filter.');
		setRunningStatusFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

    const handleBound = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
		if (newAlignment === null || newAlignment === undefined) return;
		setBound(newAlignment);
		setRunningStatusFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
		setFilterType('1');
	};

	const handleFilterExistingData = (filteredData: any) => {
		setRunningStatusFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setRunningStatusFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	const filterItems = runningStatusFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);


	React.useEffect(() => {
		const filterIds = (runningStatusFilter.length && !(responseLength === runningStatusFilter.length)) ? runningStatusFilter.map((item: any) => item.id).join(',') : '0';
		retriveGraphData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		interval = setInterval(() => retriveGraphData(filterIds), WIDGET_REFRESH_INTERVAL.RUNNING_STATUS);
	}, [runningStatusFilter, bound]);

	return (
		<Card ref={downloadContent || chartElement} className="customCard-runningStatus">
			<div className="card-header">
				<div className="card-title">
				Interaction Running Status<div className="card-title-badge">Live</div>
				</div>
				<div className="card-actions">
                    <ToggleButtonGroup value={bound} color="primary" exclusive onChange={handleBound} aria-label="bound">
                        <ToggleButton value="call" className={classes.boundButton} aria-label="call">
                            <span>Call</span>
                        </ToggleButton>
                        <ToggleButton value="digital" className={classes.boundButton} aria-label="digital">
                            <span>Digital</span>
                        </ToggleButton>
                    </ToggleButtonGroup>
					<WidgetDownload element={downloadContent || chartElement} handleDownload={handleDownload} name={'Interaction Running Status'}></WidgetDownload>
					<div className="card-icon" onClick={() => toggleDrawer(true)}>
						<Badge badgeContent={runningStatusFilter.length} color="primary">
							<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</Badge>
					</div>
				</div>
			</div>
            {bound === 'call' ?
                <>
					<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} allowedFilters={['Campaign', 'Skill']}  applyFilters={handleApplyFilters} existingSelectedFilters={runningStatusFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/>
                </>
            :
                <>
					<FilterDrawer isOpen={isDrawerOpen} RunningStatusWidget={true} allowedFilters={['Campaign']} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} additionalApiParams={additionalApiParams} existingSelectedFilters={runningStatusFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/>
                </>
            }
			{/* <FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={runningStatusFilter} setResponseLength={setresponseLength} /> */}
			<Box sx={{ width: '100%', typography: 'body1' }}>
				{runningStatusFilter.length > 0 && (
					<Box className='customTabPanel'>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div className="filter-outer">
									<div className="filter-container">
										<Stack direction="row" spacing={1}>
											{filterItems}
										</Stack>
									</div>
									<div className="filter-remove" onClick={clearFilter}>
										Remove
									</div>
								</div>
							</Grid>
						</Grid>
					</Box>
				)}
			</Box>
            {bound === 'call' ?
                <>
                    <HighchartsReact ref={chartElement} highcharts={Highcharts} options={chartOptionsPie}/>
                </>
            :
                <>
                    <HighchartsReact ref={chartElement} highcharts={Highcharts} options={chartOptionsPieChats}/>
                </>
            }
		</Card>
	);
}
