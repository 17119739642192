import React from 'react';
import './switch.css'

function SimpleSwitch(props) {

    const toggleSwitch = (e) => {
        if (props.onChange) {
            props.onChange(e)
        }

    }
    return (
        <label className="switch">
            <input type="checkbox"
                disabled={props.disabled || false}
                onChange={toggleSwitch}
                checked={props.checked || false}
                id={props.id || null}
            >
            </input>
            <span className={props.disabled ? "disabled_slider round" : "slider round"}></span>
        </label>
    )
}

export default SimpleSwitch