import React from 'react';
import { Box, Tooltip} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: '16px',
  },
  progressContainer: {
    display: 'flex',
    height: '15px',
    minWidth: '200px',
    borderRadius: '15px',
    overflow: 'hidden',
    backgroundColor: '#e0e0e0', // Grey background for the progress bar
  },
  progressSegment: {
    height: '100%',
    fontSize: '12px',
    paddingLeft: '5px'
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '9px',
  },
  outbound: {
    backgroundColor: '#E7AF5B'
  },
  inbound: {
    backgroundColor: '#3296FB'
  },
  totalLabel: {
    marginLeft: '9px',
    color: '#000000'
  },
  zeroValue: {
    backgroundColor: '#e0e0e0', // Grey background for zero value segments
  },
});

const CallStatusProgressBar = ({ data }) => {
  const classes = useStyles();
  const total = Object.values(data).reduce((sum, value) => sum + parseInt(value, 10), 0);

  // if (total === 0) return null; //this is removed to show the data if value is zero

  return (
    <>
        <Box display="flex" alignItems="center">
            <Box className={classes.progressContainer}>
                {Object.keys(data).map((key, index) => {
                const value = parseInt(data[key], 10);
                // if (value === 0) return null; //this is removed to show the data if value is zero
                
                // const percentage = (value / total) * 100; //this is removed to show the data if value is zero
                // let colorClass = '';

                const percentage = total > 0 ? (value / total) * 100 : 0;
                let colorClass = value === 0 ? classes.zeroValue : '';

                // switch (key.toLowerCase()) { //this is removed to show the data if value is zero
                //     case 'outbound':
                //     colorClass = classes.outbound;
                //     break;
                //     case 'inbound':
                //     colorClass = classes.inbound;
                //     break;
                //     default:
                //     colorClass = classes.inbound;
                //     break;
                // }

                if (value !== 0) {
                  switch (key.toLowerCase()) {
                    case 'outbound':
                      colorClass = classes.outbound;
                      break;
                    case 'inbound':
                      colorClass = classes.inbound;
                      break;
                    default:
                      colorClass = classes.inbound;
                      break;
                  }
                }

                return (
                    <Tooltip key={index} title={`${key}: ${data[key]}`}>
                    <div
                        className={`${classes.progressSegment} ${colorClass}`}
                        style={{ width: `${percentage}%` }}
                    >
                        {data[key] === 0 ? '' : data[key]}
                    </div>
                    </Tooltip>
                );
                })}
            </Box>
            <Box className={classes.totalLabel}>
                {total}
            </Box>
        </Box>
    </>
  )
};

export default CallStatusProgressBar