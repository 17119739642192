import React, { useState, useMemo, useRef, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Grid, Menu, MenuItem, Typography, Stack, Chip, Tooltip, TextField } from "@mui/material";
import OZInput from "../../../components/admin/common/OZInput";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import OZDrawer from "../../../components/admin/common/OZDrawer/OZDrawer";
import OZDialog from "../../../components/admin/common/OZDialog";
import OZAutocompletes from "../../../components/admin/common/AutoComplete/OZAutocompleteGroups";
import { addsipLocation, deletesipLocationById, getSipAgentsDropdown, updatesipLocationById,getSipAgentsDropdownWithId } from "../../../services/api-service";
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import "./SIPLocation.css";
import { AdminPages, authPage } from "../../../services/page-service";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";

const DataViewer = (viewProps) => {

    let agentData = viewProps.data.agents || [];

    const SIPLocationServerURLView = (props) => {
        return (
            <div style={{ marginTop: "15px" }}>
                <label className='oz-sipLocation-View-Header'>{props.label}</label>
                {
                    (props.data.length !== 0) ?
                        <label className='oz-sipLocation-View-Value'>{props.data} </label> :
                        <label className='oz-sipLocation-View-Value'> - </label>
                }
            </div>
        );
    };

    const LocationAgentChipView = (props) => {
        return (<div style={{ marginTop: "20px" }}>
            <label className="oz-sipLocation-View-Header">{props.label}</label>
            <Grid item xs={12} sm={12} md={12} lg={12} className="oz-sipLocation-form-content">
                {props.data.map(
                    (keyValue, i) =>
                        <SipLocationAgentsChipLabel value={keyValue[props.optionLabel]} index={i} key={i} />
                )}
            </Grid>
        </div>)
    };

    const SipLocationAgentsChipLabel = (props) => {
        return (
            <Chip key={props.value + props.index} label={props.value}
                sx={{
                    backgroundColor: "#F5F5F6",
                    color: "#536580",
                    padding: '4px',
                    margin: '5px 16px 16px 0px',
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    borderRadius: "16px"
                }} />
        );
    };

    return (
        <div>
            {viewProps?.data?.sipUrl &&
                <SIPLocationServerURLView data={viewProps?.data?.sipUrl} label={"SIP Server URL"} />}
            <LocationAgentChipView data={agentData} optionLabel={'agentName'} label={'Agents *'} />
        </div>
    );
};

const SIPLocationForm = (props) => {

    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add;

    const validationSchema = useMemo(
        () =>
            yup.object({
                location: yup
                    .string("Enter Location Name")
                    .min(3, "Location Name should be between 3 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
                    .max(50, "Location Name should be between 3 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
                    .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "Location Name should be between 3 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
                    .required("Location Name is required."),
                sipUrl: yup
                    .string("Enter SIP Server URL")
                    .min(3, "SIP Server URL should be between 3 to 500 characters.")
                    .max(500, "SIP Server URL should be between 3 to 500 characters.")
                    .matches(/^\S*$/, "SIP Server URL should be between 3 to 500 characters.")
                    .required("SIP Server URL is required."),
                agents: yup.array()
                    .of(yup.object().shape({
                        id: yup.number().required("Agents is required"),
                        agentName: yup.string().required("Agents is required"),
                    }))
                    .required("Agents is required")
                    .min(1, "Agents field must have at least 1 item."),
            }),
        []
    );

    const [anchorMoreVert, setAchorMoreVert] = useState(false);
    const [showSipLocationForm, setSipLocationFormState] = useState(true);
    const [mode, setMode] = useState(initMode);
    const anchorRef = useRef();
    const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [agentsList, setAgentsList] = useState([]);
    const [valid, setValid] = useState(true);
    const [newLoading, setNewLoading] = useState(false);

    const viewModemorevertIcon = () => {
        setTimeout(() => setAchorMoreVert(anchorRef.current), 1);
    };

    const onMenuClose = () => {
        setAchorMoreVert(false);
    };

    const closeSipLocationForm = () => {
        props.onClose();
        setSipLocationFormState(false);
    };

    const onClickEdit = () => {
        setMode(ADMIN_FORM_MODES.edit);
    };

    const onClickDiscard = () => {
        setMode(ADMIN_FORM_MODES.view);
    };

    const onClickDelete = () => {
        setAchorMoreVert(false);
        setDeleteConfirmationDialog(true);
    };

    const onClickDeleteDialogDelete = () => {
        setAchorMoreVert(false);
        deletesipLocation(props.data.id);
        setDeleteConfirmationDialog(false);
    };





    useEffect(() => {
        if(mode === ADMIN_FORM_MODES.add){
            getagentdropdownList();
        }else{
           // alert(props.data.id);
            getagentdropdownListWithId(props.data.id);
        }
    }, []);

    async function addSipLocation(data) {
        try {
            addsipLocation(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeSipLocationForm();
                        props.SIPLocation();
                        showSuccessNotification(`SipLocation ${data.location} Created Successfully`)
                        setNewLoading(false);
                    }
                    else {
                        showWarningNotification(resp.Message.location || resp.Message);
                        setNewLoading(false);
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error adding sipLocation");
                    setNewLoading(false);
                });
        } catch (e) {
            showErrorNotification("Error in sipLocation adding");
            setNewLoading(false);
        }
    };

    async function deletesipLocation(id) {
        try {
            deletesipLocationById(id)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeSipLocationForm();
                        props.SIPLocation();
                        showSuccessNotification(resp.Message);
                    }
                    else {
                        showWarningNotification(resp.Message)
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error deleting sipLocation");
                });
        } catch (e) {
            showErrorNotification("Error in sipLocation deleting");
        }
    };

    async function updatesipLocation(data) {
        try {
            updatesipLocationById(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeSipLocationForm();
                        props.SIPLocation();
                        showSuccessNotification(`SipLocation ${data.location} Updated Successfully`)
                        setNewLoading(false);
                    }
                    else {
                        showWarningNotification(resp.Message.location || resp.Message);
                        setNewLoading(false);
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error updating sipLocation");
                    setNewLoading(false);
                });
        } catch (e) {
            showErrorNotification("Error in sipLocation update");
            setNewLoading(false);
        }
    };

    async function getagentdropdownList() {
        try {
            getSipAgentsDropdown()
                .then((resp) => {
                    setAgentsList(resp);
                })
                .catch((e) => {
                    setAgentsList([]);
                });
        } catch (e) {
            showErrorNotification("Error in agentdropdown list");
        }
    };
    
    
    async function getagentdropdownListWithId(locationId) {
        try {
            setAgentsList([]);
            getSipAgentsDropdownWithId(locationId)
                .then((resp) => {
                    
                    resp.forEach(agent => {
    agent.id = parseInt(agent.id);
});
                    console.log(resp)
                    
                    setAgentsList(resp);
                })
                .catch((e) => {
                    setAgentsList([]);
                });
        } catch (e) {
            showErrorNotification("Error in agentdropdown list");
        }
    };

    const getValidationInViewMode = (e, value) => {
        props.data.location = value;
        const regex = /^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/;
        const isMatch = regex.test(value);

        const digitCount = value.length;

        // Check the conditions for validation
        const isValidLength = value.length >= 3 && value.length <= 50;
        const isValidDigitCount = digitCount >= 3 && digitCount <= 50;

        // Final validation check
        if (isMatch && isValidLength && isValidDigitCount) {
            setValid(true);
        } else {
            setValid(false);
        }
    }

    const styleMenuItemButton = useMemo(
        () => ({
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "8px",
            },
        }), []);

    const DataForm = (props) => {
        let initData = props.data || {
            location: "",
            sipUrl: "",
            agents: [],
        };

        let addModeStyle = useMemo(() => ({
            width: '400px',
            height: "30rem",
            paddingtop: "10px"
        }), []);

        let editModeStyle = useMemo(() => ({
            padding: '5px'
        }), []);

        return (
            <div>
                <Formik
                    initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        let finalData = structuredClone(values)
                        if (mode === ADMIN_FORM_MODES.add) {
                            setNewLoading(true);
                            addSipLocation(finalData);
                        }
                        if (mode !== ADMIN_FORM_MODES.add) {
                            if (valid === true) {
                                setNewLoading(true);
                                updatesipLocation(finalData);
                            }
                        }
                    }}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit}
                            id="sipLocationForm"
                            className="ozcw-scrollbar"
                            style={mode === ADMIN_FORM_MODES.add ? addModeStyle :
                                editModeStyle}
                        > {(mode === ADMIN_FORM_MODES.add || mode === ADMIN_FORM_MODES.edit) &&
                            <Grid container={true} spacing="24px">
                                {mode === ADMIN_FORM_MODES.add &&
                                    <Grid item xs={12} sm={12} md={12} lg={12} >
                                        <OZInput
                                            name="location"
                                            label="Location Name *"
                                            placeholder="Enter Location Name"
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: (mode === ADMIN_FORM_MODES.edit) ? "15px" : "0px" }}>
                                    <OZInput
                                        name="sipUrl"
                                        label="SIP Server URL *"
                                        placeholder="SIP Server URL"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>

                                                <div>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <Stack>
                                                            <label className="agent-label">
                                                                Add Agents *
                                                            </label>
                                                            <OZAutocompletes
                                                                name="agents"
                                                                PlaceHolder={"please select Agents"}
                                                                Datas={agentsList}
                                                                optionLabelName="agentName"
                                                                optionId="id"
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </div>
                                </Grid>
                            </Grid>
                            }
                            {newLoading &&
                                        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                            <LoadingScreen />
                                        </div>
                                    }
                        </form>
                    )}
                </Formik>
                {mode === ADMIN_FORM_MODES.edit &&
                    <div className="oz-sipLocation-drawer-buttons-container">
                        <Button className="oz-sipLocation-cancel-save-button" variant="outlined" onClick={closeSipLocationForm} sx={{ width: "90px" }}>
                            Cancel
                        </Button>
                        <Button className="oz-sipLocation-cancel-save-button" variant="contained" type="submit" form="sipLocationForm" sx={{ marginLeft: "20px", width: "78px" }}>
                            Save
                        </Button>
                    </div>
                }
            </div>
        );
    };
    return (
        <div>
            <div>
                {mode === ADMIN_FORM_MODES.add && (
                    <OZDialog
                        title="Add SIP Location"
                        open={showSipLocationForm}
                        onClose={closeSipLocationForm}
                        saveButtonProps={{
                            form: "sipLocationForm",
                            type: 'submit',
                            label: 'save'
                        }}>
                        {mode === ADMIN_FORM_MODES.add && <DataForm />}
                    </OZDialog>
                )}
            </div>
            {mode !== ADMIN_FORM_MODES.add && (
                <OZDrawer
                    open={showSipLocationForm}
                    title={
                        <div style={{ width: "100%" }}>
                            <div className="oz-sipLocation-drawer-title-container">
                                
                                {mode !== ADMIN_FORM_MODES.edit &&
                                    <Tooltip title={props?.data?.location}>
                                        <Typography sx={{
                                            fontWeight: 600,
                                            fontSize: '18px',
                                            alignSelf: 'center',
                                            // marginLeft: authPage(AdminPages.SIPLOCATIONS_VIEW) ? "0px" : "10px",
                                            marginLeft: !(props?.editAccess) ? "0px" : "10px",
                                            maxWidth: "375px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
                                        }}>
                                            {props?.data?.location}
                                        </Typography></Tooltip>}
                                <div >
                                    <Menu
                                        anchorEl={anchorMoreVert}
                                        open={Boolean(anchorMoreVert)}
                                        onClose={onMenuClose}
                                        sx={{
                                            "& .MuiPaper-root": {
                                                backgroundColor: "white",
                                                width: "133px",
                                            },
                                            "& .MuiMenu-paper": {
                                                backgroundColor: "white",
                                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                                border: "0.0625rem solid #E6E5E6",
                                                borderRadius: "12px",
                                                marginLeft: "-8.9625rem",
                                                marginTop: '-1.5rem'
                                            },
                                        }}
                                    >
                                    </Menu>
                                </div>
                            </div>
                            {openDeleteConfirmationDialog && <DeleteDialog title={"Delete SipLocation"} content={"This will delete this SIPLocation " + props.data.location + " permanently. You cannot undo this action."} onClose={() => { setDeleteConfirmationDialog(false) }}
                                open={true} deleteFunction={onClickDeleteDialogDelete} />}
                            <div>
                                {mode === ADMIN_FORM_MODES.edit &&
                                    <Stack flexDirection={"row"}>
                                        <TextField
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '8px',
                                                    backgroundColor: '#ffff',
                                                    fontSize: '18px',
                                                    color: "#212121",
                                                    height: '44px',
                                                    '&:hover': {
                                                        border: "none"
                                                    },
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #D6D6D7'
                                                },
                                                '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #D6D6D7'
                                                }
                                            }}
                                            defaultValue={props?.data?.location}
                                            fullWidth
                                            // onChange={(e) => { props.data.flowName = e.target.value }}
                                            onChange={(e) => getValidationInViewMode(e, e.target.value)}
                                            error={!valid}
                                            helperText={!valid ? "Location Name should be between 3 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters." : ""}
                                        />

                                        <Typography
                                            size="small"
                                            variant="text"
                                            className="oz-sipLocation-discardButton"
                                            onClick={onClickDiscard}
                                            sx={{ marginTop: "12px" }}
                                        >
                                            Discard
                                        </Typography>
                                        
                                    </Stack>
                                }
                            </div>
                        </div>
                    }
                    onClose={closeSipLocationForm}
                    // hideEditIcon={(authPage(AdminPages.SIPLOCATIONS_VIEW)) ? false : true}
                    hideEditIcon={!(props?.editAccess) || false}
                    onEdit={onClickEdit}
                    mode={mode}
                >
                    {mode !== ADMIN_FORM_MODES.edit && <DataViewer data={props.data} />}
                    {mode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}
                </OZDrawer >
            )}
        </div >
    );
};

export default SIPLocationForm;