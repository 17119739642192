import React, { useState, useMemo, useEffect } from "react";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { Button, Stack, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import LocationForm from "./LocationForm";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./Location.css";
import { getlocation, deletelocationById, getLocationById } from "../../../services/api-service";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";

function Locations() {

  const NoOfSkillsRenderer = (props) => {

    props.data.name = props.data.LocationName;

    const [locationAnchorEl, setLocationAnchorEl] = useState(null);
    const anchorRef = React.useRef();
    const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [openRenameDialog, setRenameDialog] = useState(false);

    const onClickRename = () => {
      setLocationAnchorEl(null);
      setRenameDialog(true);
    };

    const onClickDelete = () => {
      setLocationAnchorEl(null);
      setDeleteConfirmationDialog(true);
    };

    const onMorevertIconClick = () => {
      setTimeout(() => setLocationAnchorEl(anchorRef?.current), 1);
    };

    const onMenuClose = () => {
      setLocationAnchorEl(null);
    };

    const onClickDeleteDialogClose = () => {
      setDeleteConfirmationDialog(false);
    };

    const onClickDeleteDialogDelete = () => {
      deletelocation(props.data.LocationID);
      setDeleteConfirmationDialog(false);
    };

    const styleListItemButton = useMemo(
      () => ({
        "&.MuiButtonBase-root": {
          color: '#212121',
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          height: '16px',
          lineHeight: "14px",
        },
        '&:hover': {
          borderRadius: "4px",
        },
      }),
      []
    );

    return (
      <div className="oz-location-noOfSkill-container">
        <label>{props.value}</label>
        {/* {authPage(AdminPages.LOCATIONS_EDIT) &&
          <img className="locationsMorevertIcon"
            onClick={onMorevertIconClick}
            src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
          >
          </img>
        } */}
        {(newAuthPages(NewAdminPages.LOCATIONS) || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'Full') || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'Edit')) &&
          <img className="locationsMorevertIcon"
            onClick={onMorevertIconClick}
            src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
          >
          </img>
        }
        <div ref={anchorRef}>
          <Menu anchorEl={locationAnchorEl} open={Boolean(locationAnchorEl)} onClose={onMenuClose} sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#FFFFFF",
              width: "133px",
              height: "62px",
            },
            "& .MuiMenu-paper": {
              backgroundColor: "#FFFFFF",
              boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
              border: "0.0625rem solid #E6E5E6",
              borderRadius: "12px",
              marginLeft: "-0.3625rem",
              marginTop: "0rem",
            },
          }}>
            <MenuItem onClick={onClickRename} sx={{ ...styleListItemButton, marginTop: "1px" }}>Rename Location </MenuItem>
            <MenuItem onClick={onClickDelete} sx={{ ...styleListItemButton, marginTop: '9px' }} disabled={!(newAuthPages(NewAdminPages.LOCATIONS) || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'Full'))}>Delete Location</MenuItem>
          </Menu>
          {openDeleteConfirmationDialog &&
            <DeleteDialog title={"Delete Location"} content={" This will delete this " + props.data.LocationName + " location permanently. You cannot undo this action."} onClose={onClickDeleteDialogClose}
              open={true} deleteFunction={onClickDeleteDialogDelete} />
          }
          {openRenameDialog &&
            <LocationForm updateName={getLocationList} onClose={() => { setRenameDialog(false) }} data={props.data} editAccess={(newAuthPages(NewAdminPages.LOCATIONS) || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'Full') || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'Edit'))}/>
          }
        </div>
      </div>
    );
  };

  const [locationForm, setLocationForm] = useState({ isNew: false, show: false });
  const [locationList, setLocationList] = useState([]);
  const [gridRef, setGridRef] = useState(null);
  const [onRowclickLocationData, setOnRowclickLocationData] = useState(null);
  const [loading, setLoading] = useState(false)

  const showLocationForm = () => {
    setLocationForm({ isNew: true, show: true });
  };

  const closeLocationForm = () => {
    setLocationForm({ isNew: false, show: false });
  };

  let buttonStyle = useMemo(
    () => ({
      borderRadius: "8px",
      height: "30px",
      fontSize: "14px",
      fontWeight: 400,
      width: "149px",
      backgroundcolor: "#3D8BF8",
      lineHeight: "16.41px",
    }),
    []
  );

  useEffect(() => {
    getLocationList();
  }, []);

  async function getLocationList() {
    setLoading(true)
    try {
      getlocation()
        .then((resp) => {
          setLocationList(resp.Data);
          setLoading(false)
        })
        .catch((e) => {
          setLocationList([]);
          setLoading(false)
        });
    } catch (e) {
      showErrorNotification("Error in location list");
      setLoading(false);
    }
  };

  async function deletelocation(dataId) {
    try {
      deletelocationById(dataId)
        .then((resp) => {
          if (resp.Status === "Success") {
            getLocationList();
            showSuccessNotification(resp.Message);
          }
          else {
            showWarningNotification(resp.Message);
          }
        })
        .catch((e) => {
          showErrorNotification("Error deleting location");
        });
    } catch (e) {
      showErrorNotification("Error in location delete");
    }
  };

  const columnDefs = [
    {
      field: "LocationName",
      headerName: "Name",
      maxWidth: 350,
    },
    { field: "SkillCount", headerName: "No. of Skills", cellRenderer: NoOfSkillsRenderer },
  ];

  function onCLickRow(rowItem) {
    setOnRowclickLocationData({});
    setLocationForm({ isNew: false, show: true });
    try {
      getLocationById(rowItem.data.LocationID)
        .then((resp) => {
          setOnRowclickLocationData(resp.Data[0]);
        })
        .catch((e) => {
          setOnRowclickLocationData({});
        });
    } catch (e) {
      showErrorNotification("Error in location list ");
    }
  };

  return (
    <div className="ozAdminLocationsContainer">
      <Grid sx={{ marginBottom: "10px" }}>
        <Typography className="oz-page-header"> Configurations </Typography>
      </Grid>
      <Stack direction="row" sx={{ marginBottom: "16px" }} justifyContent="space-between">
        <Typography className="oz-page-heading"> Locations </Typography>
        {/* {authPage(AdminPages.LOCATIONS_EDIT) &&
          <Button
            sx={buttonStyle}
            variant="contained"
            startIcon={<AddBoxOutlinedIcon />}
            onClick={showLocationForm}
          >
            New Location
          </Button>
        } */}
        {(newAuthPages(NewAdminPages.LOCATIONS) || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'Full')) &&
          <Button
            sx={buttonStyle}
            variant="contained"
            startIcon={<AddBoxOutlinedIcon />}
            onClick={showLocationForm}
          >
            New Location
          </Button>
        }
      </Stack>
      {locationForm.show && locationForm.isNew && <LocationForm location={getLocationList} onClose={closeLocationForm} />}
      {locationForm.show && !locationForm.isNew && (
        <LocationForm openDialog={true} updateName={getLocationList} onClose={closeLocationForm} data={onRowclickLocationData} editAccess={(newAuthPages(NewAdminPages.LOCATIONS) || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'Full') || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'Edit'))} />
      )}
      <OZGridToolbar title='List of Locations' searchPlaceholder='Location name' rowCount={locationList.length} dataGridRef={gridRef} fileName='Locations' />
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        (<>
          <OZDataGrid
            rowClickCallback={onCLickRow}
            height="26.3rem"
            data={locationList}
            columns={columnDefs}
            setGridRef={setGridRef} />
        </>
        )
      }
    </div >
  );
};

export default Locations;