import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    Autocomplete,
    Box,
    Chip,
    createFilterOptions,
    Menu,
    TextField,
    Typography,
} from '@mui/material'
import { ReactComponent as SearchIcon } from '../../../static/icons/searchicon.svg'
import { getAgentListForAdmin, getgroups } from '../../../services/api-service'
import { ReactComponent as ChipCloseIcon } from '../../../static/icons/chipclose.svg'
import CustomizedCheckbox from '../../../components/admin/common/CustomCheckBox'
import {
    showErrorNotification,
    showInfoNotification,
    showWarningNotification
} from '../../../components/admin/common/NotiService'

export const DropDownIcon = () => {
    return (
        <div style={{ display: "flex", alignContent: "center", marginTop: "4px", marginRight: "4px", marginLeft: "4px" }}>
            <img
                src={`${process.env.PUBLIC_URL}/icons/downArrowDropDown.svg`}
                alt="edit"
            ></img>
        </div>
    )
};

export default function AgentSelector({
    selectedAgents,
    setSelectedAgents,
    selectedGroups,
    setSelectedGroups,
    selected
}) {

    const [agentGroupsList, setAgentGroupsList] = useState([])
    const [agentsList, setAgentsList] = useState([]);
    const [type, setType] = useState('Agents')
    const [anchorEl, setAnchorEl] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState({
        agents: false,
        groups: false
    })

    const isAllAgentsSelected = selectedAgents.length === agentsList.length;
    const isAllGroupsSelected = selectedGroups.length === agentGroupsList.length;
    const customLimitTags = useMemo(() => {
        if (selected && selected.length && selected.length > 6) {
            return 6;
        } else {
            return selected.length;
        }
    }, [selected])

    const autoCompleteListRef = useRef(null);
    const open = Boolean(anchorEl);
    const types = ['Agents', 'Agent Groups'];

    useEffect(() => {
        getAgents();
        getGroupsList();
    }, []);

    const getAgents = async () => {
        setLoading((prev) => ({ ...prev, agents: true }))
        try {
            const resp = await getAgentListForAdmin();
            if (resp.Status === "Success" && resp.Data && resp.Data.length && resp.Data.length > 0) {
                setAgentsList(resp.Data.reduce((acc, agent) => {
                    acc.push({
                        id: agent.id, type: "Agent", agentName: agent.agentName + " ~ " + agent.agentId, agentId: agent.agentId
                    })
                    return acc;
                }, []))
            } else {
                console.log("getAgents failed ~ resp:", resp)
            }
            setLoading((prev) => ({ ...prev, agents: false }))
        }
        catch (err) {
            console.log(err)
            setLoading((prev) => ({ ...prev, agents: false }))
        }
    }

    const getGroupsList = async () => {
        setLoading((prev) => ({ ...prev, groups: true }))
        try {
            const resp = await getgroups();
            if (resp.Status === "Success" && resp.Data && resp.Data.length && resp.Data.length > 0) {
                setAgentGroupsList(resp.Data.reduce((acc, group) => {
                    acc.push({ GroupName: group.GroupName, id: group.SeqID, type: "Agent Group" })
                    return acc;
                }, []))
            } else {
                console.log("getGroups failed ~ resp:", resp)
            }
            setLoading((prev) => ({ ...prev, groups: false }))
        }
        catch (err) {
            console.log(err)
            setLoading((prev) => ({ ...prev, groups: false }))
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
        setInputValue('');
    };

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight >= scrollHeight) {
            setLimit((prev) => prev + 1);
        }
    }

    const handleSelectAll = () => {
        if (type === 'Agents') {
            if (isAllAgentsSelected) {
                setSelectedAgents([])
            } else {
                setSelectedAgents(agentsList);
            }
        } else {
            if (isAllGroupsSelected) {
                setSelectedGroups([])
            } else {
                setSelectedGroups(agentGroupsList);
            }
        }
    }

    return (
        <Box className="oz-admin-bulk-update-content-body-controls-item">
            <Typography fontSize={"12px"} fontWeight={400} color={"#212121"}>
                Choose agents<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box className='agent-selector' onClick={(e) => { setAnchorEl(e.currentTarget); }}>
                <Box className='agent-selector-selected '>
                    {selected.length > 0 ? selected.map((option, index) => (
                        customLimitTags > index ?
                            <Chip key={option.id || option.GroupName} label={option.agentName || option.GroupName} onDelete={(e) => {
                                if (option.type === "Agent") setSelectedAgents(selectedAgents.filter(agent => agent.id !== option.id));
                                if (option.type === "Agent Group") setSelectedGroups(selectedGroups.filter(group => group.id !== option.id));
                            }} deleteIcon={<ChipCloseIcon />} /> :
                            customLimitTags === index ? <Chip key={option.id || option.GroupName} label={`+${selected.length - customLimitTags}`} /> : null
                    )) : <Typography fontSize={"14px"} fontWeight={400} color={"#3D4C5E"}>Select</Typography>}
                </Box>
                <DropDownIcon />
            </Box>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '8px',
                        marginTop: '10px',
                        width: "inherit",
                        border: "1px solid #D6D9DC",
                        boxShadow: "none",
                        minHeight: "300px",
                        p: 1,
                        pt: 0
                    }
                }}>
                <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <Autocomplete
                        value={type}
                        size={"small"}
                        onChange={(e, newValue) => {
                            setType(newValue || "Agents");
                        }}
                        options={types}
                        popupIcon={<DropDownIcon />}
                        clearIcon={null}
                        onKeyDown={(e) => {
                            e.stopPropagation();
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        fontSize: "14px", color: "#3D4C5E",
                                        borderRadius: '8px 0px 0px 8px',
                                        borderLeft: "none",
                                        minHeight: "40px",
                                    },
                                    minHeight: "40px",
                                }}
                            />
                        )}
                        renderOption={(props, option) => (
                            <Box component="li" {...props} sx={{ color: "#47586E", fontSize: "14px", fontWeight: 500 }}>
                                {option}
                            </Box>
                        )}
                        componentsProps={{
                            paper: { sx: { border: "1px solid #D6D6D7", marginTop: "6px", borderRadius: "8px" } },
                            popupIndicator: {
                                sx: {
                                    transform: "none",
                                    marginRight: "0px",
                                    marginTop: "6px",
                                    padding: "0px",
                                }
                            }
                        }}
                        sx={{
                            width: "165px",
                            height: "40px",
                            fontSize: "14px",
                            color: "#3D4C5E",
                            borderRadius: "8px 0px 0px 8px !important",
                            borderRight: "none !important",
                        }}
                    />
                    <Autocomplete
                        size='small'
                        sx={{ minWidth: "324px", }}
                        multiple
                        value={type === 'Agents' ? selectedAgents : selectedGroups}
                        onChange={(e, value) => {
                            if (value.some(option => option.id === 'select-all')) {
                                handleSelectAll();
                            } else {
                                if (type === 'Agents') setSelectedAgents(value);
                                if (type === 'Agent Groups') setSelectedGroups(value);
                            }
                        }}
                        loading={type === 'Agents' ? loading.agents : loading.groups}
                        loadingText={type === 'Agents' ? 'Loading Agents' : 'Loading Groups'}
                        onKeyDown={(e) => {
                            e.stopPropagation();
                        }}
                        options={[{ id: 'select-all', agentName: 'Select All' }, ...(type === 'Agents' ? agentsList : agentGroupsList)]}
                        popupIcon={<SearchIcon />}
                        clearIcon={null}
                        getOptionLabel={(option) => option.agentName || option.GroupName || ""}
                        disableCloseOnSelect={true}
                        componentsProps={{
                            paper: { sx: { border: "1px solid #D6D6D7", marginTop: "6px", borderRadius: "8px" } },
                            popupIndicator: {
                                sx: {
                                    transform: "none",
                                    marginRight: "0px",
                                    marginTop: "6px",
                                    padding: "0px",
                                }
                            }
                        }}
                        role={"listbox"}
                        ListboxProps={{
                            ref: autoCompleteListRef,
                            onScroll: handleScroll,
                            sx: { maxHeight: "224px" }
                        }}
                        onInputChange={(e, value, reason) => {
                            if (reason === "reset") return;
                            setInputValue(value)
                            if (limit > 10) { setLimit(10) }
                            if (value.includes(',')) {
                                setLoading((prev) => ({ ...prev, agents: true }))
                                try {
                                    const agentIds = value.split(',')
                                    if (type === 'Agents' && agentIds.length > 1) {
                                        let agents = agentIds.map(agentId => agentsList.find(agent => agent.agentId === agentId)).filter(agent => agent)
                                        if (agents.length > 0) {
                                            let alreadySelectedAgents = [];
                                            if (selectedAgents.length > 0) {
                                                alreadySelectedAgents = selectedAgents.map(agent => agent.agentId)
                                            }
                                            agents = agents.filter(agent => !alreadySelectedAgents.includes(agent.agentId))
                                            setSelectedAgents([...selectedAgents, ...agents])
                                            showInfoNotification('Agents added successfully')
                                        } else {
                                            showWarningNotification('No agents found with the given agent id(s)')
                                        }
                                        setInputValue('')
                                    } else {
                                        let valuesWithoutComma = value.replace(/,/g, '')
                                        setInputValue(valuesWithoutComma)
                                    }
                                }
                                catch (err) {
                                    console.log(err);
                                    setLoading((prev) => ({ ...prev, agents: false }))
                                    showErrorNotification('Failed to add multiple agents')
                                }
                            } else {
                                setInputValue(value)
                            }
                        }}
                        inputValue={inputValue}
                        isOptionEqualToValue={(option, value) => option["id"] === value["id"]}
                        filterOptions={
                            (options, params) => {
                                return createFilterOptions({
                                    matchFrom: 'start',
                                    ignoreCase: true,
                                    limit: limit,
                                })(options, params)
                            }
                        }
                        renderOption={(props, option, { selected, inputValue }) => <Box id={option.id || ""} {...props} className="oz-admin-bulk-update-content-body-controls-item-select-option">
                            <CustomizedCheckbox checked={option.id === 'select-all' ? (type === 'Agents' ? isAllAgentsSelected : isAllGroupsSelected) : selected} />
                            <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: "100%", }} fontSize={"14px"} fontWeight={400} color={"#47586E"} textTransform={"capitalize"}>
                                {option.agentName || option.GroupName}
                            </Typography>
                        </Box>}
                        renderInput={(params) => <TextField placeholder='Select'
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    fontSize: "14px", color: "#3D4C5E",
                                    borderRadius: '0px 8px 8px 0px',
                                    borderLeft: "none",
                                    minHeight: "40px"
                                },
                                minHeight: "40px",
                            }}
                            {...params}
                        />}
                        renderTags={(value, getTagProps) => <></>}
                    />
                </Box>
            </Menu>
        </Box>
    )
}