import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Button, Grid, Typography, Divider, Stack } from '@mui/material';
import { FieldArray } from 'formik';
import React, { useMemo } from 'react';
import SimpleSwitch from '../../../components/admin/common/OZSwitch/SimpleSwitch';
import OZInput from '../../../components/admin/common/OZInput';
import OZSelect from '../../../components/admin/common/OZSelect';
import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';
import RemoveIcon from '@mui/icons-material/Remove';
import { ADMIN_FORM_MODES } from '../../../utils/constants';

export default function PostCallWorkConfig(props) {

    let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400, width: '150px' }), []);
    let removeButtonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400, color: '#536580' }), []);


    // function handleChangeDialStatus(index, selected, createNewdialStatuslist, removedOption, deletedialStatusLsit, isRemovedialStatusList) {

    //     let previousSelecteddialStatusLists = structuredClone(props.formikProps.values.retryConditions);
    //     let selecteddialStatus = previousSelecteddialStatusLists.map((retryCondition) => (
    //         retryCondition.dialStatus.map((item) => {
    //             return (item);
    //         })
    //     )).flat(1);

    //     if (selected?.length > 0) {
    //         selecteddialStatus = [selecteddialStatus, selected].flat(1)
    //     }
    //     let newOptions = props.dialStatusData.filter(dialStatus => selecteddialStatus.every(selectedDialStatus => dialStatus.id !== selectedDialStatus.id));
    //     if (createNewdialStatuslist === "removeOption") {
    //         newOptions = [...newOptions, ...removedOption]
    //     }
    //     let localdialStatusListValues = structuredClone(dialListtValues)
    //     if (createNewdialStatuslist) {
    //         localdialStatusListValues = [...localdialStatusListValues, newOptions]
    //     }
    //     let newdialStatuslist = localdialStatusListValues.map((singledialStatusList, subindex) => {
    //         if (index === subindex) {
    //             return singledialStatusList;
    //         }
    //         else {
    //             if (previousSelecteddialStatusLists[subindex] === undefined) {
    //                 return newOptions
    //             } else {
    //                 return [previousSelecteddialStatusLists[subindex].dialStatus, newOptions].flat(1);
    //             }
    //         }
    //     })
    //     if (isRemovedialStatusList) {
    //         newdialStatuslist.splice(index, 1)
    //     }
    //     setDialListValues(newdialStatuslist);
    //     setPreviousSelectedDialStatus(selecteddialStatus);
    // };

    return (
        <>
            <FieldArray name="flowConfigs">
                {(fieldArrayHelpers) =>
                    <>
                        {props?.formikProps?.values?.flowConfigs?.length > 0 && props?.formikProps?.values?.flowConfigs?.map((flowConfig, index) => (
                            <>
                                <div key={index} >
                                    <FieldArray name="caseConfigs">
                                        {(configArrayHelpers) => (
                                            <>
                                                <div style={{ display: 'flex', alignContent: 'center' }}>
                                                    <Grid style={{ fontSize: '12px', marginTop: '7px' }} container spacing={1} display='flex' flexGrow={1} direction={'row'}>
                                                        <Grid item xs={1} sx={{ fontSize: '14px', fontWeight: '600' }}>
                                                            {`Case ${index + 1}`}
                                                        </Grid>
                                                        <Grid item xs={1} style={{ marginRight: '-40px' }}>
                                                            <Divider orientation='vertical' variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginRight: '80px' }} />
                                                        </Grid>
                                                        <Grid item xs={2} style={{ marginRight: '-40px' }}>
                                                            <div><SimpleSwitch disabled={props?.disabled === ADMIN_FORM_MODES.view} flexGrow={1} checked={(flowConfig?.caseConfigs?.filter((item) => item.channelType === 'SMS')?.length > 0)}
                                                                onChange={({ target: { checked } }) => {
                                                                    if (!checked) {
                                                                        if (flowConfig?.caseConfigs?.length === 1) return;
                                                                        let newConfigsForSMS = structuredClone(flowConfig);
                                                                        const caseIndex = flowConfig?.caseConfigs[0].channelType === 'SMS' ? 0 : 1;
                                                                        if (newConfigsForSMS?.caseConfigs?.length === 1) {
                                                                            fieldArrayHelpers.remove(index);
                                                                        } else {
                                                                            newConfigsForSMS.caseConfigs.splice(caseIndex, 1)
                                                                            fieldArrayHelpers.replace(index, newConfigsForSMS)
                                                                        }
                                                                    }
                                                                    else {
                                                                        fieldArrayHelpers.replace(index, {
                                                                            "caseConfigs": [...flowConfig.caseConfigs,
                                                                            {
                                                                                "channelType": "SMS",
                                                                                "parameters": [
                                                                                    {
                                                                                        "type": "Call Duration",
                                                                                        "value": [],
                                                                                        "condition": "",
                                                                                        "operator": ""
                                                                                    },
                                                                                    {
                                                                                        "type": "Disposition",
                                                                                        "value": [],
                                                                                        "condition": "",
                                                                                        "operator": ""
                                                                                    },
                                                                                    {
                                                                                        "type": "Call Status",
                                                                                        "value": [],
                                                                                        "condition": "",
                                                                                        "operator": ""
                                                                                    },
                                                                                ],
                                                                                "template": {
                                                                                    "id": "",
                                                                                    "name": ""
                                                                                },
                                                                                "did": "",
                                                                                "triggerConf": "",
                                                                                "frequency": ""
                                                                            },]
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                                SMS
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={2} style={{ marginRight: '-40px' }}>
                                                            <div><SimpleSwitch disabled={props?.disabled === ADMIN_FORM_MODES.view} flexGrow={1} checked={flowConfig?.caseConfigs?.filter((item) => item.channelType === 'Whatsapp')?.length > 0}
                                                                onChange={({ target: { checked } }) => {
                                                                    if (!checked) {
                                                                        if (flowConfig?.caseConfigs?.length === 1) return;
                                                                        let newConfigsForWhatsapp = structuredClone(flowConfig);
                                                                        const caseIndex = flowConfig?.caseConfigs[0].channelType === 'Whatsapp' ? 0 : 1;
                                                                        if (newConfigsForWhatsapp?.caseConfigs?.length === 1) {
                                                                            fieldArrayHelpers.remove(index);
                                                                        } else {
                                                                            newConfigsForWhatsapp.caseConfigs.splice(caseIndex, 1)
                                                                            fieldArrayHelpers.replace(index, newConfigsForWhatsapp)
                                                                        }
                                                                    }
                                                                    else {
                                                                        fieldArrayHelpers.replace(index, {
                                                                            "caseConfigs": [...flowConfig.caseConfigs,
                                                                            {
                                                                                "channelType": "Whatsapp",
                                                                                "parameters": [
                                                                                    {
                                                                                        "type": "Call Duration",
                                                                                        "value": [],
                                                                                        "condition": "",
                                                                                        "operator": ""
                                                                                    },
                                                                                    {
                                                                                        "type": "Disposition",
                                                                                        "value": [],
                                                                                        "condition": "",
                                                                                        "operator": ""
                                                                                    },
                                                                                    {
                                                                                        "type": "Call Status",
                                                                                        "value": [],
                                                                                        "condition": "",
                                                                                        "operator": ""
                                                                                    },
                                                                                ],
                                                                                "template": {
                                                                                    "id": "",
                                                                                    "name": ""
                                                                                },
                                                                                "did": "",
                                                                                "triggerConf": "",
                                                                                "frequency": ""
                                                                            },]
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                                Whatsapp
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Button
                                                        disabled={props?.disabled === ADMIN_FORM_MODES.view}
                                                        name={(props?.formikProps?.values?.flowConfigs?.length > 1 && index > 0) ? 'removeButton' : 'AddButton'}
                                                        style={{ marginTop: '7px' }}
                                                        sx={(props?.formikProps?.values?.flowConfigs?.length > 1 && index > 0) ? removeButtonStyle : buttonStyle}
                                                        startIcon={(props?.formikProps?.values?.flowConfigs?.length > 1 && index > 0) ? (<img src={`${process.env.PUBLIC_URL}/icons/remove.svg`} alt='remove2'></img>) : <AddBoxOutlinedIcon />}
                                                        onClick={() => {
                                                            if (props?.formikProps?.values?.flowConfigs?.length > 1 && index > 0) {
                                                                fieldArrayHelpers.remove(index)
                                                            }
                                                            else {
                                                                fieldArrayHelpers.push({
                                                                    "caseConfigs": [
                                                                        {
                                                                            "channelType": "SMS",
                                                                            "parameters": [
                                                                                {
                                                                                    "type": "Call Duration",
                                                                                    "value": [],
                                                                                    "condition": "",
                                                                                    "operator": "",
                                                                                },
                                                                                {
                                                                                    "type": "Disposition",
                                                                                    "value": [],
                                                                                    "condition": "",
                                                                                    "operator": "",
                                                                                },
                                                                                {
                                                                                    "type": "Call Status",
                                                                                    "value": [],
                                                                                    "condition": "",
                                                                                    "operator": "",
                                                                                }
                                                                            ],
                                                                            "template": {
                                                                                "id": "",
                                                                                "name": ""
                                                                            },
                                                                            "did": "",
                                                                            "triggerConf": "",
                                                                            "frequency": "",
                                                                        },
                                                                    ]
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        {(props?.formikProps?.values?.flowConfigs?.length > 1 && index > 0) ? '' : 'Another Case'}
                                                    </Button>
                                                </div>

                                                {flowConfig.caseConfigs.length > 0 && flowConfig.caseConfigs.map((item, itemIndex) => (
                                                    <div key={itemIndex}>
                                                        <InnerCasePostComponent formikProps={props?.formikProps} config={item} index={index} configindex={itemIndex} fieldArrayHelpers={fieldArrayHelpers} props={props?.formikProps?.values?.flowConfigs} disabled={props?.disabled} dispositions={props?.dispositonsDropOptions} smsTemplates={props?.smsTemplate} whatsapptemplates={props?.whatsappTemplate} didOptions={props?.didOptions} />
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                            </>
                        ))}
                    </>
                }
            </FieldArray>
        </>
    )
}

const DynamicPostComponent = ({ configindex, index, paramIndex, param, config, fieldArrayHelpers, props, disabled, dispositions, newOptions }) => {

    const handleParamRemove = (props, fieldArrayHelpers, index, configindex, paramIndex) => {
        let newConfigs = structuredClone(props);
        newConfigs[index].caseConfigs[configindex].parameters.splice(paramIndex, 1);

        newConfigs[index].caseConfigs[configindex].parameters.push({
            "type": "",
            "value": [],
            "condition": "",
            "operator": ""
        },)
        fieldArrayHelpers.replace(index,
            newConfigs[index]
        );
    }

    return (
        <>
            <Stack style={{ fontSize: '12px', marginTop: '15px', marginBottom: '25px' }} container spacing={2} display='flex' direction={'column'}>
                <Grid container direction={'row'} xs={12}>
                    <Grid item xs={2.5}>
                        <OZSelect
                            disabled={disabled === ADMIN_FORM_MODES.view}
                            name={`flowConfigs.${index}.caseConfigs.${configindex}.parameters.${paramIndex}.type`}
                            // options={['callStatus', 'disposition', 'duration']}
                            options={newOptions}
                            placeholder="Please Select"
                            leftradius={true}
                        />
                    </Grid>
                    <Grid item xs={4.4}>
                        {param?.type === "Disposition" ?
                            <CustomAutoComplete
                                disabled={disabled === ADMIN_FORM_MODES.view}
                                mode='Add'
                                name={`flowConfigs.${index}.caseConfigs.${configindex}.parameters.${paramIndex}.value`}
                                limitTags={1}
                                placeholder={"Select value"}
                                Datas={dispositions}
                                optionLabelName='reason'
                                showCheckBox={true}
                                multiple={true}
                                showselectall="true"
                                rightradius={true}
                                postCallWork
                            />
                            :
                            param?.type === 'Call Status' ?
                                <OZSelect
                                    disabled={disabled === ADMIN_FORM_MODES.view}
                                    name={`flowConfigs.${index}.caseConfigs.${configindex}.parameters.${paramIndex}.value`}
                                    options={['Answered', 'UnAnswered']}
                                    placeholder="Operator"
                                    rightradius={true}
                                />
                                :
                                param?.type === 'Call Duration' ?
                                    <>
                                        <OZSelect
                                            disabled={disabled === ADMIN_FORM_MODES.view}
                                            name={`flowConfigs.${index}.caseConfigs.${configindex}.parameters.${paramIndex}.value`}
                                            options={[{ id: 'Equal to', operator: '=', value: '' }, { id: 'Greater than', operator: '>', value: '' }, { id: 'Less than', operator: '<', value: '' }, { id: 'Greater than or equal to', operator: '>=', value: '' }, { id: 'Less than or equal to', operator: '<=', value: '' }]}
                                            placeholder="Operator"
                                            optionLabel='id'
                                            prePost
                                            rightradius={true}
                                        />
                                    </>
                                    :
                                    <>
                                        <OZSelect
                                            disabled={disabled === ADMIN_FORM_MODES.view}
                                            name={`flowConfigs.${index}.caseConfigs.${configindex}.parameters.${paramIndex}.value`}
                                            options={['Answered', 'UnAnswered']}
                                            placeholder="Operator"
                                            rightradius={true}
                                        />
                                    </>
                        }
                    </Grid>
                    <Grid item xs={1.7}>
                        <OZSelect
                            disabled={disabled === ADMIN_FORM_MODES.view}
                            name={`flowConfigs.${index}.caseConfigs.${configindex}.parameters.${paramIndex}.operator`}
                            options={['OR', 'AND']}
                            placeholder="Operator"
                            small={true}
                        />
                    </Grid>
                    <Grid item xs={1.5} sx={{ marginLeft: '30px' }}>
                        <Button
                            disabled={disabled === ADMIN_FORM_MODES.view}
                            sx={{ border: '1px solid #D6D6D7', borderRadius: '8px', height: '44px' }}
                            startIcon={<RemoveIcon style={{ marginLeft: '7px' }} color="action" />}
                            onClick={(e) => { handleParamRemove(props, fieldArrayHelpers, index, configindex, paramIndex) }}
                        >
                        </Button>
                        {/* <Button
                            disabled={disabled === ADMIN_FORM_MODES.view}
                            name={(props[index].caseConfigs[configindex].parameters?.length > 1) ? 'removeButton' : 'AddButton'}
                            sx={(props[index].caseConfigs[configindex].parameters?.length > 1) ? removeButtonStyle : buttonStyle}
                            startIcon={(props[index].caseConfigs[configindex].parameters?.length > 1) ? <RemoveIcon style={{ marginLeft: '7px' }} color="action" /> : <AddBoxOutlinedIcon />}
                            // sx={{ border: '1px solid #D6D6D7' }}
                            // startIcon={<RemoveIcon style={{ marginLeft: '7px' }} color="action" />}
                            onClick={(e) => { handleParamRemove(props, fieldArrayHelpers, index, configindex, paramIndex) }}
                        >
                            {(props[index].caseConfigs[configindex].parameters?.length > 1) ? '' : 'Add'}
                        </Button> */}
                        {/* <Button
                            name={(props[index].caseConfigs[configindex].parameters?.length > 1 && paramIndex > 0) ? 'removeButton' : 'anotherButton'}
                            sx={(props[index].caseConfigs[configindex].parameters?.length > 1 && paramIndex > 0) ? removeButtonStyle : buttonStyle}
                            startIcon={(props[index].caseConfigs[configindex].parameters?.length > 1 && paramIndex > 0) ? (<RemoveIcon style={{ marginLeft: '7px' }} color="action" />) : <AddBoxOutlinedIcon />}
                            // onClick={() => {
                            //     if (props[index].caseConfigs[configindex].parameters?.length > 1 && paramIndex > 0) {
                            //         retryConditions.config.splice(paramIndex, 1)
                            //         fieldArrayHelpers.replace(index, {
                            //             dialStatus: retryConditions.dialStatus,
                            //             config: retryConditions.config
                            //         })
                            //     }
                            //     else {
                            //         fieldArrayHelpers.replace(index, {
                            //             dialStatus: retryConditions.dialStatus,
                            //             config: [...retryConditions.config, {
                            //                 intervalDays: 0,
                            //                 intervalHours: 0,
                            //                 intervalMinutes: 0,
                            //             }]
                            //         })
                            //     }
                            // }}
                        >
                            {(props[index].caseConfigs[configindex].parameters?.length > 1 && paramIndex > 0) ? "" : "Another Retry"}
                        </Button> */}
                    </Grid>
                </Grid>
            </Stack>
        </>
    )
}

const InnerCasePostComponent = ({ formikProps, config, configindex, index, fieldArrayHelpers, props, disabled, dispositions, smsTemplates, whatsapptemplates, didOptions }) => {

    let allOptions = ['Call Status', 'Disposition', 'Call Duration'];
    let tempOptionsPresent = structuredClone(props);

    let optionPresent = tempOptionsPresent[index]?.caseConfigs[configindex]?.parameters?.map(item => item.type && item.type);
    const newOptions = allOptions?.filter(filterItem => !optionPresent.includes(filterItem));

    return (
        <div style={{ width: '100%', minHeight: '100px', paddingLeft: '10px', paddingRight: '10px', marginTop: '10px', marginBottom: '20px', backgroundColor: '#FFFFFF', borderRadius: '8px', border: '1px solid #D6D6D7' }}>
            <div style={{ display: 'flex', alignContent: 'center' }}>
                <Typography sx={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '28px',
                    marginTop: '5px',
                }} flexGrow={1} >{config.channelType}</Typography>
                {/* <img
                    src={`${process.env.PUBLIC_URL}/icons/delete-icon.svg`}
                    alt="delete"
                ></img> */}
            </div>

            <div style={{ width: '100%', minHeight: '100px', paddingLeft: '10px', paddingRight: '10px', marginTop: '10px', marginBottom: '20px', backgroundColor: '#FFFFFF', borderRadius: '8px', border: '1px solid #D6D6D7' }}>
                <FieldArray name="parameters">
                    {(paramsArrayHelpers) => (
                        config.parameters.map((param, paramIndex) => (
                            <div key={paramIndex}>
                                <DynamicPostComponent param={param} index={index} configindex={configindex} paramIndex={paramIndex} config={config.parameters} fieldArrayHelpers={fieldArrayHelpers} props={props} disabled={disabled} dispositions={dispositions} newOptions={newOptions} />
                            </div>
                        ))
                    )}
                </FieldArray>
            </div>

            <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginTop: '8px' }} />
            </span>
            <Grid style={{ fontSize: '12px', marginTop: '5px', marginBottom: '25px' }} container spacing={2} display='flex' direction={'row'} >
                <Grid item xs={6} >
                    <OZSelect
                        disabled={disabled === ADMIN_FORM_MODES.view}
                        name={`flowConfigs.${index}.caseConfigs.${configindex}.template`}
                        options={(config.channelType === "SMS") ? smsTemplates : whatsapptemplates}
                        optionLabel='name'
                        label="Select Template"
                        placeholder="Select Template"
                    />
                </Grid>
                <Grid item xs={6} >
                    <OZSelect
                        disabled={disabled === ADMIN_FORM_MODES.view}
                        name={`flowConfigs.${index}.caseConfigs.${configindex}.did`}
                        options={didOptions}
                        optionLabel='DidName'
                        label="Select DID"
                        placeholder="Select DID"
                    />
                </Grid>
                <Grid item xs={6} >
                    <OZSelect
                        disabled={disabled === ADMIN_FORM_MODES.view}
                        name={`flowConfigs.${index}.caseConfigs.${configindex}.triggerConf`}
                        label="Send Post Call Message"
                        options={['Instantly after the call', 'Minutes after the call']}
                        placeholder="Please Select"
                    />
                </Grid>
                {tempOptionsPresent[index]?.caseConfigs[configindex]?.triggerConf !== "Instantly after the call" &&
                    <Grid item xs={6} >
                        <OZInput
                            disabled={disabled === ADMIN_FORM_MODES.view}
                            name={`flowConfigs.${index}.caseConfigs.${configindex}.frequency`}
                            label="Time (minutes)"
                            placeholder="Eg : 5"
                            type='number'
                        />
                    </Grid>
                }
            </Grid>
        </div>
    )
}
