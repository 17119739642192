import { useEffect, useMemo, useState } from "react";
import { Skeleton, Typography, IconButton, TextField } from "@mui/material";
import { ReactComponent as Send } from '../../../../static/icons/send.svg';
import { ReactComponent as SendBlue } from '../../../../static/icons/sendblue.svg';
import { getLoggedInAgentsGroupWise, sendBroadCastMessage } from "../../../../services/api-service";
import { showErrorNotification, showWarningNotification } from "../../../admin/common/NotiService";
import MultiSelect from "../../MultiSelect";
import './footer.css'
import SkillComponentMultiSelect from "../../SkillComponentMultiSelect";

const WidgetFooter = ({ messages, setMessages, getMessages, component, updatedValue, prevSelectedValues, mode }) => {
    const [newMessage, setMessage] = useState("");
    const [loggedInAgentsByGroup, setLoggedInAgentsByGroup] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [loading, setLoading] = useState(false);
    const isLengthExceeded = newMessage.length >= 500;
    const disableSend = useMemo(() => (loading || newMessage.length === 0 || (selectedAgents.to && selectedAgents.to.length === 0) || isLengthExceeded), [loading, newMessage, selectedAgents, isLengthExceeded]);

    // useEffect(() => { getLoggedInAgentsByGroupData() }, [])

    useEffect(() => {
        if(prevSelectedValues && prevSelectedValues.length && component === 'skillList'){
            setLoggedInAgentsByGroup(prevSelectedValues);
        }else if(component !== 'skillList'){
            getLoggedInAgentsByGroupData()
        }
    }, [prevSelectedValues]);

    const setNewUserMessage = (msg) => setMessage(msg);

    const getLoggedInAgentsByGroupData = async () => {
        setLoading(true);
        try {
            const resp = await getLoggedInAgentsGroupWise();
            if (resp.Status === "Success" && resp.Message === "Success") {
                const data = JSON.parse(resp.Data);
                if (Object.keys(data).length === 0) {
                    setLoading(false);
                    return;
                }
                setLoggedInAgentsByGroup(Object.keys(data).map((key) => {
                    let groupName = null;
                    try {
                        groupName = data[key][0].GroupName || key
                    }
                    catch (e) {
                        groupName = key
                    }
                    return {
                        groupName,
                        groupId: key,
                        items: data[key].map((item) => {
                            return {
                                id: item.AgentId || null,
                                label: item.AgentName || null,
                                clientId: item.ClientId || null,
                                Agent_Id: item.Agent_Id || null,
                                checked: false
                            }
                        })
                    }
                }))
            }
            setLoading(false);
        }
        catch (e) {
            console.log("getLoggedInAgentsByGroupData ~ e:", e)
            setLoading(false);
        }
    }

    const onClickSend = async () => {
        setLoading(true);
        var userMsg = newMessage.trim()
        if (userMsg.length === 0 || (selectedAgents.to && selectedAgents.to.length === 0)) return;
        try {
            let buildMessage = {
                message: "",
                agentsJson: {},
                agentId: [],
                agentName: [],
                agentGroupId: [],
                agentGroupName: [],
                everyone: false
            };
            buildMessage.message = userMsg;
            buildMessage.everyone = selectedAgents.options.every(group => group.items.every(item => item.checked));
            buildMessage.agentsJson = {};
            selectedAgents.options.forEach((group) => {
                group.items.forEach((agent) => {
                    if (agent.checked) {
                        buildMessage.agentsJson[agent.label] = {
                            clientId: agent.clientId || null,
                            agentName: agent.label,
                            agentId: agent.Agent_Id
                        }
                    }
                })
            })
            buildMessage.agentsJson = JSON.stringify(buildMessage.agentsJson);
            selectedAgents.options.forEach((group) => {
                const isWholeAgentSelectedFromGroup = group.items.every(item => item.checked);
                const isGroupNameInValid = group.groupName === "NullGroupIdRecords";
                if (isWholeAgentSelectedFromGroup && !isGroupNameInValid) {
                    buildMessage.agentGroupId.push(group.groupId);
                    buildMessage.agentGroupName.push(group.groupName);
                }
                if ((!isWholeAgentSelectedFromGroup) || isGroupNameInValid) {
                    group.items.forEach(item => {
                        if (item.checked) {
                            buildMessage.agentId.push(item.id);
                            buildMessage.agentName.push(item.label);
                        }
                    });
                }
            })
            console.log("onClickSend ~ buildMessage", buildMessage)
            const resp = await sendBroadCastMessage(buildMessage);
            if (resp.Status === "Success" && resp.Message === "Success") {
                getMessages();
                setSelectedAgents([]);
            }
            setLoading(false);
        }
        catch (e) {
            console.log(e)
            setLoading(false);
            showErrorNotification("Failed to send message");
        }
        setNewUserMessage('');
    }

    function extractOnlyAgent(value) {
        if (!value || !value.options) return [];
        const agent = [];

        value?.options?.map((item) => {
            if(item.items && item.items.length){
                item.items.map((subItem) => {
                    if(subItem.checked){
                        agent.push({
                            id: subItem.id,
                            label: subItem.label,
                            agentId: subItem.Agent_Id,
                            groupName:item.groupName,
                            groupId: item.groupId
                        });
                    }
                })
            }
        })
        return agent
    }

    useEffect(() => {
        if(component === 'skillList'){
            let tempAgents = extractOnlyAgent(selectedAgents);
            updatedValue(tempAgents);
        }
    }, [selectedAgents])
    return (
        <div className={component === 'skillList' ? 'ozcw-widget-footer-skillList' : "ozcw-widget-footer"}>
            {component !== 'skillList' &&
                <div className="ozcw-footer-to-container">
                    <Typography className="ozcw-footer-to-text">To: </Typography>
                    <SearchMenu mode={mode} loading={loading} prevSelectedValues={prevSelectedValues} loggedInAgentsByGroup={loggedInAgentsByGroup} selectedAgents={selectedAgents} setSelectedAgents={setSelectedAgents} updatedValue={updatedValue} component={component} />
                </div>
            }
            {component === 'skillList' &&
                // <div style={{borderRadius: '8px', border: '1px solid #D6D6D7', height: '44px', marginTop: '10px'}}>
                    <SearchMenu mode={mode} loading={loading} prevSelectedValues={prevSelectedValues} loggedInAgentsByGroup={loggedInAgentsByGroup} selectedAgents={selectedAgents} setSelectedAgents={setSelectedAgents} updatedValue={updatedValue} component={component} />
                // </div>
            }
            {component !== 'skillList' &&
                <div className="ozcw-widget-footer-input-container">
                    <div className="ozcw-new-message">
                        <TextField
                            type="text"
                            fullWidth
                            className="ozcw-msg-input"
                            placeholder="Type message here..."
                            aria-label="new-msg-input"
                            value={newMessage}
                            error={isLengthExceeded}
                            helperText={isLengthExceeded ? "Message length should not exceed 500 characters" : ""}
                            onInput={e => { (e.target.value.length <= 500) ? setNewUserMessage(e.target.value) : showWarningNotification("Message length should not exceed 500 characters") }}
                            onKeyUp={(e) => e.key === "Enter" && onClickSend()}
                        />
                    </div>
                    <Typography fontSize={10} justifySelf={"flex-end"}>{newMessage.length}/500</Typography>
                    <IconButton disabled={disableSend} color="primary" aria-label="send message" component="span" onClick={onClickSend}>
                        {disableSend ? <Send /> : <SendBlue />}
                    </IconButton>
                </div>
            }
        </div>
    )
}

const SearchMenu = ({ selectedAgents, setSelectedAgents,mode, loggedInAgentsByGroup, loading, component, updatedValue , prevSelectedValues}) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [options, setOptions] = useState(loggedInAgentsByGroup)
    const [searchOptions, setSearchOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => { setOptions(loggedInAgentsByGroup) }, [loggedInAgentsByGroup])

    useEffect(() => {
        const to = options.reduce((acc, group) => {
            const allChecked = group.items.every(item => item.checked);
            if (allChecked && group.groupName !== "NullGroupIdRecords") {
                return [...acc, group.groupName];
            } else {
                const checkedAgents = group.items.filter(item => item.checked).map(item => item.label);
                return [...acc, ...checkedAgents];
            }
        }, []);
        setSelectedAgents({ to: to, options: options });
    }, [options, setSelectedAgents]);

    const handleSearch = (e) => {
        setOpenMenu(true);
        setSearchTerm(e.target.value);
    }

    useEffect(() => {
        if (searchTerm === '') {
            setSearchOptions(options)
        } else {
            const filteredOptions = options.filter(group =>
                group.groupName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                group.items.some(item => item.label.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setSearchOptions(filteredOptions);
        }
    }, [options, searchTerm]);

    useEffect(() => {
        if (!loading) {
            setOpenMenu(false);
            setSearchTerm('');
        }
    }, [loading])

    const isEveryOneSelected = useMemo(() => {
        try {
            if (selectedAgents?.options && selectedAgents?.options?.length > 0) {
                return selectedAgents?.options?.every(group => group?.items?.every(item => item?.checked));
            }
            return false;
        }
        catch (e) {
            // console.log(e)
            return false;
        }
    }, [selectedAgents]);

    return (
        <div style={{ display: component === 'skillList' ? "": "flex", alignItems: component === 'skillList' ? "": "center", overflowX: component === 'skillList' ? 'hidden' : 'auto'}}>
            <>
            {/* before making new componnent */}
            {component === 'skillList' ?
                <>
                    <SkillComponentMultiSelect mode={mode} isEveryOneSelected={isEveryOneSelected} searchOptions={searchOptions} options={options} setOptions={setOptions} selectedAgents={selectedAgents} setSelectedAgents={setSelectedAgents} loggedInAgentsByGroup={loggedInAgentsByGroup}/>
                </>
                :
                <>
                    {openMenu && loggedInAgentsByGroup.length > 0 &&
                        <div className='ozcw-search-menu'>
                            {loading ? <Skeleton variant="rectangular" width={"100%"} height={50} />
                                :
                                loggedInAgentsByGroup.length > 0 ? <MultiSelect isEveryOneSelected={isEveryOneSelected} searchOptions={searchOptions} options={options} setOptions={setOptions} selectedAgents={selectedAgents} setSelectedAgents={setSelectedAgents} loggedInAgentsByGroup={loggedInAgentsByGroup} /> : <Typography>No agents available</Typography>
                            }
                        </div>
                    }
                    {isEveryOneSelected ?
                        <div style={{ display: "flex", alignItems: "center", marginRight: '8px' }}>
                            <Typography color={"#008DFF"} fontSize={"14px"} fontWeight={400}>Everyone</Typography>
                        </div>
                        :
                        (selectedAgents.to && selectedAgents.to.length > 0) && <div className="small-scroll"
                            onClick={() => setOpenMenu((prev) => !prev)}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                                maxWidth: "400px",
                                overflowX: selectedAgents.to.length > 2 ? "auto" : "",
                                marginRight: "8px",
                            }}
                        >
                            {selectedAgents.to.map((agent, index) => (
                                <div key={index} style={{ display: "flex", alignItems: "center", }}>
                                    <Typography color={"#008DFF"} fontSize={"14px"} fontWeight={400}>{agent}</Typography>
                                </div>
                            ))}
                        </div>
                    }
                    <TextField
                        type="text"
                        onClick={() => setOpenMenu((prev) => !prev)}
                        onKeyDown={(e) => { if (e.code === "Escape") { setOpenMenu(false) } }}
                        fullWidth
                        className="ozcw-msg-input-search"
                        placeholder="Search"
                        aria-label="new-msg-search"
                        onChange={handleSearch}
                        value={searchTerm}
                    />
                </>
            }
            {/* before making new componnent */}

            {/* <>
            {openMenu && loggedInAgentsByGroup.length > 0 &&
                <div className={component === 'skillList' && mode === "Create" ? 'ozcw-search-menu-skillList-add' : component === 'skillList' && mode === 'Edit' ? 'ozcw-search-menu-skillList-edit' : 'ozcw-search-menu'}>
                    {loading ? <Skeleton variant="rectangular" width={"100%"} height={50} />
                        :
                        loggedInAgentsByGroup.length > 0 ? <MultiSelect isEveryOneSelected={isEveryOneSelected} searchOptions={searchOptions} options={options} setOptions={setOptions} selectedAgents={selectedAgents} setSelectedAgents={setSelectedAgents} loggedInAgentsByGroup={loggedInAgentsByGroup} /> : <Typography>No agents available</Typography>
                    }
                </div>
            }

                {isEveryOneSelected ?
                    <div style={{ display: "flex", alignItems: "center", marginRight: '8px' }}>
                        <Typography color={"#008DFF"} fontSize={"14px"} fontWeight={400}>Everyone</Typography>
                    </div>
                    :
                    (selectedAgents.to && selectedAgents.to.length > 0) && <div className="small-scroll"
                        onClick={() => setOpenMenu((prev) => !prev)}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            maxWidth: "400px",
                            overflowX: selectedAgents.to.length > 2 ? "auto" : "",
                            marginRight: "8px",
                        }}
                    >
                        {selectedAgents.to.map((agent, index) => (
                            <div key={index} style={{ display: "flex", alignItems: "center", }}>
                                <Typography color={"#008DFF"} fontSize={"14px"} fontWeight={400}>{agent}</Typography>
                            </div>
                        ))}
                    </div>}

                {component === 'skillList' ?
                    <>
                    <TextField
                        type="text"
                        onClick={() => setOpenMenu((prev) => !prev)}
                        onKeyDown={(e) => { if (e.code === "Escape") { setOpenMenu(false) } }}
                        // onBlur={(e) => setTimeout(e => setOpenMenu(false), 150)}
                        fullWidth
                        className="ozcw-msg-input-search"
                        placeholder="Search"
                        aria-label="new-msg-search"
                        onChange={handleSearch}
                        value={searchTerm}
                    />
                    </>
                    :
                    <>
                    <TextField
                        type="text"
                        onClick={() => setOpenMenu((prev) => !prev)}
                        onKeyDown={(e) => { if (e.code === "Escape") { setOpenMenu(false) } }}
                        fullWidth
                        className="ozcw-msg-input-search"
                        placeholder="Search"
                        aria-label="new-msg-search"
                        onChange={handleSearch}
                        value={searchTerm}
                    />
                    </>
                }
                </> */}
            </>
        </div>
    )
}

export default WidgetFooter;