import { Grid, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/PauseRounded';
import WaveSurfer from 'wavesurfer.js';
import { ReactComponent as DownloadIcon } from '../../static/icons/only-download.svg';
import '../AudioPlayer/AudioPlayer.css';
import { checkRoles } from '../../utils/util';
import { getSecuredRecordingUrl } from '../../services/api-service';
type Props = {
	url?: string;
	idx: number;
};

const AUDIO_EVENT_NAME = 'PLAY_AUDIO';

const AudioPlayer = ({ url, idx }: Props) => {
	const index = idx >= 0 ? idx : 1;
	// const demoURL: string = 'https://www.mfiles.co.uk/mp3-downloads/gs-cd-track2.mp3';
	//const finalUrl: string | undefined = url;
	const [finalUrl, setFinalUrl] = useState<string | undefined>(url);
	const secureAudioRecordingURL = async (url: string) => {
		const response = await getSecuredRecordingUrl({audioUrl: url});
		if(response?.data)
			setFinalUrl(response.data);
		return response?.data || url;
	}
	const [playing, setPlaying] = useState(false);
	const [waveformState, setWaveformState] = useState<WaveSurfer | null>(null);
	const waveEleRef = useRef<HTMLDivElement | null>(null);
	const generateWaveform = (withPlay = false, newUrl = url) => {
		if (!waveEleRef.current || !newUrl?.length) return;
		if (waveEleRef.current.children.length) {
			for (let i = 0; i < waveEleRef.current.children.length; i++) {
				waveEleRef.current?.removeChild(waveEleRef.current.children[i]);
			}
		}
		const waveform = WaveSurfer.create({
			container: waveEleRef.current,
			backend: 'MediaElement',
			height: 25,
			progressColor: '#FFFFFF',
			responsive: true,
			waveColor: '#FFFFFF4D',
			cursorColor: 'transparent'
		});
		waveform.load(newUrl);
		// if (withPlay) {
		// 	setTimeout(() => {
		// 		waveform.playPause();
		// 		setPlaying(waveform?.isReady);
		// 	}, 500);
		// }
		waveform.on('ready', function () {
			waveform.playPause();
			setPlaying(true);
		})
		waveform.on('finish', function () {
			setPlaying(false);
		});
		setWaveformState(waveform);
	};
	const handlePlay = () => {
		if(finalUrl && (!waveformState) && checkRoles("ROLE_SECURE_RECORDING")){
			secureAudioRecordingURL(finalUrl).then((url)=>{
				generateWaveform(false, url);
			}).catch((e)=>{
				console.error(e);
			})
		}else{
			if (!waveformState) {
				generateWaveform(false, finalUrl);
			} else if (waveformState.isReady) {
				waveformState.playPause();
				setPlaying(!playing);
			}
		}
	};

	const handleDownload = () => {
		if(finalUrl && (finalUrl==url) && checkRoles("ROLE_SECURE_RECORDING")){
			secureAudioRecordingURL(finalUrl).then((url)=>{
				window.open(url, '_blank');
			}).catch((e)=>{
				console.error(e);
			});
		}else{
			window.open(finalUrl, '_blank');
		}
	};

	useEffect(() => {
		waveformState?.destroy();
		setWaveformState(null);
		setFinalUrl(url);
	}, [url]);

	useEffect(() => {
		return () => {
			waveformState?.destroy();
		};
	}, []);

	useEffect(() => {
		const playAudioListener: (event: any) => void = (event: any) => {
			if (event?.detail?.idx !== idx) {
				if (waveformState?.isReady && waveformState?.isPlaying()) {
					waveformState.pause();
					setPlaying(false);
				}
			}
		};
		if (playing) {
			window.addEventListener(AUDIO_EVENT_NAME, playAudioListener);
			const newPlayAudioEvent = new CustomEvent(AUDIO_EVENT_NAME, { detail: { idx } });
			window.dispatchEvent(newPlayAudioEvent);
		} else {
			window.removeEventListener(AUDIO_EVENT_NAME, playAudioListener);
		}
		return () => {
			window.removeEventListener(AUDIO_EVENT_NAME, playAudioListener);
		};
	}, [playing]);

	return (
		<Grid container minWidth={'200px'} direction={'row'} alignItems="center">
			<Grid item xs>
				<Grid container direction={'row'} className="audio-player-container" alignItems="center">
					<Grid item xs={2}>
						<IconButton aria-label="play" size="small" onClick={handlePlay} className="play-icon-color" disabled={!finalUrl?.length}>
							{playing ? <PauseIcon style={{ color: 'white' }} /> : <PlayArrowIcon />}
						</IconButton>
					</Grid>
					<Grid item xs={9.5}>
						<div id={'waveform-' + index} className="waveform-container" ref={waveEleRef}></div>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={1} md={1}>
				<IconButton onClick={handleDownload} disabled={!finalUrl?.length}>
					<DownloadIcon fontSize="small" />
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default AudioPlayer;
