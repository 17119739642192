import * as React from 'react';

export default function WidgetGraph() {
	return (
		<>
			<div className="card-icon">
				<img src={`${process.env.PUBLIC_URL}/icons/graph-icon.svg`} width="16" alt="Graph view"></img>
			</div>
		</>
	);
}
