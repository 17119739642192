import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import { Badge, Box, Card, Chip, Grid, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { getDropRatio } from '../../../services/api-service';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import '../HourlyCallSummary/HourlyCallSummary.css';

const useStyles: any = makeStyles((theme: Theme) => ({
	boundButton: {
		padding: '5px !important',
		minWidth: 50
	},
	filterItem: {
		fontSize: 12,
		height: 36,
		borderRadius: 20,
		background: 'rgba(0, 142, 255, 0.1)',
		border: '1px solid #008eff',
		padding: `0 ${theme.spacing(2)}`
	},
	itemIndicator: {
		width: 5,
		height: 5,
		borderRadius: 1,
		display: 'inline-block',
		position: 'relative',
		top: '-2px',
		marginRight: 4
	},
	filterItemText: {
		margin: 0,
		color: '#536580'
	},
	filterItemSubText: {
		color: '#99A0A8'
	}
}));


let interval: any;

function DropRatio(props: any) {
	const classes = useStyles();
	const { useState, useRef } = React;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [graphData, setGraphData] = useState<any>([]);
	const [dropRatioFilter, setDropRatioFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const [filterCategory, setFilterCategory] = useState<any>(null);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || '1');
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [responseLength, setresponseLength] = useState<Number>(0);
	const downloadContent = useRef<null | any>(null);
	const [campaignName, setCampaignName] = useState<any>([]);

	const chartElement: any = useRef(null);

	const clearFilter = () => {
		setDropRatioFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = dropRatioFilter.filter((item: any) => item.id !== id);
		setDropRatioFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const filterItems = dropRatioFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const formatGraphData = (data: any) => {
		const graphData: any = {};
		return { graphData };
	};

	const filterHourlyData = (data: any[]) => {
		let filteredItems = data;
		if (dropRatioFilter?.length && filterCategory) {
			const key = `${filterCategory.value}Id`;
			const filterIds = dropRatioFilter.map((filterItem: any) => +filterItem.Id);
			filteredItems = filteredItems.filter((item) => filterIds.includes(item[key]));
		} else {
			filteredItems = filteredItems.filter((item) => item.CampaignId === 0);
		}
		return filteredItems;
	};

	const retriveGraphData = async (filters: any) => {
		const apiResponse: any = await getDropRatio({
			campaignId: filters
		});

		const expectedDropRation = apiResponse.map((item: any) => ({
			y: +item.ExpectedDropRatio,
			...item
		}));
		const currentDropRation = apiResponse.map((item: any) => ({
			y: +item.currentDropRatio,
			...item
		}));
		const campaignName = apiResponse.map((item: any) => item.campaignName);
		setCampaignName(campaignName);
		const graphOptions = [
			{
				name: 'Current',
				color: '#4C4C4C',
				type: 'column',
				data: currentDropRation
			},
			{
				name: 'Expected',
				type: 'column',
				color: '#008DFF',
				data: expectedDropRation
			}
		];
		const filteredData = filterHourlyData(apiResponse);
		const { graphData: formattedGraphData } = formatGraphData(filteredData);
		setGraphData(graphOptions);
		chartElement?.current?.chart?.redraw();
	};

	const handlePointClick = (point: any) => {
		// console.log('Clicked point', point);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const options = {
		chart: {
			height: 350,
		},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			labels: {
				rotation: -45,
				style: {
					fontSize: '12px',
					fontFamily: 'Roboto'
				}
			},
			title: {
				text: 'Campaigns ➡',
				style: {
					fontWeight: 'bold'
				}
			},
			categories: campaignName,
			crosshair: true
		},
		yAxis: {
			title: {
				text: 'Drop Ratio  %',
				style: { fontWeight: 'bold' }
			}
		},
		legend: {
			layout: 'horizontal',
			align: 'center',
			verticalAlign: 'top',
			itemMarginTop: 10
		},
		tooltip: {
			formatter(this: any): any {
				return `${this.y}`;
			}
		},
		plotOptions: {
			series: {
				pointWidth: 10,
				groupPadding: 0.4,
				point: {
					events: {
						click: function ({ point }: any) {
							handlePointClick(point);
						}
					}
				}
			}
		},
		series: graphData
	};

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		const filterIds = (dropRatioFilter.length && !(responseLength === dropRatioFilter.length)) ? dropRatioFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = dropRatioFilter.length ? dropRatioFilter.map((item: any) => item.id).join(',') : '0';
		retriveGraphData(filterIds);
		if (interval) {
			clearInterval(interval);
		}

		interval = setInterval(() => retriveGraphData(filterIds), WIDGET_REFRESH_INTERVAL.DROP_RATIO);
	}, [dropRatioFilter]);

	const handleFilterExistingData = (filteredData: any) => {
		setDropRatioFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setDropRatioFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	return (
		<>
			<Card ref={downloadContent} className="customCard">
				<div className="card-header">
					<div className="card-title">
						Drop Ratio<span className="card-sub-header">(Predictive)</span>
						<span className="card-title-badge">Live</span>
					</div>
					<div className="card-actions">
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={'Drop Ratio'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={dropRatioFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
					</div>
				</div>
				<Box sx={{ pt: 2, pl: 2 }}>
					{dropRatioFilter.length > 0 && (
						<div className="filter-outer">
							<div className="filter-container">
								<Stack direction="row" spacing={1}>
									{filterItems}
								</Stack>
							</div>
							<div className="filter-remove" onClick={clearFilter}>
								Remove
							</div>
						</div>
					)}
				</Box>

				<HighchartsReact ref={chartElement} highcharts={Highcharts} options={options} />
			</Card>
			<FilterDrawer
				isOpen={isDrawerOpen}
				toggleDrawer={toggleDrawer}
				applyFilters={handleApplyFilters}
				existingSelectedFilters={dropRatioFilter}
				allowedFilters={['Campaign']}
				additionalApiParams={{ campaignType: 'Predictive' }}
				setResponseLength={setresponseLength}
				existingFilterCategory={filterType} 
				applyFilterOnExistingData={handleFilterExistingData}
			/>
		</>
	);
}
export default DropRatio;
