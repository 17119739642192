import * as React from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { ReactComponent as FileDownloadOutlinedIcon } from '../../static/icons/download.svg';
import { Box, Menu, MenuItem } from '@mui/material';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import moment from 'moment';

import '../Widget-Download/Widget-Download.css';
import { exportToXLSX } from '../../utils/csv-export';
import { DateTimeFormat } from '../../utils/properties';

type Props = {
	element?: any;
	handleDownload?: any;
	name?: any;
	Wtype?: any;
	duration?: any;
	rowData?: any;
	columns?: any;
	xlsx? : any;
};

export default function WidgetDownload({ element, handleDownload , name, Wtype, duration, rowData, columns,xlsx}: Props) {
	const { useState } = React;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const downloadImage = (imageData: string, nName: any) => {
		const fakeLink: any = document.createElement('a');
		fakeLink.style = 'display:none;';
		if(Wtype === 'historic'){
			fakeLink.download = nName ? `${nName}(${moment(duration?.fromTime).format(DateTimeFormat)} - ${moment(duration?.toTime).format(DateTimeFormat)}).jpg` : 'widget-image.jpg';
		}else{
			fakeLink.download = nName ? `${nName}(${moment().format(DateTimeFormat)}).jpg` : 'widget-image.jpg';
		}
		fakeLink.href = imageData;
		document.body.appendChild(fakeLink);
		fakeLink.click();
		document.body.removeChild(fakeLink);
		fakeLink.remove();
	};
	const downloadPDF = (imageData: string, width: number, height: number, nName: any) => {
		let N : any = '';
		if(Wtype === 'historic'){
			N = nName ? `${nName}(${moment(duration?.fromTime).format(DateTimeFormat)} - ${moment(duration?.toTime).format(DateTimeFormat)}).pdf` : 'widget-pdf.pdf';
		}else{
			N = nName ? `${nName}(${moment().format(DateTimeFormat)}).pdf` : 'widget-pdf.pdf';
		}
		const pdf = new jsPDF({
			orientation: 'l',
			unit: 'pt',
			format: [width + 24, height + 24]
		});
		pdf.addImage(imageData, 'JPEG', 12, 12, width, height);
		pdf.save(N);
	};

	const downloadXLS = (rowData: any,columns: any, nName: any) => {
		let N : any = '';
		let columnObj: any = {};
		if(xlsx){
			columns.forEach((column: any) => {
				columnObj[column?.id ? column?.id : column?.key] = column?.label ? column?.label : column?.name;
				// columnObj[column.id] = column.label;
			});
		}else{
			columns.forEach((column: any) => {
				columnObj[column.id ? column.id : column.key] = column.id ? column.id : column.key;
			});
		}
		if(Wtype === 'historic'){
			N = nName ? `${nName}(${moment(duration?.fromTime).format(DateTimeFormat)} - ${moment(duration?.toTime).format(DateTimeFormat)}).xlsx` : 'export';
		}else{
			N = nName ? `${nName}(${moment().format(DateTimeFormat)}).xlsx` : 'export';
		}
		if(xlsx){
			columnObj = Object.fromEntries(Object.entries(columnObj).filter(([_, value]) => value !== undefined && value !== ''));
		}
		exportToXLSX(columnObj, rowData, N);
	};

	const handleClose = async (type: string = '', name: any = '') => {
		setAnchorEl(null);
		if (!type) return;
		if (!element?.current) return;
		const canvas = await html2canvas(element.current);
		const width = canvas.width;
		const height = canvas.height;
		const imgData = canvas.toDataURL('image/jpg');
		if (type === 'JPG') {
			downloadImage(imgData, name);
		} else if (type === 'PDF') {
			downloadPDF(imgData, width, height, name);
		}else if(type === 'XLS'){
			downloadXLS(rowData, columns, name);
		}
		handleDownload(type);
	};

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	return (
		<>
			<Box onClick={handleMenuOpen} sx={{ display: 'flex', alignItems: 'center' }}>
				<FileDownloadOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
				<KeyboardArrowDownOutlinedIcon style={{ color: '#536580', fontSize: '10px' }} />
			</Box>
			<Menu
				id="download-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				open={open}
				onClose={() => handleClose()}
				className="custom-dropdown"
			>
				<MenuItem className="download-item" onClick={() => handleClose('PDF', name)}>
					<img src={`${process.env.PUBLIC_URL}/img/download-pdf-icon.svg`} width="18" alt="Download PDF Icon"></img> as PDF
				</MenuItem>
				<MenuItem className="download-item" onClick={() => handleClose('JPG', name)}>
					<img src={`${process.env.PUBLIC_URL}/img/download-jpg-icon.svg`} width="18" alt="Download JPG Icon"></img> as JPG
				</MenuItem>
				{(xlsx) &&
					<MenuItem className="xls-download-item" onClick={() => handleClose('XLS', name)}>
						<img src={`${process.env.PUBLIC_URL}/icons/table-view.svg`} width="30" alt="Download XLS Icon"></img> as XLS
					</MenuItem>
				}
			</Menu>
		</>
	);
}
