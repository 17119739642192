import UserPlaceholder1 from '../static/icons/user-placeholder-1.png';
import UserPlaceholder2 from '../static/icons/user-placeholder-2.png';

export const SLA_MOCK = [
	{
		UnansweredCalls: '0',
		SkillName: 'General',
		TotalCalls: '8',
		SNO: 1,
		AverageHandlingTime: '54',
		CallHourTs: '2022-07-20 12:00:00',
		CallHour: '12',
		SkillId: '36830',
		AverageSpeedToAnswer: '29',
		SLAPercentage: '50.00',
		AnsweredCalls: '8'
	},
	{
		UnansweredCalls: '2',
		SkillName: 'General',
		TotalCalls: '6',
		SNO: 2,
		AverageHandlingTime: '34',
		CallHourTs: '2022-07-20 13:00:00',
		CallHour: '13',
		SkillId: '36830',
		AverageSpeedToAnswer: '19',
		SLAPercentage: '50.00',
		AnsweredCalls: '4'
	},
	{
		UnansweredCalls: '1',
		SkillName: 'Resource',
		TotalCalls: '3',
		SNO: 3,
		AverageHandlingTime: '36',
		CallHourTs: '2022-07-20 13:00:00',
		CallHour: '13',
		SkillId: '36832',
		AverageSpeedToAnswer: '23',
		SLAPercentage: '50.00',
		AnsweredCalls: '2'
	},
	{
		UnansweredCalls: '0',
		SkillName: 'Preview1',
		TotalCalls: '1',
		SNO: 4,
		AverageHandlingTime: '20',
		CallHourTs: '2022-07-20 12:00:00',
		CallHour: '12',
		SkillId: '36833',
		AverageSpeedToAnswer: '7',
		SLAPercentage: '100.00',
		AnsweredCalls: '1'
	},
	{
		UnansweredCalls: '0',
		SkillName: 'Preview1',
		TotalCalls: '1',
		SNO: 5,
		AverageHandlingTime: '35',
		CallHourTs: '2022-07-20 13:00:00',
		CallHour: '13',
		SkillId: '36833',
		AverageSpeedToAnswer: '27',
		SLAPercentage: '0.00',
		AnsweredCalls: '1'
	},
	{
		UnansweredCalls: '0',
		SkillName: 'Resource',
		TotalCalls: '3',
		SNO: 6,
		AverageHandlingTime: '30',
		CallHourTs: '2022-07-20 12:00:00',
		CallHour: '12',
		SkillId: '36832',
		AverageSpeedToAnswer: '11',
		SLAPercentage: '100.00',
		AnsweredCalls: '3'
	},
	{
		UnansweredCalls: '0',
		SkillName: '0',
		TotalCalls: '12',
		SNO: 7,
		AverageHandlingTime: '45',
		CallHourTs: '2022-07-20 12:00:00',
		CallHour: '12',
		SkillId: '0',
		AverageSpeedToAnswer: '23',
		SLAPercentage: '66.67',
		AnsweredCalls: '12'
	},
	{
		UnansweredCalls: '3',
		SkillName: '0',
		TotalCalls: '10',
		SNO: 8,
		AverageHandlingTime: '35',
		CallHourTs: '2022-07-20 13:00:00',
		CallHour: '13',
		SkillId: '0',
		AverageSpeedToAnswer: '21',
		SLAPercentage: '42.86',
		AnsweredCalls: '7'
	}
];

export const CALL_STATUS_MOCK = [
	{
		CallType: 'inbound',
		CampaignName: 'NewTFN_3',
		TotalCalls: 1,
		AnsweredCalls: 1,
		UnansweredCalls: 0,
		AverageHandlingTime: 122,
		AverageAnswerTime: 17,
		AverageTalkTime: 74,
		AverageWrapupTime: 31,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 17,
		SLAPercentage: 100
	},
	{
		CallType: 'inbound',
		CampaignName: 'New_TFN1.1',
		TotalCalls: 13,
		AnsweredCalls: 13,
		UnansweredCalls: 0,
		AverageHandlingTime: 277,
		AverageAnswerTime: 33,
		AverageTalkTime: 213,
		AverageWrapupTime: 30,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 33,
		SLAPercentage: 61.54
	},
	{
		CallType: 'inbound',
		CampaignName: 'New_TFN1.2',
		TotalCalls: 2,
		AnsweredCalls: 2,
		UnansweredCalls: 0,
		AverageHandlingTime: 121,
		AverageAnswerTime: 14,
		AverageTalkTime: 77,
		AverageWrapupTime: 31,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 14,
		SLAPercentage: 100
	},
	{
		CallType: 'manual',
		CampaignName: 'Inside_sales',
		TotalCalls: 1,
		AnsweredCalls: 1,
		UnansweredCalls: 0,
		AverageHandlingTime: 91,
		AverageAnswerTime: 11,
		AverageTalkTime: 49,
		AverageWrapupTime: 31,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 11,
		SLAPercentage: 100
	},
	{
		CallType: 'manual',
		CampaignName: 'LSQ_support_Airtel',
		TotalCalls: 1,
		AnsweredCalls: 0,
		UnansweredCalls: 1,
		AverageHandlingTime: 0,
		AverageAnswerTime: 0,
		AverageTalkTime: 0,
		AverageWrapupTime: 0,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 0,
		SLAPercentage: 0
	},
	{
		CallType: 'manual',
		CampaignName: 'NewTFN_2',
		TotalCalls: 6,
		AnsweredCalls: 3,
		UnansweredCalls: 3,
		AverageHandlingTime: 155,
		AverageAnswerTime: 17,
		AverageTalkTime: 117,
		AverageWrapupTime: 21,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 17,
		SLAPercentage: 100
	},
	{
		CallType: 'manual',
		CampaignName: 'New_TFN1.1',
		TotalCalls: 20,
		AnsweredCalls: 9,
		UnansweredCalls: 11,
		AverageHandlingTime: 94,
		AverageAnswerTime: 16,
		AverageTalkTime: 59,
		AverageWrapupTime: 19,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 16,
		SLAPercentage: 77.78
	},
	{
		CallType: 'manual',
		CampaignName: 'New_TFN1.2',
		TotalCalls: 7,
		AnsweredCalls: 4,
		UnansweredCalls: 3,
		AverageHandlingTime: 109,
		AverageAnswerTime: 17,
		AverageTalkTime: 63,
		AverageWrapupTime: 30,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 17,
		SLAPercentage: 75
	},
	{
		CallType: 'manual',
		CampaignName: 'New_TFN1.3',
		TotalCalls: 7,
		AnsweredCalls: 3,
		UnansweredCalls: 4,
		AverageHandlingTime: 36,
		AverageAnswerTime: 11,
		AverageTalkTime: 12,
		AverageWrapupTime: 13,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 11,
		SLAPercentage: 100
	},
	{
		CallType: 'manual',
		CampaignName: 'New_TFN1_backup',
		TotalCalls: 1,
		AnsweredCalls: 1,
		UnansweredCalls: 0,
		AverageHandlingTime: 54,
		AverageAnswerTime: 10,
		AverageTalkTime: 14,
		AverageWrapupTime: 30,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 10,
		SLAPercentage: 100
	},
	{
		CallType: 'manual',
		CampaignName: 'New_TFN2_backup',
		TotalCalls: 1,
		AnsweredCalls: 0,
		UnansweredCalls: 1,
		AverageHandlingTime: 0,
		AverageAnswerTime: 0,
		AverageTalkTime: 0,
		AverageWrapupTime: 0,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 0,
		SLAPercentage: 0
	},
	{
		CallType: 'manual',
		CampaignName: 'Ozonetel_Tollfree_Backup',
		TotalCalls: 2,
		AnsweredCalls: 1,
		UnansweredCalls: 1,
		AverageHandlingTime: 60,
		AverageAnswerTime: 9,
		AverageTalkTime: 20,
		AverageWrapupTime: 31,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 9,
		SLAPercentage: 100
	},
	{
		CallType: 'inbound',
		CampaignName: '0',
		TotalCalls: 16,
		AnsweredCalls: 16,
		UnansweredCalls: 0,
		AverageHandlingTime: 247,
		AverageAnswerTime: 30,
		AverageTalkTime: 187,
		AverageWrapupTime: 30,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 30,
		SLAPercentage: 68.75
	},
	{
		CallType: 'manual',
		CampaignName: '0',
		TotalCalls: 46,
		AnsweredCalls: 22,
		UnansweredCalls: 24,
		AverageHandlingTime: 94,
		AverageAnswerTime: 15,
		AverageTalkTime: 57,
		AverageWrapupTime: 22,
		AverageHoldTime: 0,
		AverageSpeedToAnswer: 15,
		SLAPercentage: 86.36
	}
];
export const CALL_STATUS_SINGLE_MOCK = [
	{
		AverageHandlingTime: '8:14',
		avgHandlingTimeUpdate: '+3.20%',
		AverageTalkTime: '6:39',
		AverageHoldTime: '0:31',
		AverageWrapupTime: '6:39',
		AverageAnswerTime: '8:14',
		avgAnswerTimeUpdate: '+3.20%',
		TotalCalls: '121',
		AnsweredCalls: '66',
		UnansweredCalls: '30',
		activeCalls: '25'
	}
];
export const QUEUE_COUNTER_MOCK: any = [
	{
		SkillID: 1807,
		SkillName: 'cloudAgent_support',
		QueueCount: 13,
		LongestWaitTime: 30,
		AgentsAvailable: 9
	},
	{
		SkillID: 2730,
		SkillName: 'kookoo_support',
		QueueCount: 3,
		LongestWaitTime: 5,
		AgentsAvailable: 2
	},
	{
		SkillID: 24957,
		SkillName: 'sales_support',
		QueueCount: 5,
		LongestWaitTime: 56,
		AgentsAvailable: 1
	}
];

export const SKILL_SUMMARY_MOCK: any = [
	{
		SkillName: 'General, Preview1, sticky',
		TotalCalls: '36',
		CallsPerAgent: '0',
		LongestCallQueueTime: '65',
		SNO: 1,
		AverageHandlingTime: '56',
		SkillId: '36830',
		AverageSpeedToAnswer: '16',
		SLAPercentage: '66.67',
		TotalAgents: '148',
		Answered: '30',
		Abandoned: '6'
	},
	{
		SkillName: 'sticky',
		TotalCalls: '4',
		CallsPerAgent: '0',
		LongestCallQueueTime: '81',
		SNO: 2,
		AverageHandlingTime: '0',
		SkillId: '36831',
		AverageSpeedToAnswer: '0',
		SLAPercentage: '0.00',
		TotalAgents: '20',
		Answered: '0',
		Abandoned: '4'
	},
	{
		SkillName: 'Resource',
		TotalCalls: '0',
		CallsPerAgent: '0',
		LongestCallQueueTime: '0',
		SNO: 3,
		AverageHandlingTime: '0',
		SkillId: '36832',
		AverageSpeedToAnswer: '0',
		SLAPercentage: '0.00',
		TotalAgents: '7',
		Answered: '0',
		Abandoned: '0'
	},
	{
		SkillName: 'Preview1',
		TotalCalls: '22',
		CallsPerAgent: '1',
		LongestCallQueueTime: '0',
		SNO: 4,
		AverageHandlingTime: '56',
		SkillId: '36833',
		AverageSpeedToAnswer: '15',
		SLAPercentage: '76.47',
		TotalAgents: '30',
		Answered: '17',
		Abandoned: '5'
	},
	{
		SkillName: 'Preview2',
		TotalCalls: '11',
		CallsPerAgent: '1',
		LongestCallQueueTime: '0',
		SNO: 5,
		AverageHandlingTime: '66',
		SkillId: '36834',
		AverageSpeedToAnswer: '19',
		SLAPercentage: '62.50',
		TotalAgents: '10',
		Answered: '8',
		Abandoned: '3'
	},
	{
		SkillName: 'hari_skill, Sales',
		TotalCalls: '0',
		CallsPerAgent: '0',
		LongestCallQueueTime: '0',
		SNO: 6,
		AverageHandlingTime: '0',
		SkillId: '36835',
		AverageSpeedToAnswer: '0',
		SLAPercentage: '0.00',
		TotalAgents: '3',
		Answered: '0',
		Abandoned: '0'
	},
	{
		SkillName: 'QA_Salman',
		TotalCalls: '0',
		CallsPerAgent: '0',
		LongestCallQueueTime: '0',
		SNO: 7,
		AverageHandlingTime: '0',
		SkillId: '36836',
		AverageSpeedToAnswer: '0',
		SLAPercentage: '0.00',
		TotalAgents: '17',
		Answered: '0',
		Abandoned: '0'
	},
	{
		SkillName: 'IVRCheck',
		TotalCalls: '11',
		CallsPerAgent: '1',
		LongestCallQueueTime: '0',
		SNO: 8,
		AverageHandlingTime: '23',
		SkillId: '36838',
		AverageSpeedToAnswer: '13',
		SLAPercentage: '100.00',
		TotalAgents: '8',
		Answered: '6',
		Abandoned: '5'
	}
];

export const AGENT_STATE_COUNTER_MOCK: any = [
	{
		AgentState: 'READY',
		CustomerPhoneNumber: '',
		AgentMode: 'INBOUND',
		AgentUniqueId: '132651',
		AgentSkills: 'sticky',
		SNO: 1,
		Since: '51:15:51',
		AgentPhoneNumber: '9059636878',
		AgentId: 'manishOO7',
		AgentName: 'manish'
	},
	{
		AgentState: 'READY',
		CustomerPhoneNumber: '',
		AgentMode: 'INBOUND',
		AgentUniqueId: '132694',
		AgentSkills: 'General, Preview1, sticky',
		SNO: 2,
		Since: '51:02:23',
		AgentPhoneNumber: '9966713149',
		AgentId: 'Balu_100',
		AgentName: 'BaluYadav'
	},
	{
		AgentState: 'READY',
		CustomerPhoneNumber: '',
		AgentMode: 'MANUAL',
		AgentUniqueId: '132655',
		AgentSkills: 'General, hari_skill, IVRCheck',
		SNO: 3,
		Since: '49:07:13',
		AgentPhoneNumber: '8297981004',
		AgentId: 'hari',
		AgentName: 'NameisHarii'
	},
	{
		AgentState: 'PAUSED',
		CustomerPhoneNumber: '',
		AgentMode: 'MANUAL',
		AgentUniqueId: '132659',
		CampaignNameOfLastCall: 'PredictiveUUI',
		AgentSkills: 'General, hari_skill, Preview1, Preview2, sticky',
		UCID: '3272165909485461',
		SNO: 4,
		Since: '51:16:12',
		AgentPhoneNumber: '9502013634',
		AgentId: 'Ro45',
		AgentName: 'Balu'
	}
];

export const AGENT_PERFORMANCE_MOCK: any = [
	{
		agent_name: 'Anand Pathak',
		SkillsAssigned: 'gchat,sales_support,kookoo_support,PartnerSupport_Mavenir,cloudAgent_support,whatsapp,HDFC_Project_Support,Chat_ozonetel,Hindi',
		AverageHoldDuration: 0,
		TotalCalls: 8,
		AnsweredCalls: 6,
		AbandonedCalls: 2,
		AverageHandlingDuration: 239,
		AverageSpeedToAnswer: 18,
		WrapupDuration: 119,
		TotalLoginDuration: 55542,
		FirstLoginTime: '2022-07-04 08:04:00.0',
		LastLogoutTime: '2022-07-04 12:26:36.0',
		TotalPauseDuration: 0
	},
	{
		agent_name: 'aparna',
		SkillsAssigned: 'HDFC_Project_Support,ozone_123',
		AverageHoldDuration: 0,
		TotalCalls: 0,
		AnsweredCalls: 0,
		AbandonedCalls: 0,
		AverageHandlingDuration: 0,
		AverageSpeedToAnswer: 0,
		WrapupDuration: 0,
		TotalLoginDuration: 0,
		FirstLoginTime: null,
		LastLogoutTime: null,
		TotalPauseDuration: 0
	},
	{
		agent_name: 'arbind',
		SkillsAssigned: 'HDFC_Project_Support',
		AverageHoldDuration: 0,
		TotalCalls: 0,
		AnsweredCalls: 0,
		AbandonedCalls: 0,
		AverageHandlingDuration: 0,
		AverageSpeedToAnswer: 0,
		WrapupDuration: 0,
		TotalLoginDuration: 0,
		FirstLoginTime: null,
		LastLogoutTime: null,
		TotalPauseDuration: 0
	},
	{
		agent_name: 'arif_shaik',
		SkillsAssigned: 'HDFC_Project_Support,arif_shaik',
		AverageHoldDuration: 0,
		TotalCalls: 0,
		AnsweredCalls: 0,
		AbandonedCalls: 0,
		AverageHandlingDuration: 0,
		AverageSpeedToAnswer: 0,
		WrapupDuration: 0,
		TotalLoginDuration: 0,
		FirstLoginTime: null,
		LastLogoutTime: null,
		TotalPauseDuration: 0
	},
	{
		agent_name: 'Biradar',
		SkillsAssigned: 'Hindi,kookoo_support,GENERAL,cloudAgent_support',
		AverageHoldDuration: 1,
		TotalCalls: 8,
		AnsweredCalls: 8,
		AbandonedCalls: 0,
		AverageHandlingDuration: 206,
		AverageSpeedToAnswer: 17,
		WrapupDuration: 245,
		TotalLoginDuration: 52392,
		FirstLoginTime: '2022-07-04 09:34:28.0',
		LastLogoutTime: null,
		TotalPauseDuration: 0
	},
	{
		agent_name: 'Dev',
		SkillsAssigned: 'cloudAgent_support,Sprinklr,Chat_ozonetel,HDFC_Project_Support',
		AverageHoldDuration: 0,
		TotalCalls: 0,
		AnsweredCalls: 0,
		AbandonedCalls: 0,
		AverageHandlingDuration: 0,
		AverageSpeedToAnswer: 0,
		WrapupDuration: 0,
		TotalLoginDuration: 0,
		FirstLoginTime: null,
		LastLogoutTime: null,
		TotalPauseDuration: 0
	},
	{
		agent_name: 'Divya',
		SkillsAssigned: 'UAE_Support,cloudAgent_support,kookoo_support,whatsapp,Chat_ozonetel,HDFC_Project_Support,PartnerSupport_Mavenir,gchat,sales_support',
		AverageHoldDuration: 0,
		TotalCalls: 0,
		AnsweredCalls: 0,
		AbandonedCalls: 0,
		AverageHandlingDuration: 0,
		AverageSpeedToAnswer: 0,
		WrapupDuration: 0,
		TotalLoginDuration: 0,
		FirstLoginTime: null,
		LastLogoutTime: null,
		TotalPauseDuration: 0
	},
	{
		agent_name: 'Geetansh',
		SkillsAssigned: 'HDFC_Project_Support,ozonetel_InSideSales,Outbound_team,PaidLeads_InsideSales,Geetansh_Inbound',
		AverageHoldDuration: 0,
		TotalCalls: 0,
		AnsweredCalls: 0,
		AbandonedCalls: 0,
		AverageHandlingDuration: 0,
		AverageSpeedToAnswer: 0,
		WrapupDuration: 0,
		TotalLoginDuration: 0,
		FirstLoginTime: null,
		LastLogoutTime: null,
		TotalPauseDuration: 0
	}
];

export const HOURLY_DIGITAL_SUMMARY: any = [
	{
		CallType: 'manual',
		CampaignId: 94740,
		CampaignName: 'New_TFN1.3',
		CallHour: 6,
		CallHourTs: '2022-07-04 06:00:00.0',
		TotalCalls: 4,
		AnsweredCalls: 2,
		UnansweredCalls: 2
	},
	{
		CallType: 'manual',
		CampaignId: 94740,
		CampaignName: 'New_TFN1.3',
		CallHour: 7,
		CallHourTs: '2022-07-04 07:00:00.0',
		TotalCalls: 3,
		AnsweredCalls: 1,
		UnansweredCalls: 2
	},
	{
		CallType: 'manual',
		CampaignId: 105634,
		CampaignName: 'LSQ_support_Airtel',
		CallHour: 6,
		CallHourTs: '2022-07-04 06:00:00.0',
		TotalCalls: 1,
		AnsweredCalls: 0,
		UnansweredCalls: 1
	},
	{
		CallType: 'inbound',
		CampaignId: 0,
		CampaignName: '0',
		CallHour: 10,
		CallHourTs: '2022-07-04 10:00:00.0',
		TotalCalls: 4,
		AnsweredCalls: 4,
		UnansweredCalls: 0
	},
	{
		CallType: 'inbound',
		CampaignId: 0,
		CampaignName: '0',
		CallHour: 11,
		CallHourTs: '2022-07-04 11:00:00.0',
		TotalCalls: 4,
		AnsweredCalls: 4,
		UnansweredCalls: 0
	},
	{
		CallType: 'inbound',
		CampaignId: 0,
		CampaignName: '0',
		CallHour: 12,
		CallHourTs: '2022-07-04 12:00:00.0',
		TotalCalls: 8,
		AnsweredCalls: 8,
		UnansweredCalls: 0
	},
	{
		CallType: 'manual',
		CampaignId: 0,
		CampaignName: '0',
		CallHour: 0,
		CallHourTs: '2022-07-04 00:00:00.0',
		TotalCalls: 1,
		AnsweredCalls: 1,
		UnansweredCalls: 0
	},
	{
		CallType: 'manual',
		CampaignId: 0,
		CampaignName: '0',
		CallHour: 6,
		CallHourTs: '2022-07-04 06:00:00.0',
		TotalCalls: 19,
		AnsweredCalls: 9,
		UnansweredCalls: 10
	},
	{
		CallType: 'manual',
		CampaignId: 0,
		CampaignName: '0',
		CallHour: 7,
		CallHourTs: '2022-07-04 07:00:00.0',
		TotalCalls: 10,
		AnsweredCalls: 3,
		UnansweredCalls: 7
	},
	{
		CallType: 'manual',
		CampaignId: 0,
		CampaignName: '0',
		CallHour: 10,
		CallHourTs: '2022-07-04 10:00:00.0',
		TotalCalls: 5,
		AnsweredCalls: 3,
		UnansweredCalls: 2
	},
	{
		CallType: 'manual',
		CampaignId: 0,
		CampaignName: '0',
		CallHour: 11,
		CallHourTs: '2022-07-04 11:00:00.0',
		TotalCalls: 8,
		AnsweredCalls: 4,
		UnansweredCalls: 4
	},
	{
		CallType: 'manual',
		CampaignId: 0,
		CampaignName: '0',
		CallHour: 12,
		CallHourTs: '2022-07-04 12:00:00.0',
		TotalCalls: 3,
		AnsweredCalls: 2,
		UnansweredCalls: 1
	}
];

export const AGENT_LIST_MOCK: any = [
	{
		AgentState: 'READY',
		CustomerPhoneNumber: '',
		AgentMode: 'PREVIEW',
		AgentUniqueId: '132654',
		AgentSkills: 'General, IVRCheck, Preview1, Preview2, Resource, sticky',
		SNO: 1,
		Since: '60:15:12',
		AgentPhoneNumber: '8297498468',
		AgentId: 'Priya@123',
		AgentName: 'Aayush Mann',
		// Agent List v2 Details
		CampaignName: 'AUS1_4420398495',
		CallType: 'TollBarMa',
		CustomerNum: '+33 4381812410',
		Mode: 'Manual',
		Skill: 'General, Support',
		LastCall: 'Not Answered',
		Status: 'Idle',
		Duration: '05:12'
	},
	{
		AgentState: 'PAUSED',
		CustomerPhoneNumber: '01234567890',
		AgentMode: 'PROGRESSIVE',
		AgentUniqueId: '132650',
		CampaignNameOfLastCall: 'Prog_Kitto_Skillwise',
		AgentSkills: 'General, sticky',
		UCID: '3272165942925491',
		SNO: 2,
		Since: '130:24:57',
		AgentPhoneNumber: '9059636878',
		AgentId: 'manishOO8',
		AgentName: 'Chetan Roy',
		// Agent List v2 Details
		CampaignName: 'AUS1_4420398495',
		CallType: 'TollBarMa',
		CustomerNum: '+33 4381812410',
		Mode: 'Manual',
		Skill: 'Canada Sales',
		LastCall: 'Answered',
		Status: 'Busy',
		Duration: '09:37'
	},
	{
		AgentState: 'BUSY',
		CustomerPhoneNumber: '01234567890',
		AgentMode: 'PROGRESSIVE',
		AgentUniqueId: '132650',
		CampaignNameOfLastCall: 'Prog_Kitto_Skillwise',
		AgentSkills: 'General, sticky',
		UCID: '3272165942925491',
		SNO: 3,
		Since: '130:24:57',
		AgentPhoneNumber: '9059636878',
		AgentId: 'manishOO8',
		AgentName: 'Faisal Khan',
		// Agent List v2 Details
		CampaignName: 'AUS1_4420398495',
		CallType: 'TollBarMa',
		CustomerNum: '+33 4381812410',
		Mode: 'Manual',
		Skill: 'Support',
		LastCall: 'Not Answered',
		Status: 'ACW',
		Duration: '00:41'
	},
	{
		AgentState: 'BUSY',
		CustomerPhoneNumber: '01234567890',
		AgentMode: 'PROGRESSIVE',
		AgentUniqueId: '132650',
		CampaignNameOfLastCall: 'Prog_Kitto_Skillwise',
		AgentSkills: 'General, sticky',
		UCID: '3272165942925491',
		SNO: 3,
		Since: '130:24:57',
		AgentPhoneNumber: '9059636878',
		AgentId: 'guarav',
		AgentName: 'Gaurav Bansal',
		// Agent List v2 Details
		CampaignName: 'AUS1_4420398495',
		CallType: 'TollBarMa',
		CustomerNum: '+33 4381812410',
		Mode: 'Manual',
		Skill: 'Premium Plus',
		LastCall: 'Answered',
		Status: 'Calling',
		Duration: '01:25'
	}
];

export const TOP_DISPOSITION_MOCK: any = [
	{
		TotalCalls: '3',
		MainDisposition: 'Issue Pending',
		SNO: 1
	},
	{
		TotalCalls: '2',
		MainDisposition: 'Issue resolved',
		SNO: 2
	},
	{
		TotalCalls: '2',
		MainDisposition: 'Support',
		SNO: 3,
		SubDisposition1: 'Development'
	},
	{
		TotalCalls: '2',
		MainDisposition: 'Test',
		SNO: 4,
		SubDisposition3: 'Automation',
		SubDisposition1: 'QA',
		SubDisposition2: 'Inbound'
	},
	{
		TotalCalls: '2',
		MainDisposition: 'Wrap up time exceeded :20',
		SNO: 5
	},
	{
		TotalCalls: '1',
		MainDisposition: 'Support',
		SNO: 6,
		SubDisposition1: 'Technical'
	},
	{
		TotalCalls: '1',
		MainDisposition: 'Test',
		SNO: 7,
		SubDisposition1: 'CA',
		SubDisposition2: 'Hari'
	},
	{
		TotalCalls: '1',
		MainDisposition: 'Test',
		SNO: 8,
		SubDisposition3: 'Manual',
		SubDisposition1: 'CA',
		SubDisposition2: 'Inbound'
	},
	{
		TotalCalls: '1',
		MainDisposition: 'Test',
		SNO: 9,
		SubDisposition3: 'Automation',
		SubDisposition1: 'QA',
		SubDisposition2: 'Outbound'
	}
];

export const PRESET_MOCK = [
	{
		id: 1,
		dashboard: {
			dashboardName: 'Some Dashboard',
			favourite: false,
			layouts: {
				lg: [
					{ i: '0', x: 0, y: 0, w: 6, h: 2, minW: 6, maxW: 12, minH: 2, maxH: 2, widgetName: 'SLA' },
					{ i: '1', x: 0, y: 0, w: 6, h: 2, minW: 6, maxW: 12, minH: 2, maxH: 2, widgetName: 'CALL_STATUS' },
					{ i: '2', x: 0, y: 0, w: 6, h: 2, minW: 6, maxW: 12, minH: 2, maxH: 2, widgetName: 'QUEUE_COUNTER' }
				]
			}
		}
	},
	{
		id: 2,
		dashboard: {
			dashboardName: 'Some Dashboard 2',
			favourite: true,
			layouts: {
				lg: [{ i: '2', x: 0, y: 0, w: 6, h: 2, minW: 6, maxW: 12, minH: 2, maxH: 2, widgetName: 'QUEUE_COUNTER' }]
			}
		}
	},
	{
		id: 3,
		dashboard: {
			dashboardName: 'Some Dashboard 4',
			favourite: true,
			layouts: {
				lg: [{ i: '2', x: 0, y: 0, w: 6, h: 2, minW: 6, maxW: 12, minH: 2, maxH: 2, widgetName: 'CALL_STATUS' }]
			}
		}
	}
];

export const FILTERS_MOCK = [
	{ Id: 1, Name: 'UK Sales' },
	{ Id: 2, Name: 'Premium Plus' },
	{ Id: 3, Name: 'EU Sales' },
	{ Id: 4, Name: 'VIP' },
	{ Id: 5, Name: 'EU Tec' },
	{ Id: 6, Name: 'Africa Scales' },
	{ Id: 7, Name: 'Mexico Tec' },
	{ Id: 8, Name: 'Africa Tec' },
	{ Id: 9, Name: 'Premium Plus' },
	{ Id: 10, Name: 'Iran Tec' },
	{ Id: 11, Name: 'VIP' },
	{ Id: 12, Name: 'Iran Scales' },
	{ Id: 13, Name: 'Africa Scales' },
	{ Id: 14, Name: 'India Support' },
	{ Id: 15, Name: 'Africa Tec' },
	{ Id: 16, Name: 'USA Tec' },
	{ Id: 17, Name: 'Iran Tec' },
	{ Id: 18, Name: 'India Support' },
	{ Id: 19, Name: 'Iran Scales' },
	{ Id: 20, Name: 'USA Tec' }
];

export const AGENT_STATE_COUNT_MOCK = [
	{
		Ready: '8',
		Pause: '7',
		SNO: 1,
		ACW: '4',
		Busy: '12',
		LoggedOut: '32',
		TotalAgents: '100',
		LoggedIn: '82'
	}
];

export const CALL_COUNTS_MOCK = [
	{
		TotalCalls: '114',
		LongestWaitTime: '30',
		SNO: 1,
		CallsInQueue: '30',
		Answered: '82',
		Abandoned: '32'
	}
];

export const TOP_5_SKILLS_MOCK = [
	{
		Skill: 'General',
		LongestWaitTime: '30',
		SNO: 1,
		Queue: '23'
	},
	{
		Skill: 'Support',
		LongestWaitTime: '30',
		SNO: 2,
		Queue: '23'
	},
	{
		Skill: 'English scales',
		LongestWaitTime: '30',
		SNO: 3,
		Queue: '23'
	},
	{
		Skill: 'Premium plus',
		LongestWaitTime: '30',
		SNO: 4,
		Queue: '23'
	},
	{
		Skill: 'Canada scales',
		LongestWaitTime: '30',
		SNO: 5,
		Queue: '23'
	}
];

export const TOP_3_AGENTS = [
	{
		Agent: 'Tushar Kumar',
		SNO: 1
	},
	{
		Agent: 'Bobby Singh',
		SNO: 2
	},
	{
		Agent: 'Ekta Rao',
		SNO: 3
	}
];

export const COMPARATIVE_CALL_TRENDS_MOCK = [
	{
		UnAnsweredCalls: '30',
		TotalCalls: '100',
		SNO: 1,
		CallHour: '06',
		DateType: 'CurrentDay',
		AnsweredCalls: '70',
		ActiveAgents: '25'
	},
	{
		UnAnsweredCalls: '35',
		TotalCalls: '110',
		SNO: 2,
		CallHour: '06',
		DateType: 'YesterDay',
		AnsweredCalls: '75',
		ActiveAgents: '30'
	},
	{
		UnAnsweredCalls: '33',
		TotalCalls: '105',
		SNO: 3,
		CallHour: '06',
		DateType: '7DaysAvg',
		AnsweredCalls: '72',
		ActiveAgents: '27'
	},
	{
		UnAnsweredCalls: '30',
		TotalCalls: '100',
		SNO: 4,
		CallHour: '07',
		DateType: 'CurrentDay',
		AnsweredCalls: '70',
		ActiveAgents: '25'
	},
	{
		UnAnsweredCalls: '35',
		TotalCalls: '110',
		SNO: 5,
		CallHour: '07',
		DateType: 'YesterDay',
		AnsweredCalls: '75',
		ActiveAgents: '30'
	},
	{
		UnAnsweredCalls: '33',
		TotalCalls: '105',
		SNO: 6,
		CallHour: '07',
		DateType: '7DaysAvg',
		AnsweredCalls: '72',
		ActiveAgents: '27'
	},
	{
		UnAnsweredCalls: '30',
		TotalCalls: '100',
		SNO: 7,
		CallHour: '09',
		DateType: 'CurrentDay',
		AnsweredCalls: '70',
		ActiveAgents: '25'
	},
	{
		UnAnsweredCalls: '35',
		TotalCalls: '110',
		SNO: 8,
		CallHour: '09',
		DateType: 'YesterDay',
		AnsweredCalls: '75',
		ActiveAgents: '30'
	},
	{
		UnAnsweredCalls: '33',
		TotalCalls: '105',
		SNO: 9,
		CallHour: '09',
		DateType: '7DaysAvg',
		AnsweredCalls: '72',
		ActiveAgents: '27'
	},
	{
		UnAnsweredCalls: '30',
		TotalCalls: '100',
		SNO: 10,
		CallHour: '14',
		DateType: 'CurrentDay',
		AnsweredCalls: '70',
		ActiveAgents: '25'
	},
	{
		UnAnsweredCalls: '35',
		TotalCalls: '110',
		SNO: 11,
		CallHour: '14',
		DateType: 'YesterDay',
		AnsweredCalls: '75',
		ActiveAgents: '30'
	},
	{
		UnAnsweredCalls: '33',
		TotalCalls: '105',
		SNO: 12,
		CallHour: '14',
		DateType: '7DaysAvg',
		AnsweredCalls: '72',
		ActiveAgents: '27'
	},
	{
		UnAnsweredCalls: '30',
		TotalCalls: '100',
		SNO: 13,
		CallHour: '17',
		DateType: 'CurrentDay',
		AnsweredCalls: '70',
		ActiveAgents: '25'
	},
	{
		UnAnsweredCalls: '35',
		TotalCalls: '110',
		SNO: 14,
		CallHour: '17',
		DateType: 'YesterDay',
		AnsweredCalls: '75',
		ActiveAgents: '30'
	},
	{
		UnAnsweredCalls: '33',
		TotalCalls: '105',
		SNO: 15,
		CallHour: '17',
		DateType: '7DaysAvg',
		AnsweredCalls: '72',
		ActiveAgents: '27'
	}
];

export const AGENT_DETAILS_MOCK = [
	{
		TotalLoginDuration: '172',
		TotalWrapupTime: '40',
		TotalTalkTime: '100',
		SNO: 1,
		TotalPauseTime: '50',
		AnsweredPerc: '42',
		TotalIdleTime: '172',
		FirstLoginTime: '03:18:00'
	}
];

export const TOP_5_OUTLIERS_MOCK = [
	{
		AvgHandlingTime: '172',
		SNO: 1,
		AvgWrapupTime: '10',
		AgentName: 'Raju Suresh',
		AvgPickupTime: '102',
		AvgHoldTime: '20'
	},
	{
		AvgHandlingTime: '172',
		SNO: 2,
		AvgWrapupTime: '10',
		AgentName: 'Mukesh',
		AvgPickupTime: '102',
		AvgHoldTime: '20'
	},
	{
		AvgHandlingTime: '172',
		SNO: 3,
		AvgWrapupTime: '10',
		AgentName: 'Ajay Raj',
		AvgPickupTime: '102',
		AvgHoldTime: '20'
	},
	{
		AvgHandlingTime: '172',
		SNO: 4,
		AvgWrapupTime: '0',
		AgentName: 'Vijay',
		AvgPickupTime: '172',
		AvgHoldTime: '0'
	},
	{
		AvgHandlingTime: '172',
		SNO: 5,
		AvgWrapupTime: '10',
		AgentName: 'Ajay Kumar',
		AvgPickupTime: '102',
		AvgHoldTime: '20'
	}
];

export const DAYWISE_NUMBEROF_CALLS: any = [
	{
		StartTime: '19/05/2022',
		TotalCalls: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: '19/05/2022',
		TotalCalls: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: '19/05/2022',
		TotalCalls: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: '19/05/2022',
		TotalCalls: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: '19/05/2022',
		TotalCalls: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: '19/05/2022',
		TotalCalls: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: '19/05/2022',
		TotalCalls: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: '19/05/2022',
		TotalCalls: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: '19/05/2022',
		TotalCalls: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: '19/05/2022',
		TotalCalls: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	}
];

export const PERFORMANCE_DASHBOARD: any = [
	{
		AgentName: 'Aayush Mann',
		AvgHoldTime: '00.00',
		Event: '20',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: { value: 'Chetan Roy', icon: UserPlaceholder1 },
		AvgHoldTime: '00.00',
		TotalInteractions: '35',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Abhishek',
		AvgHoldTime: '00.00',
		TotalInteractions: '14',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: { value: 'Faisal Khan', icon: UserPlaceholder2 },
		AvgHoldTime: '00.00',
		TotalInteractions: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Gaurav Bansal',
		AvgHoldTime: '00.00',
		TotalInteractions: '17',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Aayush Mann',
		AvgHoldTime: '00.00',
		TotalInteractions: '20',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Aayush Mann',
		AvgHoldTime: '00.00',
		TotalInteractions: '14',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Aayush Mann',
		AvgHoldTime: '00.00',
		TotalInteractions: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Aayush Mann',
		AvgHoldTime: '00.00',
		TotalInteractions: '17',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Aayush Mann',
		AvgHoldTime: '00.00',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Aayush Mann',
		AvgHoldTime: '00.00',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Aayush Mann',
		AvgHoldTime: '00.00',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Aayush Mann',
		AvgHoldTime: '00.00',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Aayush Mann',
		AvgHoldTime: '00.00',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Aayush Mann',
		AvgHoldTime: '00.00',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	},
	{
		AgentName: 'Aayush Mann',
		AvgHoldTime: '00.00',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '05:21',
		ASA: '00:21',
		ACW: '02:38',
		PauseTime: '00:00:00'
	}
];

export const HOURLY_CALL_SUMMARY_REPORTS: any = [
	{
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: { value: '00:00', warning: 'warning' }
	},
	{
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00'
	},
	{
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: { value: '36.3% | 17', warning: 'warning' },
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00'
	},
	{
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: { value: '00:21', warning: 'warning' },
		AWT: { value: '05:2', warning: 'warning' },
		ATT: { value: '00:21', warning: 'warning' },
		TotalCallsInQueue: { value: '0', warning: 'warning' },
		ACW: { value: '00:00', warning: 'warning' }
	},
	{
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00'
	},
	{
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: { value: '36.3% | 17', warning: 'warning' },
		APT: { value: '00:21', warning: 'warning' },
		AWT: '05:21',
		ATT: { value: '00:21', warning: 'warning' },
		TotalCallsInQueue: { value: '0', warning: 'warning' },
		ACW: { value: '00:00', warning: 'warning' }
	},
	{
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: { value: '00:21', warning: 'warning' },
		TotalCallsInQueue: '0',
		ACW: '00:00'
	},
	{
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: { value: '36.3% | 17', warning: 'warning' },
		APT: '00:21',
		AWT: { value: '05:2', warning: 'warning' },
		ATT: '00:21',
		TotalCallsInQueue: { value: '0', warning: 'warning' },
		ACW: '00:00'
	},
	{
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: { value: '0', warning: 'warning' },
		ACW: { value: '00:00', warning: 'warning' }
	},
	{
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00'
	}
];

export const HOURLY_CALL_SUMMARY_DETAILED_REPORTS: any = [
	{
		Date: '01/07/2022',
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00',
		CallsAbandonedInQueue: '1',
		AvgQueueDisconnectTime: '0'
	},
	{
		Date: '01/07/2022',
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00',
		CallsAbandonedInQueue: '1',
		AvgQueueDisconnectTime: '0'
	},
	{
		Date: '01/07/2022',
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00',
		CallsAbandonedInQueue: '1',
		AvgQueueDisconnectTime: '0'
	},
	{
		Date: '01/07/2022',
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00',
		CallsAbandonedInQueue: '1',
		AvgQueueDisconnectTime: '0'
	},
	{
		Date: '01/07/2022',
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00',
		CallsAbandonedInQueue: '1',
		AvgQueueDisconnectTime: '0'
	},
	{
		Date: 'Total (01/07/2022)',
		Total: true,
		Hour: ' ',
		TotalCalls: '4',
		AnsweredCalls: '4',
		AbandonedCalls: '1',
		APT: '00:17',
		AWT: '06:19',
		ATT: '00:17',
		TotalCallsInQueue: '1',
		ACW: '00:13',
		CallsAbandonedInQueue: '1',
		AvgQueueDisconnectTime: '1'
	},
	{
		Date: '01/07/2022',
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00',
		CallsAbandonedInQueue: '1',
		AvgQueueDisconnectTime: '0'
	},
	{
		Date: '01/07/2022',
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00',
		CallsAbandonedInQueue: '1',
		AvgQueueDisconnectTime: '0'
	},
	{
		Date: '01/07/2022',
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00',
		CallsAbandonedInQueue: '1',
		AvgQueueDisconnectTime: '0'
	},
	{
		Date: '01/07/2022',
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00',
		CallsAbandonedInQueue: '1',
		AvgQueueDisconnectTime: '0'
	},
	{
		Date: '01/07/2022',
		Hour: '12:00 - 01:00',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AWT: '05:21',
		ATT: '00:21',
		TotalCallsInQueue: '0',
		ACW: '00:00',
		CallsAbandonedInQueue: '1',
		AvgQueueDisconnectTime: '0'
	}
];

export const CALL_DETAILS_REPORT: any = {
	reports: [
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Aayush Mann',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Abhishek',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Chetan Roy',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Faisal Khan',
			Campaign: 'Inbound_16468461468',
			Location: 'India',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Gaurav Bansal',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Aayush Mann',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Abhishek',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Chetan Roy',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Faisal Khan',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Gaurav Bansal',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Aayush Mann',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Abhishek',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Chetan Roy',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Faisal Khan',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		},
		{
			CallID: '9062524175982455',
			CallType: 'Inbound',
			AgentName: 'Gaurav Bansal',
			Campaign: 'Inbound_16468461468',
			Location: 'USA',
			CallerNumber: '+15036430555',
			Skill: 'Sales',
			Date: '06/14/2022',
			StartTime: '2022-06-14 | 17:22:50',
			TimeToAnswer: '00:00:00',
			EndTime: '2022-06-14 17:22:55.000',
			TalkTime: '00:00:00',
			HoldTime: '00:00:00',
			Duration: '00:00:14',
			CallFlow: 'Queue',
			DialedNumber: '10367',
			Disposition: 'General Query',
			WrapupDuration: '00:00:00',
			HandlingTime: '00:00:00',
			Status: 'Unanswered',
			DialStatus: 'QTimeExceeded{15}',
			CustomerDialStatus: 'Not Dialed',
			AgentDialStatus: 'Not Dialed',
			HangupBy: 'Caller Disconnect',
			TransferDetails: '',
			UIUI: '',
			Comments: '',
			Feedbacks: 'Feedback',
			CustomerRingTime: '00:00:00',
			Audio: ''
		}
	]
};

export const PREDICTIVE_PERFORMANCE_REPORT: any = [
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0'
	},
	{
		Date: '07/07/2022',
		Campaign: 'PredictiveOMN',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3'
	}
];

export const MISSED_CALL_REPORT: any = [
	{
		CallerNumber: '4804492315',
		CallCount: '2',
		FirstCalledTime: '07/07/2022 | 11:29:07',
		LastCalledTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		Duration: '00:01:31',
		Skill: 'Sales -> Sales -> Basic',
		FallbackRule: 'AgentDial -> Queue'
	},
	{
		CallerNumber: '4804492315',
		CallCount: '1',
		FirstCalledTime: '07/07/2022 | 11:29:07',
		LastCalledTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		Duration: '00:01:31',
		Skill: 'Sales -> Sales -> Basic',
		FallbackRule: 'AgentDial -> Queue'
	},
	{
		CallerNumber: '4804492315',
		CallCount: '3',
		FirstCalledTime: '07/07/2022 | 11:29:07',
		LastCalledTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		Duration: '00:01:31',
		Skill: 'Sales -> Sales -> Basic',
		FallbackRule: 'AgentDial -> Queue'
	},
	{
		CallerNumber: '4804492315',
		CallCount: '6',
		FirstCalledTime: '07/07/2022 | 11:29:07',
		LastCalledTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		Duration: '00:01:31',
		Skill: 'Sales -> Sales -> Basic',
		FallbackRule: 'AgentDial -> Queue'
	},
	{
		CallerNumber: '4804492315',
		CallCount: '4',
		FirstCalledTime: '07/07/2022 | 11:29:07',
		LastCalledTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		Duration: '00:01:31',
		Skill: 'Sales -> Sales -> Basic',
		FallbackRule: 'AgentDial -> Queue'
	},
	{
		CallerNumber: '4804492315',
		CallCount: '8',
		FirstCalledTime: '07/07/2022 | 11:29:07',
		LastCalledTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		Duration: '00:01:31',
		Skill: 'Sales -> Sales -> Basic',
		FallbackRule: 'AgentDial -> Queue'
	},
	{
		CallerNumber: '4804492315',
		CallCount: '5',
		FirstCalledTime: '07/07/2022 | 11:29:07',
		LastCalledTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		Duration: '00:01:31',
		Skill: 'Sales -> Sales -> Basic',
		FallbackRule: 'AgentDial -> Queue'
	},
	{
		CallerNumber: '4804492315',
		CallCount: '9',
		FirstCalledTime: '07/07/2022 | 11:29:07',
		LastCalledTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		Duration: '00:01:31',
		Skill: 'Sales -> Sales -> Basic',
		FallbackRule: 'AgentDial -> Queue'
	},
	{
		CallerNumber: '4804492315',
		CallCount: '3',
		FirstCalledTime: '07/07/2022 | 11:29:07',
		LastCalledTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		Duration: '00:01:31',
		Skill: 'Sales -> Sales -> Basic',
		FallbackRule: 'AgentDial -> Queue'
	},
	{
		CallerNumber: '4804492315',
		CallCount: '7',
		FirstCalledTime: '07/07/2022 | 11:29:07',
		LastCalledTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		Duration: '00:01:31',
		Skill: 'Sales -> Sales -> Basic',
		FallbackRule: 'AgentDial -> Queue'
	}
];

export const SKILLS_PRODUCTIVITY: any = [
	{
		Skill: 'Basic',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'General',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'Support',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'Digital',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'Cannada_Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'UK_Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'Argentina_Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'India_Support',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'Australia_Support',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	}
];

export const SKILLS_PRODUCTIVITY_DETAILED_REPORTS: any = [
	{
		Date: '01/07/2022',
		Skill: 'Basic',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'General',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'Support',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'Digital',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: 'Total (01/07/2022)',
		Total: true,
		Hour: ' ',
		TotalCalls: '4',
		AnsweredCalls: '4',
		AbandonedCalls: '1',
		APT: '00:17',
		AHT: '06:19',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '1'
	},
	{
		Date: '01/07/2022',
		Skill: 'Cannada_Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'UK_Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'Argentina_Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'India_Support',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'Australia_Support',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	}
];

export const CONFERENCE_DETAILS: any = [
	{
		AgentName: 'Aayush Mann',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS', color: 'green' },
		ExitStatus: { value: 'ANSWERED', color: 'green' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Abhishek',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS', color: 'green' },
		ExitStatus: { value: 'ANSWERED', color: 'green' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Chetan Roy',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS', color: 'green' },
		ExitStatus: { value: 'ANSWERED', color: 'green' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Faisal Khan',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS', color: 'green' },
		ExitStatus: { value: 'UNANSWERED', color: 'red' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Gaurav Bansal',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS', color: 'green' },
		ExitStatus: { value: 'ANSWERED', color: 'green' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Faisal Khan',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS', color: 'green' },
		ExitStatus: { value: 'UNANSWERED', color: 'red' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Aayush Mann',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS', color: 'green' },
		ExitStatus: { value: 'UNANSWERED', color: 'red' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Faisal Khan',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS', color: 'green' },
		ExitStatus: { value: 'ANSWERED', color: 'green' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Aayush Mann',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS', color: 'green' },
		ExitStatus: { value: 'UNANSWERED', color: 'red' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Faisal Khan',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS', color: 'green' },
		ExitStatus: { value: 'ANSWERED', color: 'green' },
		Audio: { value: '', isAudio: true }
	}
];

export const HOURLY_PHONE_CALL_REPORTS: any = [
	{
		PhoneNumber: 8652799399,
		Hour: '01:00 - 02:00',
		TotalCalls: 2,
		AnsweredCalls: 2,
		AbandonedCalls: '0',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		PhoneNumber: 8652799399,
		Hour: '01:00 - 02:00',
		TotalCalls: 2,
		AnsweredCalls: 2,
		AbandonedCalls: 1,
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		PhoneNumber: 8652799399,
		Hour: '01:00 - 02:00',
		TotalCalls: 2,
		AnsweredCalls: 2,
		AbandonedCalls: { value: 1, warning: 'warning' },
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		PhoneNumber: 'Total',
		Hour: '',
		TotalCalls: 4,
		AnsweredCalls: 4,
		AbandonedCalls: 1,
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19',
		background: 'total-background'
	},
	{
		PhoneNumber: 'Jaibalakrishna',
		Hour: '04:00 - 05:00',
		TotalCalls: 4,
		AnsweredCalls: 4,
		AbandonedCalls: 1,
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		PhoneNumber: 'Total',
		Hour: '',
		TotalCalls: 4,
		AnsweredCalls: 4,
		AbandonedCalls: 1,
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19',
		background: 'total-background'
	},
	{
		PhoneNumber: 'Jaibalakrishna',
		Hour: '06:00 - 07:00',
		TotalCalls: 4,
		AnsweredCalls: 4,
		AbandonedCalls: 1,
		AvgPickupTime: '00:21',
		AvgTalkTime: { value: '06:19', warning: 'warning' }
	},
	{
		PhoneNumber: 'Jaibalakrishna',
		Hour: '07:00 - 08:00',
		TotalCalls: 4,
		AnsweredCalls: 4,
		AbandonedCalls: { value: 1, warning: 'warning' },
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		PhoneNumber: 'Total',
		Hour: '',
		TotalCalls: 4,
		AnsweredCalls: 4,
		AbandonedCalls: 1,
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19',
		background: 'total-background'
	},
	{
		PhoneNumber: 'Shivamani',
		Hour: '01:00 - 02:00',
		TotalCalls: 2,
		AnsweredCalls: 2,
		AbandonedCalls: '0',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	}
];

export const HOURLY_PHONE_CALL_DETAILED_REPORTS: any = [
	{
		Date: '01/07/2022',
		PhoneNumber: 8652799399,
		Hour: '01:00 - 02:00',
		TotalCalls: 2,
		AnsweredCalls: 2,
		AbandonedCalls: '0',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		Date: '01/07/2022',
		PhoneNumber: 8652799399,
		Hour: '01:00 - 02:00',
		TotalCalls: 2,
		AnsweredCalls: 2,
		AbandonedCalls: 1,
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		Date: '01/07/2022',
		PhoneNumber: 8652799399,
		Hour: '01:00 - 02:00',
		TotalCalls: 2,
		AnsweredCalls: 2,
		AbandonedCalls: { value: 1, warning: 'warning' },
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		Date: '',
		PhoneNumber: 'Total',
		Hour: '',
		TotalCalls: 4,
		AnsweredCalls: 4,
		AbandonedCalls: 1,
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19',
		background: 'total-background'
	},
	{
		Date: '01/07/2022',
		PhoneNumber: 'Jaibalakrishna',
		Hour: '04:00 - 05:00',
		TotalCalls: 4,
		AnsweredCalls: 4,
		AbandonedCalls: 1,
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		Date: '',
		PhoneNumber: 'Total',
		Hour: '',
		TotalCalls: 4,
		AnsweredCalls: 4,
		AbandonedCalls: 1,
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19',
		background: 'total-background'
	},
	{
		Date: 'Grand Total (01/07/2022)',
		Total: true,
		PhoneNumber: '',
		Hour: '',
		TotalCalls: 14,
		AnsweredCalls: 14,
		AbandonedCalls: 3,
		AvgPickupTime: '01:34',
		AvgTalkTime: '24:20'
	},
	{
		Date: '01/07/2022',
		PhoneNumber: 'Jaibalakrishna',
		Hour: '07:00 - 08:00',
		TotalCalls: 4,
		AnsweredCalls: 4,
		AbandonedCalls: 1,
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		Date: '01/07/2022',
		PhoneNumber: 'Total',
		Hour: '',
		TotalCalls: 4,
		AnsweredCalls: 4,
		AbandonedCalls: 1,
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19',
		background: 'total-background'
	},
	{
		Date: '01/07/2022',
		PhoneNumber: 'Shivamani',
		Hour: '01:00 - 02:00',
		TotalCalls: 2,
		AnsweredCalls: 2,
		AbandonedCalls: '0',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	}
];

export const PHONE_PRODUCTIVITY_REPORTS: any = [
	{
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'PhnPadma69',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Test_032',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Test_032',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: { value: '36.3% | 17', warning: 'warning' },
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'PhnPadma6',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: { value: '06:19', warning: 'warning' }
	},
	{
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: { value: '36.3% | 17', warning: 'warning' },
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'PhnPadma6',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Shivamani',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	}
];

export const PHONE_PRODUCTIVITY_DETAILED_REPORTS: any = [
	{
		Date: '01/07/2022',
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'PhnPadma69',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		Date: '01/07/2022',
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Test_032',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		Date: '01/07/2022',
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Test_032',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: { value: '36.3% | 17', warning: 'warning' },
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		Date: '01/07/2022',
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		Date: '01/07/2022',
		Total: true,
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		Date: '01/07/2022',
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'PhnPadma6',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		Date: '01/07/2022',
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: { value: '06:19', warning: 'warning' }
	},
	{
		Date: '01/07/2022',
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: { value: '36.3% | 17', warning: 'warning' },
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		Date: '01/07/2022',
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'PhnPadma6',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		Date: '01/07/2022',
		Total: true,
		CallerDisconnects: '36.3% | 17',
		PhoneNo: '+91 9703331632',
		PhoneName: 'Shivamani',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	}
];

export const PHONE_DASHBOARD: any = [
	{
		PhoneName: 'PhnPadma69',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		PhoneName: 'Test_032',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		PhoneName: 'Test_032',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: { value: '36.3% | 17', warning: 'warning' },
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	},
	{
		PhoneName: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		PhoneName: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		PhoneName: 'PhnPadma6',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		PhoneName: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: { value: '06:19', warning: 'warning' }
	},
	{
		PhoneName: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: { value: '36.3% | 17', warning: 'warning' },
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		PhoneName: 'PhnPadma6',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '06:19'
	},
	{
		PhoneName: 'Shivamani',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AvgPickupTime: '00:21',
		AvgTalkTime: '05:21'
	}
];

export const AGENT_LOGIN_REPORT: any = [
	{
		AgentID: '10056',
		AgentName: 'Aayush Mann',
		AgentMode: 'Inbound',
		Event: { value: 'Login', eventColor: 'login-color' },
		EventDetails: '/49.37.129.92.49955',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 17:22:55'
	},
	{
		AgentID: '10056',
		AgentName: { value: 'Chetan Roy', icon: UserPlaceholder1 },
		AgentMode: 'Inbound',
		Event: { value: 'AUX', eventColor: 'aux-color' },
		EventDetails: '/49.37.129.92.49955',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 17:22:55'
	},
	{
		AgentID: '10056',
		AgentName: 'Abhishek',
		AgentMode: 'Inbound',
		Event: { value: 'Idle', eventColor: 'idle-color' },
		EventDetails: '/49.37.129.92.49955',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 17:22:55'
	},
	{
		AgentID: '10056',
		AgentName: { value: 'Faisal Khan', icon: UserPlaceholder2 },
		AgentMode: 'Inbound',
		Event: { value: 'Logout', eventColor: 'logout-color' },
		EventDetails: '/49.37.129.92.49955',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 17:22:55'
	},
	{
		AgentID: '10056',
		AgentName: 'Gaurav Bansal',
		AgentMode: 'Inbound',
		Event: { value: 'AUX', eventColor: 'aux-color' },
		EventDetails: '/49.37.129.92.49955',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 17:22:55'
	},
	{
		AgentID: '10056',
		AgentName: 'Aayush Mann',
		AgentMode: 'Inbound',
		Event: { value: 'Login', eventColor: 'login-color' },
		EventDetails: '/49.37.129.92.49955',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 17:22:55'
	},
	{
		AgentID: '10056',
		AgentName: 'Aayush Mann',
		AgentMode: 'Inbound',
		Event: { value: 'Idle', eventColor: 'idle-color' },
		EventDetails: '/49.37.129.92.49955',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 17:22:55'
	},
	{
		AgentID: '10056',
		AgentName: 'Aayush Mann',
		AgentMode: 'Inbound',
		Event: { value: 'Logout', eventColor: 'logout-color' },
		EventDetails: '/49.37.129.92.49955',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 17:22:55'
	},
	{
		AgentID: '10056',
		AgentName: 'Aayush Mann',
		AgentMode: 'Inbound',
		Event: { value: 'Idle', eventColor: 'idle-color' },
		EventDetails: '/49.37.129.92.49955',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 17:22:55'
	},
	{
		AgentID: '10056',
		AgentName: 'Aayush Mann',
		AgentMode: 'Inbound',
		Event: { value: 'Idle', eventColor: 'idle-color' },
		EventDetails: '/49.37.129.92.49955',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 17:22:55'
	}
];

export const CUSTOMER_HOLD_REPORT: any = [
	{
		AgentName: 'Aayush Mann',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'ANSWERED' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Abhishek',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'ANSWERED' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Chetan Roy',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'ANSWERED' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Faisal Khan',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'UNANSWERED', color: 'red' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Gaurav Bansal',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'ANSWERED' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Faisal Khan',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'UNANSWERED', color: 'red' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Aayush Mann',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'UNANSWERED', color: 'red' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Faisal Khan',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'ANSWERED' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Aayush Mann',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'UNANSWERED', color: 'red' },
		Audio: { value: '', isAudio: true }
	},
	{
		AgentName: 'Faisal Khan',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'ANSWERED' },
		Audio: { value: '', isAudio: true }
	}
];

export const USER_HANGUP_IN_IVR: any = [
	{
		Date: '07/07/2022',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'ANSWERED' },
		Audio: { value: '', isAudio: true }
	},
	{
		Date: '07/07/2022',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'ANSWERED' },
		Audio: { value: '', isAudio: true }
	},
	{
		Date: '07/07/2022',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'ANSWERED' },
		Audio: { value: '', isAudio: true }
	},
	{
		Date: '07/07/2022',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'UNANSWERED', color: 'red' },
		Audio: { value: '', isAudio: true }
	},
	{
		Date: '07/07/2022',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'ANSWERED' },
		Audio: { value: '', isAudio: true }
	},
	{
		Date: '07/07/2022',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'UNANSWERED', color: 'red' },
		Audio: { value: '', isAudio: true }
	},
	{
		Date: '07/07/2022',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'UNANSWERED', color: 'red' },
		Audio: { value: '', isAudio: true }
	},
	{
		Date: '07/07/2022',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'ANSWERED' },
		Audio: { value: '', isAudio: true }
	},
	{
		Date: '07/07/2022',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'UNANSWERED', color: 'red' },
		Audio: { value: '', isAudio: true }
	},
	{
		Date: '07/07/2022',
		DID: '919703331632',
		UCID: '9022164250120971',
		ParticipantNo: '9704111802',
		StartTime: '2022-06-14 | 17:22:50',
		PickupTime: '2022-06-14 17:22:55.000',
		EndTime: '2022-06-14 17:22:55.000',
		DialStatus: { value: 'SUCCESS' },
		ExitStatus: { value: 'ANSWERED' },
		Audio: { value: '', isAudio: true }
	}
];
export const AUDIT_LOG_REPORT: any = [
	{
		UserName: 'crm_user',
		Module: 'Integration',
		Action: 'INS',
		DateAndTime: '06/02/2022 | 03:27:39',
		Description: 'FbChat integration settings updat...',
		IpAdress: '125.134.553.671'
	},
	{
		UserName: 'crm_user',
		Module: 'Integration',
		Action: 'INS',
		DateAndTime: '06/02/2022 | 03:27:39',
		Description: 'FbChat integration settings updat...',
		IpAdress: '125.134.553.671'
	},
	{
		UserName: 'crm_user',
		Module: 'Integration',
		Action: 'INS',
		DateAndTime: '06/02/2022 | 03:27:39',
		Description: 'FbChat integration settings updat...',
		IpAdress: '125.134.553.671'
	},
	{
		UserName: 'crm_user',
		Module: 'Integration',
		Action: 'INS',
		DateAndTime: '06/02/2022 | 03:27:39',
		Description: 'FbChat integration settings updat...',
		IpAdress: '125.134.553.671'
	},
	{
		UserName: 'crm_user',
		Module: 'Integration',
		Action: 'INS',
		DateAndTime: '06/02/2022 | 03:27:39',
		Description: 'FbChat integration settings updat...',
		IpAdress: '125.134.553.671'
	},
	{
		UserName: 'crm_user',
		Module: 'Integration',
		Action: 'INS',
		DateAndTime: '06/02/2022 | 03:27:39',
		Description: 'FbChat integration settings updat...',
		IpAdress: '125.134.553.671'
	},
	{
		UserName: 'crm_user',
		Module: 'Integration',
		Action: 'INS',
		DateAndTime: '06/02/2022 | 03:27:39',
		Description: 'FbChat integration settings updat...',
		IpAdress: '125.134.553.671'
	},
	{
		UserName: 'crm_user',
		Module: 'Integration',
		Action: 'INS',
		DateAndTime: '06/02/2022 | 03:27:39',
		Description: 'FbChat integration settings updat...',
		IpAdress: '125.134.553.671'
	},
	{
		UserName: 'crm_user',
		Module: 'Integration',
		Action: 'INS',
		DateAndTime: '06/02/2022 | 03:27:39',
		Description: 'FbChat integration settings updat...',
		IpAdress: '125.134.553.671'
	},
	{
		UserName: 'crm_user',
		Module: 'Integration',
		Action: 'INS',
		DateAndTime: '06/02/2022 | 03:27:39',
		Description: 'FbChat integration settings updat...',
		IpAdress: '125.134.553.671'
	}
];
export const CALL_SUMMARY_OUTBOUND: any = [
	{
		CallType: 'Inbound',
		TotalCalls: '14',
		AnsweredCalls: '36.3% |17',
		AbandonedCalls: '36.3% |17',
		AvgHandleTime: '00:21',
		AvgWrapupTime: '00:21',
		AvgTalktime: '00:21',
		CallerDisconnects: '36.3% |17',
		AgentDisconnects: '36.3% |17'
	},
	{
		CallType: 'Manual',
		TotalCalls: '14',
		AnsweredCalls: '36.3% |17',
		AbandonedCalls: '36.3% |17',
		AvgHandleTime: '00:21',
		AvgWrapupTime: '00:21',
		AvgTalktime: '00:21',
		CallerDisconnects: '36.3% |17',
		AgentDisconnects: '36.3% |17'
	},
	{
		CallType: 'Progressive',
		TotalCalls: '14',
		AnsweredCalls: '36.3% |17',
		AbandonedCalls: '36.3% |17',
		AvgHandleTime: '00:21',
		AvgWrapupTime: '00:21',
		AvgTalktime: '00:21',
		CallerDisconnects: '36.3% |17',
		AgentDisconnects: '36.3% |17'
	},
	{
		CallType: 'Predictive',
		TotalCalls: '14',
		AnsweredCalls: '36.3% |17',
		AbandonedCalls: '36.3% |17',
		AvgHandleTime: '00:21',
		AvgWrapupTime: '00:21',
		AvgTalktime: '00:21',
		CallerDisconnects: '36.3% |17',
		AgentDisconnects: '36.3% |17'
	},
	{
		CallType: 'Voice',
		TotalCalls: '14',
		AnsweredCalls: '36.3% |17',
		AbandonedCalls: '36.3% |17',
		AvgHandleTime: '00:21',
		AvgWrapupTime: '00:21',
		AvgTalktime: '00:21',
		CallerDisconnects: '36.3% |17',
		AgentDisconnects: '36.3% |17'
	},
	{
		CallType: 'Digital',
		TotalCalls: '14',
		AnsweredCalls: '36.3% |17',
		AbandonedCalls: '36.3% |17',
		AvgHandleTime: '00:21',
		AvgWrapupTime: '00:21',
		AvgTalktime: '00:21',
		CallerDisconnects: '36.3% |17',
		AgentDisconnects: '36.3% |17'
	},
	{
		CallType: 'Preview',
		TotalCalls: '14',
		AnsweredCalls: '36.3% |17',
		AbandonedCalls: '36.3% |17',
		AvgHandleTime: '00:21',
		AvgWrapupTime: '00:21',
		AvgTalktime: '00:21',
		CallerDisconnects: '36.3% |17',
		AgentDisconnects: '36.3% |17'
	}
];

export const CAMPAIGN_PRODUCTIVITY: any = [
	{
		Skill: 'Basic',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'General',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'Support',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'Digital',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'Cannada_Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'UK_Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'Argentina_Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'India_Support',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Skill: 'Australia_Support',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	}
];

export const CAMPAIGN_PRODUCTIVITY_DETAILED_REPORTS: any = [
	{
		Date: '01/07/2022',
		Skill: 'Basic',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'General',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'Support',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'Digital',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: 'Total (01/07/2022)',
		Total: true,
		Hour: ' ',
		TotalCalls: '4',
		AnsweredCalls: '4',
		AbandonedCalls: '1',
		APT: '00:17',
		AHT: '06:19',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '1'
	},
	{
		Date: '01/07/2022',
		Skill: 'Cannada_Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'UK_Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'Argentina_Sales',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'India_Support',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	},
	{
		Date: '01/07/2022',
		Skill: 'Australia_Support',
		TotalCalls: '2',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '00:21',
		AHT: '00:21',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: '0'
	}
];

export const SYSTEM_SUMMARY_REPORT = [
	{
		CallType: 'Inbound',
		TotalCalls: '14',
		CallsConnected: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '00:21',
		CallerDisconnects: '36.3% | 17',
		TransferCalls: '36.3% | 17'
	},
	{
		CallType: 'Manual',
		TotalCalls: '43',
		CallsConnected: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '00:21',
		CallerDisconnects: '36.3% | 17',
		TransferCalls: '36.3% | 17'
	},
	{
		CallType: 'Progressive',
		TotalCalls: '33',
		CallsConnected: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '00:21',
		CallerDisconnects: '36.3% | 17',
		TransferCalls: '36.3% | 17'
	},
	{
		CallType: 'Predictive',
		TotalCalls: '9',
		CallsConnected: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '00:21',
		CallerDisconnects: '36.3% | 17',
		TransferCalls: '36.3% | 17'
	},
	{
		CallType: 'Voice',
		TotalCalls: '34',
		CallsConnected: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '00:21',
		CallerDisconnects: '36.3% | 17',
		TransferCalls: '36.3% | 17'
	},
	{
		CallType: 'Digital',
		TotalCalls: '87',
		CallsConnected: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '00:21',
		CallerDisconnects: '36.3% | 17',
		TransferCalls: '36.3% | 17'
	},
	{
		CallType: 'Preview',
		TotalCalls: '13',
		CallsConnected: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		AHT: '00:21',
		CallerDisconnects: '36.3% | 17',
		TransferCalls: '36.3% | 17'
	}
];

export const AGENT_DISPOSITION_REPORT = [
	{
		AgentName: 'Aayush Mann',
		Dispositions: 'AccountUpdates',
		TotalCalls: '8',
		ATT: '05:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Abhishek',
		Dispositions: 'AlleyComplaint',
		TotalCalls: '5',
		ATT: '06:19',
		AWT: '00:34'
	},
	{
		AgentName: { value: 'Chetan Roy', icon: UserPlaceholder1 },
		Dispositions: 'Available1',
		TotalCalls: '10',
		ATT: '06:19',
		AWT: '00:34'
	},
	{
		AgentName: 'Faisal Khan',
		Dispositions: 'Available_available',
		TotalCalls: '7',
		ATT: '06:19',
		AWT: '00:34'
	},
	{
		AgentName: { value: 'Gaurav Bansal', icon: UserPlaceholder2 },
		Dispositions: 'GeneralQuery',
		TotalCalls: '9',
		ATT: '06:19',
		AWT: '00:34'
	},
	{
		AgentName: 'Abhishek',
		Dispositions: 'AlleyComplaint',
		TotalCalls: '5',
		ATT: '06:19',
		AWT: '00:34'
	},
	{
		AgentName: { value: 'Chetan Roy', icon: UserPlaceholder1 },
		Dispositions: 'Available1',
		TotalCalls: '10',
		ATT: '06:19',
		AWT: '00:34'
	},
	{
		AgentName: 'Faisal Khan',
		Dispositions: 'Available_available',
		TotalCalls: '7',
		ATT: '06:19',
		AWT: '00:34'
	},
	{
		AgentName: { value: 'Gaurav Bansal', icon: UserPlaceholder2 },
		Dispositions: 'GeneralQuery',
		TotalCalls: '9',
		ATT: '06:19',
		AWT: '00:34'
	}
];

export const AGENT_EFFICIENCY_REPORT = [
	{
		Details: 'Average Talk Time',
		CompanyStatistics: '00:00:47',
		AgentStatistics: '00:01:35',
		Trigger: '- 00:01:12 ',
		AgentProductivity: 'Low'
	},
	{
		Details: 'Average Pickup Time',
		CompanyStatistics: '00:00:13',
		AgentStatistics: '00:00:20',
		Trigger: '+ 00:01:05',
		AgentProductivity: 'High'
	},
	{
		Details: 'Average Wrapup Time',
		CompanyStatistics: '00:00:25',
		AgentStatistics: '00:00:28',
		Trigger: '+ 00:01:07 ',
		AgentProductivity: 'High'
	},
	{
		Details: 'Total Login Duration',
		CompanyStatistics: '00:00:25',
		AgentStatistics: '00:00:28',
		Trigger: '+ 00:01:07 ',
		AgentProductivity: 'High'
	},
	{
		Details: 'Total Idle Time',
		CompanyStatistics: '00:00:47',
		AgentStatistics: '00:01:35',
		Trigger: '- 00:01:12',
		AgentProductivity: 'Low'
	},
	{
		Details: 'Abandoned Calls',
		CompanyStatistics: '14',
		AgentStatistics: '10',
		Trigger: '- 4',
		AgentProductivity: 'High'
	},
	{
		Details: 'Connected Percentage',
		CompanyStatistics: '14',
		AgentStatistics: '10',
		Trigger: '- 4',
		AgentProductivity: 'High'
	},
	{
		Details: 'Customer Disconnect',
		CompanyStatistics: '14',
		AgentStatistics: '10',
		Trigger: '- 4',
		AgentProductivity: 'High'
	},
	{
		Details: 'Average Hold Time',
		CompanyStatistics: '00:00:47',
		AgentStatistics: '00:01:35',
		Trigger: '- 00:01:12 ',
		AgentProductivity: 'Low'
	}
];

export const AGENT_PRODUCTIVITY: any = [
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		TotalInteractions: '20',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: { value: 'Chetan Roy', icon: UserPlaceholder1 },
		TotalCalls: '2',
		TotalInteractions: '35',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Abhishek',
		TotalCalls: '2',
		TotalInteractions: '14',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: { value: 'Faisal Khan', icon: UserPlaceholder2 },
		TotalCalls: '2',
		TotalInteractions: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Gaurav Bansal',
		TotalCalls: '2',
		TotalInteractions: '17',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		TotalInteractions: '20',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		TotalInteractions: '14',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		TotalInteractions: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		TotalInteractions: '17',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	}
];

export const AGENT_PRODUCTIVITY_DETAILED_REPORTS: any = [
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '20',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: { value: 'Chetan Roy', icon: UserPlaceholder1 },
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '35',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Abhishek',
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '14',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: { value: 'Faisal Khan', icon: UserPlaceholder2 },
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		TotalCalls: '2',
		Date: 'Total (01/07/2022)',
		TotalInteractions: '17',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '20',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '14',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '8',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '17',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		Date: 'Total (01/07/2022)',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	},
	{
		AgentName: 'Aayush Mann',
		TotalCalls: '2',
		Date: '01/11/2022',
		TotalInteractions: '207',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		APT: '05:21',
		ATT: '00:21',
		AWT: '02:38',
		CallerDiscounts: '36.3% | 17',
		TransferCalls: 0
	}
];

export const AGENT_STATE_SUMMARY_REPORT: any = [
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	}
];

export const AGENT_STATE_SUMMARY_DETAILED_REPORT: any = [
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		Date: '01/07/2022',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		Date: '01/07/2022',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		Date: '01/07/2022',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		Date: '01/07/2022',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		Date: '01/07/2022',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		Date: '01/07/2022',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		Date: '01/07/2022',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		Date: '01/07/2022',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		Date: '01/07/2022',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		Mode: 'Inbound',
		TotalBusyTime: '05:21:34',
		Date: '01/07/2022',
		TotalWrapupTime: '00:21:34',
		TotalPauseTime: '00:21:34',
		TotalDialTime: '00:21:34',
		TotalIdleTime: '05:21:34',
		TotalLoginDuration: '05:21:34'
	}
];

export const HOURLY_AGENT_CALL_REPORT: any = [
	{
		AgentName: 'Aayush Mann',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Aayush Mann',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Aayush Mann',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Total ',
		SubTotal: true,
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Piyush Singh',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Total ',
		SubTotal: true,
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Faisal Khan',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Faisal Khan',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Total ',
		SubTotal: true,
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Abhishek',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	}
];

export const HOURLY_AGENT_CALL_DETAILED_REPORT: any = [
	{
		AgentName: 'Aayush Mann',
		Date: '07/11/2022',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Aayush Mann',
		Date: '07/11/2022',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Aayush Mann',
		Date: '07/11/2022',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		Date: '',
		AgentName: 'Total ',
		SubTotal: true,
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Piyush Singh',
		Date: '07/11/2022',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		Date: '',
		AgentName: 'Total ',
		SubTotal: true,
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		Date: 'Grand Total (01/07/2022)',
		Total: true,
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Faisal Khan',
		Date: '07/11/2022',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		Date: '',
		AgentName: 'Total ',
		SubTotal: true,
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Aayush Mann',
		Date: '07/11/2022',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Aayush Mann',
		Date: '07/11/2022',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Aayush Mann',
		Date: '07/11/2022',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	},
	{
		AgentName: 'Aayush Mann',
		Date: '07/11/2022',
		Hour: '01:00 - 02:00',
		TotalCalls: '2',
		AnsweredCalls: '2',
		AbandonedCalls: '0',
		APT: '00:21',
		ATT: '00:21',
		AWT: '00:21'
	}
];

export const CHAT_DIPOSITION_SUMMARY: any = [
	{
		Diposition: 'Feedback_Good',
		TotalChats: '8'
	},
	{
		Diposition: 'General_Enquiry',
		TotalChats: '5'
	},
	{
		Diposition: 'Interested',
		TotalChats: '10'
	},
	{
		Diposition: 'Operation_GeneralQuiry',
		TotalChats: '7'
	},
	{
		Diposition: 'Gaurav Bansal',
		TotalChats: '9'
	},
	{
		Diposition: 'Aayush Mann',
		TotalChats: '7'
	},
	{
		Diposition: 'Abhishek',
		TotalChats: '3'
	},
	{
		Diposition: 'Chetan Roy',
		TotalChats: '1'
	},
	{
		Diposition: 'Faisal Khan',
		TotalChats: '3'
	},
	{
		Diposition: 'Gaurav Bansal',
		TotalChats: '2'
	}
];
export const AGENT_BREAK_DETAILS_REPORT = [
	{ TotalBreakTime: '00:01:19', SNO: 1, Break: 'Lunch reason', AgentName: 'Vathi', AgentID: 'Vathi' },
	{ TotalBreakTime: '00:00:17', SNO: 2, Break: 'test_kettle', AgentName: 'Vathi', AgentID: 'Vathi' },
	{ TotalBreakTime: '00:01:36', SNO: 3, Break: 'SUB TOTAL', AgentName: 'Vathi', AgentID: 'Vathi' },
	{ TotalBreakTime: '00:00:09', SNO: 4, Break: 'charan43', AgentName: 'vinay', AgentID: 'vinay' },
	{ TotalBreakTime: '00:00:09', SNO: 5, Break: 'Tea Time2', AgentName: 'vinay', AgentID: 'vinay' },
	{ TotalBreakTime: '00:00:18', SNO: 6, Break: 'SUB TOTAL', AgentName: 'vinay', AgentID: 'vinay' },
	{ TotalBreakTime: '00:01:54', SNO: 7, Break: 'GRAND TOTAL', AgentName: 'TOTAL' }
];

export const AGENT_CALL_SUMMARY: any = [
	{
		AgentName: 'Aayush Mann',
		CallType: 'Preview',
		TotalCalls: '/49.37.129.92.49955',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		TotalTalkTime: '05:21:34'
	},
	{
		AgentName: { value: 'Chetan Roy', icon: UserPlaceholder1 },
		CallType: 'Predictive',
		TotalCalls: '/49.37.129.92.49955',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		TotalTalkTime: '05:21:34'
	},
	{
		AgentName: 'Abhishek',
		CallType: 'Preview',
		TotalCalls: '/49.37.129.92.49955',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		TotalTalkTime: '05:21:34'
	},
	{
		AgentName: { value: 'Faisal Khan', icon: UserPlaceholder2 },
		CallType: 'Predictive',
		TotalCalls: '/49.37.129.92.49955',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		TotalTalkTime: '05:21:34'
	},
	{
		AgentName: 'Gaurav Bansal',
		CallType: 'Manual',
		TotalCalls: '/49.37.129.92.49955',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		TotalTalkTime: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		CallType: 'Progressive',
		TotalCalls: '/49.37.129.92.49955',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		TotalTalkTime: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		CallType: 'Preview',
		TotalCalls: '/49.37.129.92.49955',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		TotalTalkTime: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		CallType: 'Manual',
		TotalCalls: '/49.37.129.92.49955',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		TotalTalkTime: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		CallType: 'Predictive',
		TotalCalls: '/49.37.129.92.49955',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		TotalTalkTime: '05:21:34'
	},
	{
		AgentName: 'Aayush Mann',
		CallType: 'Progressive',
		TotalCalls: '/49.37.129.92.49955',
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17',
		TotalTalkTime: '05:21:34'
	}
];

export const HOURLY_AGENTS_SUMMARY_SERIES: any = [
	{
		name: 'Answered Calls',
		type: 'column',
		yAxis: 1,
		color: '#B0CFA5',
		data: [27.6, 28.8, 21.7, 34.1, 29.0, 28.4, 45.6, 51.7, 39.0, 60.0, 28.6, 32.1, 60.0, 28.6, 32.1, 29.0, 28.4, 27.6, 28.8, 21.7, 34.1, 29.0, 28.4],
		pointWidth: 8
	},
	{
		name: 'Abandoned Calls',
		type: 'column',
		yAxis: 1,
		color: '#FF92A6',
		data: [28.6, 29.8, 18.7, 28.1, 19.0, 18.4, 35.6, 41.7, 19.0, 50.0, 38.6, 42.1, 40.0, 38.6, 22.1, 29.8, 18.7, 18.4, 35.6, 41.7, 19.0, 50.0, 38.6],
		pointWidth: 8
	},
	{
		name: 'No.of Agents',
		color: '#3296FB',
		data: [-13.6, -14.9, -5.8, -0.7, -13.6, -0.7, 14.5, 10.8, 5.8, -0.7, -11.0, -16.4, -10.6, -14.9, -5.8, -4.5, 10.8, 5.8, -0.7, -5.0, -6.4, -13.6, -14.9]
	}
];

export const DAY_CALL_SUMMARY: any = [
	{
		StartTime: 'PhnPadma69',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: 'Test_032',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: 'Test_032',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: 'PhnPadma6',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: 'Jaibalakrishna',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: 'PhnPadma6',
		TotalCalls: 4,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	},
	{
		StartTime: 'Shivamani',
		TotalCalls: 2,
		AnsweredCalls: '36.3% | 17',
		AbandonedCalls: '36.3% | 17'
	}
];

export const DIGITAL_DETAIL_REPORT: any = [
	{
		Session: '+919686255526',
		MonitorUcid: '9062524175982455',
		Campaign: 'omni_channel',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 | 17:22:50',
		FirstResponseTime: '2022-06-14 | 17:22:50',
		AgentChatTime: '00:00:00',
		AgentChatStartTime: '2022-06-14 | 17:22:50',
		Node: 'NEXT_FREE_AGENT->CALLING->...',
		AgentName: 'Aayush Mann',
		Skill: 'Sales',
		EndBy: 'System: Agent Disconnect',
		Comments: '',
		Disposition: 'General Query',
		Name: '',
		Email: '',
		Phone: '+919686255526',
		UIUI: '+919686255526',
		ChatTranscript: 'Chat History',
		DownloadChatHistory: ''
	},
	{
		Session: '+919686255526',
		MonitorUcid: '9062524175982455',
		Campaign: 'omni_channel',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 | 17:22:50',
		FirstResponseTime: '2022-06-14 | 17:22:50',
		AgentChatTime: '00:00:00',
		AgentChatStartTime: '2022-06-14 | 17:22:50',
		Node: 'NEXT_FREE_AGENT->CALLING->...',
		AgentName: 'Aayush Mann',
		Skill: 'Sales',
		EndBy: 'System: Agent Disconnect',
		Comments: '',
		Disposition: 'General Query',
		Name: '',
		Email: '',
		Phone: '+919686255526',
		UIUI: '+919686255526',
		ChatTranscript: 'Chat History',
		DownloadChatHistory: ''
	},
	{
		Session: '+919686255526',
		MonitorUcid: '9062524175982455',
		Campaign: 'omni_channel',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 | 17:22:50',
		FirstResponseTime: '2022-06-14 | 17:22:50',
		AgentChatTime: '00:00:00',
		AgentChatStartTime: '2022-06-14 | 17:22:50',
		Node: 'NEXT_FREE_AGENT->CALLING->...',
		AgentName: 'Aayush Mann',
		Skill: 'Sales',
		EndBy: 'System: Agent Disconnect',
		Comments: '',
		Disposition: 'General Query',
		Name: '',
		Email: '',
		Phone: '+919686255526',
		UIUI: '+919686255526',
		ChatTranscript: 'Chat History',
		DownloadChatHistory: ''
	},
	{
		Session: '+919686255526',
		MonitorUcid: '9062524175982455',
		Campaign: 'omni_channel',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 | 17:22:50',
		FirstResponseTime: '2022-06-14 | 17:22:50',
		AgentChatTime: '00:00:00',
		AgentChatStartTime: '2022-06-14 | 17:22:50',
		Node: 'NEXT_FREE_AGENT->CALLING->...',
		AgentName: 'Aayush Mann',
		Skill: 'Sales',
		EndBy: 'System: Agent Disconnect',
		Comments: 'Customer is happy that we provi...',
		Disposition: 'General Query',
		Name: 'Rajesh',
		Email: 'rajesh@ozonetel.com',
		Phone: '+919686255526',
		UIUI: '+919686255526',
		ChatTranscript: 'Chat History',
		DownloadChatHistory: ''
	},
	{
		Session: '+919686255526',
		MonitorUcid: '9062524175982455',
		Campaign: 'omni_channel',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 | 17:22:50',
		FirstResponseTime: '2022-06-14 | 17:22:50',
		AgentChatTime: '00:00:00',
		AgentChatStartTime: '2022-06-14 | 17:22:50',
		Node: 'NEXT_FREE_AGENT->CALLING->...',
		AgentName: 'Aayush Mann',
		Skill: 'Sales',
		EndBy: 'System: Agent Disconnect',
		Comments: '',
		Disposition: 'General Query',
		Name: '',
		Email: '',
		Phone: '+919686255526',
		UIUI: '+919686255526',
		ChatTranscript: 'Chat History',
		DownloadChatHistory: ''
	},
	{
		Session: '+919686255526',
		MonitorUcid: '9062524175982455',
		Campaign: 'omni_channel',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 | 17:22:50',
		FirstResponseTime: '2022-06-14 | 17:22:50',
		AgentChatTime: '00:00:00',
		AgentChatStartTime: '2022-06-14 | 17:22:50',
		Node: 'NEXT_FREE_AGENT->CALLING->...',
		AgentName: 'Aayush Mann',
		Skill: 'Sales',
		EndBy: 'System: Agent Disconnect',
		Comments: 'System: No agent',
		Disposition: 'General Query',
		Name: 'Suresh',
		Email: 'suresh@ozonetel.com',
		Phone: '+919686255526',
		UIUI: '+919686255526',
		ChatTranscript: 'Chat History',
		DownloadChatHistory: ''
	},
	{
		Session: '+919686255526',
		MonitorUcid: '9062524175982455',
		Campaign: 'omni_channel',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 | 17:22:50',
		FirstResponseTime: '2022-06-14 | 17:22:50',
		AgentChatTime: '00:00:00',
		AgentChatStartTime: '2022-06-14 | 17:22:50',
		Node: 'NEXT_FREE_AGENT->CALLING->...',
		AgentName: 'Aayush Mann',
		Skill: 'Sales',
		EndBy: 'System: Agent Disconnect',
		Comments: '',
		Disposition: 'General Query',
		Name: 'Akash',
		Email: 'aakash@ozonetel.com',
		Phone: '+919686255526',
		UIUI: '+919686255526',
		ChatTranscript: 'Chat History',
		DownloadChatHistory: ''
	},
	{
		Session: '+919686255526',
		MonitorUcid: '9062524175982455',
		Campaign: 'omni_channel',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 | 17:22:50',
		FirstResponseTime: '2022-06-14 | 17:22:50',
		AgentChatTime: '00:00:00',
		AgentChatStartTime: '2022-06-14 | 17:22:50',
		Node: 'NEXT_FREE_AGENT->CALLING->...',
		AgentName: 'Aayush Mann',
		Skill: 'Sales',
		EndBy: 'System: Agent Disconnect',
		Comments: 'Test',
		Disposition: 'General Query',
		Name: 'Vinod',
		Email: 'vinod@ozonetel.com',
		Phone: '+919686255526',
		UIUI: '+919686255526',
		ChatTranscript: 'Chat History',
		DownloadChatHistory: ''
	},
	{
		Session: '+919686255526',
		MonitorUcid: '9062524175982455',
		Campaign: 'omni_channel',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 | 17:22:50',
		FirstResponseTime: '2022-06-14 | 17:22:50',
		AgentChatTime: '00:00:00',
		AgentChatStartTime: '2022-06-14 | 17:22:50',
		Node: 'NEXT_FREE_AGENT->CALLING->...',
		AgentName: 'Aayush Mann',
		Skill: 'Sales',
		EndBy: 'System: Agent Disconnect',
		Comments: 'Random Comments',
		Disposition: 'General Query',
		Name: '',
		Email: '',
		Phone: '+919686255526',
		UIUI: '+919686255526',
		ChatTranscript: 'Chat History',
		DownloadChatHistory: ''
	},
	{
		Session: '+919686255526',
		MonitorUcid: '9062524175982455',
		Campaign: 'omni_channel',
		StartTime: '2022-06-14 | 17:22:50',
		EndTime: '2022-06-14 | 17:22:50',
		FirstResponseTime: '2022-06-14 | 17:22:50',
		AgentChatTime: '00:00:00',
		AgentChatStartTime: '2022-06-14 | 17:22:50',
		Node: 'NEXT_FREE_AGENT->CALLING->...',
		AgentName: 'Aayush Mann',
		Skill: 'Sales',
		EndBy: 'System: Agent Disconnect',
		Comments: 'Random Comments',
		Disposition: 'General Query',
		Name: '',
		Email: '',
		Phone: '+919686255526',
		UIUI: '+919686255526',
		ChatTranscript: 'Chat History',
		DownloadChatHistory: ''
	}
];

export const REPORTS_MOCK = [
	{
		SerialNumber: '1',
		ReportName: 'Agent Breaks Miscellaneus',
		Description: 'Agent Breaks Miscellaneus'
	},
	{
		SerialNumber: '2',
		ReportName: 'Agent Disposition Inbound Online',
		Description: 'Agent Disposition Inbound Online'
	},
	{
		SerialNumber: '3',
		ReportName: 'Agent Disposition Outbound Online',
		Description: 'Agent Disposition Outbound Online'
	},
	{
		SerialNumber: '4',
		ReportName: 'Agent Hourly Summary Miscellaneus',
		Description: 'Agent Hourly Summary Miscellaneus'
	},
	{
		SerialNumber: '5',
		ReportName: 'Agent Performance Miscellaneus',
		Description: 'Agent Performance Miscellaneus'
	},
	{
		SerialNumber: '6',
		ReportName: 'Agent Performance Inbound Online',
		Description: 'Agent Performance Inbound Online'
	},
	{
		SerialNumber: '7',
		ReportName: 'Agent Performance Outbound Online',
		Description: 'Agent Performance Outbound Online'
	},
	{
		SerialNumber: '8',
		ReportName: 'Agent Summary Miscellaneus',
		Description: 'Agent Summary Miscellaneus'
	},
	{
		SerialNumber: '9',
		ReportName: 'Call Master Inbound Offline',
		Description: 'Call Master Inbound Offline'
	},
	{
		SerialNumber: '10',
		ReportName: 'Call Master Outbound Offline',
		Description: 'Call Master Outbound Offline'
	}
];

export const CALL_DETAILS_LIVE_REPORT: any = [
	{
		UCID: { value: 'CO-0044', eventColor: 'primary-color', isClickable: true },
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Location: 'Lorem ipsum',
		CallerID: '+91970331632',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46'
	},
	{
		UCID: { value: 'CO-0060', eventColor: 'primary-color', isClickable: true },
		UUI: '19896199076',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Location: 'Lorem ipsum',
		CallerID: '+919703331632',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '18:40:48',
		AnswerTime: '18:40:48'
	},
	{
		UCID: { value: 'CO-0021', eventColor: 'primary-color', isClickable: true },
		UUI: '14584375316',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Location: 'Lorem ipsum',
		CallerID: '+919703331632',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:21:09',
		AnswerTime: '20:21:09'
	},
	{
		UCID: { value: 'CO-0053', eventColor: 'primary-color', isClickable: true },
		UUI: '17525687291',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Location: 'Lorem ipsum',
		CallerID: '+919703331632',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '00:20:00',
		AnswerTime: '00:20:00'
	},
	{
		UCID: { value: 'CO-0096', eventColor: 'primary-color', isClickable: true },
		UUI: '18945143342',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Location: 'Lorem ipsum',
		CallerID: '+919703331632',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '11:07:27',
		AnswerTime: '11:07:27'
	},
	{
		UCID: { value: 'CO-0080', eventColor: 'primary-color', isClickable: true },
		UUI: '13361838104',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Location: 'Lorem ipsum',
		CallerID: '+919703331632',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '19:37:58',
		AnswerTime: '19:37:58'
	},
	{
		UCID: { value: 'CO-0069', eventColor: 'primary-color', isClickable: true },
		UUI: '18363627539',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Location: 'Lorem ipsum',
		CallerID: '+919703331632',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '21:59:38',
		AnswerTime: '21:59:38'
	},
	{
		UCID: { value: 'CO-0091', eventColor: 'primary-color', isClickable: true },
		UUI: '17841907570',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Location: 'Lorem ipsum',
		CallerID: '+919703331632',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '05:36:15',
		AnswerTime: '05:36:15'
	},
	{
		UCID: { value: 'CO-0044', eventColor: 'primary-color', isClickable: true },
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Location: 'Lorem ipsum',
		CallerID: '+919703331632',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '10:54:19',
		AnswerTime: '10:54:19'
	},
	{
		UCID: { value: 'CO-0043', eventColor: 'primary-color', isClickable: true },
		UUI: '18293636730',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Location: 'Lorem ipsum',
		CallerID: '+919703331632',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '09:58:48',
		AnswerTime: '09:58:48'
	}
];

export const SCHEDULED_REPORTS_MOCK = [
	{
		JobID: '12564',
		JobName: 'Lorem Ipsum',
		Report: 'Call Details Report',
		StartTime: '07/07/2022 | 11:29:07',
		JobDetails: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	}
];

export const CALL_BACKS: any = [
	{
		SNO: 1,
		Comments: 'tyjkl;',
		CallbackNumber: '09398119727',
		AgentName: 'manish',
		CallBackDate: '2022-10-20 11:27:50'
	},
	{
		SNO: 2,
		Comments: '',
		CallbackNumber: '9177990051',
		AgentName: 'manish',
		CallBackDate: '2022-10-21 15:15:00'
	},
	{
		SNO: 3,
		Comments: 'ertyuio',
		CallbackNumber: '09515345502',
		AgentName: 'manish',
		CallBackDate: '2022-11-01 20:09:02'
	},
	{
		SNO: 4,
		Comments: 'Manish inbound callback ',
		CallbackNumber: '9121015635',
		AgentName: 'manish',
		CallBackDate: '2022-11-02 20:09:02'
	},
	{
		SNO: 5,
		Comments: '',
		CallbackNumber: '7780462448',
		AgentName: 'Priya@123',
		CallBackDate: '2022-10-31 18:07:22'
	},
	{
		SNO: 6,
		Comments: '',
		CallbackNumber: '09553513237',
		AgentName: 'Priya@123',
		CallBackDate: '2022-11-01 14:06:52'
	},
	{
		SNO: 7,
		Comments: '2345t6y',
		CallbackNumber: '00919492335969',
		AgentName: 'manish007',
		CallBackDate: '2022-11-01 18:11:29'
	},
	{
		SNO: 8,
		Comments: 'manish today callback',
		CallbackNumber: '8790026882',
		AgentName: 'manish007',
		CallBackDate: '2022-11-01 19:00:26'
	},
	{
		SNO: 9,
		Comments: 'Comment',
		CallbackNumber: '08187867008',
		AgentName: 'Salman',
		CallBackDate: '2022-12-02 12:20:38'
	},
	{
		SNO: 10,
		Comments: '',
		CallbackNumber: '09502013634',
		AgentName: 'Salman',
		CallBackDate: '2022-12-02 12:05:38'
	}
];

export const AGENT_STATE_DETAIL_MOCK = [
	{
		TotalCalls: '0',
		AbandonedCalls: '0',
		AverageSpeedToAnswer: '0',
		AnsweredCalls: '0',
		WrapupDuration: '0',
		TotalPauseDuration: '0',
		AverageHoldDuration: '0',
		TotalLoginDuration: '0',
		AverageHandlingDuration: '0',
		SNO: 2,
		AgentName: 'Kantha'
	},
	{
		TotalCalls: '0',
		AbandonedCalls: '0',
		AverageSpeedToAnswer: '0',
		AnsweredCalls: '0',
		WrapupDuration: '0',
		TotalPauseDuration: '0',
		AverageHoldDuration: '0',
		TotalLoginDuration: '0',
		AverageHandlingDuration: '0',
		SNO: 3,
		AgentName: 'Priya@123'
	},
	{
		TotalCalls: '0',
		AbandonedCalls: '0',
		AverageSpeedToAnswer: '0',
		AnsweredCalls: '0',
		WrapupDuration: '0',
		TotalPauseDuration: '0',
		AverageHoldDuration: '0',
		TotalLoginDuration: '0',
		AverageHandlingDuration: '0',
		SNO: 4,
		AgentName: 'NameisHarii'
	}
];

export const AGENT_EVENT_MOCK = [
	{ AgentMode: 'INBOUND', Event: 'login', EventDetails: '/49.37.128.224:54042', StartTime: '2022-11-0921:32:42', EndTime: '' },
	{ AgentMode: 'INBOUND', Event: 'AUX', EventDetails: 'changeMode', StartTime: '2022-11-0921:32:42', EndTime: '2022-11-0921:32:44' },
	{ AgentMode: 'MANUAL', Event: 'AUX', EventDetails: 'changeMode', StartTime: '2022-11-0921:32:44', EndTime: '2022-11-0921:32:46' },
	{ AgentMode: 'MANUAL', Event: 'release', EventDetails: 'releaseByAgent', StartTime: '2022-11-0921:32:46', EndTime: '2022-11-0921:32:54' },
	{ AgentMode: 'MANUAL', Event: 'calling', EventDetails: '', StartTime: '2022-11-0921:32:54', EndTime: '2022-11-0921:33:13' },
	{ AgentMode: 'MANUAL', Event: 'IDLE', EventDetails: '', StartTime: '2022-11-0921:33:13', EndTime: '2022-11-0921:33:41' },
	{ AgentMode: 'MANUAL', Event: 'calling', EventDetails: '', StartTime: '2022-11-0921:33:41', EndTime: '2022-11-0921:33:50' },
	{ AgentMode: 'MANUAL', Event: 'IDLE', EventDetails: '', StartTime: '2022-11-0921:33:50', EndTime: '2022-11-0921:33:56' },
	{ AgentMode: 'MANUAL', Event: 'calling', EventDetails: '', StartTime: '2022-11-0921:33:56', EndTime: '2022-11-0921:34:07' },
	{ AgentMode: 'MANUAL', Event: 'incall', EventDetails: '', StartTime: '2022-11-0921:34:07', EndTime: '2022-11-0921:36:53' }
];

export const CALL_DETAILS_MODAL_REPORT: any = [
	{
		UCID: 'CO-0091',
		UUI: '17841907570',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Location: 'Lorem ipsum',
		CallerID: '+919703331632',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '05:36:15',
		AnswerTime: '05:36:15',
		EndTime: '20:53:46',
		Duration: '20:53:46',
		FallbackRule: 'Agent Dial',
		Reason: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: 'Aayush Mann',
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum',
		Comments: '“Lorem ipsum dolor sit amet ...”'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Location: 'Lorem ipsum',
		CallerID: '+919703331632',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '10:54:19',
		AnswerTime: '10:54:19',
		EndTime: '20:53:46',
		Duration: '20:53:46',
		FallbackRule: 'Agent Dial',
		Reason: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: 'Aayush Mann',
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum',
		Comments: '“Lorem ipsum dolor sit amet ...”'
	}
];

export const ANSWERED_CALLS: any = [
	{
		agentName: 'Aayush Mann',
		percentage: 60,
		currentValue: 10,
		totalValue: 15
	},
	{
		agentName: 'Chetan Roy',
		percentage: 65,
		currentValue: 8,
		totalValue: 12
	},
	{
		agentName: 'Abhishek',
		percentage: 50,
		currentValue: 13,
		totalValue: 25
	}
];

export const ANSWERED_CALLS_PERCENTAGE: any = [
	{
		agentName: 'Aayush Mann',
		percentage: 75
	},
	{
		agentName: 'Chetan Roy',
		percentage: 82
	},
	{
		agentName: 'Abhishek',
		percentage: 70
	}
];

export const AVG_TALK_TIME: any = [
	{
		agentName: 'Aayush Mann',
		time: '00:06:48'
	},
	{
		agentName: 'Chetan Roy',
		time: '00:07:00'
	},
	{
		agentName: 'Abhishek',
		time: '00:08:21'
	}
];

export const AVG_PICKUP_TIME: any = [
	{
		agentName: 'Aayush Mann',
		time: '00:00:11'
	},
	{
		agentName: 'Chetan Roy',
		time: '00:00:18'
	},
	{
		agentName: 'Abhishek',
		time: '00:00:20'
	}
];

export const AVG_WRAPUP_TIME: any = [
	{
		agentName: 'Aayush Mann',
		time: '00:00:37'
	},
	{
		agentName: 'Chetan Roy',
		time: '00:00:48'
	},
	{
		agentName: 'Abhishek',
		time: '00:01:01'
	}
];

export const AGENT_HANGUPS: any = [
	{
		agentName: 'Aayush Mann',
		percentage: 23
	},
	{
		agentName: 'Chetan Roy',
		percentage: 31
	},
	{
		agentName: 'Abhishek',
		percentage: 36
	}
];

export const DIGITAL_DETAILS_LIVE_REPORT: any = [
	{
		SeqId: 2061107,
		SessionId: '917005504866',
		StartDate: '2022-12-08',
		StartTime: '14:28:45',
		EndDate: '2022-12-08',
		EndTime: '14:28:46',
		Node: 'NEXT_FREE_AGENT',
		Campaign_ID: 92437,
		Skill: 'general',
		AgentID: null,
		Transcript: null,
		Disposition: null,
		Comments: null,
		EndBy: null,
		PhoneNumer: 917005504866,
		UUI: '',
		CustName: null,
		CustMail: null,
		user_id: 7410,
		monitorUcid: 74101670489924690
	},
	{
		SeqId: 2061108,
		SessionId: '917005504866',
		StartDate: '2022-12-08',
		StartTime: '14:28:46',
		EndDate: '2022-12-08',
		EndTime: '14:45:17',
		Node: 'AGENT',
		Campaign_ID: 92437,
		Skill: 'general',
		AgentID: 'Megha',
		Transcript: '',
		Disposition: 'chatEndedByRuleTimeout',
		Comments: '',
		EndBy: 'Agent',
		PhoneNumer: 917005504866,
		UUI: '',
		CustName: null,
		CustMail: null,
		user_id: 7410,
		monitorUcid: 74101670489924690
	}
];

export const IVR_FEEDBACK_REPORT: any = [
	{
		CampaignName: 'Lorem ipsum dolor sit amet',
		CallID: 18140219333,
		AgentName: { value: 'Aayush Mann', icon: 'user_icon' },
		SkillName: 'Satisfied',
		CallerNo: 18140219333,
		StartTime: '07/07/2022 | 11:30:07',
		EndTime: '07/07/2022 | 11:30:07',
		Duration: '20:53:46',
		Feedback: 'Question 1',
		QuestionNumber: 1,
		OptionNumber: 1,
		OptionResponse: 'Satisfied',
		ExtraData: 'NULL',
		Audio: '',
		Download: ''
	},
	{
		CampaignName: 'Lorem ipsum dolor sit amet',
		CallID: 18140219333,
		AgentName: { value: 'Abhishek', icon: 'user_icon' },
		SkillName: 'Satisfied',
		CallerNo: 18140219333,
		StartTime: '07/07/2022 | 11:30:07',
		EndTime: '07/07/2022 | 11:30:07',
		Duration: '20:53:46',
		Feedback: 'Question 1',
		QuestionNumber: 1,
		OptionNumber: 1,
		OptionResponse: 'Satisfied',
		ExtraData: 'NULL',
		Audio: '',
		Download: ''
	},
	{
		CampaignName: 'Lorem ipsum dolor sit amet',
		CallID: 18140219333,
		AgentName: { value: 'Chetan Roy', icon: 'user_icon' },
		SkillName: 'Satisfied',
		CallerNo: 18140219333,
		StartTime: '07/07/2022 | 11:30:07',
		EndTime: '07/07/2022 | 11:30:07',
		Duration: '20:53:46',
		Feedback: 'Question 1',
		QuestionNumber: 1,
		OptionNumber: 1,
		OptionResponse: 'Satisfied',
		ExtraData: 'NULL',
		Audio: '',
		Download: ''
	},
	{
		CampaignName: 'Lorem ipsum dolor sit amet',
		CallID: 18140219333,
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		SkillName: 'Satisfied',
		CallerNo: 18140219333,
		StartTime: '07/07/2022 | 11:30:07',
		EndTime: '07/07/2022 | 11:30:07',
		Duration: '20:53:46',
		Feedback: 'Question 1',
		QuestionNumber: 1,
		OptionNumber: 1,
		OptionResponse: 'Satisfied',
		ExtraData: 'NULL',
		Audio: '',
		Download: ''
	},
	{
		CampaignName: 'Lorem ipsum dolor sit amet',
		CallID: 18140219333,
		AgentName: { value: 'Gaurav Bansal', icon: 'user_icon' },
		SkillName: 'Satisfied',
		CallerNo: 18140219333,
		StartTime: '07/07/2022 | 11:30:07',
		EndTime: '07/07/2022 | 11:30:07',
		Duration: '20:53:46',
		Feedback: 'Question 1',
		QuestionNumber: 1,
		OptionNumber: 1,
		OptionResponse: 'Satisfied',
		ExtraData: 'NULL',
		Audio: '',
		Download: ''
	},
	{
		CampaignName: 'Lorem ipsum dolor sit amet',
		CallID: 18140219333,
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		SkillName: 'Satisfied',
		CallerNo: 18140219333,
		StartTime: '07/07/2022 | 11:30:07',
		EndTime: '07/07/2022 | 11:30:07',
		Duration: '20:53:46',
		Feedback: 'Question 1',
		QuestionNumber: 1,
		OptionNumber: 1,
		OptionResponse: 'Satisfied',
		ExtraData: 'NULL',
		Audio: '',
		Download: ''
	},
	{
		CampaignName: 'Lorem ipsum dolor sit amet',
		CallID: 18140219333,
		AgentName: { value: 'Gaurav Bansal', icon: 'user_icon' },
		SkillName: 'Satisfied',
		CallerNo: 18140219333,
		StartTime: '07/07/2022 | 11:30:07',
		EndTime: '07/07/2022 | 11:30:07',
		Duration: '20:53:46',
		Feedback: 'Question 1',
		QuestionNumber: 1,
		OptionNumber: 1,
		OptionResponse: 'Satisfied',
		ExtraData: 'NULL',
		Audio: '',
		Download: ''
	},
	{
		CampaignName: 'Lorem ipsum dolor sit amet',
		CallID: 18140219333,
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		SkillName: 'Satisfied',
		CallerNo: 18140219333,
		StartTime: '07/07/2022 | 11:30:07',
		EndTime: '07/07/2022 | 11:30:07',
		Duration: '20:53:46',
		Feedback: 'Question 1',
		QuestionNumber: 1,
		OptionNumber: 1,
		OptionResponse: 'Satisfied',
		ExtraData: 'NULL',
		Audio: '',
		Download: ''
	},
	{
		CampaignName: 'Lorem ipsum dolor sit amet',
		CallID: 18140219333,
		AgentName: { value: 'Gaurav Bansal', icon: 'user_icon' },
		SkillName: 'Satisfied',
		CallerNo: 18140219333,
		StartTime: '07/07/2022 | 11:30:07',
		EndTime: '07/07/2022 | 11:30:07',
		Duration: '20:53:46',
		Feedback: 'Question 1',
		QuestionNumber: 1,
		OptionNumber: 1,
		OptionResponse: 'Satisfied',
		ExtraData: 'NULL',
		Audio: '',
		Download: ''
	},
	{
		CampaignName: 'Lorem ipsum dolor sit amet',
		CallID: 18140219333,
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		SkillName: 'Satisfied',
		CallerNo: 18140219333,
		StartTime: '07/07/2022 | 11:30:07',
		EndTime: '07/07/2022 | 11:30:07',
		Duration: '20:53:46',
		Feedback: 'Question 1',
		QuestionNumber: 1,
		OptionNumber: 1,
		OptionResponse: 'Satisfied',
		ExtraData: 'NULL',
		Audio: '',
		Download: ''
	}
];

export const MISSED_CALL_LIVE_REPORT: any = [
	{
		CallerNo: '18140219333',
		CallCount: 5,
		Duration: '20:53:46',
		LastCallTime: '07/07/2022 | 11:30:07',
		FirstCallTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		SkillName: 'Satisfied',
		FallbackRule: 'Queue'
	},
	{
		CallerNo: '18140219333',
		CallCount: 5,
		Duration: '20:53:46',
		LastCallTime: '07/07/2022 | 11:30:07',
		FirstCallTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		SkillName: 'Satisfied',
		FallbackRule: 'Queue'
	},
	{
		CallerNo: '18140219333',
		CallCount: 5,
		Duration: '20:53:46',
		LastCallTime: '07/07/2022 | 11:30:07',
		FirstCallTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		SkillName: 'Satisfied',
		FallbackRule: 'Queue'
	},
	{
		CallerNo: '18140219333',
		CallCount: 5,
		Duration: '20:53:46',
		LastCallTime: '07/07/2022 | 11:30:07',
		FirstCallTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		SkillName: 'Satisfied',
		FallbackRule: 'Queue'
	},
	{
		CallerNo: '18140219333',
		CallCount: 5,
		Duration: '20:53:46',
		LastCallTime: '07/07/2022 | 11:30:07',
		FirstCallTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		SkillName: 'Satisfied',
		FallbackRule: 'Queue'
	},
	{
		CallerNo: '18140219333',
		CallCount: 5,
		Duration: '20:53:46',
		LastCallTime: '07/07/2022 | 11:30:07',
		FirstCallTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		SkillName: 'Satisfied',
		FallbackRule: 'Queue'
	},
	{
		CallerNo: '18140219333',
		CallCount: 5,
		Duration: '20:53:46',
		LastCallTime: '07/07/2022 | 11:30:07',
		FirstCallTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		SkillName: 'Satisfied',
		FallbackRule: 'Queue'
	},
	{
		CallerNo: '18140219333',
		CallCount: 5,
		Duration: '20:53:46',
		LastCallTime: '07/07/2022 | 11:30:07',
		FirstCallTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		SkillName: 'Satisfied',
		FallbackRule: 'Queue'
	},
	{
		CallerNo: '18140219333',
		CallCount: 5,
		Duration: '20:53:46',
		LastCallTime: '07/07/2022 | 11:30:07',
		FirstCallTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		SkillName: 'Satisfied',
		FallbackRule: 'Queue'
	},
	{
		CallerNo: '18140219333',
		CallCount: 5,
		Duration: '20:53:46',
		LastCallTime: '07/07/2022 | 11:30:07',
		FirstCallTime: '07/07/2022 | 11:30:07',
		LastCallEndTime: '07/07/2022 | 11:30:07',
		SkillName: 'Satisfied',
		FallbackRule: 'Queue'
	}
];

export const AGENT_STATUS_SKILL_REPORT: any = [
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 4,
		AgentsAvailable: 4,
		AgentsRinging: 4,
		AgentsonACDCall: 4,
		AgentsinACW: 4,
		AgentsinOther: 4,
		AgentsinAUX: 4,
		AgentsStaffed: 4
	},
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 5,
		AgentsAvailable: 5,
		AgentsRinging: 5,
		AgentsonACDCall: 5,
		AgentsinACW: 5,
		AgentsinOther: 5,
		AgentsinAUX: 5,
		AgentsStaffed: 5
	},
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 3,
		AgentsAvailable: 3,
		AgentsRinging: 3,
		AgentsonACDCall: 3,
		AgentsinACW: 3,
		AgentsinOther: 3,
		AgentsinAUX: 3,
		AgentsStaffed: 3
	},
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 2,
		AgentsAvailable: 2,
		AgentsRinging: 2,
		AgentsonACDCall: 2,
		AgentsinACW: 2,
		AgentsinOther: 2,
		AgentsinAUX: 2,
		AgentsStaffed: 2
	},
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 6,
		AgentsAvailable: 6,
		AgentsRinging: 6,
		AgentsonACDCall: 6,
		AgentsinACW: 6,
		AgentsinOther: 6,
		AgentsinAUX: 6,
		AgentsStaffed: 6
	},
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 5,
		AgentsAvailable: 5,
		AgentsRinging: 5,
		AgentsonACDCall: 5,
		AgentsinACW: 5,
		AgentsinOther: 5,
		AgentsinAUX: 5,
		AgentsStaffed: 5
	},
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 2,
		AgentsAvailable: 2,
		AgentsRinging: 2,
		AgentsonACDCall: 2,
		AgentsinACW: 2,
		AgentsinOther: 2,
		AgentsinAUX: 2,
		AgentsStaffed: 2
	},
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 4,
		AgentsAvailable: 4,
		AgentsRinging: 4,
		AgentsonACDCall: 4,
		AgentsinACW: 4,
		AgentsinOther: 4,
		AgentsinAUX: 4,
		AgentsStaffed: 4
	},
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 3,
		AgentsAvailable: 3,
		AgentsRinging: 3,
		AgentsonACDCall: 3,
		AgentsinACW: 3,
		AgentsinOther: 3,
		AgentsinAUX: 3,
		AgentsStaffed: 3
	},
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 4,
		AgentsAvailable: 4,
		AgentsRinging: 4,
		AgentsonACDCall: 4,
		AgentsinACW: 4,
		AgentsinOther: 4,
		AgentsinAUX: 4,
		AgentsStaffed: 4
	},
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 6,
		AgentsAvailable: 6,
		AgentsRinging: 6,
		AgentsonACDCall: 6,
		AgentsinACW: 6,
		AgentsinOther: 6,
		AgentsinAUX: 6,
		AgentsStaffed: 6
	},
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 4,
		AgentsAvailable: 4,
		AgentsRinging: 4,
		AgentsonACDCall: 4,
		AgentsinACW: 4,
		AgentsinOther: 4,
		AgentsinAUX: 4,
		AgentsStaffed: 4
	},
	{
		SkillName: 'Lorem ipsum',
		QueueCount: 2,
		AgentsAvailable: 2,
		AgentsRinging: 2,
		AgentsonACDCall: 2,
		AgentsinACW: 2,
		AgentsinOther: 2,
		AgentsinAUX: 2,
		AgentsStaffed: 2
	}
];

export const SKILL_WISE_SKILL_REPORT: any = [
	{
		SkillName: 'Lorem ipsum',
		CallsWaitingCount: 4,
		OldestCallWaitingTime: '20:53:46',
		AvgAnswerTime: '20:53:46',
		ACDCalls: 4,
		AvgACDTime: '20:53:46',
		AbandonedCalls: 4,
		AvgAbandonedCallTime: '20:53:46'
	},
	{
		SkillName: 'Lorem ipsum',
		CallsWaitingCount: 4,
		OldestCallWaitingTime: '20:53:46',
		AvgAnswerTime: '20:53:46',
		ACDCalls: 4,
		AvgACDTime: '20:53:46',
		AbandonedCalls: 4,
		AvgAbandonedCallTime: '20:53:46'
	},
	{
		SkillName: 'Lorem ipsum',
		CallsWaitingCount: 4,
		OldestCallWaitingTime: '20:53:46',
		AvgAnswerTime: '20:53:46',
		ACDCalls: 4,
		AvgACDTime: '20:53:46',
		AbandonedCalls: 4,
		AvgAbandonedCallTime: '20:53:46'
	},
	{
		SkillName: 'Lorem ipsum',
		CallsWaitingCount: 4,
		OldestCallWaitingTime: '20:53:46',
		AvgAnswerTime: '20:53:46',
		ACDCalls: 4,
		AvgACDTime: '20:53:46',
		AbandonedCalls: 4,
		AvgAbandonedCallTime: '20:53:46'
	},
	{
		SkillName: 'Lorem ipsum',
		CallsWaitingCount: 4,
		OldestCallWaitingTime: '20:53:46',
		AvgAnswerTime: '20:53:46',
		ACDCalls: 4,
		AvgACDTime: '20:53:46',
		AbandonedCalls: 4,
		AvgAbandonedCallTime: '20:53:46'
	},
	{
		SkillName: 'Lorem ipsum',
		CallsWaitingCount: 4,
		OldestCallWaitingTime: '20:53:46',
		AvgAnswerTime: '20:53:46',
		ACDCalls: 4,
		AvgACDTime: '20:53:46',
		AbandonedCalls: 4,
		AvgAbandonedCallTime: '20:53:46'
	},
	{
		SkillName: 'Lorem ipsum',
		CallsWaitingCount: 4,
		OldestCallWaitingTime: '20:53:46',
		AvgAnswerTime: '20:53:46',
		ACDCalls: 4,
		AvgACDTime: '20:53:46',
		AbandonedCalls: 4,
		AvgAbandonedCallTime: '20:53:46'
	},
	{
		SkillName: 'Lorem ipsum',
		CallsWaitingCount: 4,
		OldestCallWaitingTime: '20:53:46',
		AvgAnswerTime: '20:53:46',
		ACDCalls: 4,
		AvgACDTime: '20:53:46',
		AbandonedCalls: 4,
		AvgAbandonedCallTime: '20:53:46'
	},
	{
		SkillName: 'Lorem ipsum',
		CallsWaitingCount: 4,
		OldestCallWaitingTime: '20:53:46',
		AvgAnswerTime: '20:53:46',
		ACDCalls: 4,
		AvgACDTime: '20:53:46',
		AbandonedCalls: 4,
		AvgAbandonedCallTime: '20:53:46'
	},
	{
		SkillName: 'Lorem ipsum',
		CallsWaitingCount: 4,
		OldestCallWaitingTime: '20:53:46',
		AvgAnswerTime: '20:53:46',
		ACDCalls: 4,
		AvgACDTime: '20:53:46',
		AbandonedCalls: 4,
		AvgAbandonedCallTime: '20:53:46'
	}
];

export const SMS_LIVE_REPORT: any = [
	{
		SMSNumber: '07/07/2022 | 11:30:07',
		SMSDate: '07/07/2022 | 11:30:07',
		Status: 'Failed'
	},
	{
		SMSNumber: '07/07/2022 | 11:30:07',
		SMSDate: '07/07/2022 | 11:30:07',
		Status: 'Failed'
	},
	{
		SMSNumber: '07/07/2022 | 11:30:07',
		SMSDate: '07/07/2022 | 11:30:07',
		Status: 'Failed'
	},
	{
		SMSNumber: '07/07/2022 | 11:30:07',
		SMSDate: '07/07/2022 | 11:30:07',
		Status: 'Failed'
	},
	{
		SMSNumber: '07/07/2022 | 11:30:07',
		SMSDate: '07/07/2022 | 11:30:07',
		Status: 'Failed'
	},
	{
		SMSNumber: '07/07/2022 | 11:30:07',
		SMSDate: '07/07/2022 | 11:30:07',
		Status: 'Failed'
	},
	{
		SMSNumber: '07/07/2022 | 11:30:07',
		SMSDate: '07/07/2022 | 11:30:07',
		Status: 'Failed'
	}
];

export const CUSTOMER_INTERACTION_REPORT: any = [
	{
		Operator: '',
		Circle: '',
		CallerNo: '9553513237',
		CallDate: '2022-11-28',
		SNO: 1,
		OutputResponse: '',
		CallID: '327116696155282',
		campign_name: 'InboundDilaing',
		CallTime: '11:35:59',
		InputProvided: '',
		campaign_id: '82959',
		CheckPointID: 'CALL_START'
	},
	{
		Operator: '',
		Circle: '',
		CallerNo: '9553513237',
		CallDate: '2022-11-28',
		SNO: 2,
		OutputResponse: 'USER',
		CallID: '327116696155282',
		campign_name: 'InboundDilaing',
		CallTime: '11:35:59',
		InputProvided: 'HANGUP_BY',
		campaign_id: '82959',
		CheckPointID: 'CALL_END'
	},
	{
		Operator: '',
		Circle: '',
		CallerNo: '9553513237',
		CallDate: '2022-11-28',
		SNO: 3,
		OutputResponse: '',
		CallID: '327116696155613',
		campign_name: 'ManiTest',
		CallTime: '11:36:32',
		InputProvided: '',
		campaign_id: '82960',
		CheckPointID: 'CALL_START'
	},
	{
		Operator: '',
		Circle: '',
		CallerNo: '9553513237',
		CallDate: '2022-11-28',
		SNO: 4,
		OutputResponse: '',
		CallID: '327116696155613',
		campign_name: 'ManiTest',
		CallTime: '11:36:32',
		InputProvided: '',
		campaign_id: '82960',
		CheckPointID: 'After Sticky Fail'
	},
	{
		Operator: '',
		Circle: '',
		CallerNo: '9553513237',
		CallDate: '2022-11-28',
		SNO: 5,
		OutputResponse: 'USER',
		CallID: '327116696155613',
		campign_name: 'ManiTest',
		CallTime: '11:36:34',
		InputProvided: 'HANGUP_BY',
		campaign_id: '82960',
		CheckPointID: 'CALL_END'
	},
	{
		Operator: '',
		Circle: '',
		CallerNo: '9553513237',
		CallDate: '2022-11-28',
		SNO: 6,
		OutputResponse: '',
		CallID: '327116696158238',
		campign_name: 'testInbound',
		CallTime: '11:40:53',
		InputProvided: '',
		campaign_id: '82956',
		CheckPointID: 'CALL_START'
	},
	{
		Operator: '',
		Circle: '',
		CallerNo: '9553513237',
		CallDate: '2022-11-28',
		SNO: 7,
		OutputResponse: '',
		CallID: '327116696158238',
		campign_name: 'testInbound',
		CallTime: '11:40:53',
		InputProvided: 'WAITING_FOR_DTMF',
		campaign_id: '82956',
		CheckPointID: 'Menu_Menu'
	}
];

export const IVR_HANGUPS_REPORT: any = [
	{
		DisconnectType: 'user_disconnected',
		CallerNo: '9553513237',
		EndTime: '11:40:55',
		SNO: 1,
		CallID: '327116696158238',
		CallType: 'Inbound',
		StartTime: '11:40:23',
		CampaignName: 'testInbound',
		CalledNo: '914030015801',
		Duration: '00:00:32',
		LastCheckPoint: 'collectdtmf',
		IVRFlow: '["{\\"name\\":\\"ivrstartnode\\"}","{\\"name\\":\\"CALL_START\\"}","{\\"name\\":\\"Menu\\"}"]'
	},
	{
		DisconnectType: 'user_disconnected',
		CallerNo: '9553513237',
		EndTime: '11:36:34',
		SNO: 2,
		CallID: '327116696155613',
		CallType: 'Inbound',
		StartTime: '11:36:01',
		CampaignName: 'ManiTest',
		CalledNo: '914030015802',
		Duration: '00:00:33',
		LastCheckPoint: 'NotStickyTransfer',
		IVRFlow:
			'["{\\"name\\":\\"ivrstartnode\\"}","{\\"name\\":\\"CALL_START\\"}","{\\"name\\":\\"Play\\"}","{\\"name\\":\\"Builtin\\"}","{\\"name\\":\\"Condition\\"}","{\\"name\\":\\"AfterStickyFail\\"}","{\\"name\\":\\"NotStickyTransfer\\"}"]'
	},
	{
		DisconnectType: 'user_disconnected',
		CallerNo: '9553513237',
		EndTime: '11:35:59',
		SNO: 3,
		CallID: '327116696155282',
		CallType: 'Inbound',
		StartTime: '11:35:28',
		CampaignName: 'InboundDilaing',
		CalledNo: '914030015803',
		Duration: '00:00:31',
		LastCheckPoint: 'kookoo',
		IVRFlow: '["{\\"name\\":\\"ivrstartnode\\"}","{\\"name\\":\\"CALL_START\\"}","{\\"name\\":\\"kookoo\\"}"]'
	},
	{
		DisconnectType: 'user_disconnected',
		CallerNo: '9553513237',
		EndTime: '11:35:43',
		SNO: 4,
		CallID: '327116696155121',
		CallType: 'Inbound',
		StartTime: '11:35:12',
		CampaignName: 'CampaignTest',
		CalledNo: '914030015804',
		Duration: '00:00:31',
		LastCheckPoint: '0',
		IVRFlow: '[]'
	},
	{
		DisconnectType: 'user_disconnected',
		CallerNo: '00918790026882',
		EndTime: '11:35:18',
		SNO: 5,
		CallID: '327116696154860',
		CallType: 'Inbound',
		StartTime: '11:34:46',
		CampaignName: 'CampaignTest',
		CalledNo: '914030015804',
		Duration: '00:00:32',
		LastCheckPoint: '0',
		IVRFlow: '[]'
	}
];

export const PREDICTIVE_PERFORMANCE_LIVE_REPORT: any = [
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '2',
		UniqueCallsDialed: '1',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '0',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	},
	{
		Campaign: 'Lorem ipsum dolor sit amet',
		TotalCallsDialed: '4',
		UniqueCallsDialed: '2',
		CustomersAnswered: '36.3%  | 17',
		UniqueCustomersAnswered: '36.3%  | 17',
		CustomersUnAnswered: '36.3%  | 17',
		UniqueCustomersUnAnswered: '36.3%  | 17',
		DialFailed: '3',
		QueueDrop: '36.3% | 17',
		QueueWaitTime: '20:53:46',
		AvgQueueTime: '20:53:46',
		AgentAnsweredCalls: '4',
		AgentUnansweredCalls: '4',
		Sucess: '4',
		UniqueSucess: '4',
		FailCount: '36.3% | 17',
		UniqueFailCount: '36.3% | 17'
	}
];

export const SLA_LIVE_REPORT: any = [
	{
		Hour: '4',
		TotalCalls: '4',
		TotalAnsweredCalls: '4',
		TotalAnsweredwithinSLA: '4',
		SLA: '4',
		SLAAchieved: 'Not Achieved'
	},
	{
		Hour: '4',
		TotalCalls: '4',
		TotalAnsweredCalls: '4',
		TotalAnsweredwithinSLA: '4',
		SLA: '4',
		SLAAchieved: 'Not Achieved'
	},
	{
		Hour: '4',
		TotalCalls: '4',
		TotalAnsweredCalls: '4',
		TotalAnsweredwithinSLA: '4',
		SLA: '4',
		SLAAchieved: 'Not Achieved'
	},
	{
		Hour: '4',
		TotalCalls: '4',
		TotalAnsweredCalls: '4',
		TotalAnsweredwithinSLA: '4',
		SLA: '4',
		SLAAchieved: 'Not Achieved'
	},
	{
		Hour: '4',
		TotalCalls: '4',
		TotalAnsweredCalls: '4',
		TotalAnsweredwithinSLA: '4',
		SLA: '4',
		SLAAchieved: 'Not Achieved'
	},
	{
		Hour: '4',
		TotalCalls: '4',
		TotalAnsweredCalls: '4',
		TotalAnsweredwithinSLA: '4',
		SLA: '4',
		SLAAchieved: 'Not Achieved'
	},
	{
		Hour: '4',
		TotalCalls: '4',
		TotalAnsweredCalls: '4',
		TotalAnsweredwithinSLA: '4',
		SLA: '4',
		SLAAchieved: 'Not Achieved'
	},
	{
		Hour: '4',
		TotalCalls: '4',
		TotalAnsweredCalls: '4',
		TotalAnsweredwithinSLA: '4',
		SLA: '4',
		SLAAchieved: 'Not Achieved'
	},
	{
		Hour: '4',
		TotalCalls: '4',
		TotalAnsweredCalls: '4',
		TotalAnsweredwithinSLA: '4',
		SLA: '4',
		SLAAchieved: 'Not Achieved'
	},
	{
		Hour: '4',
		TotalCalls: '4',
		TotalAnsweredCalls: '4',
		TotalAnsweredwithinSLA: '4',
		SLA: '4',
		SLAAchieved: 'Not Achieved'
	}
];

export const PRI_UTILISATION: any = [
	{
		PRIName: '12644958.ports 30'
	},
	{
		PRIName: '12644958.ports 30'
	},
	{
		PRIName: '12644958.ports 30'
	},
	{
		PRIName: '12644958.ports 30'
	},
	{
		PRIName: '12644958.ports 30'
	},
	{
		PRIName: '12644958.ports 30'
	},
	{
		PRIName: '12644958.ports 30'
	},
	{
		PRIName: '12644958.ports 30'
	},
	{
		PRIName: '12644958.ports 30'
	},
	{
		PRIName: '12644958.ports 30'
	}
];

export const DAILER_COUNT_REPORT: any = [
	{
		TotalFailed: '0',
		TotalPending: '10',
		TotalAdded: '10',
		SNO: 1,
		CampaignName: 'QA_Outbound_Progressive',
		TotalSuccess: '0'
	}
];

export const DAILER_COUNT_AGENT_REPORT: any = [
	{
		TotalFailed: '0',
		TotalPending: '2',
		TotalAdded: '3',
		SNO: 1,
		CampaignName: 'QA_Outbound_Preview_Agentwise',
		TotalSuccess: '1',
		AgentID: '132645',
		AgentName: 'Salman'
	},
	{
		TotalFailed: '1',
		TotalPending: '4',
		TotalAdded: '5',
		SNO: 2,
		CampaignName: 'QA_Outbound_Progressive_Agentwise',
		TotalSuccess: '0',
		AgentID: '132645',
		AgentName: 'Salman'
	}
];

export const AGENT_PERFORMANCE_CALL_TYPE_LIVE: any = [
	{
		AvgTalkTime: '00:00:00',
		TotalCalls: '1',
		AvgCustomerRingTime: '00:00:00',
		TotalTalkTime: '00:00:00',
		SNO: 1,
		CallsFailed: '1',
		CallType: 'Manual',
		AvgWrapupTime: '00:00:00',
		AgentName: 'Priya@123',
		CallsConnected: '0',
		AvgPickupTime: '00:00:00',
		AvgHoldTime: '00:00:00'
	}
];

export const DIPOSITION_SUMMARY_LIVE: any = [
	{
		AgentId: '10056',
		WrapupTimeExceededCount: '3'
	},
	{
		AgentId: '10057',
		WrapupTimeExceededCount: '7'
	},
	{
		AgentId: '10058',
		WrapupTimeExceededCount: '5'
	},
	{
		AgentId: '10059',
		WrapupTimeExceededCount: '2'
	},
	{
		AgentId: '10060',
		WrapupTimeExceededCount: '1'
	},
	{
		AgentId: '10061',
		WrapupTimeExceededCount: '0'
	},
	{
		AgentId: '10062',
		WrapupTimeExceededCount: '5'
	},
	{
		AgentId: '10063',
		WrapupTimeExceededCount: '8'
	},
	{
		AgentId: '10064',
		WrapupTimeExceededCount: '4'
	},
	{
		AgentId: '10065',
		WrapupTimeExceededCount: '6'
	}
];

export const DIPOSITION_SUMMARY_LIVE_1: any = [
	{
		AgentId: '10056',
		Available: '3',
		CallComplete: '3',
		SalesEnquiryGetkookoo: '0',
		SubDisposition: '1',
		WrapupTimeExceededCount: '3'
	},
	{
		AgentId: '10057',
		Available: '3',
		CallComplete: '3',
		SalesEnquiryGetkookoo: '0',
		SubDisposition: '1',
		WrapupTimeExceededCount: '7'
	},
	{
		AgentId: '10058',
		Available: '3',
		CallComplete: '3',
		SalesEnquiryGetkookoo: '0',
		SubDisposition: '1',
		WrapupTimeExceededCount: '5'
	},
	{
		AgentId: '10059',
		Available: '3',
		CallComplete: '3',
		SalesEnquiryGetkookoo: '0',
		SubDisposition: '1',
		WrapupTimeExceededCount: '2'
	},
	{
		AgentId: '10060',
		Available: '3',
		CallComplete: '3',
		SalesEnquiryGetkookoo: '0',
		SubDisposition: '1',
		WrapupTimeExceededCount: '1'
	},
	{
		AgentId: '10061',
		Available: '3',
		CallComplete: '3',
		SalesEnquiryGetkookoo: '0',
		SubDisposition: '1',
		WrapupTimeExceededCount: '0'
	},
	{
		AgentId: '10062',
		Available: '3',
		CallComplete: '3',
		SalesEnquiryGetkookoo: '0',
		SubDisposition: '1',
		WrapupTimeExceededCount: '5'
	},
	{
		AgentId: '10063',
		Available: '3',
		CallComplete: '3',
		SalesEnquiryGetkookoo: '0',
		SubDisposition: '1',
		WrapupTimeExceededCount: '8'
	},
	{
		AgentId: '10064',
		Available: '3',
		CallComplete: '3',
		SalesEnquiryGetkookoo: '0',
		SubDisposition: '1',
		WrapupTimeExceededCount: '4'
	},
	{
		AgentId: '10065',
		Available: '3',
		CallComplete: '3',
		SalesEnquiryGetkookoo: '0',
		SubDisposition: '1',
		WrapupTimeExceededCount: '6'
	}
];

export const AGENT_LOGIN: any = [
	{
		LogoutTime: '2022-11-28 11:32:11',
		LoginTime: '2022-11-28 11:28:55',
		SNO: 1,
		AgentID: '132654',
		AgentName: 'Priya@123',
		Agent_ID: 'Priya@123'
	},
	{
		LogoutTime: '2022-11-28 12:33:15',
		LoginTime: '2022-11-28 11:32:14',
		SNO: 2,
		AgentID: '132654',
		AgentName: 'Priya@123',
		Agent_ID: 'Priya@123'
	},
	{
		LogoutTime: '2022-11-28 14:40:56',
		LoginTime: '2022-11-28 14:38:14',
		SNO: 3,
		AgentID: '132663',
		AgentName: 'venkat',
		Agent_ID: 'venkat'
	},
	{
		LogoutTime: '2022-11-28 15:41:13',
		LoginTime: '2022-11-28 14:22:59',
		SNO: 4,
		AgentID: '132772',
		AgentName: 'vinay',
		Agent_ID: 'vinay'
	},
	{
		LogoutTime: '2022-11-28 15:41:13',
		LoginTime: '2022-11-28 15:41:18',
		SNO: 5,
		AgentID: '132772',
		AgentName: 'vinay',
		Agent_ID: 'vinay'
	}
];

export const AGENT_LOGIN_MODAL_TABLE: any = [
	{
		StartTime: '07:43:34 AM',
		EndTime: '07:43:34 AM',
		Mode: 'Inbound',
		Event: 'Login',
		Details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	},
	{
		StartTime: '07:43:34 AM',
		EndTime: '07:43:34 AM',
		Mode: 'Inbound',
		Event: 'Login',
		Details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	},
	{
		StartTime: '07:43:34 AM',
		EndTime: '07:43:34 AM',
		Mode: 'Inbound',
		Event: 'Login',
		Details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	},
	{
		StartTime: '07:43:34 AM',
		EndTime: '07:43:34 AM',
		Mode: 'Inbound',
		Event: 'Login',
		Details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	},
	{
		StartTime: '07:43:34 AM',
		EndTime: '07:43:34 AM',
		Mode: 'Inbound',
		Event: 'Login',
		Details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	},
	{
		StartTime: '07:43:34 AM',
		EndTime: '07:43:34 AM',
		Mode: 'Inbound',
		Event: 'Login',
		Details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	},
	{
		StartTime: '07:43:34 AM',
		EndTime: '07:43:34 AM',
		Mode: 'Inbound',
		Event: 'Login',
		Details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	},
	{
		StartTime: '07:43:34 AM',
		EndTime: '07:43:34 AM',
		Mode: 'Inbound',
		Event: 'Login',
		Details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	},
	{
		StartTime: '07:43:34 AM',
		EndTime: '07:43:34 AM',
		Mode: 'Inbound',
		Event: 'Login',
		Details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	},
	{
		StartTime: '07:43:34 AM',
		EndTime: '07:43:34 AM',
		Mode: 'Inbound',
		Event: 'Login',
		Details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	}
];

export const AGENT_PERFORMANCE_CALL_MODE_LIVE: any = [
	{
		TotalLoginDuration: '00:03:29',
		TotalBreakDuration: '00:00:00',
		SNO: 1,
		ModeType: 'INBOUND',
		AgentName: 'Priya@123',
		TotalIdleDuration: '00:03:29',
		TotalDialDuration: '00:00:00'
	},
	{
		TotalLoginDuration: '00:05:14',
		TotalBreakDuration: '00:00:00',
		SNO: 2,
		ModeType: 'PROGRESSIVE',
		AgentName: 'Priya@123',
		TotalIdleDuration: '00:05:14',
		TotalDialDuration: '00:00:00'
	},
	{
		TotalLoginDuration: '00:55:33',
		TotalBreakDuration: '00:00:00',
		SNO: 3,
		ModeType: 'MANUAL',
		AgentName: 'Priya@123',
		TotalIdleDuration: '00:54:53',
		TotalDialDuration: '00:00:40'
	},
	{
		TotalLoginDuration: '00:00:06',
		TotalBreakDuration: '00:00:00',
		SNO: 4,
		ModeType: 'INBOUND',
		AgentName: 'vinay',
		TotalIdleDuration: '00:00:06',
		TotalDialDuration: '00:00:00'
	},
	{
		TotalLoginDuration: '01:18:11',
		TotalBreakDuration: '00:00:18',
		SNO: 5,
		ModeType: 'MANUAL',
		AgentName: 'vinay',
		TotalIdleDuration: '01:17:53',
		TotalDialDuration: '00:00:00'
	},
	{
		TotalLoginDuration: '00:00:23',
		TotalBreakDuration: '00:00:00',
		SNO: 6,
		ModeType: 'INBOUND',
		AgentName: 'venkat',
		TotalIdleDuration: '00:00:23',
		TotalDialDuration: '00:00:00'
	},
	{
		TotalLoginDuration: '00:02:19',
		TotalBreakDuration: '00:00:00',
		SNO: 7,
		ModeType: 'MANUAL',
		AgentName: 'venkat',
		TotalIdleDuration: '00:02:19',
		TotalDialDuration: '00:00:00'
	}
];
export const VOICE_MAIL_REPORT: any = [
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	},
	{
		UCID: 'CO-0044',
		UUI: '18140219333',
		CampaignName: 'Lorem ipsum dolor sit amet',
		Skill: 'Lorem ipsum dol',
		CallDate: '07/07/2022',
		StartTime: '20:53:46',
		AnswerTime: '20:53:46',
		EndTime: '20:53:46',
		TalkTime: '20:53:46',
		WrapupTime: '20:53:46',
		Duration: '20:53:46',
		CustomerRingTime: '20:53:46',
		FallbackRule: '',
		DialedNumber: '(704) 555-0127',
		Type: 'Lorem ipsum',
		AgentName: { value: 'Faisal Khan', icon: 'user_icon' },
		Disposition: 'Lorem ipsum',
		Status: 'Lorem ipsum',
		HangupBy: 'Lorem ipsum',
		Hangup: 'Lorem ipsum',
		Transfer: 'Lorem ipsum',
		TransferredTo: 'Lorem ipsum'
	}
];

export const CUSTOMER_HOLD_LIVE_REPORT: any = [
	{
		UCID: '9062165721854741',
		CampaignName: 'Inbound_1646841468',
		CallerNo: '+91 9703331632',
		AgentID: 'Agent 1',
		CallStartTime: '11:29:07',
		HoldStartTime: '11:30:07',
		HoldEndTime: '11:30:07',
		HoldDuration: '00:01:31',
		HoldStartedAfterCall: '00:01:31',
		HoldEndedAfterCall: '00:01:31'
	},
	{
		UCID: '9062165721854741',
		CampaignName: 'Inbound_1646841468',
		CallerNo: '+91 9703331632',
		AgentID: 'Agent 2',
		CallStartTime: '11:29:07',
		HoldStartTime: '11:30:07',
		HoldEndTime: '11:30:07',
		HoldDuration: '00:01:31',
		HoldStartedAfterCall: '00:01:31',
		HoldEndedAfterCall: '00:01:31'
	},
	{
		UCID: '9062165721854741',
		CampaignName: 'Inbound_1646841468',
		CallerNo: '+91 9703331632',
		AgentID: 'Agent 3',
		CallStartTime: '11:29:07',
		HoldStartTime: '11:30:07',
		HoldEndTime: '11:30:07',
		HoldDuration: '00:01:31',
		HoldStartedAfterCall: '00:01:31',
		HoldEndedAfterCall: '00:01:31'
	},
	{
		UCID: '9062165721854741',
		CampaignName: 'Inbound_1646841468',
		CallerNo: '+91 9703331632',
		AgentID: 'Agent 4',
		CallStartTime: '11:29:07',
		HoldStartTime: '11:30:07',
		HoldEndTime: '11:30:07',
		HoldDuration: '00:01:31',
		HoldStartedAfterCall: '00:01:31',
		HoldEndedAfterCall: '00:01:31'
	},
	{
		UCID: '9062165721854741',
		CampaignName: 'Inbound_1646841468',
		CallerNo: '+91 9703331632',
		AgentID: 'Agent 5',
		CallStartTime: '11:29:07',
		HoldStartTime: '11:30:07',
		HoldEndTime: '11:30:07',
		HoldDuration: '00:01:31',
		HoldStartedAfterCall: '00:01:31',
		HoldEndedAfterCall: '00:01:31'
	},
	{
		UCID: '9062165721854741',
		CampaignName: 'Inbound_1646841468',
		CallerNo: '+91 9703331632',
		AgentID: 'Agent 6',
		CallStartTime: '11:29:07',
		HoldStartTime: '11:30:07',
		HoldEndTime: '11:30:07',
		HoldDuration: '00:01:31',
		HoldStartedAfterCall: '00:01:31',
		HoldEndedAfterCall: '00:01:31'
	},
	{
		UCID: '9062165721854741',
		CampaignName: 'Inbound_1646841468',
		CallerNo: '+91 9703331632',
		AgentID: 'Agent 7',
		CallStartTime: '11:29:07',
		HoldStartTime: '11:30:07',
		HoldEndTime: '11:30:07',
		HoldDuration: '00:01:31',
		HoldStartedAfterCall: '00:01:31',
		HoldEndedAfterCall: '00:01:31'
	},
	{
		UCID: '9062165721854741',
		CampaignName: 'Inbound_1646841468',
		CallerNo: '+91 9703331632',
		AgentID: 'Agent 8',
		CallStartTime: '11:29:07',
		HoldStartTime: '11:30:07',
		HoldEndTime: '11:30:07',
		HoldDuration: '00:01:31',
		HoldStartedAfterCall: '00:01:31',
		HoldEndedAfterCall: '00:01:31'
	},
	{
		UCID: '9062165721854741',
		CampaignName: 'Inbound_1646841468',
		CallerNo: '+91 9703331632',
		AgentID: 'Agent 9',
		CallStartTime: '11:29:07',
		HoldStartTime: '11:30:07',
		HoldEndTime: '11:30:07',
		HoldDuration: '00:01:31',
		HoldStartedAfterCall: '00:01:31',
		HoldEndedAfterCall: '00:01:31'
	},
	{
		UCID: '9062165721854741',
		CampaignName: 'Inbound_1646841468',
		CallerNo: '+91 9703331632',
		AgentID: 'Agent 10',
		CallStartTime: '11:29:07',
		HoldStartTime: '11:30:07',
		HoldEndTime: '11:30:07',
		HoldDuration: '00:01:31',
		HoldStartedAfterCall: '00:01:31',
		HoldEndedAfterCall: '00:01:31'
	}
];

export const LIVECALLS_MOCK: any = [
	{ agentStatus: 'NotDialed', callStatus: 'Queued', campaignId: 6147, dateModified: '2022-12-14T17:24:41', name: 'RITESHBHATIA', phone: '09896457719', ucid: 9072167101888171 },
	{
		agentSelected: 'CHF024749',
		agentStatus: 'answered',
		callStatus: 'answered',
		campaignId: 6147,
		dateModified: '2022-12-14T17:24:08',
		name: 'VIJAYKUMAR',
		phone: '09896422918',
		ucid: 9072167101882131
	}
];

export const DATA_SUMMARY_MOCK: any = [
	{ CampaignName: 'Test1', TotalCalls: '2464', '0-3mins': '288', '3-5mins': '156', '5-10mins': '444', '10-15mins': '235', '15-30mins': '82', '30-60mins': '29', 'Above 60mins': '1118' },
	{ CampaignName: ' Test2', TotalCalls: '793', '0-3mins': '320', '3-5mins': '39', '5-10mins': '22', '10-15mins': '4', '15-30mins': '3', '30-60mins': '1', 'Above 60mins': '404' },
	{ CampaignName: 'Test3', TotalCalls: '229', '0-3mins': '75', '3-5mins': '13', '5-10mins': '11', '10-15mins': '1', '15-30mins': '8', '30-60mins': '3', 'Above 60mins': '118' }
];

export const OUTBOUND_CAMPAIGN_STATUS_LIVE: any = [
	{ campign_name: 'QA_Outbound_API', TotalAdded: 4, TotalSuccess: 1, TotalFailed: 1, TotalPending: 4 },
	{ campign_name: 'Prev_Tosca', TotalAdded: 7, TotalSuccess: 1, TotalFailed: 2, TotalPending: 6 },
	{ campign_name: 'QA_Outbound_Preview', TotalAdded: 20, TotalSuccess: 0, TotalFailed: 0, TotalPending: 20 },
	{ campign_name: 'QA_Outbound_Preview_Agentwise', TotalAdded: 10, TotalSuccess: 0, TotalFailed: 0, TotalPending: 10 },
	{ campign_name: 'QA_Outbound_Preview_Skillwise', TotalAdded: 10, TotalSuccess: 0, TotalFailed: 0, TotalPending: 10 },
	{ campign_name: 'QA_Outbound_Progressive', TotalAdded: 10, TotalSuccess: 0, TotalFailed: 1, TotalPending: 9 },
	{ campign_name: 'QA_Outbound_Progressive_Agentwise', TotalAdded: 20, TotalSuccess: 0, TotalFailed: 0, TotalPending: 20 },
	{ campign_name: 'QA_Outbound_Progressive_Skillwise', TotalAdded: 10, TotalSuccess: 0, TotalFailed: 0, TotalPending: 10 },
	{ campign_name: 'QA_Outbound_Predictive', TotalAdded: 10, TotalSuccess: 0, TotalFailed: 0, TotalPending: 10 },
	{ campign_name: 'QA_Outbound_IVR', TotalAdded: 20, TotalSuccess: 0, TotalFailed: 2, TotalPending: 15 }
];

export const OUTBOUND_PACING: any = [
	{ currentPacing: '1', campaignName: 'Test2' },
	{ currentPacing: '5', campaignName: 'Test2' }
];

export const DROP_RATIO: any = [
	{ ExpectedDropRatio: '5', currentDropRatio: '4.0', campaignName: 'Test2' },
	{ ExpectedDropRatio: '5', currentDropRatio: '50.0', campaignName: 'Test2' }
];

export const AGENT_NAMES: any = {
	agent: [
		{
			name: 'All',
			value: 'All',
			id: null
		},
		{
			name: 'AGENT001',
			value: 'AGENT001',
			id: 132953
		},
		{
			name: 'agent05',
			value: 'agent05',
			id: 132951
		},
		{
			name: 'Agenttest',
			value: 'Agenttest',
			id: 132830
		},
		{
			name: 'aparna',
			value: 'aparna',
			id: 132661
		},
		{
			name: 'Asoke',
			value: 'Asoke',
			id: 132662
		},
		{
			name: 'Ayyappa',
			value: 'Ayyappa',
			id: 132856
		},
		{
			name: 'Balagani',
			value: 'Balagani',
			id: 132915
		},
		{
			name: 'Balu',
			value: 'Balu',
			id: 132933
		},
		{
			name: 'BaluYadav',
			value: 'BaluYadav',
			id: 132694
		},
		{
			name: 'Baluyadav1',
			value: 'Baluyadav1',
			id: 132668
		},
		{
			name: 'bharath',
			value: 'bharath',
			id: 132950
		},
		{
			name: 'charan',
			value: 'charan',
			id: 132903
		},
		{
			name: 'charan0012',
			value: 'charan0012',
			id: 132745
		},
		{
			name: 'charan0013',
			value: 'charan0013',
			id: 132717
		},
		{
			name: 'charan0014',
			value: 'charan0014',
			id: 132749
		},
		{
			name: 'charan0015',
			value: 'charan0015',
			id: 132750
		},
		{
			name: 'charan0016',
			value: 'charan0016',
			id: 132751
		},
		{
			name: 'charan0017',
			value: 'charan0017',
			id: 132752
		},
		{
			name: 'charan0018',
			value: 'charan0018',
			id: 132701
		},
		{
			name: 'charan0019',
			value: 'charan0019',
			id: 132753
		},
		{
			name: 'charan0021',
			value: 'charan0021',
			id: 132755
		},
		{
			name: 'charan0022',
			value: 'charan0022',
			id: 132758
		},
		{
			name: 'charan0023',
			value: 'charan0023',
			id: 132759
		},
		{
			name: 'charan0024',
			value: 'charan0024',
			id: 132762
		},
		{
			name: 'charan0029',
			value: 'charan0029',
			id: 132773
		},
		{
			name: 'charan0035',
			value: 'charan0035',
			id: 132775
		},
		{
			name: 'charan123',
			value: 'charan123',
			id: 132866
		},
		{
			name: 'china',
			value: 'china',
			id: 132715
		},
		{
			name: 'ddd',
			value: 'ddd',
			id: 132956
		},
		{
			name: 'Dili',
			value: 'Dili',
			id: 132728
		},
		{
			name: 'hara',
			value: 'hara',
			id: 132722
		},
		{
			name: 'Hari',
			value: 'Hari',
			id: 132716
		},
		{
			name: 'Hari prasad',
			value: 'Hari prasad',
			id: 132947
		},
		{
			name: 'Hariii122',
			value: 'Hariii122',
			id: 132761
		},
		{
			name: 'Hello',
			value: 'Hello',
			id: 132688
		},
		{
			name: 'Hello1',
			value: 'Hello1',
			id: 132690
		},
		{
			name: 'jagadish11',
			value: 'jagadish11',
			id: 132652
		},
		{
			name: 'Kantha',
			value: 'Kantha',
			id: 132653
		},
		{
			name: 'kookoo',
			value: 'kookoo',
			id: 132929
		},
		{
			name: 'manish',
			value: 'manish',
			id: 132651
		},
		{
			name: 'manish007',
			value: 'manish007',
			id: 132780
		},
		{
			name: 'manish1',
			value: 'manish1',
			id: 132650
		},
		{
			name: 'Moukthik',
			value: 'Moukthik',
			id: 133005
		},
		{
			name: 'NameisHarii',
			value: 'NameisHarii',
			id: 132655
		},
		{
			name: 'Omer',
			value: 'Omer',
			id: 132855
		},
		{
			name: 'ozonetel',
			value: 'ozonetel',
			id: 132643
		},
		{
			name: 'Padhu',
			value: 'Padhu',
			id: 132657
		},
		{
			name: 'Pavan',
			value: 'Pavan',
			id: 132916
		},
		{
			name: 'Pradyumna',
			value: 'Pradyumna',
			id: 132763
		}
	]
};
