import { useMemo, useState } from 'react';
import * as yup from 'yup';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import { Formik } from 'formik';
import OZInput from '../../../components/admin/common/OZInput';
import { Button, Grid } from '@mui/material';
import { editIntegrations, saveIntegrations } from '../../../services/api-service';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';

const NetsapiensIntegration = (mainprops) => {

    console.log(mainprops);

    const validationSchema = useMemo(
        () =>
            yup.object({
                authCode: yup
                    .string()
                    .required("Domain Url is Required"),
                clientId: yup
                    .string()
                    .required("Client Id is Required"),
                clientSecret: yup
                    .string()
                    .required("Client Secrete is Required"),
                userName: yup
                    .string()
                    .required("User Name is Required"),
                password: yup
                    .string()
                    .required("Password Secrete is Required"),
            }),
        []
    );

    const [loading, setLoading] = useState(false);
    const [refreshForm, setRefreshForm] = useState(false);

    const onBtnDisconnect = () => {
        mainprops.setNetsapiensIntegration(false);
    };

    const handleCancel = () => {
        setRefreshForm(prevState => !prevState)
    };

    async function saveNetsapiens(values) {

        setLoading(true)
        console.log(values)

        let data = {
            authCode: values.authCode,
            clientId: values.clientId,
            clientSecret: values.clientSecret,
            userName: values.userName,
            password: values.password,
            integrationName: "Netsapiens"
        }
        try {
            saveIntegrations(data)
                .then((resp) => {
                    if (resp.Status === "success") {
                        showSuccessNotification("Netsapiens Integration is Saved Successfully")
                        setLoading(false);
                        mainprops.getUserIntegrationsData()
                        mainprops.setNetsapiensIntegration(false)
                    }
                    else {
                        showErrorNotification("Error Saving Netsapiens Integration ");
                        setLoading(false)
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in Saving Netsapiens Integration ");
                    setLoading(false)
                    console.log(e)
                });
        } catch (e) {
            showErrorNotification("Error in Saving Netsapiens Integration ");
            setLoading(false)
        }
    }

    async function editNetsapiens(values) {

        setLoading(true);
        console.log(values);

        let changeInNetsapiensIntegrationData = {
            ...mainprops?.netsapiensData,
            authCode: values.authCode,
            clientId: values.clientId,
            clientSecret: values.clientSecret,
            userName: values.userName,
            password: values.password,
            integrationName: "Netsapiens"
        }

        try {
            editIntegrations(changeInNetsapiensIntegrationData)
                .then((resp) => {
                    if (resp.Status === "success") {
                        showSuccessNotification("Netsapiens Integration is Edited Successfully")
                        setLoading(false);
                        mainprops.getUserIntegrationsData()
                        mainprops.setNetsapiensIntegration(false)
                    }
                    else {
                        showErrorNotification("Error Editing Netsapiens Integration");
                        setLoading(false)
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in Editing Netsapiens Integration");
                    setLoading(false)
                    console.log(e)
                });
        } catch (e) {
            showErrorNotification("Error in Editing Netsapiens Integration");
            setLoading(false)
        }
    }


    const DataForm = () => {
        let initData = {
            authCode: (mainprops?.netsapiensData !== null && mainprops?.netsapiensData?.authCode !== null && mainprops?.netsapiensData?.authCode !== undefined) ? mainprops.netsapiensData.authCode : '',
            clientId: (mainprops?.netsapiensData !== null && mainprops?.netsapiensData?.clientId !== null && mainprops?.netsapiensData?.clientId !== undefined) ? mainprops.netsapiensData.clientId : '',
            clientSecret: (mainprops?.netsapiensData !== null && mainprops?.netsapiensData?.clientSecret !== null && mainprops?.netsapiensData?.clientSecret !== undefined) ? mainprops.netsapiensData.clientSecret : '',
            userName: '',
            password: ''
        }

        console.log(initData)

        return (
            <div>
                <Formik
                    key={refreshForm}
                    initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (mainprops?.netsapiensData === null) {
                            console.log(values)
                        }
                        else {
                            console.log(values)
                        }
                    }}
                >
                    {
                        (props) => (
                            <div className="oz-admin-integration-Idesk-page">
                                <Grid sx={{ marginBottom: "10px" }}>
                                    <div onClick={onBtnDisconnect} className="oz-page-header"> Settings / Integrations / natsapeins settings </div>
                                </Grid>

                                <div className="oz-admin-integration-Idesk-IdeskName">
                                    Netsapiens Settings
                                </div>

                                <form
                                    onSubmit={props.handleSubmit}
                                >
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="authCode"
                                                placeholder="Domain URL"
                                                label="Domain URL *"
                                            />
                                            <div style={{ fontSize: "14px", color: '#8dcadf' }}>
                                                Note: Please try below domains if any integration issues
                                            </div>
                                            <div style={{ fontSize: "14px", color: '#8dcadf' }}>
                                                crexendo-core-021-las.cls.iaas.run
                                            </div>
                                            <div style={{ fontSize: "14px", color: '#8dcadf' }}>
                                                crexendo-core-031-dfw.cls.iaas.run
                                            </div>
                                            <div style={{ fontSize: "14px", color: '#8dcadf' }}>
                                                crexendo-core-041-grr.cls.iaas.run
                                            </div>
                                        </div>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="clientId"
                                                placeholder="Client Id"
                                                label="Client Id *"
                                            />
                                        </div>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="clientSecret"
                                                placeholder="Client Secrete"
                                                label="Client Secrete *"
                                            />
                                        </div>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="userName"
                                                placeholder="User Name"
                                                label="User Name *"
                                            />
                                        </div>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="password"
                                                placeholder="Password"
                                                label="Password *"
                                                type="password"
                                            />
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", marginTop: "80px", marginBottom: "80px" }}>
                                        <Button variant='contained' autoFocus sx={{ marginRight: "10px" }} type="submit">
                                            Save
                                        </Button>
                                        <Button variant='outlined' autoFocus onClick={() => handleCancel()}>
                                            Cancel
                                        </Button>
                                    </div>

                                </form>
                            </div>
                        )
                    }
                </Formik>
            </div>
        )
    }

    return (
        <>
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                <DataForm />
            }
        </>
    )
}

export default NetsapiensIntegration