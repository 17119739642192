import { Drawer, Box, IconButton, Grid, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import '../WidgetsSidebar/WidgetsSidebar.css';
import { WIDGET_LIST } from '../../utils/constants';
import { checkRoles } from '../../utils/util';

const useStyles: any = makeStyles((theme: Theme) => ({
	thresholdContainer: {
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column'
	},
	headerText: {
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '21px',
		color: '#212121'
	},
	subTitle: {
		fontWeight: 400,
		fontSize: '12px',
		lineHeight: '14px',
		color: '#536580'
	},
	drawerGutter: {
		paddingTop: '74px'
	}
}));

type Props = {
	toggleDrawer: any;
	isOpen: boolean | undefined;
};

const WidgetsSidebar = ({ isOpen, toggleDrawer }: Props) => {
	const classes = useStyles();
	const [filteredWidgetList, setFilteredWidgetList] = React.useState(WIDGET_LIST);
	const handleSearch = (event: any) => {
		if (!event.target.value) {
			setFilteredWidgetList(WIDGET_LIST);
		}
		const filteredList = (WIDGET_LIST).filter((widget) => widget.label.toLowerCase().includes(event.target.value.toLowerCase()));
		setFilteredWidgetList(filteredList);
	};
	const handleDragStart = (event: React.DragEvent<HTMLDivElement>, item: any) => {
		event.dataTransfer?.setData('widgetData', JSON.stringify(item));
	};
	return (
		<Drawer
			variant="persistent"
			ModalProps={{
				hideBackdrop: true
			}}
			open={isOpen}
			anchor={'right'}
			onClose={() => toggleDrawer(false)}
		>
			<Box sx={{ width: 465 }} className={classes.thresholdContainer}>
				<div className={classes.drawerGutter}>
					<Grid container direction="row" justifyContent="space-between" alignItems="center">
						<Grid item>
							<span className={classes.headerText}>Add Widget</span>
						</Grid>
						<Grid item xs={8}>
							<Grid container spacing={2} justifyContent="flex-end">
								<Grid item xs={8}>
									<div className="widget-search-container">
										<Grid container spacing={1}>
											<Grid item xs={10}>
												<div className="input-spacing drawer-search-bar">
													<input onChange={handleSearch} type="text" placeholder="Search" />
												</div>
											</Grid>
											<Grid className="input-spacing" item xs={2}>
												<img src={`${process.env.PUBLIC_URL}/img/search-icon.svg`} width="12" alt="Filter Search"></img>
											</Grid>
										</Grid>
									</div>
								</Grid>
								<Grid item xs={2}>
									<IconButton aria-label="delete" size="small" onClick={() => toggleDrawer(false)}>
										<CloseIcon style={{ fontSize: '22px', color: '#99a0a8' }} />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
				<p className={classes.subTitle}>Drag the widget</p>
				<div className="widget-items-container">
					<Grid container spacing={2}>
						{filteredWidgetList.map((item) => (
							<Grid key={item.widgetName} item xs={item.width || 6}>
								<div className="widget-item-draggable" draggable={true} onDragStart={(event) => handleDragStart(event, item)}>
									<img src={`${process.env.PUBLIC_URL}/thumbnails/${item.image}`} alt={item.label}></img>
									<p className="widget-name">{item.label}</p>
								</div>
							</Grid>
						))}
					</Grid>
				</div>
			</Box>
		</Drawer>
	);
};

export default WidgetsSidebar;