import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Badge, Chip, Grid, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { getActiveCalls, getChanelStatusData } from '../../../services/api-service';
import { filterTypes, WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import '../ChanelStatusDigital/ChannelStatus.css';
import { secondsToMinSec } from '../../../utils/util';

let interval: any;

export default function ChannelStatus(props: any) {
	const { useState, useRef } = React;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [callType, setCallType] = React.useState('all');
	const downloadContent = useRef<null | any>(null);
	const [callStatus, setCallStatus] = useState<any>({});
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || '1');
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [toggleType, setToggleType] = React.useState('Agent');// changes done 



	const test = [
		{
			"AVGResponseTime": "0",
			"TotalConversations": "1",
			"SNO": 1,
			"AVGFirstResponseTime": "0.0000",
			"TotalSessionTime": "17",
			"DigitalType": "All",
			"CampaignName": "TestMail",
			"AVGSessiontime": "17.0000",
			"TotalConnectedConversations": "0",
			"TotalabandonedConversations": "1"
		},
		{
			"AVGResponseTime": "0",
			"TotalConversations": "5",
			"SNO": 2,
			"AVGFirstResponseTime": "12.0000",
			"TotalSessionTime": "25695",
			"DigitalType": "All",
			"CampaignName": "Chat",
			"AVGSessiontime": "5139.0000",
			"TotalConnectedConversations": "2",
			"TotalabandonedConversations": "3"
		},
		{
			"AVGResponseTime": "0",
			"TotalConversations": "2",
			"SNO": 3,
			"AVGFirstResponseTime": "0.0000",
			"TotalSessionTime": "8",
			"DigitalType": "All",
			"CampaignName": "918048642310",
			"AVGSessiontime": "4.0000",
			"TotalConnectedConversations": "0",
			"TotalabandonedConversations": "2"
		},
		{
			"AVGResponseTime": "0",
			"TotalConversations": "11",
			"SNO": 4,
			"AVGFirstResponseTime": "24.6667",
			"TotalSessionTime": "99086",
			"DigitalType": "All",
			"CampaignName": "Chat_FormTesting",
			"AVGSessiontime": "9007.8182",
			"TotalConnectedConversations": "6",
			"TotalabandonedConversations": "5"
		},
		{
			"AVGResponseTime": "0",
			"TotalConversations": "19",
			"SNO": 5,
			"AVGFirstResponseTime": "21.5000",
			"TotalSessionTime": "124806",
			"DigitalType": "All",
			"CampaignName": "0",
			"AVGSessiontime": "6568.7368",
			"TotalConnectedConversations": "8",
			"TotalabandonedConversations": "11"
		}
	];

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setCallType(newValue);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = callStatusFilter.filter((item: any) => item.id !== id);
		setCallStatusFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const clearFilter = () => {
		console.info('Clear all filter.');
		setCallStatusFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

	const [callStatusFilter, setCallStatusFilter] = useState<any>(props?.selectedFilters?.filters || []);

	const filterItems = callStatusFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const getChannelStatus = async (filters = '0') => {
		const data: any = await getChanelStatusData({ filters, type: filterType, callType, dateTypeId: '0' });
		console.log(filterTypes);
		const filterKey = filterTypes.find((item: any) => item.id === +filterType) ?? filterTypes[0];
		console.log(filterKey);
		const filterValueKey = `${filterKey.value}Name`;
		console.log(filterValueKey);
		const totalValueData = data.find((item: any) => item[filterValueKey] === '0');
		if (callStatusFilter.length <= 2) {
			const historicData = await getChanelStatusData({ filters, type: filterType, callType, dateTypeId: '1' });
			const totalHistoricValueData = historicData.find((item: any) => item[filterValueKey] === '0');
			if (totalValueData) {
				// totalValueData.averageHandlingTimeTicker = +totalHistoricValueData?.AVGSessiontime
				// 	? +(((+totalValueData.AVGSessiontime - +totalHistoricValueData.AVGSessiontime) / +totalHistoricValueData.AVGSessiontime) * 100).toFixed(2)
				// 	: 0;
				totalValueData.averageAnswerTimeTicker = +totalHistoricValueData?.AVGSessiontime
					? +(((+totalValueData.AVGSessiontime - +totalHistoricValueData.AVGSessiontime) / +totalHistoricValueData.AVGSessiontime) * 100).toFixed(2)
					: 0;
				// const activeCalls = await getActiveCalls();
				// totalValueData.activeCalls = activeCalls.ActiveCallsInIVR;
			}

			console.log('totalVaueData-----> ', data[0]);

			setCallStatus(totalValueData ?? {});
		} else {
			const columns = [
				{ name: filterKey.label, key: filterValueKey },
				{ name: 'AHT', key: 'AverageHandlingTime', subTitle: totalValueData.AverageHandlingTime },
				{ name: 'ATT', key: 'AverageTalkTime', subTitle: totalValueData.AverageTalkTime },
				{ name: 'AWT', key: 'AverageWrapupTime', subTitle: totalValueData.AverageWrapupTime },
				{ name: 'Avg. Hold Time', key: 'AverageHoldTime', subTitle: totalValueData.AverageHoldTime },
				{ name: 'Avg. Answer Time', key: 'AverageAnswerTime', subTitle: totalValueData.AverageAnswerTime },
				{ name: 'Active Calls', key: 'TotalCalls', subTitle: totalValueData.TotalCalls },
				{ name: 'Total Answered', key: 'AnsweredCalls', subTitle: totalValueData.AnsweredCalls },
				{ name: 'Total Abandoned', key: 'UnansweredCalls', subTitle: totalValueData.UnansweredCalls }
			];
			const actualData = data.filter((item: any) => item[filterValueKey] !== '0');
			setRowData(actualData);
			setColumnData(columns);
		}
	};

	const handleFilterExistingData = (filteredData: any) => {
		setCallStatusFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setCallStatusFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	const handleToggleType = (event: React.SyntheticEvent, newValue: string) => { //changes done 
		setToggleType(newValue);
	};

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		const filterIds = (callStatusFilter.length && !(responseLength === callStatusFilter.length)) ? callStatusFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = callStatusFilter.length ? callStatusFilter.map((item: any) => item.id).join(',') : '0';
		getChannelStatus(filterIds);
		if (interval) {
			clearInterval(interval);
		}

		interval = setInterval(() => getChannelStatus(filterIds), WIDGET_REFRESH_INTERVAL.CALL_STATUS);
	}, [callStatusFilter, callType]);

	return (
		<Card className="customCard">
			<div className="card-header border-none">
				<div className="card-title">
					Channel Status<span className="card-sub-header">(Digital)</span>
					<div className="card-title-badge">Live</div>
				</div>
				<div className="card-actions">
					<ToggleButtonGroup value={toggleType} onChange={handleToggleType} color="primary" exclusive aria-label="bound">
						<ToggleButton value="Agent" style={{ width: '70px', padding: '5px' }} aria-label="Agent">
							<span>Agent</span>
						</ToggleButton>
						{/* <ToggleButton value="bot" style={{width: '73px', padding: '5px'}} aria-label="bot">
							<span>Bot</span>
						</ToggleButton> */}
					</ToggleButtonGroup>
					<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={'Channel Status (Digital)'}></WidgetDownload>
					<div className="card-icon" onClick={() => toggleDrawer(true)}>
						<Badge badgeContent={callStatusFilter.length} color="primary">
							<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</Badge>
					</div>
				</div>
			</div>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={callStatusFilter} additionalApiParams={{ campaignType: 'Chat' }} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/>

			<Box ref={downloadContent} sx={{ width: '100%', typography: 'body1' }}>
				<TabContext value={callType}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} className="customTabs">
							<Tab label="All" value="all" />
							<Tab label="Whatsapp" value="whatsapp" />
							<Tab label="Web Chat" value="web" />
							<Tab label="SMS" value="sms" />
							{/* <Tab label="Preview" value="preview" />
							<Tab label="Progressive" value="progressive" /> */}
						</TabList>
					</Box>
					<div className="customTabPanel">
						<Grid container spacing={2}>
							{callStatusFilter.length > 0 && (
								<Grid item xs={12}>
									<div className="filter-outer">
										<div className="filter-container">
											<Stack direction="row" spacing={1}>
												{filterItems}
											</Stack>
										</div>
										<div className="filter-remove" onClick={clearFilter}>
											Remove
										</div>
									</div>
								</Grid>
							)}
							{callStatusFilter.length > 2 ? (
								<OZTable rows={rowData} columns={columnData}></OZTable>
							) : (
								<>
									<Grid item xs={12}>
										<div className="widget-inner-card">
											<Grid container direction="row" justifyContent="space-between" alignItems="center">
												<Grid item xs={5}>
													<div className="widget-values-container">
														<div className="widget-caption">Total Session Time</div>
														<div className="widget-values">
															<div className="widget-value">{secondsToMinSec(callStatus.TotalSessionTime) || '-'}</div>
															<div className={`widget-value-update ${callStatus.averageHandlingTimeTicker < 0 ? 'red' : ''}`}>
																{callStatus.averageHandlingTimeTicker < 0 ? (
																	<img src={`${process.env.PUBLIC_URL}/icons/down-red-arrow.svg`} width="11" alt="Average Handling Time"></img>
																) : (
																	<img src={`${process.env.PUBLIC_URL}/icons/up-green-arrow.svg`} width="11" alt="Average Handling Time"></img>
																)}{' '}
																{`${callStatus.averageHandlingTimeTicker || 0}%`}
															</div>
														</div>
													</div>
												</Grid>
												<Grid item xs={7}>
													<Grid container spacing={2}>
														<Grid item xs={6}>
															<div className="widget-values-container widget-values-sm">
																<div className="widget-caption">Average First Response Time</div>
																<div className="widget-values">
																	<div className="widget-value">{secondsToMinSec(callStatus.AVGFirstResponseTime) || '-'}</div>
																</div>
															</div>
														</Grid>
														{/* <Grid item xs={6}>
															<div className="widget-values-container widget-values-sm">
																<div className="widget-caption">Avg. Hold Time</div>
																<div className="widget-values">
																	<div className="widget-value">{secondsToMinSec(callStatus.AverageHoldTime) || '-'}</div>
																</div>
															</div>
														</Grid> */}
														<Grid item xs={6}>
															<div className="widget-values-container widget-values-sm">
																<div className="widget-caption">Average Response Time</div>
																<div className="widget-values">
																	<div className="widget-value">{secondsToMinSec(callStatus.AVGResponseTime) || '-'}</div>
																</div>
															</div>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</div>
									</Grid>
									<Grid item xs={5}>
										<div className="widget-inner-card">
											<Grid item xs={12}>
												<div className="widget-values-container widget-values-sm">
													<div className="widget-caption">Average Session Time</div>
													<div className="widget-values">
														<div className="widget-value widget-value-md">{secondsToMinSec(callStatus.AVGSessiontime) || '-'}</div>
														<div className={`widget-value-update ${callStatus.averageAnswerTimeTicker < 0 ? 'red' : ''}`}>
															{callStatus.averageAnswerTimeTicker < 0 ? (
																<img src={`${process.env.PUBLIC_URL}/icons/down-red-arrow.svg`} width="11" alt="Average Handling Time"></img>
															) : (
																<img src={`${process.env.PUBLIC_URL}/icons/up-green-arrow.svg`} width="11" alt="Average Handling Time"></img>
															)}{' '}
															{`${callStatus.averageAnswerTimeTicker || 0}%`}
														</div>
													</div>
												</div>
											</Grid>
										</div>
									</Grid>
									<Grid item xs={7}>
										<div className="widget-inner-card">
											<Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="center">
												<Grid item xs={6}>
													<div className="widget-values-container widget-values-sm">
														<div className="widget-caption">Total Conversations</div>
														<div className="widget-values">
															<div className="widget-value">{callStatus.TotalConversations || '-'}</div>
														</div>
													</div>
												</Grid>
												<Grid item xs={6}>
													<div className="widget-values-container widget-values-inline">
														<div className="widget-values-row">
															<div className="widget-caption">Total Connected</div>
															<div className="widget-value">{callStatus.TotalConnectedConversations || '-'}</div>
														</div>
														<div className="widget-values-row">
															<div className="widget-caption">Total Abandoned</div>
															<div className="widget-value">{callStatus.TotalabandonedConversations || '-'}</div>
														</div>
														{/* <div className="widget-values-row">
															<div className="widget-caption">Total Queued</div>
															<div className="widget-value">{callStatus.activeCalls || '0'}</div>
														</div> */}
													</div>
												</Grid>
											</Grid>
										</div>
									</Grid>
								</>
							)}
						</Grid>
					</div>
				</TabContext>
			</Box>
		</Card>
	);
}
