import WidgetLauncher from "./launcher/Launcher";
import Widget from "./widget/Widget.jsx";
import { useEffect, useState } from "react";
import './chatWidget.css';
import { fetchBroadCastMessage } from "../../services/api-service";

function ChatWidget() {

  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const toggleWidget = () => setOpen((prev) => !prev);
  useEffect(() => { open && getMessages() }, [open])

  const getMessages = async () => {
    try {
      const resp = await fetchBroadCastMessage();
      if (resp.Data) {
        const data = JSON.parse(resp.Data)
        const buildMessage = data.map((msg) => {
          let to = []
          if (msg.AgentName && msg.AgentName.length > 0) { to = [...msg.AgentName.split(',')] }
          if (msg.AgentGroupName && msg.AgentGroupName.length > 0) { to = [...to, ...msg.AgentGroupName.split(',')] }
          return {
            type: "text",
            to: to,
            time: new Date(msg.MessageSentTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
            msg: msg.Message,
            ts: msg.MessageSentTime
          }
        })
        if (buildMessage.length) {
          const sortedMessages = buildMessage.sort((a, b) => new Date(a.ts) - new Date(b.ts))
          setMessages(sortedMessages)
        }
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <div className={`ozcw-widget-container ${open && ' ozcw-widget-container-expanded '}`}>
      {open && <Widget getMessages={getMessages} open={open} messages={messages} setMessages={setMessages} />}
      <WidgetLauncher open={open} toggleWidget={toggleWidget} messages={messages} />
    </div>
  )
}


export default ChatWidget