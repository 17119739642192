import React from 'react';
import { FormControlLabel, FormControl, FormGroup, FormHelperText, Typography, Divider } from '@mui/material';
import { connect, getIn } from "formik";
import CustomizedCheckbox from './CustomCheckBox';


const OZFCheckboxBulkCampaign = connect(({ name, label, onDeleteIcon, options, direction, disabled, labelPosition, multi, formik }) => {

    const error = getIn(formik.errors, name);
    const touch = getIn(formik.touched, name);

    let selectedValues = getIn(formik.values, name);

    const handleDeleteIcon = (e, name) => {
        console.log("Image is clicked", name);
        onDeleteIcon(name);
    }

    return (
        <React.Fragment>
            <FormControl component="fieldset" required
                error={error}
                variant="standard">
                {(multi && label) && <Typography fontSize='14px' paddingBottom={'2px'} htmlFor={name}>{label}</Typography>}
                {multi ? <FormGroup row={direction === 'row' || false}>
                    {options.map((option, index) => {
                        return <FormControlLabel
                            value={option}
                            key={option + index}
                            control={
                                <CustomizedCheckbox size='small'
                                    disabled={disabled}
                                    onChange={formik.handleChange}
                                    checked={selectedValues && selectedValues.length !== 0 ? selectedValues.includes(option) : false}
                                    id={name} />
                            }
                            label={label} />
                    })}

                </FormGroup> :
                    <div style={{ display: 'flex', alignContent: 'center', height: '30px', backgroundColor: '#FFFFFF', borderRadius: '8px', border: '1px solid #99A0A8' }}>
                        <div flexGrow={1}>
                            <FormControlLabel
                                value={selectedValues}
                                control={
                                    <CustomizedCheckbox style={{ marginLeft: '10px', marginTop: '-2px' }} size='small'
                                        disabled={disabled}
                                        onChange={formik.handleChange}
                                        // onClick={handleCheckBox}
                                        checked={selectedValues}
                                        id={name} />
                                }
                                label={
                                    <div style={{ display: 'flex', alignContent: 'center' }} >
                                        <Typography paddingBottom='3px' fontSize='14px' fontWeight={400} color={disabled ? "#99A0A8" : '#212121'}>{label}
                                        </Typography>
                                    </div>
                                }
                                labelPlacement={labelPosition || 'start'}
                            />
                        </div>
                        <Divider sx={{ color: '#99A0A8', height: '29px' }} orientation="vertical" />
                        <img
                            style={{ marginTop: '-2px', paddingLeft: '5px' }}
                            src={`${process.env.PUBLIC_URL}/icons/deleteIconGrey.svg`}
                            alt="edit"
                            onClick={(e) => handleDeleteIcon(e, label)}
                        >
                        </img>
                    </div>
                }
                <FormHelperText>{error}</FormHelperText>

            </FormControl>
        </React.Fragment>
    );
})

export default OZFCheckboxBulkCampaign;