import { IconButton } from '@mui/material';
import * as React from 'react';
import Carousel from 'react-material-ui-carousel';
import { WIDGET_COMPONENT_MAPPING } from '../../../utils/constants';
import DefaultWidget from '../../DefaultWidget/DefaultWidget';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import '../WidgetsCarousel/WidgetsCarousel.css';
import CarouselWidgetDrawer from '../../CarouselWidgetDrawer/CarouselWidgetDrawer';

type Props = {
	enableWidgetEdit?: boolean;
	selectedWidgets?: any[];
	onWidgetChange?: any;
	handleFilterChange?: any;
	selectedFilters?: any;
	widgetId?:any;
};

export default function WidgetsCarousel({ selectedWidgets = [], onWidgetChange, enableWidgetEdit, handleFilterChange, selectedFilters, widgetId }: Props) {
	const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean | undefined>(false);
	const [widgets, setWidgets] = React.useState<any[]>(selectedWidgets);
	const [selectedWidgetFilters, setSelectedWidgetFilters] = React.useState<any[]>(selectedFilters || []);
	const [selectedWidgetId, setSelectedWidgetId] = React.useState<any>(widgetId || '');
	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleAddWidget = () => {
		toggleDrawer(true);
	};

	const handleSelectWidgets = (selectedWidgets: any) => {
		setWidgets(selectedWidgets);
		if (onWidgetChange) {
			onWidgetChange(selectedWidgets);
		}
		toggleDrawer(false);
	};

	return (
		<div className="carousel-container">
			{widgets.length ? (
				<Carousel
					height={394}
					navButtonsAlwaysInvisible={true}
					animation="slide"
					interval={15000}
					autoPlay={true}
					stopAutoPlayOnHover={true}
					activeIndicatorIconButtonProps={{ style: { color: '#008DFF' } }}
					indicatorContainerProps={{ style: { marginTop: 0, borderTop: '1px solid #e6e5e6' } }}
				>
					{widgets.map((widget: any, idx: number) => (
						<WidgetItem key={idx} item={widget} selectedFilters={selectedWidgetFilters[idx]} handleFilterChange={(selectedFilters: any[], filterSection: any, index: any, selectedHeaders: any[], headerSection: any)=>handleFilterChange?.(selectedFilters, filterSection, selectedWidgetId, selectedHeaders, headerSection, true, idx, selectedWidgetFilters)}/>
					))}
				</Carousel>
			) : (
				<div className="add-widget-placeholder">
					<div className="card-header">
						<div className="card-title">Carousel</div>
					</div>
					<div className="card-content">
						<div className="text-center">
							<img src={`${process.env.PUBLIC_URL}/icons/add-widget.svg`} alt="Add widget" />
							<p>
								No widgets yet{' '}
								<span className="link-text" onClick={handleAddWidget}>
									click here
								</span>{' '}
								to add widgets
							</p>
						</div>
					</div>
				</div>
			)}
			{enableWidgetEdit && (
				<IconButton className="add-widget-btn" onClick={handleAddWidget}>
					<WidgetsOutlinedIcon />
				</IconButton>
			)}
			<CarouselWidgetDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} selectWidgets={handleSelectWidgets} />
		</div>
	);
}

function WidgetItem(props: any) {
	const ComponentToRender = WIDGET_COMPONENT_MAPPING[props.item.widgetName] || DefaultWidget;
	return (
		<div className="carousel-widget-container">
			<ComponentToRender handleFilterChange={props?.handleFilterChange} selectedFilters={props?.selectedFilters || []}/>
		</div>
	);
}
