import { useEffect, useMemo, useState } from "react"
import OZDialog from "../../../components/admin/common/OZDialog";
import * as yup from 'yup';
import { Formik } from "formik";
import OZFCheckbox from "../../../components/admin/common/OZFCheckbox";
import CustomAutoComplete from "../../../components/admin/common/AutoComplete/CustomAutoComplete";
import { editDigitalCampaigns, getSecondaryDidDropdown } from "../../../services/api-service";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import OZInput from "../../../components/admin/common/OZInput";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";

const EditDigitalCampaignWhatsAppIntegration = (mainProps) => {

    console.log(mainProps.data)
    console.log(mainProps.skillsList)
    console.log(mainProps.alreadyAssignedDIDs)

    let whatsAppIntegrationData = []
    mainProps.data.map((item) => {
        if (item.integration.name === "WhatsappChat") {
            whatsAppIntegrationData.push(item)
        }
    })
    whatsAppIntegrationData = whatsAppIntegrationData[0]
    console.log(whatsAppIntegrationData)

    let jsonData = JSON.parse(whatsAppIntegrationData.jsonData)

    const [showOnlyDidField, setShowOnlyDidField] = useState(false)

    useEffect(() => {
        getDidList();

        let whatsAppIntegrationData = []
        mainProps.data.map((item) => {
            if (item.integration.name === "WhatsappChat") {
                whatsAppIntegrationData.push(item)
            }
        })
        whatsAppIntegrationData = whatsAppIntegrationData[0]
        console.log(whatsAppIntegrationData)
        let jsonData = JSON.parse(whatsAppIntegrationData.jsonData)

        if (jsonData?.data?.skill === null || jsonData?.data?.skill === undefined) {
            setShowOnlyDidField(true)
        }

    }, []);

    async function getDidList() {
        try {
            getSecondaryDidDropdown()
                .then((resp) => {
                    const didArray = resp.Data !== null ? JSON.parse(resp.Data).map(item => item?.DID) : [];
                    // console.log(didArray)
                    const alreadyAssignedDIDs = mainProps.alreadyAssignedDIDs;
                    // Filter out DIDs that are not present in alreadyAssignedDIDs
                    const filteredDIDs = didArray.filter(did => !alreadyAssignedDIDs.includes(did));
                    // console.log(filteredDIDs)
                    const filteredDIDsArrayOfObjects = []
                    filteredDIDs.map((item, { index }) => {
                        let obj = {
                            id: index,
                            DID: item
                        }
                        filteredDIDsArrayOfObjects.push(obj)
                    })
                    setDidList(filteredDIDsArrayOfObjects)
                })
                .catch((e) => {
                    console.log(e)
                    setDidList([]);
                });
        } catch (e) {
            showErrorNotification("Error in fetching did list");
            setDidList([]);
        }
    };

    async function editDigitalCampaign(values) {
        setLoading(true);
        console.log(values);
        let clientidDefaultString = whatsAppIntegrationData.clientId.split(":")
        let changeInWhatsAppIntegrationData = {
            ...whatsAppIntegrationData,
            clientId: `${clientidDefaultString[0]}:${values.didOrUuid.DID}`
        }
        let changeInJsonDataOfWhatsAppIntegrationData = JSON.parse(changeInWhatsAppIntegrationData.jsonData)

        changeInJsonDataOfWhatsAppIntegrationData.data.offline = values.switchBoard
        changeInJsonDataOfWhatsAppIntegrationData.data.skill = values.skill.skillName
        changeInJsonDataOfWhatsAppIntegrationData.data.custom = values.custom
        changeInJsonDataOfWhatsAppIntegrationData.data.appUrl = values.appUrl

        console.log(changeInJsonDataOfWhatsAppIntegrationData)

        changeInWhatsAppIntegrationData = {
            ...changeInWhatsAppIntegrationData,
            jsonData: JSON.stringify(changeInJsonDataOfWhatsAppIntegrationData)
        }

        console.log(changeInWhatsAppIntegrationData)
        try {
            editDigitalCampaigns(changeInWhatsAppIntegrationData)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification("WhatsApp Integration Of Digital Campaign is Edited Successfully")
                        setLoading(false);
                        mainProps.getDigitalCampaignDetails()
                        mainProps.close()
                    }
                    else {
                        showErrorNotification("Error Editing Digital Campaign Integration");
                        setLoading(false)
                        mainProps.getDigitalCampaignDetails();
                        mainProps.close()
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in Editing Digital Campaign Integration");
                    setLoading(false)
                    console.log(e)
                    mainProps.getDigitalCampaignDetails();
                    mainProps.close()
                });
        } catch (e) {
            showErrorNotification("Error in Editing Digital Campaign Integration");
            setLoading(false)
            mainProps.getDigitalCampaignDetails();
            mainProps.close()
        }
    }

    async function editDigitalCampaignOldIntegration(values) {
        setLoading(true);
        console.log(values);
        let changeInWhatsAppIntegrationData = {
            ...whatsAppIntegrationData,
            clientId: `whatsapp:${values.didOrUuid}`
        }
        console.log(changeInWhatsAppIntegrationData)
        try {
            editDigitalCampaigns(changeInWhatsAppIntegrationData)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification("WhatsApp Integration Of Digital Campaign is Edited Successfully")
                        setLoading(false);
                        mainProps.getDigitalCampaignDetails()
                        mainProps.close()
                    }
                    else {
                        showErrorNotification("Error Editing Digital Campaign Integration");
                        setLoading(false)
                        mainProps.getDigitalCampaignDetails();
                        mainProps.close()
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in Editing Digital Campaign Integration");
                    setLoading(false)
                    console.log(e)
                    mainProps.getDigitalCampaignDetails();
                    mainProps.close()
                });
        } catch (e) {
            showErrorNotification("Error in Editing Digital Campaign Integration");
            setLoading(false)
            mainProps.getDigitalCampaignDetails();
            mainProps.close()
        }
    }

    const [didList, setDidList] = useState([]);
    const [loading, setLoading] = useState(false);

    const validationSchema1 = useMemo(
        () =>
            yup.object({
                didOrUuid: yup
                    .object().shape({
                        DID: yup.string().required("DID or UUID is required"),
                    })
                    .required("DID or UUID is required"),
                skill: yup
                    .object().shape({
                        id: yup.number().required("Skill is required"),
                        skillName: yup.string().required("Skill is required"),
                    })
                    .required("Skill is required"),
                switchBoard: yup.boolean(),
                custom: yup.boolean(),
                appUrl: yup.string()
            }),
        []
    );

    const validationSchema2 = useMemo(
        () =>
            yup.object({
                didOrUuid: yup
                    .string()
                    .required("DID or UUID is required")
                    .matches(/^[\d+]{1,1}[\d]{3,12}$/, 'Did should be between 4 to 12 digits long and allows prefix +')
                    .test('is-unique', 'DID already taken', function (value) {
                        return !mainProps.alreadyAssignedDIDs.includes(value);
                    }),
            }),
        [mainProps.alreadyAssignedDIDs]
    );

    const DataForm = () => {

        let skill = {
            id: '',
            skillName: ''
        }
        let didOrUuid = {
            id: '',
            DID: ''
        }
        mainProps.skillsList.map((item) => {
            if (item.skillName === jsonData?.data?.skill) {
                skill.id = item.id;
                skill.skillName = item.skillName
            }
        })

        let didAlone = "";

        if (showOnlyDidField) {
            didAlone = whatsAppIntegrationData.clientId.split(":")
            if (didAlone[1] === undefined || didAlone[1] === null) {
                didAlone = whatsAppIntegrationData.clientId
            }
            else {
                didAlone = didAlone[1]
            }
        }
        else {
            didAlone = whatsAppIntegrationData.clientId.split(":")
        }

        if (didList.length > 0) {
            didList.map((item) => {
                if (item.DID === didAlone[1]) {
                    didOrUuid.id = item.id;
                    didOrUuid.DID = item.DID
                }
            })
        }

        if (didOrUuid.id === '' && didOrUuid.DID === '') {
            didOrUuid.id = -1;
            didOrUuid.DID = didAlone[1]
        }

        let initData1 = {
            switchBoard: jsonData?.data?.offline,
            didOrUuid: didOrUuid,
            skill: skill,
            custom: jsonData?.data?.custom,
            appUrl: jsonData?.data?.appUrl
        }

        let initData2 = {
            didOrUuid: didAlone,
        }

        console.log(initData1)
        console.log(initData2)

        return (
            <div>
                <Formik
                    initialValues={showOnlyDidField ? initData2 : initData1}
                    validationSchema={showOnlyDidField ? validationSchema2 : validationSchema1}
                    onSubmit={(values) => {
                        showOnlyDidField ?
                            editDigitalCampaignOldIntegration(values) :
                            editDigitalCampaign(values)
                    }}
                >
                    {
                        (props) => (
                            <div>
                                <form
                                    onSubmit={props.handleSubmit}
                                    id="editWhatsAppConfigurationForm"
                                    style={{
                                        width: "800px",
                                    }}
                                >
                                    {!showOnlyDidField &&
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ marginBottom: "5px" }}>
                                                <OZFCheckbox
                                                    name="switchBoard"
                                                    label="Switch Board"
                                                    labelPosition="end"
                                                />
                                            </div>
                                            <div style={{ marginBottom: "15px" }}>
                                                <CustomAutoComplete
                                                    mode='Add'
                                                    name="skill"
                                                    placeholder={"Search Skill Name"}
                                                    Datas={mainProps.skillsList}
                                                    optionLabelName='skillName'
                                                    showCheckBox={true}
                                                    popupIcon={null}
                                                    labelHeader='Skill *'
                                                />
                                            </div>
                                            <div style={{ marginBottom: "5px" }}>
                                                <CustomAutoComplete
                                                    mode='Add'
                                                    name="didOrUuid"
                                                    placeholder={"Search DID or UUID No."}
                                                    Datas={didList}
                                                    optionLabelName='DID'
                                                    showCheckBox={true}
                                                    popupIcon={null}
                                                    labelHeader='DID/UUID *'
                                                />
                                            </div>
                                            <div style={{ marginBottom: "5px" }}>
                                                <OZFCheckbox
                                                    name="custom"
                                                    label="Custom"
                                                    labelPosition="end"
                                                />
                                            </div>
                                            {(initData1.appUrl !== null && initData1.appUrl !== undefined && initData1.appUrl !== '') &&
                                                <div style={{ marginBottom: "15px" }}>
                                                    <OZInput
                                                        name="appUrl"
                                                        placeholder="Enter AppURL"
                                                        label="App URl"
                                                    />
                                                </div>
                                            }
                                        </div>
                                    }
                                    {showOnlyDidField &&

                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ marginBottom: "5px" }}>
                                                <OZInput
                                                    name="didOrUuid"
                                                    placeholder="Enter didOrUuid"
                                                    label="DID/UUID *"
                                                />
                                            </div>
                                        </div>
                                    }

                                </form>
                            </div>
                        )
                    }
                </Formik>
            </div>
        )
    }


    return (<>
        {loading ?
            (
                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                    <LoadingScreen />
                </div>
            ) :
            <OZDialog open={mainProps.open} onClose={mainProps.close} title="Edit WhatsApp Configurations" saveButtonProps={{ form: 'editWhatsAppConfigurationForm', type: 'submit' }} >
                <DataForm />
            </OZDialog>
        }
    </>
    )

}

export default EditDigitalCampaignWhatsAppIntegration