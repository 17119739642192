export const setValueInStorage = (key: string, value: any | undefined | null) => {
	localStorage.setItem(key, JSON.stringify(value));
};
export const deleteValueInStorage = (key: string) => {
	localStorage.removeItem(key);
};

export const getValueInStorage = (key: string): any | null => {
	const value = localStorage.getItem(key);
	if (value) {
		return JSON.parse(value);
	}
	return null;
};
