import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Divider, Grid, Typography, TextField, Tooltip } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import OZDrawer from '../../../components/admin/common/OZDrawer/OZDrawer';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OZDialog from '../../../components/admin/common/OZDialog';
import { addTemplate, deleteTemplateById, updateTemplate } from '../../../services/api-service';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';

function WhatsappView(props) {
    const TemplateFieldsView = (props) => {
        return (<>
            <div className="oz-Template-view-lablel-container">
                <label className='oz-template-view-label'>{props.label}</label>
                {
                    (props.data.length !== 0) ?
                        <label className='oz-template-view-value'>{props.data} </label> :
                        <label className='oz-template-view-value'> - </label>
                }
            </div>
        </>
        )
    }

    return (
        <Grid direction={"column"} container={true} spacing={1}>
            {props?.data?.text &&
                <TemplateFieldsView data={props?.data?.text} label={" Template Text *"} />}
            {props?.data?.templateId &&
                <TemplateFieldsView data={props?.data?.templateId} label={"Language *"} />}
            {props?.data?.entityId &&
                <TemplateFieldsView data={props?.data?.entityId} label={"WhatsApp Number *"} />}
            {props?.data?.type &&
                <TemplateFieldsView data={props?.data?.type} label={"Type *"} />}
        </Grid>
    )
}
const WhatsappTemplateForm = (props) => {
    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add
    const validationSchema = useMemo(() => (yup.object({
        name: yup
            .string('Name')
            .min(2, "Name should not be empty and it should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
            .max(50, "Name should not be empty and it should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
            .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "Name should not be empty and it should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
            .required('Name is required'),
        text: yup
            .string()
            .matches(/^(?!\s+$).{1,1200}$/, "Text should not be empty and allows max of 1200 characters.")
            .required('Template Text is required.'),
        entityId: yup
            .string()
            .test("maxDigits",
                "WhatsApp Number should be between 2 to 20 digits long and allows prefix +",
                (number) => String(number).length >= 2 && String(number).length <= 20)
            .matches(/^(\+)?[0-9]+$/, "WhatsApp Number should be between 2 to 20 digits long and allows prefix +")
            .required('WhatsApp Number is required'),
        templateId: yup
            .string()
            .min(1, "Language should be between 2 to 20 alphanumeric characters long and allows special characters like _ and it cannot start and end with Special characters.")
            .max(20, "Language should be between 2 to 20 alphanumeric characters long and allows special characters like _ and it cannot start and end with Special characters.")
            .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "Language should be between 2 to 20 alphanumeric characters long and allows special characters like _ and it cannot start and end with Special characters.")
            .required('Language is required'),

    })), []);

    const [open, setState] = useState(true)
    const [mode, setMode] = useState(initMode);
    const [valid, setValid] = useState(true);

    const closeWhatsappTemplateForm = () => {
        props.onClose();
        setState(false)
    }

    const EditWhatsappTemplateForm = () => {
        setMode(ADMIN_FORM_MODES.edit);
    }
    const [anchorE1, setAnchorE1] = React.useState(false);
    const anchorRef = React.useRef();
    const [openDelete, setDeleteDialog] = useState(false)
    const opens = Boolean(anchorE1);

    const morevertclick = () => {
        setTimeout(() => setAnchorE1(anchorRef?.current), 1);
    };

    const handleMenuClose = () => {
        setAnchorE1(null);
    };

    const onClickButton = () => {
        setMode(ADMIN_FORM_MODES.view);
    };

    function openDeleteDialog() {
        setAnchorE1(null);
        setDeleteDialog(true)
    };

    const deleteWhatsapp = () => {
        setAnchorE1(null);
        deleteWhatsappById(props.data.id)
        setDeleteDialog(false)
    };

    async function deleteWhatsappById() {
        try {
            deleteTemplateById(props.data.id)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeWhatsappTemplateForm();
                        props.whatsappTemplate();
                        showSuccessNotification(resp.Message)
                    } else {
                        showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    let buttonStyle = useMemo(
        () => ({
            borderRadius: "0.5rem",
            height: "2.75rem",
            fontSize: "0.875rem",
            fontWeight: 400,
        }),
        []
    );

    async function addWhatsapp(data) {
        data.type = "whatsapp"
        try {
            addTemplate(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeWhatsappTemplateForm();
                        props.whatsappTemplate();
                        showSuccessNotification(`Template ${data.name} has been added Successfully`);
                    }
                    else {
                        showErrorNotification(typeof resp?.Message === 'object' ? JSON.stringify(resp?.Message) : resp?.Message);
                        // showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    async function updateWhatsapp(data) {
        data.type = "whatsapp"
        try {
            updateTemplate(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        if (props?.whatsappTemplate) {
                            props.whatsappTemplate();
                        }
                        closeWhatsappTemplateForm();
                        showSuccessNotification(`Template ${data.name} has been updated Successfully`);
                    }
                    else {
                        // showErrorNotification(resp?.Message);
                        showErrorNotification(typeof resp?.Message === 'object' ? JSON.stringify(resp?.Message) : resp?.Message);
                        // showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    const getValidationInViewMode = (e, value) => {
        props.data.name = value;
        const regex = /^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/;
        const isMatch = regex.test(value);

        const digitCount = value.length;

        // Check the conditions for validation
        const isValidLength = value.length >= 2 && value.length <= 50;
        const isValidDigitCount = digitCount >= 2 && digitCount <= 50;

        // Final validation check
        if (isMatch && isValidLength && isValidDigitCount) {
            setValid(true);
        } else {
            setValid(false);
        }
    }

    let styleEditButton = useMemo(() => ({
        fontWeight: '400',
        fontSize: '14px',
        color: '#3D8BF8',
    }),
        []
    );

    const styleMenuItemButton = useMemo(
        () => ({
            padding: "0px",
            alignSelf: "center",
            paddingLeft: "10px",
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "8px",
            },
        }),
        []
    );

    const DataForm = (props) => {

        let initData = props.data || {
            name: '',
            text: '',
            type: [],
            templateId: '',
            entityId: ''
        };

        let style1 = useMemo(() => ({
            width: '600px',
            height: "20rem",
            paddingtop: "10px"

        }), [])

        let style2 = useMemo(() => ({
            maxHeight: '76vh',
            overflowY: 'auto',
            padding: '5px'
        }), [])

        return (

            <div className='oz-Whatsapp-add-form'>
                <Formik initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        let data = structuredClone(values);
                        // let newText = "";
                        // newText = data?.text?.trim();

                        if (data?.text === "" || data?.text?.length > 1200) {
                            showErrorNotification("Text should not be empty and allows max of 1200 characters.");
                            return;
                        } else {
                            if (mode === ADMIN_FORM_MODES.add) {
                                addWhatsapp(data);
                            }
                            if (mode !== ADMIN_FORM_MODES.add) {
                                if (valid === true) {
                                    updateWhatsapp(data);
                                }
                            }
                        }
                    }}>

                    {props => (
                        <form onSubmit={props.handleSubmit} id='whatsappForm' className='ozcw-scrollbar' style={mode === ADMIN_FORM_MODES.add ? style1 : style2}>
                            <Grid container spacing={2} marginTop='1px'>
                                {mode === ADMIN_FORM_MODES.add && <Grid item xs={12} sm={12} md={12} lg={12} marginRight="1px" >
                                    <OZInput
                                        name="name"
                                        label="Template Name *"
                                        placeholder="Name"
                                    />
                                </Grid>}
                                {(mode === ADMIN_FORM_MODES.add || mode === ADMIN_FORM_MODES.edit) && (<Grid item xs={12} sm={12} md={12} lg={12} marginRight="1px" >
                                    <OZInput
                                        name="text"
                                        label="Template Text *"
                                        placeholder="Email Subject"
                                    />
                                </Grid>)}
                                {(mode === ADMIN_FORM_MODES.add || mode === ADMIN_FORM_MODES.edit) && (
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} sm={12} md={12} lg={12} marginLeft="15px" marginTop='15px'>
                                            <OZInput
                                                name="entityId"
                                                label="WhatsApp Number *"
                                                placeholder="WhatsApp Number"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} marginLeft="15px" >
                                            <OZInput
                                                name="templateId"
                                                label="Language *"
                                                placeholder="Language"
                                            />
                                        </Grid>
                                    </Grid>)}
                            </Grid>
                        </form>)}
                </Formik>
                {mode === ADMIN_FORM_MODES.edit && (
                    <div className='oz-template-drawer-buttons'>
                        <Grid item marginRight={'10px'} >
                            <Button disabled={mode === ADMIN_FORM_MODES.view}
                                sx={{ ...buttonStyle, }}
                                variant='outlined'
                                onClick={closeWhatsappTemplateForm}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item marginRight={'10px'}>
                            <Button
                                disabled={mode === ADMIN_FORM_MODES.view}
                                sx={{ ...buttonStyle, }}
                                variant='contained'
                                type="submit"
                                form='whatsappForm'
                            >
                                Save Changes
                            </Button>
                        </Grid>
                    </div>)}
            </div >
        )
    };

    return (
        <div>
            <div >
                {mode === ADMIN_FORM_MODES.add && (
                    <OZDialog
                        title="Template"
                        subTitle=" Create Whatsapp Template"
                        formType="template"
                        open={open}
                        mode={mode}
                        saveButtonProps={{
                            form: "whatsappForm",
                            type: 'submit',
                            label: 'save'
                        }}
                        onClose={closeWhatsappTemplateForm}>
                        <Divider style={{ color: "#E6E5E6" }}></Divider>
                        {mode === ADMIN_FORM_MODES.add && <DataForm />}
                    </OZDialog>
                )}
            </div>
            {mode !== ADMIN_FORM_MODES.add && (
                <OZDrawer
                    open={open}
                    formType='ivr'
                    title={
                        <div className='oz-template-drawer-textdeild-container'>
                            <div className='oz-template-drawer-title-container'>
                                {/* {mode !== ADMIN_FORM_MODES.edit && authPage(AdminPages.TEMPLATES_EDIT) && <img className='oz-template-view-morevert-img' ref={anchorRef}
                                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                    alt="edit"
                                    onClick={morevertclick}
                                ></img>} */}
                                {/* (emailTemplateFormMode !== ADMIN_FORM_MODES.edit &&(newAuthPages(NewAdminPages.TEMPLATES_PAGE)|| newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit')))) */}
                                {(mode !== ADMIN_FORM_MODES.edit && (newAuthPages(NewAdminPages.TEMPLATES_PAGE) || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit'))) && <img className='oz-template-view-morevert-img' ref={anchorRef}
                                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                    alt="edit"
                                    onClick={morevertclick}
                                ></img>}
                                {mode !== ADMIN_FORM_MODES.edit &&
                                    <Tooltip title={props?.data?.name}>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '18px',
                                                alignSelf: 'center',
                                                // marginLeft: authPage(AdminPages.TEMPLATES_EDIT) ? "0px" : "22px",
                                                marginLeft: '0px',
                                                maxWidth: "400px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
                                            }}
                                        >
                                            {props?.data?.name}
                                        </Typography></Tooltip>}

                                {mode === ADMIN_FORM_MODES.edit &&
                                    <TextField
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: "none",
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                fontWeight: 600,
                                                fontSize: "18px",
                                                color: "#212121",
                                                borderRadius: '8px',
                                                backgroundColor: '#ffff',
                                                border: "1px solid #D6D6D7",
                                                height: '44px',
                                                '&:hover': {
                                                    border: "1px solid #D6D6D7"
                                                },
                                            },
                                        }}
                                        defaultValue={props?.data?.name}
                                        fullWidth
                                        // onChange={(e) => { props.data.name = e.target.value }}
                                        onChange={(e) => getValidationInViewMode(e, e.target.value)}
                                        error={!valid}
                                        helperText={!valid ? "Name should not be empty and it should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters." : ""}
                                    />
                                }
                                <div >
                                    <Menu
                                        anchorEl={anchorE1}
                                        open={opens}
                                        onClose={handleMenuClose}
                                        sx={{
                                            "& .MuiPaper-root": {
                                                backgroundColor: "white",
                                                height: "37px",
                                                width: "133px",
                                            },
                                            "& .MuiMenu-paper": {
                                                backgroundColor: "white",
                                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                                border: "0.0625rem solid #E6E5E6",
                                                borderRadius: "12px",
                                                marginLeft: "-8.9625rem",
                                                marginTop: '-1.5rem'
                                            },
                                        }}
                                    >
                                        <MenuItem sx={{ ...styleMenuItemButton }} disabled={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit')} onClick={openDeleteDialog}>Delete</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                            {openDelete &&
                                <DeleteDialog
                                    title={"Delete Whatsapp Template"}
                                    // content={"This will delete this Whatsapp Template : " + props.data.name + ". permanently. You cannot undo this action."}
                                    content={`This will delete (${props.data.name}) Whatsapp Template permanently. You cannot undo this action.`}
                                    onClose={() => { setDeleteDialog(false) }}
                                    open={openDelete}
                                    deleteFunction={deleteWhatsapp}
                                />
                            }
                            <div className='oz-template-drawer-label-container'>
                                <Typography marginLeft={mode === ADMIN_FORM_MODES.edit ? "2px" : "23px"} marginBottom={mode === ADMIN_FORM_MODES.edit ? "0px" : "3px"} marginTop={mode === ADMIN_FORM_MODES.edit ? "5px" : "2px"} style={{ color: "#99A0A8", fontSize: '14px' }}> Whatsapp Template</Typography>
                                {mode === ADMIN_FORM_MODES.edit && (<Typography
                                    size="small"
                                    variant="text"
                                    sx={{
                                        ...styleEditButton,
                                        marginLeft: "408px",
                                        marginTop: "5px",
                                        position: "absolute",
                                        cursor: "pointer"
                                    }}
                                    onClick={onClickButton}
                                >
                                    Discard
                                </Typography>)}
                            </div>
                        </div>
                    }
                    onClose={closeWhatsappTemplateForm}
                    onEdit={EditWhatsappTemplateForm}
                    hideEditIcon={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'View')}
                    // hideEditIcon={!authPage(AdminPages.TEMPLATES_EDIT)}
                    mode={mode}
                >
                    {mode !== ADMIN_FORM_MODES.edit && (
                        <WhatsappView data={props.data} />
                    )}
                    {mode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}
                </OZDrawer>
            )}

        </div>
    );
};
export default WhatsappTemplateForm;