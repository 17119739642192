import { AccessTime, KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon } from '@mui/icons-material';
import { Badge, Box, Grid, Menu, MenuItem, Snackbar,IconButton, ToggleButton, ToggleButtonGroup, Typography, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TimePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import React, { Fragment, useEffect } from 'react';
import { ReactComponent as FilterAltOutlinedIcon } from '../../static/icons/filter.svg';
import { ReactComponent as GraphIcon } from '../../static/icons/graph.svg';
import { ReactComponent as TableIcon } from '../../static/icons/table.svg';
import { ReactComponent as BrkDownbySkillicon } from '../../static/icons/breakDownBySkillIcon.svg';
import { ReactComponent as GraphIconNew } from '../../static/icons/graphIconNew.svg';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import ReportDownload from '../Report-Download/Report-Download';
import WidgetGraph from '../Widget-Graph/Widget-Graph';
import WidgetSearch from '../Widget-Search/Widget-Search';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import SelectPopover from '../SelectPopover/SelectPopover';
import NewSelectPopover from '../SelectPopover/NewSelectPopover';
import { ReactComponent as RefreshOutlinedIcon } from '../../static/icons/refresh.svg';
dayjs.extend(utc);
dayjs.extend(tz);

const useStyles: any = makeStyles(() => ({
	dropdownMenu: {
		minWidth: 'fit-content !important',
		border: '1px solid #e6e5e6 !important',
		boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04) !important',
		borderRadius: '12px !important',
		marginTop: '10px !important'
	},
	dropdownMenuItem: {
		fontWeight: '400 !important',
		fontSize: '12px !important',
		lineHeight: '14px !important',
		color: '#212121 !important'
	},
	dropdownLabelMenuItem: {
		fontWeight: '400 !important',
		fontSize: '12px !important',
		lineHeight: '14px !important',
		color: '#212121 !important',
		width: '149px'
	},
	boundButton: {
		padding: '5px !important'
	},
	dropdownSearchContainer: {
		height: '30px',
		border: '1px solid #D6D6D7',
		borderRadius: '8px',
		backgroundColor: '#F2F2F2'
	},
	searchContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: '#FFFFFF',
		height: '28px',
		borderLeft: '1px solid #D6D6D7',
		borderRadius: '0px 8px 8px 0px',
		width: '193px',
		padding: '0px 8px'
	},
	searchInput: {
		border: 'none',
		outline: 'none'
	},
	timeInputsContainer: {
		borderRadius: '8px 8px 8px 8px',
		border: '1px solid #d6d6d7',
		backgroundColor: '#ffffff',
		height: '30px'
	},
	timeInput: {
		width: '115px',
		padding: '0px'
	},
	arrowIconContainer: {
		backgroundColor: '#d6d6d7',
		width: '30px',
		border: '1px solid #d6d6d7',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	timePickerMenu: {
		width: '350px !important'
	},
	singleDatePickerMenu: {
		width: '300px !important'
	},
	alignAccestime: {
		paddingTop: '10px',
		paddingLeft: '10px',
		paddingBottom: '5px'
	},
	todayTypography: {
		color: '#3D8BF8 !important',
		paddingLeft: '5px'
	},
	lastTypography: {
		paddingTop: '30px',
		paddingLeft: '20px'
	},
	timePickerContainer: {
		border: '2px solid #E6E5E6',
		borderRadius: '10px',
		margin: '0px 12px 0px 12px',
		width: 'fit-content !important'
	}
}));

type durationobject = {
	fromTime: string;
	toTime: string;
};

type DisabledTime = (now: Dayjs) => {
	disabledHours?: () => number[];
	disabledMinutes?: (selectedHour: number) => number[];
	disabledSeconds?: (selectedHour: number, selectedMinute: number) => number[];
};
interface Props {
	// showDurationMenu?: boolean;
	// showDownload?: boolean;
	// showThresholdDrawer?: boolean;
	// showFilterDrawer?: boolean;
	title?: any;
	showSearch?: boolean;
	handleBound?(event: React.MouseEvent<HTMLElement>, bound: string): void;
	duration?: string;
	selectedSearchField?: any;
	handleDuration?(duration?: string, time?: durationobject): void;
	handleViewToggle?(event: React.MouseEvent<HTMLElement>): void;
	downloadContent?: boolean;
	handleDownload?(type?: string, filters?: any): void;
	toggleThresholdDrawer?(isOpen?: boolean, data?: any): void;
	toggleFilterDrawer?(isOpen?: boolean): void;
	filters?: any;
	bound?: string | null;
	agentBound?: string | null;
	showGraphData?: Boolean;
	showToggleIcon?: boolean;
	showToggleIconWithName?: boolean;
	onSearchFieldSelect?(type: string): void;
	handleSearchInput?(event: any): void;
	searchFields?: any[];
	boundValues?: any[];
	showTimepicker?: boolean;
	fromTime?: Dayjs;
	toTime?: Dayjs;
	handleChangeFromTime?(newValue: Dayjs | null): void;
	handleChangeToTime?(newValue: Dayjs | null): void;
	enableTodayDuration?: boolean;
	enableLastThreeDaysDuration?: boolean;
	enableSameDayLastWeek?: boolean;
	searchLabel?: string;
	onSearchClick?(event: any): void;
	withTimePicker?: boolean;
	allowTodayDateInCustomDate?: boolean;
	allowFutureDates?: boolean;
	allowSingleCustomDate?: boolean;
	additionalColumns?: any[];
	onColumnsChange?(obj: any): void;
	hamburger?: any;
	enableToday?: any;
	enableRefreshButton?: boolean;
	lastFetchedTime?: any;
	handleRefreshClick?(event: any): void;
	selectedElements?: any[];
}

const ReportsPageHead = ({
	duration,
	selectedSearchField,
	handleDuration,
	handleViewToggle,
	downloadContent,
	handleDownload,
	toggleThresholdDrawer,
	toggleFilterDrawer,
	filters,
	showSearch = false,
	title,
	handleBound,
	bound,
	agentBound,
	showGraphData,
	showToggleIcon,
	showToggleIconWithName,
	onSearchFieldSelect,
	handleSearchInput,
	searchFields,
	boundValues = [
		{ name: 'Default View', value: 'DEFAULT_VIEW' },
		{ name: 'Detailed View', value: 'DETAILED_VIEW' }
	],
	showTimepicker = false,
	fromTime,
	toTime,
	handleChangeFromTime = () => { },
	handleChangeToTime = () => { },
	enableTodayDuration,
	enableLastThreeDaysDuration,
	enableSameDayLastWeek,
	searchLabel,
	onSearchClick,
	withTimePicker,
	allowTodayDateInCustomDate,
	allowFutureDates,
	allowSingleCustomDate,
	additionalColumns,
	onColumnsChange,
	hamburger,
	enableToday,
	enableRefreshButton = false,
	lastFetchedTime,
	handleRefreshClick,
	selectedElements,
}: Props) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [customAnchorEl, setCustomAnchorEl] = React.useState<null | HTMLElement>(null);
	const [searchFieldMenuAnchorEl, setSearchFieldMenuAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const customOpen = Boolean(customAnchorEl);
	const labelOpen = Boolean(searchFieldMenuAnchorEl);
	const [startTime, setStartTime] = React.useState<Dayjs>(dayjs().tz(localStorage.getItem('userTZ') || '').startOf('day'));
	// const [startTime, setStartTime] = React.useState<Dayjs>(dayjs.tz('00:00:00', 'HH:mm:ss', localStorage.getItem('userTZ') || ''));
	const [endTime, setEndTime] = React.useState<Dayjs>(dayjs().tz(localStorage.getItem('userTZ') || ''));
	const searchInputRef = React.useRef<any>();
	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const [isColumnsPopoverOpen, setIsColumnsPopoverOpen] = React.useState<boolean>(false);
	const [columnsEleRefPoint, setColumnsElRefPoint] = React.useState<any>(null);
	const [selectedColumns, setSelectedColumns] = React.useState<any>(selectedElements);

	const onHamburgerClick = (event: any) => {
		setColumnsElRefPoint({ top: event.clientY, left: event.clientX });
		setIsColumnsPopoverOpen(true);
	};

	const handleOnColumnsPopoverClose = () => {
		setColumnsElRefPoint(null);
		setIsColumnsPopoverOpen(false);
	};

	const handleOnSelectColumnChange = (selected: any) => {
		setSelectedColumns(selected);
		onColumnsChange && onColumnsChange(selected);
		handleOnColumnsPopoverClose();
	};

	useEffect(() => {
		setSelectedColumns(selectedElements);
	}, [selectedElements]);

	const onStartTimeChange = (time: Dayjs | any) => {
		if (endTime && time > endTime) {
			setSnackbarMessage('Start time should be lesser than end time');
			setOpenSnackbar(true);
			return;
		} else {
			setStartTime(time);
			handleSelectDuration('Today', { newStartTime: time });
		}
	};

	const onEndTimeChange = (time: Dayjs | any) => {
		if (time < startTime) {
			setSnackbarMessage('End time should be greater than start time');
			setOpenSnackbar(true);
			return;
		} else {
			setEndTime(time);
			handleSelectDuration('Today', { newEndTime: time });
		}
	};

	const handleRefreshTimeChange = () => {
		//making fromTime and toTime to current time
		const startTimeRefresh = dayjs().tz(localStorage.getItem('userTZ') || '').startOf('day');
		const EndTimeRefresh = dayjs().tz(localStorage.getItem('userTZ') || '');
		setStartTime(startTimeRefresh);
		setEndTime(EndTimeRefresh);
		handleSelectDuration('Today', {newStartTime: startTimeRefresh, newEndTime: EndTimeRefresh });
	};

	// const getRange = (start: number, end: number): number[] => [...Array(end - start + 1).keys()].map((i) => i + start);
	// const disabledTime = useCallback(
	// 	(d: Dayjs): DisabledTime => {
	// 		if (d.isSame(startTime, 'day')) {
	// 			return {
	// 				// disabledHours: () => (startTime.hour() > 0 ? getRange(0, startTime.hour() - 1) : []),
	// 				disabledMinutes: (hour: any) => (hour === startTime.hour() && startTime.minute() > 0 ? getRange(0, startTime.minute() - 1) : [])
	// 			};
	// 		}

	// 		if (d.isSame(endTime, 'day')) {
	// 			return {
	// 				// disabledHours: () => getRange(endTime.hour() + 1, 24),
	// 				disabledMinutes: (hour: any) => (hour === endTime.hour() ? getRange(endTime.minute() + 1, 60) : [])
	// 			};
	// 		}
	// 	},
	// 	[startTime, endTime]
	// );

	const handleSelectDuration = (duration: any, { newStartTime = startTime, newEndTime = endTime } = {}) => {
		let fromTime = '';
		let toTime = '';
		switch (duration) {
			case 'Today':
				if (allowSingleCustomDate) {
					fromTime = newStartTime?.format('YYYY-MM-DD');
					toTime = newEndTime?.format('YYYY-MM-DD');
				} else {
					fromTime = newStartTime?.format('YYYY-MM-DD HH:mm:ss');
					toTime = newEndTime?.format('YYYY-MM-DD HH:mm:ss');
				}
				break;

			case 'Yesterday':
				const now = new Date();
				const yesterday = new Date(now);
				yesterday.setDate(now.getDate() - 1);
				yesterday.setHours(0, 0, 0, 0);

				fromTime = yesterday.getFullYear() + '-' + (yesterday.getMonth() + 1).toString().padStart(2, '0') + '-' + yesterday.getDate().toString().padStart(2, '0') + ' 00:00:00';
				toTime = yesterday.getFullYear() + '-' + (yesterday.getMonth() + 1).toString().padStart(2, '0') + '-' + yesterday.getDate().toString().padStart(2, '0') + ' 23:59:59';

				// console.log("From Time:", fromTime);
				// console.log("To Time:", toTime);

				// const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
				// fromTime = yesterday.toISOString().split('T')[0] + ' 00:00:00';
				// toTime = yesterday.toISOString().split('T')[0] + ' 23:59:59';
				// console.log("From-Y--->",fromTime);
				// console.log("To-Y--->",toTime);
				break;

			case 'Same day last week':
				const sameDayLastWeek = new Date(new Date().setDate(new Date().getDate() - 7));
				fromTime = sameDayLastWeek.toISOString().split('T')[0] + ' 00:00:00';
				toTime = sameDayLastWeek.toISOString().split('T')[0] + ' 23:59:59';
				break;

			case 'Last 7 Days':
				const today = new Date();
				today.setHours(0, 0, 0, 0); // Set to the beginning of the current day

				// Calculate the end of the current day
				const todayEnd = new Date(today);
				todayEnd.setHours(23, 59, 59, 999); // Set to 23:59:59.999 on the current day

				// Calculate fromTime as 7 days before todayEnd
				const fromTimeDate = new Date(todayEnd);
				fromTimeDate.setDate(fromTimeDate.getDate() - 7);
				fromTime = fromTimeDate.toISOString().split('T')[0] + ' 00:00:00';

				// Calculate toTime as the end of the previous day
				const yesterdayEnd = new Date(todayEnd);
				yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);
				toTime = yesterdayEnd.toISOString().split('T')[0] + ' 23:59:59';
				break;

			case 'Last 3 Days':
				const last3Days = new Date(new Date().setDate(new Date().getDate() - 3));
				fromTime = last3Days.toISOString().split('T')[0] + ' 00:00:00';
				toTime = new Date().toISOString().split('T')[0] + ' 23:59:59';
				break;
		}

		// console.log(fromTime, toTime)
		if (handleDuration && fromTime && toTime) handleDuration(duration, { fromTime, toTime });
		setAnchorEl(null);
	};
	const handleCustomMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setCustomAnchorEl(event.currentTarget);
	};
	const handleCustomMenuClose = (event: any) => {
		setCustomAnchorEl(null);
		if (!!event) {
			setAnchorEl(null);
		}
	};

	const handleSearchFieldMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		if (event.currentTarget) setSearchFieldMenuAnchorEl(event.currentTarget);
	};

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		if (event.currentTarget) setAnchorEl(event.currentTarget);
	};

	const handleSelectSearchField = (type: any = null) => {
		if (type && onSearchFieldSelect) {
			onSearchFieldSelect(type);
		}
		setSearchFieldMenuAnchorEl(null);
	};

	const handleSearchClick = (searchText: string) => {
		if (onSearchClick) {
			onSearchClick({ target: { value: searchText } });
		}
	};

	// const handleKeyDown = (event: any) => {
	// 	if ((event.target.type !== 'text' && !event.metaKey && event.key?.match(/[a-z]+/i)?.[0].length === 1) || event.key === '-') {
	// 		return event.preventDefault();
	// 	}
	// 	if (event.key === 'Enter') {
	// 		handleSearchClick(searchInputRef?.current?.value);
	// 	}
	// };

	const handleKeyDown = (event: any) => {
		if ((event.target.type !== 'text' && !event.metaKey && !event.ctrlKey &&
			event.key?.match(/[a-z]+/i)?.[0].length === 1) || event.key === '-') {
			return event.preventDefault();
		}
		if (event.key === 'Enter') {
			handleSearchClick(searchInputRef?.current?.value);
		}
	};

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	};

	useEffect(() => {
		handleSelectDuration(duration);
		// setStartTime(dayjs('00:00:00', 'HH:mm:ss').tz(localStorage.getItem('userTZ')||''));
		setEndTime(dayjs().tz(localStorage.getItem('userTZ') || ''));
	}, []);

	return (
		<>
			<Grid container sx={{ padding: '1rem' }}>
				<Grid item xs={12} container direction={'row'}>
					<Grid item>
						<Typography variant="h6">{title}</Typography>
					</Grid>
					{enableRefreshButton && (
						<Grid item sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
							<div className="refresh-btn card-icon" style={{marginBottom: '-7px'}} 
							onClick={(event) => {
								if (handleRefreshClick) {
									if(handleDuration){
										handleRefreshTimeChange();
									}
									handleRefreshClick(event);
								}
							  }}>
								<RefreshOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</div>
							<span className="last-fetched-time">Last fetched at: {lastFetchedTime} </span>
						</Grid>
					)}
					<Grid direction={'row'} item xs container alignItems={'center'} justifyContent="flex-end" gap={2}>
						{agentBound !== undefined && (
							<Grid item>
								<ToggleButtonGroup value={agentBound} color="primary" exclusive onChange={handleBound} aria-label="agentBound">
									<ToggleButton value="calltype" className={classes.boundButton} aria-label="calltype">
										<span>Call Type</span>
									</ToggleButton>
									<ToggleButton value="callmode" className={classes.boundButton} aria-label="callmode">
										<span>Call Mode</span>
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						)}
						{showSearch && (
							<Grid item>
								<WidgetSearch onTextChange={handleSearchInput} onSearchClick={handleSearchClick} placeholder={searchLabel ? searchLabel : 'Search Agent'}></WidgetSearch>
							</Grid>
						)}
						{showTimepicker && (
							<Grid item>
								<Grid container className={classes.timeInputsContainer}>
									<Grid item>
										<TimePicker value={fromTime} placeholder="End Time" onChange={handleChangeFromTime} minuteStep={5} secondStep={5} />
									</Grid>
									<Grid item className={classes.arrowIconContainer}>
										<img src={`${process.env.PUBLIC_URL}/icons/time-side-arrow.svg`} alt="arrow" />
									</Grid>
									<Grid item>
										<TimePicker value={toTime} placeholder="End Time" onChange={handleChangeToTime} minuteStep={5} secondStep={5} />
									</Grid>
								</Grid>
							</Grid>
						)}
						{bound !== undefined && (
							<Grid item>
								<ToggleButtonGroup value={bound} color="primary" exclusive onChange={handleBound} aria-label="bound">
									{boundValues.map((eachBound: any, idx: any) => (
										<ToggleButton key={idx} value={eachBound.value} className={classes.boundButton} aria-label="voice">
											<span>{eachBound.name}</span>
										</ToggleButton>
									))}
								</ToggleButtonGroup>
							</Grid>
						)}
						{onSearchFieldSelect && (
							<Grid item>
								<Grid container className={classes.dropdownSearchContainer}>
									<Grid item>
										<div>
											<div onClick={handleSearchFieldMenuOpen}>
												<Typography
													variant="caption"
													sx={{
														fontWeight: 400,
														fontSize: '12px',
														textAlign: 'right',
														color: '#212121',
														cursor: 'pointer',
														marginRight: '72px',
														marginLeft: '12px'
													}}
												>
													{selectedSearchField.name}
												</Typography>
												<KeyboardArrowDownOutlinedIcon style={{ color: '#536580', fontSize: '10px', marginRight: '13px' }} />
											</div>
											<Menu
												sx={{ width: '50px' }}
												anchorEl={searchFieldMenuAnchorEl}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'right'
												}}
												keepMounted
												transformOrigin={{
													vertical: 'top',
													horizontal: 'right'
												}}
												open={labelOpen}
												onClose={() => handleSelectSearchField()}
												PaperProps={{ className: classes.dropdownMenu }}
											>
												{searchFields?.map((eachField: any, idx: any) => {
													return (
														<MenuItem key={idx} className={classes.dropdownLabelMenuItem} onClick={() => handleSelectSearchField(eachField)}>
															{eachField.name}
														</MenuItem>
													);
												})}
											</Menu>
										</div>
									</Grid>
									<Grid item className={classes.searchContainer}>
										<input
											type={selectedSearchField.type || 'text'}
											placeholder="Search"
											ref={searchInputRef}
											className={classes.searchInput}
											onChange={handleSearchInput}
											onKeyDown={handleKeyDown}
										/>
										<img
											onClick={() => handleSearchClick(searchInputRef?.current?.value)}
											src={`${process.env.PUBLIC_URL}/icons/search-icon.svg`}
											alt="search"
											className="cursor-pointer"
										/>
									</Grid>
								</Grid>
							</Grid>
						)}
						{handleDuration && (
							<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
								<div>
									<div onClick={handleMenuOpen}>
										<Typography
											variant="caption"
											sx={{
												fontWeight: 400,
												fontSize: '12px',
												textAlign: 'start',
												color: '#536580',
												cursor: 'pointer',
												display: 'inline-block'
											}}
										>
											<Fragment>{duration}</Fragment>
										</Typography>
										<KeyboardArrowDownOutlinedIcon style={{ color: '#536580', fontSize: '10px' }} />
									</div>
									<Menu
										sx={{ width: '50px' }}
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'right'
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right'
										}}
										open={open}
										onClose={() => {
											handleDuration();
											setAnchorEl(null);
										}}
										PaperProps={{ className: classes.dropdownMenu }}
									>
										{!allowSingleCustomDate && (<>
											{enableTodayDuration && (
												<Box className={classes.timePickerMenu}>
													<Grid item display={'flex'} className={classes.alignAccestime}>
														<AccessTime style={{ color: '#3D8BF8' }} />
														<Typography className={classes.todayTypography}> Today</Typography>
													</Grid>
													<Grid container flexDirection="row" justifyContent="center" alignContent="center">
														<Grid item xs={4}>
															<TimePicker value={startTime} onChange={onStartTimeChange} defaultValue={startTime} minuteStep={5} secondStep={5} />
														</Grid>
														<Grid item xs={2} className={classes.arrowIconContainer}>
															<img src={`${process.env.PUBLIC_URL}/icons/time-side-arrow.svg`} alt="arrow" />
														</Grid>
														<Grid item xs={4}>
															<TimePicker value={endTime} onChange={onEndTimeChange} defaultValue={endTime} minuteStep={5} secondStep={5} />
														</Grid>
													</Grid>
												</Box>
											)}
											{enableLastThreeDaysDuration && (
												<MenuItem className={classes.dropdownMenuItem} onClick={() => handleSelectDuration('Last 3 Days')}>
													Last 3 Days
												</MenuItem>
											)}
											{(!enableTodayDuration && !enableToday) && (
												<MenuItem className={classes.dropdownMenuItem} onClick={() => handleSelectDuration('Yesterday')}>
													Yesterday
												</MenuItem>
											)}
											{enableToday && (
												<MenuItem className={classes.dropdownMenuItem} onClick={() => handleSelectDuration('Today')}>
													Today
												</MenuItem>
											)}
											{enableSameDayLastWeek && (
												<MenuItem className={classes.dropdownMenuItem} onClick={() => handleSelectDuration('Same day last week')}>
													Same day last week
												</MenuItem>
											)}
											{!enableTodayDuration && (
												<MenuItem className={classes.dropdownMenuItem} onClick={() => handleSelectDuration('Last 7 Days')}>
													Last 7 Days
												</MenuItem>
											)}
											{!enableTodayDuration && (
												<div>
													<MenuItem className={classes.dropdownMenuItem} onClick={handleCustomMenuClick}>
														Custom
													</MenuItem>
													<Menu
														id="demo-positioned-menu"
														aria-labelledby="demo-positioned-button"
														anchorEl={customAnchorEl}
														open={customOpen}
														onClose={handleCustomMenuClose}
														anchorOrigin={{
															vertical: 'top',
															horizontal: 'left'
														}}
														transformOrigin={{
															vertical: 'top',
															horizontal: 'left'
														}}
														sx={{ width: '376px', padding: '0px' }}
													>
														<CustomDatePicker
															handleDuration={handleDuration}
															handleClose={handleCustomMenuClose}
															withTime={withTimePicker}
															allowDateTillDay={allowTodayDateInCustomDate}
															allowFutureDates={allowFutureDates}
															allowSingleDate={false}
														/>
													</Menu>
												</div>
											)}
										</>)}
										{allowSingleCustomDate && (
											<Box className={classes.singleDatePickerMenu}>
												<CustomDatePicker
													handleDuration={handleDuration}
													handleClose={handleCustomMenuClose}
													withTime={withTimePicker}
													allowDateTillDay={allowTodayDateInCustomDate}
													allowFutureDates={allowFutureDates}
													allowSingleDate={allowSingleCustomDate}
												/>
											</Box>
										)}
									</Menu>
								</div>
							</Grid>
						)}
						{showToggleIcon && (
							<Grid item sx={{ display: 'flex', alignItems: 'center', padding: '0 0.25rem', cursor: 'pointer' }} onClick={handleViewToggle}>
								{showGraphData ? <TableIcon /> : <GraphIcon />}
							</Grid>
						)}
						{/* {showToggleIconWithName && (
							<Grid item sx={{ display: 'flex', alignItems: 'center', padding: '0 0.25rem', cursor: 'pointer' }} onClick={handleViewToggle}>
								{showGraphData ? <BrkDownbySkillicon /> : <GraphIconNew />}
							</Grid>
						)} */}
						{hamburger && (
							<Grid>
								<IconButton onClick={onHamburgerClick}>
									<img src={`${process.env.PUBLIC_URL}/icons/table-hamburger-icon.svg`} width="20" alt="Hamburger" style={{ marginTop: '5px' }}></img>
								</IconButton>
							</Grid>
						)}
						{downloadContent && (
							<Grid item sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
								<ReportDownload title={title} handleDownload={handleDownload}></ReportDownload>
							</Grid>
						)}
						{toggleThresholdDrawer && (
							<Grid item sx={{ display: 'flex', alignItems: 'center', padding: '0 0.25rem', cursor: 'pointer' }} onClick={() => toggleThresholdDrawer(true)}>
								<WidgetGraph></WidgetGraph>
							</Grid>
						)}
						{toggleFilterDrawer && (
							<Grid item sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => toggleFilterDrawer(true)}>
								<Badge badgeContent={typeof filters === 'object' ? Object.values(filters).length : filters?.length} color="primary">
									<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
								</Badge>
							</Grid>
						)}
						{/* {hamburger && (
							<Grid>
								<IconButton onClick={onHamburgerClick}>
									<img src={`${process.env.PUBLIC_URL}/icons/table-hamburger-icon.svg`} width="20" alt="Hamburger" style={{ marginTop: '5px' }}></img>
								</IconButton>
							</Grid>
						)} */}
					</Grid>
				</Grid>
				<NewSelectPopover
					isOpen={isColumnsPopoverOpen}
					elementsArray={additionalColumns || []}
					anchorRef={columnsEleRefPoint}
					handlePopoverClose={handleOnColumnsPopoverClose}
					selectedElements={selectedColumns}
					handleOnSelectionChange={handleOnSelectColumnChange}
					addMetricsLabel
				/>
			</Grid>
			<Snackbar open={openSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleClose}>
				<Alert
					iconMapping={{
						error: <DoDisturbOnIcon style={{ color: 'red' }} />
					}}
					onClose={handleClose}
					severity="error"
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
};

export default ReportsPageHead;
