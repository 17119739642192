import { Button, Grid } from "@mui/material";
import { FieldArray } from "formik";
import OZSelect from "../../../components/admin/common/OZSelect";


export function SkillAutocomplete(skillAutoCompleteprops) {
    return (
        <FieldArray name="skills">
            {({ remove, push }) => (
                <div>
                    {skillAutoCompleteprops?.formikValues?.values?.skills?.length > 0 && skillAutoCompleteprops?.formikValues?.values?.skills?.map((skill, index) => (
                        <div key={index}>
                            <Grid container spacing={1}>
                                <Grid item xs={skillAutoCompleteprops?.mode === "Create" ? 3.5 : 4.3} style={{ padding: skillAutoCompleteprops?.mode === "Create" ? '10px' : '10px', width: skillAutoCompleteprops?.mode === "Create" ? '50%' : '0%', marginTop: skillAutoCompleteprops?.mode === "Create" ? '0px' : '0px' }}>
                                    <OZSelect
                                        name={`skills.${index}.skills`}
                                        label=" Skill Name *"
                                        placeholder="Select Skill"
                                        optionLabel="skillName"
                                        options={skillAutoCompleteprops?.skillDataList}
                                    />
                                </Grid>
                                <Grid item xs={skillAutoCompleteprops?.mode === "Create" ? 3.5 : 3.2}>
                                    <OZSelect
                                        name={`skills.${index}.multiskillqueueTimeOut_Min`}
                                        label="QueueTimeout (in Min)"
                                        placeholder="Select Mins"
                                        options={[...Array(31).keys()]}
                                    />
                                </Grid>
                                <Grid item xs={skillAutoCompleteprops?.mode === "Create" ? 3.5 : 3.2}>
                                    <OZSelect
                                        name={`skills.${index}.multiskillqueueTimeOut_Sec`}
                                        label="(in Sec)"
                                        placeholder="Select Secs"
                                        options={[...Array(60).keys()]}
                                    />
                                </Grid>

                                {skillAutoCompleteprops?.formikValues?.values?.skills?.length > 1 && index > 0 &&
                                    <div style={{ paddingLeft: skillAutoCompleteprops?.mode === "Create" ? "0px" : "3px", marginTop: skillAutoCompleteprops?.mode === "Create" ? "35px" : "35px", cursor: "pointer" }} >
                                        <img onClick={() => { remove(index) }}
                                            src={`${process.env.PUBLIC_URL}/icons/deleteIconGrey.svg`}
                                            alt="remove"
                                        >
                                        </img>
                                    </div>
                                }
                            </Grid>
                            {skillAutoCompleteprops?.formikValues?.values?.skills?.length === (index + 1) &&
                                <Button variant="text"
                                    sx={{ fontSize: "14px", fontWeight: 400, color: skillAutoCompleteprops?.mode === 'Create' ? '#536580' : '#3D8BF8', padding: "0px 0px 0px 0px", marginLeft: "1px" }}
                                    onClick={() => push({ multiskillqueueTimeOut_Min: 0, multiskillqueueTimeOut_Sec: 1, skillName: [] })}
                                >
                                    <img style={{ paddingRight: "6px", paddingBottom: "3px" }}
                                        src={skillAutoCompleteprops?.mode === 'Create' ? `${process.env.PUBLIC_URL}/icons/anotherlevel.svg` : `${process.env.PUBLIC_URL}/icons/anotherlevelblue.svg`}
                                        alt="edit" >
                                    </img>
                                    Another level
                                </Button>}
                        </div>
                    ))}
                </div>
            )}
        </FieldArray>
    )
}