import React, { useState, useMemo } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Grid, Button } from "@mui/material";
import OZInput from "../../../components/admin/common/OZInput";
import OZDialog from "../../../components/admin/common/OZDialog";
import { saveMusic, updateMusicById } from "../../../services/api-service";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";

const MusicForm = (props) => {
    let initMode = props.data ? ADMIN_FORM_MODES.edit : ADMIN_FORM_MODES.add
    const editAccess = (initMode === ADMIN_FORM_MODES.edit && props?.editAccess === true || (initMode === ADMIN_FORM_MODES.add));
    const [showMusicForm, setMusicFormState] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [mode, setMode] = useState(initMode);
    const [newLoading, setNewLoading] = useState(false);

    const formButtonStyle = useMemo(
        () => ({
            borderRadius: '8px',
            fontWeight: '400',
            fontSize: '14px',
        }),
        []
    );

    const validationSchema = useMemo(
        () =>
            yup.object({
                name: yup
                    .string()
                    .min(3, "Name should be between 3 to 50 characters.")
                    .max(50, "Name should be between 3 to 50 characters.")
                    .matches(/^[A-Za-z0-9_]+$/, "Name should be between 3 to 50 alphanumeric characters and allow special characters like underscore only.")
                    .required("Name is required"),
                audioUrl: yup
                    .string()
                    .min(5, "Audio URL Should start with http|https and Ends with .wav/.mp3 and atleast one character to be contain in between and allows Max Length of 100 characters")
                    .max(100, "Audio URL Should start with http|https and Ends with .wav/.mp3 and atleast one character to be contain in between and allows Max Length of 100 characters")
                    .matches(/^(https?:\/\/)(.{1,255}\.)(wav|mp3)$/, "Audio URL Should start with http|https and Ends with .wav/.mp3 and atleast one character to be contain in between and allows Max Length of 100 characters")
                    .required("Audio URL is required.")
            }),
        []
    );

    const closeMusicForm = () => {
        localStorage.removeItem('musicData');
        props.onClose();
        setMusicFormState(false);
    };

    async function addMusic(data) {
        try {
            saveMusic(data).then((resp) => {
                if (resp.Status === "Success") {
                    showSuccessNotification(`(${data?.name}) ${resp?.Message}`);
                    props.updateName();
                    closeMusicForm();
                    setNewLoading(false);
                }
                else {
                    showErrorNotification(resp.Message.name || resp.Message);
                    setNewLoading(false);
                }
            }).catch((e) => {
                showErrorNotification("Error adding music");
                setNewLoading(false);
            });
        } catch (e) {
            showErrorNotification("Error in music adding ");
            setNewLoading(false);
        }
    };

    async function updatemusic(data) {
        try {
            updateMusicById(data).then((resp) => {
                if (resp.Status === "Success") {
                    closeMusicForm();
                    props.updateName();
                    showSuccessNotification(`(${data?.name}) ${resp?.Message}`);
                    setNewLoading(false);
                }
                else {
                    showErrorNotification(resp.Message.name || resp.Message);
                    setNewLoading(false);
                }
            }).catch((e) => {
                showErrorNotification("Error updating music");
                setNewLoading(false);
            });
        } catch (e) {
            showErrorNotification("Error in music update");
            setNewLoading(false);
        }
    };

    const DataForm = (formProps) => {
        let initData = formProps.editData || {
            name: "",
            audioUrl: "",
        };

        // console.log(props.data);

        function getInitData() {
            let localData = localStorage.getItem('musicData');
            if (localData) {
                return JSON.parse(localData);
            } else {
                return initData;
            }
        }

        return (
            <div className="ozAddLocationform">
                <Formik
                    validateOnMount
                    initialValues={getInitData()}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (mode === ADMIN_FORM_MODES.edit || mode === ADMIN_FORM_MODES.add) {
                            localStorage.setItem('musicData', JSON.stringify(values));
                        }
                        let saveData = structuredClone(values);
                        if (mode === ADMIN_FORM_MODES.add) {
                            setNewLoading(true);
                            addMusic(saveData);
                        }
                        else {
                            setNewLoading(true);
                            updatemusic(saveData);
                        }
                    }}
                >
                    {
                        (props) => (
                            <div>
                                <form
                                    onSubmit={props.handleSubmit}
                                    id="musicForm"
                                    style={{
                                        width: "464px",
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <OZInput
                                                name="name"
                                                placeholder="Enter Name"
                                                label="Name *"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <OZInput
                                                name="audioUrl"
                                                placeholder="Audio URL"
                                                label="Audio URL *"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 1 }} justifyContent='end'>
                                        <Grid item>
                                            <Button style={formButtonStyle} onClick={closeMusicForm} variant="outlined">
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item >
                                            {editAccess && (
                                                <Button style={formButtonStyle} variant="contained" onClick={() => {
                                                    if (!props.isValid) {
                                                        props.handleSubmit();
                                                        showErrorNotification("Please Check the values and fill all the mandatory field");
                                                    }
                                                }} type="submit" form="musicForm">
                                                    Save
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {newLoading &&
                                        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                            <LoadingScreen />
                                        </div>
                                    }
                                </form>
                            </div>
                        )}
                </Formik>
            </div>
        );
    };

    return (
        <div>
            <OZDialog
                hideActions
                title={
                    <label style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
                        {mode === ADMIN_FORM_MODES.add ? "Add Music" : `Edit Music`}
                    </label>
                }
                open={showMusicForm}
                onClose={closeMusicForm}
            >
                {mode === ADMIN_FORM_MODES.view && <DataForm editData={props.data} />}
                {mode === ADMIN_FORM_MODES.add && <DataForm />}
                {mode === ADMIN_FORM_MODES.edit && <DataForm editData={props.data} />}
            </OZDialog>
        </div>
    );
};

export default MusicForm;