import React from 'react';
import { FormControlLabel, FormControl, FormGroup, Checkbox, FormHelperText, Typography } from '@mui/material';
import { connect, getIn } from "formik";
import CustomizedCheckbox from './CustomCheckBox';


const OZFCheckbox = connect(({ name, label, options, direction, disabled, labelPosition, multi, formik, labelSize,onChange }) => {

    const error = getIn(formik.errors, name);
    const touch = getIn(formik.touched, name);

    let selectedValues = getIn(formik.values, name);
    
    const handleChange = (event) => {

        formik.handleChange(event);

        if (onChange) {

            onChange(event.target.checked); // Pass the checked value to the onChange function

        }

    };

    return (
        <React.Fragment>
            <FormControl component="fieldset" required
                error={error}
                variant="standard">
                {(multi && label) && <Typography fontSize='14px' paddingBottom={'2px'} htmlFor={name}>{label}</Typography>}
                {multi ? <FormGroup row={direction === 'row' || false}>
                    {options.map((option, index) => {
                        return <FormControlLabel
                            value={option}
                            key={option + index}
                            control={
                                <CustomizedCheckbox size='small'
                                    disabled={disabled}
                                    onChange={handleChange}
                                    checked={selectedValues && selectedValues.length !== 0 ? selectedValues.includes(option) : false}
                                    id={name} />
                            }
                            label={label} />
                    })}

                </FormGroup> :
                    <FormControlLabel
                        value={selectedValues}
                        control={
                            <CustomizedCheckbox size='small'
                                disabled={disabled}
                                onChange={handleChange}
                                checked={selectedValues}
                                id={name} />
                        }
                        label={<Typography fontSize={labelSize ? labelSize : '14px'} fontWeight={400} color={disabled ? "#99A0A8" : '#212121'}>{label}</Typography>} labelPlacement={labelPosition || 'start'} />
                }
                <FormHelperText>{error}</FormHelperText>

            </FormControl>
        </React.Fragment>
    );
})

export default OZFCheckbox;