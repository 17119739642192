import { AccessTime, KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon } from '@mui/icons-material';
import { Box, Grid, Menu, MenuItem, Snackbar, Typography, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TimePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import React, { Fragment, useEffect } from 'react';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import NewSelectPopover from '../SelectPopover/NewSelectPopover';
dayjs.extend(utc);
dayjs.extend(tz);

const useStyles: any = makeStyles(() => ({
	dropdownMenu: {
		minWidth: 'fit-content !important',
		border: '1px solid #e6e5e6 !important',
		boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04) !important',
		borderRadius: '12px !important',
		marginTop: '10px !important'
	},
	dropdownMenuItem: {
		fontWeight: '400 !important',
		fontSize: '12px !important',
		lineHeight: '14px !important',
		color: '#212121 !important'
	},
	dropdownLabelMenuItem: {
		fontWeight: '400 !important',
		fontSize: '12px !important',
		lineHeight: '14px !important',
		color: '#212121 !important',
		width: '149px'
	},
	boundButton: {
		padding: '5px !important'
	},
	dropdownSearchContainer: {
		height: '30px',
		border: '1px solid #D6D6D7',
		borderRadius: '8px',
		backgroundColor: '#F2F2F2'
	},
	searchContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: '#FFFFFF',
		height: '28px',
		borderLeft: '1px solid #D6D6D7',
		borderRadius: '0px 8px 8px 0px',
		width: '193px',
		padding: '0px 8px'
	},
	searchInput: {
		border: 'none',
		outline: 'none'
	},
	timeInputsContainer: {
		borderRadius: '8px 8px 8px 8px',
		border: '1px solid #d6d6d7',
		backgroundColor: '#ffffff',
		height: '30px'
	},
	timeInput: {
		width: '115px',
		padding: '0px'
	},
	arrowIconContainer: {
		backgroundColor: '#d6d6d7',
		width: '30px',
		border: '1px solid #d6d6d7',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	timePickerMenu: {
		width: '350px !important'
	},
	singleDatePickerMenu: {
		width: '300px !important'
	},
	alignAccestime: {
		paddingTop: '10px',
		paddingLeft: '10px',
		paddingBottom: '5px'
	},
	todayTypography: {
		color: '#3D8BF8 !important',
		paddingLeft: '5px'
	},
	lastTypography: {
		paddingTop: '30px',
		paddingLeft: '20px'
	},
	timePickerContainer: {
		border: '2px solid #E6E5E6',
		borderRadius: '10px',
		margin: '0px 12px 0px 12px',
		width: 'fit-content !important'
	}
}));

type durationobject = {
	fromTime: string;
	toTime: string;
};

type DisabledTime = (now: Dayjs) => {
	disabledHours?: () => number[];
	disabledMinutes?: (selectedHour: number) => number[];
	disabledSeconds?: (selectedHour: number, selectedMinute: number) => number[];
};
interface Props {
	// showDurationMenu?: boolean;
	// showDownload?: boolean;
	// showThresholdDrawer?: boolean;
	// showFilterDrawer?: boolean;
	title?: any;
	showSearch?: boolean;
	handleBound?(event: React.MouseEvent<HTMLElement>, bound: string): void;
	duration?: string;
	selectedSearchField?: any;
	handleDuration?(duration?: string, time?: durationobject): void;
	handleViewToggle?(event: React.MouseEvent<HTMLElement>): void;
	downloadContent?: boolean;
	handleDownload?(type?: string, filters?: any): void;
	toggleThresholdDrawer?(isOpen?: boolean, data?: any): void;
	toggleFilterDrawer?(isOpen?: boolean): void;
	filters?: any;
	bound?: string | null;
	agentBound?: string | null;
	showGraphData?: Boolean;
	showToggleIcon?: boolean;
	showToggleIconWithName?: boolean;
	onSearchFieldSelect?(type: string): void;
	handleSearchInput?(event: any): void;
	searchFields?: any[];
	boundValues?: any[];
	showTimepicker?: boolean;
	fromTime?: Dayjs;
	toTime?: Dayjs;
	handleChangeFromTime?(newValue: Dayjs | null): void;
	handleChangeToTime?(newValue: Dayjs | null): void;
	enableTodayDuration?: boolean;
	enableLastThreeDaysDuration?: boolean;
	enableSameDayLastWeek?: boolean;
	searchLabel?: string;
	onSearchClick?(event: any): void;
	withTimePicker?: boolean;
	allowTodayDateInCustomDate?: boolean;
	allowFutureDates?: boolean;
	allowSingleCustomDate?: boolean;
	additionalColumns?: any[];
	onColumnsChange?(obj: any): void;
	hamburger?: any;
	enableToday?: any;
	enableLastThirtyMinutesDuration? : any;
    enableLastOneHourDuration? : any;
    enableLastFourHourDuration? : any;
    enableLastEightHourDuration? : any;
    enableWidgetIcon?: any;
    graphViewTab? : any;
    handleGraphViewTab? : any;
}

const WidgetPageHeadCustom = ({
	duration,
	selectedSearchField,
	handleDuration,
	handleViewToggle,
	downloadContent,
	handleDownload,
	toggleThresholdDrawer,
	toggleFilterDrawer,
	filters,
	showSearch = false,
	title,
	handleBound,
	bound,
	agentBound,
	showGraphData,
	showToggleIcon,
	showToggleIconWithName,
	onSearchFieldSelect,
	handleSearchInput,
	searchFields,
	boundValues = [
		{ name: 'Default View', value: 'DEFAULT_VIEW' },
		{ name: 'Detailed View', value: 'DETAILED_VIEW' }
	],
	showTimepicker = false,
	fromTime,
	toTime,
	handleChangeFromTime = () => { },
	handleChangeToTime = () => { },
	enableTodayDuration,
	enableLastThreeDaysDuration,
	enableSameDayLastWeek,
	searchLabel,
	onSearchClick,
	withTimePicker,
	allowTodayDateInCustomDate,
	allowFutureDates,
	allowSingleCustomDate,
	additionalColumns,
	onColumnsChange,
	hamburger,
	enableToday,
	enableLastThirtyMinutesDuration,
    enableLastOneHourDuration,
    enableLastFourHourDuration,
    enableLastEightHourDuration,
    enableWidgetIcon,
    graphViewTab,
    handleGraphViewTab,
}: Props) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [customAnchorEl, setCustomAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const customOpen = Boolean(customAnchorEl);
	const [startTime, setStartTime] = React.useState<Dayjs | any>(dayjs().tz(localStorage.getItem('userTZ') || '').startOf('day'));
	// const [startTime, setStartTime] = React.useState<Dayjs>(dayjs.tz('00:00:00', 'HH:mm:ss', localStorage.getItem('userTZ') || ''));
	const [endTime, setEndTime] = React.useState<Dayjs | any>(dayjs().tz(localStorage.getItem('userTZ') || ''));
	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const [isColumnsPopoverOpen, setIsColumnsPopoverOpen] = React.useState<boolean>(false);
	const [columnsEleRefPoint, setColumnsElRefPoint] = React.useState<any>(null);
	const [selectedColumns, setSelectedColumns] = React.useState<any>(additionalColumns);


    const [graphanchorEl, setGraphAnchorEl] = React.useState<null | HTMLElement>(null);
    const openGraph = Boolean(graphanchorEl);

	const handleOnColumnsPopoverClose = () => {
		setColumnsElRefPoint(null);
		setIsColumnsPopoverOpen(false);
	};

	const handleOnSelectColumnChange = (selected: any) => {
		setSelectedColumns(selected);
		onColumnsChange && onColumnsChange(selected);
		handleOnColumnsPopoverClose();
	};

	useEffect(() => {
		setSelectedColumns(additionalColumns);
	}, [additionalColumns]);

	const onStartTimeChange = (time: Dayjs | any, value: any) => {
		if (endTime && Math.floor(new Date(time).getTime() / 1000) >= Math.floor(new Date(value).getTime() / 1000)) {
			setOpenSnackbar(true);
			setSnackbarMessage('Start time should be lesser than end time');
			return;
		} else {
			setStartTime(time);
			handleSelectDuration('Today', { newStartTime: time });
			setCustomAnchorEl(null);
		}
	};

	const onEndTimeChange = (time: Dayjs | any, value: any) => {
		if (Math.floor(new Date(time).getTime() / 1000) <= Math.floor(new Date(value).getTime() / 1000)) {
			setOpenSnackbar(true);
			setSnackbarMessage('End time should be greater than start time');
			return;
		} else {
			setEndTime(time);
			handleSelectDuration('Today', { newEndTime: time });
			setCustomAnchorEl(null);
		}
	};

	// const getRange = (start: number, end: number): number[] => [...Array(end - start + 1).keys()].map((i) => i + start);
	// const disabledTime = useCallback(
	// 	(d: Dayjs): DisabledTime => {
	// 		if (d.isSame(startTime, 'day')) {
	// 			return {
	// 				// disabledHours: () => (startTime.hour() > 0 ? getRange(0, startTime.hour() - 1) : []),
	// 				disabledMinutes: (hour: any) => (hour === startTime.hour() && startTime.minute() > 0 ? getRange(0, startTime.minute() - 1) : [])
	// 			};
	// 		}

	// 		if (d.isSame(endTime, 'day')) {
	// 			return {
	// 				// disabledHours: () => getRange(endTime.hour() + 1, 24),
	// 				disabledMinutes: (hour: any) => (hour === endTime.hour() ? getRange(endTime.minute() + 1, 60) : [])
	// 			};
	// 		}
	// 	},
	// 	[startTime, endTime]
	// );

	const handleSelectDuration = (duration: any, { newStartTime = startTime, newEndTime = endTime } = {}) => {
		let fromTime = '';
		let toTime = '';
		switch (duration) {
			case 'Today':
				if (allowSingleCustomDate) {
					fromTime = newStartTime?.format('YYYY-MM-DD');
					toTime = newEndTime?.format('YYYY-MM-DD');
				} else {
					fromTime = newStartTime?.format('YYYY-MM-DD HH:mm:ss');
					toTime = newEndTime?.format('YYYY-MM-DD HH:mm:ss');
				}
				break;
			case 'Last 30 Mins':
				// Get the current date and time
				let nowTime = new Date();

				// Calculate the time 30 minutes ago
				const thirtyMinutesAgo = new Date(nowTime.getTime() - 30 * 60000);

				// Format the current time (end time)
				toTime = nowTime.getFullYear() + '-' + 
					(nowTime.getMonth() + 1).toString().padStart(2, '0') + '-' + 
					nowTime.getDate().toString().padStart(2, '0') + ' ' + 
					nowTime.getHours().toString().padStart(2, '0') + ':' + 
					nowTime.getMinutes().toString().padStart(2, '0') + ':' + 
					nowTime.getSeconds().toString().padStart(2, '0');

				// Format the time 30 minutes ago (start time)
				fromTime = thirtyMinutesAgo.getFullYear() + '-' + 
					(thirtyMinutesAgo.getMonth() + 1).toString().padStart(2, '0') + '-' + 
					thirtyMinutesAgo.getDate().toString().padStart(2, '0') + ' ' + 
					thirtyMinutesAgo.getHours().toString().padStart(2, '0') + ':' + 
					thirtyMinutesAgo.getMinutes().toString().padStart(2, '0') + ':' + 
					thirtyMinutesAgo.getSeconds().toString().padStart(2, '0');

				break;
            case 'Last 1 Hour':
				// Get the current date and time
				let nowTimeOneHour = new Date();

				// Calculate the time 30 minutes ago
				const oneHourAgo = new Date(nowTimeOneHour.getTime() - 1 * 60 * 60000);

				// Format the current time (end time)
				toTime = nowTimeOneHour.getFullYear() + '-' + 
					(nowTimeOneHour.getMonth() + 1).toString().padStart(2, '0') + '-' + 
					nowTimeOneHour.getDate().toString().padStart(2, '0') + ' ' + 
					nowTimeOneHour.getHours().toString().padStart(2, '0') + ':' + 
					nowTimeOneHour.getMinutes().toString().padStart(2, '0') + ':' + 
					nowTimeOneHour.getSeconds().toString().padStart(2, '0');

				// Format the time 30 minutes ago (start time)
				fromTime = oneHourAgo.getFullYear() + '-' + 
					(oneHourAgo.getMonth() + 1).toString().padStart(2, '0') + '-' + 
					oneHourAgo.getDate().toString().padStart(2, '0') + ' ' + 
					oneHourAgo.getHours().toString().padStart(2, '0') + ':' + 
					oneHourAgo.getMinutes().toString().padStart(2, '0') + ':' + 
					oneHourAgo.getSeconds().toString().padStart(2, '0');

				break;
            case 'Last 4 Hours':
				// Get the current date and time
				let nowTimeFour = new Date();

				// Calculate the time 30 minutes ago
				const fourHourAgo = new Date(nowTimeFour.getTime() - 4 * 60 * 60000);

				// Format the current time (end time)
				toTime = nowTimeFour.getFullYear() + '-' + 
					(nowTimeFour.getMonth() + 1).toString().padStart(2, '0') + '-' + 
					nowTimeFour.getDate().toString().padStart(2, '0') + ' ' + 
					nowTimeFour.getHours().toString().padStart(2, '0') + ':' + 
					nowTimeFour.getMinutes().toString().padStart(2, '0') + ':' + 
					nowTimeFour.getSeconds().toString().padStart(2, '0');

				// Format the time 30 minutes ago (start time)
				fromTime = fourHourAgo.getFullYear() + '-' + 
					(fourHourAgo.getMonth() + 1).toString().padStart(2, '0') + '-' + 
					fourHourAgo.getDate().toString().padStart(2, '0') + ' ' + 
					fourHourAgo.getHours().toString().padStart(2, '0') + ':' + 
					fourHourAgo.getMinutes().toString().padStart(2, '0') + ':' + 
					fourHourAgo.getSeconds().toString().padStart(2, '0');

				break;
            case 'Last 8 Hours':
				// Get the current date and time
				let nowTimeEight = new Date();

				// Calculate the time 30 minutes ago
				const eightAgo = new Date(nowTimeEight.getTime() - 8 * 60 * 60000);

				// Format the current time (end time)
				toTime = nowTimeEight.getFullYear() + '-' + 
					(nowTimeEight.getMonth() + 1).toString().padStart(2, '0') + '-' + 
					nowTimeEight.getDate().toString().padStart(2, '0') + ' ' + 
					nowTimeEight.getHours().toString().padStart(2, '0') + ':' + 
					nowTimeEight.getMinutes().toString().padStart(2, '0') + ':' + 
					nowTimeEight.getSeconds().toString().padStart(2, '0');

				// Format the time 30 minutes ago (start time)
				fromTime = eightAgo.getFullYear() + '-' + 
					(eightAgo.getMonth() + 1).toString().padStart(2, '0') + '-' + 
					eightAgo.getDate().toString().padStart(2, '0') + ' ' + 
					eightAgo.getHours().toString().padStart(2, '0') + ':' + 
					eightAgo.getMinutes().toString().padStart(2, '0') + ':' + 
					eightAgo.getSeconds().toString().padStart(2, '0');

				break;
		}

		// console.log(fromTime, toTime)
		if (handleDuration && fromTime && toTime) handleDuration(duration, { fromTime, toTime });
		setAnchorEl(null);
	};
	const handleCustomMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setCustomAnchorEl(event.currentTarget);
	};
	const handleCustomMenuClose = (event: any) => {
		setCustomAnchorEl(null);
		if (!!event) {
			setAnchorEl(null);
		}
	};


	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		if (event.currentTarget) setAnchorEl(event.currentTarget);
	};

    const handleGraphMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (event.currentTarget) setGraphAnchorEl(event.currentTarget);
    }

    const handleGraphMenuClose = (event: any) => {
		setGraphAnchorEl(null);
		if (!!event) {
			setGraphAnchorEl(null);
		}
	};

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	};

	useEffect(() => {
		handleSelectDuration(duration);
		// setStartTime(dayjs('00:00:00', 'HH:mm:ss').tz(localStorage.getItem('userTZ')||''));
		setEndTime(dayjs().tz(localStorage.getItem('userTZ') || ''));
	}, []);

	return (
		<>
			<Grid container sx={{ padding: '1rem' }}>
				<Grid item xs={12} container direction={'row'}>
					<Grid item>
						<Typography variant="h6">{title}</Typography>
					</Grid>
					<Grid direction={'row'} item xs container alignItems={'center'} justifyContent="flex-end" gap={2}>
						{handleDuration && (
							<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
								<div>
									<div onClick={handleMenuOpen}>
										<Typography
											variant="caption"
											sx={{
												fontWeight: 400,
												fontSize: '12px',
												textAlign: 'start',
												color: '#536580',
												cursor: 'pointer',
												display: 'inline-block'
											}}
										>
											<Fragment>{duration}</Fragment>
										</Typography>
										<KeyboardArrowDownOutlinedIcon style={{ color: '#536580', fontSize: '10px' }} />
									</div>
									<Menu
										sx={{ width: '50px' }}
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'right'
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right'
										}}
										open={open}
										onClose={() => {
											handleDuration();
											setAnchorEl(null);
										}}
										PaperProps={{ className: classes.dropdownMenu }}
									>
										{!allowSingleCustomDate && (<>
											{enableLastThirtyMinutesDuration && (
												<MenuItem className={classes.dropdownMenuItem} onClick={() => handleSelectDuration('Last 30 Mins')}>
													Last 30 Min
												</MenuItem>
											)}
                                            {enableLastOneHourDuration && (
												<MenuItem className={classes.dropdownMenuItem} onClick={() => handleSelectDuration('Last 1 Hour')}>
													Last 1 Hour
												</MenuItem>
											)}
                                            {enableLastFourHourDuration && (
												<MenuItem className={classes.dropdownMenuItem} onClick={() => handleSelectDuration('Last 4 Hours')}>
													Last 4 Hours
												</MenuItem>
											)}
                                            {enableLastEightHourDuration && (
												<MenuItem className={classes.dropdownMenuItem} onClick={() => handleSelectDuration('Last 8 Hours')}>
													Last 8 Hours
												</MenuItem>
											)}
											{!enableTodayDuration && (
												<div>
													<MenuItem className={classes.dropdownMenuItem} onClick={handleCustomMenuClick}>
														Custom
													</MenuItem>
													<Menu
														id="demo-positioned-menu"
														aria-labelledby="demo-positioned-button"
														anchorEl={customAnchorEl}
														open={customOpen}
														onClose={handleCustomMenuClose}
														anchorOrigin={{
															vertical: 'top',
															horizontal: 'left'
														}}
														transformOrigin={{
															vertical: 'top',
															horizontal: 'left'
														}}
														sx={{ width: '376px', padding: '0px' }}
													>
                                                        <Box className={classes.timePickerMenu}>
                                                            <Grid item display={'flex'} className={classes.alignAccestime}>
                                                                <AccessTime style={{ color: '#3D8BF8' }} />
                                                                <Typography className={classes.todayTypography}> Today</Typography>
                                                            </Grid>
                                                            <Grid container flexDirection="row" justifyContent="center" alignContent="center">
                                                                <Grid item xs={4}>
                                                                    <TimePicker value={startTime} onChange={(e) => onStartTimeChange(e, endTime)} defaultValue={startTime} minuteStep={5} secondStep={5} />
                                                                </Grid>
                                                                <Grid item xs={2} className={classes.arrowIconContainer}>
                                                                    <img src={`${process.env.PUBLIC_URL}/icons/time-side-arrow.svg`} alt="arrow" />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <TimePicker value={endTime} onChange={(e) => onEndTimeChange(e,startTime)} defaultValue={endTime} minuteStep={5} secondStep={5} />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
													</Menu>
												</div>
											)}
										</>)}
									</Menu>
								</div>
							</Grid>
						)}

                        {enableWidgetIcon && (
                            <div>
                                <div onClick={handleGraphMenuOpen}>
									{graphViewTab === 'line' ?
									<><img style={{marginTop: '8px'}} src={`${process.env.PUBLIC_URL}/icons/lineBar.svg`} alt='x'></img></>
									:
									<><img style={{marginTop: '8px'}} src={`${process.env.PUBLIC_URL}/icons/bar.svg`} alt='x'></img></>
									}
                                    <KeyboardArrowDownOutlinedIcon style={{ color: '#536580', fontSize: '10px' }} />
                                </div>
                                <Menu
                                    anchorEl={graphanchorEl}
                                    open={openGraph}
                                    onClose={handleGraphMenuClose}
                                    id="download-appbar"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    className="custom-dropdown"
                                >
                                    <MenuItem className="widget-download-item" onClick={(e) => {handleGraphViewTab(e, 'line') ; handleGraphMenuClose(e)}} >
										<img src={`${process.env.PUBLIC_URL}/icons/lineBar.svg`} alt='x'></img>
                                    </MenuItem>
                                    <MenuItem className="widget-download-item" onClick={(e) =>{ handleGraphViewTab(e, 'bar') ; handleGraphMenuClose(e)}} >
									<img src={`${process.env.PUBLIC_URL}/icons/bar.svg`} alt='x'></img>
                                    </MenuItem>
                                </Menu>
                            </div>
                        )}
					</Grid>
				</Grid>
				<NewSelectPopover
					isOpen={isColumnsPopoverOpen}
					elementsArray={additionalColumns || []}
					anchorRef={columnsEleRefPoint}
					handlePopoverClose={handleOnColumnsPopoverClose}
					selectedElements={selectedColumns}
					handleOnSelectionChange={handleOnSelectColumnChange}
					addMetricsLabel
				/>
			</Grid>
			<Snackbar open={openSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleClose}>
				<Alert
					iconMapping={{
						error: <DoDisturbOnIcon style={{ color: 'red' }} />
					}}
					onClose={handleClose}
					severity="error"
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
};

export default WidgetPageHeadCustom;
