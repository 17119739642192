import React, { useState, useMemo, useRef } from "react";
import CustomSelect from "./CustomSelect";
import { Typography, Button, Menu, IconButton } from '@mui/material';
import { deselectAllRows, downloadSelectedData } from "../../../components/admin/common/OZDataGrid/GridUtil";
import { showErrorNotification } from "../../../components/admin/common/NotiService";
import AddAgentsToGroup from "./AddAgentsToGroup";
import { getAgentbyId } from "../../../services/api-service";

export const BulkActionComponent = (bulkOptionProps) => {

    const [agentsToGroupMenuAnchorE1, setAgentsToGroupMenuAnchorE1] = useState(false);
    const anchorRef = useRef();
    const [openAddtoGroup, setOpenAddtoGroup] = useState(false)

    const ClickToOpenMenu = () => {
        setTimeout(() => setAgentsToGroupMenuAnchorE1(anchorRef?.current), 1);
        setOpenAddtoGroup(true)
    };
    const handleClose = () => {
        setAgentsToGroupMenuAnchorE1(null);
        setOpenAddtoGroup(false)
    };

    const basicTextStyle = useMemo(
        () => ({
            fontSize: "14px",
            fontWeight: "400",
            color: "#212121",
        }),
        []
    );

    function updateAgentStatus(userEnteredValues) {
        let cloneOfSelectedAgentData = structuredClone(bulkOptionProps.list)
        let dontClose = false
        cloneOfSelectedAgentData.length > 0 && (
            cloneOfSelectedAgentData.map((agentData, index) => {
                getAgentbyId(agentData.id).then(resp => {
                    let agentDataById = structuredClone(resp?.Data[0])
                    if (userEnteredValues === "Locked") {
                        agentDataById.locked = true
                    }
                    else if (userEnteredValues === "Active") {
                        agentDataById.active = true
                    }
                    else {
                        agentDataById.active = false
                    }
                    bulkOptionProps.updateFuntion(agentDataById, dontClose, index, cloneOfSelectedAgentData.length, deselectAllRows)
                }).catch((e) => { showErrorNotification(`Agent ID: ${agentData.agentId} data is having trouble getting value from api`) });
                return null;
            }))
    }

    function downloadAgentData(formValue) {
        let fileType = formValue.toLowerCase()
        downloadSelectedData(fileType, "AgentGroupList")
        deselectAllRows()
    }

    return (
        <div>
            {bulkOptionProps?.list?.length > 0 && (
                <div className="oz-agent-bulk-actions-container">
                    <Typography sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: '#000000'
                    }}>{bulkOptionProps?.list?.length} Users Selected</Typography>
                    <CustomSelect
                        name="status"
                        placeholder='Status'
                        options={['Active', 'Locked', 'Deactivate']}
                        list={bulkOptionProps.list}
                        onChange={(value) => { updateAgentStatus(value) }}
                    />
                    <CustomSelect
                        name="export"
                        placeholder='Export'
                        options={['CSV', 'Excel', 'XML', 'PDF']}
                        onChange={(value) => { downloadAgentData(value) }}
                    />
                    <div className="oz-agent-bulk-actions-add-to-group-container">
                        <Button ref={anchorRef} onClick={ClickToOpenMenu}
                            variant="text"
                            sx={{
                                ...basicTextStyle, paddingLeft: '5px', color: '#3D8BF8'
                            }}
                        >
                            <img className="oz-agent-bulk-actions-add-to-group-image" onClick={null}
                                src={`${process.env.PUBLIC_URL}/icons/anotherlevelblue.svg`}
                                alt="anotherbutton"
                            ></img>
                            Add To Group</Button>
                        <Button ref={anchorRef} onClick={() => { deselectAllRows() }}
                            sx={{
                                ...basicTextStyle, paddingLeft: '5px', cursor: "pointer",
                                color: '#536580'
                            }}
                        > Clear All</Button>
                    </div>
                    <div >
                        <Menu
                            anchorEl={agentsToGroupMenuAnchorE1} open={Boolean(agentsToGroupMenuAnchorE1)} onClose={handleClose}
                            sx={{
                                "& .MuiMenu-paper": {
                                    backgroundColor: "white",
                                    width: "458px",
                                    height: "409px",
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.06)",
                                    border: "0.0625rem solid #E6E5E6",
                                    borderRadius: "12px",
                                    position: "relative",
                                    marginTop: '-27px'
                                },
                            }}
                        >
                            <div className="oz-agent-bulk-actions-add-to-group-inner">
                                <div className="oz-agent-bulk-actions-add-to-group-inner-icon-button">
                                    <Typography flexGrow={1}> Add {bulkOptionProps?.list?.length} Agents to group
                                    </Typography>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => { handleClose() }}
                                        sx={{
                                            color: '#99A0A8',
                                            alignSelf: "center"
                                        }}
                                    >
                                        <img src={`${process.env.PUBLIC_URL}/icons/close.svg`} alt='x' ></img>
                                    </IconButton>
                                </div>
                                {openAddtoGroup && <AddAgentsToGroup initialData={bulkOptionProps.initialData} autoCompleteList={bulkOptionProps.autoCompleteList} list={bulkOptionProps.list} handleClose={handleClose} />}
                            </div>
                        </Menu>
                    </div>
                </div >
            )
            }
        </div >
    )
}
