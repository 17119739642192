import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Badge, Box, Card, Chip, Grid, Menu, MenuItem, Stack, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { getSkillSummary } from '../../../services/api-service';
import AlertIcon from '../../../static/icons/Alert.svg';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import BreakDownDrawer from '../../BreakdownDrawer/BreakdownDrawer';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import Switch from '../../Switch/Switch';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import WidgetGraph from '../../Widget-Graph/Widget-Graph';

const useStyles: any = makeStyles((theme: Theme) => ({
	boundButton: {
		padding: '5px !important'
	}
}));

let interval: any;

function CSAT() {
	const { useState, useRef } = React;
	const downloadContent = useRef<null | HTMLElement>(null);
	const chartElement: any = useRef(null);
	const donutChartElement: any = useRef(null);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [csAtFilter, setCsAtFilter] = useState<any>([]);
	const [filterType, setFilterType] = useState<any>('1');
	const [duration, setDuration] = useState<string>('Today');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [bound, setBound] = useState<string | null>('CONTACT_CENTER');
	const [isBreakdownDrawerOpen, setIsBreakdownDrawerOpen] = useState<boolean | undefined>(false);
	const [hideAht, setHideAht] = useState(false);
	const classes = useStyles();

	const handleBound = (event: React.MouseEvent<HTMLElement>, value: string | null) => {
		setBound(value);
	};
	const clearFilter = () => {
		setCsAtFilter([]);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = csAtFilter.filter((item: any) => item.id !== id);
		setCsAtFilter(newFilter);
	};

	const options = {
		chart: {
			zoomType: 'xy'
		},
		credits: { enabled: false },
		title: '',
		xAxis: [
			{
				title: {
					text: 'Time ➡'
				},
				plotLines: [
					{
						label: 'Target: 88%',
						color: 'red', // Color value
						dashStyle: 'dot', // Style of the plot line. Default to solid
						value: 14, // Value of where the line will appear
						width: 2 // Width of the line
					}
				],
				categories: ['06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				crosshair: true
			}
		],
		yAxis: [
			{
				// Primary yAxis
				labels: {
					style: {
						color: '#99A0A8'
					}
				},
				title: {
					text: 'CSAT %',
					style: {
						color: '#99A0A8'
					}
				}
			},
			{
				// Secondary yAxis
				title: {
					text: 'Avg AHT',
					style: {
						color: '#99A0A8'
					}
				},
				labels: {
					style: {
						color: '#99A0A8'
					}
				},
				plotLines: [
					{
						label: 'Target: 88%',
						color: '#008DFF', // Color value
						dashStyle: 'dot', // Style of the plot line. Default to solid
						value: 50, // Value of where the line will appear
						width: 2 // Width of the line
					}
				],
				opposite: true
			}
		],
		tooltip: {
			shared: true
		},
		legend: false,
		series: [
			{
				name: 'AHT',
				type: 'column',
				yAxis: 1,
				color: '#E3ECFD',
				data: [27.6, 28.8, 21.7, 34.1, 29.0, 28.4, 45.6, 51.7, 39.0, 60.0, 28.6, 32.1, 60.0, 28.6, 32.1, 0, 0],
				visible: !hideAht,
				pointWidth: 10
			},
			{
				name: 'CSAT %',
				color: '#3296FB',
				data: [
					-13.6,
					-14.9,
					{
						y: -5.8,

						marker: {
							symbol: `url(${AlertIcon})`
						}
					},
					-0.7,
					-13.6,
					-0.7,
					14.5,
					10.8,
					5.8,
					-0.7,
					-11.0,
					-16.4,
					-13.6,
					-14.9,
					-5.8,
					0,
					0
				],
				point: {
					events: {
						click: function ({ point }: any) {
							toggleBreakdownDrawer(true);
						}
					}
				}
			}
		]
	};

	const donutChartOptions = {
		credits: { enabled: false },
		chart: {
			type: 'pie'
		},
		title: {
			text: 'CSAT score'
		},
		plotOptions: {
			pie: {
				shadow: false,
				center: ['50%', '50%'],
				dataLabels: {
					enabled: false
				},
				showInLegend: true
			}
		},
		tooltip: {
			valueSuffix: '%'
		},
		series: [
			{
				data: [
					{
						name: 'Very unsatisfied',
						y: 74.77,
						color: '#CF0B7E'
					},
					{
						name: 'Unsatisfied',
						y: 12.82,
						color: '#E57DBA'
					},
					{
						name: 'Neutral',
						y: 4.63,
						color: '#D6C72E'
					},
					{
						name: 'Satisfied',
						y: 2.44,
						color: '#41D8CD'
					},
					{
						name: 'Very Satisfied',
						y: 2.02,
						color: '#02B2A3'
					}
				],
				size: '80%',
				innerSize: '55%',
				id: 'versions'
			}
		],
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 400
					},
					chartOptions: {
						series: [
							{},
							{
								id: 'versions',
								dataLabels: {
									enabled: false
								}
							}
						]
					}
				}
			]
		}
	};

	const toggleBreakdownDrawer = (isOpen: boolean, data: any = {}) => {
		setIsBreakdownDrawerOpen(isOpen);
	};

	const filterItems = csAtFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const retrieveTableData = async (filters = '0') => {
		const rows: any[] = await getSkillSummary({ filters, type: filterType });

		for (const row of rows) {
			const skills = row.SkillName?.split(',') || [];
			const tempSkills = [...skills];
			if (skills.length > 1) {
				row.SkillName = {
					value: skills.slice(0, 1).join(','),
					badge: `+${skills.length - 1}`,
					extraValues: tempSkills.splice(1, skills.length - 1)
				};
				row.Icons = { value: 'error_icon' };
			} else {
				row.SkillName = skills.join(',');
				row.Icons = { value: 'success_icon' };
			}
		}

		const columns = [
			{ name: 'Agent', key: 'Agent' },
			{ name: '', key: 'Icons' },
			{ name: 'Skills', key: 'SkillName' },
			{ name: 'CSAT', key: 'CSAT' },
			{ name: 'ASA', key: 'AverageSpeedToAnswer' },
			{ name: 'AHT', key: 'AverageHandlingTime' },
			{ name: 'Avg. handling time', key: 'AverageHandlingTime' },
			{ name: 'Interactions', key: 'Interactions' },
			{ name: 'Responses', key: 'Responses' }
		];
		setRowData(rows);
		setColumnData(columns);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleDownload = (type: string) => {};

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		const filterIds = csAtFilter.length ? csAtFilter.map((item: any) => item.id).join(',') : '0';
		retrieveTableData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		interval = setInterval(() => retrieveTableData(filterIds), WIDGET_REFRESH_INTERVAL.CSAT);
	}, [csAtFilter]);

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType }: any) => {
		setCsAtFilter(selectedFilters);
		setFilterType(newFilterType);
		toggleDrawer(false);
	};
	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		if (event.currentTarget) setAnchorEl(event.currentTarget);
	};
	const open = Boolean(anchorEl);

	const handleDuration = (type: string = '') => {
		if (type) {
			setDuration(type);
		}
		setAnchorEl(null);
	};

	const toggleAht = () => {
		setHideAht((prev) => !prev);
	};

	return (
		<>
			<Card className="customCard">
				<div className="card-header">
					<div className="card-title">
						CSAT Stats
						<span className="card-title-badge">Live</span>
					</div>
					<div className="card-actions">
						<div>
							<div onClick={handleMenuOpen}>
								{duration}
								<KeyboardArrowDownOutlinedIcon style={{ color: '#536580', fontSize: '10px' }} />
							</div>
							<Menu
								id="download-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right'
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
								open={open}
								onClose={() => handleDuration()}
								className="custom-dropdown"
							>
								<MenuItem className="download-item" onClick={() => handleDuration('Today')}>
									Today
								</MenuItem>
								<MenuItem className="download-item" onClick={() => handleDuration('Yesterday')}>
									Yesterday
								</MenuItem>
								<MenuItem className="download-item" onClick={() => handleDuration('Same day last week')}>
									Same day last week
								</MenuItem>
							</Menu>
						</div>
						<ToggleButtonGroup value={bound} color="primary" exclusive onChange={handleBound} aria-label="bound">
							<ToggleButton value="CONTACT_CENTER" className={classes.boundButton} aria-label="voice">
								<span>Contact Center</span>
							</ToggleButton>
							<ToggleButton value="AGENT" className={classes.boundButton} aria-label="outbound">
								<span>Agent</span>
							</ToggleButton>
						</ToggleButtonGroup>
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={'CSAT Stats'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={csAtFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
						<div>
							<WidgetGraph></WidgetGraph>
						</div>
					</div>
				</div>
				<div className="card-header">
					<Grid container direction="row" alignItems="center">
						<Grid item xs={0.5} className="legend-icon">
							<span className="legend-icon-hr"></span>
						</Grid>
						<Grid item xs={0.5}>
							<span>CSAT%</span>
						</Grid>
						<Grid item xs={0.6} className="legend-icon">
							<div className="legend-icon-dottedlines"></div>
						</Grid>
						<Grid item xs={0.6}>
							Target
						</Grid>
						<Grid item xs={0.3} className="legend-icon">
							<span className="legend-icon-rectangle"></span>
						</Grid>
						<Grid item xs>
							AHT
						</Grid>
						<Grid>
							<Stack direction="row" spacing={1} alignItems="center">
								<Typography fontWeight={700}>AHT</Typography>
								<Switch onClick={toggleAht} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
							</Stack>
						</Grid>
					</Grid>
				</div>
				<Box ref={downloadContent} sx={{ width: '100%', typography: 'body1' }}>
					{csAtFilter.length > 0 && (
						<Box className="customTabPanel">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className="filter-outer">
										<div className="filter-container">
											<Stack direction="row" spacing={1}>
												{filterItems}
											</Stack>
										</div>
										<div className="filter-remove" onClick={clearFilter}>
											Remove
										</div>
									</div>
								</Grid>
							</Grid>
						</Box>
					)}
					{bound === 'AGENT' ? (
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={4}>
								<HighchartsReact ref={donutChartElement} highcharts={Highcharts} options={donutChartOptions} />
							</Grid>
							<Grid item xs={8}>
								<OZTable columns={columnData} rows={rowData}></OZTable>
							</Grid>
						</Grid>
					) : (
						<HighchartsReact ref={chartElement} highcharts={Highcharts} options={options} />
					)}
				</Box>
			</Card>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={csAtFilter} />
			{isBreakdownDrawerOpen && <BreakDownDrawer isOpen={isBreakdownDrawerOpen} toggleDrawer={toggleBreakdownDrawer} widgetName="CSAT" />}
		</>
	);
}
export default CSAT;
