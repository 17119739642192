import { useEffect, useRef, } from "react";
import { Box, Stack, Typography } from "@mui/material";
import "./conversation.css";

const TextPreview = ({ message }) => {
    return <Typography sx={{ wordWrap: 'break-word', wordBreak: "break-all" }} fontSize={"12px"} color={"#212121"}>
        {message.msg}
    </Typography>
}

const Conversation = ({ messages }) => {

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView());
        return <div ref={elementRef} />;
    };

    const renderMessageItem = ({ type, index, msg }) => {
        let item = null;
        if (type === "text") {
            item = <TextPreview message={msg} index={index} />
        }
        return item;
    }

    const message = (props) => {
        var msg = props.msg;
        return (
            <Stack
                direction={'row'}
                key={props.index}
                className={"ozcw-widget-message-wrapper animate-fade-in"}
                justifyContent={'flex-start'}
            >
                <Stack
                    className="ozcw-message-main"
                    direction={"column"}
                    alignItems={'flex-start'}
                >
                    <div className="ozcw-message-main-item">
                        <Typography fontSize={"10px"} className="ozcw-message-text" flexGrow={1}>
                            To: <Typography component={"span"} fontSize={"10px"} color={"#008DFF"} fontWeight={600}>{msg.to.join(", ")}</Typography>
                        </Typography>
                        <Typography marginLeft={"4px"} fontSize={"10px"} color="#99A0A8" className="ozcw-message-text">
                            {msg.time}
                        </Typography>
                    </div>
                    <Box
                        component='div'
                        textAlign={'left'}
                        p={'10px 10px 10px 10px'}
                        borderRadius={'0.125rem 0.7rem 0.75rem 0.75rem'}
                        bgcolor={"#E6F4FF"}
                        color={"#212121"}
                    >
                        {renderMessageItem({ type: msg.type, index: props.index, msg: msg })}
                    </Box>
                </Stack>
            </Stack>
        )
    }

    return (
        <Box className="ozcw-widget-body" sx={{ backgroundColor: "#FFFFFF" }}>
            <Typography fontSize={"12px"} color={"#536580"} textAlign={"center"} fontWeight={500}>
                Today
            </Typography>
            {messages.length > 0 && messages.map(function (msg, i) { return message({ msg: msg, index: i }) })}
            <AlwaysScrollToBottom />
        </Box >
    )
}

export default Conversation;