
import { connect, getIn } from "formik";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, Typography } from '@mui/material';

const OzDatePicker = connect(({
    label, name, placeholder, formik, ...additionalProps
}) => {
    const values = getIn(formik.values, name);
    let error = getIn(formik.errors, name);
    error = error ? error === "Holiday Date is required" ? error : "Holiday Date is invalid" : null;
    console.log('error', error)
    const touch = getIn(formik.touched, name);
    const date = values || null;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography htmlFor={name} fontSize='12px' fontWeight={400} lineHeight='14px' marginBottom={'6px'} marginLeft='1px' color='#212121'>
                {label}
            </Typography>
            <DatePicker
                value={date}
                onChange={(newValue) => {
                    formik.setFieldValue(name, newValue)
                }}
                openTo="day"
                showToolbar={false}
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => <TextField
                    id={name}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                            backgroundColor: '#ffff',
                            fontSize: '14px',
                            height: '44px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #D6D6D7'
                        },
                        '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #D6D6D7'
                        }
                    }}
                    fullWidth
                    onBlur={formik.handleBlur}
                    {...params}
                    error={Boolean(touch) && Boolean(error)}
                    helperText={touch && error}
                />}
            />
        </LocalizationProvider>
    );
})

export default OzDatePicker