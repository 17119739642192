import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { Button, Typography, Menu, ListItemButton } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import { deletePauseReasonById, getPauseReason, getPauseReasonById, importPauseReasons } from "../../../services/api-service";
import "./pauseReason.css";
import PauseReasonsForm from "./pauseReasonsForm";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import { AdminPages, authPage, NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";

function PauseReasons() {
  const [openPauseReasonForm, setOpenPauseReasonForm] = useState({ isNew: false, show: false });
  const [pauseReasonTableData, setPauseReasonTableData] = useState([]);
  const [pauseReasonGridData, setPauseReasonGridData] = useState(null)
  const [pauseReasonOnclickRowData, setPauseReasonOnclickRowData] = useState(null)
  const [loading, setLoading] = useState(false);

  function EditAgentRowOnMouseEnter(props) {
    const [pauseReasonAnchorE1, setPauseReasonAnchorE1] = useState(false);
    const anchorRef = useRef();
    const [openPauseReasonDeleteDialog, setOpenPauseReasonDeleteDialog] = useState(false)
    const [openRenameDialog, setOpenRenameDialog] = useState(false)
    const [pauseReasonRowData, setPauseReasonRowData] = useState({})

    const onClickMoreVertIcon = () => {
      setTimeout(() => setPauseReasonAnchorE1(anchorRef?.current), 1);
    };

    const closePauseReasonMorevertMenu = () => {
      setPauseReasonAnchorE1(false);
    };

    function onClickDelete() {
      setPauseReasonAnchorE1(false);
      setOpenPauseReasonDeleteDialog(true)
    }

    const styleListItemButton = useMemo(
      () => ({
        "&.MuiButtonBase-root": {
          color: '#212121',
          fontFamily: "Roboto",
          fontSize: "0.75rem",
          fontWeight: 400,
          height: '18px',
          lineHeight: "14px",
        },
        '&:hover': {
          borderRadius: "4px",
        },
      }),
      []

    );

    async function deletePauseReasonsById() {
      try {
        deletePauseReasonById(props.data.id)
          .then((resp) => {
            if (resp.Status === "Success") {
              getPauseReasons();
              showSuccessNotification(resp.Message)
            } else {
              showErrorNotification(resp.Message);
            }
          })
          .catch((e) => {
            showErrorNotification("Error in Deleting Pause Reason");
          });
      } catch (e) {
        showErrorNotification("Error in Deleting Pause Reason");
      }
      setOpenPauseReasonDeleteDialog(false)
    }

    function onClickRename() {
      let singlePauseReasonRowData = {}
      try {
        getPauseReasonById(props.data.id)
          .then((resp) => {
            singlePauseReasonRowData = resp.Data[0]
            singlePauseReasonRowData.pauseTime = props.data.pauseTime
            setPauseReasonRowData(singlePauseReasonRowData);
          })
          .catch((e) => {
            setPauseReasonRowData({});
          });
      } catch (e) {
        showErrorNotification("Error in pause reason list ");
      }
      setPauseReasonAnchorE1(false);
      setOpenRenameDialog(true)
    }

    return (
      <div className="oz-pause-reason-morevert-column-cell">
        <Typography sx={{ fontSize: "0.75rem", fontWeight: 400, color: "#000000" }}>{props.value}</Typography>
        {/* {authPage(AdminPages.PAUSE_REASONS_EDIT) && <img className="delMoreIcon"
          onClick={onClickMoreVertIcon}
          src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
        >
        </img>} */}
        {(newAuthPages(NewAdminPages.PAUSE_REASONS) || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Full') || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Edit')) &&
          <img className="delMoreIcon"
            onClick={onClickMoreVertIcon}
            src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
          >
          </img>}
        <div ref={anchorRef}>
          <Menu anchorEl={pauseReasonAnchorE1} open={Boolean(pauseReasonAnchorE1)} onClose={closePauseReasonMorevertMenu} sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#FFFFFF",
              width: "133px",
              height: "62px",
            },
            "& .MuiMenu-paper": {
              backgroundColor: "#FFFFFF",
              boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
              border: "0.0625rem solid #E6E5E6",
              borderRadius: "12px",
              marginLeft: "-1.0625rem",
              marginTop: "1.2rem",
            },
          }}>
            <ListItemButton
              sx={styleListItemButton}
              onClick={() => { onClickRename() }}>
              Rename
            </ListItemButton>
            <ListItemButton
              onClick={onClickDelete}
              disabled={!(newAuthPages(NewAdminPages.PAUSE_REASONS) || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Full'))}
              sx={{ ...styleListItemButton, marginTop: '7px' }}>
              Delete
            </ListItemButton>
          </Menu>
          {openPauseReasonDeleteDialog && <DeleteDialog
            open={openPauseReasonDeleteDialog}
            onClose={() => { setOpenPauseReasonDeleteDialog(false) }}
            title="Delete Pause Reason"
            content={`This will delete Pause Reason: ${props.data.reason} permanently. You cannot undo this action.`}
            deleteFunction={deletePauseReasonsById} />}
        </div>
        {openRenameDialog && <PauseReasonsForm updateReason={getPauseReasons} onClose={() => { setOpenRenameDialog(false) }} data={pauseReasonRowData} editAccess={(newAuthPages(NewAdminPages.PAUSE_REASONS) || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Full') || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Edit'))} />}
      </div>
    );
  };

  async function getPauseReasons() {
    setLoading(true);
    try {
      getPauseReason()
        .then((resp) => {
          setPauseReasonTableData(resp.Data);
          setLoading(false);
        })
        .catch((e) => {
          setPauseReasonTableData({});
          setLoading(false);
        });
    } catch (e) {
      console.log("Error in Pause Reason list ");
      setLoading(false);
    }
  }

  useEffect(() => {
    getPauseReasons();
  }, []);

  const columnDefs = [
    {
      field: "reason",
      headerName: "Reasons",
      maxWidth: '460',
    },
    {
      field: "pauseTime",
      headerName: "Pause Time",
      cellRenderer: EditAgentRowOnMouseEnter,
    },
  ];

  let buttonStyle = useMemo(
    () => ({
      width: "9.3125rem",
      height: "1.875rem",
      backgroundColor: "#3D8BF8",
      borderRadius: "0.5rem",
      fontSize: "0.875rem",
      fontWeight: 400,
    }),
    []
  );

  const showPauseReasonForm = () => {
    setOpenPauseReasonForm({ isNew: true, show: true });
  };

  const closePauseReasonForm = () => {
    setOpenPauseReasonForm(false);
    setOpenPauseReasonForm({ isNew: false, show: false });
  };

  function onClickSampleDownload() {
    const link = document.createElement("a");
    link.download = `pause_reason_upload_sample.xls`;
    link.href = `${process.env.PUBLIC_URL}/sample_files/pause_reason_upload_sample.xls`;
    link.click();
  };

  function onCLickRow(rowItem) {
    setOpenPauseReasonForm({ isNew: false, show: true });
    setPauseReasonOnclickRowData({});
    let singlePauseReasonRowData = {}
    try {
      getPauseReasonById(rowItem.data.id)
        .then((resp) => {
          singlePauseReasonRowData = resp.Data[0]
          singlePauseReasonRowData.pauseTime = rowItem.data.pauseTime
          setPauseReasonOnclickRowData(singlePauseReasonRowData);
        })
        .catch((e) => {
          setPauseReasonOnclickRowData({});
        });
    } catch (e) {
      showErrorNotification("Error in pause reason list ");
    }
  }

  return (
    <div className="ozAdminPauseReasonContainer oz-pause-reason-main-container" >
      <Typography
        sx={{ fontSize: "0.75rem", lineHeight: "0.875rem", marginBottom: "0.625rem", color: "#212121", }} alignSelf="center">
        Configurations
      </Typography>

      <div className="oz-pause-reason-inner-container">
        <Typography
          sx={{ fontStyle: "normal", fontWeight: 600, fontSize: "1.5rem", lineHeight: "1.75rem" }} flexGrow={1}>
          Pause Reasons
        </Typography>
        {/* {authPage(AdminPages.PAUSE_REASONS_EDIT) && <Button
          sx={{ ...buttonStyle }}
          variant="contained"
          startIcon={<AddBoxOutlinedIcon />}
          onClick={showPauseReasonForm}>
          New Reason
        </Button>} */}
        {(newAuthPages(NewAdminPages.PAUSE_REASONS) || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Full')) && <Button
          sx={{ ...buttonStyle }}
          variant="contained"
          startIcon={<AddBoxOutlinedIcon />}
          onClick={showPauseReasonForm}>
          New Reason
        </Button>}
      </div>

      {openPauseReasonForm.show && openPauseReasonForm.isNew && (
        <PauseReasonsForm
          updateReason={getPauseReasons}
          onClose={closePauseReasonForm}
        />
      )}
      {openPauseReasonForm.show && !openPauseReasonForm.isNew && (
        <PauseReasonsForm updateReason={getPauseReasons} onClose={closePauseReasonForm} data={pauseReasonOnclickRowData} openDialog={true} editAccess={(newAuthPages(NewAdminPages.PAUSE_REASONS) || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Full') || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Edit'))} />
      )}
      {/* <OZGridToolbar showImport={authPage(AdminPages.PAUSE_REASONS_EDIT)} importProps={{
        label: "Bulk Import Reasons", importFunction: importPauseReasons, fileName: "PauseReason.xls", onClickSampleDownload: onClickSampleDownload
      }} refreshGrid={getPauseReasons} title='List of Pause reasons' searchPlaceholder='Reason' rowCount={pauseReasonTableData.length} dataGridRef={pauseReasonGridData} fileName='reasons' /> */}
      <OZGridToolbar showImport={(newAuthPages(NewAdminPages.PAUSE_REASONS) || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Full') || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'data'))} importProps={{
        label: "Bulk Import Reasons", importFunction: importPauseReasons, fileName: "PauseReason.xls", onClickSampleDownload: onClickSampleDownload
      }} refreshGrid={getPauseReasons} title='List of Pause reasons' searchPlaceholder='Reason' rowCount={pauseReasonTableData.length} dataGridRef={pauseReasonGridData} fileName='reasons' />
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        (<>
          <OZDataGrid
            height={"26.3rem"}
            columns={columnDefs}
            data={pauseReasonTableData}
            setGridRef={setPauseReasonGridData}
            rowClickCallback={onCLickRow}
          />
        </>
        )
      }
    </div>
  );
}

export default PauseReasons;
