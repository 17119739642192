import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { top5Skills } from '../../services/api-service';
import { useState, useEffect } from 'react';
import '../WallboardSkillTable/WallboardSkillTable.css';
import { WIDGET_REFRESH_INTERVAL } from '../../utils/constants';
import { secondsToTime } from '../../utils/util';

export default function WallboardSkillTable(props: any) {
	const { toggleClass } = props;
	const [top5SkillsList, setTop5SkillsList] = useState<any>([]);

	const getTop5SkillsApi = async () => {
		const top5SkillsData: any = await top5Skills();
		setTop5SkillsList(top5SkillsData);
	};

	useEffect(() => {
		getTop5SkillsApi();
		const interval: any = setInterval(() => {
			getTop5SkillsApi();
		}, WIDGET_REFRESH_INTERVAL.WALLBOARD);
		return () => clearInterval(interval);
	}, []);
	return (
		<TableContainer>
			<Table aria-label="simple table" className={`wallboard-table ${toggleClass.tableHeaderBg}`}>
				<TableHead className="wallboard-table-head">
					<TableRow className="wallboard-table-head-row">
						<TableCell className={toggleClass.tableHeadColor}>Skill</TableCell>
						<TableCell align="center" className={toggleClass.tableHeadColor}>
							Queue
						</TableCell>
						<TableCell align="center" className={toggleClass.tableHeadColor}>
							Longest wait time
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{top5SkillsList?.map((row: any) => (
						<TableRow key={row.Skill} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" className={toggleClass.rowCellColor}>
								{row?.Skill || '-'}
							</TableCell>
							<TableCell align="center" className={toggleClass.rowCellColor}>
								{row?.Queue || '-'}
							</TableCell>
							<TableCell align="center" className={toggleClass.rowCellColor}>
								{secondsToTime(row.LongestWaitTime) || '-'}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
