import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Badge, Box, Card, Chip, Grid, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { getHourlyDigitalCallTrends } from '../../../services/api-service';
import { getLastFetchedDetail } from '../../../services/application-service';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
// import { ReactComponent as RefreshOutlinedIcon } from '../../../static/icons/refresh.svg';
import { filterTypes, WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import '../HourlyDigitalSummary/HourlyDigitalSummary.css';
import CircularProgressWithLabel from './LinearProgress';
import Menu from '@mui/material/Menu';

// Highcharts.seriesTypes.area.prototype.drawLegendSymbol =
//      Highcharts.seriesTypes.line.prototype.drawLegendSymbol;
const useStyles: any = makeStyles((theme: Theme) => ({
	boundButton: {
		padding: '5px !important',
		minWidth: 50
	},
	filterItem: {
		fontSize: 12,
		height: 36,
		borderRadius: 20,
		background: 'rgba(0, 142, 255, 0.1)',
		border: '1px solid #008eff',
		padding: `0 ${theme.spacing(2)}`
	},
	itemIndicator: {
		width: 5,
		height: 5,
		borderRadius: 1,
		display: 'inline-block',
		position: 'relative',
		top: '-2px',
		marginRight: 4
	},
	filterItemText: {
		margin: 0,
		color: '#536580'
	},
	filterItemSubText: {
		color: '#99A0A8'
	},
	popoverContent: {
		minWidth: 200,
		fontSize: '14px',
		lineHeight: 2,
	}
}));
let interval: any;

const defaultFilters = [
	{ id: 1, label: 'General', color: '#EE4060' },
	{ id: 2, label: 'Canada Sales', color: '#EEBE40' },
	{ id: 3, label: 'Support', color: '#B0CFA5' },
	{ id: 4, label: 'English Sales', color: '#53B9ED' },
	{ id: 5, label: 'Premium Plus', color: '#B2A1F9' }
];

function HourlyDigitalSummary() {
	const classes = useStyles();
	const { useState, useRef } = React;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [bound, setBound] = useState<string>('all');
	const [graphData, setGraphData] = useState<any>([]);
	const [categories, setCategories] = useState<any>([]);
	const [hourlyDigitalSummaryFilter, setHourlyDigitalSummaryFilter] = useState<any>([]);
	const [filterType, setFilterType] = useState<any>('1');
	const downloadContent = useRef<null | any>(null);
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [lastFetchedTime, setLastFetchedTime] = useState<any>('00:00');
	const [isPopoverOpenOnClickBar, setIsPopoverOpenOnClickBar] = useState<boolean>(false);
	const [selectedPoint, setSelectedPoint] = useState<any>(null);
	const [graphRefPoint, setGraphRefPoint] = useState<any>(null);
	const [dataInGraph, setDataInGraph] = useState<any>([]);
	// const dummyData = 
	//     [
	//         {
	//             "CallHours": "11",
	//             "SNO": 1,
	//             "CampaignId": "84006",
	//             "AbandonedConversation": "5",
	//             "TotalConversation": "35",
	//             "DigitalType": "All",
	//             "CallHourTs": "2023-05-17 11:00:00",
	//             "ConnectedConversation": "15",
	//             "CampaignName": "Chat_FormTesting"
	//         },
	//         {
	//             "CallHours": "13",
	//             "SNO": 2,
	//             "CampaignId": "84006",
	//             "AbandonedConversation": "2",
	//             "TotalConversation": "52",
	//             "DigitalType": "All",
	//             "CallHourTs": "2023-05-17 13:00:00",
	//             "ConnectedConversation": "5",
	//             "CampaignName": "Chat_FormTesting"
	//         },
	//         {
	//             "CallHours": "13",
	//             "SNO": 3,
	//             "CampaignId": "84006",
	//             "AbandonedConversation": "2",
	//             "TotalConversation": "42",
	//             "DigitalType": "All",
	//             "CallHourTs": "2023-05-17 13:00:00",
	//             "ConnectedConversation": "5",
	//             "CampaignName": "Chat_FormTesting"
	//         },
	//         {
	//             "CallHours": "6",
	//             "SNO": 4,
	//             "CampaignId": "84418",
	//             "AbandonedConversation": "1",
	//             "TotalConversation": "61",
	//             "DigitalType": "All",
	//             "CallHourTs": "2023-05-17 06:00:00",
	//             "ConnectedConversation": "5",
	//             "CampaignName": "Rajesh_Ganji_Chat"
	//         },
	//         {
	//             "CallHours": "11",
	//             "SNO": 5,
	//             "CampaignId": "0",
	//             "AbandonedConversation": "5",
	//             "TotalConversation": "55",
	//             "DigitalType": "All",
	//             "CallHourTs": "2023-05-17 11:00:00",
	//             "ConnectedConversation": "10",
	//             "CampaignName": "0"
	//         },
	//         {
	//             "CallHours": "13",
	//             "SNO": 6,
	//             "CampaignId": "0",
	//             "AbandonedConversation": "2",
	//             "TotalConversation": "72",
	//             "DigitalType": "All",
	//             "CallHourTs": "2023-05-17 13:00:00",
	//             "ConnectedConversation": "5",
	//             "CampaignName": "0"
	//         },
	//         {
	//             "CallHours": "15",
	//             "SNO": 7,
	//             "CampaignId": "0",
	//             "AbandonedConversation": "2",
	//             "TotalConversation": "32",
	//             "DigitalType": "All",
	//             "CallHourTs": "2023-05-17 15:00:00",
	//             "ConnectedConversation": "5",
	//             "CampaignName": "0"
	//         },
	//         {
	//             "CallHours": "6",
	//             "SNO": 8,
	//             "CampaignId": "0",
	//             "AbandonedConversation": "1",
	//             "TotalConversation": "41",
	//             "DigitalType": "All",
	//             "CallHourTs": "2023-05-17 06:00:00",
	//             "ConnectedConversation": "5",
	//             "CampaignName": "0"
	//         },
	//     ]

	const chartElement: any = useRef(null);

	const clearFilter = () => {
		setHourlyDigitalSummaryFilter([]);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = hourlyDigitalSummaryFilter.filter((item: any) => item.id !== id);
		setHourlyDigitalSummaryFilter(newFilter);
	};

	const filterItems = hourlyDigitalSummaryFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));
	const formatGraphData = (data: any[]) => {
		const sortedData = [...data];
		sortedData.sort((a, b) => (+a.CallHours > +b.CallHours ? 1 : +b.CallHours > +a.CallHours ? -1 : 0));
		const graphData = [
			{
				name: 'Total Conversation',
				color: '#E3F0FE',
				data: sortedData.map((item) => +item.TotalConversation),
				campaignName: sortedData.map((item) => +item.CampaignName),
				// pointPlacement: 0.13,
				pointPlacement: (sortedData.length === 1) ? 0.135 : (sortedData.length === 2) ? 0.13 : 0.13,
				pointWidth: 50,
			},
			{
				name: 'Connected',
				color: '#B0CFA5',
				data: sortedData.map((item) => +item.ConnectedConversation),
				campaignName: sortedData.map((item) => +item.CampaignName),
				// pointPlacement: -0.03,
				pointPlacement: (sortedData.length === 1) ? -0.005 : (sortedData.length === 2) ? -0.02 : -0.03,
			},
			{
				name: 'Abandoned',
				color: '#71B6FB',
				data: sortedData.map((item) => +item.AbandonedConversation),
				campaignName: sortedData.map((item) => +item.CampaignName),
				// pointPlacement: -0.11,
				pointPlacement: (sortedData.length === 1) ? -0.125 : (sortedData.length === 2) ? -0.12 : -0.11,
			}
		];
		return { graphData, sortedData };
	};

	const filterHourlyData = (data: any[]) => {
		let filteredItems = data;
		const filterKey = filterTypes.find((item: any) => item.id === +filterType) ?? filterTypes[0];
		const key = `${filterKey.value}Id`;
		if (bound !== 'all') {
			filteredItems = filteredItems.filter((item) => item.CallType === bound);
		}
		if (hourlyDigitalSummaryFilter?.length && filterType) {
			const filterIds = hourlyDigitalSummaryFilter.map((filterItem: any) => +filterItem.id);
			filteredItems = filteredItems.filter((item) => filterIds.includes(+item[key]));
		} else {
			const finalFilterItems: any[] = [];
			filteredItems
				.filter((item) => +item[key] === 0)
				.reduce((acc: any, item: any) => {
					if (!acc[item.CallHours]) {
						acc[item.CallHours] = { ...item, TotalConversation: 0, ConnectedConversation: 0, AbandonedConversation: 0 };
						finalFilterItems.push(acc[item.CallHours]);
					}
					acc[item.CallHours].TotalConversation += +item.TotalConversation;
					acc[item.CallHours].ConnectedConversation += +item.ConnectedConversation;
					acc[item.CallHours].AbandonedConversation += +item.AbandonedConversation;
					return acc;
				}, {});
			filteredItems = finalFilterItems;
		}
		return filteredItems;
	};

	const retriveGraphData = async (filters = '0') => {
		const data: any = await getHourlyDigitalCallTrends({ filters, type: filterType, callType: bound });
		const filteredData = filterHourlyData(data);
		const { graphData: formattedGraphData, sortedData } = formatGraphData(filteredData);
		setDataInGraph(sortedData);
		setCategories(sortedData.map((item: any) => `${item.CallHours}:00`));
		setGraphData(formattedGraphData);
		setLastFetchedTime(getLastFetchedDetail());
		chartElement?.current?.chart?.redraw();
	};

	const handlePointClick = (point: any) => {
		console.log('clicked ', point.index);
		setSelectedPoint(point);
		setGraphRefPoint(point?.graphic?.element || point);
		setIsPopoverOpenOnClickBar(true);
	};
	// const handleBound = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
	// 	setBound(newAlignment);
	// };

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	// const toggleRefresh = () => {
	// 	const filterIds = hourlyDigitalSummaryFilter.length ? hourlyDigitalSummaryFilter.map((item: any) => item.id).join(',') : '0';
	// 	retriveGraphData(filterIds);
	// };

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const handleOnPopoverClose = () => {
		setGraphRefPoint(null);
		setSelectedPoint(null);
		setIsPopoverOpenOnClickBar(false);
	};

	const getPopoverContentOnClickBar = () => {
		if (!selectedPoint) return null;
		let connectedProgress: any = null;
		let abandonedProgress: any = null;
		let connectedData: any = "";
		let totalConversationData: any = "";
		let abandonedData: any = "";
		let skillNameData: any = null;

		dataInGraph.map((itemNew: any, ind: any) => {
			if (ind === selectedPoint?.index) {
				connectedData = itemNew.ConnectedConversation;
				totalConversationData = itemNew.TotalConversation;
				abandonedData = itemNew.AbandonedConversation;
				skillNameData = itemNew.CampaignName;
			}
			return null;
		})
		connectedProgress = Math.ceil((connectedData / totalConversationData) * 100);
		abandonedProgress = Math.ceil((abandonedData / totalConversationData) * 100);

		return (
			<Box sx={{ p: 2 }} className={classes.popoverContent}>
				<div className='popover-accordian-summary-box'>
					<div className='popover-accordian-summary-box-total'>
						{(parseInt(skillNameData) === 0) ?
							<>
								Total Conversations <span className='popover-accordian-summary-box-title'>({totalConversationData})</span>
							</>
							:
							<>
								{skillNameData} <span className='popover-accordian-summary-box-title'>({totalConversationData})</span>
							</>
						}
					</div>
					<div className='popover-accordian-summary-subBox'>
						Connected <span className='popover-accordian-summary-subBox-title'> <CircularProgressWithLabel className='linear-progress' value={connectedProgress} /></span> <span className='popover-accordian-summary-subBox-value'>({connectedData})</span>
					</div>
					<div className='popover-accordian-summary-subBox'>
						Abandoned <span className='popover-accordian-summary-subBox-title'><CircularProgressWithLabel className='linear-progress' value={abandonedProgress} /></span> <span className='popover-accordian-summary-subBox-value'>({abandonedData})</span>
					</div>
				</div>
			</Box>
		);
	};

	const options = {
		chart: {
			type: 'column',
			height: 350,
		},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			title: {
				text: 'Time ➡',
				style: { fontWeight: 'bold' }
			},
			categories: categories,
			crosshair: true,
		},
		yAxis: {
			title: {
				text: 'Interaction Volume ➡',
				style: { fontWeight: 'bold' }
			},
			gridLineDashStyle: 'dash',
		},
		legend: {
			layout: 'horizontal',
			align: 'right',
			verticalAlign: 'top',
		},
		tooltip: {
			headerFormat: '<span style="font-size:11px">{series.name}</span>',
			// pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> {series.name}<br/>',
			pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>',
		},
		series: graphData,
		plotOptions: {
			series: {
				cursor: 'pointer',
				borderRadiusTopLeft: '50%',
				borderRadiusTopRight: '50%',
				pointWidth: 10,
				borderRadius: 2,
				groupPadding: 0.3,
				point: {
					events: {
						click: function ({ point }: any) {
							handlePointClick(point);
						}
					}
				}
			}
		},
	};

	React.useEffect(() => {
		const resizeListener = () => {
			chartElement?.current?.chart?.reflow();
		};
		window.addEventListener('widgetResize', resizeListener);
		window.addEventListener('resize', resizeListener);
		return () => {
			clearInterval(interval);
			window.removeEventListener('widgetResize', resizeListener);
		};
	}, []);

	React.useEffect(() => {
		const filterIds = (hourlyDigitalSummaryFilter.length && !(responseLength === hourlyDigitalSummaryFilter.length)) ? hourlyDigitalSummaryFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = hourlyDigitalSummaryFilter.length ? hourlyDigitalSummaryFilter.map((item: any) => item.id).join(',') : '0';
		retriveGraphData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		interval = setInterval(() => retriveGraphData(filterIds), WIDGET_REFRESH_INTERVAL.HOURLY_DIGITAL_SUMMARY);
	}, [bound, hourlyDigitalSummaryFilter, filterType]);

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType }: any) => {
		setHourlyDigitalSummaryFilter(selectedFilters);
		setFilterType(newFilterType);
		toggleDrawer(false);
	};

	return (
		<>
			<Card ref={downloadContent} className="customCard">
				<div className="card-header">
					<div className="card-title">
						Hourly Digital Summary <span className="card-sub-header">(Digital)</span>
						<span className="card-title-badge">Live</span>
						{/* <div className="refresh-btn card-icon" onClick={() => toggleRefresh()}>
						<RefreshOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</div>
						<span className="last-fetched-time">Last fetched at: {lastFetchedTime} </span> */}
					</div>
					<div className="card-actions">
						{/* <ToggleButtonGroup value={bound} color="primary" exclusive onChange={handleBound} aria-label="bound">
							<ToggleButton value="all" className={classes.boundButton} aria-label="all">
								<span>All</span>
							</ToggleButton>
							<ToggleButton value="voice" className={classes.boundButton} aria-label="voice">
								<span>Voice</span>
							</ToggleButton>
							<ToggleButton value="outbound" className={classes.boundButton} aria-label="outbound">
								<span>Outbound</span>
							</ToggleButton>
							<ToggleButton value="digital" className={classes.boundButton} aria-label="Digital">
								<span>Digital</span>
							</ToggleButton>
						</ToggleButtonGroup> */}
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={'Hourly Digital Summary(Digital)'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={hourlyDigitalSummaryFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
					</div>
				</div>
				<Box sx={{ width: '100%', typography: 'body1' }}>
					{hourlyDigitalSummaryFilter.length > 0 && (
						<Box className='customTabPanel'>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className="filter-outer">
										<div className="filter-container">
											<Stack direction="row" spacing={1}>
												{filterItems}
											</Stack>
										</div>
										<div className="filter-remove" onClick={clearFilter}>
											Remove
										</div>
									</div>
								</Grid>
							</Grid>
						</Box>
					)}
				</Box>

				<HighchartsReact ref={chartElement} highcharts={Highcharts} options={options} />
			</Card>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} allowedFilters={['Campaign', 'Skill', 'Group']} existingSelectedFilters={hourlyDigitalSummaryFilter} setResponseLength={setresponseLength} />

			{/* <Popover
                sx={{marginTop: '20px', borderRadius : '30px'}}
				open={isPopoverOpenOnClickBar}
				onClose={handleOnPopoverClose}
				anchorReference="anchorEl"
				anchorEl={graphRefPoint}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				{getPopoverContentOnClickBar()}
			</Popover> */}
			<Menu
				id='hourlyDigitalMenu'
				aria-labelledby="hourlyDigitalMenu"
				anchorEl={graphRefPoint}
				open={isPopoverOpenOnClickBar}
				onClose={handleOnPopoverClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				sx={{
					'& .MuiMenu-paper': {
						backgroundColor: '#FFFFFF',
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04)',
						border: '1px solid #E6E5E6'
					}
				}}
				anchorReference="anchorEl"
			>
				{getPopoverContentOnClickBar()}

			</Menu>
		</>
	);
}
export default HourlyDigitalSummary;
