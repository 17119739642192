import pdfMake from "pdfmake/build/pdfmake";

const hLineWidth = (i, node) =>
    i === 0 || i === node.table.body.length ? 1 : 1;

const vLineWidth = (i, node) =>
    i === 0 || i === node.table.widths.length ? 1 : 0;

const hLineColor = (i, node) =>
    i === 0 || i === node.table.body.length
        ? '#babfc7'
        : '#dde2eb';

const vLineColor = (i, node) =>
    i === 0 || i === node.table.widths.length
        ? '#babfc7'
        : '#dde2eb';
const fillColor = (rowIndex, node, columnIndex) => {
    if (rowIndex < node.table.headerRows) {
        return "#f8f8f8";
    }
    return rowIndex % 2 === 0 ? '#fcfcfc' : '#fff';
};

const footer = function (currentPage, pageCount) {
    return {
        text: currentPage.toString() + " of " + pageCount,
        margin: [20]
    };
};

function printPdfWithData(headersActual, rowData, filename) {
    const headers = Object.values(headersActual);
    const headerKeys = Object.keys(headersActual);
    const docDefinition = {
        pageOrientation: 'landscape',
        pageMargins: [10, 10, 10, 40],
        footer,
        content: [
            {
                style: "myTable",
                table: {
                    headerRows: 1,
                    widths: headers.map(() => '*'),
                    body: [
                        headers.map(header => ({ text: header, style: 'tableHeader' })),
                        ...rowData.map(row => headerKeys.map(header => row[header]))
                    ],
                    style: 'table'
                },
                layout: {
                    fillColor,
                    hLineWidth,
                    vLineWidth,
                    hLineColor,
                    vLineColor
                }
            }
        ],
        styles: {
            myTable: {
                margin: [0, 10, 0, 0]
            },
            tableHeader: {
                bold: true,
                margin: [0, 20, 0, 0]
            },
            tableCell: {
                // margin: [0, 15, 0, 0]
            }
        }
    };

    // Generate and download the PDF
    pdfMake.createPdf(docDefinition).download(filename + '.pdf');
}

export default printPdfWithData;