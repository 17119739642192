import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack } from '@mui/system';
import { connect } from "formik";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {Grid,TextField } from "@mui/material";
import moment from 'moment';


const MaterialUIPickers = connect(({ name, selectedFilters, date }) => {
    let newDate = null;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = useState(newDate);

    const onSelect = (newValue) => {
      setValue(newValue);
    };

    const onSelectClose = () => {
        let newFormatedValue = value?.format("DD-MM-YYYY HH:mm:ss");
        date(newFormatedValue,name);
      };

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3}>
          <DateTimePicker
            // disablePast
            // disableFuture
            // value={value ?? selectedFilters[name]}
            value={value != null ? value : (selectedFilters[name] != null ? moment(selectedFilters[name]).format("DD-MM-YYYY HH:mm:ss") : null)}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            onChange={onSelect}
            onClose={onSelectClose}
            inputFormat="DD-MM-YYYY HH:mm:ss"
            renderInput={(params) => <TextField sx={{
                '& .MuiOutlinedInput-root': {
                    border:'0px'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: '0px'
                },
                '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    border: '0px'
                },
                '& .MuiOutlinedInput-input':{
                    fontSize: '13px',
                    width: '133px'
                }
                }} {...params} />}
          />
        </Stack>
      </LocalizationProvider>
    );
  })

const OZDateAccordion = ({date, selectedFilters}) =>
{
    const useStyles = makeStyles((theme) => ({
        filterContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            paddingTop: '74px'
        },
        filterHeader: {
            display: 'flex',
            padding: '1rem',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: '#FFFFFF',
            boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.08)'
        },
        headerText: {
            fontWeight: '600 !important',
            fontSize: '18px !important'
        },
        subHeaderText: {
            paddingTop: '20px',
            paddingLeft: '20px',
            fontWeight: '400',
            fontSize: '14px',
            marginBottom: '8px !important'
        },
        timeInputsContainer: {
            borderRadius: '8px 8px 8px 8px',
            backgroundColor: '#ffffff',
            height: '30px',
            maxWidth: '415px',
            border: '1px solid #d6d6d7',
            marginLeft: '20px'
        },
        arrowIconContainer: {
            backgroundColor: '#d6d6d7',
            width: '30px',
            height: '29px',
            border: '1px solid #d6d6d7',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        timePicker: {
            height: '30px',
            width: '100%',
            zIndex: 'inherit'
        },
        timePickerContainer: {
            width: '189px'
        }
    }));
    const classes = useStyles();
    
    return (
        <Grid item sx={{ 
            display: 'flex',
            borderRadius: '8px',
            border: '1px solid #D6D6D7',
            backgroundColor: '#ffff',
            fontSize: '14px',
            alignItems: 'center',
            height:'29px'
           }}>
           <Grid item>
           <MaterialUIPickers name="startDate" 
                date={date}
                selectedFilters={selectedFilters}
               // values={blockGroupFormProps?.values} 
               />
           </Grid>
           <Grid item className={classes.arrowIconContainer}>
               <img src={`${process.env.PUBLIC_URL}/icons/time-side-arrow.svg`} alt="arrow" />
           </Grid>
           <Grid item>
           <MaterialUIPickers name="endDate" 
                date={date}
                selectedFilters={selectedFilters}
               // values={blockGroupFormProps?.values} 
               />
           </Grid>
       </Grid>
    );
}

export default OZDateAccordion;