import { Button, FormHelperText, Grid } from "@mui/material";
import { FieldArray, getIn, connect } from "formik";
import { useState, useEffect } from "react";
import CustomAutoComplete from "../../../components/admin/common/AutoComplete/CustomAutoComplete";
import OZInput from "../../../components/admin/common/OZInput";
// import Widget from "../../../components/BroadCast/widget/Widget";
// import { fetchBroadCastMessage, getLoggedInAgentsGroupWise } from "../../../services/api-service";
import WidgetFooter from "../../../components/BroadCast/widget/footer/Footer";
import { checkRoles } from "../../../utils/util";
// import DummyFooter from "../../../components/BroadCast/widget/footer/DummyFooter";


export function AgentListForSub(agentListProps) {

    const [agentListValues, setAgentListValues] = useState(() => {
        if (agentListProps.autoCompleteMode === "Add") {
            return [agentListProps.agentDataList]
        }
        else {
            if (agentListProps?.formikValues?.values?.agentSkillList.length > 0) {
                let previousSelectedagentLists = structuredClone(agentListProps.formikValues.values.agentSkillList);
                let selectedagents = previousSelectedagentLists.map((agent) => (
                    agent.agentList.map((item) => {
                        return (item);
                    })
                )).flat(1);
                return agentListProps?.formikValues?.values?.agentSkillList.map((agents) => {
                    let newOptions = agentListProps.agentDataList.filter(agent => selectedagents.every(selectedAgent => selectedAgent.id !== agent.id));
                    return [...agents.agentList, ...newOptions,]
                })
            }
            else {
                return []
            }
        }
    });

    //::::::::;ROLE_DATA_ACCESS:::::::://

    useEffect(() => {
        let totalValueArr = [];
        agentListProps?.formikValues?.values?.agentSkillList.map((item) => {
            // let totalValue = 0;
            // const agentListLength = item.agentList?.length || 0; // Only consider 'agentList' length
            // setlengthForAgentSkillList(totalValue + agentListLength);
            // console.log("Agent List Length:", agentListLength);
            totalValueArr.push(item?.agentList?.length || 0);
        })
        setlengthForAgentSkillList(totalValueArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
    }, [agentListProps.formikValues]);

    const [agentListForSub, setAgentListForSub] = useState([agentListProps.updateValues]);

    const [lengthForAgentSkillList, setlengthForAgentSkillList] = useState(0);

    function makeFunctionToPassFooter(value){
        let tempValue = structuredClone(value);
        const fullOptions = agentListProps.updateValues;
        let neValue = [];

        function getGroups(agent, groupList){
                const grpIndex = groupList.findIndex((g) => g.groupId === agent.groupId);
                if (grpIndex > -1) {
                    const groupAgent = groupList[grpIndex].items.find((groupItem) => groupItem.Agent_Id === agent.agentId);
                    
                    if (groupAgent) {
                        groupAgent.checked = true;
                    }
                }
                return groupList

        }

        function getSelectedAgents(index){
            const selected = []
            tempValue.forEach((agentSkillList,aindex)=>{
                if(aindex !== index){
                    agentSkillList.agentList.forEach((item)=>{
                        selected.push(item)
                    })
                }
            })
            return selected;
        }

        tempValue.map((mainItem,index) => {
        const selectedAgents= getSelectedAgents(index)
            const subOptions = [] 
            fullOptions.map((grp)=>{
                let newGrp = {
                    ...grp
                }
                newGrp.items = grp.items.filter((agentItem)=>{
                    const isPrevSelected = selectedAgents.findIndex((selectedAgent)=> {
                                return selectedAgent.id === agentItem.id
                    })
                    if(isPrevSelected > -1){
                        return false
                    }
                    return true
                })
                if(newGrp.items && newGrp.items.length){
                    subOptions.push(newGrp)
                }
            })
            let options = [];
            mainItem.agentList.forEach((agent) => {
               
                 options = getGroups(agent, subOptions);
                
            });


            neValue.push(options)

        })
        console.log("here  neValue ", neValue);
        return neValue;
    }
    //::::::::;ROLE_DATA_ACCESS:::::::://

    //::::::::;ROLE_DATA_ACCESS:::::::://
    useEffect(() => {
        if(localStorage.getItem('isAdmin') === false || localStorage.getItem('isAdmin') === 'false'){
            if(agentListProps.mode === 'Edit'){
                if(agentListProps.formikValues.initialValues.agentSkillList){
                    // setAlreadySelecetdValues(agentListProps.formikValues.initialValues.agentSkillList);

                    let tempAlreadySelecetd = makeFunctionToPassFooter(agentListProps.formikValues.initialValues.agentSkillList);
                    setAgentListForSub(tempAlreadySelecetd)

                    // agentListProps.formikValues.setFieldValue(`agentSkillList`, agentListProps.formikValues.initialValues.agentSkillList);
                }
            }
        }
    }, [agentListProps.mode]);
    //::::::::;ROLE_DATA_ACCESS:::::::://

    // eslint-disable-next-line no-unused-vars
    
    const [previousSelectedAgentList, setPreviousSelectedAgentList] = useState([]);

    const [formState, setFormState] = useState([]);

    function handleChangeAgents(index, selected, createNewagentlist, removedOption, deleteagentLsit, isRemoveagentList) {

        let previousSelectedagentLists = structuredClone(agentListProps.formikValues.values.agentSkillList);
        let selectedagents = previousSelectedagentLists.map((agent) => (
            agent.agentList.map((item) => {
                return (item);
            })
        )).flat(1);

        if (selected.length > 0) {
            selectedagents = [selectedagents, selected].flat(1)
        }
        let newOptions = agentListProps.agentDataList.filter(agent => selectedagents.every(selectedAgent => selectedAgent.id !== agent.id));
        if (createNewagentlist === "removeOption") {
            newOptions = [...newOptions, ...removedOption]
        }
        let localagentListValues = structuredClone(agentListValues)
        if (createNewagentlist) {
            localagentListValues = [...localagentListValues, newOptions]
        }
        let newagentlist = localagentListValues.map((singleagentList, subindex) => {
            if (index === subindex) {
                return singleagentList;
            }
            else {
                if (previousSelectedagentLists[subindex] === undefined) {
                    return newOptions
                } else {
                    return [previousSelectedagentLists[subindex].agentList, newOptions].flat(1);
                }
            }
        })
        if (isRemoveagentList) {
            newagentlist.splice(index, 1)
        }
        setAgentListValues(newagentlist);
        setPreviousSelectedAgentList(selectedagents);
    };

    //::::::::;ROLE_DATA_ACCESS:::::::://
    const [open, setOpen] = useState(true);
    const [messages, setMessages] = useState([]);

    useEffect(() => { open && getMessages() }, [open])

    const getMessages = async () => {
    // try {
    //   const resp = await fetchBroadCastMessage();
    //   if (resp.Data) {
    //     const data = JSON.parse(resp.Data)
    //     const buildMessage = data.map((msg) => {
    //       let to = []
    //       if (msg.AgentName && msg.AgentName.length > 0) { to = [...msg.AgentName.split(',')] }
    //       if (msg.AgentGroupName && msg.AgentGroupName.length > 0) { to = [...to, ...msg.AgentGroupName.split(',')] }
    //       return {
    //         type: "text",
    //         to: to,
    //         time: new Date(msg.MessageSentTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
    //         msg: msg.Message,
    //         ts: msg.MessageSentTime
    //       }
    //     })
    //     if (buildMessage.length) {
    //       const sortedMessages = buildMessage.sort((a, b) => new Date(a.ts) - new Date(b.ts))
    //       setMessages(sortedMessages)
    //     }
    //   }
    // }
    // catch (err) {
    //   console.log(err)
    // }
    }

    function updateGroupsWithSelectedAgents(selected, groups) {
        selected.forEach((selectedItem) => {
            selectedItem.agentList?.forEach((agent) => {
                const group = groups.find((g) => g.groupId === agent.groupId && g.groupName === agent.groupName);
                if (group) {
                    const groupAgent = group.items.find((groupItem) => groupItem.Agent_Id === agent.agentId);
                    
                    if (groupAgent) {
                        groupAgent.checked = true;
                    }
                }
            });
        });
    
        return groups;
    }

    const handleRemoveForSub = (index) => {
        let newLoginAgentListValues = structuredClone(agentListForSub);

        let selectedAgents = agentListProps?.formikValues?.values?.agentSkillList[index]?.agentList;
        newLoginAgentListValues.splice(index);
        newLoginAgentListValues.map((groups) => {

            selectedAgents.forEach((agent) => {
                const group = groups.find((g) => g.groupId === agent.groupId && g.groupName === agent.groupName);
                if (group) {
                    const groupAgent = group.items.find((groupItem) => groupItem.Agent_Id === agent.agentId);
                    
                    if (groupAgent) {
                        groupAgent.checked = false;
                    }
                }
            });

        })


        // setAgentListForSub(newLoginAgentListValues);
        setAgentListForSub(prev => [...prev, newLoginAgentListValues]);


    }

    // const handleChangeForSub = (index) => {
    //     let newLoginAgentListValues = structuredClone(agentListProps?.updateValues);
    //     let selectedAgents = agentListProps?.formikValues?.values?.agentSkillList; //just for testing 
    //     console.log("here non selectedAgents", selectedAgents);
    //     const updatedGroups = updateGroupsWithSelectedAgents(selectedAgents, newLoginAgentListValues);
    //     console.log("here non updatedGroups", updatedGroups);
    //     const updatedGroupsNew = updatedGroups.map(group => {
    //         return {
    //             ...group,
    //             items: group.items.filter(item => !item.checked)
    //         };
    //     });
    //     const nonEmptyGroups = updatedGroupsNew.filter(group => group.items.length > 0);
    //     console.log("here non empty groups", nonEmptyGroups);
    //     setAgentListForSub(prev => [...prev, nonEmptyGroups]);
    // }

    //::::::::::writting for testing:::::::::::::://
    const handleChangeForSub = (index) => {
        let newLoginAgentListValues = structuredClone(agentListProps?.updateValues);
        let selectedAgents = agentListProps?.formikValues?.values?.agentSkillList; //just for testing 
        const updatedGroups = updateGroupsWithSelectedAgents(selectedAgents, newLoginAgentListValues);
        const updatedGroupsNew = updatedGroups.map(group => {
            return {
                ...group,
                items: group.items.filter(item => !item.checked)
            };
        });
        const nonEmptyGroups = updatedGroupsNew.filter(group => group.items.length > 0);
        setAgentListForSub(prev => [...prev, nonEmptyGroups]);
    }
    //::::::::::writting for testing:::::::::::::://

    const getNewUpdatedValue = (value, index) => {
        agentListProps.formikValues.setFieldValue(`agentSkillList.${index}.agentList`, value);
    }

    //::::::::;ROLE_DATA_ACCESS:::::::://

    return (
        <FieldArray name="agentSkillList">
        {({ replace, remove, push }) => (
            <div>
                {agentListProps.formikValues.values.agentSkillList.length > 0 && agentListProps.formikValues.values.agentSkillList.map((agent, index) => {
                    const agentSkillList = `agentSkillList.${index}.agentList`;

                    const errorDays = getIn(agentListProps.formikValues.errors, agentSkillList);
                    const touchDays = getIn(agentListProps.formikValues.touched, agentSkillList);


                    return(
                        <div key={index}>
                            <Grid container spacing={2} marginBottom='15px'  >
                                <Grid item xs={agentListProps.autoCompleteMode === "Add" ? 1.7 : 1.7}    >
                                    <OZInput
                                        name={`agentSkillList.${index}.priority`}
                                        label="Priority"
                                        type="text"
                                        placeholder="1"
                                    />
                                </Grid>

                                <Grid item xs={agentListProps.autoCompleteMode === "Add" ? 7.6 : 9.5} sx={{marginTop: agentListProps.autoCompleteMode === "Add" ? '10px' : ""}} >
                                    <WidgetFooter mode={agentListProps?.mode} prevSelectedValues={agentListForSub[index] || []} component={'skillList'} getMessages={getMessages} messages={messages} setMessages={setMessages} updatedValue={(e) => getNewUpdatedValue(e, index)} />
                                    {/* <DummyFooter mode={agentListProps?.mode} prevSelectedValues={agentListForSub[index] || []} component={'skillList'} getMessages={getMessages} messages={messages} setMessages={setMessages} updatedValue={(e) => getNewUpdatedValue(e, index)} /> */}
                                    <FormHelperText error>{touchDays && errorDays}</FormHelperText>
                                </Grid>
                                {agentListProps.formikValues.values.agentSkillList.length > 1 && index > 0 &&
                                    <div style={{
                                        paddingLeft: agentListProps.mode === "Create" ? "50px" : "3px", marginTop: "47px", cursor: "pointer",
                                    }} >
                                        <img onClick={() => {
                                            remove(index)
                                            // if(checkRoles('ROLE_ADMIN')){
                                                // handleChangeAgents(index, [], "removeOption", agentListProps.formikValues.values.agents[index].agentList.length > 0 ? agentListProps.formikValues.values.agents[index].agentList : [], true)
                                            // }else{
                                                handleRemoveForSub(index);
                                            // }
                                        }}
                                            src={`${process.env.PUBLIC_URL}/icons/deleteIconGrey.svg`}
                                            alt="remove"
                                        ></img>
                                    </div>
                                }
                            </Grid>

                            {(agentListProps.formikValues.values.agentSkillList.length === (index + 1)) &&
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        disabled={!(agentListProps.formikValues.values.agentSkillList[index].agentList?.length > 0 && agentListProps.formikValues.values.agentSkillList[index].priority && agentListProps.fullLengthOfAgentsByGroup !== lengthForAgentSkillList)}
                                        variant="text"
                                        sx={{
                                            fontSize: "14px", fontWeight: 400,
                                            color: agentListProps.mode === 'Create' ? '#536580' : '#3D8BF8',
                                            padding: "0px 0px 0px 0px",
                                            marginLeft: "1px"
                                        }}
                                        onClick={() => {
                                            handleChangeForSub(index)
                                            push({ priority: '', agentList: [] });
                                            //     handleChangeAgents(index, [], true);
                                        }}
                                    >
                                        <img style={{ paddingRight: "6px", paddingBottom: "3px" }}
                                            src={agentListProps.mode === 'Create' ? `${process.env.PUBLIC_URL}/icons/anotherlevel.svg` : `${process.env.PUBLIC_URL}/icons/anotherlevelblue.svg`}
                                            alt="edit"
                                        ></img>
                                        Another level</Button>
                                </div>}
                        </div>
                    )})
                }
            </div>
        )}
        </FieldArray>
    )
}

