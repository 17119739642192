import React, { useState, useMemo, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { Typography } from "@mui/material";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import IvrForm from "./IvrForm";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { deleteIvrById, getIvrById, getIvrFlow,saveCopyAccountIvr,saveCopyIvr } from "../../../services/api-service";
import "./Ivr.css";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import { checkRoles } from "../../../utils/util";
import { getUserId } from "../../../services/auth-service";
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import { IVR_FLOW_URL } from "../../../utils/properties";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import CopyToAccountForm from "./CopyToAccountForm";


const IvrFlow = (props) => {
  const [showIvrFlowForm, setShowIvrFlowForm] = useState({ isNew: false, show: false });
  const [ivrGridRef, setIvrGridRef] = useState(null)
  const [onClickIvrRowData, setOnClickIvrRowData] = useState({});
  const [openDailogState, setDailogState] = useState(false);
  const [openDrawer, setDrawerState] = useState(false);
  const [loading, setLoading] = useState(false);
  const getIvrName = (props) => {
    return (
      <span className="oz-ivr-flowName-colour">{props.value}</span>
    )
  };
  async function saveCopy(data, copyType, accountId) {
    console.log("data", data);
    getIvrById(data.id)
      .then((resp) => {
        setOnClickIvrRowData(resp.Data[0]);
        let copyItem = resp.Data[0]
        //delete copyItem.flowId
        let saveCopys = data.flowName + Date.now()
        copyItem.flowName = saveCopys
        const functionToCall = (copyType == "Normal") ? saveCopyIvr(copyItem) : saveCopyAccountIvr(copyItem,accountId);
        functionToCall
          .then((resp) => {
            if (resp.Status === "Success") {
              closeIvrForm();
              getIvrList()
              showSuccessNotification(`IVR Flow (${data.flowName}) has been Copied Successfully`);
            }
            else {
              showErrorNotification(resp.Message)
            }
          })
          .catch((e) => {
            showErrorNotification(JSON.stringify(e));
          });
      })
      .catch((e) => {
        setOnClickIvrRowData({});
      });
  };

  async function deleteIvrsById(id, name) {
    try {
      deleteIvrById(id)
        .then((resp) => {
          if (resp.Status === "Success") {
            closeIvrForm();
            getIvrList()
            showSuccessNotification(`IVR Flow ${name} has been deleted Successfully`)
          } else {
            showErrorNotification(resp.Message)
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  };

  function ivrCallBack(type, id, data, accountId) {
    if (type === "saveCopyFunction") {
      data.id = data.flowId
      saveCopy(data, "Normal");
    }
    if (type === "saveCopyAccountFunction") {
      data.id = data.flowId
      saveCopy(data, "Account", accountId);
    }
    if (type === "deleteFunction") {
      deleteIvrsById(id, data.flowName);
    }
  };

  const Desgin = (props) => {
    const [ivranchorEl, setAnchorEl] = React.useState(false);
    const open = Boolean(ivranchorEl);
    const anchorRef = React.useRef();
    const [deleteMode, setDeleteMode] = useState(false);
    const [openCopyAccountDailog, setCopyAccountDialog] = useState(false)

    const morevertclick = () => {
      setTimeout(() => setAnchorEl(anchorRef?.current), 1);
    };
    const handleClose = () => {
      setAnchorEl(false);
    };
    const saveCopyFlowName = () => {
      setAnchorEl(false);
      saveCopy(props.data, "Normal");
    };
    const saveCopyAccountFlow = (accountId) => {
      setAnchorEl(false);
      saveCopy(props.data, "Account", accountId);
    };
    const onClickDelete = () => {
      setAnchorEl(false);
      setDeleteMode(true);
    };
    const onClickDialogDelete = () => {
      let id = props?.data?.id;
      let name = props?.data?.flowName;
      deleteIvrsById(id, name);
      setDeleteMode(false);
    };
    const onClickDialogClose = () => {
      setDeleteMode(false);
    };

    const openInNewWindow = (e, data) => {
      try {
        const url = new URL(IVR_FLOW_URL);
        const currentApplicationHost = window.location.host;
        url.host = currentApplicationHost;
        const updatedUrl = url.toString();
        e.stopPropagation();
        let userId = getUserId()
        //passing parent user Id as userId for opening ivr flow for subadmin
        if(localStorage.getItem('parentUser') && localStorage.getItem('parentUser') !== 'null'){
          userId = JSON.parse(localStorage.getItem('parentUser')).id;
        }
        if (data?.type === 1) {
          window.open(`${updatedUrl}/index.html?#/flows/${data.id}/userId/${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
          // window.open(`https://beta-ivr.ccaas.ozonetel.com/ivrdd/index.html?#/flows/${data.id}/userId/${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
        } else if((data?.type === 3)){
            window.open(`${updatedUrl}/botv2/index.html?flowId=${data?.id}&userId=${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
          // window.open(`https://beta-ivr.ccaas.ozonetel.com/ivrdd/bot/index.html?#/flows/${data.id}/userId/${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
        }else{
                      window.open(`${updatedUrl}/bot/index.html?#/flows/${data?.id}/userId/${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
        }
        
      } catch (e) {
        showErrorNotification("Failed to open url");
      }
    };
    function openCopyAccountDialog() {
      setAnchorEl(false);
      setCopyAccountDialog(true)
    };
    const styleMenuItemButton = useMemo(
      () => ({
        "&.MuiButtonBase-root": {
          color: '#212121',
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          height: '18px',
          lineHeight: "14px",
        },
        '&:hover': {
          borderRadius: "8px",
        },
      }),
      []
    );

    return (
      <div className="oz-ivr-grid-cellrenderer-container" >
        <Typography
          sx={{
            fontfamily: "Roboto",
            fontstyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            color: "#536580",
            cursor: "pointer"
          }}
          onClick={(e) => openInNewWindow(e, props.data)}>
          Design
        </Typography>
        {/* {authPage(AdminPages.IVR_FLOWS_VIEW) && */}
        {(newAuthPages(NewAdminPages.IVR_FLOWS) || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Full') || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Edit')) &&
          <img className="gridMorevertIcon" ref={anchorRef} style={{ marginTop: "2px", marginLeft: '0.625rem', alignSelf: 'center' }}
            src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
            alt="edit"
            onClick={morevertclick}
          ></img>
        }
        <div ref={anchorRef}>
          <Menu anchorEl={ivranchorEl} open={open} onClose={handleClose} sx={{
            maxHeight: "70vh",
            "& .MuiPaper-root": {
              backgroundColor: "white",
              width: "133px",
            },
            "& .MuiMenu-paper": {
              backgroundColor: "white",
              boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
              border: "0.0625rem solid #E6E5E6",
              borderRadius: "12px",
              marginLeft: "-1.5625rem",
            },
          }} >
            {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_IVR_COPY_USER")) && <MenuItem disabled={newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'data')} sx={{ ...styleMenuItemButton }} onClick={saveCopyFlowName}>Save Copy</MenuItem>}
            {(checkRoles("ROLE_ADMIN")) && <MenuItem disabled={newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'data')} sx={{ ...styleMenuItemButton, marginTop: "7px" }} onClick={openCopyAccountDialog}>Save Copy Account</MenuItem>}
            <MenuItem disabled={newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'data')} sx={{ ...styleMenuItemButton, marginTop: checkRoles("ROLE_ADMIN") ? "7px" : "0px" }} onClick={onClickDelete}>Delete</MenuItem>
          </Menu>
        </div>
        {openCopyAccountDailog &&
          <CopyToAccountForm saveCopyAccountFlow={saveCopyAccountFlow} setCopyAccountDialog={setCopyAccountDialog}/>
        }
        {
          deleteMode && <DeleteDialog title={"Delete IVR Flow"} content={"This will delete this IVR Flow:" + props.data.flowName + " permanently. You cannot undo this action."} onClose={onClickDialogClose}
            open={true} deleteFunction={onClickDialogDelete} />
        }

      </div>

    );
  };

  const [ivrList, setIvrList] = useState([]);
  useEffect(() => {
    getIvrList();
  }, []);

  const columnDefs = [
    {
      field: "id",
      headerName: "Flow Id",
      cellRenderer: getIvrName
    },
    {
      field: "flowName",
      headerName: "Flow Name",
      cellRenderer: getIvrName
    },
    {
      field: "Actions",
      cellRenderer: Desgin,
    },
  ];

  async function getIvrList() {
    setLoading(true);
    try {
      getIvrFlow()
        .then((resp) => {
          setIvrList(resp.Data);
          setLoading(false);
        })
        .catch((e) => {
          setIvrList({});
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setLoading(false);
    }
  };

  let buttonStyle = useMemo(
    () => ({
      borderRadius: "8px",
      height: "30px",
      fontSize: "14px",
      fontWeight: 400,
    }),
    []
  );

  const showIvrForm = () => {
    setShowIvrFlowForm({ isNew: true, show: true });
    setDailogState(true);
    setDrawerState(false);
  };

  const closeIvrForm = () => {
    setShowIvrFlowForm(false);
    setShowIvrFlowForm({ isNew: false, show: false });
  };

  async function onClickRow(rowItem) {
    setDrawerState(true);
    setDailogState(false);
    setLoading(true);
    setShowIvrFlowForm({ isNew: false, show: true });
    setOnClickIvrRowData({});
    try {
      getIvrById(rowItem.data.id)
        .then((resp) => {
          setOnClickIvrRowData(resp.Data[0]);
          setLoading(false);
        })
        .catch((e) => {
          setOnClickIvrRowData({});
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setLoading(false);
    }
  };

  return (
    <div className="oz-ivr-container">
      <Typography
        sx={{
          fontweight: '400',
          fontSize: '12px',
          marginBottom: "5px"
        }}
        flexGrow={1}
        alignSelf="center"
      >
        Configurations
      </Typography>
      <Stack direction="row" sx={{ marginBottom: 2 }} spacing={1}>
        <Typography
          sx={{
            fontStyle: "Roboto",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "28.13px",
            marginBottom: "4px"
          }}
          flexGrow={1}
          alignSelf="center"
        >
          IVR Flows
        </Typography>
        {/* {authPage(AdminPages.IVR_FLOWS_VIEW) && */}
        {(newAuthPages(NewAdminPages.IVR_FLOWS) || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Full')) &&
          <Button
            sx={buttonStyle}
            className="Add-button"
            variant="contained"
            startIcon={<AddBoxOutlinedIcon />}
            onClick={showIvrForm}
          >
            New IVR Flow
          </Button>
        }
      </Stack>
      {showIvrFlowForm.show && showIvrFlowForm.isNew && (
        <IvrForm
          Ivr={getIvrList}
          ivrCallBack={ivrCallBack}
          onClose={closeIvrForm}
          popClose={openDailogState}
          drawerClose={openDrawer}
        />
      )}
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        (<>
          {showIvrFlowForm.show && !showIvrFlowForm.isNew && (
            <IvrForm
              Ivr={getIvrList}
              ivrCallBack={ivrCallBack}
              onClose={closeIvrForm}
              data={onClickIvrRowData || []}
              popClose={openDailogState}
              drawerClose={openDrawer}
            />
          )}
        </>
        )
      }
      <OZGridToolbar modifyHeaderName={['Flow Id']} modifyHeaderNameLocation={['A1']} title='List of IVR Flows' searchPlaceholder='Flow Name' rowCount={ivrList.length} dataGridRef={ivrGridRef} fileName='ivrflows' />
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        (<>
          <OZDataGrid
            columns={columnDefs}
            data={ivrList}
            rowClickCallback={onClickRow}
            height="26.3rem"
            setGridRef={setIvrGridRef}
          />
        </>
        )
      }
    </div>
  );
};

export default IvrFlow;
