import React from 'react';
import { Box, Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import OZCheckbox from '../OZCheckbox/OZCheckbox';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
	isOpen?: boolean;
	handlePopoverClose?(): void;
	anchorRef?: any;
	elementsArray?: Array<any>;
	selectedElements?: [];
	handleOnSelectionChange?(selectedElements: any): void;
	addMetricsLabel?: any;
}

const useStyles: any = makeStyles(() => ({
	popoverContent: {
		minWidth: 350,
		fontSize: '12px',
		lineHeight: 2,
		padding: '1rem'
	},
	popoverHeader: {
		width: '100%'
	},
	popoverBody: {
		marginTop: '0.5rem',
		maxHeight: '200px',
		overflow: 'auto',

		'&::-webkit-scrollbar': {
			width: '10px'
		},
		/* Track */
		'&::-webkit-scrollbar-track': {
			background: '#f1f1f1',
			borderRadius: '8px'
		},
		/* Handle */
		'&::-webkit-scrollbar-thumb': {
			background: '#888',
			borderRadius: '8px'
		},
		/* Handle on hover */
		'&::-webkit-scrollbar-thumb:hover': {
			background: '#555'
		}
	},
	popoverAction: {
		marginTop: '1rem'
	}
}));

const SelectPopover = ({ isOpen = false, handlePopoverClose, anchorRef, elementsArray, selectedElements, handleOnSelectionChange, addMetricsLabel }: Props) => {
	const [selected, setSelected] = React.useState<any>(selectedElements);
	const classes = useStyles();

	const isSelected = (item: any) => {
		const result = selected.some((eachElement: any) => item.id === eachElement.id);
		return result;
	};

	const handleOnElementChange = (event: any) => {
		if (event?.target?.checked && event?.target?.value) {
			const existingSelectedElements: any = [...selected];
			existingSelectedElements.splice(event.target.value.positionIndex || 0, 0, event.target.value);
			setSelected(existingSelectedElements);
		} else {
			const newSelectedElements: any = selected.filter((item: any) => item.id !== event?.target?.value?.id);
			setSelected(newSelectedElements);
		}
	};

	const handleApplySelection = () => {
		handleOnSelectionChange && handleOnSelectionChange(selected);
	};

	return (
		<Popover
			open={isOpen}
			onClose={handlePopoverClose}
			anchorReference={'anchorPosition'}
			anchorPosition={anchorRef}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
		>
			<Box className={classes.popoverContent}>
				<Stack direction={'row'} alignItems={'center'}>
					<Typography className={classes?.popoverHeader}>{addMetricsLabel ? 'Add Metrics' : 'Edit Column Headers'}</Typography>
					<IconButton onClick={handlePopoverClose}>
						<CloseIcon />
					</IconButton>
				</Stack>

				<Box className={classes?.popoverBody}>
					{elementsArray &&
						elementsArray.map((eachElement: any, idx: number) => {
							return (
								eachElement?.label && (
									<OZCheckbox
										key={eachElement.id + idx}
										item={eachElement}
										checked={isSelected(eachElement)}
										label={eachElement.label}
										id={eachElement.id}
										onChange={handleOnElementChange}
									/>
								)
							);
						})}
				</Box>
				<Box className={classes?.popoverAction}>
					<Button variant="contained" onClick={handleApplySelection} sx={{ borderRadius: '8px' }} fullWidth>
						Apply
					</Button>
				</Box>
			</Box>
		</Popover>
	);
};

export default SelectPopover;
