import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { clearSession, isLoggedIn } from '../../services/auth-service';
import ContactAdmin from '../ContactAdmin/ContactAdmin';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import Login from '../Login/Login';
import ResetPassword from '../ResetPassword/ResetPassword';
import '../Wireframe/Wireframe.css';
import WireframeDashboard from '../WireframeDashboard/WireframeDashboard';
import { isJwtTokenExpired } from '../../services/api-service';
import ResetPasswordNew from '../Login/ResetPasswordNew';
import SsoLogin from '../Login/SsoLogin';

function Wireframe() {

	const LoginPage = () => {
		let matches = false
		try {
			matches = window.location.href.includes('dishtv') || false;
		} catch {
			matches = false
		}
		return matches ? <SsoLogin /> : <Login />;
	}

	return (
		<Router basename="/">
			<Routes>
				<Route path="/" element={<Navigate to="/login" />} />
				<Route path={`/login`} element={<AuthRoute />}>
					<Route path={`/login`} element={<LoginPage />} />
				</Route>
				<Route path={`/forgot-password`} element={<ForgotPassword />} />
				<Route path={`/reset-password`} element={<ResetPassword />} />
				<Route path={`/contact-admin`} element={<ContactAdmin />} />
				<Route path={`/resetpassword`} element={<ResetPasswordNew />} />
				<Route path={`*`} element={<PrivateRoute />}>
					<Route path={`*`} element={<WireframeDashboard />}></Route>
				</Route>
			</Routes>
		</Router>
	);
}

export default Wireframe;

function PrivateRoute({ children, ...rest }: any) {
	const isUserLoggedIn = isLoggedIn();
	const isTokenExpired = isJwtTokenExpired();
	const isUserAuthorized = isUserLoggedIn && !isTokenExpired;

	if (isUserAuthorized) {
		return <Outlet />;
	} else {
		clearSession();
		return <Navigate to="/login" />;
	}
}

function AuthRoute({ children, ...rest }: any) {
	const isUserLoggedIn = isLoggedIn();
	const isTokenExpired = isJwtTokenExpired();
	const isUserAuthorized = isUserLoggedIn && !isTokenExpired;
	if (isUserAuthorized) {
		return <Navigate to="/preset" />;
	} else {
		clearSession();
		return <Outlet />;
	}
}
