export function showNotification(title: string, options?: NotificationOptions & { tag?: string }) {
  if (Notification.permission === 'granted') {
      const { tag, ...notificationOptions } = options || {};

      if (tag) {
          const existingNotification = new Notification(title, { ...notificationOptions, tag });

          existingNotification.onclick = () => {
              // Handle click action if needed
          };
      } else {
          new Notification(title, notificationOptions);
      }
  } else if (Notification.permission === 'default') {
      Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
              showNotification(title, options);
          }
      }).catch(err => {
          console.error('Error requesting notification permission:', err);
      });
  } else {
      console.log('Notification permission denied.');
  }
}
