import { Formik } from "formik"
import OZDialog from "../../../components/admin/common/OZDialog"
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen"
import { useMemo, useState } from "react"
import OZInput from "../../../components/admin/common/OZInput"
import * as yup from 'yup';
import { editDigitalCampaigns, saveDigitalCampaigns } from "../../../services/api-service"
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService"

const AddAndEditDigitalCampaignSmsIntegration = (mainProps) => {

    console.log(mainProps.data)
    console.log(mainProps.alreadyAssignedDIDs)
    let smsIntegrationData = []
    mainProps?.data?.map((item) => {
        if (item.integration.name === "SmsChat") {
            smsIntegrationData.push(item)
        }
    })
    smsIntegrationData = mainProps?.data !== undefined ? smsIntegrationData[0] : null
    console.log(smsIntegrationData)

    // const validationSchema = useMemo(
    //     () =>
    //         yup.object({
    //             didOrUuid: yup
    //                 .string()
    //                 .required("DID or UUID is required"),
    //         }),
    //     []
    // );

    const validationSchema = useMemo(() =>
        yup.object({
            didOrUuid: yup
                .string()
                .required("DID or UUID is required")
                .matches(/^(?!~)[\w+~]{4,50}(?<!~)$/, 'Did should be between 4 to 50 character long and allows + and special characters like ~ (but ~ cannot be the first or last character)')
                .test('is-unique', 'DID already taken', function (value) {
                    return !mainProps.alreadyAssignedDIDs.includes(value);
                }),
        }),
        [mainProps.alreadyAssignedDIDs]
    );

    async function editDigitalCampaign(values) {
        setLoading(true);
        console.log(values);
        let clientidDefaultString = smsIntegrationData.clientId.split(":")
        let changeInsmsIntegrationData = {
            ...smsIntegrationData,
            clientId: `${clientidDefaultString[0]}:${values.didOrUuid}`
        }
        console.log(changeInsmsIntegrationData)
        try {
            editDigitalCampaigns(changeInsmsIntegrationData)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification("SMS Integration Of Digital Campaign is Edited Successfully")
                        setLoading(false);
                        mainProps.getDigitalCampaignDetails()
                        mainProps.close()
                    }
                    else {
                        showErrorNotification("Error Editing Digital Campaign Integration");
                        setLoading(false)
                        mainProps.getDigitalCampaignDetails();
                        mainProps.close()
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in Editing Digital Campaign Integration");
                    setLoading(false)
                    console.log(e)
                    mainProps.getDigitalCampaignDetails();
                    mainProps.close()
                });
        } catch (e) {
            showErrorNotification("Error in Editing Digital Campaign Integration");
            setLoading(false)
            mainProps.getDigitalCampaignDetails();
            mainProps.close()
        }
    }

    async function saveCampaignIntegration(values) {
        let data = {
            campaignId: mainProps.campaignId,
            clientId: `sms:${values.didOrUuid}`,
            integrationName: "SmsChat"
        }
        try {
            saveDigitalCampaigns(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification("SMS Integration Of Digital Campaign is Saved Successfully")
                        setLoading(false);
                        mainProps.getDigitalCampaignDetails()
                        mainProps.close()
                    }
                    else {
                        showErrorNotification("Error Saving Digital Campaign Integration");
                        setLoading(false)
                        mainProps.getDigitalCampaignDetails();
                        mainProps.close()
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in Saving Digital Campaign Integration");
                    setLoading(false)
                    console.log(e)
                    mainProps.getDigitalCampaignDetails();
                    mainProps.close()
                });
        } catch (e) {
            showErrorNotification("Error in Saving Digital Campaign Integration");
            setLoading(false)
            mainProps.getDigitalCampaignDetails();
            mainProps.close()
        }
    }

    const [loading, setLoading] = useState(false);

    const DataForm = () => {
        let didAlone = (smsIntegrationData !== undefined && smsIntegrationData !== null) ? smsIntegrationData.clientId.split(":") : null
        console.log(didAlone)
        didAlone = (didAlone !== undefined && didAlone !== null) ? didAlone[1] : null

        let initData = {
            didOrUuid: (didAlone !== undefined && didAlone !== null) ? didAlone : '',
        }

        console.log(initData)

        return (
            <div>
                <Formik
                    initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (mainProps.mode === "Add") {
                            saveCampaignIntegration(values)
                        }
                        if (mainProps.mode === "Edit") {
                            editDigitalCampaign(values)
                        }
                    }}
                >
                    {
                        (props) => (
                            <div>
                                <form
                                    onSubmit={props.handleSubmit}
                                    id="editSmsIntegrationForm"
                                    style={{
                                        width: "800px",
                                    }}
                                >
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <OZInput
                                            name="didOrUuid"
                                            placeholder="Search DID or UUID No."
                                            label="DID or UUID *"
                                        />
                                    </div>

                                </form>
                            </div>
                        )
                    }
                </Formik>
            </div>
        )
    }

    return (<>
        {loading ?
            (
                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                    <LoadingScreen />
                </div>
            ) :
            <OZDialog open={mainProps.open} onClose={mainProps.close} title={mainProps.data !== undefined ? "Edit SMS Configurations" : "SMS Integration"} saveButtonProps={{ form: 'editSmsIntegrationForm', type: 'submit' }} >
                <DataForm />
            </OZDialog>
        }
    </>
    )

}
export default AddAndEditDigitalCampaignSmsIntegration