import React, { useMemo } from "react";
import { Formik } from "formik";
import { Button } from '@mui/material';
import { getgroupById, updategroupById } from "../../../services/api-service";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import CustomAutoComplete from "../../../components/admin/common/AutoComplete/CustomAutoComplete";
import { deselectAllRows } from "../../../components/admin/common/OZDataGrid/GridUtil";


export default function AddAgentsToGroup(agentToGroupMenuProps) {

    let buttonStyle = useMemo(
        () => ({
            borderRadius: "0.5rem",
            height: "2.75rem",
            fontSize: "0.875rem",
            fontWeight: 400,
        }),
        []
    );

    return (
        <div >
            <Formik
                initialValues={agentToGroupMenuProps.initialData}
                onSubmit={(formValues) => {
                    let selectedAgentList = agentToGroupMenuProps.list.map(agentData => {
                        return { 'id': agentData.id, 'agentName': agentData.agentName, 'agentId': agentData.agentId }
                    })
                    getgroupById(formValues.groups.SeqID).then(resp => {
                        let groupDataById = resp.Data[0];
                        groupDataById.agents = [...groupDataById.agents, ...selectedAgentList]
                        const uniqueIds = []
                        const filteredSelectedAgents = groupDataById.agents.filter(agentData => {
                            const isDuplicate = uniqueIds.includes(agentData.id);
                            if (!isDuplicate) {
                                uniqueIds.push(agentData.id);
                                return true;
                            }
                            return false;
                        });
                        groupDataById.agents = filteredSelectedAgents
                        try {
                            updategroupById(groupDataById)
                                .then((resp) => {
                                    if (resp.Status === "Success") {
                                        agentToGroupMenuProps.handleClose()
                                        if (agentToGroupMenuProps?.handleCloseInUpdate) {
                                            agentToGroupMenuProps.handleCloseInUpdate()
                                        }
                                        showSuccessNotification(`AgentGroup: ${groupDataById.name} Updated Successfully`)
                                        deselectAllRows()
                                    }
                                    else {
                                        showErrorNotification(JSON.stringify(resp.Message.name || resp.Message.description || resp.Message));
                                        // showErrorNotification(JSON.stringify(resp.Message));
                                    }
                                })
                                .catch((e) => {
                                    console.log(e)
                                    showErrorNotification(JSON.stringify(e));
                                });
                        } catch (e) {
                            console.log(e)
                            showErrorNotification(JSON.stringify(e));
                        }
                    }).catch(e => {
                        console.log(e)
                        showErrorNotification("No group Data Available, Check your Internet")
                    })
                }}
            >
                {(formik) => (
                    <div>
                        <form
                            onSubmit={formik.handleSubmit}
                            id="addAgentGroupForm"
                            className="ozcwScrollbar oz-agent-to-group-form"
                        >
                            <CustomAutoComplete
                                name="groups"
                                labelHeader="Group Name"
                                placeholder="Group Name"
                                Datas={agentToGroupMenuProps.autoCompleteList}
                                mode={"Add"}
                                multiple={false}
                                optionLabelName={"GroupName"} />
                        </form>
                    </div>
                )}
            </Formik>
            <div className="oz-agent-to-group-form-buttons" >
                <Button
                    variant="outlined"
                    sx={{ ...buttonStyle, marginRight: '1.25rem' }}
                    onClick={agentToGroupMenuProps.handleClose}>
                    Cancel
                </Button>
                <Button form="addAgentGroupForm" variant="contained" sx={buttonStyle} type="submit">
                    ADD
                </Button>
            </div>
        </div>
    )
}