import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState, useMemo, useEffect } from 'react';
import { Button, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { MuiOtpInput } from 'mui-one-time-password-input';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactComponent as CloseIcon } from "./../../MFAicons/close.svg";
import { ReactComponent as Preview } from "./../../MFAicons/previousButton.svg";
import { ReactComponent as ForgotIcon } from "./../../MFAicons/forgotPassword.svg";
import { ReactComponent as PhoneOtpIcon } from "./../../MFAicons/phoneOtpIcon.svg";
import { ReactComponent as EmailOtpIcon } from "./../../MFAicons/emailOtpIcon.svg";


import "./OtpVerification.css"


const OtpVerification = (props) => {
    const [open, setOpen] = useState(props?.open);
    const [otp, setOtp] = useState("");
    const [seconds, setSeconds] = useState(30); 
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const email = props && props.email ? true : false;
    const phoneNumber = props?.phoneNumber || '';
    const firstPart = phoneNumber.slice(0, 2); // First 2 characters
    const lastPart = phoneNumber.slice(-3); // Last 3 digits or fewer if length < 5
    const middleMasked = phoneNumber.length > 5 ? "*".repeat(phoneNumber.length - 5) : "*".repeat(phoneNumber.length - 2);
    const maskedEmail = email && maskEmail(props.email);

    let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '44px', fontSize: '14px', fontWeight: 400, backgroundColor: '#3D8BF8' }), []);

    const handleClose = () => {
        setOpen(false);
        props.onClose();
    };

    const onClickPreviousButton = () => {

            setOpen(false);
            props.onClose();
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const handleChange = (newValue) => {
        setOtp(newValue)
        setError('')
    };

    const resendOTP = () => { // resend OTP 
        setOtp("");
        setError('')
        setSeconds(30);
        props.onClickResend();
    };

    const VerifyOTP = (e) => {
        setLoading(true);
        props.onClickVerify(otp, handleVerificationError);
        setLoading(false);
    }
    const handleVerificationError = () => {
        setError("Verification Failed try again with new OTP")
    }


    function maskEmail(email) {
        const [localPart, domain] = email.split('@'); // Split the email into local part and domain
        if (localPart.length <= 3) {
            // If the local part is 3 characters or less, mask the whole local part
            return `${'*'.repeat(localPart.length)}@${domain}`;
        }
        
        const visiblePart = localPart.slice(-3); // Keep the last 3 characters visible
        const maskedPart = '*'.repeat(localPart.length - 3); // Replace the rest with asterisks
        return `${maskedPart}${visiblePart}@${domain}`;
    }
    
    // Handle pressing "Enter" key to trigger OTP verification
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && otp.length === 6 && !loading) {
        VerifyOTP();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [otp, loading]);
  
  
    return (
        <div className='admin-change-password-form'>

            <Dialog
                open={open}
                onClose={handleClose}
                sx={{ zIndex: 10000 }}
            >
                <div className='oz-tb-otp-verification-container'>
                <Stack direction={'row'} display={'flex'}>
                    <div className='oz-tb-otp-main-header-container'>
                        <Button className='oz-tb-otp-verify-back-button'
                            onClick={onClickPreviousButton}
                            >
                            <Preview className='oz-tb-otp-verification-back-button-icon'/><label style={{marginLeft:"5px"}}>back</label>
                        </Button>
                        <label  className='oz-tb-otp-main-header-label'>Enter OTP</label>
                    </div>
                </Stack>
                <div className='oz-tb-otp-verify-lock-img'>
                    {/* <ForgotIcon /> */}
                    {props.otpType === "sms"? 
                    <div className='oz-tb-otp-icon-and-label'>
                    <PhoneOtpIcon />
                    <label> Please enter the 6 digit code </label>
                    <label>received on your phone number </label>
                      <label>{firstPart +middleMasked+lastPart}</label>
                    </div>

                    :
                    <div className='oz-tb-otp-icon-and-label'>
                    <EmailOtpIcon />
                    <div className='oz-tb-email-otp-verification-label'>
                    <label> Please enter the 6 digit code </label>
                    <label>received on your email </label>
                    <label>{maskedEmail} </label>
                    </div></div>
                    }
                </div>
                <div className="col-text-center">
                    <div className='oz-tb-otp-box' >
                        <MuiOtpInput length={6} TextFieldsProps={{ size: 'small', placeholder: '-', type: "password" }} value={otp} onChange={handleChange} />
                    </div>
                    <label className='oz-tb-otp-verify-error-msg'> {error}</label>
                    <div className="countdown-text" style={{ display: 'grid', direction: 'row', marginBottom: '10px' }} >
                       
                        <LoadingButton disabled={!otp || otp.length < 6} sx={buttonStyle} type="submit" size="large" onClick={ VerifyOTP } loading={loading} variant="contained">
                            Login
                        </LoadingButton>

                        <div className='oz-tb-otp-timer-label'>
                                  <label className='oz-tb-otp-not-yet-received-label'>Not received yet? </label> 
                                  {seconds > 0 ? 
                                   (
                                   <div className='oz-tb-otp-resend-timer-box'>
                                    <label className='oz-tb-otp-resend-otp-in-label'> Resend OTP in 0: {seconds < 10 ? `0${seconds}` : seconds} </label> 
                                    <label >  </label>
                                    </div>
                                    )
                                :
                                 (<Button
                                disabled={seconds > 0}
                                onClick={resendOTP}
                                sx={{ marginLeft: '0px', marginTop: '0px' ,height:"15px"}}
                            >
                                Resend Code
                            </Button>
                        )}
                        </div>
                    </div> 
                </div>

                </div>
            </Dialog>
        </div >
    )
}
export default OtpVerification