import React, { useMemo, useState, useEffect } from 'react';
import { Button, Stack, Typography, Grid, Tooltip, Menu, MenuItem, } from '@mui/material';
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { deletePlanById, getPlanById, getPlans } from "../../../services/api-service";
import AddPlans from "./AddPlansForm";
import './Plans.css';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import CopyPlanForm from './CopyPlanForm';

function Plans() {

  const [planForm, setPlanForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [planList, setPlanList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gridRef, setGridRef] = useState(null);

  useEffect(() => {
    getPlansList();
  }, []);

  let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400 }), []);

  const GetNameRenderer = (props) => {
    return (
      <Tooltip title={(props?.data?.name) ? props?.data?.name : ''}>
        <span style={{ color: "#3D8BF8", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", cursor: 'pointer' }}>{props.value}</span>
      </Tooltip>
    );
  };

  const DateRenderer = (props) => {
    const anchorRef = React.useRef();
    const [deleteMenuAnchor, setDeleteMenuAnchor] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openCopy, setOpenCopy] = useState(false);


    const onMorevertIconClick = () => {
      setTimeout(() => setDeleteMenuAnchor(anchorRef?.current), 1);
    };

    const handleCloseDeleteMenu = () => {
      setDeleteMenuAnchor(false);
    };

    function onClickDelete(e) {
      setDeleteMenuAnchor(false);
      setOpenDelete(true)
    }

    function onClickCopy(e) {
      setDeleteMenuAnchor(false);
      setOpenCopy(true)
    }

    const styleListItemButton = useMemo(
      () => ({
        "&.MuiButtonBase-root": {
          color: '#212121',
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          height: '16px',
          lineHeight: "14px",
        },
        '&:hover': {
          borderRadius: "4px",
        },
      }),
      []
    );

    const date = new Date(props.data.createdDate);
    const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

    return (
      <div className='oz-user-Status-container'>
        <label>{date.toLocaleString("en-GB", options)}</label>
        <img className="userMorevertIcon"
          onClick={onMorevertIconClick}
          src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
        >
        </img>
        <div ref={anchorRef}>
          <Menu anchorEl={deleteMenuAnchor} open={Boolean(deleteMenuAnchor)} onClose={handleCloseDeleteMenu} sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#FFFFFF",
              width: "133px",
              minHeight: "35px",
            },
            "& .MuiMenu-paper": {
              backgroundColor: "#FFFFFF",
              boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
              border: "0.0625rem solid #E6E5E6",
              borderRadius: "12px",
              marginLeft: "-0.3625rem",
              marginTop: "0rem",
            },
          }}>
            <MenuItem onClick={onClickDelete} sx={{ ...styleListItemButton, marginTop: "2px"}}>Delete</MenuItem>
            <MenuItem onClick={onClickCopy} sx={{ ...styleListItemButton, marginTop: "3px"}}>Make a Copy</MenuItem>
          </Menu>
          {openDelete && <DeleteDialog
            open={openDelete}
            onClose={() => { setOpenDelete(false) }}
            title={"Delete Plan"}
            content={ `This will delete (${props.data.name}) Plan permanently. You cannot undo this action.`}
            deleteFunction={() => deletePlan(props.data)} />}

          {openCopy && <CopyPlanForm
              props={props?.data}
              onClose={() => {setOpenCopy(false)}}
              updateFunction={getPlansList}
            />
          }
        </div>
      </div>
    );
  };

  const columnDefs = [
    { field: 'name', headerName: 'Plan Name ', enableRowGroup: false, cellRenderer: GetNameRenderer },
    { field: 'createdDate', headerName: 'Created Date', cellRenderer: DateRenderer },
  ];

  const showAddPlanForm = () => {
    setPlanForm(true);
    setMode(ADMIN_FORM_MODES.add);
  };

  const closeAddPlanForm = () => {
    setPlanForm(false);
  };

  async function onClickRow(rowItem) {
    setLoading(true);
    let id = rowItem.data.id;
    try {
      await getPlanById(id)
        .then((resp) => {
          if(resp?.Status === 'Success'){
            setSelectedPlan(resp?.Data|| {});
            setPlanForm(true);
            setMode(ADMIN_FORM_MODES.view)
          }else{
            setSelectedPlan({});
            showErrorNotification("Error getting Selected Plan");
          }
        })
        .catch((e) => {
          setSelectedPlan({});
          showErrorNotification("Error getting Selected Plan");
        });
    } catch (e) {
      showErrorNotification("Error getting Selected Plan");
      setSelectedPlan({});
    }
    setLoading(false);;
  }

  async function deletePlan(data) {
    setLoading(true);
    try {
      await deletePlanById(data?.id)
        .then((resp) => {
          if (resp?.Status === "Success") {
            showSuccessNotification(`Plan ${data.name} Deleted Successfully`)
            getPlansList();
          }
          else {
            showErrorNotification(resp?.Message?.name || resp?.Message?.description || resp?.Message || "Failed to delete the Plan");
          }
        })
        .catch((e) => {
          showErrorNotification("Failed to delete the Plan");

        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
    setLoading(false);
  }

  async function getPlansList() {
    setLoading(true);
    try {
      await getPlans()
        .then((resp) => {
          setPlanList(resp.Data);
        })
        .catch((e) => {
          setPlanList([]);
        });
    } catch (e) {
      showErrorNotification("Error in users list");
      setPlanList([]);
    }
    setLoading(false);
  };

  return (
    <div>
      {!planForm && (
        <div className="ozAdminUsersContainer">
          <Grid sx={{ marginBottom: "10px" }}>
            <Typography className='oz-page-header'> People </Typography>
          </Grid>
          <Stack direction="row" sx={{ marginBottom: "16px" }} justifyContent="space-between">
            <Typography className='oz-page-heading'> Plans </Typography>
            {/* {localStorage.getItem('parentUser') === 'null' &&
              <Button
                sx={buttonStyle}
                variant="contained"
                startIcon={<AddBoxOutlinedIcon />}
                onClick={showAddPlanForm}
              >
                Add Plan
              </Button>
            } */}
            <Button
              sx={buttonStyle}
              variant="contained"
              startIcon={<AddBoxOutlinedIcon />}
              onClick={showAddPlanForm}
            >
              Add Plan
            </Button>
          </Stack>
          <OZGridToolbar 
            title='List of Plans' 
            searchPlaceholder='Plan Name' 
            rowCount={planList.length} 
            dataGridRef={gridRef} 
            fileName='Plans' 
          // showFilters={true}
          // filtertProps={{ column: 'enabled', options: [{ Active: true }, { Deactivated: false }] }} 
          />
          <OZDataGrid height="26.3rem" columns={columnDefs} data={planList} rowClickCallback={onClickRow} setGridRef={setGridRef} />
          {loading &&
            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
              <LoadingScreen />
            </div>
          }
        </div >
      )}
      {planForm && (
        <>
          {loading ?
            (
              <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                <LoadingScreen />
              </div>
            ) :
            (
              <>
                <AddPlans hideAgent={false} onClose={closeAddPlanForm} mode={mode} updateFunction={getPlansList} rowData={selectedPlan} />
              </>
            )
          }
        </>
      )}
    </div>
  );
}
export default Plans;