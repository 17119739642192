import { Formik } from "formik"
import OZDialog from "../../../components/admin/common/OZDialog"
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen"
import { useMemo, useState } from "react"
import OZInput from "../../../components/admin/common/OZInput"
import * as yup from 'yup';
import { editDigitalCampaigns, saveDigitalCampaigns } from "../../../services/api-service"
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService"

const AddAndEditDigitalCampaignFacebookIntegration = (mainProps) => {

    console.log(mainProps.data)
    let facebookIntegrationData = []
    mainProps?.data?.map((item) => {
        if (item.integration.name === "FbChat") {
            facebookIntegrationData.push(item)
        }
    })
    facebookIntegrationData = mainProps?.data !== undefined ? facebookIntegrationData[0] : null
    console.log(facebookIntegrationData)

    const validationSchema = useMemo(
        () =>
            yup.object({
                clientId: yup
                    .string()
                    .required("FaceBook Page Id is Required"),
                authToken: yup
                    .string()
                    .required("Graph FB Token is Required")
            }),
        []
    );

    async function editDigitalCampaign(values) {
        setLoading(true);
        console.log(values);

        let changeInFacebookIntegrationData = {
            ...facebookIntegrationData,
            clientId: values.clientId,
            authToken: values.authToken
        }

        try {
            editDigitalCampaigns(changeInFacebookIntegrationData)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification("Facebook Integration Of Digital Campaign is Edited Successfully")
                        setLoading(false);
                        mainProps.getDigitalCampaignDetails()
                        mainProps.close()
                    }
                    else {
                        showErrorNotification("Error Editing Digital Campaign Integration");
                        setLoading(false)
                        mainProps.getDigitalCampaignDetails();
                        mainProps.close()
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in Editing Digital Campaign Integration");
                    setLoading(false)
                    console.log(e)
                    mainProps.getDigitalCampaignDetails();
                    mainProps.close()
                });
        } catch (e) {
            showErrorNotification("Error in Editing Digital Campaign Integration");
            setLoading(false)
            mainProps.getDigitalCampaignDetails();
            mainProps.close()
        }
    }

    async function saveCampaignIntegration(values) {
        let data = {
            campaignId: mainProps.campaignId,
            clientId: values.clientId,
            authToken: values.authToken,
            integrationName: "FbChat"
        }
        try {
            saveDigitalCampaigns(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification("Facebook Integration Of Digital Campaign is Saved Successfully")
                        setLoading(false);
                        mainProps.getDigitalCampaignDetails()
                        mainProps.close()
                    }
                    else {
                        showErrorNotification("Error Saving Digital Campaign Integration");
                        setLoading(false)
                        mainProps.getDigitalCampaignDetails();
                        mainProps.close()
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in Saving Digital Campaign Integration");
                    setLoading(false)
                    console.log(e)
                    mainProps.getDigitalCampaignDetails();
                    mainProps.close()
                });
        } catch (e) {
            showErrorNotification("Error in Saving Digital Campaign Integration");
            setLoading(false)
            mainProps.getDigitalCampaignDetails();
            mainProps.close()
        }
    }

    const [loading, setLoading] = useState(false);

    const DataForm = () => {
        let initData = {
            clientId: (facebookIntegrationData?.clientId !== undefined && facebookIntegrationData?.clientId !== null) ? facebookIntegrationData.clientId : '',
            authToken: (facebookIntegrationData?.authToken !== undefined && facebookIntegrationData?.authToken !== null) ? facebookIntegrationData.authToken : '',
        }

        console.log(initData)

        return (
            <div>
                <Formik
                    initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (mainProps.mode === "Add") {
                            saveCampaignIntegration(values)
                        }
                        if (mainProps.mode === "Edit") {
                            editDigitalCampaign(values)
                        }
                    }}
                >
                    {
                        (props) => (
                            <div>
                                <form
                                    onSubmit={props.handleSubmit}
                                    id="editFacebookIntegrationForm"
                                    style={{
                                        width: "800px",
                                    }}
                                >
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="clientId"
                                                placeholder="Facebook Page ID"
                                                label="Facebook Page ID *"
                                            />
                                        </div>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="authToken"
                                                placeholder="Graph FB Token"
                                                label="Graph FB Token *"
                                            />
                                            <div style={{ fontSize: "14px", color: '#8dcadf' }}>
                                                Example : https://graph.facebook.com/v2.6/me/messages?access_token=YOUR_ACCESS_TOKEN
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        )
                    }
                </Formik>
            </div>
        )
    }

    return (<>
        {loading ?
            (
                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                    <LoadingScreen />
                </div>
            ) :
            <OZDialog open={mainProps.open} onClose={mainProps.close} title={mainProps.data !== undefined ? "Edit Facebook Configurations" : "Facebook Integration"} saveButtonProps={{ form: 'editFacebookIntegrationForm', type: 'submit' }} >
                <DataForm />
            </OZDialog>
        }
    </>
    )

}
export default AddAndEditDigitalCampaignFacebookIntegration