import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Divider, Grid, Typography, TextField, Tooltip } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import OZDrawer from '../../../components/admin/common/OZDrawer/OZDrawer';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import OZRichText from '../../../components/admin/common/OZRichText/OZRichText';
import './Email.css'
import OZDialog from '../../../components/admin/common/OZDialog';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { addTemplate, deleteTemplateById, updateTemplate } from '../../../services/api-service';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';
import { ScrollToFieldError } from '../../../components/admin/common/ScrollToFieldError';

const EmailView = (props) => {
    const TemplateFieldsView = (props) => {
        return (
            <div>
                <label className='oz-template-view-value' dangerouslySetInnerHTML={{ __html: props.data.text }} />
                <label className='oz-template-view-label'>{props.label}</label>
                {
                    (props.data.length !== 0) ?
                        <label className='oz-template-view-value'>{props.data} </label> :
                        <label className='oz-template-view-value'> - </label>
                }
            </div>
        )
    }

    return (
        <div className="oz-Template-view-lablel-container">
            <label className='oz-template-view-label'>
                Template Text *
            </label>
            <label className='oz-template-view-value' dangerouslySetInnerHTML={{ __html: props.data.text }} />
            {props?.data?.templateId &&
                <TemplateFieldsView data={props?.data?.templateId} label={"Template ID *"} />}
            {props?.data?.entityId &&
                <TemplateFieldsView data={props?.data?.entityId} label={"Entity Id *"} />}
            {props?.data?.type &&
                <TemplateFieldsView data={props?.data?.type} label={"Type *"} />}
        </div>
    )
}
const EmailTemplateForm = (props) => {
    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add
    const validationSchema = useMemo(() => (yup.object({
        name: yup
            .string('Enter Name ')
            .min(3, "Template Name should not be empty and it should be between 3 to 50 alphanumeric characters long and allows special characters like _,spaces and Template Name cannot start and end with Special characters.")
            .max(50, "Template Name should not be empty and it should be between 3 to 50 alphanumeric characters long and allows special characters like _,spaces and Template Name cannot start and end with Special characters.")
            .matches(/^([A-Za-z0-9]((_| )?[A-Za-z0-9])+)$/, "Template Name should not be empty and it should be between 3 to 50 alphanumeric characters long and allows special characters like _,spaces and Template Name cannot start and end with Special characters.")
            .required('Template Name is required'),
        text: yup
            .string("Enter Subject")
            .matches(/^(?!\s)(?=\S)(.{1,500})(?<=\S)(?<!\s)$/, "Text should not be empty and allows max of 1200 characters.")
            .required('Text is required'),
        // entityId: yup
        //     .string("Enter EntityId")
        //     .required('EntityId is required'),
        // templateId: yup
        //     .string("Enter TemplateId")
        //     .required('TemplateId is required'),
    })), []);
    const [open, setState] = useState(true)
    const [emailTemplateFormMode, setEmailTemplateFormMode] = useState(initMode);
    const [emailTemplateanchorE1, setEmailTemplateAnchorE1] = React.useState(false);
    const anchorRef = React.useRef();
    const opens = Boolean(emailTemplateanchorE1);
    const [openDeleteDailog, setDeleteDialog] = useState(false)
    const [valid, setValid] = useState(true);

    const closeEmailTemplateForm = () => {
        props.onClose();
        setState(false)
    };

    const EditEmailTemplateForm = () => {
        setEmailTemplateFormMode(ADMIN_FORM_MODES.edit);
    };

    let buttonStyle = useMemo(
        () => ({
            borderRadius: "0.5rem",
            height: "2.75rem",
            fontSize: "0.875rem",
            fontWeight: 400,
        }),
        []
    );

    const morevertclick = () => {
        setTimeout(() => setEmailTemplateAnchorE1(anchorRef.current), 1);
    };

    const handleMenuClose = () => {
        setEmailTemplateAnchorE1(null);
    };

    function openDeleteDialog() {
        setEmailTemplateAnchorE1(null);
        setDeleteDialog(true)
    };

    const deleteEmailTemplate = () => {
        setEmailTemplateAnchorE1(null);
        deleteEmailById(props.data.id)
        setDeleteDialog(false)
    };

    async function deleteEmailById() {
        try {
            deleteTemplateById(props.data.id)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeEmailTemplateForm();
                        props.emailTemplate();
                        showSuccessNotification(resp.Message)

                    } else {
                        showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    const onClickDiscardButton = () => {
        setEmailTemplateFormMode(ADMIN_FORM_MODES.view);
    };

    async function addEmail(data) {
        data.type = "email"
        try {
            addTemplate(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification(`Template ${data.name} has been added Successfully`);
                        props.emailTemplate();
                        closeEmailTemplateForm();
                    }
                    else {
                        showErrorNotification(resp?.Message);
                        // showErrorNotification(JSON.stringify(resp.Message));
                    }

                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    async function updateEmail(data) {
        data.type = "email"
        try {
            updateTemplate(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeEmailTemplateForm();
                        props.emailTemplate();
                        showSuccessNotification(`Template ${data.name} has been updated Successfully`);
                    }
                    else {
                        showErrorNotification(resp?.Message);
                        // showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    const getValidationInViewMode = (e, value) => {
        props.data.name = value;
        const regex = /^([A-Za-z0-9]((_| )?[A-Za-z0-9])+)$/;
        const isMatch = regex.test(value);

        const digitCount = value?.length;

        // Check the conditions for validation
        const isValidLength = value.length >= 2 && value.length <= 50;
        const isValidDigitCount = digitCount >= 2 && digitCount <= 50;

        // Final validation check
        if (isMatch && isValidLength && isValidDigitCount) {
            setValid(true);
        } else {
            setValid(false);
        }
    }

    let styleEditButton = useMemo(() => ({
        fontWeight: '400',
        fontSize: '14px',
        color: '#3D8BF8',
    }), []
    );
    const styleListItemButton = useMemo(
        () => ({
            padding: "0px",
            alignSelf: "center",
            paddingLeft: "10px",
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "8px",
            },
        }),
        []
    );

    const DataForm = (props) => {
        let initData = props.data || {
            name: '',
            text: '',
            // entityId: '',
            // templateId: ''
        };
        let style1 = useMemo(() => ({
            width: '600px',
            height: "20rem",
            paddingtop: "10px"

        }), [])

        let style2 = useMemo(() => ({
            maxHeight: '76vh',
            overflowY: 'auto',
            padding: '5px'
        }), [])


        return (
            <div className='oz-email-add-form'>
                <Formik initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        let data = structuredClone(values);

                        let newParser = "";
                        const parser = new DOMParser();
                        const parsedHtml = parser.parseFromString(data?.text, 'text/html');
                        const textContent = parsedHtml.documentElement.textContent;
                        newParser = textContent.trim();

                        if (newParser === "" || newParser?.length > 500) {
                            showErrorNotification("Text should not be empty and allows max of 500 characters.");
                            return;
                        } else {
                            if (emailTemplateFormMode === ADMIN_FORM_MODES.add) {
                                addEmail(data);
                            }
                            if (emailTemplateFormMode !== ADMIN_FORM_MODES.add) {
                                if (valid === true) {
                                    updateEmail(data);
                                }
                            }
                        }
                    }}>
                    {props => (
                        <form onSubmit={props.handleSubmit} id='emailForm' className='ozcw-scrollbar' style={emailTemplateFormMode === ADMIN_FORM_MODES.add ? style1 :
                            style2} >
                            <Grid container spacing={1} >
                                <Grid container spacing={2} direction={'row'}>
                                    {emailTemplateFormMode === ADMIN_FORM_MODES.add && <Grid item xs={12} sm={6} md={4} lg={12} marginLeft='10px' marginTop={'35px'}>
                                        <OZInput
                                            name="name"
                                            label="Template Name*"
                                            placeholder="Name" />
                                    </Grid>}
                                </Grid>
                                {(emailTemplateFormMode === ADMIN_FORM_MODES.add || emailTemplateFormMode === ADMIN_FORM_MODES.edit) && (<Grid item xs={12} sm={12} md={12} lg={12} marginLeft="2px" marginTop={emailTemplateFormMode === ADMIN_FORM_MODES.add ? "3px" : "10px"} >
                                    <label style={{ fontSize: '12px' }} >Template Text *</label>
                                    <OZRichText
                                        label="From"
                                        name="text"
                                        placeholder="Name"
                                        height={emailTemplateFormMode === ADMIN_FORM_MODES.add ? "100px" : "300px"}
                                    />
                                </Grid>)}
                                {/* {(emailTemplateFormMode === ADMIN_FORM_MODES.add || emailTemplateFormMode === ADMIN_FORM_MODES.edit) && (
                                    <Grid container spacing={2} direction={'row'} >
                                        <Grid item xs={12} sm={6} md={4} lg={12} marginLeft={"9px"} marginTop="20px"  >
                                            <OZInput
                                                name="entityId"
                                                label="Entity ID *"
                                                placeholder="Entity ID" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={12} marginLeft={"9px"} >
                                            <OZInput
                                                name="templateId"
                                                label="Template ID *"
                                                placeholder="Template ID" />
                                        </Grid>
                                    </Grid>)} */}
                            </Grid>
                            <ScrollToFieldError />
                        </form>
                    )
                    }
                </Formik>
                {emailTemplateFormMode === ADMIN_FORM_MODES.edit && (
                    <div className='oz-emailtemplate-drawer-buttons'>
                        <Grid item marginRight={'10px'} >
                            <Button disabled={emailTemplateFormMode === ADMIN_FORM_MODES.view} sx={{ ...buttonStyle, marginLeft: "10px" }} variant='outlined' onClick={closeEmailTemplateForm}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item marginRight={'10px'}>
                            <Button
                                disabled={emailTemplateFormMode === ADMIN_FORM_MODES.view}

                                variant="contained"
                                type="submit"
                                sx={{ ...buttonStyle, width: "130px" }}
                                form='emailForm'
                            >
                                Save Changes
                            </Button></Grid>
                    </div>)}
            </div >
        )
    };
    return (
        <div>
            <div>
                {emailTemplateFormMode === ADMIN_FORM_MODES.add && (
                    <OZDialog
                        title="Template"
                        formType="template"
                        subTitle=" Create Email Template"
                        saveButtonProps={{
                            form: "emailForm",
                            type: 'submit',
                            label: 'save'
                        }}
                        open={open}
                        mode={emailTemplateFormMode}
                        onClose={closeEmailTemplateForm}>
                        <Divider style={{ color: "#E6E5E6" }}></Divider>
                        {emailTemplateFormMode === ADMIN_FORM_MODES.add && <DataForm />}
                    </OZDialog>
                )}
            </div>
            {emailTemplateFormMode !== ADMIN_FORM_MODES.add && (
                <OZDrawer
                    open={open}
                    formType='template'
                    title={
                        <div className='oz-template-drawer-textdeild-container'>
                            <div className='oz-template-drawer-title-container'>
                                {/* {emailTemplateFormMode !== ADMIN_FORM_MODES.edit && authPage(AdminPages.TEMPLATES_EDIT) && <img className='oz-template-view-morevert-img' ref={anchorRef}
                                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                    alt="edit"
                                    onClick={morevertclick}
                                ></img>} */}
                                {(emailTemplateFormMode !== ADMIN_FORM_MODES.edit &&(newAuthPages(NewAdminPages.TEMPLATES_PAGE) || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit'))) && <img className='oz-template-view-morevert-img' ref={anchorRef}
                                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                    alt="edit"
                                    onClick={morevertclick}
                                ></img>}
                                {emailTemplateFormMode !== ADMIN_FORM_MODES.edit &&
                                    <Tooltip title={props?.data?.name}>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '18px',
                                                alignSelf: 'center',
                                                // marginLeft: authPage(AdminPages.TEMPLATES_EDIT) ? "0px" : "22px",
                                                marginLeft: "0px",
                                                maxWidth: "400px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
                                            }}
                                        >
                                            {props?.data?.name}
                                        </Typography></Tooltip>}

                                {emailTemplateFormMode === ADMIN_FORM_MODES.edit &&
                                    <TextField
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: "none",
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                fontWeight: 600,
                                                fontSize: "18px",
                                                color: "#212121",
                                                borderRadius: '8px',
                                                backgroundColor: '#ffff',
                                                border: "1px solid #D6D6D7",
                                                height: '44px',
                                                '&:hover': {
                                                    border: "1px solid #D6D6D7"
                                                },
                                            },
                                        }}
                                        defaultValue={props?.data?.name}
                                        fullWidth
                                        // onChange={(e) => { props.data.name = e.target.value }}
                                        onChange={(e) => getValidationInViewMode(e, e.target.value)}
                                        error={!valid}
                                        helperText={!valid ? "Template Name should not be empty and it should be between 3 to 50 alphanumeric characters long and allows special characters like _,spaces and Template Name cannot start and end with Special characters." : ""}
                                    />
                                }
                                <div >
                                    <Menu
                                        anchorEl={emailTemplateanchorE1}
                                        open={opens}
                                        onClose={handleMenuClose}
                                        sx={{
                                            "& .MuiPaper-root": {
                                                backgroundColor: "white",
                                                height: "37px",
                                                width: "133px",
                                            },
                                            "& .MuiMenu-paper": {
                                                backgroundColor: "white",
                                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                                border: "0.0625rem solid #E6E5E6",
                                                borderRadius: "12px",
                                                marginLeft: "-8.9625rem",
                                                marginTop: '-1.5rem'
                                            },
                                        }}
                                    >
                                        <MenuItem sx={{ ...styleListItemButton }} disabled={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit')} onClick={openDeleteDialog}>Delete</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                            {openDeleteDailog &&
                                <DeleteDialog
                                    title={"Delete Email Template"}
                                    content={`This will delete (${props.data.name}) Email Template permanently. You cannot undo this action.`}
                                    onClose={() => { setDeleteDialog(false) }}
                                    open={openDeleteDailog}
                                    deleteFunction={deleteEmailTemplate}
                                />
                            }
                            <div className='oz-template-drawer-label-container'>
                                <Typography marginLeft={emailTemplateFormMode === ADMIN_FORM_MODES.edit ? "2px" : "23px"} marginBottom={emailTemplateFormMode === ADMIN_FORM_MODES.edit ? "0px" : "3px"} marginTop={emailTemplateFormMode === ADMIN_FORM_MODES.edit ? "5px" : "2px"} style={{ color: "#99A0A8", fontSize: '14px' }}> Email Template</Typography>
                                {emailTemplateFormMode === ADMIN_FORM_MODES.edit && (<Typography
                                    size="small"
                                    variant="text"
                                    sx={{
                                        ...styleEditButton,
                                        marginLeft: "408px",
                                        marginTop: "5px",
                                        position: "absolute",
                                        cursor: "pointer"
                                    }}
                                    onClick={onClickDiscardButton}
                                >
                                    Discard
                                </Typography>)}
                            </div>
                        </div>
                    }
                    onClose={closeEmailTemplateForm}
                    onEdit={EditEmailTemplateForm}
                    hideEditIcon={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'View')}
                    mode={emailTemplateFormMode}>
                    {emailTemplateFormMode !== ADMIN_FORM_MODES.edit && <EmailView data={props.data} />}
                    {emailTemplateFormMode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}
                </OZDrawer>
            )}
        </div>
    );
};
export default EmailTemplateForm;
