import { Typography, ListItemButton, Menu } from "@mui/material";
import React, { useState, useEffect, useMemo, useRef } from "react";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import { getMappings, deleteMappingById } from "../../../services/api-service";
import "./Mappings.css";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";

const Mappings = () => {
  const [mappingsGridData, setMappingsGridData] = useState(null)
  const [mappingsTableData, setMappingsTableData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getMapping();
  }, []);

  async function getMapping() {
    setLoading(true);
    try {
      getMappings()
        .then((resp) => {
          setMappingsTableData(resp.Data);
          setLoading(false);
        })
        .catch((e) => {
          setMappingsTableData({});
          setLoading(false);
        });
    } catch (e) {
      console.log("Error in Mappings list ");
      setLoading(false);
    }
  }

  function EditMappingRowOnMouseEnter(props) {
    const [mappingsAnchorE1, setMappingsAnchorE1] = useState(false);
    const anchorRef = useRef();
    const [openMappingDeleteDialog, setOpenMappingDeleteDialog] = useState(false)

    const onClickMoreVertIcon = () => {
      setTimeout(() => setMappingsAnchorE1(anchorRef?.current), 1);
    };

    const closeDeleteMenu = () => {
      setMappingsAnchorE1(false);
    };

    function onClickDelete() {
      setMappingsAnchorE1(false);
      setOpenMappingDeleteDialog(true)
    }

    const styleListItemButton = useMemo(
      () => ({
        padding: "0px",
        alignSelf: "center",
        paddingLeft: "10px",
        "&.MuiButtonBase-root": {
          display: "flex",
          color: '#212121',
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          height: '18px',
        },
        '&:hover': {
          borderRadius: "8px",
        },
      }),
      []
    );

    async function deleteMappingsById() {
      try {
        deleteMappingById(props.data.id)
          .then((resp) => {
            if (resp.Status === "Success") {
              getMapping();
              showSuccessNotification(resp.Message)
            } else {
              showErrorNotification(JSON.stringify(resp.Message));
            }
          })
          .catch((e) => {
            showErrorNotification(JSON.stringify(e));
          });
      } catch (e) {
        showErrorNotification(JSON.stringify(e));
      }
      setOpenMappingDeleteDialog(false)
    }

    return (
      <div className="oz-pause-reason-morevert-column-cell">
        <Typography sx={{ fontSize: "12px", fontWeight: 400, color: "#000000", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{props.value}</Typography>
        {newAuthPages(NewAdminPages.MAPPING || newAuthSubAdminAccess(NewAdminPages.MAPPING, 'Edit') ||newAuthSubAdminAccess(NewAdminPages.MAPPING, 'Full')) &&
        <img className="mappingsMorevertIcon"
          onClick={onClickMoreVertIcon}
          src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
        >
        </img>
        }
        <div ref={anchorRef}>
          <Menu anchorEl={mappingsAnchorE1} open={Boolean(mappingsAnchorE1)} onClose={closeDeleteMenu} sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#FFFFFF",
              width: "133px",
              height: "37px",
            },
            "& .MuiMenu-paper": {
              backgroundColor: "#FFFFFF",
              boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
              border: "0.0625rem solid #E6E5E6",
              borderRadius: "12px",
              marginLeft: "-1.0625rem",
            },
          }}>
            <ListItemButton
              onClick={onClickDelete}
              disabled={newAuthSubAdminAccess(NewAdminPages.MAPPING, 'Edit')}
              sx={styleListItemButton}>
              Delete
            </ListItemButton>
          </Menu>
          {openMappingDeleteDialog && <DeleteDialog
            open={openMappingDeleteDialog}
            onClose={() => { setOpenMappingDeleteDialog(false) }}
            title="Delete Mapping"
            content={`This will delete this Mapping: ${props.data.name} permanently. You cannot undo this action.`}
            deleteFunction={deleteMappingsById} />}
        </div>
      </div>
    );
  };

  const columnDefs = [
    {
      field: "name",
      headerName: "Name",
      maxWidth: '400',
    },
    {
      field: "value",
      headerName: "Mapping",
      cellRenderer: EditMappingRowOnMouseEnter,
    },
  ];

  return (
    <div className="ozAdminMappingContainer">
      <Typography
        sx={{
          width: "79px",
          height: "14px",
          fontSize: "12px",
          lineHeight: "14px",
          marginBottom: "10px",
          color: "#212121",
        }}
      >
        Configurations
      </Typography>
      <Typography
        sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}
      >
        Mappings
      </Typography>
      <OZGridToolbar
        modifyHeaderName={['Mapping']}
        modifyHeaderNameLocation={['B1']}
        title='List of Mappings'
        searchPlaceholder='Name'
        rowCount={mappingsTableData.length}
        dataGridRef={mappingsGridData}
        fileName='Mappings'
      />
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        (<>
          <OZDataGrid
            height="26.3rem"
            columns={columnDefs}
            data={mappingsTableData}
            setGridRef={setMappingsGridData}
          />
        </>
        )
      }
    </div>
  );
};

export default Mappings;
