import React, { useState, useMemo, useEffect } from "react";
import CustomSelectCampaign from "./CustomSelectCampaign";
import { Formik } from "formik";
import { Typography, Radio, FormControl, Button, Grid } from '@mui/material';
import { deselectAllRows, downloadSelectedData } from '../../../components/admin/common/OZDataGrid/GridUtil';
import OZInputBulkCampaign from '../../../components/admin/common/OZInputBulkCampaign';
import OZFCheckboxBulkCampaign from '../../../components/admin/common/OZFCheckboxBulkCampaign';
import { getSkillsDropdown, getdisposition, updateBulkCampaign } from '../../../services/api-service';
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomAutoCompleteBulkCampaign from "../../../components/admin/common/AutoComplete/CustomAutoCompleteBulkCampaign";
import { checkRoles } from "../../../utils/util";
import BulkCampaignDownloadDataForm from "./BulkCampaignDownloadDataForm";

export const BulkActionCampaign = (bulkOptionProps) => {

    const [bulkManagementList, setBulkManagementList] = useState([]);
    const [skillOption, setSkillOption] = useState([]);
    const [dispositionOption, setDispositionOption] = useState([]);
    const [dummy, setDummy] = useState([]);
    const [bulkDownloadDataOpen, setBulkDownloadDataOpen] = useState(false);
    const [idList, setIdList] = useState('');

    const basicTextStyle = useMemo(
        () => ({
            fontSize: "14px",
            fontWeight: "400",
            color: "#212121"
        }),
        []
    );

    const formButtonStyle = useMemo(
        () => ({
            borderRadius: '8px',
            fontWeight: '400',
            fontSize: '14px',
            height: '30px',
            marginTop: '20px',
        }),
        []
    );

    useEffect(() => {
        getSkillsOptions()
        getDispositionOptions()
    }, [])

    async function getSkillsOptions() {
        try {
            getSkillsDropdown().then(resp => {
                setSkillOption(resp.Data);
            }).catch(e => {
                showErrorNotification('No Skills Data, Check your Internet Connection');
                setSkillOption({});
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function getDispositionOptions() {
        try {
            getdisposition().then(resp => {
                setDispositionOption(resp.Data);
            }).catch(e => {
                showErrorNotification('No Dispostions Data, Check your Internet Connection');
                setDispositionOption({});
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function getBulkCampaignForm(data, type) {
        let newData = data;
        if (newData.sla === null) {
            delete newData.sla;
        }
        if (newData.dialInterval === null) {
            delete newData.dialInterval;
        }
        if (newData?.dispositions?.length === 0) {
            delete newData.dispositions;
        }
        if (newData?.skills?.length === 0) {
            delete newData.skills;
        }
        data = newData;
        try {
            updateBulkCampaign(data).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(resp.Message);
                    if (type === 'InBound') {
                        bulkOptionProps.updateBulkActions('InBound');
                    } else if (type === 'Preview' || type === 'Progressive' || type === 'IVR' || type === 'Predictive') {
                        bulkOptionProps.updateBulkActions('Outbound');
                    } else if (type === 'Chat' || type === 'PushData' || type === 'MissedCall' || type === 'SMS_OUT') {
                        bulkOptionProps.updateBulkActions('Marketing');
                    }
                    deselectAllRows();
                    setBulkManagementList([]);
                    setDummy([]);
                } else {
                    showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    const OpenSelectForm = (value) => {
        setDummy(prev => [...prev, value]);
        setBulkManagementList(prev => [...prev, x[value]]);
    }

    const downloadFile = (value) => {
        let fileType = value.toLowerCase();
        downloadSelectedData(fileType, "CampaignGroupList");
    }

    const onLinkClick = (e) => { //this is new added for going to the Tab
        bulkOptionProps.linkMain();
    }

    const handleDownloadClick = (list) => {
        const newidList = list?.reduce((acc, item, index) => {
            const campaignId = item?.campaignId?.toString();
            if (index === 0) {
                acc = campaignId;
            } else {
                acc += `,${campaignId}`;
            }
            return acc;
        }, {});

        setIdList(newidList);
        setBulkDownloadDataOpen(true);
        // getBulkCampaignData(idList);
    }

    //BulkCampaignManagement form

    const [radioValueSkills, setRadioValueSkills] = useState("Replace");
    const [radioValueDisp, setRadioValueDisp] = useState("Replace");

    const initData = {
        acwNac: false,
        skills: [],
        dispositions: [],
        dialInterval: null,
        STDEnabled: false,
        dialCustomer: false,
        dndEnable: false,
        offLineMode: false,
        dialByHoldingAgent: false,
        formatPhoneNumber: false,
        campaignIds: '',
        sla: null,
        skillBulkActionType: '',
        dispositionBulkActionType: '',
    };

    const deleteField = (name) => {
        if (x[name]) {
            if (bulkManagementList?.length >= 0) {
                setBulkManagementList((prev) => prev.filter(item => item !== x[name]))
                setDummy((prev) => prev.filter(item => item !== name))
            } else {
                setBulkManagementList([])
                deselectAllRows()
                setDummy([])
            }
        }
    }

    const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
        ({ theme, checked }) => ({
            '.MuiFormControlLabel-label': checked && {
                color: '#3D8BF8',
            },
        }),
    );

    const closeMenuPopButton = (e, buttonType) => {
        if (buttonType === 'download') {
            setBulkDownloadDataOpen(false);
        }
    }

    function MyFormControlLabel(props) {
        const radioGroup = useRadioGroup();

        let checked = false;

        if (radioGroup) {
            checked = radioGroup.value === props.value;
        }

        return <StyledFormControlLabel checked={checked} {...props} />;
    }

    MyFormControlLabel.propTypes = {
        /**
         * The value of the component.
         */
        value: PropTypes.any,
    };

    const handleRadioValue = (e, type) => {
        if (type === 'skill') {
            setRadioValueSkills(e.target.value);
        } else if (type === 'disposition') {
            setRadioValueDisp(e.target.value);
        }
    }

    const OfflineMode =
        <div style={{ marginTop: '30px', paddingRight: '8px', paddingLeft: '8px' }}>
            <OZFCheckboxBulkCampaign
                name="offLineMode"
                label="Offline Mode"
                labelPosition="end"
                onDeleteIcon={(name) => deleteField(name)}
            />
        </div>

    const acwForUnansweredCalls =
        <div style={{ marginTop: '30px', paddingRight: '8px', paddingLeft: '8px' }}>
            <OZFCheckboxBulkCampaign
                name="acwNac"
                label="ACW for Unanswered calls"
                labelPosition="end"
                onDeleteIcon={(name) => deleteField(name)}
            />
        </div>

    const stdEnabled =
        <div style={{ marginTop: '30px', paddingRight: '8px', paddingLeft: '8px' }}>
            <OZFCheckboxBulkCampaign
                name="STDEnabled"
                label="STD Enabled"
                labelPosition="end"
                onDeleteIcon={(name) => deleteField(name)}
            />
        </div>

    const dialCustomerFirst =
        <div style={{ marginTop: '30px', paddingRight: '8px', paddingLeft: '8px' }}>
            <OZFCheckboxBulkCampaign
                name="dialCustomer"
                label="Dial Customer First"
                labelPosition="end"
                onDeleteIcon={(name) => deleteField(name)}
            />
        </div>

    const dialbyholdingAgent =
        <div style={{ marginTop: '30px', paddingRight: '8px', paddingLeft: '8px' }}>
            <OZFCheckboxBulkCampaign
                name="dialByHoldingAgent"
                label="Dial by holding agent"
                labelPosition="end"
                onDeleteIcon={(name) => deleteField(name)}
            />
        </div>

    const dndEnable =
        <div style={{ marginTop: '30px', paddingRight: '8px', paddingLeft: '8px' }}>
            <OZFCheckboxBulkCampaign
                name="dndEnable"
                label="DND Enable"
                labelPosition="end"
                onDeleteIcon={(name) => deleteField(name)}
            />
        </div>

    const dialInterval =
        <div style={{ marginTop: '10px', paddingRight: '8px', paddingLeft: '8px' }}>
            <OZInputBulkCampaign
                name="dialInterval"
                label="Dial Interval (in Sec)"
                placeholder="Dial Interval (in Sec)"
                onDeleteIcon={(name) => deleteField(name)}
            />
        </div>

    const wrapupTime =
        <div style={{ marginTop: '10px', paddingRight: '8px', paddingLeft: '8px' }}>
            <OZInputBulkCampaign
                name="sla"
                label="Wrapup Time (in sec)"
                placeholder="Wrapup Time (in sec)"
                onDeleteIcon={(name) => deleteField(name)}
            />
        </div>

    const dispositions =
        <div style={{ paddingRight: '8px', paddingLeft: '8px' }}>
            <div style={{ width: '326px', marginTop: '-16px' }}>
                <div style={{ display: 'flex', alignContent: 'center' }}>
                    <Typography sx={{
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '12px',
                        color: '#99A0A8',
                        paddingTop: '23px',
                        // paddingTop: '20px',
                    }} flexGrow={1} > Dispositions</Typography>
                    <FormControl style={{ paddingTop: '8px' }}>
                        <RadioGroup
                            defaultValue={radioValueDisp}
                            onChange={(e) => handleRadioValue(e, 'disposition')}
                        >
                            <div style={{ display: 'flex', alignContent: 'center' }}>
                                <MyFormControlLabel
                                    flexGrow={1}
                                    value='Replace'
                                    control={<Radio size='small' />}
                                    label="Replace"
                                />
                                <MyFormControlLabel
                                    value="Merge"
                                    control={<Radio size='small' />}
                                    label='Merge'
                                />
                            </div>
                        </RadioGroup>
                    </FormControl>
                </div>
                <CustomAutoCompleteBulkCampaign
                    mode='Add'
                    limitTags={2}
                    name="dispositions"
                    currentLabel='Dispositions'
                    placeholder={"Select Disposition"}
                    Datas={dispositionOption}
                    optionLabelName='reason'
                    showCheckBox={true}
                    multiple={true}
                    showselectall="true"
                    onDeleteIcon={(name) => deleteField(name)}
                />
            </div>
        </div>

    const skills =
        <div style={{ paddingRight: '8px', paddingLeft: '8px' }}>
            <div style={{ width: '326px', marginTop: '-16px' }}>
                <div style={{ display: 'flex', alignContent: 'center' }}>
                    <Typography sx={{
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '12px',
                        color: '#99A0A8',
                        paddingTop: '23px',
                        // paddingTop: '20px',
                    }} flexGrow={1} > Skills</Typography>
                    <FormControl style={{ paddingTop: '8px' }}>
                        <RadioGroup
                            defaultValue={radioValueSkills}
                            onChange={(e) => handleRadioValue(e, 'skill')}
                        >
                            <div style={{ display: 'flex', alignContent: 'center' }}>
                                <MyFormControlLabel
                                    flexGrow={1}
                                    value='Replace'
                                    control={<Radio size='small' />}
                                    label="Replace"
                                />
                                <MyFormControlLabel
                                    value="Merge"
                                    control={<Radio size='small' />}
                                    label='Merge'
                                />
                            </div>
                        </RadioGroup>
                    </FormControl>
                </div>
                <CustomAutoCompleteBulkCampaign
                    mode='Add'
                    limitTags={2}
                    name="skills"
                    currentLabel='Skills'
                    placeholder={"Select Skills"}
                    Datas={skillOption}
                    optionLabelName='skillName'
                    showCheckBox={true}
                    multiple={true}
                    showselectall="true"
                    onDeleteIcon={(name) => deleteField(name)}
                />
            </div>
        </div>

    let x = {
        'Offline Mode': (!dummy.includes("Offline Mode")) ? OfflineMode : null, 'ACW for Unanswered calls': (!dummy.includes("ACW for Unanswered calls")) ? acwForUnansweredCalls : null, 'STD Enabled': (!dummy.includes("STD Enabled")) ? stdEnabled : null, 'Dial Customer First': (!dummy.includes("Dial Customer First")) ? dialCustomerFirst : null,
        'Dial by holding agent': (!dummy.includes("Dial by holding agent")) ? dialbyholdingAgent : null, 'DND Enable': (!dummy.includes("DND Enable")) ? dndEnable : null, 'Dial Interval (in Sec)': (!dummy.includes("Dial Interval (in Sec)")) ? dialInterval : null, 'Wrapup Time (in sec)': (!dummy.includes("Wrapup Time (in sec)")) ? wrapupTime : null,
        'Dispositions': (!dummy.includes("Dispositions")) ? dispositions : null, 'Skills': (!dummy.includes("Skills")) ? skills : null
    }

    return (
        <div>
            <div>
                {bulkOptionProps?.list?.length > 0 && (
                    <div style={{ display: "flex", alignItems: 'center', gap: 16 }}>
                        <Typography sx={{
                            fontSize: "12px",
                            fontWeight: 400,
                            color: '#000000',
                            paddingTop: '25px',
                        }}>{bulkOptionProps?.list?.length} Campaigns Selected</Typography>
                        <CustomSelectCampaign
                            height='30px'
                            // width='98px'
                            fontSize='12px'
                            border='1px solid #99A0A8'
                            name="export"
                            placeholder='Export'
                            options={['CSV', 'Excel', 'PDF']}
                            onChange={(value) => { downloadFile(value) }}
                        />
                        <CustomSelectCampaign
                            height='30px'
                            // width='119px'
                            fontSize='12px'
                            border='1px solid #99A0A8'
                            name="actions"
                            placeholder='Actions'
                            options={['Offline Mode', 'ACW for Unanswered calls', 'STD Enabled', 'Wrapup Time (in sec)', 'Dispositions', 'Skills', checkRoles("ROLE_DND_CHECK") && 'DND Enable', bulkOptionProps?.campaignPage !== "Inbound" && 'Dial Customer First', bulkOptionProps?.campaignPage !== "Inbound" && 'Dial by holding agent', bulkOptionProps?.campaignPage !== "Inbound" && 'Dial Interval (in Sec)']}
                            onChange={(value) => { OpenSelectForm(value) }}
                        />
                        <Typography onClick={() => { deselectAllRows(); setBulkManagementList([]); setDummy([]) }}
                            sx={{
                                ...basicTextStyle, paddingLeft: '5px', paddingTop: '25px',
                                color: '#536580', cursor: "pointer"
                            }}
                            flexGrow={1}> Clear All</Typography>
                        {bulkOptionProps?.campaignPage === 'Outbound' && bulkOptionProps?.list?.length > 1 &&
                            <Button style={formButtonStyle} variant="contained" onClick={() => handleDownloadClick(bulkOptionProps?.list)}>
                                Download Data
                            </Button>
                        }
                    </div>
                )}
            </div>
            {bulkManagementList?.length > 0 && (
                <div style={{ marginTop: '15px' }}>
                    <Formik
                        initialValues={initData}
                        onSubmit={(values) => {
                            let type = '';
                            bulkOptionProps.list.map((item, index) => {
                                if (item.campaignId) {
                                    values.campaignIds += item.campaignId + ',';
                                }
                                if (item.campaignType) {
                                    type = item.campaignType;
                                }
                            })
                            values.formatPhoneNumber = values.STDEnabled;
                            values.campaignIds = values.campaignIds.slice(0, -1);
                            if (radioValueDisp === 'Replace') {
                                values.dispositionBulkActionType = 'replace';
                            } else if (radioValueDisp === 'Merge') {
                                values.dispositionBulkActionType = 'merge';
                            }
                            if (radioValueSkills === 'Replace') {
                                values.skillBulkActionType = 'replace';
                            } else if (radioValueSkills === 'Merge') {
                                values.skillBulkActionType = 'merge';
                            }
                            getBulkCampaignForm(values, type);
                        }}
                    >
                        {(formik) => (
                            <form
                                onSubmit={formik.handleSubmit} id="bulkCampaign-Form" className='ozcw-scrollbar' style={{
                                    maxHeight: 'auto',
                                    overflowY: 'auto',
                                    // padding: '5px'
                                }}>

                                <div style={{ minHeight: '50px', borderRadius: '8px', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#FFFFFF', paddingBottom: '10px', alignContent: 'center', display: 'flex', flexWrap: 'wrap' }}>
                                    <Grid container direction={"row"} minWidth="82%" maxWidth={"82%"}>
                                        {(bulkManagementList?.length > 0) ? (
                                            bulkManagementList.map((item, i) => {
                                                return item
                                            })
                                        ) : null
                                        }
                                    </Grid>

                                    <div style={{ marginTop: '17px', position: 'absolute', right: 0, marginRight: '-45px', minWidth: "18%", maxWidth: "18%" }}>
                                        <Button style={{ fontWeight: '400', fontSize: '14px', color: '#3D8BF8', backgroundColor: ' #FFFFFF', borderRadius: '8px', width: '73px' }} variant='outlined' onClick={() => { deselectAllRows(); setBulkManagementList([]); setDummy([]) }}>
                                            Cancel
                                        </Button>
                                        <Button style={{ fontWeight: '400', fontSize: '14px', color: '#FFFFFF', backgroundColor: ' #3D8BF8', borderRadius: '8px', marginLeft: '10px', width: '78px' }} variant="contained" type="submit" form="bulkCampaign-Form">
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div >
            )}

            {bulkDownloadDataOpen && (
                <BulkCampaignDownloadDataForm
                    onClose={(e) => closeMenuPopButton(e, 'download')}
                    open={true}
                    list={idList}
                    multipleCampaign
                    link={(e) => {setBulkDownloadDataOpen(false);closeMenuPopButton(e, 'download'); onLinkClick(e) }}
                />
            )}
        </div >
    )
}
