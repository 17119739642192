import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import { ReactComponent as RefreshOutlinedIcon } from '../../../static/icons/refresh.svg';
import { Badge, Box, Card, Chip, Grid, Stack } from '@mui/material';
import * as React from 'react';
import { getDataSummary } from '../../../services/api-service';
import { getLastFetchedDetail } from '../../../services/application-service';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import '../AgentPerformance/AgentPerformance.css';

let interval: any;

function DataSummary(props: any) {
	const { useState, useRef } = React;
	const downloadContent = useRef<null | HTMLElement>(null);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [dataSummaryFilter, setDataSummaryFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || '3');
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [lastFetchedTime, setLastFetchedTime] = useState<any>('00:00');

	const clearFilter = () => {
		setDataSummaryFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = dataSummaryFilter.filter((item: any) => item.id !== id);
		setDataSummaryFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const filterItems = dataSummaryFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const retrieveTableData = async (filters = '0') => {
		const rows: any[] = await getDataSummary({
			filters
		});
		for (const row of rows) {
			const skills = row.SkillsAssigned?.split(',') || [];
			if (skills.length > 3) {
				row.skillsFormatted = {
					value: skills.slice(0, 3).join(','),
					badge: `+${skills.length - 3}`
				};
			} else {
				row.skillsFormatted = skills.join(',');
			}
		}
		const columns = [
			{ name: 'Campaign name', key: 'CampaignName', align: 'left' },
			{ name: 'Total Calls', key: 'TotalCalls', align: 'left' },
			{ name: '0-3', key: '0-3mins', align: 'left' },
			{ name: '3-5', key: '3-5mins', align: 'left' },
			{ name: '5-10', key: '5-10mins', align: 'left' },
			{ name: '10-15', key: '10-15mins', align: 'left' },
			{ name: '15-30', key: '15-30mins', align: 'left' },
			{ name: '30-60', key: '30-60mins', align: 'left' },
			{ name: 'Above 60 mins', key: 'Above 60mins', align: 'left' }
		];
		setRowData(rows);
		setColumnData(columns);
		setLastFetchedTime(getLastFetchedDetail());
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const toggleRefresh = () => {
		const filterIds = dataSummaryFilter.length ? dataSummaryFilter.map((item: any) => item.id).join(',') : '0';
		retrieveTableData(filterIds);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const handleFilterExistingData = (filteredData: any) => {
		setDataSummaryFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setDataSummaryFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);
	React.useEffect(() => {
		const filterIds = (dataSummaryFilter.length && !(responseLength === dataSummaryFilter.length)) ? dataSummaryFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = dataSummaryFilter.length ? dataSummaryFilter.map((item: any) => item.id).join(',') : '0';
		retrieveTableData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		// interval = setInterval(() => retrieveTableData(filterIds), WIDGET_REFRESH_INTERVAL.CSAT);
	}, [dataSummaryFilter]);

	return (
		<>
			<Card className="customCard">
				<div className="card-header">
					<div className="card-title">
						Data Summary
						<span className="card-title-badge">Live</span>
						<div className="refresh-btn card-icon" onClick={() => toggleRefresh()}>
							<RefreshOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</div>
						<span className="last-fetched-time">Last fetched at: {lastFetchedTime} </span>
					</div>
					<div className="card-actions">
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} xlsx rowData={rowData} columns={columnData} name={'Data Summary'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={dataSummaryFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
					</div>
				</div>
				<Box sx={{ width: '100%', typography: 'body1' }}>
					{dataSummaryFilter.length > 0 && (
						<Box className="customTabPanel">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className="filter-outer">
										<div className="filter-container">
											<Stack direction="row" spacing={1}>
												{filterItems}
											</Stack>
										</div>
										<div className="filter-remove" onClick={clearFilter}>
											Remove
										</div>
									</div>
								</Grid>
							</Grid>
						</Box>
					)}
					<OZTable downloadContent={downloadContent} columns={columnData} rows={rowData}></OZTable>
				</Box>
			</Card>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} allowedFilters={['Campaign']} existingSelectedFilters={dataSummaryFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/>
		</>
	);
}
export default DataSummary;
