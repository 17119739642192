import * as React from 'react';
import { Link } from 'react-router-dom';
import '../Login/Login.css';

function ContactAdmin() {
	return (
		<>
			<div className="login_container">
				<div className="login-left">
					<img src={`${process.env.PUBLIC_URL}/img/ozonetel-logo.svg`} alt="Ozonetel"></img>
					<div className="login-title">Modernize your Call Center Software today!</div>
					<div className="login-sub-title">Enterprise-grade contact center suite built for fast-growing businesses</div>
				</div>
				<div className="login-right">
					<div className="forget-password-bx">
						<div className="login-form-title text-center">Reset Password</div>
						<img className="mt-88 mb-26" src={`${process.env.PUBLIC_URL}/img/contact-admin.svg`} alt="Contact Admin"></img>

						<p className="grey_p mb-96">
							Your email account not authenticated. <br />
							please contact administrator.
						</p>

						<span className="know-cred">
							I know the login credentials. <Link to="/login">Go to Login</Link>
						</span>
					</div>
				</div>
			</div>
		</>
	);
}

export default ContactAdmin;
