import { Button, Grid } from "@mui/material";
import { FieldArray } from "formik";
import { useState } from "react";
import CustomAutoComplete from "../../../components/admin/common/AutoComplete/CustomAutoComplete";
import OZInput from "../../../components/admin/common/OZInput";
import { isSubUser } from "../../../utils/util";
import CustomAutoCompleteSubAdmin from "../../../components/admin/common/AutoComplete/CustomAutoCompleteSubAdmin.jsx";


export function AgentSkillList(skillListProps) {
    const [skillListValues, setskillListValues] = useState(() => {
        if (skillListProps.autoCompleteMode === "Add") {
            return [skillListProps.skillData]
        }
        else {
            let previousSelectedSkillLists = structuredClone(skillListProps.formikValues.values.skills);
            let selectedSkills = previousSelectedSkillLists.map((skill) => (
                skill.skillList.map((item) => {
                    return (item);
                })
            )).flat(1);
            if (skillListProps?.formikValues?.values?.skills.length > 0) {
                return skillListProps?.formikValues?.values?.skills.map((skills) => {
                    let newOptions = skillListProps.skillData.filter(skill => selectedSkills.every(selectedSkill => selectedSkill.id !== skill.id));
                    return [...skills.skillList, ...newOptions,]
                })
            }
            else {
                return []
            }
        }
    });

    // eslint-disable-next-line no-unused-vars
    const [previousSelectedSkillList, setPreviousSelectedSkillList] = useState([])

    function handleChangeSkills(index, selected, createNewSkilllist, removedOption, deleteSkillLsit, isRemoveSkillList) {

        let previousSelectedSkillLists = structuredClone(skillListProps.formikValues.values.skills);
        let selectedSkills = previousSelectedSkillLists.map((skill) => (
            skill.skillList.map((item) => {
                return (item);
            })
        )).flat(1);

        if (selected.length > 0) {
            selectedSkills = [selectedSkills, selected].flat(1)
        }
        let newOptions = skillListProps.skillData.filter(skill => selectedSkills.every(selectedSkill => selectedSkill.id !== skill.id));
        if (createNewSkilllist === "removeOption") {
            newOptions = [...newOptions, ...removedOption]
        }
        let localSkillListValues = structuredClone(skillListValues)
        if (createNewSkilllist) {
            localSkillListValues = [...localSkillListValues, newOptions]
        }
        let newSkilllist = localSkillListValues.map((singleSkillList, subindex) => {
            if (index === subindex) {
                return singleSkillList;
            }
            else {
                if (previousSelectedSkillLists[subindex] === undefined) {
                    return newOptions
                } else {
                    return [previousSelectedSkillLists[subindex].skillList, newOptions].flat(1);
                }
            }
        })
        if (isRemoveSkillList) {
            newSkilllist.splice(index, 1)
        }
        setskillListValues(newSkilllist);
        setPreviousSelectedSkillList(selectedSkills);
    };



    return (
        <FieldArray name="skills">
            {({ replace, remove, push }) => (
                <div>
                    {skillListProps.formikValues.values.skills.length > 0 && skillListProps.formikValues.values.skills.map((skill, index) => (
                        <div key={index}>
                            <Grid container spacing={2} marginBottom='15px'  >
                                <Grid item xs={skillListProps.autoCompleteMode === "Add" ? 0.8 : 1.78}    >
                                    <OZInput
                                        name={`skills.${index}.priority`}
                                        label="Priority"
                                        type="text"
                                        placeholder="1"
                                    />
                                </Grid>
                                <Grid item xs={skillListProps.autoCompleteMode === "Add" ? "" : 9.5} style={{ width: "341px" }} >
                                    {(isSubUser() && skillListProps.autoCompleteMode !== "Add") ? <CustomAutoCompleteSubAdmin
                                        name={`skills.${index}.skillList`}
                                        labelHeader="Agent’s skills"
                                        placeholder={skillListProps.autoCompletePlaceHolder}
                                        selectedValues={skillListProps.autoCompleteMode === "Add" ? "" : skill.skillList}
                                        Datas={skillListValues[index]?.length > 0 ? skillListValues[index] : []}
                                        subAdminApiList={skillListProps.skillData}
                                        mode={skillListProps.autoCompleteMode}
                                        optionLabelName={"skillName"}
                                        showCheckBox={true}
                                        multiple={true}
                                        showselectall={skillListValues[index]?.length > 1 ? "true" : false}
                                        autocompleteTextFieldWidth={"341px"}
                                        limitTags={2}
                                        autoCompleteWidth={"321px"}
                                        onChangeValue={{ "handleChangeDatasList": handleChangeSkills, "index": index, "createNewDatasList": false }}
                                        disableOptionsNotInDatas={true}
                                        removeDuplicates={true}
                                    />
                                        : <CustomAutoComplete
                                            name={`skills.${index}.skillList`}
                                            labelHeader="Agent’s skills"
                                            placeholder={skillListProps.autoCompletePlaceHolder}
                                            selectedValues={skillListProps.autoCompleteMode === "Add" ? "" : skill.skillList}
                                            Datas={skillListValues[index]?.length > 0 ? skillListValues[index] : []}
                                            mode={skillListProps.autoCompleteMode}
                                            optionLabelName={"skillName"}
                                            showCheckBox={true}
                                            multiple={true}
                                            showselectall={skillListValues[index]?.length > 1 ? "true" : false}
                                            autocompleteTextFieldWidth={"341px"}
                                            limitTags={2}
                                            autoCompleteWidth={"321px"}
                                            onChangeValue={{ "handleChangeDatasList": handleChangeSkills, "index": index, "createNewDatasList": false }}
                                        />
                                    }
                                </Grid>
                                {skillListProps.formikValues.values.skills.length > 1 && index > 0 &&
                                    <div className='oz-agent-form-deleteIcon-container'>
                                        <img onClick={() => {
                                            remove(index);
                                            handleChangeSkills(index, [], "removeOption", skillListProps.formikValues.values.skills[index].skillList.length > 0 ? skillListProps.formikValues.values.skills[index].skillList : [], true)
                                        }}
                                            src={`${process.env.PUBLIC_URL}/icons/deleteIconGrey.svg`}
                                            alt="remove"
                                        ></img>
                                    </div>
                                }
                            </Grid>

                            {(skillListProps.formikValues.values.skills.length === (index + 1)) &&
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        variant="text"
                                        sx={{
                                            fontSize: "14px", fontWeight: 400,
                                            color: skillListProps.mode === 'Create' ? '#536580' : '#3D8BF8',
                                            padding: "0px 0px 0px 0px",
                                            marginLeft: "1px"
                                        }}
                                        onClick={() => {
                                            push({ priority: '1', skillList: [] });
                                            handleChangeSkills(index, [], true);
                                        }}
                                    >
                                        <img style={{ paddingRight: "6px", paddingBottom: "3px" }}
                                            src={skillListProps.mode === 'Create' ? `${process.env.PUBLIC_URL}/icons/anotherlevel.svg` : `${process.env.PUBLIC_URL}/icons/anotherlevelblue.svg`}
                                            alt="edit"
                                        ></img>
                                        Another level</Button>
                                </div>}
                        </div>
                    ))
                    }
                </div>
            )}
        </FieldArray>
    )
}

