import { Button, Grid, ListItemButton, Menu, Typography, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import { useMemo, useState, useRef } from "react";
import * as yup from "yup";
import OZDialog from "../../../components/admin/common/OZDialog";
import OZDrawer from "../../../components/admin/common/OZDrawer/OZDrawer";
import OZFCheckbox from "../../../components/admin/common/OZFCheckbox";
import OZInput from "../../../components/admin/common/OZInput";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import { addDialoutNumbers, addDialoutNumbersById, deleteDialoutNumbersById } from "../../../services/api-service";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import "./manageNumber.css"
import { CustomViewCheckBox } from "./ManageNumbers";
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";

function ViewDialoutNumberForm(viewDialoutNumberFormProps) {
  let styleFieldHeader = useMemo(
    () => ({
      fontSize: "0.75rem",
      height: '0.875rem',
      color: '#99A0A8',
      lineHeight: "0.875rem",
    }),
    []
  );

  let styleFieldValue = useMemo(
    () => ({
      height: '1rem',
      fontWeight: 400,
      fontSize: "0.875rem",
      color: '#212121',
      lineHeight: "1rem",
    }),
    []
  );

  return (
    <div>
      <Grid container={true} marginTop="1.5rem" marginLeft="0.625rem">
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ ...styleFieldHeader, width: "5.5625rem", }}>
          Dialout Number *
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ ...styleFieldValue, width: "6.625rem", marginTop: '0.5rem' }} >
          {viewDialoutNumberFormProps.viewData.dialOutNumber}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ ...styleFieldHeader, marginTop: '1.1875rem', marginLeft: "2px" }}>
          <CustomViewCheckBox checked={viewDialoutNumberFormProps.viewData.sip || false} label="SIP" />
        </Grid>
      </Grid>
    </div>
  );
}

const AddDialoutNumberForm = (dialOutNumberFormProps) => {
  let initMode = dialOutNumberFormProps.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add;
  const [openDialoutNumberForm, setOpenDialoutNumberForm] = useState(true);
  const [dialoutNumberFormMode, setDialoutNumberFormMode] = useState(initMode);
  const [viewMoreVertMenuAnchro, setViewMoreVertMenuAnchor] = useState(false);
  const [openDelete, setopenDelete] = useState(false)
  const anchorRef = useRef();
  const [valid, setValid] = useState(true);

  const handleCloseMoreVertMenu = () => {
    setViewMoreVertMenuAnchor(false);
  };

  function onClickDelete() {
    setViewMoreVertMenuAnchor(false);
    setopenDelete(true)
  }

  const onClickMoreVertIcon = () => {
    setTimeout(() => setViewMoreVertMenuAnchor(anchorRef?.current), 1);
  };

  let buttonStyle = useMemo(
    () => ({
      borderRadius: "0.5rem",
      height: "2.75rem",
      fontSize: "0.875rem",
      fontWeight: 400,
    }),
    []
  );

  const styleListItemButton = useMemo(
    () => ({
      "&.MuiButtonBase-root": {
        color: '#212121',
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 400,
        height: '18px',
        lineHeight: "14px",
      },
      '&:hover': {
        borderRadius: "8px",
      },
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        // dialOutName: yup
        //   .string("Enter Name")
        //   .required("Numberis required"),
        // dialOutNumber: yup
        //   .string("Enter Number")
        //   .required("Numberis required"),
        dialOutName: yup
          .string("Dial Out Name is required.")
          .min(2, "Dial Out Name should be between 2 to 50 alphanumeric characters long allows prefix + and special characters like .,@,_,- and Name cannot start and end with Special characters.")
          .max(50, "Dial Out Name should be between 2 to 50 alphanumeric characters long allows prefix + and special characters like .,@,_,- and Name cannot start and end with Special characters.")
          .matches(/^([+A-Za-z0-9]((\\.|@|_|\\-|\\s)?[A-Za-z0-9])+)$/, 'Dial Out Name should be between 2 to 50 alphanumeric characters long allows prefix + and special characters like .,@,_,- and Name cannot start and end with Special characters.')
          .required("Dial Out Name is required."),
        dialOutNumber: yup
          .string()
          .test("maxDigits",
            "Dial Out Number should be between 3 to 17 characters.",
            (number) => String(number).length >= 2 && String(number).length <= 17)
          .matches(/^(?:\+\d{2,16}|\d{3,17})$/, "Dial Out Number should be between 3 to 16 digits long and allows prefix +")
          .required("Dial Out Number is required."),
        sip: false,
      }),
    []
  );

  const closeDialoutNumberForm = () => {
    dialOutNumberFormProps.onClose();
    setOpenDialoutNumberForm(false);
  };

  async function addDialoutNumber(formData) {
    try {
      addDialoutNumbers(formData)
        .then((resp) => {
          if (resp.Status === "Success") {
            closeDialoutNumberForm();
            if (dialOutNumberFormProps.currentTab === '2') {
              dialOutNumberFormProps.updateDialoutNumbers();
            }
            showSuccessNotification(`DialOutNumber: ${formData.dialOutNumber} Added Successfully`)
          }
          else {
            // showErrorNotification(resp.Message);
            showErrorNotification(resp?.Message?.dialOutName || resp?.Message?.dialOutNumber || resp?.Message);
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  }

  async function updateDialoutNumber(formData) {
    formData.id = dialOutNumberFormProps.data.id;
    try {
      addDialoutNumbersById(formData)
        .then((resp) => {
          if (resp.Status === "Success") {
            closeDialoutNumberForm();
            dialOutNumberFormProps.updateDialoutNumber();
            showSuccessNotification(`DialOutNumber: ${formData.dialOutNumber} Updated Successfully`)
          }
          else {
            // showErrorNotification(resp.Message);
            showErrorNotification(resp?.Message?.dialOutNumber || resp?.Message?.dialOutName || resp?.Message);
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  }

  async function deleteDialoutNumberById() {
    try {
      deleteDialoutNumbersById(dialOutNumberFormProps.data.id)
        .then((resp) => {
          if (resp.Status === "Success") {
            closeDialoutNumberForm();
            dialOutNumberFormProps.updateDialoutNumber();
            showSuccessNotification(resp.Message)
          }
          else {
            showErrorNotification(JSON.stringify(resp.Message));
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
    setopenDelete(false)
  }

  const getValidationInViewMode = (e, value) => {
    dialOutNumberFormProps.data.dialOutName = value;
    const regex = /^([+A-Za-z0-9]((\\.|@|_|\\-|\\s)?[A-Za-z0-9])+)$/;
    const isMatch = regex.test(value);

    const digitCount = value.length;

    // Check the conditions for validation
    const isValidLength = value.length >= 2 && value.length <= 50;
    const isValidDigitCount = digitCount >= 2 && digitCount <= 50;

    // Final validation check
    if (isMatch && isValidLength && isValidDigitCount) {
      setValid(true);
    } else {
      setValid(false);
    }
  }

  const DataForm = (dialOutFormProps) => {
    let initData = dialOutFormProps.data || {
      dialOutName: "",
      dialOutNumber: "",
      sip: false,
    };

    return (
      <div className="ozDialoutNumberAddForm">
        <Formik
          initialValues={initData}
          validationSchema={validationSchema}
          onSubmit={(formValues) => {
            if (dialoutNumberFormMode === ADMIN_FORM_MODES.add) {
              addDialoutNumber(formValues);
            }
            else {
              if (valid === true) {
                updateDialoutNumber(formValues);
              }
            }
          }}>
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              id="dialoutNumberForm"
              className={"ozcwScrollbar oz-add-phone-number-form-container " + (dialoutNumberFormMode === ADMIN_FORM_MODES.edit ? "oz-add-phone-number-form-edit-container" : "oz-add-phone-number-form-add-container")}
            >
              <Grid container={true} spacing={2}>
                {(dialoutNumberFormMode === ADMIN_FORM_MODES.add) &&
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <OZInput
                      name="dialOutName"
                      label="Dialout Name *"
                      placeholder="Enter Name" />
                  </Grid>
                }
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <OZInput
                    name="dialOutNumber"
                    label="Dialout Phone Number *"
                    placeholder="Enter Number" />
                </Grid>
                <Grid sx={{ marginLeft: "2px" }} item xs={12} sm={12} md={12} lg={12}>
                  <OZFCheckbox labelPosition="end" name="sip" label="SIP" />
                </Grid>
              </Grid>

              {dialoutNumberFormMode === ADMIN_FORM_MODES.edit && (
                <div className="oz-add-phone-number-form-edit-button-container">
                  <Button
                    variant="outlined"
                    sx={{ ...buttonStyle, marginRight: '1.25rem' }}
                    onClick={closeDialoutNumberForm}>
                    Cancel
                  </Button>
                  <Button variant="contained" sx={buttonStyle} type="submit">
                    Save
                  </Button>
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div>
      {dialoutNumberFormMode === ADMIN_FORM_MODES.add ? (
        <OZDialog
          title="Add Dialout Number"
          saveButtonProps={{
            form: 'dialoutNumberForm',
            type: 'submit'
          }}
          open={openDialoutNumberForm}
          mode={dialoutNumberFormMode}
          onClose={closeDialoutNumberForm}>
          {dialoutNumberFormMode === ADMIN_FORM_MODES.add && <DataForm />}
        </OZDialog>
      ) : (
        <OZDrawer
          open={openDialoutNumberForm}
          title={
            <div className="oz-add-phone-number-form-drawer-header-container">
              {/* {authPage(AdminPages.DAILOUT_NUMBERS_EDIT) && dialoutNumberFormMode === "View" && <img ref={anchorRef} className="oz-add-phone-number-form-drawer-header-morevert" */}
              {(newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full') ) && dialoutNumberFormMode === "View" && <img ref={anchorRef} className="oz-add-phone-number-form-drawer-header-morevert"
                src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                alt="edit"
                onClick={dialoutNumberFormMode === "View" ? onClickMoreVertIcon : null}
              ></img>}
              <Menu
                anchorEl={viewMoreVertMenuAnchro}
                open={Boolean(viewMoreVertMenuAnchro)}
                onClose={handleCloseMoreVertMenu}
                sx={{
                  maxHeight: "70vh",
                  "& .MuiPaper-root": {
                    backgroundColor: "white",
                    width: "160px",
                  },
                  "& .MuiMenu-paper": {
                    backgroundColor: "white",
                    boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                    border: "0.0625rem solid #E6E5E6",
                    borderRadius: "12px",
                    marginLeft: "-10.5625rem",
                    marginTop: '-1.5rem'
                  },
                }}
              >
                <ListItemButton
                  onClick={onClickDelete}
                  disabled={newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit')}
                  sx={styleListItemButton}>
                  Delete
                </ListItemButton>
              </Menu>
              {openDelete && <DeleteDialog
                open={openDelete}
                onClose={() => { setopenDelete(false) }}
                title="Delete Dialout Number"
                content={`This will delete Dialout Number:${dialOutNumberFormProps.data.dialOutNumber} permanently. You cannot undo this action.`}
                deleteFunction={deleteDialoutNumberById} />}
              {dialoutNumberFormMode === "View" ? <Tooltip title={dialOutNumberFormProps.data.dialOutName}><div
                className="oz-managenumbers-drawer-title-heading"
                sx={{
                  fontWeight: 600,
                  fontSize: "1.125rem",
                  width: "11rem",
                  marginTop: '1px',
                  // marginLeft: authPage(AdminPages.DAILOUT_NUMBERS_EDIT) ? "" : "10px",
                  marginLeft: (newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit')) ? "" : "10px",
                }}
                flexGrow={1}>
                {dialOutNumberFormProps.data.dialOutName}
              </div></Tooltip> :
                <TextField
                  sx={{
                    paddingLeft: "12px",
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      backgroundColor: '#ffff',
                      fontSize: '18px',
                      color: "#212121",
                      height: '44px',
                      '&:hover': {
                        border: "none"
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #D6D6D7'
                    },
                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #D6D6D7'
                    }
                  }}
                  defaultValue={dialOutNumberFormProps.data.dialOutName}
                  fullWidth
                  // onChange={(e) => { dialOutNumberFormProps.data.dialOutName = e.target.value }}
                  onChange={(e) => getValidationInViewMode(e, e.target.value)}
                  error={!valid}
                  helperText={!valid ? "Dial Out Name should be between 2 to 50 alphanumeric characters long allows prefix + and special characters like .,@,_,- and Name cannot start and end with Special characters." : ""}
                >
                </TextField>}
              {dialoutNumberFormMode === "Edit" &&
                <Typography
                  sx={{
                    fontSize: '0.875rem', marginRight: "-4px", paddingLeft: "8px", fontWeight: 400, color: "#3D8BF8", cursor: "pointer"
                  }}
                  onClick={() => setDialoutNumberFormMode('View')}
                >
                  Discard
                </Typography>
              }
            </div>
          }
          onClose={closeDialoutNumberForm}
          // hideEditIcon={!authPage(AdminPages.DAILOUT_NUMBERS_EDIT)}
          hideEditIcon={newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'View')}
          onEdit={() => setDialoutNumberFormMode(ADMIN_FORM_MODES.edit)}
          mode={dialoutNumberFormMode}
        >
          {dialoutNumberFormMode === ADMIN_FORM_MODES.view && (
            <ViewDialoutNumberForm viewData={dialOutNumberFormProps.data} />
          )}
          {dialoutNumberFormMode === ADMIN_FORM_MODES.edit && <DataForm data={dialOutNumberFormProps.data} />}
        </OZDrawer>
      )
      }
    </div >
  );
};

export default AddDialoutNumberForm;
