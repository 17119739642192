import React, { useMemo, useState, useEffect } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Typography, Divider } from '@mui/material';
import './Adminsettings.css';
import AccountSettings from './AccountSettings';
import GeneralSettings from './GeneralSettings';
import IntegrationOrMiscellaneousSettings from './IntegrationOrMiscellaneousSettings';
import { authPage, AdminPages } from '../../../services/page-service';
import { showErrorNotification } from '../../../components/admin/common/NotiService';
import { OZCustomToggleButton } from '../../../components/admin/common/OZCustomToggleButton';
import { getSettingsList } from "../../../services/api-service";


export default function AdminSettings(props) {
  // const adminSettingsTabList = authPage(AdminPages.USER_SETTINGS) ? ['Account', 'General', 'Integration', 'Miscellaneous'] : ['Account']
  const adminSettingsTabList = ['Account', 'General', 'Integration', 'Miscellaneous'];
  const [activeTab, setActiveTab] = useState(adminSettingsTabList[0]);
  const [settingsList, setSettingsList] = useState([]);

  const handleTablChange = (event, value) => {
    setActiveTab(value);
  };


  let styleToggleButtonLabel = useMemo(
    () => ({
      color: "#212121",
      // width: "64px",
      heigh: "14px",
      fontFamily: "Roboto",
      fontSize: " 12px",
      fontWeight: 400,
      lineHeight: "14px",
      letterSpacing: "0em",
      textAlign: "center",
    }),
    []
  );

  let toggleStyle = useMemo(
    () => ({
      backgroundColor: "#F5F8FA",
      width: "93px",
      height: "30px",
      borderRadius: 0,
      '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#FFFF',
      },
    }),
    []
  );

  const fetchSettings = async () => {
    try{
      const resp= await getSettingsList();
      setSettingsList(resp);
      return resp

    }
    catch(err){
      console.error(err)
    }
  };

  useEffect(() => {   
    fetchSettings();
  }, []);

  // function settingContent() {
  //   switch (activeTab) {
  //     case adminSettingsTabList[0]:
  //       return <AccountSettings />
  //     case adminSettingsTabList[1]:
  //       return (authPage(AdminPages.USER_SETTINGS)) && <GeneralSettings settingsList={fetchSettings} />
  //     case adminSettingsTabList[2]:
  //       return (authPage(AdminPages.USER_SETTINGS)) && <IntegrationOrMiscellaneousSettings name='integration' settingsList={fetchSettings} />
  //     case adminSettingsTabList[3]:
  //       return (authPage(AdminPages.USER_SETTINGS)) && <IntegrationOrMiscellaneousSettings name='miscellaneous' settingsList={fetchSettings} />
  //     default: showErrorNotification('Not a valid settings')
  //   }
  // }

  function settingContent() {
    switch (activeTab) {
      case adminSettingsTabList[0]:
        return <AccountSettings />
      case adminSettingsTabList[1]:
        return <GeneralSettings settingsList={fetchSettings} />
      case adminSettingsTabList[2]:
        return <IntegrationOrMiscellaneousSettings name='integration' settingsList={fetchSettings} />
      case adminSettingsTabList[3]:
        return <IntegrationOrMiscellaneousSettings name='miscellaneous' settingsList={fetchSettings} />
      default: showErrorNotification('Not a valid settings')
    }
  }

  return (
    <div className='ozAdminSettings' >
      <Typography
        sx={{
          height: "14px",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontStyle: "normal",
          fontSize: "12px",
          lineHeight: "14px",
          marginBottom: "10px",
          color: "#212121",
        }}
        flexGrow={1}
        alignSelf="center"
      >
        Settings / Admin Settings
      </Typography>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "24px",
          lineHeight: "28px",
          marginBottom: "24px"
        }}
        flexGrow={1}
        alignSelf="center"
      >
        Admin Settings
      </Typography>

      <div style={{ display: "flex", height: "30px" }}>
        <TabContext value={activeTab}>
          <TabList
            TabIndicatorProps={{
              sx: { backgroundColor: "#008EFF", marginBottom: "18px" },
            }}
            onChange={handleTablChange}
          >
            {adminSettingsTabList.map((item, index) => {
              return <OZCustomToggleButton
                key={item}
                sx={{ ...toggleStyle, borderRight: adminSettingsTabList.length - 1 !== index ? 0 : null }}
                value={item}
              >
                <Typography sx={styleToggleButtonLabel}>{item}</Typography>
              </OZCustomToggleButton>
            })}

          </TabList>
          <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
            <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6" }} />
          </span>
        </TabContext>
      </div>
      {settingContent()}
    </div>
  );
}