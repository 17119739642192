import { APXOR_KEY,LOCATION } from './properties';

declare global {
    interface Window { 
        APP_CONSTANTS: {
            APXOR_KEY: string;
LOCATION: string;
        };
    }
}

window.APP_CONSTANTS = {
    APXOR_KEY,
LOCATION,
};