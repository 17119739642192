import * as React from "react";
import { Grid, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { Formik } from "formik";
import { useMemo, useState } from "react";
import * as yup from "yup";
import OZInput from "../../../components/admin/common/OZInput";
import { addNewReasonById, saveNewReason } from "../../../services/api-service";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import OZDialog from "../../../components/admin/common/OZDialog";
import CustomizedCheckbox from "../../../components/admin/common/CustomCheckBox";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";

const PauseReasonsForm = (pauseReasonFormProps) => {
  let pauseReasonFormInitialMode = pauseReasonFormProps.data ? "Rename" : ADMIN_FORM_MODES.add;
  pauseReasonFormInitialMode = pauseReasonFormProps?.openDialog === true ? "Rename" : pauseReasonFormInitialMode;
  const [openAddDialog, setOpenAddDialog] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [pauseReasonFormMode, setPauseReasonFormMode] = useState(pauseReasonFormInitialMode);
  const validationSchema = useMemo(
    () =>
      yup.object({
        reason: yup
          .string("Enter Pause Reason")
          .required("Pause Reason is required")
          .min(3, "Pause Reason should be between 3 to 50 alphanumeric characters long allows special characters like space,_ and Reason cannot start and end with Special characters.")
          .max(50, "Pause Reason should be between 3 to 50 alphanumeric characters long allows special characters like space,_ and Reason cannot start and end with Special characters.")
          .matches(/^([A-Za-z0-9]((\_|\s)*?[A-Za-z0-9])+)$/, "Pause Reason should be between 3 to 50 alphanumeric characters long allows special characters like space,_ and Reason cannot start and end with Special characters.")
      }),

    []
  );

  const closePauseReasonForm = () => {
    pauseReasonFormProps.onClose();
    setOpenAddDialog(false);
  };

  async function addReason(formData) {
    try {
      saveNewReason(formData)
        .then((resp) => {
          if (resp.Status === "Success") {
            closePauseReasonForm();
            pauseReasonFormProps.updateReason();
            showSuccessNotification(`PauseReason: ${formData.reason} Created Successfully`)
          }
          else {
            showErrorNotification(resp?.Message);
            // showErrorNotification(JSON.stringify(resp.Message))
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  }

  async function updatePauseReason(formData) {
    try {
      addNewReasonById(formData)
        .then((resp) => {
          if (resp.Status === "Success") {
            closePauseReasonForm();
            pauseReasonFormProps.updateReason();
            showSuccessNotification(`PauseReason: ${formData.reason} Updated Successfully`)
          }
          else {
            showErrorNotification(resp?.Message);
            // showErrorNotification(JSON.stringify(resp.Message))
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  }

  const DataForm = (formProps) => {

    let newPauseTime = null

    if (formProps?.editData?.timeLimit) {
      newPauseTime = new Date(formProps.editData.timeLimit * 1000).toISOString().slice(11, 19);
      newPauseTime = moment(newPauseTime, "HH:mm:ss")
    }

    let pauseReasonFormInitialtData = formProps.editData || {
      reason: "",
      pauseTime: "--:--:--",
      timeLimit: null,
    };

    const [onChangeTimeValue, setOnChangeTimeValue] = useState(newPauseTime);
    const [noTimeLimit, setNoTimeLimit] = useState(formProps?.editData?.timeLimit === null ? false : true);

    const handleTimeValueChange = (newValue) => {
      setOnChangeTimeValue(newValue);
      pauseReasonFormInitialtData.pauseTime = newValue.format("HH:mm:ss")
    };

    return (
      <div className="ozPauseReasonAddForm">
        <Formik
          initialValues={pauseReasonFormInitialtData}
          validationSchema={validationSchema}
          onSubmit={(userEneteredValues) => {
            try {
              if (noTimeLimit) {
                let time = onChangeTimeValue.format("HH:mm:ss")
                time = time.split(':');
                userEneteredValues.timeLimit = (+time[0]) * 60 * 60 + (+time[1]) * 60 + (+time[2]);
              }
              else {
                userEneteredValues.timeLimit = null
              }
            }
            catch (e) {
              console.log(e)
            }
            if (pauseReasonFormMode === "Create") {
              addReason(userEneteredValues)
            }
            else {
              updatePauseReason(userEneteredValues);
            }
          }}
        >
          {(formik) => (
            <div>
              <form
                onSubmit={formik.handleSubmit}
                id="pauseReasonForm"
                className="ozcwScrollbar oz-pause-reason-form-container"
              >
                <Grid container={true} spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <OZInput
                      name="reason"
                      placeholder="Enter Reason"
                      label="Pause Reason *"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      fontSize='12px'
                      fontWeight={400}
                      lineHeight='14px'
                      marginBottom={'6px'}
                      marginLeft='1px'
                      color={!noTimeLimit ? "#99A0A8" : "#212121"}
                    >
                      Pause Time
                    </Typography>
                    <TimePicker
                      disabled={!noTimeLimit}
                      showSecond={true}
                      value={onChangeTimeValue}
                      onChange={handleTimeValueChange}
                      placeholder='--:--:--'
                    />
                  </Grid>
                  <Grid sx={{ marginLeft: '0.125rem' }} item xs={3.8} sm={3.8} md={3.8} lg={3.8} >
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          '& .MuiTypography-root': {
                            fontSize: "0.875rem",
                            color: '#212121',
                          }
                        }}
                        control={
                          <CustomizedCheckbox
                            defaultChecked={!noTimeLimit}
                            onClick={() => {
                              if (noTimeLimit) { setNoTimeLimit(false); setOnChangeTimeValue(onChangeTimeValue); }
                              else { setNoTimeLimit(true); }
                            }}
                          />
                        }
                        label="No Time Limit"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </form>
            </div>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div>
      {pauseReasonFormMode === ADMIN_FORM_MODES.add ? (
        <OZDialog
          title="Add Pause Reason"
          saveButtonProps={{
            form: 'pauseReasonForm',
            type: 'submit'
          }}
          open={openAddDialog}
          mode={pauseReasonFormMode}
          onClose={closePauseReasonForm}
        >
          <DataForm />
        </OZDialog>
      ) : (
        <OZDialog
          title="Reason"
          saveButtonProps={{
            form: 'pauseReasonForm',
            type: 'submit'
          }}
          open={openAddDialog}
          mode={pauseReasonFormMode}
          onClose={closePauseReasonForm}
          hideSaveButton={!(pauseReasonFormProps.editAccess) || false}
        >
          <DataForm editData={pauseReasonFormProps.data} />
        </OZDialog>
      )
      }
    </div >
  );
};

export default PauseReasonsForm;
