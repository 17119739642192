export const getLastFetchedDetail = () => {
    const timezone = localStorage.getItem('userTZ') || '';
    // const options = { timeZone: timezone, hour: 'numeric', minute: 'numeric', hour12: true };
    const options: Intl.DateTimeFormatOptions = {
        timeZone: timezone,
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    // Get the current time in the specified timezone in 12-hour format
    const timeIn12HourFormat = new Date().toLocaleTimeString('en-US', options);

    return timeIn12HourFormat;
}