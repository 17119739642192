import { connect, getIn } from "formik";
import 'react-quill/dist/quill.snow.css';
import './OZRichText.css'
import React, { useState, useRef, useEffect } from 'react';
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor, { Jodit } from "jodit-react";



const TextEditor = (props) => {
    const editor = useRef(null);
    const [value, setValue] = useState(props.value);
    // const isReadOnly = props.disable

    function onEditorChange(content, delta, source, editor) {
        if (props.onChangeText) {
            props.onChangeText(content)
        }
        setValue(content)
    }

    useEffect(() => {
        Jodit.modules.Icon.set('customIcon', "");
    }, []);

    return (
        <div>
            <JoditEditor
                ref={editor}
                onBlur={onEditorChange}
                // defaultValue={value}
                value={value}
                readonly={props.disable || false}
                config={{
                    disablePlugins: ["paste"],
                    readonly: props.disable ? props.disable : false,
                    toolbarAdaptive: false,
                    toolbarButtonSize: 'middle',
                    showCharsCounter: false,
                    showWordsCounter: "",
                    toolbarSticky: false,
                    showPlaceholder: false,
                    showXPathInStatusbar: false,
                    buttons: [{
                        name: 'undo',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/undo.svg`

                    },
                    {
                        name: 'redo',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/redo.svg`,

                    },
                        "|",
                        "font",
                        "|",
                    {
                        name: 'fontsize',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/fontsize.svg`,

                    },
                        "|",
                    {
                        name: 'bold',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/bold.svg`,

                    },
                    {
                        name: 'italic',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/italic.svg`,

                    },
                    {
                        name: 'underline',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/underline.svg`,

                    },
                    {
                        name: 'brush',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/textcolor.svg`,

                    },
                        "|",
                        "align",
                        /*  {
                             name: 'align-Left',
                             iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/alignleft.svg`,
     
                         },
                         {
                             name: 'underline',
                             iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/aligncenter.svg`,
     
                         },
                         {
                             name: 'underline',
                             iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/alignright.svg`,
     
                         },
                         {
                             name: 'underline',
                             iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/alignjustify.svg`,
     
                         }, */
                        "|",
                    {
                        name: "indent",
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/increaseindent.svg`,

                    },
                    {
                        name: 'outdent',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/decreaseindent.svg`,
                    },
                        "|",
                    {
                        name: 'ul',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/bullets.svg`,

                    }, {
                        name: 'ol',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/numbers.svg`,

                    },
                        "|",
                    {
                        name: 'eraser',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/clear.svg`,

                    }, {
                        name: 'strikethrough',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/strikethrough.svg`,

                    },
                    {
                        name: 'paragraph',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/quote.svg`,

                    },
                        "|",
                    /*  {
                         name: '',
                         iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/file.svg`,
                     }, */
                    {
                        name: 'link',
                        iconURL: `${process.env.PUBLIC_URL}/icons/oz-rt-icons/link.svg`,

                    },

                    ]

                }}
            />
        </div>
    );
}


const OZRichText = connect(({ name, placeholder, disable, height, width, formik }) => {

    /*  const error = getIn(formik.errors, name);
     const touch = getIn(formik.touched, name); */

    function updateData(data) {
        formik.setFieldValue(name, data);
    }
    return (
        <React.Fragment>
            <TextEditor
                disable={disable}
                name={name}
                id={name}
                value={getIn(formik.values, name)}
                onChangeText={updateData}
                placeholder={placeholder}
                height={height} />
        </React.Fragment>
    );
})


export default OZRichText




