import React, { useState, useMemo, useEffect, useRef } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Grid, Stack, Divider, Chip, Typography, Tooltip } from "@mui/material";
import OZInput from "../../../components/admin/common/OZInput";
import OZDrawer from "../../../components/admin/common/OZDrawer/OZDrawer";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import { addgroup, getagentsDropdown, getgroupById, getsubUserDropdown, updategroupById, } from "../../../services/api-service";
import OZAutocompletes from "../../../components/admin/common/AutoComplete/OZAutocompleteGroups";
import OZDialog from "../../../components/admin/common/OZDialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";

const ViewGroups = (viewProps) => {

  let agentData = viewProps.data.agents || [];
  let userData = viewProps.data.users || [];

  const editAgent = () => {
    viewProps.mode("editAgent");
  };

  const editSubadmin = () => {
    viewProps.mode("editSubAdmin");
  };

  const GroupsChipView = (props) => {
    return (<>
      <div className="oz-group-form-header">
        <label className="oz-group-form-label">{props.label}</label>
        {/* {authPage(AdminPages.GROUPS_EDIT) && */}
        {(newAuthPages(NewAdminPages.GROUPS) || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Full')) &&
          <Typography color="#3D8BF8" fontSize='14px' fontWeight={400} sx={{ cursor: 'pointer' }} onClick={props.editAction}>
            Edit
          </Typography>
        }
      </div>
      <Grid item xs={12} sm={12} md={12} lg={12} className="oz-group-form-content">
        {props.data.map(
          (keyValue, i) =>
            <GroupChipLabel value={keyValue[props.optionLabel]} index={i} key={i} />
        )}
        {!props.hideDivider && <Grid margin={'10px -10px 10px -20px'}>
          <Divider />
        </Grid>}
      </Grid>
    </>)
  };

  const GroupChipLabel = (props) => {
    return (
      <Chip key={props.value + props.index} label={props.value}
        sx={{
          backgroundColor: "#F5F5F6",
          color: "#536580",
          padding: '8px',
          margin: '5px 5px 5px 0px',
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16px",
          borderRadius: "16px"
        }} />
    );
  };

  return (
    <div>
      <GroupsChipView data={agentData} optionLabel={'agentName'} label={'Agents *'} editAction={editAgent} />
      <GroupsChipView data={userData} optionLabel={'username'} label={'Sub admin *'} editAction={editSubadmin} hideDivider />
    </div >
  );
};

const GroupsForm = (props) => {

  let [groupData, setGroupData] = useState({});
  const [showGroupForm, setGroupFormState] = useState(true);
  const [mode, setMode] = useState(props.mode);
  const [agentsList, setAgentsList] = useState([]);
  const [usersList, setUserList] = useState([]);
  const [anchorMoreVert, setAchorMoreVert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const anchorRef = useRef();

  function setData(data) {
    let updatedData = structuredClone(data);
    setGroupData(updatedData);
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .min(2, "Group Name should be between 2 to 50 characters.")
          .max(50, "Group Name should be between 2 to 50 characters.")
          .matches(/^([A-Za-z]+((_|\s)?[A-Za-z\d])*)$/, "Group Name seems to be invalid.")
          .required("Group Name is required"),
        description: yup
          .string()
          .min(2, "Description should be between 2 to 200 characters.")
          .max(200, "Description should be between 2 to 200 characters.")
          .required("Description is required"),
        users: yup
          .array()
          .of(yup.object().shape({
            id: yup.number().required("Sub admin is required"),
            username: yup.string().required("Sub admin is required"),
          }))
          .required("Sub admin is required")
          .min(1, "Sub admin field must have at least 1 item."),
        agents: yup
          .array()
          .of(yup.object().shape({
            id: yup.number().required("Agents is required."),
            agentName: yup.string().required("Agents is required."),
          }))
          .required("Agents is required.")
          .min(1, "Agent field must have at least 1 item."),
      }),
    []
  );

  const closeGroupForm = () => {
    props.onClose();
    setGroupFormState(false);
  };

  const viewModeMorevertIcon = () => {
    setTimeout(() => setAchorMoreVert(anchorRef?.current), 1);
  };

  const onMenuClose = () => {
    setAchorMoreVert(null);
  };

  const onClickDeleteDialogClose = () => {
    setDeleteConfirmationDialog(false);
  };

  const onClickDeleteDialogDelete = () => {
    props.groupsCallBack("deleteFunction", props.data.id);
    setDeleteConfirmationDialog(false);
    closeGroupForm();
  };

  const onClickDelete = () => {
    setAchorMoreVert(null);
    setDeleteConfirmationDialog(true);
  };

  const onClickEditBasicInfo = () => {
    setAchorMoreVert(null);
    setMode("editBasicInfo");
  };

  useEffect(() => {
    getagentdropdownList();
    getuserdropdownList();
  }, []);

  async function addGroup(data) {
    setLoading(true);
    try {
      addgroup(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            showSuccessNotification(`AgentGroup ${data.name} Created Successfully`);
            closeGroupForm();
            props.groupsCallBack("listFunction");
            setLoading(false);
          }
          else {
            showWarningNotification(resp.Message.name || resp.Message.description || resp.Message);
            setLoading(false);
          }
        })
        .catch((e) => {
          showErrorNotification("Error adding group");
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in group adding ");
      setLoading(false);
    }
  };

  async function updateGroup(data) {
    setLoading(true);
    try {
      updategroupById(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            showSuccessNotification(`AgentGroup ${data.name} Updated Successfully`);
            if (mode !== "Rename") {
              setMode(ADMIN_FORM_MODES.view);
            }
            props.groupsCallBack("listFunction", data.id);
            setLoading(false);
          }
          else {
            showWarningNotification(resp.Message.name || resp.Message.description || resp.Message);
            setLoading(false);
          }
        })
        .catch((e) => {
          showErrorNotification("Eror updating group");
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in group update");
      setLoading(false);
    }
  };

  async function getagentdropdownList() {
    try {
      getagentsDropdown()
        .then((resp) => {
          setAgentsList(resp);
        })
        .catch((e) => {
          setAgentsList([]);
        });
    } catch (e) {
      showErrorNotification("Error in agentdropdown list");
    }
  };

  async function getuserdropdownList() {
    try {
      getsubUserDropdown()
        .then((resp) => {
          setUserList(resp.Data);
        })
        .catch((e) => {
          setUserList([]);
        });
    } catch (e) {
      showErrorNotification("Error in userdropdown list");
    }
  };

  const styleListItemButton = useMemo(
    () => ({
      "&.MuiButtonBase-root": {
        color: '#212121',
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 400,
        height: '18px',
        lineHeight: "14px",
      },
      '&:hover': {
        borderRadius: "4px",
      },
    }),
    []
  );

  const DataForm = (props) => {

    let initData = props.editData || {
      name: "",
      description: "",
      users: [],
      agents: [],
    };

    const [openAddAgent, setOpenAddAgent] = useState(false);

    const PreviousStep = () => {
      setOpenAddAgent(false);
    };

    return (
      <div className="ozGroupAddForm">
        <Formik
          initialValues={initData}
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (mode === ADMIN_FORM_MODES.add) {
              addGroup(values);
            }
            if (mode !== ADMIN_FORM_MODES.add) {
              updateGroup(values);
            }
          }}
        >
          {(props) => (
            <form
              onSubmit={props.handleSubmit}
              id="groupForm"
              style={{
                width: "27rem",
                paddingTop: "30px",
              }}
            >
              {!openAddAgent && mode === ADMIN_FORM_MODES.add &&
                <div>
                  <Grid container={true} spacing="24px">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <OZInput
                        name="name"
                        placeholder="Name"
                        label="Group Name *"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <OZInput name="description"
                        placeholder="About this group"
                        label="Description *"
                        multiline={true}
                        maxRows={1}
                        height='auto'
                        inputProps={{ style: { height: '100px' } }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Stack>
                        <label className="agent-subadmin-label">
                          Add Sub admins *
                        </label>
                        <OZAutocompletes
                          name="users"
                          PlaceHolder={"Start typing name"}
                          Datas={usersList}
                          optionLabelName="username"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid sx={{ marginTop: "73px" }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-end"
                    >
                      <Button onClick={() => {
                        if (props.errors.name === undefined && props.errors.description === undefined && props.errors.users === undefined) {
                          setOpenAddAgent(true);
                          props.touched.agents = null
                        } else {
                          props.handleSubmit();
                        }
                      }}
                        className=" createButton" variant="contained"  >
                        Continue
                      </Button>
                    </Stack>
                  </Grid>
                </div>
              }
              {openAddAgent && mode === ADMIN_FORM_MODES.add &&
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Stack>
                      <label className="agent-subadmin-label">
                        Add Agents *
                      </label>
                      <OZAutocompletes
                        name="agents"
                        PlaceHolder={"please select Agents"}
                        Datas={agentsList}
                        optionLabelName="agentName"
                        optionId='agentId'
                      />
                    </Stack>
                  </Grid>
                  <Grid sx={{ marginTop: "320px" }}>
                    <Stack direction="row" justifyContent="space-between">
                      <Button className="previousButton" variant="text" onClick={PreviousStep} >
                        Previous Step
                      </Button>
                      <Button className="createButton" variant="contained" type="submit" >
                        Create
                      </Button>
                    </Stack>
                  </Grid>
                </>}
              {mode === "editAgent" &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <OZAutocompletes
                    name="agents"
                    PlaceHolder={"Search Agent Name"}
                    Datas={agentsList}
                    optionLabelName="agentName"
                    optionId='agentId'
                    mode="Edit"
                  />
                </Grid>
              }
              {mode === "editSubAdmin" &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <OZAutocompletes
                    name="users"
                    PlaceHolder={"Search Sub admin Name"}
                    Datas={usersList}
                    optionLabelName="username"
                    mode="Edit"
                  />
                </Grid>
              }
              {mode === 'Rename' &&
                (
                  <Grid item xs={12} sm={12} md={12} lg={12} marginTop="-30px">
                    <OZInput
                      name="name"
                      placeholder="Name"
                      label="Group Name *"
                    />
                  </Grid>
                )
              }
              {mode === 'editBasicInfo' &&
                <div style={{ width: "27rem" }}>
                  <Grid container={true} spacing="24px" marginTop="-55px">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <OZInput
                        name="name"
                        placeholder="Name"
                        label="Group Name *"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <OZInput name="description"
                        placeholder="About this group"
                        label="Description *"
                        multiline={true}
                        maxRows={1}
                        height='auto'
                        inputProps={{ style: { height: '100px' } }} />
                    </Grid>
                  </Grid>
                </div>
              }
            </form>
          )}
        </Formik>
      </div>
    );
  };
  return (
    <div>
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
            <LoadingScreen />
          </div>
        )
        :
        (
          <div>
            {mode === ADMIN_FORM_MODES.add ?
              (
                <OZDialog open={showGroupForm} mode={mode} onClose={closeGroupForm} title="Create Group" hideActions={true}>
                  <Divider style={{ color: "#E6E5E6" }}></Divider>
                  <DataForm />
                </OZDialog>
              ) : (mode === 'Rename' ? (<div>
                <OZDialog open={showGroupForm} onClose={closeGroupForm} title="Rename" saveButtonProps={{ form: 'groupForm', type: 'submit', }} >
                  <DataForm editData={groupData} />
                </OZDialog>
              </div>) : (
                <div>
                  <OZDrawer
                    open={showGroupForm}
                    formType='groups'
                    hideEditIcon={true}
                    title={
                      <div>
                        <div style={{ display: 'flex', alignItems: 'center' }} >
                          {/* {authPage(AdminPages.GROUPS_EDIT) && */}
                          {(newAuthPages(NewAdminPages.GROUPS) || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Full')) &&
                            <Button onClick={viewModeMorevertIcon} sx={{ marginRight: "-20px", padding: "0px", border: "0px", fontSize: "0px", marginLeft: "-20px", width: "0px", height: "0px", marginBottom: "20px" }}>
                              <img style={{ alignSelf: 'start' }}
                                ref={anchorRef}
                                src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                alt="edit"
                              ></img>
                            </Button>
                          }
                          <Tooltip title={groupData.name}>
                            <label style={{ color: "#212121", fontWeight: 600, fontSize: '18px', marginLeft: (newAuthPages(NewAdminPages.GROUPS) || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Full')) ? "0px" : "11px", maxWidth: "400px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                            {/* <label style={{ color: "#212121", fontWeight: 600, fontSize: '18px', marginLeft: (authPage(AdminPages.GROUPS_EDIT)) ? "0px" : "11px", maxWidth: "400px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}> */}
                              {groupData.name}
                            </label>
                          </Tooltip>
                          <Menu
                            anchorEl={anchorMoreVert}
                            open={Boolean(anchorMoreVert)}
                            onClose={onMenuClose}
                            sx={{
                              maxHeight: "70vh",
                              "& .MuiPaper-root": {
                                backgroundColor: "white",
                                width: "160px",
                                height: "64px"
                              },
                              "& .MuiMenu-paper": {
                                backgroundColor: "white",
                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                border: "0.0625rem solid #E6E5E6",
                                borderRadius: "12px",
                                marginLeft: "-10.5625rem",
                                marginTop: '-1.5rem'
                              },
                            }}
                          >
                            <MenuItem onClick={onClickEditBasicInfo} sx={styleListItemButton}>
                              Edit Basic Info
                            </MenuItem>
                            <MenuItem disabled={newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Edit')} onClick={onClickDelete} sx={{ ...styleListItemButton, marginTop: '8px' }}>
                              Delete Group
                            </MenuItem>
                          </Menu>
                        </div>
                        {openDeleteConfirmationDialog &&
                          <DeleteDialog title={"Delete Group"} content={" This will delete this " + groupData.name + " group permanently. You cannot undo this action."} onClose={onClickDeleteDialogClose}
                            open={true} deleteFunction={onClickDeleteDialogDelete} />
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12} marginLeft="12px">
                          <label style={{ fontSize: "14px", lineHeight: "16.41px", color: "#212121", fontWeight: 400, maxWidth: "400px", wordBreak: "break-all", }}>
                            {groupData.description}
                          </label>
                        </Grid>
                      </div>
                    }
                    onClose={closeGroupForm}
                  >
                    <ViewGroups data={groupData} mode={setMode} />
                  </OZDrawer>
                </div >
              ))
            }
            {mode === "editAgent" &&
              <OZDialog open={showGroupForm} onClose={() => { setMode(ADMIN_FORM_MODES.view) }} title="Add/Remove Agents" saveButtonProps={{ form: 'groupForm', type: 'submit', }}>
                <DataForm editData={groupData} />
              </OZDialog>
            }
            {mode === "editSubAdmin" &&
              <OZDialog open={showGroupForm} onClose={() => { setMode(ADMIN_FORM_MODES.view) }} title="Add/Remove Subadmins" saveButtonProps={{ form: 'groupForm', type: 'submit', }}>
                <DataForm editData={groupData} />
              </OZDialog>
            }
            {mode === "editBasicInfo" &&
              <OZDialog open={showGroupForm} onClose={() => { setMode(ADMIN_FORM_MODES.view) }} title="Edit Basic Info" saveButtonProps={{ form: 'groupForm', type: 'submit', }}>
                <DataForm editData={groupData} />
              </OZDialog>
            }
          </div>
        )
      }
    </div >
  );
};

export default GroupsForm;