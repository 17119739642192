import { showErrorNotification } from "../components/admin/common/NotiService"
import { checkRoles } from "../utils/util"
import { getSettingsListByCode, getUserLoggedIn, getUserModules } from "./api-service"
import { setRoleData } from "./auth-service"

export const AdminPages = {
    //campaigns
    INBOUND_CAMP_VIEW: 'inboundCampaignsView',
    INBOUND_CAMP_EDIT: 'inboundCampaignsEdit',
    OUTBOUND_CAMP_VIEW: 'outboundCampaignsView',
    OUTBOUND_CAMP_EDIT: 'outboundCampaignsEdit',
    MARKETING_CAMP_VIEW: 'marketingCampaignsView',
    MARKETING_CAMP_EDIT: 'marketingCampaignsEdit',
    RESTORE_CAMP_VIEW: 'restoreCampaignsView',

    //Templates //SMS templates in old is same as all templates
    // EmailTemplatePage: emailTemplatesView,
    // 'emailTemplatesEdit',
    // 'whatsappTemplatesView',
    // 'whatsappTemplatesEdit', 
    TEMPLATES_VIEW: 'templatesView',
    TEMPLATES_EDIT: 'templatesEDIT',

    // will show userlist by defaut & roles check will handle it
    USERS_VIEW: 'usersView',
    USERS_EDIT: 'usersEdit',

    //Agents
    AGENTS_VIEW: 'agentsView',
    AGENTS_EDIT: 'agentsEdit',

    //Groups
    GROUPS_VIEW: 'groupsView',
    GROUPS_EDIT: 'groupsEdit',

    //Ivr
    IVR_FLOWS_VIEW: 'IVRFlowsView',
    // IVR_FLOWS_EDIT: 'IVRFlowsEdit',

    //Manage Numbers
    AGENT_NUMBERS_VIEW: 'AgentNumbersView',
    AGENT_NUMBERS_EDIT: 'AgentNumbersEdit',
    DAILOUT_NUMBERS_VIEW: 'dailoutNumbersView',
    DAILOUT_NUMBERS_EDIT: 'dailoutNumbersEdit',
    TRANSFER_NUMBERS_VIEW: 'transferNumbersView',
    TRANSFER_NUMBERS_EDIT: 'transferNumbersEdit',
    //view and edit is same for block group numbers
    BLOCKED_NUMBERS_VIEW: 'blockedNumbersView',
    // BLOCKED_NUMBERS_EDIT: 'blockedNumbersEdit',

    //Locations
    LOCATIONS_VIEW: 'locationsView',
    LOCATIONS_EDIT: 'locationsEdit',

    //Skills
    SKILLS_VIEW: 'skillsView',
    SKILLS_EDIT: 'skillsEdit',

    //pauseReason
    PAUSE_REASONS_VIEW: 'pauseReasonsView',
    PAUSE_REASONS_EDIT: 'pauseReasonsEdit',

    //Disposition
    DISPOSITIONS_VIEW: 'dispositionsView',
    DISPOSITIONS_EDIT: 'dispositionsEdit',

    //Pre-Post Call Flow
    PREPOST_VIEW: 'prePostView',
    PREPOST_EDIT: 'prePostEdit',

    //mappings
    MAPPINGS_LIST_VIEW: 'mappingsView', //no edit for mappings

    //sipLocations
    SIPLOCATIONS_VIEW: 'SipLocationView',
    //  SIPLOCATIONS_Edit : 'SipLocationsEdit,

    // Integrations (edit and view is same for integrations)
    INTEGRATIONS_VIEW: 'integrationsView',

    //settings
    // except profile edit all the settings will be handled with this settings
    // 'adminSettingsView',
    // 'adminSettingsEdit',
    // 'agentSettingsView',
    // 'agentSettingsEdit'
    USER_SETTINGS: 'userSettings'

    // DNC will be handled with roles
}

export const NewAdminPages = {
    //campaigns
    CAMPAIGN_PAGE : 'Campaigns',
    // INBOUND_CAMP: 'Inbound Campaigns',
    // OUTBOUND_CAMP: 'Outbound Campaigns',
    // CHAT_CAMP: 'Chat Campaigns',
    // MARKETING_PAGE: 'Marketing Campaigns',
    // RESTORE_CAMP: 'Restore Campaigns',

    //agents
    AGENTS_PAGE : 'Agents',
    // AGENTS_VIEW: 'agentsView',
    // AGENTS_EDIT: 'agentsEdit',

    //Templates
    TEMPLATES_PAGE : 'Templates',

    //Skills
    SKILLS_PAGE : 'Skills',

    //dispositions
    DISPOSITIONS_PAGE : 'Dispositions',

    //Groups
    GROUPS: 'Groups',
    // GROUPS_VIEW: 'Groups View',
    // GROUPS_EDIT: 'Groups Edit',

    //Ivr
    IVR_FLOWS: 'IVR Flows',
    // IVR_FLOWS_VIEW: 'IVRFlowsView',

    //Pre-Post Call Flow
    PREPOST_PAGE: 'Pre Post',
    // PREPOST_EDIT: 'PrePostEdit',

    //Manage Numbers
    // AGENT_NUMBERS_VIEW: 'AgentNumbersView',
    // AGENT_NUMBERS_EDIT: 'AgentNumbersEdit',
    // DAILOUT_NUMBERS_VIEW: 'DailoutNumbersView',
    // DAILOUT_NUMBERS_EDIT: 'DailoutNumbersEdit',
    // TRANSFER_NUMBERS_VIEW: 'TransferNumbersView',
    // TRANSFER_NUMBERS_EDIT: 'TransferNumbersEdit',
    // //view and edit is same for block group numbers
    // BLOCKED_NUMBERS_VIEW: 'Blocked Numbers',

    MANAGE_NUMBERS: 'Manage Numbers',
    // PLANS: 'Plans',
    // USERS: 'Users',
    SUB_USERS: 'Sub Users',
    LOCATIONS: 'Locations',
    PAUSE_REASONS: 'Pause Reasons',
    SIP_LOCATION: 'Sip Location',
    MUSIC_FILE: 'Music File',
    SIP_NUMBER: 'Sip Number',
    HOLIDAYS: 'Holidays',
    // AGENT_NUMBERS: 'Agent Numbers',
    // DIALOUT_NUMBERS: 'Dial Out Number',
    // TRANSFER_NUMBERS: 'Transfer Numbers',
    // BLOCKED_NUMBERS: 'Blocked Numbers',

    REPORTS: 'Reports',
    LIVE_REPORTS: 'Live Reports',
    HISTORIC_REPORTS: 'Historic Reports',
    DASHBOARDS: 'Dashboard',

    
    //mappings
    MAPPING: 'Mapping',

    //Settings
    // SETTINGS: 'Settings',
    ADMIN_SETTTINGS: 'Admin Settings',
    AGENT_SETTINGS: 'Agent Settings',
    INTEGRATION_SETTINGS: 'Integration Settings'
}

const pagesMap = {
    //campaigns
    'Campaigns-Inbound List': AdminPages.INBOUND_CAMP_VIEW,
    'Campaigns-Inbound Edit': AdminPages.INBOUND_CAMP_EDIT,
    'Campaigns-Outbound List': AdminPages.OUTBOUND_CAMP_VIEW,
    'Campaigns-Outbound Edit': AdminPages.OUTBOUND_CAMP_EDIT,
    'Marketing_Campaigns': AdminPages.MARKETING_CAMP_VIEW,
    'Marketing_Campaign_Edit': AdminPages.MARKETING_CAMP_EDIT,
    'RestoreCampaignList': AdminPages.RESTORE_CAMP_VIEW,

    //Templates
    'SMSTemplateList': AdminPages.TEMPLATES_VIEW,
    'SMSTemplateEdit': AdminPages.TEMPLATES_EDIT,

    // Users will show userlist by defaut & roles check will handle it

    'Agents-List': AdminPages.AGENTS_VIEW,
    'Agents-Edit': AdminPages.AGENTS_EDIT,

    //groups
    'groupsView': AdminPages.GROUPS_VIEW,
    'groupsEdit': AdminPages.GROUPS_EDIT,

    //ivr
    'IvrFlows-List': AdminPages.IVR_FLOWS_VIEW, //same for view and edit

    //Manage Numbers
    'PhoneNumbers-List': AdminPages.AGENT_NUMBERS_VIEW,
    'PhoneNumbers-Edit': AdminPages.AGENT_NUMBERS_EDIT,
    'DialOutNumbers_List': AdminPages.DAILOUT_NUMBERS_VIEW,
    'DialOutNumbers_Edit': AdminPages.DAILOUT_NUMBERS_EDIT,
    'TransferNumbers_List': AdminPages.TRANSFER_NUMBERS_VIEW,
    'TransferNumbers_Edit': AdminPages.TRANSFER_NUMBERS_EDIT,
    'BlockNumbersGroup-List': AdminPages.BLOCKED_NUMBERS_VIEW, //view and edit is same for block group numbers

    //Locations
    'Locations-List': AdminPages.LOCATIONS_VIEW,
    'Locations-edit': AdminPages.LOCATIONS_EDIT,

    //skills
    'Skills_List': AdminPages.SKILLS_VIEW,
    'Skills_Edit': AdminPages.SKILLS_EDIT,

    //pauseReason
    'Pause Reasons List': AdminPages.PAUSE_REASONS_VIEW,
    'Pause Reasons Edit': AdminPages.PAUSE_REASONS_EDIT,

    //Disposition
    'Dispositions List': AdminPages.DISPOSITIONS_VIEW,
    'Dispositions Edit': AdminPages.DISPOSITIONS_EDIT,

    // //AddCallWork
    // 'Add Call Work List': AdminPages.ADDCALLWORK_VIEW,
    // 'Add Call Work Edit': AdminPages.ADDCALLWORK_EDIT,

    //mappings   
    'Mappings_List': AdminPages.MAPPINGS_LIST_VIEW, //no edit for mappings

    //sipLocation
    'SipLocations': AdminPages.SIPLOCATIONS_VIEW, //same for view and edit

    //Integration
    'Integrations': AdminPages.INTEGRATIONS_VIEW, //edit and view is same for integrations

    // 'adminSettingsView',
    // 'adminSettingsEdit',
    // 'agentSettingsView',
    // 'agentSettingsEdit'
    'User Settings': AdminPages.USER_SETTINGS // except profile edit all the settings will be handled with this settings

    //DNC will be handled with roles
}


let finalPages = [AdminPages.USERS_VIEW, AdminPages.USERS_EDIT]

function formatKey(input) {
    return input.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export async function pageAuthService() {
    await getUserModules().then(resp => {
        try {
            let data = resp.Data.userPage;
            let roleData = resp.Data.roles;
            setRoleData({ roles: JSON.stringify(roleData) });
            if (data.length > 0) {
                data.forEach(element => {
                    if (pagesMap[element.page_name]) {
                        if (!finalPages.includes(pagesMap[element.page_name])) {
                            finalPages.push(pagesMap[element.page_name])
                            if (element.page_name === 'Agents-List') {
                                finalPages.push(AdminPages.GROUPS_VIEW)
                            }
                            if (element.page_name === 'Agents-Edit') {
                                finalPages.push(AdminPages.GROUPS_EDIT)
                            }
                        }
                    }
                });
                localStorage.setItem('authPages', JSON.stringify(finalPages))
                // return finalPages;
            }
            else {
                // showErrorNotification('user modules list is empty....')
            }
        } catch (e) {
            // showErrorNotification('error getting user modules...', e)
        }

    }).catch((e) => {
        // showErrorNotification('failed to get user modules..login again.', e)
    })

    // getUserLoggedIn().then((resp) => {
    //     try {
    //         if (resp.Data.length > 0 || resp.Data !== null) {
    //             let data = resp.Data[0];
    //             if (data.parentUser === null || data.parentUser === undefined || data.parentUser === '') {
    //                 localStorage.setItem('parentUser', null);
    //             } else {
    //                 localStorage.setItem('parentUser', JSON.stringify(data.parentUser));
    //             }
    //         }
    //         else {
    //             showErrorNotification('user Profile list is empty....')
    //         }
    //     } catch (e) {
    //         showErrorNotification('error Profile user settings...', e)
    //     }
    // }).catch((e) => {
    //     showErrorNotification('failed to get user Profile..login again.', e)
    // });

    await getUserLoggedIn().then(resp => {
        try {
            let data = resp?.Data[0];//only doing for SubAdmin for testing
            let resultForPage = [];
            let resultAccess = [];
            let tempData = [];
            let tempData1 = [];
            if(resp?.Status === "Success" && resp?.Data?.length > 0){
                if(checkRoles("ROLE_ADMIN")){ //checking for SuperAdmin
                    tempData = data?.appPlanUser?.appPlan?.features;
                }else if(checkRoles("ROLE_ADMIN") === false){//for Admin and SubAdmin
                    if(data?.parentUser === null || data?.parentUser === "null"){ //for Admin
                        tempData = data?.appPlanUser?.appPlan?.features;
                    }else{// for SubAdmin
                        if(data?.appProfileUser[0]?.profileSetting){
                            let tData = JSON.parse(data?.appProfileUser[0]?.profileSetting);
                            tempData1 = tData;
                        }
                    }
                }

                if (data?.parentUser === null || data?.parentUser === undefined || data?.parentUser === 'null' || data?.parentUser === "") {
                    localStorage.setItem('parentUser', null);
                    localStorage.setItem('isAdmin', "true");
                } else {
                    localStorage.setItem('parentUser', JSON.stringify(data.parentUser));
                    localStorage.setItem('isAdmin', "false");
                }
            }
            if (tempData?.length > 0) { // For SuperAdmin or Admin
                tempData.forEach(item => {
                    resultForPage.push(`${item.name}`);
                });
            }else{ // For SubAdmin
                Object.keys(tempData1).forEach(key => {
                    const value = tempData1[key];
            
                    if (Array.isArray(value)) {
                        value.forEach(item => {
                            // resultAccess.push(`${item}_${key}`);
                            resultAccess.push(`${item}_${getAccess(key)}`);
                        });
                    } else if (typeof value === 'boolean') {
                        // resultAccess.push(`${(key === 'liveReports' ? 'live Reports' : key === 'historicReports' ? 'historic Reports' : key).charAt(0).toUpperCase() + (key === 'liveReports' ? 'live Reports' : key === 'historicReports' ? 'historic Reports' : key).slice(1)}_${value}`);
                        resultAccess.push(`${formatKey(key).charAt(0).toUpperCase() + formatKey(key).slice(1)}_${value}`);
                    }
                });
            }
            localStorage.setItem('newAuthPage', JSON.stringify(resultForPage));
            localStorage.setItem('newAuthSubAdminAccess', JSON.stringify(resultAccess));
        } catch (e) {
            // showErrorNotification('error getting user modules...', e)
        }
    }).catch((e) => {
        // showErrorNotification('failed to get user modules..login again.', e)
    })
    
    await getSettingsListByCode({ isAdmin: "true", settingsCode: "PRE_POST_CALL_FLOW" }).then((resp) => {
        try {
            if (resp.Data.length > 0 || resp.Data !== null) {
                let data = resp.Data[0];
                if (data.ParameterValue === null) {
                    if (data.DefaultValue === false || data.DefaultValue === 'false') {
                        // sessionStorage.setItem(`settings_${data.ParameterCode}`, false);
                    }
                } else if (data.ParameterValue !== null) {
                    if (data.ParameterValue === true || data.ParameterValue === 'true') {
                        // sessionStorage.setItem(`settings_${data.ParameterCode}`, true);
                        finalPages.push(AdminPages.PREPOST_VIEW)
                        finalPages.push(AdminPages.PREPOST_EDIT)
                    } else if (data.ParameterValue === false || data.ParameterValue === 'false') {
                        // sessionStorage.setItem(`settings_${data.ParameterCode}`, false);
                    }
                }
                localStorage.setItem('authPages', JSON.stringify(finalPages))
            }
            else {
                showErrorNotification('user Settings list is empty....')
            }
        } catch (e) {
            showErrorNotification('error getting user settings...', e)
        }
    }).catch((e) => {
        showErrorNotification('failed to get user settings..login again.', e)
    });

}


export function authPage(page) {
    try {
        let authPages = JSON.parse(localStorage.getItem('authPages')) || finalPages
        return authPages.includes(page)
    }
    catch (e) {
        console.log("Can't able to find user modules value from session storage", e)
    }
}

export function getAccess(type){
    if(type === 'View' || type === 'viewAccess'){
        // return 'viewAccess';
        return '$v*A@#';
    }else if(type === 'Edit' || type === 'editAccess'){
        // return 'editAccess';
        return '^e@@A*';
    }else if(type === 'Full' || type === 'fullAccess'){
        // return 'fullAccess';
        return '@f&A@*';
    }else if(type === 'data' || type === 'dataUpload'){
        // return 'dataUpload';
        return '*d%%U##';
    }else if(type === true){
        return true;
    }else if(type === false){
        return false;
    }
}

export function newAuthSubAdminAccess(page, accessType) {
    try {
        let authPages = JSON.parse(localStorage.getItem('newAuthSubAdminAccess')) || finalPages;
        let access = getAccess(accessType);
        return authPages.includes(`${page}_${access}`);
    }
    catch (e) {
        console.log("Can't able to find user modules value from local storage", e)
    }
}

export function newAuthPages(page) {
    try {
        let authPages = JSON.parse(localStorage.getItem('newAuthPage')) || finalPages;
        if(authPages.includes(page)){
            return authPages.includes(page);
        }else{
            return false;
        }
    }
    catch (e) {
        console.log("Can't able to find user modules value from local storage", e, page);
    }
}
