import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import { ReactComponent as RefreshOutlinedIcon } from '../../../static/icons/refresh.svg';
import { Badge, Box, Card, Grid, Stack, Theme, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useRef, useState, useEffect } from 'react';
import { outboundCampaignStatusLive, getWidgetConfig } from '../../../services/api-service';
import { getLastFetchedDetail } from '../../../services/application-service';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import { checkDataAccessFilters, checkRoles, determineFilterType } from '../../../utils/util';

const useStyles: any = makeStyles((theme: Theme) => ({
	typeButton: {
		padding: '5px !important'
	},
	filterItem: {
		fontSize: 12,
		height: 24,
		borderRadius: 20,
		background: 'rgba(0, 142, 255, 0.1)',
		border: '1px solid #008eff',
		padding: `0 ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center'
	},
	itemIndicator: {
		width: 5,
		height: 5,
		borderRadius: 1,
		display: 'inline-block',
		position: 'relative',
		top: '-2px',
		marginRight: 4
	},
	filterItemText: {
		margin: 0,
		color: '#536580'
	},
	filterItemSubText: {
		color: '#99A0A8'
	}
}));

let interval: any;

function OutboundCampaignStatus(props: any) {
	const classes = useStyles();
	const downloadContent = useRef<null | any>(null);
	const chartElement: any = useRef(null);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [outboundCampaignFilter, setOutboundCampaignFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const [graphData, setGraphData] = useState<any>([]);
	const [allowedFilters, setAllowedFilters] = useState<string[]>(['Campaign']);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || 1);
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [isBreakdownDrawerOpen, setIsBreakdownDrawerOpen] = useState<boolean | undefined>(false);
	const [campaignName, setCampaignName] = useState<any>([]);
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [lastFetchedTime, setLastFetchedTime] = useState<any>('00:00');
	const [bound, setBound] = useState<any>('All');

	const additionalApiParams = {campaignType: 'Preview,Progressive,IVR,Predictive'};

	const handleBound = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
		if (newAlignment === null || newAlignment === undefined) return;
		setBound(newAlignment);
	};
	const handleDownload = (type: string) => {
		console.log(type);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const toggleRefresh = () => {
		const filterIds = outboundCampaignFilter.length ? outboundCampaignFilter.map((item: any) => item.id).join(',') : '0';
		retrieveGraphData(filterIds, filterType);
	};

	const clearFilter = () => {
		setOutboundCampaignFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

	const toggleBreakdownDrawer = (isOpen: boolean, data: any = {}) => {
		setIsBreakdownDrawerOpen(isOpen);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = outboundCampaignFilter.filter((item: any) => item.id !== id);
		setOutboundCampaignFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const filterItems = outboundCampaignFilter.map((item: any, index: number) => (
		<div className={classes.filterItem}>
			<Grid container direction={'row'} alignItems="center" gap={2}>
				<Grid item>
					<p className={classes.filterItemText}>
						<span className={classes.itemIndicator} style={{ background: item.color }}></span>
						{item.label}
					</p>
					<b>{item.target}</b>
				</Grid>
				<Grid item display={'flex'} alignItems="center">
					<CloseOutlinedIcon onClick={() => handleFilterDelete(item.id)} color="primary" style={{ cursor: 'pointer', fontSize: '14px' }} />
				</Grid>
			</Grid>
		</div>
	));

	const handleFilterExistingData = (filteredData: any) => {
		setOutboundCampaignFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setOutboundCampaignFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	const retrieveGraphData = async (filters = '0', type = '1', campaiginType = 'all') => {
		let params: any = '';
		let apiResponse: any = '';

		if(checkRoles("ROLE_DATA_ACCESS")){
			params = { agent : '0', campaign : filters, toggle : '0', skill : '0', campaignType : bound }
			apiResponse = await outboundCampaignStatusLive({ ...params });
		}else{
			params = { campaign: filters, campaiginType };
			apiResponse = await outboundCampaignStatusLive({ ...params });
		}

		// const params: any = { campaign: filters, campaiginType };
		// const apiResponse: any = await outboundCampaignStatusLive({ ...params });

		const uploadData = apiResponse.map((item: any) => ({
			y: +item.TotalAdded,
			...item
		}));
		const successData = apiResponse.map((item: any) => ({
			y: +item.TotalSuccess,
			...item
		}));
		const failedData = apiResponse.map((item: any) => ({
			y: +item.TotalFailed,
			...item
		}));
		const pendingData = apiResponse.map((item: any) => ({
			y: +item.TotalPending,
			...item
		}));
		const expiredData = apiResponse.map((item: any) => ({
			y: +item.TotalExpired,
			...item
		}));
		const dayLimitReached = apiResponse.map((item: any) => ({
			// 	y: +item.DayLimitReached,
			y: +item.DayLimitReached,
			...item
		}));
		const campaignName = apiResponse.map((item: any) => item.CampaignName);
		setCampaignName(campaignName);
		const graphOptions = [
			{
				name: 'Upload',
				color: '#E3ECFD',
				data: uploadData,
				pointWidth: 35,
				borderRadius: 5
			},
			{
				name: 'Success',
				color: '#4F9F52',
				data: successData,
				pointPlacement: -0.025
			},
			{
				name: 'Failed',
				color: '#E65B5C',
				data: failedData,
				pointPlacement: -0.27
			},
			{
				name: 'Pending',
				color: '#E7AF5B',
				data: pendingData,
				pointPlacement: -0.3
			},
			{
				name: 'Expired',
				color: '#A026AD',
				data: expiredData,
				pointPlacement: -0.3
			},
			{
				name: 'Limit Exceeded',
				color: '#EBADAD',
				data: dayLimitReached,
				pointPlacement: -0.3
			}
		];

		setGraphData(graphOptions);
		setLastFetchedTime(getLastFetchedDetail());
		chartElement?.current?.chart?.redraw();
	};

	useEffect(() => {
		const resizeListener = () => {
			chartElement?.current?.chart?.reflow();
		};
		window.addEventListener('widgetResize', resizeListener);
		window.addEventListener('resize', resizeListener);

		return () => {
			clearInterval(interval);
			window.removeEventListener('widgetResize', resizeListener);
		};
	}, []);

	useEffect(()	=> {
		const fetchWidgetConfig = async () => {
			try {
				if(checkDataAccessFilters()) {
					let widgetConfig = await getWidgetConfig('131', 'widget');
					if (widgetConfig) {
						if (typeof widgetConfig === 'string') {
							widgetConfig = JSON.parse(widgetConfig);
						}
						// Check the condition and set the appropriate filters
						const filters = widgetConfig.data && checkDataAccessFilters()
										? widgetConfig.data
										: widgetConfig.config;
		
						if (filters) {
							const filtersArray = filters.split(',');
							setAllowedFilters(filtersArray);
							const determinedFilterType = determineFilterType(filtersArray);
							if (determinedFilterType !== undefined) {
								setFilterType(determinedFilterType);
							}
						}
					}
				}
			} catch (error) {
				console.error('Error fetching widget config:', error);
			}
		};
	fetchWidgetConfig();
}, []);

	useEffect(() => {
		const filterIds = (outboundCampaignFilter.length && !(responseLength === outboundCampaignFilter.length)) ? outboundCampaignFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = outboundCampaignFilter.length ? outboundCampaignFilter.map((item: any) => item.id).join(',') : '0'; 
		retrieveGraphData(filterIds, filterType);
		if (interval) {
			clearInterval(interval);
		}
		// interval = setInterval(() => retrieveGraphData(filterIds, filterType), WIDGET_REFRESH_INTERVAL.OUTBOUND_CAMPAIGN_STATUS);
	}, [filterType, outboundCampaignFilter, bound]);

	const options = {
		credits: { enabled: false },
		chart: {
			type: 'column',
			height: 350,
		},
		title: {
			text: ''
		},
		xAxis: {
			title: {
				text: 'Campaigns ➡',
				style: {
					fontWeight: 'bold'
				}
			},
			categories: campaignName,
			crosshair: true,
			labels: {
				rotation: -45,
				style: {
					fontSize: '12px',
					fontFamily: 'Roboto'
				}
			}
		},
		yAxis: {
			title: {
				text: 'Interaction Volume',
				style: { fontWeight: 'bold' }
			},
			gridLineDashStyle: 'dash'
		},
		legend: {
			layout: 'horizontal',
			align: 'center',
			verticalAlign: 'top'
			// itemMarginTop: 10
		},
		tooltip: {
			shared: true
		},
		plotOptions: {
			series: {
				cursor: 'pointer',
				pointWidth: 10,
				borderRadius: 2,
				groupPadding: 0.3,
				point: {
					events: {
						click: function ({ point }: any) {
							toggleBreakdownDrawer(true);
						}
					}
				}
			}
		},
		series: graphData
	};
	// [
	// 	{
	// 		name: 'Upload',
	// 		color: 'rgba(165,170,217,1)',
	// 		data: [
	// 			13.93, 13.63, 13.73, 13.67, 14.37, 14.89, 14.56, 14.32, 14.13, 13.93,
	// 			18.8, 13.21,
	// 		],
	// 		pointPadding: 0.3,
	// 		pointPlacement: -0.2,
	// 	},
	// 	{
	// 		name: 'Success',
	// 		color: 'rgba(126,86,134,.9)',
	// 		data: [
	// 			10.0, 9.93, 9.97, 10.01, 10.23, 10.26, 10.0, 9.12, 9.36, 8.72, 8.38,
	// 			8.69,
	// 		],
	// 		pointPadding: 0.4,
	// 		pointPlacement: -0.2,
	// 	},
	// 	{
	// 		name: 'Failed',
	// 		color: 'rgba(248,161,63,1)',
	// 		data: [
	// 			4.35, 4.32, 4.34, 4.39, 4.46, 4.52, 4.58, 4.55, 4.53, 4.51, 4.49,
	// 			4.57,
	// 		],
	// 		pointPadding: 0.3,
	// 		pointPlacement: 0.2,
	// 		yAxis: 1,
	// 	},
	// 	{
	// 		name: 'Pending',
	// 		color: 'rgba(186,60,61,.9)',
	// 		data: [
	// 			203.6, 198.8, 208.5, 203.6, 198.8, 208.5, 203.6, 198.8, 208.5, 203.6,
	// 			198.8, 208.5,
	// 		],
	// 		pointPadding: 0.4,
	// 		pointPlacement: 0.2,
	// 		yAxis: 1,
	// 	},
	// ],

	return (
		<>
			<Card ref={downloadContent} className="customCard">
				<div className="card-header">
					<div className="card-title">
						Outbound Campaign Status
						<span className="card-title-badge">Live</span>
						<div className="refresh-btn card-icon" onClick={() => toggleRefresh()}>
							<RefreshOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</div>
						<span className="last-fetched-time">Last fetched at: {lastFetchedTime} </span>
					</div>
					<div className="card-actions">
						{checkRoles("ROLE_DATA_ACCESS") &&
							<ToggleButtonGroup value={bound} color="primary" exclusive onChange={handleBound} aria-label="bound">
								<ToggleButton value="All" className={classes.boundButton} aria-label="All">
									<span>All</span>
								</ToggleButton>
								<ToggleButton value="Preview" className={classes.boundButton} aria-label="Preview">
									<span>Preview</span>
								</ToggleButton>
								<ToggleButton value="Progressive" className={classes.boundButton} aria-label="Progressive">
									<span>Progressive</span>
								</ToggleButton>
								<ToggleButton value="Predictive" className={classes.boundButton} aria-label="Predictive">
									<span>Predictive</span>
								</ToggleButton>
								<ToggleButton value="IVR" className={classes.boundButton} aria-label="IVR">
									<span>IVR</span>
								</ToggleButton>
							</ToggleButtonGroup>
						}
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={'Outbound Campaign Status'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={outboundCampaignFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
					</div>
				</div>
				<Box sx={{ pt: 2, pl: 2 }}>
					{outboundCampaignFilter.length > 0 && (
						<div className="filter-outer">
							<div className="filter-container">
								<Stack direction={'row'} spacing={1}>
									{filterItems}
								</Stack>
							</div>
							<div className="filter-remove" onClick={clearFilter}>
								Remove
							</div>
						</div>
					)}
				</Box>
				<HighchartsReact ref={chartElement} highcharts={Highcharts} options={options} />
			</Card>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={outboundCampaignFilter} setResponseLength={setresponseLength} additionalApiParams={additionalApiParams} allowedFilters={allowedFilters} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/>
			{/* <FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} allowedFilters={['Campaign']} existingSelectedFilters={outboundCampaignFilter} setResponseLength={setresponseLength} additionalApiParams={additionalApiParams} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/> */}
			{/* {isBreakdownDrawerOpen && <BreakDownDrawer isOpen={isBreakdownDrawerOpen} toggleDrawer={toggleBreakdownDrawer} widgetName={'OUTBOUND_CALL_CAMPAIGN'} />} */}
		</>
	);
}

export default OutboundCampaignStatus;
