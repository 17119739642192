import { ActivityData } from '../types/agentAlertsType';
import { showNotification } from '../utils/notificationUtils';

export function getAgentAlertMessages(activityData: ActivityData[]) {
    activityData.forEach((activity, index) => {
        let categoryText = '';
        const categoryParts = activity.category.split(':');
        if (categoryParts.length === 2 && categoryParts[0] === 'paused') {
            categoryText = `exceeded ${categoryParts[1]}`;
        } else {
            switch (activity.category) {
                case 'chat:busy':
                    categoryText = 'on chat';
                    break;
                case 'call:busy':
                    categoryText = 'on call';
                    break;
                case 'acw':
                    categoryText = 'in ACW';
                    break;
                default:
                    categoryText = `in ${activity.category}`;
                    break;
            }
        }

        const message = `${activity.agentName} has been ${categoryText} for ${activity.since}`;
        console.log('Agent ID:', activity.agentId);
        console.log('Since:', activity.since);
        console.log('Message:', message);

        // Example of showing notification
        const randomNumber = Math.floor(Math.random() * 1000000);
        const uniqueTag = `activity_${activity.agentId}_${activity.currentTime}_${index}_${randomNumber}`
        const notificationOptions = {
            body: message,
            tag: uniqueTag // Unique tag based on agentId and index
        };

        showNotification('CxHub', notificationOptions);
    });
}
