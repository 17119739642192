import React, { useState, useMemo } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Grid } from "@mui/material";
import OZInput from "../../../components/admin/common/OZInput";
import OZDialog from "../../../components/admin/common/OZDialog";
import { addlocation, updatelocationById } from "../../../services/api-service";
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import { ADMIN_FORM_MODES } from "../../../utils/constants";

const LocationForm = (props) => {

  let initMode = props.data ? "Rename" : ADMIN_FORM_MODES.add;
  initMode = props?.openDialog === true ? "Rename" : initMode;
  const [showLocationForm, setLocationFormState] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [mode, setMode] = useState(initMode);


  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string("Enter Location Name")
          .required("Location Name is required")
          .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "Location Name should be between 3 to 50 alphanumeric characters long allows special characters like _ and Name cannot start and end with Special characters.")
          .min(3, "Location Name should be between 3 to 50 alphanumeric characters long allows special characters like _ and Name cannot start and end with Special characters.")
          .max(50, "Location Name should be between 3 to 50 alphanumeric characters long allows special characters like _ and Name cannot start and end with Special characters.")
      }),
    []
  );

  const closeLocationForm = () => {
    props.onClose();
    setLocationFormState(false);
  };

  async function addLocation(data) {
    try {
      addlocation(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            showSuccessNotification(`Location ${data.name} Created Successfully`);
            props.location();
            closeLocationForm();
          }
          else {
            showWarningNotification(resp.Message.name || resp.Message);
          }
        })
        .catch((e) => {
          showErrorNotification("Error adding location");
        });
    } catch (e) {
      showErrorNotification("Error in location adding ");
    }
  };

  async function updatelocation(data) {
    if (props?.openDialog === true) {
      data.LocationID = data.id;
      data.LocationName = data.name;
    }
    try {
      updatelocationById(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            closeLocationForm();
            props.updateName();
            showSuccessNotification(`Location ${data.name} Updated Successfully`);
          }
          else {
            showWarningNotification(resp.Message.name || resp.Message);
          }
        })
        .catch((e) => {
          showErrorNotification("Error updating location");
        });
    } catch (e) {
      showErrorNotification("Error in location update");
    }
  };

  const DataForm = (formProps) => {

    let initData = formProps.editData || {
      name: "",
    };

    console.log(props.data);

    return (
      <div className="ozAddLocationform">
        <Formik
          initialValues={initData}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (mode === "Create") {
              addLocation(values);
            }
            else {
              updatelocation(values);
            }
          }}
        >
          {
            (props) => (
              <div>
                <form
                  onSubmit={props.handleSubmit}
                  id="locationForm"
                  style={{
                    width: "464px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <OZInput
                        name="name"
                        placeholder="Enter Name"
                        label="Location Name *"
                      />
                    </Grid>
                  </Grid>
                </form>
              </div>
            )}
        </Formik>
      </div>
    );
  };

  return (
    <div>
      {mode === ADMIN_FORM_MODES.add ? (
        <OZDialog open={showLocationForm} onClose={closeLocationForm} title="Add Location" saveButtonProps={{ form: 'locationForm', type: 'submit' }} mode={mode}>
          <DataForm />
        </OZDialog >
      ) :
        (
          <OZDialog open={showLocationForm} onClose={closeLocationForm} title="Rename" saveButtonProps={{ form: 'locationForm', type: 'submit' }} mode={mode} hideSaveButton={!(props.editAccess) || false}>
            <DataForm editData={props.data} />
          </OZDialog>
        )
      }
    </div>
  );
};

export default LocationForm;