import { Typography, IconButton, } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { ADMIN_FORM_MODES } from '../../../../utils/constants';
import './OZDrawer.css'



function OZDrawer(props) {
    let title = props.title;
    let mode = props.mode || ADMIN_FORM_MODES.add
    let isIVR = props.formType ? true : false

    const toggleDrawer = () => {
        props.onClose();
    }
    const getEditStyle = () => {
        if (isIVR) {
            let style = { position: "absolute", margin: '0px', cursor: "pointer", color: '#3D8BF8', marginTop: "24px", height: '16px', fontSize: '14px', fontWeight: 400, marginLeft: "440px" }
            return style;

        } else {
            let style = { height: '16px', width: "24px", fontSize: '14px', cursor: "pointer", color: '#3D8BF8', fontWeight: 400, margin: '0px', marginRight: "12px", marginBottom: "5px" }
            return style;
        }
    }
    const getCloseBtnStyle = () => {
        if (isIVR) {
            let style = { color: '#99A0A8', marginBottom: "30px", marginRight: "10px" }
            return style;

        }
        else {
            let style = { color: '#99A0A8' }
            return style;
        }
    }

    return (
        <Drawer open={props.open} anchor={'right'} onClose={() => toggleDrawer()} >
            <Box sx={{ width: props.width || "30rem", padding: '75px 10px 10px 10px' }}>
                <div className='oz-drawer-header'>
                    <Typography fontSize={'18px'}
                        fontWeight={600}
                        // lineHeight={'21px'}
                        padding={1}
                        flexGrow={1}
                        alignSelf='center'
                        display={'flex'}
                        alignItems='center'
                        component={'div'}
                    >
                        {title}
                    </Typography>

                    {(mode === ADMIN_FORM_MODES.view && !props.hideEditIcon) &&
                        <Typography
                            size='small'
                            variant="text"
                            sx={getEditStyle}
                            onClick={() => { props.onEdit() }}>


                            Edit
                        </Typography>
                    }
                    <IconButton
                        sx={getCloseBtnStyle}
                        size='small'
                        className='closeIcon'
                        onClick={() => toggleDrawer()}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/icons/close.svg`}
                            alt="edit"
                        ></img>
                    </IconButton>
                </div>
                <div className='oz-drawer-body'>
                    {props.children}
                </div>
            </Box>
        </Drawer >
    )
}

export default OZDrawer;