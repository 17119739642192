import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import { Button, Grid, FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import OZDialog from '../../../components/admin/common/OZDialog';
import Divider from '@mui/material/Divider';

// import DownloadTimeConfirmationForm from './DownloadTimeConfirmation';

const DownloadFileConfirmationForm = (props) => {
    const [openBulkCampaignDownloadDataForm, setBulkCampaignDownloadDataForm] = useState(true);
    const [openTimeConfirmationTab, setOpenTimeConfirmationTab] = useState(false);
    const [selectedValue, setSelectedValue] = useState('csv');

    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    const closeBulkCampaignDownloadDataForm = () => {
        setBulkCampaignDownloadDataForm(false);
        props.onClose();
    }

    const downloadClicked = (value) => {
        props.onDownload(value);
        closeBulkCampaignDownloadDataForm();
    }

    const DataForm = (data) => {
        const formButtonStyle = useMemo(
            () => ({
                borderRadius: '8px',
                fontWeight: '400',
                fontSize: '14px',
            }),
            []
        );

        const textStyle = useMemo(
            () => ({
                marginLeft: '20px', 
                fontSize: '12px', 
                color: '#909DAD', 
                marginTop: 4
            }),
            []
        );

        return (
            <div>
                <Formik
                >
                    {(props) => (
                        < form
                            onSubmit={props.handleSubmit}
                            id="downloadData-form"
                            className="ozcw-scrollbar"
                            style={{
                                maxHeight: '70vh'
                            }}
                        >
                            <div style={{ minHeight: '9rem' }}>
                                <Grid container={true} spacing="20px" marginTop={"5px"}>
                                    {!openTimeConfirmationTab &&
                                    <>
                                        <Grid item xs={12}>
                                            Choose what to export
                                        </Grid>
                                        <Grid xs={12} sx={{marginLeft: 1}} container spacing={2}>
                                            <Grid item>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={selectedValue}
                                                    onChange={handleChange}
                                                >
                                                    <FormControlLabel value="csv" control={<Radio />} label=".csv file" />
                                                    <FormControlLabel value="gz" control={<Radio />} label=".gz file" />
                                                </RadioGroup>
                                            </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 5 }} justifyContent='end'>
                                            <Grid item>
                                                <Button style={formButtonStyle} onClick={closeBulkCampaignDownloadDataForm} variant="outlined" type="submit">
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button style={formButtonStyle} variant="contained" onClick={selectedValue === 'csv' ? (e) =>{setOpenTimeConfirmationTab(true)} : (e) => downloadClicked('gz')} >
                                                    Export
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                    }
                                    {openTimeConfirmationTab &&
                                    <>
                                        {selectedValue === 'csv' && <span style={textStyle} >Note: The csv file option can have an impact on the download file due to the data size.</span> }
                                        <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 5 }} justifyContent='end'>
                                            <Grid item>
                                                <Button style={formButtonStyle} onClick={(e) => setOpenTimeConfirmationTab(false)} variant="outlined" type="submit">
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button style={formButtonStyle} variant="contained" onClick={(e) =>{downloadClicked(selectedValue)}} >
                                                    Download
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                    }
                                </Grid>

                                {/* {openTimeConfirmationTab &&
                                    <DownloadTimeConfirmationForm
                                        onClose={(e)=> setOpenTimeConfirmationTab(false)}
                                        open={true}
                                        // onDownload={(e) => downloadClicked()}
                                        onDownload={(e) => downloadClicked(e)}
                                        props={selectedValue}
                                    />
                                } */}

                            </div>
                        </form>
                    )}
                </Formik>

            </div >
        );
    };
    return (
        <div>
            <OZDialog
                hideActions
                title={
                    <label style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
                        Download Campaign Data
                    </label>
                }
                open={openBulkCampaignDownloadDataForm}
                onClose={closeBulkCampaignDownloadDataForm}
            >
                <Divider style={{ color: "#E6E5E6" }}></Divider>
                {<DataForm data={props?.props} />}
            </OZDialog>
        </div>
    );
};

export default DownloadFileConfirmationForm;