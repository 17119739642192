import '../OZCheckbox/OZCheckbox.css';

type Props = {
	id: any;
	label: string | any;
	checked: boolean | undefined;
	item?: any;
	onChange: any;
	group?: string;
	disabled?: boolean | false;
	from?: string;
};

export default function OZCheckbox({ id, checked, label, from, onChange, item, group, disabled }: Props) {

	const disabledStyle = (from === "reports" && disabled) ? "custom-checkbox-disabled" : "";
	const handleChange = (data: any) => {
		onChange(data);
	};

	return (
		<div className={"custom-checkbox " + disabledStyle}>
			<input
				type="checkbox"
				id={group ? group + id : id}
				checked={checked}
				disabled={disabled}
				onChange={(e) => {
					handleChange({
						target: {
							value: { ...item, id, label, group },
							checked: e.target.checked
						}
					});
				}}
			/>
			<label htmlFor={group ? group + id : id}>{label}</label>
		</div>
	);
}
