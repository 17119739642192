import React, { useState } from 'react';
import { FormControl, MenuItem, Select, Typography, IconButton } from '@mui/material';
import { connect } from "formik";

const CustomSelect = connect(({ name, options, placeholder, mode, file, onChange, disabledOptions, disableOptions }) => {

    const [selected, setSelected] = useState('');

    const DropDownIcon = () => {
        return (<IconButton sx={{
            position: 'absolute !important', right: '0', pointerEvents: 'none !important'
        }}><img style={{ paddingRight: file === 'integration' ? '4px' : "12px" }}
            src={`${process.env.PUBLIC_URL}/icons/downArrowDropDown.svg`}
            alt="edit"
        ></img></IconButton>)
    };

    const customRenderValue = (selected) => {

        const placeHolderStyle = {
            fontSize: "14px",
            fontWeight: 400,
            heigth: '16px',
            marginTop: "2px",
            marginLeft: file === 'integration' ? '-6px' : '0px',
            color: mode === "Add" ? '#99A0A8' : '#000000'
        };

        const selectedValueStyle = {
            fontSize: "14px",
            fontWeight: 400,
            heigth: '16px',
            marginTop: "2px",
            marginLeft: file === 'integration' ? '-6px' : '0px',
            color: "#212121"
        };

        const Placeholder = <Typography style={placeHolderStyle
        } > {placeholder}</Typography >
        const Selected = <Typography style={selectedValueStyle
        } > {selected}</Typography >

        return (selected === "" || selected === undefined) ? Placeholder : Selected
    };

    const onSelectOption = (event, name) => {
        onChange(event.target.value, name);
        setSelected(event.target.value);
    };

    return (

        <FormControl>
            <Select
                sx={{
                    height: file === 'integration' ? '30px' : '44px',
                    width: file === 'integration' ? "350px" : "200px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: '8px',
                    marginTop: file === 'integration' ? '4px' : '24px',
                    border: "1px solid #D6D6D7",

                    '& .MuiOutlinedInput-notchedOutline': {
                        border: "0px",
                        borderRadius: '8px',
                        fontSize: file === 'integration' ? '12px' : '14px',
                    },
                    '&:hover': {
                        border: "1px solid #D6D6D7 ",
                    },
                }}
                IconComponent={DropDownIcon}
                placeholder={placeholder}
                displayEmpty
                id={name}
                name={name}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            width: '200px',
                            border: "1px solid #E5E5E5",
                            borderRadius: "8px",
                            marginTop: file === 'integration' ? '-12.5px' : '0px',
                            '& .MuiMenuItem-root': {
                                bgcolor: "white",
                                fontWeight: 400,
                                fontSize: file === 'integration' ? '12px' : '14px',
                                marginLeft: file === 'integration' ? '-6px' : '0px',
                                color: "#212121"
                            },
                            '& .MuiMenuItem-root:hover': {
                                color: file === 'integration' ? '#212121' : '#3D8BF8',
                                backgroundColor: '#FFFFF'

                            },
                        },
                    },
                }}

                value={selected}
                SelectDisplayProps={{
                }}
                onChange={(e) => { onSelectOption(e, name) }}
                renderValue={(selected) => customRenderValue(selected)}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                {options.map((option, index) => {
                    return (
                        <MenuItem key={option + name} value={option} divider={file !== 'integration' && ((name === "AdminAccess") ? index === 1 : index === 0)} disabled={disableOptions ? disabledOptions.includes(option) : null}>
                            {option}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    );
})

export default CustomSelect;