import { Formik } from "formik"
import OZDialog from "../../../components/admin/common/OZDialog"
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen"
import { useMemo, useState } from "react"
import OZInput from "../../../components/admin/common/OZInput"
import * as yup from 'yup';
import { editDigitalCampaigns, saveDigitalCampaigns } from "../../../services/api-service"
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService"

const AddAndEditDigitalCampaignTwitterIntegration = (mainProps) => {

    console.log(mainProps.data)
    let twitterIntegrationData = []
    mainProps?.data?.map((item) => {
        if (item.integration.name === "TwitterChat") {
            twitterIntegrationData.push(item)
        }
    })
    twitterIntegrationData = mainProps?.data !== undefined ? twitterIntegrationData[0] : null
    console.log(twitterIntegrationData)

    const validationSchema = useMemo(
        () =>
            yup.object({
                clientId: yup
                    .string()
                    .required("Owner Id is Required"),
                clientKey: yup
                    .string()
                    .required("Consumer Key is Required"),
                clientSecret: yup
                    .string()
                    .required("Consumer Secrete is Required"),
                authToken: yup
                    .string()
                    .required("OAUTH Access Token is Required"),
                authCode: yup
                    .string()
                    .required("OAUTH Access Secret is Required"),
            }),
        []
    );

    async function editDigitalCampaign(values) {
        setLoading(true);
        console.log(values);

        let changeInTwitterIntegrationData = {
            ...twitterIntegrationData,
            clientId: values.clientId,
            clientKey: values.clientKey,
            clientSecret: values.clientSecret,
            authToken: values.authToken,
            authCode: values.authCode
        }

        try {
            editDigitalCampaigns(changeInTwitterIntegrationData)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification("Twitter Integration Of Digital Campaign is Edited Successfully")
                        setLoading(false);
                        mainProps.getDigitalCampaignDetails()
                        mainProps.close()
                    }
                    else {
                        showErrorNotification("Error Editing Digital Campaign Integration");
                        setLoading(false)
                        mainProps.getDigitalCampaignDetails();
                        mainProps.close()
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in Editing Digital Campaign Integration");
                    setLoading(false)
                    console.log(e)
                    mainProps.getDigitalCampaignDetails();
                    mainProps.close()
                });
        } catch (e) {
            showErrorNotification("Error in Editing Digital Campaign Integration");
            setLoading(false)
            mainProps.getDigitalCampaignDetails();
            mainProps.close()
        }
    }

    async function saveCampaignIntegration(values) {
        let data = {
            campaignId: mainProps.campaignId,
            clientId: values.clientId,
            clientKey: values.clientKey,
            clientSecret: values.clientSecret,
            authToken: values.authToken,
            authCode: values.authCode,
            integrationName: "TwitterChat"
        }
        try {
            saveDigitalCampaigns(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification("Twitter Integration Of Digital Campaign is Saved Successfully")
                        setLoading(false);
                        mainProps.getDigitalCampaignDetails()
                        mainProps.close()
                    }
                    else {
                        showErrorNotification("Error Saving Digital Campaign Integration");
                        setLoading(false)
                        mainProps.getDigitalCampaignDetails();
                        mainProps.close()
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in Saving Digital Campaign Integration");
                    setLoading(false)
                    console.log(e)
                    mainProps.getDigitalCampaignDetails();
                    mainProps.close()
                });
        } catch (e) {
            showErrorNotification("Error in Saving Digital Campaign Integration");
            setLoading(false)
            mainProps.getDigitalCampaignDetails();
            mainProps.close()
        }
    }

    const [loading, setLoading] = useState(false);

    const DataForm = () => {
        let initData = {
            clientId: (twitterIntegrationData?.clientId !== undefined && twitterIntegrationData?.clientId !== null) ? twitterIntegrationData.clientId : '',
            clientKey: (twitterIntegrationData?.clientKey !== undefined && twitterIntegrationData?.clientKey !== null) ? twitterIntegrationData.clientKey : '',
            clientSecret: (twitterIntegrationData?.clientSecret !== undefined && twitterIntegrationData?.clientSecret !== null) ? twitterIntegrationData.clientSecret : '',
            authToken: (twitterIntegrationData?.authToken !== undefined && twitterIntegrationData?.authToken !== null) ? twitterIntegrationData.authToken : '',
            authCode: (twitterIntegrationData?.authCode !== undefined && twitterIntegrationData?.authCode !== null) ? twitterIntegrationData.authCode : '',
        }

        console.log(initData)

        return (
            <div>
                <Formik
                    initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (mainProps.mode === "Add") {
                            saveCampaignIntegration(values)
                        }
                        if (mainProps.mode === "Edit") {
                            editDigitalCampaign(values)
                        }
                    }}
                >
                    {
                        (props) => (
                            <div>
                                <form
                                    onSubmit={props.handleSubmit}
                                    id="editTwitterIntegrationForm"
                                    style={{
                                        width: "800px",
                                    }}
                                >
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="clientId"
                                                placeholder="Owner Id"
                                                label="Owner Id *"
                                            />
                                        </div>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="clientKey"
                                                placeholder="Consumer Key"
                                                label="Consumer Key *"
                                            />
                                        </div>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="clientSecret"
                                                placeholder="Consumer Secrete"
                                                label="Consumer Secrete *"
                                            />
                                        </div>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="authToken"
                                                placeholder="OAUTH Access Token"
                                                label="OAUTH Access Token *"
                                            />
                                        </div>
                                        <div style={{ marginBottom: "15px" }}>
                                            <OZInput
                                                name="authCode"
                                                placeholder="OAUTH Access Secret"
                                                label="OAUTH Access Secret *"
                                            />
                                        </div>
                                    </div>

                                </form>
                            </div>
                        )
                    }
                </Formik>
            </div>
        )
    }

    return (<>
        {loading ?
            (
                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                    <LoadingScreen />
                </div>
            ) :
            <OZDialog open={mainProps.open} onClose={mainProps.close} title={mainProps.data !== undefined ? "Edit Twitter Configurations" : "Twitter Integration"} saveButtonProps={{ form: 'editTwitterIntegrationForm', type: 'submit' }} >
                <DataForm />
            </OZDialog>
        }
    </>
    )

}
export default AddAndEditDigitalCampaignTwitterIntegration