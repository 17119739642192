import React, { useState, useMemo, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Grid, Typography, Stack, Divider, Box } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import { getCampaignOutboundListForAdmin, getTemplates, getDispositionsDropdown, addPostCallwork, updatePostCallwork, getDidDropdown } from '../../../services/api-service';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';
import { checkRoles } from '../../../utils/util';
import { authPage, AdminPages, newAuthPages, NewAdminPages, newAuthSubAdminAccess } from '../../../services/page-service';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { OZCustomToggleButton } from '../../../components/admin/common/OZCustomToggleButton';
import SimpleSwitch from '../../../components/admin/common/OZSwitch/SimpleSwitch';
import PostCallWorkConfig from './PostCallWorkConfig';
import CopyCampaignForm from '../Campaigns/CopyCampaignForm';
import { getUserId } from '../../../services/auth-service';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';

const AddCallWorkForm = (props) => {
    // console.log("props coming here ", props?.data);

    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add

    const [mode, setMode] = useState(initMode);

    // const validationSchema = useMemo(() => (yup.object({
    //     campaignName: yup
    //         .string("Enter Camapign Name")
    //         .min(2, "Campaign Name should be between 2 to 255 alphanumeric characters long and allows special characters like .,@,_,-")
    //         .max(255, "Campaign Name should be between 2 to 255 alphanumeric characters long and allows special characters like .,@,_,-")
    //         .matches(/^(([A-Za-z0-9]((\\.|@|_|-)?[A-Za-z0-9])+))$/, "Campaign Name should be between 2 to 255 alphanumeric characters long and allows special characters like .,@,_,-")
    //         .required("Campaign Name is required"),
    //     campaignType: yup
    //         .string("Enter Camapign Type")
    //         .required("Campaign Type is required"),
    //     fallbackDID: yup
    //         .number("Enter FallBack DID")
    //         .nullable()
    //         .test("maxDigits",
    //             "FallBack DID should be between 3 to 16 digits long and allows prefix +",
    //             (number) => String(number).length >= 3 && String(number).length <= 16)
    //         .typeError('The value must be a number')
    //         .integer('The value must be a number'),
    //     did: yup
    //         .mixed()
    //         .when("campaignType", {
    //             is: type => type === 'Chat',
    //             then: yup
    //                 .string("Enter Campaign Identifier")
    //                 .nullable()
    //                 .min(4, "'Campaign Identifier should be between 4 to 12 digits long and allows prefix +")
    //                 .max(13, "'Campaign Identifier should be between 4 to 12 digits long and allows prefix +")
    //                 .matches(/^([0-9]+|^\+[0-9]+)$/, 'Campaign Identifier should be between 4 to 12 digits long and allows prefix +')
    //                 .required("Campaign Identifier is required"),
    //             otherwise: yup
    //                 .mixed()
    //                 .notRequired()
    //                 .when("campaignType", {
    //                     is: type => type === 'InBound',
    //                     then: yup
    //                         .number("Enter DID")
    //                         .test("maxDigits",
    //                             "DID should be between 3 to 16 digits long and allows prefix +",
    //                             (number) => String(number).length >= 3 && String(number).length <= 16)
    //                         .typeError('The value must be a number')
    //                         .integer('The value must be a number')
    //                         .required('DID is required'),
    //                     otherwise: yup
    //                         .mixed()
    //                         .notRequired(),
    //                 })
    //         }),
    //     ruleNot: yup
    //         .number()
    //         .typeError('The value must be a number')
    //         .when('campaignType', {
    //             is: id => id === 'Chat',
    //             then: yup
    //                 .number()
    //                 .min(1, 'Number of concurrent chats should be between 1 and 9.')
    //                 .max(9, 'Number of concurrent chats should be between 1 and 9.')
    //                 .typeError('The value must be a number')
    //                 .integer('The value must be a number')
    //                 .required('Number of concurrent chats is required.'),
    //             otherwise: yup.number().notRequired().nullable()
    //         }),
    //     dispositions: yup
    //         .array()
    //         .of(yup.object())
    //         .required("Dispositions is required.")
    //         .min(1, "Dispositions field must have at least 1 item"),
    //     callPrefix: yup
    //         .string()
    //         .matches(/^[0-9+][0-9]{0,5}$/, "Call Prefix should be between 1 to 6 digits long and allows prefix +")
    //         .nullable(),
    //     skills: yup
    //         .array()
    //         .of(yup.object().shape({
    //             id: yup.number().required("Skills is required"),
    //             skillName: yup.string().required("skills is required"),
    //         }))
    //         .required("Skills is required")
    //         .min(1, "Skills field must have at least 1 item "),
    //     sla: yup
    //         .number()
    //         .typeError("Wrapup Time (in Sec) is required")
    //         .when('dispositionType.id', {
    //             is: id => id === 'API' || id === 'Toolbar',
    //             then: yup
    //                 .number()
    //                 .min(0, "Wrapup Time should be between 0 to 9999")
    //                 .max(9999, "Wrapup Time should be between 0 to 9999")
    //                 .typeError('Wrapup Time should be a Number')
    //                 .required("Wrapup Time (in Sec) is required.")
    //                 .nullable(),
    //             otherwise: yup.number().notRequired().nullable()
    //         }),
    //     ivrFlowChat: yup
    //         .object()
    //         .when('campaignType', {
    //             is: id => id === 'Chat',
    //             then: yup
    //                 .object()
    //                 .nullable()
    //                 .required("IVR Flow is required."),
    //             otherwise: yup.object().notRequired().nullable()
    //         }),
    //     // screenPopUrl: yup
    //     //     .object()
    //     //     .when('campaignType', {
    //     //         is: type => type === 'Chat', 
    //     //         then: yup
    //     //             .object()
    //     //             .nullable()
    //     //             .required("Plugin Name is required."),
    //     //         otherwise: yup.object().notRequired().nullable()
    //     //     }),
    // })), []);

    const [copyCampaign, setCopyCampaign] = useState(false);
    const [smsTemplateOption, setSmsTemplateOption] = useState([]);
    const [whatsappTemplateOption, setWhatsappTemplateOption] = useState([]);
    const [campaignSelectOption, setCampaignSelectOption] = useState([]);
    const [dispositonsOption, setDispositonsOption] = useState([]);
    const [didOption, setDidOption] = useState([]);
    const [loading, setLoading] = useState((mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? true : false);
    const [value, setValue] = useState((mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? props?.data?.actionType === "Post" ? '1' : props?.data?.actionType === "Pre" ? '0' : '1' : '1');
    const [newLoading, setNewLoading] = useState(false);

    const closeAddWorkForm = () => {
        localStorage.removeItem("addCallWorkForm");
        props.updatePostCallWorkForm();
        props.onClose();
    }

    useEffect(() => {
        if (mode !== ADMIN_FORM_MODES.view) {
            getSmsTemplateOptions()
            getWhatsappTemplateOptions()
            getCampaignSelectOptions()
            getDispositionsOptions()
            getDidOptions()
        }
    }, [mode])

    async function addPostAddWorkForm(data) {
        try {
            addPostCallwork(data).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(`${data.flowName} is Created Successfully`);
                    props.updatePostCallWorkForm();
                    closeAddWorkForm();
                } else {
                    showErrorNotification(resp?.Message);
                }

            }).catch(e => {
                console.log(JSON.stringify(e));
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            console.log(JSON.stringify(e));
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function updatePostAddWorkForm(data) {
        try {
            updatePostCallwork(data).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(`${data.flowName} is Updated Successfully`);
                    props.updatePostCallWorkForm();
                    closeAddWorkForm();
                } else {
                    showErrorNotification(resp?.Message);
                }
            }).catch(e => {
                console.log(JSON.stringify(e));
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            console.log(JSON.stringify(e));
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function getSmsTemplateOptions() {
        setNewLoading(true);
        try {
            getTemplates('sms').then(resp => {
                let tempArraySms = [];
                resp?.Data?.map((item) => {
                    const data = {
                        id: item?.id,
                        name: item?.name,
                    }
                    tempArraySms.push(data);
                    return null;
                })
                setSmsTemplateOption(tempArraySms);
                setNewLoading(false);
            }).catch(e => {
                setSmsTemplateOption([]);
                showErrorNotification('No SMS Template Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getWhatsappTemplateOptions() {
        setNewLoading(true);
        try {
            getTemplates('whatsapp').then(resp => {
                let tempWhatsArray = [];
                resp?.Data?.map((item) => {
                    const data = {
                        id: item?.id,
                        name: item?.name,
                    }
                    tempWhatsArray.push(data);
                    return null;
                })
                setWhatsappTemplateOption(tempWhatsArray);
                setNewLoading(false);
            }).catch(e => {
                setWhatsappTemplateOption([]);
                showErrorNotification('No Whatsapp Template Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getCampaignSelectOptions() {
        setNewLoading(true);
        try {
            getCampaignOutboundListForAdmin().then(resp => {
                let TempArray = [];
                resp?.Data?.map((item) => {
                    if (item?.campaignType === "Preview" || item?.campaignType === "Progressive") {
                        const data = {
                            id: item?.campaignId,
                            campaignName: item?.campaignName,
                            campaignId: item?.campaignId,
                        }
                        TempArray.push(data);
                    }
                    return null;
                })
                setCampaignSelectOption(TempArray);
                setNewLoading(false);
            }).catch(e => {
                setCampaignSelectOption([]);
                showErrorNotification('No CampaignSelect Data, Check your Internet Connection');
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getDidOptions() {
        setNewLoading(true);
        let body = {};
        try {
            getDidDropdown(body).then(resp => {
                let newTempData = [];
                let newData = structuredClone(JSON.parse(resp.Data));
                newData?.map((item) => {
                    const data = {
                        did: item?.DID,
                        DidName: item?.DID,
                    }
                    newTempData.push(data);
                    return null;
                })
                setDidOption(newTempData);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No DID Data, Check your Internet Connection');
                setDidOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    async function getDispositionsOptions() {
        setNewLoading(true);
        try {
            getDispositionsDropdown().then(resp => {
                setDispositonsOption(resp.Data);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification('No Dispostions Data, Check your Internet Connection');
                setDispositonsOption([]);
                setNewLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setNewLoading(false);
        }
    }

    const onClickEdit = () => {
        setMode(ADMIN_FORM_MODES.edit);
    }

    const onClickDiscardEdit = () => {
        setMode(ADMIN_FORM_MODES.view);
    }

    // const openCopyCampaign = () => {
    //     setCopyCampaign(true);
    // }

    // const closeCopyCampaignForm = (props) => {
    //     setCopyCampaign(false);
    // }


    const DataForm = (props) => {
        let flowConditions = useMemo(
            () => (
                [{
                    caseConfigs: [
                        {
                            "channelType": "SMS",
                            "parameters": [
                                {
                                    "type": "Call Duration",
                                    "value": [],
                                    "condition": "",
                                    "operator": ""
                                },
                                {
                                    "type": "Disposition",
                                    "value": [],
                                    "condition": "",
                                    "operator": ""
                                },
                                {
                                    "type": "Call Status",
                                    "value": [],
                                    "condition": "",
                                    "operator": ""
                                }
                            ],
                            "template": {
                                "id": "",
                                "name": ""
                            },
                            "did": "",
                            "triggerConf": "",
                            "frequency": ""
                        },
                    ]
                }]
            ), []
        );

        if (props?.data) {
            if (props?.data?.flowConfigs?.length > 0) {
                let newFlowConfigs = structuredClone(props?.data?.flowConfigs);
                newFlowConfigs?.map((item, index) => {
                    item?.caseConfigs?.map((subItem, subIndex) => {
                        subItem?.parameters?.map((subsitem, subsIndex) => {
                            if (subsitem.type === "duration" && typeof subsitem.value !== 'object') {
                                let test = {};
                                if (subsitem.condition === '=') {
                                    test = { id: 'Equal to', operator: '=', value: subsitem.value };
                                } else if (subsitem.condition === '>') {
                                    test = { id: 'Greater than', operator: '>', value: subsitem.value };
                                } else if (subsitem.condition === '<') {
                                    test = { id: 'Less than', operator: '<', value: subsitem.value };
                                } else if (subsitem.condition === '>=') {
                                    test = { id: 'Greater than or equal to', operator: '>=', value: subsitem.value };
                                } else if (subsitem.condition === '<=') {
                                    test = { id: 'Less than or equal to', operator: '<=', value: subsitem.value };
                                }
                                subsitem['value'] = test
                            }
                            return null;
                        })
                        subItem?.parameters?.map((subsitem, subsIndex) => {
                            if (subsitem.type === "duration") {
                                subsitem.type = 'Call Duration';
                            }
                            if (subsitem.type === "disposition") {
                                subsitem.type = 'Disposition';
                            }
                            if (subsitem.type === "callStatus") {
                                subsitem.type = 'Call Status';
                            }
                            return null;
                        })
                        return null;
                    })
                    return null;
                })
                props.data.flowConfigs = newFlowConfigs;
            } else {
                if (props.data.flowConfigs === null) {
                    props.data.flowConfigs = flowConditions;
                }
            }
        }

        // console.log("here new data is ", props?.data)
        let initData = props.data || {
            flowName: "",
            actionType: "",
            flowConfigs: flowConditions,
            campaignIds: [],
            userID: getUserId(),
        };
        setLoading(false)

        let formHeading = useMemo(
            () => ({
                fontWeight: '400',
                fontSize: '22px',
                color: '#212121',
                fontStyle: 'normal',
                fontFamily: 'Roboto',
            }),
            []
        );
        // let copyStyle = useMemo(
        //     () => ({
        //         paddingLeft: '20px',
        //         fontSize: '14px',
        //         flex: 'right',
        //         color: '#536580',
        //     }),
        //     []
        // );

        const formButtonStyle = useMemo(
            () => ({
                borderRadius: '8px',
                fontWeight: '400',
                fontSize: '14px',
            }),
            []
        );
        const toggleStyle = useMemo(
            () => ({
                color: "#212121",
                fontFamily: "Roboto",
                fontSize: " 0.75rem",
                fontWeight: 400,
                letterSpacing: "0em",
                backgroundColor: "#F5F8FA",
                minWidth: "5.8125rem",
                height: "1.875rem",
                borderRadius: 0,
                '&.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: '#FFFF',
                },
            }),
            []
        );

        const handleChange = (event, newValue, props) => {
            setValue(newValue);
        }

        // const [leaveCampaign, setLeaveCampaign] = useState(false);

        // const LeaveCampaign = (props) => {
        //     const closeLeaveCampaign = () => {
        //         setOpenLeaveCampaign(false);
        //         props.onClose();
        //     }
        //     const [openLeaveCampaign, setOpenLeaveCampaign] = useState(true);

        //     return (
        //         <div>
        //             <LockedAndStatusConfirmationDialog
        //                 hideActions
        //                 title={
        //                     <div>
        //                         <div style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
        //                             <label>Leave Campaign?</label>
        //                         </div>
        //                     </div>
        //                 }
        //                 content={
        //                     <>
        //                         <div style={{ fontSize: '0.75rem', color: '#000000', fontWeight: '400' }}>
        //                             Your entries will be lost when you leave this page.
        //                         </div>
        //                         <div style={{ fontSize: '0.75rem', color: '#000000', fontWeight: '400' }}>
        //                             Are you sure you want leave the campaign ?
        //                         </div>
        //                         <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 1 }} justifyContent='end'>
        //                             <Grid item >
        //                                 <Button style={formButtonStyle} variant='outlined' type="submit" onClick={closeLeaveCampaign}>
        //                                     Cancel
        //                                 </Button>
        //                             </Grid>
        //                             <Grid item >
        //                                 <Button style={formButtonStyle} onClick={closeAddWorkForm} variant="contained" type="submit">
        //                                     Yes, Leave Campaign
        //                                 </Button>
        //                             </Grid>
        //                         </Grid>
        //                     </>
        //                 }
        //                 open={openLeaveCampaign}
        //                 onClose={closeLeaveCampaign}
        //             >
        //             </LockedAndStatusConfirmationDialog>
        //         </div>
        //     )
        // }

        // const openLeaveCampaignForm = () => {
        //     setLeaveCampaign(true);
        // }

        // const closeLeaveCampaignForm = () => {
        //     setLeaveCampaign(false);
        // }

        const PreCall = (props) => {
            const propsPre = props?.props;
            const [smsSelect, setSmsSelect] = useState(true);
            const [whatsAppSelect, setwhatsAppSelect] = useState(false);

            const HandleSwitchInPre = (e, value) => {
                if (value === "sms") {
                    setSmsSelect(e.target.checked);
                } else if (value === "whatsApp") {
                    setwhatsAppSelect(e.target.checked);
                }
            }

            return (
                <Stack>
                    <div style={{ width: '100%', minHeight: '300px', marginBottom: '20px', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#FBFCFC', borderRadius: '8px', border: '1px solid #D6D6D7' }}>
                        <div style={{ fontSize: '14px', fontWeight: '600', color: '#212121', marginTop: '5px' }}>
                            Pre-Call
                        </div>
                        <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                            <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginTop: '8px' }} />
                        </span>

                        <Grid style={{ fontSize: '12px', marginTop: '7px' }} container spacing={1} display='flex' direction={'row'}>
                            <Grid item xs={1} sx={{ fontSize: '14px', fontWeight: '600' }}>
                                Case 1
                            </Grid>
                            <Grid item xs={1} style={{ marginRight: '-40px' }}>
                                <Divider orientation='vertical' variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginRight: '80px' }} />
                            </Grid>
                            <Grid item xs={2} style={{ marginRight: '-40px' }}>
                                <div><SimpleSwitch flexGrow={1} checked={smsSelect} onChange={(e) => HandleSwitchInPre(e, 'sms')} /> SMS </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div> <SimpleSwitch flexGrow={1} checked={whatsAppSelect} onChange={(e) => HandleSwitchInPre(e, 'whatsApp')} /> WhatsApp</div>
                            </Grid>
                        </Grid>

                        {smsSelect &&
                            <div style={{ width: '100%', minHeight: '100px', paddingLeft: '10px', paddingRight: '10px', marginTop: '10px', backgroundColor: '#FFFFFF', borderRadius: '8px', border: '1px solid #D6D6D7' }}>
                                <div style={{ display: 'flex', alignContent: 'center' }}>
                                    <Typography sx={{
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        lineHeight: '28px',
                                        marginTop: '5px',
                                    }} flexGrow={1} >SMS</Typography>
                                    <img
                                        // style={additionalProps.imageStyle}
                                        src={`${process.env.PUBLIC_URL}/icons/delete-icon.svg`}
                                        alt="delete"
                                    ></img>
                                </div>
                                <Grid style={{ fontSize: '12px', marginTop: '5px', marginBottom: '25px' }} container spacing={2} display='flex' direction={'row'} >
                                    <Grid item xs={6} >
                                        <OZInput
                                            name="campaignType"
                                            label="Select Template "
                                            placeholder="Select Template "
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <OZInput
                                            name="campaignType"
                                            label="Select DID "
                                            placeholder="Select DID "
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        }

                        {whatsAppSelect &&
                            <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                                <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginTop: '15px' }} />
                            </span>
                        }

                        {whatsAppSelect &&
                            <div style={{ width: '100%', minHeight: '100px', paddingLeft: '10px', marginBottom: '15px', paddingRight: '10px', marginTop: '10px', backgroundColor: '#FFFFFF', borderRadius: '8px', border: '1px solid #D6D6D7' }}>
                                <div style={{ display: 'flex', alignContent: 'center' }}>
                                    <Typography sx={{
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        lineHeight: '28px',
                                        marginTop: '5px',
                                    }} flexGrow={1} >WhatsApp</Typography>
                                    <img
                                        // style={additionalProps.imageStyle}
                                        src={`${process.env.PUBLIC_URL}/icons/delete-icon.svg`}
                                        alt="delete"
                                    ></img>
                                </div>
                                <Grid style={{ fontSize: '12px', marginTop: '5px', marginBottom: '25px' }} container spacing={2} display='flex' direction={'row'} >
                                    <Grid item xs={6} >
                                        <OZInput
                                            name="campaignType"
                                            label="Select Template "
                                            placeholder="Select Template "
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <OZInput
                                            name="campaignType"
                                            label="Select DID "
                                            placeholder="Select DID "
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </div>

                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex justifyContent='end'>
                        <Grid item>
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={closeAddWorkForm} variant="outlined" type="submit">
                                Cancel
                            </Button>
                        </Grid>
                        {/* {leaveCampaign && (
                            <LeaveCampaign onClose={closeLeaveCampaignForm} />
                        )} */}
                        {/* <Grid item >
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={(mode === ADMIN_FORM_MODES.add) ? handleNext : null} variant="contained" type="submit" form="campaignInbound-form">
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Next'}
                            </Button>
                        </Grid> */}
                        <Grid item >
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={() => {
                                if (!propsPre.isValid) {
                                    propsPre.handleSubmit();
                                    showErrorNotification("Please fill all the mandatory field")
                                }
                            }} variant="contained" type={(propsPre.isValid) ? "submit" : null} form="addCallWork-Form">
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Submit'}
                            </Button>
                        </Grid>
                    </Grid>

                </Stack>
            );
        }

        const PostCall = (props) => {
            const propsPost = props?.props;
            return (
                <Stack>
                    <div style={{ width: '100%', minHeight: '260px', paddingLeft: '10px', paddingRight: '10px', marginTop: '10px', backgroundColor: '#FBFCFC', borderRadius: '8px', border: '1px solid #D6D6D7' }}>
                        <div style={{ fontSize: '14px', fontWeight: '600', color: '#212121', marginTop: '5px' }}>
                            Post-Call
                        </div>
                        <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                            <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginTop: '8px' }} />
                        </span>
                        <PostCallWorkConfig
                            dispositonsDropOptions={dispositonsOption}
                            smsTemplate={smsTemplateOption}
                            whatsappTemplate={whatsappTemplateOption}
                            didOptions={didOption}
                            disabled={mode}
                            formikProps={propsPost}
                        />
                    </div>

                    <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={(mode === ADMIN_FORM_MODES.add) ? { marginTop: 10, marginBottom: '10px' } : { marginTop: 10 }} justifyContent='end'>
                        <Grid item>
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={closeAddWorkForm} variant="outlined" type="submit">
                                Cancel
                            </Button>
                        </Grid>
                        {/* {leaveCampaign && (
                            <LeaveCampaign onClose={closeLeaveCampaignForm} />
                        )} */}
                        {/* <Grid item >
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={(mode === ADMIN_FORM_MODES.add) ? handleNext : null} variant="contained" type="submit" form="addCallWork-Form">
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Save'}
                            </Button>
                        </Grid> */}
                        <Grid item >
                            <Button style={formButtonStyle} disabled={mode === ADMIN_FORM_MODES.view} onClick={() => {
                                if (!propsPost.isValid) {
                                    propsPost.handleSubmit();
                                    showErrorNotification("Please fill all the mandatory field")
                                }
                            }} variant="contained" type={(propsPost.isValid) ? "submit" : null} form="addCallWork-Form">
                                {(mode === ADMIN_FORM_MODES.view || mode === ADMIN_FORM_MODES.edit) ? 'Save Changes' : 'Submit'}
                            </Button>
                        </Grid>
                    </Grid>

                </Stack>
            );
        }

        return (
            <div >
                <Formik
                    initialValues={initData}
                    validateOnMount
                    // validationSchema={validationSchema}
                    onSubmit={(values) => {
                        let saveData = structuredClone(values);
                        if (saveData.actionType === "") {
                            if (value === '1') {
                                saveData.actionType = "Post";
                            } else if (value === '0') {
                                saveData.actionType = 'Pre';
                            }
                        }
                        let flag = 0;
                        let didFlag = 0;
                        saveData.flowConfigs.map((item, mainindex) => {
                            if (item.caseConfigs) {
                                item.caseConfigs.map((subItem, subIndex) => {
                                    if (subItem?.triggerConf === "Minutes after the call") {
                                        if (subItem?.frequency === "") {
                                            flag = 1;
                                            showErrorNotification(`Time is needed for Case ${mainindex + 1} and subCase ${subItem.channelType}`);
                                            return null;
                                        } else if (subItem?.frequency <= 0) {
                                            flag = 1;
                                            showErrorNotification(`Time should be greater than be 0 for Case ${mainindex + 1} and subCase ${subItem.channelType}`);
                                            return null;
                                        }
                                    } else if (subItem?.triggerConf === "Instantly after the call") {
                                        subItem.frequency = "";
                                    }
                                    if (subItem.parameters) {
                                        subItem.parameters.map((subsitem, index) => {
                                            if (subsitem.type === "") {
                                                subsitem.value = "";
                                            } else if (subsitem.type !== "") {
                                                if (subsitem.type === "Call Duration") {
                                                    if (subsitem.value?.length === 0 || subItem.condition === "" || subsitem.operator === "" || subsitem?.value === []) {
                                                        flag = 1;
                                                        showErrorNotification(`please pass all values for duration for Case ${mainindex + 1} and subCase ${subItem.channelType}`);
                                                        return null;
                                                    } else {
                                                        subsitem.condition = subsitem.value.operator;
                                                        subsitem.value = subsitem.value.value;
                                                    }
                                                } else if (subsitem.type === "Disposition") {
                                                    if (subsitem.value?.length === 0 || subsitem.operator === "" || subsitem?.value === []) {
                                                        flag = 1;
                                                        showErrorNotification(`please pass all values for disposition for Case ${mainindex + 1} and subCase ${subItem.channelType}`);
                                                        return null;
                                                    }
                                                    subsitem.condition = "in";
                                                } else if (subsitem.type === "Call Status") {
                                                    if (subsitem.value?.length === 0 || subsitem.operator === "" || subsitem?.value === []) {
                                                        flag = 1;
                                                        showErrorNotification(`please pass all values for callStatus for Case ${mainindex + 1} and subCase ${subItem.channelType}`);
                                                        return null;
                                                    }
                                                    subsitem.condition = "in";
                                                }
                                            }
                                            return null;
                                        })
                                    }
                                    if (subItem?.did === "" || subItem?.did?.length === 0 || subItem?.did === []) {
                                        didFlag = 1;
                                        showErrorNotification(`Please select DID for Case ${mainindex + 1}`);
                                        return null;
                                    } else {
                                        subItem.did = subItem?.did?.did;
                                    }
                                    return null;
                                })
                            }
                            return null;
                        })
                        saveData?.flowConfigs?.map((item) => {
                            item?.caseConfigs?.map((subItem) => {
                                subItem?.parameters.map((subSubItem) => {
                                    if (subSubItem?.type === 'Call Status') {
                                        subSubItem.type = 'callStatus';
                                    } else if (subSubItem?.type === 'Call Duration') {
                                        subSubItem.type = 'duration';
                                    } else if (subSubItem?.type === 'Disposition') {
                                        subSubItem.type = 'disposition';
                                    }
                                    return null;
                                })
                                return null;
                            })
                            return null;
                        })
                        if (flag !== 0 || didFlag !== 0) {
                            showErrorNotification("Please fill all the required Parameters");
                            return null;
                        } else if (flag === 0 || didFlag === 0) {
                            if (mode === ADMIN_FORM_MODES.add) {
                                addPostAddWorkForm(saveData);
                            } else if (mode === ADMIN_FORM_MODES.edit) {
                                updatePostAddWorkForm(saveData);
                            }
                        }
                    }}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit} id="addCallWork-Form" className='ozcw-scrollbar' style={{
                                maxHeight: 'auto',
                                overflowY: 'auto',
                                padding: '1px',
                            }}>
                            {loading ?
                                (
                                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <LoadingScreen />
                                    </div>
                                )
                                :
                                (
                                    <div style={{ width: '60vw', minHeight: '23rem', borderRadius: '0px 0px 8px 8px', background: '#FFFFFF' }}>
                                        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                                            <div style={{ display: 'flex', alignContent: 'center', paddingTop: '15px' }}>
                                                <Typography sx={formHeading} flexGrow={1}>Call Work Flow</Typography>
                                                {mode !== ADMIN_FORM_MODES.add && (
                                                    <div>
                                                        {/* {authPage(AdminPages.SIPLOCATIONS_VIEW) && (
                                                            <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                                                <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit' : 'Discard Edit'}</label>
                                                            </div>
                                                        )} */}
                                                        {(newAuthPages(NewAdminPages.PREPOST_PAGE) || newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'Full')) && (
                                                            <div style={{ fontSize: '14px', color: (mode === ADMIN_FORM_MODES.view) ? '#536580' : '#3D8BF8' }} onClick={(mode === ADMIN_FORM_MODES.view) ? onClickEdit : onClickDiscardEdit}>
                                                                <label style={{ cursor: "pointer" }}>{(mode === ADMIN_FORM_MODES.view) ? 'Edit' : 'Discard Edit'}</label>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <Grid style={{ marginTop: '12px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                                <Grid item xs={6}>
                                                    <OZInput
                                                        disabled={mode === ADMIN_FORM_MODES.view}
                                                        name="flowName"
                                                        label="Call Work name"
                                                        placeholder="Call Work Name"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CustomAutoComplete
                                                        disabled={mode === ADMIN_FORM_MODES.view}
                                                        mode='Add'
                                                        name="campaignIds"
                                                        limitTags={2}
                                                        placeholder={"Select Campaign"}
                                                        Datas={campaignSelectOption}
                                                        labelHeader='Select Campaign'
                                                        optionLabelName='campaignName'
                                                        showCheckBox={true}
                                                        multiple={true}
                                                        showselectall="true"
                                                        popupIcon={null}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div style={{ fontSize: '14px', display: 'flex', marginTop: '20px', color: '@212121' }}>
                                                <label>Call type</label>
                                            </div>
                                        </div>
                                        <TabContext value={value}>
                                            <Box sx={{ borderColor: 'divider', borderRadius: '0px 0px 3px 3px', marginTop: '10px', marginLeft: '22px' }} >
                                                <TabList
                                                    TabIndicatorProps={{
                                                        style: { backgroundColor: "#008EFF", marginBottom: "1.125rem" },
                                                    }}
                                                    value={value}
                                                    onChange={(e, newvalue) => (mode === ADMIN_FORM_MODES.view) ? null : handleChange(e, newvalue, props)}
                                                >
                                                    {/* <OZCustomToggleButton label="PreCall" value="0" sx={{ ...toggleStyle, borderRight: 0 }}>Pre - Call</OZCustomToggleButton> */}
                                                    <OZCustomToggleButton label="PostCall" value="1" sx={{ ...toggleStyle }}>Post - Call</OZCustomToggleButton>
                                                </TabList>
                                            </Box>
                                            <div style={{ display: 'flex', alignContent: 'center' }}>
                                                <div style={{ width: '60vw', minHeight: '23rem', borderRadius: '0px 0px 8px 8px', backgroundColor: '#FFFFFF' }}>
                                                    <TabPanel value="0">
                                                        <PreCall props={props} />
                                                    </TabPanel>
                                                    <TabPanel value="1">
                                                        <PostCall props={props} />
                                                    </TabPanel>
                                                </div>
                                            </div>
                                        </TabContext>
                                    </div>
                                )
                            }
                            {newLoading && (
                                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                    <LoadingScreen />
                                </div>
                            )}
                        </form>
                    )}
                </Formik>
            </div >
        );
    }
    return (
        <div onClose={closeAddWorkForm}
            mode={mode}
        >
            {mode === ADMIN_FORM_MODES.add && (
                <div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} alignSelf='center' onClick={closeAddWorkForm}>{'Configuration / ' + mode + ' Call Work'}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px',
                                color: '#000000',
                            }} flexGrow={1} alignSelf='center'>{mode + ' Call Work'}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.view && (
                <div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} onClick={closeAddWorkForm} alignSelf='center'>{'Configuration /  Call Work'}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px'
                            }} flexGrow={1} alignSelf='center' >{props.data.flowName}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.edit && (
                <div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '14ppx',
                                color: '#212121',
                                cursor: "pointer",
                            }} flexGrow={1} alignSelf='center' onClick={closeAddWorkForm}>{'Configuration / Call Work'}</Typography>
                        </Stack>
                    </div>
                    <div>
                        <Stack direction={'row'} marginBottom='10px' marginTop={'10px'}>
                            <Typography sx={{
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '24px',
                                lineHeight: '28px'
                            }} flexGrow={1} alignSelf='center'>{props.data.flowName}</Typography>
                        </Stack>
                    </div>
                </div>
            )}

            {mode === ADMIN_FORM_MODES.view && <DataForm data={props.data} />}
            {mode === ADMIN_FORM_MODES.add && <DataForm />}
            {mode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}

        </div>
    );
};

export default AddCallWorkForm;
