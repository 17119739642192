import { forwardRef, useCallback } from 'react'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { SnackbarContent, } from 'notistack'
let snackBarRef = null;
let closeSnacBarRef = null;

export function setSnackBarRef(value) {
    snackBarRef = value;
}

export function setCloseSnackBarRef(value) {
    closeSnacBarRef = value;
}



const CustomSnackBar = forwardRef((props, ref) => {
    let notficationColor = props.variant === 'success' ? '#70B173' : '#E65B5C'
    const successIcon = <CheckCircleIcon sx={{ color: notficationColor }} />
    const infoIcon = <InfoIcon sx={{ color: '#3296FB' }} />
    const warningIcon = <WarningIcon sx={{ color: '#E0A751', fontSize: '22px' }} />
    const deleteIcon = <RemoveCircleIcon sx={{ color: notficationColor }} />
    const errorIcon = <ErrorIcon sx={{ color: notficationColor }} />
    let icons = { 'success': successIcon, 'info': infoIcon, 'warn': warningIcon, 'delete': deleteIcon, 'error': errorIcon }

    const handleDismiss = useCallback(() => {
        closeSnacBarRef(props.id);
    }, [props.id]);

    return (
        <SnackbarContent ref={ref} role="alert" >
            <div style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                border: `1px solid ${notficationColor}`,
                minWidth: '20rem',
                width: '30rem',
                overflowWrap: 'anywhere',
                maxWidth: 'calc(100% - 32px)',
                borderRadius: '8px',
                overflow: 'auto',
                // boxShadow: '0px 3px 6px gray',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
                padding: '5px 16px 5px 16px'
            }}
            >
                {icons[props.variant]}
                {/* <Typography fontSize={'14px'} fontWeight={400} lineHeight={'16px'} color='#212121' margin={'10px'} flexGrow={1} >
                    {props.message}
                </Typography> */}
                <div style={{ minWidth: '20rem', maxWidth: 'calc(100% - 32px)', maxHeight: '25rem', display: 'flex' }}>
                    <Typography fontSize={'14px'} fontWeight={400} lineHeight={'16px'} color='#212121' margin={'10px'} flexGrow={1} >
                        {props.message}
                    </Typography>
                </div>
                {props.action &&
                    <>
                        <Divider orientation='vertical' color='#536580' sx={{ height: '70%', marginRight: '8px' }} />
                        <Button
                            variant='text'
                            size='small'
                            sx={{
                                fontWeight: '600',
                                color: '#536580',
                                fontSize: 14,
                                minWidth: '45px'
                            }}>
                            undo
                        </Button>
                    </>
                }
                <IconButton
                    aria-label="close"
                    onClick={handleDismiss}
                    sx={{
                        color: '#99A0A8',
                        padding: 0
                    }}
                >
                    <CloseIcon sx={{ fontSize: '20px' }} />
                </IconButton>
            </div>
        </SnackbarContent>
    )
})
export function showSuccessNotification(message) {
    if (snackBarRef != null) {
        snackBarRef(message, {
            variant: 'success', content: (key, message) => (
                <CustomSnackBar id={key} message={message} variant={'success'} />
            ),
        })
    }
}

export function showWarningNotification(message) {
    if (snackBarRef != null) {
        snackBarRef(message, {
            variant: 'warn', content: (key, message) => (
                <CustomSnackBar id={key} message={message} variant={'warn'} />
            ),
        })
    }
}

export function showErrorNotification(message) {
    if (snackBarRef != null) {
        snackBarRef(message, {
            variant: 'error', content: (key, message) => (
                <CustomSnackBar id={key} message={message} variant={'error'} />
            ),
        })
    }
}

export function showInfoNotification(message) {
    if (snackBarRef != null) {
        snackBarRef(message, {
            variant: 'info', content: (key, message) => (
                <CustomSnackBar id={key} message={message} variant={'info'} />
            ),
        })
    }
}


export function showStatusNotification(message) {
    if (snackBarRef != null) {
        snackBarRef(message, {
            variant: 'delete', content: (key, message) => (
                <CustomSnackBar id={key} message={message} variant={'delete'} />
            ),
        })
    }
}