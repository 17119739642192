import React, { useState, useMemo, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Grid, } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import { addNewCampaignInbound, getRowDataListForCampaignId, addNewCampaignOutbound, addNewMarketingFormDigitalCampaign, addNewMarketingCampaign, getDIDInMissedCallDropdown, getSecondaryDidDropdown } from '../../../services/api-service';
import OZDialog from "../../../components/admin/common/OZDialog";
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import OZSelect from '../../../components/admin/common/OZSelect';

const CopyCampaignForm = (props) => {
    const [openCopyCampaignForm, setCopyCampaignForm] = useState(true);
    const [didInMissedCallOption, setDidInMissedCallOption] = useState([]);

    const validationSchema = useMemo(() => (yup.object({
        // did: yup
        //     .number()
        //     .typeError('The value must be a number')
        //     .integer('The value must be a number')
        //     .required('This field is required'),
        did: yup
            .mixed()
            .when("type", {
                is: type => type === 'Chat',
                then: yup
                    .string("Enter Campaign Identifier")
                    .nullable()
                    .min(4, "'Campaign Identifier should be between 4 to 12 digits long and allows prefix +")
                    .max(13, "'Campaign Identifier should be between 4 to 12 digits long and allows prefix +")
                    .matches(/^([0-9]+|^\+[0-9]+)$/, 'Campaign Identifier should be between 4 to 12 digits long and allows prefix +')
                    .required("Campaign Identifier is required"),
                otherwise: yup
                    .mixed()
                    .notRequired()
                    .when("type", {
                        is: type => type === 'InBound',
                        then: yup
                            .number("Enter DID")
                            .test("maxDigits",
                                "DID should be between 3 to 16 digits long and allows prefix +",
                                (number) => String(number).length >= 3 && String(number).length <= 16)
                            .typeError('The value must be a number')
                            .integer('The value must be a number')
                            .required('DID is required'),
                        otherwise: yup
                            .mixed()
                            .notRequired()
                            .when("type", {
                                is: type => type === 'SMS_OUT',
                                then: yup
                                    .string()
                                    .typeError('Sender ID must be in uppercase.')
                                    .required("Sender ID is required")
                                    .min(6, "Sender Id length is invalid.It should be of 6 characters")
                                    .max(6, "Sender Id length is invalid.It should be of 6 characters")
                                    .matches(/^[A-Z]+$/, 'Sender ID must be in uppercase.'),
                                otherwise: yup
                                    .mixed()
                                    .notRequired()
                                    .when("type", {
                                        is: type => type === 'MissedCall',
                                        then: yup
                                            .mixed()
                                            .required("DID is required."),
                                        otherwise: yup
                                            .mixed()
                                            .notRequired()
                                            .when('type', {
                                                is: type => type === "PushData",
                                                then: yup
                                                    .mixed()
                                                    .notRequired(),
                                                otherwise: yup
                                                    .mixed()
                                                    .notRequired()
                                                    .when('type', {
                                                        is: type => type === "Preview" || type === "IVR" || type === "Predictive" || type === "Progressive",
                                                        then: yup
                                                            .number("Enter DID")
                                                            .test("maxDigits",
                                                                "DID should be between 3 to 16 digits long and allows prefix +",
                                                                (number) => String(number).length >= 3 && String(number).length <= 16)
                                                            .typeError('The value must be a number')
                                                            .integer('The value must be a number')
                                                            .required('DID is required'),
                                                        otherwise: yup
                                                            .mixed()
                                                            .notRequired(),
                                                    })
                                            })
                                    })
                            })
                    })
            }),

    })), []);

    const closeCopyCampaignForm = () => {
        setCopyCampaignForm(false);
        props.onClose();
    }

    useEffect(() => {
        getDidInMissecCallOptions()
        getSecondaryDidOptions()
    }, [])

    const [secondaryDidOption, setSecondaryDidOption] = useState([]);
    async function getSecondaryDidOptions() {
        try {
            getSecondaryDidDropdown().then(resp => {
                const newDIArray = resp.Data !== null ? JSON.parse(resp.Data).map(item => item?.DID) : [];
                setSecondaryDidOption(newDIArray);
            }).catch(e => {
                setSecondaryDidOption([]);
                showErrorNotification('No Secondary DID Data, Check your Internet Connection');
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function getDidInMissecCallOptions() {
        try {
            getDIDInMissedCallDropdown().then(resp => {
                setDidInMissedCallOption(resp.Data);
            }).catch(e => {
                showErrorNotification('No DID , Check your Internet Connection');
                setDidInMissedCallOption({});
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    const formButtonStyle = useMemo(
        () => ({
            borderRadius: '8px',
            fontWeight: '400',
            fontSize: '14px',
        }),
        []
    );

    async function saveCopy(id, values) {
        try {
            getRowDataListForCampaignId(values.id).then(resp => {
                let data = resp.Data[0];
                delete data.campaignId;
                delete data.id;
                data.campaignName = values.campaignName;
                data.did = values.did;
                if (data.campaignType === 'InBound') {
                    addNewCampaignInbound(data).then(resp => {
                        if (resp.Status === 'Success') {
                            showSuccessNotification(`Campaign ${data.campaignName} Created Successfully `);
                            props.updateCopyCampaign();
                            closeCopyCampaignForm();
                        } else {
                            showErrorNotification(JSON.stringify(resp.Message));
                        }

                    }).catch(e => {
                        showErrorNotification(JSON.stringify(e));
                    })
                }
                else if (data.campaignType === "Predictive" || data.campaignType === "Progressive" || data.campaignType === "Preview" || data.campaignType === 'IVR') {
                    addNewCampaignOutbound(data).then(resp => {
                        if (resp.Status === 'Success') {
                            showSuccessNotification(`Campaign ${data.campaignName} Created Successfully `);
                            props.updateCopyCampaignOutbound();
                            closeCopyCampaignForm();
                        } else {
                            showErrorNotification(JSON.stringify(resp.Message));
                        }
                    }).catch(e => {
                        showErrorNotification(JSON.stringify(e));
                    })
                }
                else if (data.campaignType === "Chat" || data.campaignType === "MissedCall" || data.campaignType === 'PushData' || data.campaignType === 'SMS_OUT') {
                    if (data.campaignType === "Chat") {
                        addNewMarketingFormDigitalCampaign(data).then(resp => {
                            if (resp.Status === 'Success') {
                                showSuccessNotification(`Campaign ${data.campaignName} Created Successfully `);
                                // props.updateCopyCampaignMarketing();
                                props.updateCopyCampaign();
                                closeCopyCampaignForm();
                            } else {

                                showErrorNotification(JSON.stringify(resp.Message));
                            }
                        }).catch(e => {
                            showErrorNotification(JSON.stringify(e));
                        })
                    }
                    else {
                        if (data.campaignType === 'PushData') {
                            delete data.did;
                        }
                        addNewMarketingCampaign(data).then(resp => {
                            if (resp.Status === 'Success') {
                                showSuccessNotification(`Campaign ${data.campaignName} Created Successfully `);
                                props.updateCopyCampaignMarketing();
                                closeCopyCampaignForm();
                            } else {
                                showErrorNotification(JSON.stringify(resp.Message));
                            }
                        }).catch(e => {
                            showErrorNotification(JSON.stringify(e));
                        })
                    }
                }

            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    const DataForm = (props) => {
        let initData = {}
        initData.campaignName = props.data.campaignName + '_Copy';
        initData.id = props.data.id;
        initData.type = props.data.type;
        initData.did = "";

        function getDidName() {
            if (initData?.type === 'Chat') {
                return "Campaign Identifier *";
            }
            else if (initData?.type === 'SMS_OUT') {
                return "Sender ID *";
            } else {
                return "DID *";
            }
        }

        function getPlaceHolderDid() {
            if (initData?.type === 'Chat') {
                return "Campaign Identifier.";
            }
            else if (initData?.type === 'SMS_OUT') {
                return "Sender ID";
            } else {
                return "DID no.";
            }
        }

        return (
            <div >
                <Formik
                    initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        let saveData = structuredClone(values);
                        if (saveData.type === 'MissedCall') {
                            saveData.did = saveData.did.did;
                        }
                        delete saveData.type;
                        saveCopy(props.id, saveData);
                    }}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit} id="copyCampaign-Form" className='ozcw-scrollbar' style={{
                                maxHeight: '80vh',
                                overflowY: 'auto',
                            }}>
                            <Grid container spacing={3} flexGrow={1}>
                                <Grid item xs={12} >
                                    <OZInput
                                        name='campaignName'
                                        label="Campaign Name"
                                    />
                                </Grid>
                                {props?.values?.type !== "PushData" && props?.values?.type !== "MissedCall" &&
                                    <Grid item xs={12} >
                                        <OZSelect
                                            name="did"
                                            options={secondaryDidOption}
                                            label={getDidName()}
                                            placeholder={getPlaceHolderDid()}
                                        />
                                    </Grid>
                                }
                                {props?.values?.type === "MissedCall" &&
                                    <Grid item xs={12} >
                                        <OZSelect
                                            name="did"
                                            label={getDidName()}
                                            optionLabel='did'
                                            options={didInMissedCallOption}
                                            placeholder={getPlaceHolderDid()}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 3 }} justifyContent='end'>
                                <Grid item>
                                    <Button style={formButtonStyle} variant='outlined' onClick={closeCopyCampaignForm}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item >
                                    <Button style={formButtonStyle} variant="contained" type="submit" form='copyCampaign-Form'>
                                        Copy Campaign
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </div >
        );
    }
    return (
        <div>
            <OZDialog
                hideActions
                title={
                    <div>
                        <div style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
                            <label>Copy Campaign</label>
                        </div>
                        <div style={{ fontSize: '0.75rem', color: '#99A0A8', fontWeight: '400' }}>
                            Please Confirm the name and DID for the duplicate Campaign
                        </div>
                    </div>
                }
                open={openCopyCampaignForm}
                onClose={closeCopyCampaignForm}
            >
                <DataForm data={props} />
            </OZDialog>

        </div>
    );
};

export default CopyCampaignForm;
