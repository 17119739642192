import * as React from 'react';
import '../Login/Login.css';

function ResetPassword() {
	return (
		<>
			<div className="login_container">
				<div className="login-left">
					<img src={`${process.env.PUBLIC_URL}/img/ozonetel-logo.svg`} alt="Ozonetel"></img>
					<div className="login-title">Modernize your Call Center Software today!</div>
					<div className="login-sub-title">Enterprise-grade contact center suite built for fast-growing businesses</div>
				</div>
				<div className="login-right">
					<div className="forget-password-bx">
						<div className="login-form-title text-center">Reset Password</div>
						<img src={`${process.env.PUBLIC_URL}/img/forget-pwd2.svg`} alt="Forgot Password"></img>
						<div className="ozonetel-form-control">
							<label className="form-label">New password</label>
							<input type="text" placeholder="Enter new password " />
						</div>

						<div className="ozonetel-form-control">
							<label className="form-label">Confirm Password</label>
							<input type="text" placeholder="Enter new password " />
						</div>

						<button className="loginBtn">Request for OTP</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default ResetPassword;
