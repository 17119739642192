import React from 'react';
import { Box, Tooltip} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: '16px',
  },
  progressContainer: {
    display: 'flex',
    height: '20px',
    minWidth: '200px',
    // borderRadius: '10px',
    overflow: 'hidden',
    backgroundColor: '#e0e0e0', // Grey background for the progress bar
    // marginBottom: '6px',
  },
  progressSegment: {
    height: '100%',
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  ready: {
    backgroundColor: '#E3F8DC', // Green
  },
  pause: {
    backgroundColor: '#E3ECFD', // Yellow
  },
  aux: {
    backgroundColor: '#FDEED8', // Blue
  },
  busy: {
    backgroundColor: '#F6E8E9', // Red
  },
  calling: {
    backgroundColor: '#B2A1F9' //
  },
  acw: {
    backgroundColor: '#C39D80'
  }
});

const AgentStaffedProgress = ({ data }) => {
  const classes = useStyles();
  const total = Object.values(data).reduce((sum, value) => sum + parseInt(value, 10), 0);

  return (
    <>
      <Box className={classes.progressContainer}>
        {(data.CALLING === 0 || data.CALLING === '0') &&(data.AUX === 0 || data.AUX === '0') && (data.Busy === 0 || data.Busy === '0') && (data.Pause === 0 || data.Pause === '0') && (data.Ready === 0 || data.Ready === '0') && (data.ACW === 0 || data.ACW === '0') ? 
      <></>
      :
      <>

        {Object.keys(data).map((key, index) => {
          const value = parseInt(data[key], 10);
          const percentage = (value / total) * 100;
          let colorClass = '';

          switch (key) {
            case 'Ready':
              colorClass = classes.ready;
              break;
            case 'Pause':
              colorClass = classes.pause;
              break;
            case 'AUX':
              colorClass = classes.aux;
              break;
            case 'Busy':
              colorClass = classes.busy;
              break;
            case 'ACW':
              colorClass = classes.acw;
              break;
            case 'CALLING':
              colorClass = classes.calling;
              break;
            default:
              break;
          }

          return(
            <>
            <Tooltip title={`${key}: ${data[key]}`}>
              <div
                key={index}
                className={`${classes.progressSegment} ${colorClass}`}
                style={{ width: `${percentage === 0 || percentage === '0' ? percentage : percentage + 10}%` , paddingTop: '3px'}}
              >
                {data[key]}
              </div>
              </Tooltip>
            </>
          )

        })}
      </>
      }
      </Box>
    </>
  )
};

export default AgentStaffedProgress