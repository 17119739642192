import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useMemo, useRef, useState } from "react"
import * as yup from "yup";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import { addReportForm } from "../../../services/api-service";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import { Formik } from "formik";
import OZInput from "../../../components/admin/common/OZInput";
import OZSelect from "../../../components/admin/common/OZSelect";
import OZDialog from "../../../components/admin/common/OZDialog";
import OZDrawer from "../../../components/admin/common/OZDrawer/OZDrawer";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import '../addWidget/WidgetReportForm.css';

const ReportViewDrawer = (reportViewProps) => {

    let styleViewButton = useMemo(() => ({ fontfamily: 'Roboto', fontstyle: 'normal', fontweight: '400', fontsize: '14px', lineheight: '16px' }),
        []
    );

    return (<div style={{ maxHeight: "60vh", overflowY: "auto", padding: "10px", width: "29rem", }}>
        <Grid container={true} spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop={"0.313rem"}>
                    <label className="oz-widgetReportForm-View-Header">Report ID</label>
                </Grid>
                <label className="oz-widgetReportForm-View-Value">{reportViewProps?.data.reportID}</label>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop={"0.313rem"}>
                    <label className="oz-widgetReportForm-View-Header">Report Name</label>
                </Grid>
                <label className="oz-widgetReportForm-View-Value">{reportViewProps?.data.reportName}</label>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop={"0.313rem"}>
                    <label className="oz-widgetReportForm-View-Header">Report Query</label>
                </Grid>
                <label className="oz-widgetReportForm-View-Value">{reportViewProps?.data.reportQuery}</label>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop={"0.313rem"}>
                    <label className="oz-widgetReportForm-View-Header">Report Params</label>
                </Grid>
                <label className="oz-widgetReportForm-View-Value">{reportViewProps?.data.reportParams}</label>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop={"0.313rem"}>
                    <label className="oz-widgetReportForm-View-Header">Report Location</label>
                </Grid>
                <label className="oz-widgetReportForm-View-Value">{reportViewProps?.data?.reportLocation}</label>
            </Grid>
        </Grid>

    </div>)
}

const ReportForm = (props) => {
    const widgetLocationNames = [
        { apiLocation: 0, Location: 'DashboardDB' },
        { apiLocation: 1, Location: 'MDL' },
        { apiLocation: 2, Location: 'RedisConnection' },
        { apiLocation: 3, Location: 'CloudagentDB' },
        { apiLocation: 4, Location: 'UpdateMonitor' }
    ]

    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add;

    const [reportFormMode, setReportFormMode] = useState(initMode);
    const [ReportFormAnchorEl, setReportAnchorEl] = useState(false);
    const [open, setState] = useState(true);
    const anchorRef = useRef();
    const [openDeleteDialog, setDeleteDialog] = useState(false);

    if (reportFormMode === ADMIN_FORM_MODES.edit || reportFormMode === ADMIN_FORM_MODES.view) {

        if (props.data.reportLocation === 0) {
            props.data.reportLocation = "DashboardDB"
        }
        else if (props.data.reportLocation === 1) {
            props.data.reportLocation = "MDL"
        }
        else if (props.data.reportLocation === 2) {
            props.data.reportLocation = "RedisConnection"
        }
        else if (props.data.reportLocation === 3) {
            props.data.reportLocation = "CloudagentDB"
        }
        else if (props.data.reportLocation === 4) {
            props.data.widgetLocation = "UpdateMonitor"
        }
    }

    if (props.data) {
        widgetLocationNames.forEach((item) => {
            if (item.apiLocation === props.data.apiLocation) {
                props.data.apiLocation = item;
            }
        });
    }

    const validationSchema = useMemo(() =>
        yup.object({
            apiID: yup
                .number("Enter apiID")
                .typeError("Must be a number")
                .required("apiID is required"),
            apiName: yup
                .string("Enter apiName")
                .required("apiName is required"),
            apiQuery: yup
                .string("Enter apiQuery")
                .required("apiQuery is required"),
            apiParams: yup
                .string("Enter apiParams")
                .required("apiParams are required"),
            apiLocation: yup
                .object()
                .nullable()
                .required("apiLocation is required"),
        }), []
    );

    let styleEditButton = useMemo(() => ({
        fontWeight: '400',
        fontSize: '14px',
        color: '#3D8BF8',
    }), []
    );

    const viewModemorevertclick = () => {
        setTimeout(() => setReportAnchorEl(anchorRef.current), 1);
    };

    const handleMenuClose = () => {
        setReportAnchorEl(false);
    };

    const closeReportForm = () => {
        props.onClose();
        setState(false);
    }

    const onClickEditButton = () => {
        setReportFormMode(ADMIN_FORM_MODES.edit);
    }

    const onClickDiscardButton = () => {
        setReportFormMode(ADMIN_FORM_MODES.view);
    }

    function onOpenDeleteDialog() {
        setReportAnchorEl(false);
        setDeleteDialog(true);
    }

    const deleteDashboardReport = () => {
        setReportAnchorEl(false);
        props.delFunction(props.data.reportID, props.data.reportName);
        setDeleteDialog(false);
    }



    async function addWidgetReport(data) {
        data.apiLocation = data.apiLocation.apiLocation;
        try {
            addReportForm(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeReportForm();
                        props.Report();
                        showSuccessNotification(`${data.apiID} has been added Successfully`)
                    }
                    else {
                        showErrorNotification(JSON.stringify(resp.Message));
                    }
                }).catch((e) => {
                    showErrorNotification(JSON.stringify(e))
                })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    async function updateReportForm(data) {
        let reportLocation = null

        if (data.reportLocation === "DashboardDB" || data.reportLocation.Location === "DashboardDB") {
            reportLocation = 0
        }
        else if (data.reportLocation === "MDL" || data.reportLocation.Location === "MDL") {
            reportLocation = 1
        }
        else if (data.reportLocation === "RedisConnection" || data.reportLocation.Location === "RedisConnection") {
            reportLocation = 2
        }
        else if (data.reportLocation === "CloudagentDB" || data.reportLocation.Location === "CloudagentDB") {
            reportLocation = 3
        }
        else if (data.reportLocation === "UpdateMonitor" || data.reportLocation.Location === "UpdateMonitor") {
            reportLocation = 4
        }

        data.reportLocation = reportLocation

        const newResponse = {
            action: 'update',
            type: 'report',
            apiID: data.reportID,
            apiName: data.reportName,
            apiQuery: data.reportQuery,
            apiParams: data.reportParams,
            apiLocation: data.reportLocation
        }

        try {
            addReportForm(newResponse)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeReportForm();
                        props.Report();
                        showSuccessNotification(`${newResponse.apiName} has been updated Successfully`);
                    }
                    else {
                        showErrorNotification(JSON.stringify(resp.Message));
                    }
                }).catch((e) => {
                    showErrorNotification(JSON.stringify(e));

                })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    let buttonStyle = useMemo(() => ({
        borderRadius: "0.5rem",
        height: "2.75rem",
        fontSize: "0.875rem",
        fontWeight: 400,
    }), []
    );


    const styleMenuItemButton = useMemo(
        () => ({
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "8px",
            },
        }),
        []
    );


    const DataForm = (props) => {
        let initData = props.data ||
        {
            apiID: null,
            apiName: '',
            apiQuery: '',
            apiParams: '',
            apiLocation: null,
            type: null,
            action: (reportFormMode === ADMIN_FORM_MODES.add) ? 'save' : 'update'
        };


        let style1 = useMemo(() => ({
            width: '400px',
            height: "20rem",
            paddingtop: "10px"

        }), [])

        let style2 = useMemo(() => ({
            height: '68vh',
            padding: '5px'
        }), [])


        return (<div className="ozReportAddForm">
            <Formik
                initialValues={initData}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    let finalData = structuredClone(values)
                    if (reportFormMode === ADMIN_FORM_MODES.add) {
                        addWidgetReport(finalData);
                    }
                    else if (reportFormMode !== ADMIN_FORM_MODES.add) {
                        updateReportForm(finalData);

                    }
                }}
            >
                {(props) => (<form
                    onSubmit={props.handleSubmit}
                    id="reportForm"
                    className="ozcw-scrollbar"
                    style={reportFormMode === ADMIN_FORM_MODES.add ? style1 : style2}>
                    <Grid container spacing={2}>
                        {(reportFormMode !== ADMIN_FORM_MODES.edit) && <Grid item xs={12} sm={6} md={4} lg={12} >
                            <OZInput name={reportFormMode === ADMIN_FORM_MODES.add ? 'apiID' : 'reportID'} label={reportFormMode === ADMIN_FORM_MODES.add ? 'api ID' : 'Report ID'} placeholder="ID" type="number" />
                        </Grid>}
                        <Grid item xs={12} sm={6} md={4} lg={12} >
                            <OZInput name={reportFormMode === ADMIN_FORM_MODES.add ? 'apiName' : 'reportName'} label={reportFormMode === ADMIN_FORM_MODES.add ? 'api Name' : 'Report Name'} placeholder="Name" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={12} >
                            <OZInput name={reportFormMode === ADMIN_FORM_MODES.add ? 'apiQuery' : 'reportQuery'} label={reportFormMode === ADMIN_FORM_MODES.add ? 'api Query' : 'Report query'} placeholder="Query" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={12} >
                            <OZInput name={reportFormMode === ADMIN_FORM_MODES.add ? 'apiParams' : 'reportParams'} label={reportFormMode === ADMIN_FORM_MODES.add ? 'api Params' : 'Report Params'} placeholder="Params" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={12} >
                            <OZSelect
                                placeholder="Select Location "
                                name={reportFormMode === ADMIN_FORM_MODES.add ? 'apiLocation' : 'reportLocation'}
                                label={reportFormMode === ADMIN_FORM_MODES.add ? 'api Location' : 'report location'}
                                optionLabel="Location"
                                options={[
                                    { apiLocation: 0, Location: 'DashboardDB' },
                                    { apiLocation: 1, Location: 'MDL' },
                                    { apiLocation: 2, Location: 'RedisConnection' },
                                    { apiLocation: 3, Location: 'CloudagentDB' },
                                    { apiLocation: 4, Location: 'UpdateMonitor' }
                                ]} />
                        </Grid>
                        {(reportFormMode !== ADMIN_FORM_MODES.edit) && <Grid item xs={12} sm={6} md={4} lg={12} >
                            <OZSelect
                                placeholder="Select Type "
                                name="type"
                                label="type"
                                options={['widget', 'report']} />
                        </Grid>}
                    </Grid>
                    {
                        reportFormMode === ADMIN_FORM_MODES.edit &&
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            <div className="oz-administration-view-drawer-buttons-container">
                                <Button sx={buttonStyle} variant="outlined" onClick={closeReportForm}>
                                    Cancel
                                </Button>
                                <Button sx={{ ...buttonStyle, marginLeft: "10px" }} variant="contained" onClick={() => updateReportForm(props.values)} >
                                    Save
                                </Button>
                            </div>
                        </Grid>
                    }
                </form>)}

            </Formik>
        </div>)
    };
    return (<div>
        <div>
            {reportFormMode === ADMIN_FORM_MODES.add && (
                <OZDialog
                    title="Add api details"
                    open={open}
                    onClose={closeReportForm}
                    saveButtonProps={{
                        form: 'reportForm',
                        type: 'submit',
                        label: 'save'
                    }}>
                    {reportFormMode === ADMIN_FORM_MODES.add && <DataForm />}
                </OZDialog>
            )}
        </div>
        {reportFormMode !== ADMIN_FORM_MODES.add &&
            <OZDrawer
                open={open}
                title={
                    <div style={{ width: "100%" }}>
                        <div className="oz-adminstration-drawer-title-container">
                            {reportFormMode !== ADMIN_FORM_MODES.edit && <img className="oz-ivr-view-morevereticon" ref={anchorRef}
                                src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                alt="edit"
                                onClick={viewModemorevertclick}
                            ></img>}
                            {reportFormMode !== ADMIN_FORM_MODES.edit &&
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: '18px',
                                        alignSelf: 'center',
                                        marginLeft: "10px"
                                    }}
                                >
                                    {props?.data?.reportName}
                                </Typography>}
                            <div  >
                                <Menu
                                    anchorEl={ReportFormAnchorEl}
                                    open={Boolean(ReportFormAnchorEl)}
                                    onClose={handleMenuClose}
                                    sx={{
                                        "& .MuiPaper-root": {
                                            backgroundColor: "white",
                                            width: "133px",
                                        },
                                        "& .MuiMenu-paper": {
                                            backgroundColor: "white",
                                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                            border: "0.0625rem solid #E6E5E6",
                                            borderRadius: "12px",
                                            marginLeft: "-8.9625rem",
                                            marginTop: '-1.5rem'
                                        },
                                    }}
                                >
                                    <MenuItem sx={{ ...styleMenuItemButton, marginTop: "7px" }} onClick={onOpenDeleteDialog}>Delete</MenuItem>
                                </Menu>
                            </div>
                        </div>
                        {openDeleteDialog && <DeleteDialog title={"Delete"} content={"This will delete this api: permanently." + props.data.reportName + " You cannot undo this action."} onClose={() => { setDeleteDialog(false) }
                        } open={true} deleteFunction={deleteDashboardReport} />}
                        <div>

                            {reportFormMode === ADMIN_FORM_MODES.edit && (<>
                                <Typography sx={{ fontWeight: 500 }}>Edit Report Details</Typography>
                                <Typography
                                    size="small"
                                    variant="text"
                                    sx={{
                                        ...styleEditButton,
                                        marginLeft: "362px",
                                        marginTop: "-21px",
                                        position: "absolute",
                                        cursor: "pointer"
                                    }}
                                    onClick={onClickDiscardButton}
                                >
                                    Discard
                                </Typography></>)}
                        </div>
                    </div>
                }
                onClose={closeReportForm}
                onEdit={onClickEditButton}
                mode={reportFormMode}
            >
                {reportFormMode !== ADMIN_FORM_MODES.edit && <ReportViewDrawer data={props.data} />}
                {reportFormMode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}
            </OZDrawer >}
    </div>)
};
export default ReportForm;
