import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import { Formik, Field, useFormikContext } from 'formik';
import OZInput from '../../../components/admin/common/OZInput';
import OZDialog from '../../../components/admin/common/OZDialog';
import { Divider, Grid } from '@mui/material';
import OZSelect from '../../../components/admin/common/OZSelect';
import { DropzoneAreaBase } from "mui-file-dropzone";
import './Integration.css';
import { showErrorNotification, showInfoNotification, showSuccessNotification, showWarningNotification } from '../../../components/admin/common/NotiService';
import { saveSSOLogin, saveSSOLoginWithLDAP, saveSSOLoginWithSAML } from '../../../services/api-service';

const SSOLogin = (mainprops) => {

    console.log(mainprops);

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                authType: yup
                    .string()
                    .required("Category is Required"),
                authConfig: yup
                    .string()
                    .when("authType", {
                        is: (authType) => (authType === "SAML"),
                        then: yup
                            .string()
                            .required("IpEntityId is Required"),
                        otherwise: yup.string().notRequired().nullable(),
                    }),
                url: yup
                    .string()
                    .when("authType", {
                        is: (authType) => (authType === "LDAP"),
                        then: yup
                            .string()
                            .required("Connection Url is Required"),
                        otherwise: yup.string().notRequired().nullable(),
                    }),
                port: yup
                    .string()
                    .when("authType", {
                        is: (authType) => (authType === "LDAP"),
                        then: yup
                            .string()
                            .required("Connection Port is Required"),
                        otherwise: yup.string().notRequired().nullable(),
                    }),
                userDn: yup
                    .string()
                    .when("authType", {
                        is: (authType) => (authType === "LDAP"),
                        then: yup
                            .string()
                            .required("User DN is Required"),
                        otherwise: yup.string().notRequired().nullable(),
                    }),
                domain: yup
                    .string()
                    .when("authType", {
                        is: (authType) => (authType === "LDAP"),
                        then: yup
                            .string()
                            .required("Domain is Required"),
                        otherwise: yup.string().notRequired().nullable(),
                    }),
                dc: yup
                    .string()
                    .when("authType", {
                        is: (authType) => (authType === "LDAP"),
                        then: yup
                            .string()
                            .required("DC is Required"),
                        otherwise: yup.string().notRequired().nullable(),
                    }),
            }),
        []
    );

    const [loading, setLoading] = useState(false);

    function UploadIcon() {
        return (
            <div style={{ paddingTop: '12px', float: 'right', paddingRight: '15px' }}>
                <img alt='edit' src={`${process.env.PUBLIC_URL}/icons/upload_data_reverse.svg`}></img>
            </div>
        )
    }

    function checkVariant(message, variant) {
        if (variant === "success") {
            showSuccessNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
        }
        else if (variant === "error") {
            showErrorNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
        }
        else {
            showInfoNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
        }
    }

    const DataForm = () => {

        let initData = {
            authType: mainprops?.data?.authType || "SAML",
            authConfig: mainprops?.data?.authConfig || '',
            federationUrl: mainprops?.data?.federationUrl || '',
            authFile: mainprops?.data?.authFile || [],
            url: mainprops?.data?.url || '',
            port: mainprops?.data?.port || '',
            userDn: mainprops?.data?.userDn || '',
            domain: mainprops?.data?.domain || '',
            dc: mainprops?.data?.dc || ''
        }

        const saveSsoLogin = async (values) => {
            if (values.authFile.length === 0 && values.federationUrl === "" && values.authType === "SAML") {
                showWarningNotification("FedeartionURL or File is required.")
            }
            else if (values.authFile.length > 0 && values.federationUrl !== "" && values.authType === "SAML") {
                showWarningNotification("you can give FederationURL or File , not both.")
            }
            else {
                setLoading(true);
                let formData = new FormData();
                let authConfig = {
                    url: values.authConfig,
                }
                if (values.federationUrl !== "") {
                    authConfig["federationUrl"] = values.federationUrl
                }
                if (values.authType === "SAML") {
                    formData.append('data', JSON.stringify({
                        authType: values.authType,
                        authConfig: JSON.stringify(authConfig)
                    }));
                    if (values.authFile.length > 0) {
                        formData.append('file', values.authFile[0].file);
                    }
                }
                else {
                    let authConfig = {
                        url: values.url,
                        port: values.port,
                        userDn: values.userDn,
                        domain: values.domain,
                        dc: values.dc,
                        isAllowAdmin: false
                    }
                    values = {
                        authType: values.authType,
                        authConfig: JSON.stringify(authConfig),
                    }
                }
                try {
                    if (values.authType === "LDAP") {
                        saveSSOLoginWithLDAP(values)
                            .then((resp) => {
                                if (resp.Status === "Success") {
                                    setLoading(false)
                                    showSuccessNotification("SSO Login details saved successfully")
                                    mainprops.getUserIntegrationsData()
                                    mainprops.onClickCancel()
                                }
                                else {
                                    setLoading(false)
                                    showErrorNotification(resp.Message)
                                    console.log(resp.Message)
                                }
                            })
                            .catch((e) => {
                                console.error(e);
                                setLoading(false)
                                showErrorNotification("Error saving SSOLogin details");
                            });
                    }
                    if (values.authType === "SAML") {
                        saveSSOLoginWithSAML(formData)
                            .then((resp) => {
                                if (resp.Status === "Success") {
                                    setLoading(false)
                                    showSuccessNotification("SSO Login details saved successfully")
                                    mainprops.getUserIntegrationsData()
                                    mainprops.onClickCancel()
                                }
                                else {
                                    setLoading(false)
                                    showErrorNotification(resp.Message)
                                    console.log(resp.Message)
                                }
                            })
                            .catch((e) => {
                                console.error(e);
                                setLoading(false)
                                showErrorNotification("Error saving SSOLogin details");
                            });
                    }
                }
                catch {
                    setLoading(false)
                    showErrorNotification(`Error in saving SSO Login details`)
                }
            }
        }

        return (
            <div>
                <Formik
                    initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        console.log(values);
                        saveSsoLogin(values);
                    }}
                >
                    {({ handleSubmit, setFieldValue, values }) => (
                        <div>
                            <form
                                onSubmit={handleSubmit}
                                id="ssoLoginForm"
                                style={{
                                    width: "682px",
                                }}
                            >
                                <Divider variant="fullWidth" sx={{ borderColor: "#D6D6D7" }} />
                                <div style={{ margin: "10px" }}>
                                    <Grid item xs={12}>
                                        <Field
                                            name="authType"
                                            as={OZSelect}
                                            label="Select Category "
                                            placeholder="Select"
                                            options={["SAML", "LDAP"]}
                                        />
                                    </Grid>
                                </div>
                                <div style={{ margin: "10px", backgroundColor: "#F6F8F9" }}>
                                    {values.authType !== "LDAP" &&
                                        <div style={{ padding: "15px" }}>
                                            <Grid item xs={12}>
                                                <Field
                                                    name='authConfig'
                                                    as={OZInput}
                                                    label='Identity provider entity ID (URL)'
                                                    placeholder="Enter ID"
                                                />
                                            </Grid>
                                            <Divider style={{ marginTop: "15px", marginBottom: "15px", marginLeft: "-15px", marginRight: "-15px" }}></Divider>
                                            <Grid item xs={12} >
                                                <Field
                                                    name='federationUrl'
                                                    as={OZInput}
                                                    label='Federation URL'
                                                    placeholder="Enter Federation URL"
                                                />
                                            </Grid>
                                            <div style={{ display: "flex", alignItems: "center", fontSize: "12px", fontWeight: 500, color: "#909DAD", marginTop: "10px", marginBottom: "10px", justifyContent: "center" }}>
                                                OR
                                            </div>
                                            <Grid item xs={12}>
                                                <div style={{ marginBottom: '4px', fontSize: "12px", fontWeight: 400, color: "#47586E" }}>
                                                    Config file to import (XML)
                                                </div>
                                                <DropzoneAreaBase
                                                    Icon={UploadIcon}
                                                    initialFiles={values.authFile}
                                                    fileObjects={values.authFile}
                                                    dropzoneParagraphClass={values.authFile.length > 0 ? "config-dropzone-no-text" : "config-dropzone-text"}
                                                    dropzoneClass="config-drop-zone-area"
                                                    previewGridProps={{
                                                        item: {
                                                            position: 'absolute',
                                                            display: 'flex',
                                                            flexWrap: "wrap",
                                                            alignItems: 'end',
                                                            marginTop: "-50px",
                                                        },
                                                        container: { spacing: 1, direction: 'row' }
                                                    }}
                                                    acceptedFiles={['application/xml', 'text/xml']}
                                                    onAlert={(message, variant) => { checkVariant(message, variant); }}
                                                    onDelete={(fileObj) => { console.log('Removed File:', fileObj); setFieldValue('authFile', []); }}
                                                    onAdd={(files) => {
                                                        if (files?.length > 0) {
                                                            setFieldValue('authFile', [files[0]]);
                                                        }
                                                    }}
                                                    filesLimit={1}
                                                    useChipsForPreview={true}
                                                    showAlerts={false}
                                                />
                                            </Grid>
                                        </div>
                                    }
                                    {values.authType === "LDAP" &&
                                        <div style={{ padding: "15px", display: 'flex', flexDirection: "column", justifyContent: 'space-between', gap: "10px" }}>
                                            <Grid item xs={12}>
                                                <Field
                                                    name='url'
                                                    as={OZInput}
                                                    label='Connection URL'
                                                    placeholder="Enter Connection URL"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field
                                                    name='port'
                                                    as={OZInput}
                                                    label='Connection Port'
                                                    placeholder="Enter Connection Port"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field
                                                    name='userDn'
                                                    as={OZInput}
                                                    label='User DN'
                                                    placeholder="Enter User DN"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field
                                                    name='domain'
                                                    as={OZInput}
                                                    label='Domain'
                                                    placeholder="Enter Domain"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field
                                                    name='dc'
                                                    as={OZInput}
                                                    label='DC'
                                                    placeholder="Enter DN"
                                                />
                                            </Grid>
                                        </div>
                                    }
                                </div>
                            </form>
                        </div>
                    )}
                </Formik>
            </div>
        )
    }

    return (
        <>
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                <OZDialog open={mainprops.open} onClose={mainprops.onClickCancel} title="Single sign-on" saveButtonProps={{ form: 'ssoLoginForm', type: 'submit' }} form="ssoLoginForm">
                    <DataForm />
                </OZDialog>
            }
        </>
    )
}

export default SSOLogin;