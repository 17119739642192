import { Backdrop, CircularProgress, CssBaseline } from '@mui/material';
import React,{useEffect} from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import OZSidenav from '../../components/OZSidenav/OZSidenav';
import OZToolbar from '../../components/OZToolbar/OZToolbar';
import { OZContext } from '../../contexts/OZContext';
import { deleteValueInStorage } from '../../services/storage-service';
import { LOCAL_STORAGE } from '../../utils/constants';
import Admin from '../admin/Admin';
import CreateDashboard from '../CreateDashboard/CreateDashboard';
import Dashboard from '../Dashboard/Dashboard';
import ErrorComponent from '../Error/Error';
import Playground from '../Playground/Playground';
import Presets from '../Presets/Presets';
import Wallboard from '../Wallboard/Wallboard';
import '../WireframeDashboard/WireframeDashboard.css';
import ChatWidget from '../../components/BroadCast/ChatWidget';
import OAuthIntegration from '../admin/Integration/OAuthIntegration';
import { useSnackbar } from 'notistack';
import { setCloseSnackBarRef, setSnackBarRef } from '../../components/admin/common/NotiService';

const ReportsWireframe = React.lazy(() => import('../reports/ReportsWireframe'));

function Wireframe() {
	const navigate = useNavigate();
	const location = useLocation();
	const { selectedDashboard, setSelectedDashboard, isLoaderOpen } = React.useContext(OZContext);
	const [wallboardEdit, setWallboardEdit] = React.useState<any>(false);


        useEffect(() => {
          console.log("insie");
            let Apxor=document.createElement("script");
            Apxor.src="Apxor.js";
            Apxor.async=true;
            document.body.appendChild(Apxor);
            Apxor.onload=function(){

            console.log("calling UserId");
            }

       }, []);

	const handleDashboardSelect = (event: any) => {
		setSelectedDashboard(event);
		if (event?.dashboard?.dashboardName === 'Wallboard') {
			setWallboardEdit(false);
			navigate('/wallboard');
		} else {
			navigate('/dashboard');
		}
	};
	const handleDashboardEdit = (event: any) => {
		setSelectedDashboard(event);
		if (event?.dashboard?.dashboardName === 'Wallboard') {
			setWallboardEdit(true);
			navigate('/wallboard');
		} else {
			navigate('/create');
		}
	};
	React.useEffect(() => {
		if (location.pathname === '/preset') {
			deleteValueInStorage(LOCAL_STORAGE.SELECTED_DASHBOARD);
			setSelectedDashboard({});
		}
	}, [location]);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	React.useEffect(() => {
		setSnackBarRef(enqueueSnackbar);
		setCloseSnackBarRef(closeSnackbar);
	}, [enqueueSnackbar, closeSnackbar])
	return (
		<div>
			<ChatWidget />
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoaderOpen}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<CssBaseline />
			<OZToolbar onSelectDashboard={handleDashboardSelect}></OZToolbar>
			<OZSidenav></OZSidenav>
			<main className="content">
				<div className="toolbar" />
				<React.Suspense
					fallback={
						<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
							<CircularProgress color="inherit" />
						</Backdrop>
					}
				>
					<Routes>
						<Route path={`/dashboard`} element={<Dashboard selectedDashboard={selectedDashboard} />} />
						<Route path={`/preset`} element={<Presets onPresetClick={handleDashboardSelect} onPresetEdit={handleDashboardEdit} />} />
						<Route path={`/create`} element={<CreateDashboard selectedDashboard={selectedDashboard} />} />
						<Route path={`/playground`} element={<Playground />} />
						<Route path={`/wallboard`} element={<Wallboard wallboardOnEdit={wallboardEdit} />} />
						<Route path={`/reports/*`} element={<ReportsWireframe />}></Route>
						<Route path={`/admin/*`} element={<Admin />} />
						<Route path={`/oauth`} element={<OAuthIntegration />} />
						<Route path="*" element={<ErrorComponent />}></Route>
					</Routes>
				</React.Suspense>
			</main>
		</div>
	);
}

export default Wireframe;
