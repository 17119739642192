import React, {useState} from 'react';
import { Box, Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import OZCheckbox from '../OZCheckbox/OZCheckbox';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
	popoverContent: {
		minWidth: 350,
		fontSize: '12px',
		lineHeight: 2,
		padding: '1rem'
	},
	popoverHeader: {
		width: '100%'
	},
	popoverBody: {
		marginTop: '0.5rem',
		maxHeight: '200px',
		overflow: 'auto',

		'&::-webkit-scrollbar': {
			width: '10px'
		},
		/* Track */
		'&::-webkit-scrollbar-track': {
			background: '#f1f1f1',
			borderRadius: '8px'
		},
		/* Handle */
		'&::-webkit-scrollbar-thumb': {
			background: '#888',
			borderRadius: '8px'
		},
		/* Handle on hover */
		'&::-webkit-scrollbar-thumb:hover': {
			background: '#555'
		}
	},
	popoverAction: {
		marginTop: '1rem'
	}
}));

const NewSelectPopover = ({ isOpen, handlePopoverClose, anchorRef, elementsArray, selectedElements, handleOnSelectionChange, addMetricsLabel }) => {

    const  DataForm = ({ isOpen, handlePopoverClose, anchorRef, elementsArray, selectedElements, handleOnSelectionChange, addMetricsLabel }) => {
        const [selected, setSelected] = useState(selectedElements);
        const classes = useStyles();

        const isSelected = (item) => {
            const result = selected.some((eachElement) => item.id === eachElement.id);
            return result;
        };

        const handleOnElementChange = (event) => {
            if (event?.target?.checked && event?.target?.value) {
                const existingSelectedElements = [...selected];
                existingSelectedElements.splice(event.target.value.positionIndex || 0, 0, event.target.value);
                setSelected(existingSelectedElements);
            } else {
                const newSelectedElements = selected.filter((item) => item.id !== event?.target?.value?.id);
                setSelected(newSelectedElements);
            }
        };

        const handleApplySelection = () => {
            handleOnSelectionChange && handleOnSelectionChange(selected);
        };

        return (
            <Popover
                open={isOpen}
                onClose={handlePopoverClose}
                anchorReference={'anchorPosition'}
                anchorPosition={anchorRef}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box className={classes.popoverContent}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Typography className={classes?.popoverHeader}>{addMetricsLabel ? 'Add Metrics' : 'Edit Column Headers'}</Typography>
                        <IconButton onClick={handlePopoverClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>

                    <Box className={classes?.popoverBody}>
                        {elementsArray &&
                            elementsArray.map((eachElement, idx) => {
                                return (
                                    eachElement?.label && (
                                        <OZCheckbox
                                            key={eachElement.id + idx}
                                            item={eachElement}
                                            checked={isSelected(eachElement)}
                                            label={eachElement.label}
                                            id={eachElement.id}
                                            onChange={handleOnElementChange}
                                        />
                                    )
                                );
                            })}
                    </Box>
                    <Box className={classes?.popoverAction}>
                        <Button variant="contained" onClick={handleApplySelection} sx={{ borderRadius: '8px' }} fullWidth>
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Popover>
        );
    };


    return (
        <>
            <DataForm isOpen={isOpen} handlePopoverClose={handlePopoverClose}
                anchorRef={anchorRef} elementsArray={elementsArray}
                selectedElements={selectedElements}
                handleOnSelectionChange={handleOnSelectionChange}
                addMetricsLabel={addMetricsLabel}
            />
        </>
    )
}

export default  NewSelectPopover;
