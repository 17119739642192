import React, { useState } from 'react';
import { FormControl, MenuItem, Select, Typography, IconButton } from '@mui/material';

function CustomSelect(additionalProps) {

    const [selected, setSelected] = useState('')

    const DropDownIcon = () => {
        return <IconButton sx={{
            position: 'absolute !important', right: '0', pointerEvents: 'none !important'
        }}><img
            src={`${process.env.PUBLIC_URL}/icons/downArrowDropDown.svg`}
            alt="edit"
        ></img></IconButton>
    };

    const customRenderValue = (selected) => {
        const selectedValueStyle = {
            fontSize: "12px",
            fontWeight: 400,
            heigth: '14px',
            marginTop: "2px",
            color: "#212121",
        }
        const Placeholder = <Typography sx={
            selectedValueStyle
        } > {additionalProps.placeholder}</Typography >
        const Selected = <Typography sx={selectedValueStyle
        } > {selected}</Typography >
        return (selected === "" || selected === undefined) ? Placeholder : Selected
    }

    function updateAgent(event) {
        setSelected(event.target.value)
        additionalProps.onChange(event.target.value);
    }

    return (
        <React.Fragment>
            <FormControl sx={{}}>
                <Select
                    sx={{
                        height: '30px', width: "98px",
                        backgroundColor: "#FFFFFF",
                        borderRadius: '8px',
                        border: "1px solid #99A0A8",
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: "0px",
                            borderRadius: '8px',
                            fontSize: '12px',
                        },
                        '&:hover': {
                            border: "1px solid #99A0A8",
                        },
                    }}
                    IconComponent={DropDownIcon}
                    placeholder={additionalProps.placeholder}
                    displayEmpty
                    name={additionalProps.name}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                width: '133px',
                                border: "1px solid #E6E5E6",
                                boxShadow: " 0px 4px 12px rgba(0, 0, 0, 0.04)",
                                borderRadius: "12px",
                                marginTop: "-7px",
                                marginLeft: "16.5px",
                                '& .MuiList-root': {
                                    paddingTop: 0
                                },
                                '& .MuiMenuItem-root': {
                                    bgcolor: "white",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "#212121",
                                    paddingBottom: "0px",
                                },
                                '& .MuiMenuItem-root:hover': {
                                    color: '#3D8BF8'
                                },
                            },
                        },
                        variant: "menu",
                    }}

                    value={selected}
                    SelectDisplayProps={{
                    }}
                    onChange={(event) => { updateAgent(event) }}
                    renderValue={(selected) => customRenderValue(selected)}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {additionalProps.options.map((option, index) => {
                        return (<MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </React.Fragment>
    );
}

export default CustomSelect;