import './IdeskIntegration.css'
import { TextField, Button, Card, CardContent, Grid, Tooltip } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import * as yup from 'yup';
import { FieldArray, Formik, Field, ErrorMessage } from 'formik';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import SegmentOutlinedIcon from '@mui/icons-material/SegmentOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Filter1Icon from '@mui/icons-material/Filter1';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import { saveIdeskIntegrationData } from '../../../services/api-service';

export const IdeskIntegration = (props) => {

    const options = ["Text", "Number", 'Email', "Date", "Radio", "TextArea", "Select", "CheckBox"];
    const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [ideskdata, setIdeskdata] = useState(props.ideskData);

    useEffect(() => {
        let dummy = []
        ideskdata?.ideskIntegration && ideskdata?.ideskIntegration !== null && ideskdata?.ideskIntegration !== undefined && ideskdata.ideskIntegration.map((data) => {
            dummy.push(
                {
                    field_type: data.field_type,
                    field_name: data.field_name,
                    field_label: data.field_label,
                    field_values: data.field_values,
                    field_default_value: data.field_default_value,
                    field_required: data.field_required,
                    field_created_date: data.field_created_date,
                    field_deleted: data.field_deleted,
                    field_deleted_date: data.field_deleted_date,
                    field_num: data.field_num,
                    isOpen: false
                }
            )
        })
        setIdeskdata({ ideskIntegration: dummy })
    }, [])

    const [refreshForm, setRefreshForm] = useState(false);
    const validationSchema = useMemo(() =>
        yup.object({
            ideskIntegration: yup.array().of(
                yup.object().shape({
                    field_type: yup
                        .string()
                        .required("field_type is required"),
                    field_name: yup
                        .string()
                        .required("field_name is required"),
                    field_label: yup
                        .string()
                        .required("field_label is required"),
                    field_values: yup.mixed(),
                    field_default_value: yup
                        .string(),
                    field_required: yup
                        .boolean()
                        .required("field_required is required"),
                    field_created_date: yup
                        .string(),
                    field_num: yup
                        .string(),
                    field_deleted: yup
                        .boolean()
                        .required("field_deleted is required"),
                    field_deleted_date: yup
                        .string(),
                })
            )
        }), []);

    const onClickAdd = (fieldtype, arrayhelpers, values) => {
        if (values?.ideskIntegration === null || values.ideskIntegration.length < 10) {
            arrayhelpers.push(
                {
                    field_type: fieldtype,
                    field_name: '',
                    field_label: '',
                    field_values: '',
                    field_default_value: '',
                    field_required: false,
                    field_created_date: JSON.stringify(new Date()),
                    field_deleted: false,
                    field_deleted_date: '',
                    field_num: '',
                    isOpen: true
                }
            )
        }
        else {
            showWarningNotification("Can have a Maximum of 10 records only")
        }
    };

    const handleCancel = () => {
        setIdeskdata(props.ideskData)
        setRefreshForm(prevState => !prevState)
    };

    const addExtraValues = (index, arrayhelpers, values) => {
        values?.ideskIntegration && values?.ideskIntegration !== null && (values.ideskIntegration).map((val, valIndex) => {
            if (index === valIndex) {
                if (typeof (val.field_values) === 'object') {
                    (val.field_values).push('');
                    arrayhelpers.replace(valIndex, {
                        field_type: val.field_type,
                        field_name: val.field_name,
                        field_label: val.field_label,
                        field_values: val.field_values,
                        field_default_value: val.field_default_value,
                        field_required: val.field_required,
                        field_created_date: val.field_created_date,
                        field_deleted: val.field_deleted,
                        field_deleted_date: val.field_deleted_date,
                        field_num: val.field_num,
                        isOpen: val.isOpen
                    })
                }
                else {
                    let temp = val.field_values;
                    let tempArray = [];
                    tempArray.push(temp);
                    tempArray.push('');
                    val.field_values = tempArray;
                    arrayhelpers.replace(valIndex, {
                        field_type: val.field_type,
                        field_name: val.field_name,
                        field_label: val.field_label,
                        field_values: val.field_values,
                        field_default_value: val.field_default_value,
                        field_required: val.field_required,
                        field_created_date: val.field_created_date,
                        field_deleted: val.field_deleted,
                        field_deleted_date: val.field_deleted_date,
                        field_num: val.field_num,
                        isOpen: val.isOpen
                    })
                }
            }
        })
    }

    const removeValues = (index, arrayhelpers, values, subindex) => {
        values?.ideskIntegration && values?.ideskIntegration !== null && (values.ideskIntegration).map((val, valIndex) => {
            if (index === valIndex) {
                (val.field_values).splice(subindex, 1);
                arrayhelpers.replace(valIndex, {
                    field_type: val.field_type,
                    field_name: val.field_name,
                    field_label: val.field_label,
                    field_values: val.field_values,
                    field_default_value: val.field_default_value,
                    field_required: val.field_required,
                    field_created_date: val.field_created_date,
                    field_deleted: val.field_deleted,
                    field_deleted_date: val.field_deleted_date,
                    field_num: val.field_num,
                    isOpen: val.isOpen
                })
            }
        })
    }

    const onClickCard = (index, arrayhelpers, values) => {
        values?.ideskIntegration && values?.ideskIntegration !== null && (values.ideskIntegration).map((val, valIndex) => {
            if (index === valIndex) {
                arrayhelpers.replace(valIndex, {
                    field_type: val.field_type,
                    field_name: val.field_name,
                    field_label: val.field_label,
                    field_values: val.field_values,
                    field_default_value: val.field_default_value,
                    field_required: val.field_required,
                    field_created_date: val.field_created_date,
                    field_deleted: val.field_deleted,
                    field_deleted_date: val.field_deleted_date,
                    field_num: val.field_num,
                    isOpen: true
                })
            }
        })
    }

    const onClickClose = (index, arrayhelpers, values) => {
        values?.ideskIntegration && values?.ideskIntegration !== null && (values.ideskIntegration).map((val, valIndex) => {
            if (index === valIndex) {
                arrayhelpers.replace(valIndex, {
                    field_type: val.field_type,
                    field_name: val.field_name,
                    field_label: val.field_label,
                    field_values: val.field_values,
                    field_default_value: val.field_default_value,
                    field_required: val.field_required,
                    field_created_date: val.field_created_date,
                    field_deleted: val.field_deleted,
                    field_deleted_date: val.field_deleted_date,
                    field_num: val.field_num,
                    isOpen: false
                })
            }
        })
    }

    const onBtnDisconnect = () => {
        props.setIdeskIntegration(false);
    };

    async function saveIdeskData(data) {
        try {
            saveIdeskIntegrationData(data)
                .then((resp) => {
                    if (resp.status === "success" && resp.statusCode === 200) {
                        showSuccessNotification('Idesk Integration Saved Successfully');
                        props.getUserIntegrationsData()
                        props.setIdeskIntegration(false);
                    }
                })
                .catch((e) => {
                    showErrorNotification("Error in saving Idesk Integration");
                });
        } catch (e) {
            showErrorNotification("Error in Idesk Integration Saving");
        }
    };

    return (
        <Formik
            key={refreshForm}
            initialValues={ideskdata}
            validationSchema={validationSchema}
            onSubmit={values => {
                let obj = { jsonData: [] };
                values?.ideskIntegration && values?.ideskIntegration !== null && (values.ideskIntegration).map((data) => {
                    (obj.jsonData).push(
                        {
                            field_type: data.field_type.toLowerCase(),
                            field_name: data.field_name,
                            field_label: data.field_label,
                            field_values: data.field_values,
                            field_default_value: data.field_default_value,
                            field_required: data.field_required,
                            field_created_date: data.field_created_date,
                            field_deleted: data.field_deleted,
                            field_deleted_date: data.field_deleted_date,
                            field_num: data.field_num,
                        }
                    )
                })
                console.log(obj);
                saveIdeskData(obj);
            }}
            render={({ values, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <FieldArray
                        name="ideskIntegration"
                        render={arrayhelpers => {
                            let ideskData = values.ideskIntegration;
                            return (
                                <div className="oz-admin-integration-Idesk-page">

                                    <Grid sx={{ marginBottom: "10px" }}>
                                        <div onClick={onBtnDisconnect} className="oz-page-header"> Settings / Integrations / idesk settings </div>
                                    </Grid>

                                    <div className="oz-admin-integration-Idesk-IdeskName">
                                        Idesk Settings
                                    </div>

                                    <Grid container >
                                        <Grid item md={3.5} className='oz-admin-integration-Idesk-editableTable' backgroundColor={"#f5f7f9"} marginTop={"30px"} marginBottom={"30px"} >
                                            <div className='oz-admin-integration-Idesk-card-header-name'>
                                                Drag and Drop to create fields
                                            </div>
                                            {options && options !== null && options.map((option) => {
                                                return <Card className='oz-admin-integration-Idesk-card' onClick={() => { onClickAdd(option, arrayhelpers, values) }} sx={{ cursor: "grab" }} onDragEnd={() => { onClickAdd(option, arrayhelpers, values) }} draggable>
                                                    <CardContent>
                                                        <div className='oz-admin-integration-Idesk-card-headerContainer-simplecard'>
                                                            {option === "Text" ? <TextFieldsOutlinedIcon fontSize='small' /> : option === "Number" ? <Filter1Icon fontSize='small' /> : option === "Email" ? <MailOutlineOutlinedIcon fontSize='small' /> : option === "Date" ? <CalendarMonthOutlinedIcon fontSize='small' /> : option === "Radio" ? <RadioButtonCheckedOutlinedIcon fontSize='small' /> : option === "TextArea" ? <SegmentOutlinedIcon fontSize='small' /> : option === "Select" ? <ArrowDropDownCircleOutlinedIcon fontSize='small' /> : option === "CheckBox" ? <CheckBoxOutlinedIcon fontSize='small' /> : ''}
                                                            <div style={{ marginLeft: "8px" }}>{option}</div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            })
                                            }
                                        </Grid>

                                        <Grid item md={8.17} className='oz-admin-integration-Idesk-editableTable' marginTop={"30px"} marginBottom={"30px"}>
                                            <div className='oz-admin-integration-Idesk-form'>
                                                <div className='oz-admin-integration-Idesk-form-positions'>
                                                    <label className='oz-admin-integration-Idesk-form-labels'> Auth Token* </label>
                                                    <TextField value={ideskdata.token} variant="standard" className='oz-admin-integration-Idesk-form-textfield'
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            readOnly: true,
                                                        }}
                                                        sx={{
                                                            backgroundColor: "#eaeaea",
                                                            minHeight: "44px !important",
                                                            '& .MuiInputBase-input': {
                                                                fontSize: "14px",
                                                                color: "#777",
                                                                paddingLeft: "20px",
                                                                marginTop: "10px",
                                                                paddingRight: "20px"
                                                            },
                                                        }}></TextField>
                                                </div>
                                            </div>
                                            {values.ideskIntegration !== null && values.ideskIntegration.length > 0 &&
                                                ideskData.map(({ field_type, field_required, field_values, field_label, isOpen }, index) => {
                                                    return <Card className='oz-admin-integration-Idesk-card-big'>
                                                        <CardContent>
                                                            <div className='oz-admin-integration-Idesk-card-headerContainer'>
                                                                <div className='oz-admin-integration-Idesk-card-header' onClick={() => { onClickCard(index, arrayhelpers, values) }}>
                                                                    {field_type.toLowerCase() === "text" ? <TextFieldsOutlinedIcon fontSize='small' /> : field_type.toLowerCase() === "number" ? <Filter1Icon fontSize='small' /> : field_type.toLowerCase() === "email" ? <MailOutlineOutlinedIcon fontSize='small' /> : field_type.toLowerCase() === "date" ? <CalendarMonthOutlinedIcon fontSize='small' /> : field_type.toLowerCase() === "radio" ? <RadioButtonCheckedOutlinedIcon fontSize='small' /> : field_type.toLowerCase() === "textarea" ? <SegmentOutlinedIcon fontSize='small' /> : field_type.toLowerCase() === "select" ? <ArrowDropDownCircleOutlinedIcon fontSize='small' /> : field_type.toLowerCase() === "checkbox" ? <CheckBoxOutlinedIcon fontSize='small' /> : ''}
                                                                    <div style={{ marginLeft: "8px" }}> {(field_label === '') ? `New ${field_type}` : field_label} </div>
                                                                </div>
                                                                <div>
                                                                    {isOpen &&
                                                                        <Tooltip title="Close">
                                                                            <ClearIcon fontSize='small' onClick={() => { onClickClose(index, arrayhelpers, values) }} style={{ marginRight: "15px", cursor: "pointer" }} />
                                                                        </Tooltip>
                                                                    }
                                                                    <Tooltip title="Delete">
                                                                        <DeleteIcon fontSize='small' onClick={() => { setDeleteConfirmationDialog(true); setDeleteIndex(index) }} style={{ marginRight: "16px", cursor: "pointer" }} />
                                                                    </Tooltip>
                                                                    {openDeleteConfirmationDialog &&
                                                                        <DeleteDialog
                                                                            title={"Delete Field"}
                                                                            content={"This will delete this field permanently. You cannot undo this action."}
                                                                            onClose={() => { setDeleteConfirmationDialog(false); }}
                                                                            open={true} deleteFunction={() => arrayhelpers.remove(deleteIndex)} backdrop={true}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                            {isOpen &&
                                                                < Grid container className='oz-admin-integration-Idesk-cardContents'>
                                                                    <Grid item md={5.5} className='oz-admin-integration-Idesk-gridItem'>
                                                                        <div className='oz-admin-integration-Idesk-fields oz-admin-integration-Idesk-singleLine-fields oz-admin-integration-Idesk-sameRows'>
                                                                            <div className='oz-admin-integration-Idesk-label-header'>FieldType:</div>
                                                                            <div className='oz-admin-integration-Idesk-fieldType'>{
                                                                                field_type === "text" ? "Text" : field_type === "number" ? "Number" : field_type === "email" ? "Email" : field_type === "date" ? "Date" : field_type === "radio" ? "Radio" : field_type === "textarea" ? "TextArea" : field_type === "select" ? "Select" : field_type === "checkbox" ? "CheckBox" : field_type}
                                                                            </div>
                                                                        </div>
                                                                        <div className='oz-admin-integration-Idesk-fields oz-admin-integration-Idesk-doubleLine-fields'>
                                                                            <div className='oz-admin-integration-Idesk-label-header'>FieldName:</div>
                                                                            <Field
                                                                                className={'oz-admin-integration-Idesk-card-input'}
                                                                                name={`ideskIntegration.${index}.field_name`}
                                                                                placeholder='Field Name'
                                                                            />
                                                                            <div className='oz-admin-integration-Idesk-errormessage'>
                                                                                <ErrorMessage name={`ideskIntegration.${index}.field_name`} />
                                                                            </div>
                                                                        </div>
                                                                        <div className='oz-admin-integration-Idesk-fields oz-admin-integration-Idesk-doubleLine-fields'>
                                                                            <div className='oz-admin-integration-Idesk-label-header'>FieldValue:</div>
                                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                                <Field
                                                                                    className={(field_type.toLowerCase() === "checkbox" || field_type.toLowerCase() === "select" || field_type.toLowerCase() === "radio") ? 'oz-admin-integration-Idesk-card-input-exceptionfields' : 'oz-admin-integration-Idesk-card-input'}
                                                                                    name={((field_type.toLowerCase() === "checkbox" || field_type.toLowerCase() === "select" || field_type.toLowerCase() === "radio") && (typeof (field_values) === 'object')) ? `ideskIntegration.${index}.field_values[0]` : `ideskIntegration.${index}.field_values`}
                                                                                    placeholder='Value'
                                                                                />
                                                                                {(field_type.toLowerCase() === "checkbox" || field_type.toLowerCase() === "select" || field_type.toLowerCase() === "radio") &&
                                                                                    <Tooltip title="Add fieldValue">
                                                                                        <Button sx={{
                                                                                            backgroundColor: "#eaeaea", borderRadius: "0px", marginBottom: "10px",
                                                                                            '&.MuiButton-root': {
                                                                                                minWidth: "20px !important",
                                                                                            },
                                                                                            '&:hover': {
                                                                                                backgroundColor: '#eaeaea',
                                                                                            },
                                                                                            "& .MuiOutlinedInput-root.Mui-focused": {
                                                                                                "& > fieldset": {
                                                                                                    border: "1px Solid #eaeaea"
                                                                                                },
                                                                                            },
                                                                                            "& .MuiOutlinedInput-root:hover": {
                                                                                                "& > fieldset": {
                                                                                                    border: "1px Solid #eaeaea"
                                                                                                }
                                                                                            }
                                                                                        }} onClick={() => addExtraValues(index, arrayhelpers, values)}>
                                                                                            <AddCircleOutlineRoundedIcon />
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                }
                                                                            </div>
                                                                            {
                                                                                ((field_type.toLowerCase() === "checkbox" || field_type.toLowerCase() === "select" || field_type.toLowerCase() === "radio") && (typeof (field_values) === 'object')) &&
                                                                                (ideskData[index].field_values).map((val, subindex) => {
                                                                                    if (subindex > 0) {
                                                                                        return <div style={{ display: "flex", flexDirection: "row" }}>
                                                                                            <Field
                                                                                                className={'oz-admin-integration-Idesk-card-input-exceptionfields'}
                                                                                                name={`ideskIntegration.${index}.field_values[${subindex}]`}
                                                                                                placeholder='Value'
                                                                                            />
                                                                                            <Tooltip title="Remove fieldValue">
                                                                                                <Button sx={{
                                                                                                    backgroundColor: "#eaeaea", borderRadius: "0px", marginBottom: "10px",
                                                                                                    '&.MuiButton-root': {
                                                                                                        minWidth: "20px !important",
                                                                                                    },
                                                                                                    '&:hover': {
                                                                                                        backgroundColor: '#eaeaea',
                                                                                                    },
                                                                                                    "& .MuiOutlinedInput-root.Mui-focused": {
                                                                                                        "& > fieldset": {
                                                                                                            border: "1px Solid #eaeaea"
                                                                                                        },
                                                                                                    },
                                                                                                    "& .MuiOutlinedInput-root:hover": {
                                                                                                        "& > fieldset": {
                                                                                                            border: "1px Solid #eaeaea"
                                                                                                        }
                                                                                                    }
                                                                                                }} onClick={() => { removeValues(index, arrayhelpers, values, subindex) }}>
                                                                                                    <RemoveCircleOutlineRoundedIcon />
                                                                                                </Button>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    }
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item md={5.5} className='oz-admin-integration-Idesk-gridItem'>
                                                                        <div className='oz-admin-integration-Idesk-fields oz-admin-integration-Idesk-singleLine-fields oz-admin-integration-Idesk-sameRows'>
                                                                            <div className='oz-admin-integration-Idesk-label-header'>IsRequired:</div>
                                                                            <Field
                                                                                className="oz-admin-integration-Idesk-field-checkboxes"
                                                                                name={`ideskIntegration.${index}.field_required`}
                                                                                style={{ marginRight: 8 }}
                                                                                type="Checkbox"
                                                                                checked={field_required}
                                                                            ></Field>
                                                                        </div>
                                                                        <div className='oz-admin-integration-Idesk-fields oz-admin-integration-Idesk-doubleLine-fields'>
                                                                            <div className='oz-admin-integration-Idesk-label-header'>FieldLabel:</div>
                                                                            <div>
                                                                                <Field
                                                                                    className={'oz-admin-integration-Idesk-card-input'}
                                                                                    name={`ideskIntegration.${index}.field_label`}
                                                                                    placeholder='Field Label'
                                                                                />
                                                                                <div className='oz-admin-integration-Idesk-errormessage'>
                                                                                    <ErrorMessage name={`ideskIntegration.${index}.field_label`} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {(field_type.toLowerCase() === "checkbox" || field_type.toLowerCase() === "select" || field_type.toLowerCase() === "radio") &&
                                                                            <div className='oz-admin-integration-Idesk-fields oz-admin-integration-Idesk-doubleLine-fields'>
                                                                                <div className='oz-admin-integration-Idesk-label-header'>DefaultValue:</div>
                                                                                < Field
                                                                                    className={'oz-admin-integration-Idesk-card-input'}
                                                                                    name={`ideskIntegration.${index}.field_default_value`}
                                                                                    placeholder='Default Value'
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        </CardContent>
                                                    </Card>
                                                })
                                            }
                                            <div style={{ display: "flex", marginTop: "80px", marginBottom: "80px" }}>
                                                <Button variant='contained' autoFocus sx={{ marginRight: "10px" }} type="submit">
                                                    Save
                                                </Button>
                                                <Button variant='outlined' autoFocus onClick={() => handleCancel()}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </div>
                            )
                        }}
                    />
                </form >
            )}
        />
    )
}