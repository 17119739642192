import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Badge, Box, Card, Chip, Grid, Menu, MenuItem, Stack, Theme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { getSkillSummary } from '../../../services/api-service';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import ThresholdDrawer from '../../ThresholdDrawer/ThresholdDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import WidgetGraph from '../../Widget-Graph/Widget-Graph';
import '../OccupancyvsCall/OccupancyvsCall.css';
import ORVSCV from './OccupancyratevsCallvolume';
import { mapRowDataWithThreshold } from '../../../utils/util';

const useStyles: any = makeStyles((theme: Theme) => ({
	boundButton: {
		padding: '5px !important'
	}
}));

let interval: any;

function OccupancyCall() {
	const { useState, useRef } = React;
	const downloadContent = useRef<null | HTMLElement>(null);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [ovscFilter, setOvScFilter] = useState<any>([]);
	const [filterType, setFilterType] = useState<any>('1');
	const [duration, setDuration] = useState<string>('Today');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [bound, setBound] = useState<string | null>('contactcenter');
	const [isThresholdDrawerOpen, setIsThresholdDrawerOpen] = useState<boolean | undefined>(false);

	const classes = useStyles();

	const handleBound = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
		setBound(newAlignment);
	};
	const clearFilter = () => {
		setOvScFilter([]);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = ovscFilter.filter((item: any) => item.id !== id);
		setOvScFilter(newFilter);
	};

	const filterItems = ovscFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const retrieveTableData = async (filters = '0') => {
		const rows: any[] = await getSkillSummary({ filters, type: filterType });
		const columns = [
			{ name: 'Agent', key: 'SkillName' },
			{ name: 'Agent Group', key: 'TotalCalls' },
			{ name: 'Occupancy %', key: 'TotalAgents' },
			{ name: 'Total Interaction volume', key: 'callsPerAgent' },
			{ name: 'Ready', key: 'LongestCallQueueTime' },
			{ name: 'Busy', key: 'Answered' },
			{ name: 'Pause', key: 'Abandoned' },
			{ name: 'ACW', key: 'SLAPercentage' }
		];
		setRowData(rows);
		setColumnData(columns);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		const filterIds = ovscFilter.length ? ovscFilter.map((item: any) => item.id).join(',') : '0';
		retrieveTableData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		interval = setInterval(() => retrieveTableData(filterIds), WIDGET_REFRESH_INTERVAL.OCCUPANCY_VS_CALL);
	}, [ovscFilter]);

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType }: any) => {
		setOvScFilter(selectedFilters);
		setFilterType(newFilterType);
		toggleDrawer(false);
	};
	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		if (event.currentTarget) setAnchorEl(event.currentTarget);
	};
	const open = Boolean(anchorEl);

	const handleDuration = (type: string = '') => {
		if (type) {
			setDuration(type);
		}
		setAnchorEl(null);
	};

	const toggleThresholdDrawer = (isOpen: boolean, data: any = null) => {
		setIsThresholdDrawerOpen(isOpen);
		if (data) {
			const updatedRowData = mapRowDataWithThreshold(data, rowData);
			setRowData(updatedRowData);
		}
	};

	return (
		<>
			<Card className="customCard">
				<div className="card-header">
					<div className="card-title">
						Occupancy Rate vs {bound === 'contactcenter' ? 'Call' : 'Interaction'} Volume
						<span className="card-title-badge">Live</span>
					</div>
					<div className="card-actions">
						<div>
							<div onClick={handleMenuOpen}>
								{duration}
								<KeyboardArrowDownOutlinedIcon style={{ color: '#536580', fontSize: '10px' }} />
							</div>
							<Menu
								id="download-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right'
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
								open={open}
								onClose={() => handleDuration()}
								className="custom-dropdown"
							>
								<MenuItem className="download-item" onClick={() => handleDuration('Today')}>
									Today
								</MenuItem>
								<MenuItem className="download-item" onClick={() => handleDuration('Yesterday')}>
									Yesterday
								</MenuItem>
								<MenuItem className="download-item" onClick={() => handleDuration('Same day last week')}>
									Same day last week
								</MenuItem>
							</Menu>
						</div>
						<ToggleButtonGroup value={bound} color="primary" exclusive onChange={handleBound} aria-label="bound">
							<ToggleButton value="contactcenter" className={classes.boundButton} aria-label="voice">
								<span>Contact Center</span>
							</ToggleButton>
							<ToggleButton value="agent" className={classes.boundButton} aria-label="outbound">
								<span>Agent</span>
							</ToggleButton>
						</ToggleButtonGroup>
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={`Occupancy Rate vs ${bound === 'contactcenter' ? 'Call' : 'Interaction'} Volume`}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={ovscFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
						<div onClick={() => toggleThresholdDrawer(true)}>
							<WidgetGraph></WidgetGraph>
						</div>
					</div>
				</div>
				<Box ref={downloadContent} sx={{ width: '100%', typography: 'body1' }}>
					{ovscFilter.length > 0 && (
						<Box className="customTabPanel">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className="filter-outer">
										<div className="filter-container">
											<Stack direction="row" spacing={1}>
												{filterItems}
											</Stack>
										</div>
										<div className="filter-remove" onClick={clearFilter}>
											Remove
										</div>
									</div>
								</Grid>
							</Grid>
						</Box>
					)}

					{bound === 'agent' ? <OZTable columns={columnData} rows={rowData}></OZTable> : <ORVSCV></ORVSCV>}
				</Box>
			</Card>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={ovscFilter} />
			{isThresholdDrawerOpen && <ThresholdDrawer isOpen={isThresholdDrawerOpen} toggleDrawer={toggleThresholdDrawer} />}
		</>
	);
}
export default OccupancyCall;
