import * as yup from "yup";
import OZDialog from "../../../components/admin/common/OZDialog";
import { Formik } from "formik";
import { Autocomplete, FormControl, TextField, Typography } from "@mui/material";

const CopyToAccountForm = (props) => {
    const validationSchemaCopy = yup.object().shape({
        selectedUser: yup.object().nullable().required('Account is required'),
    });

    const usrLst = localStorage.getItem('userList');
    let userList = [];
    if (usrLst) {
        const parsedList = JSON.parse(usrLst);
        if (Object.keys(parsedList).length === 0) {
            userList = [];
        } else {
            userList = parsedList;
        }
    }
    const userSortedList = userList?.sort((a, b) => (a.label > b.label) ? 1 : -1);

    return(<OZDialog
        sx={{
            "& .MuiDialog-container": {
                marginTop: '100px'
            },
        }}
        open={true}
        title="Save Copy Account"
        onClose={() => { props.setCopyAccountDialog(false); }}
        cancelButtonProps={{ label: "Discard" }}
        saveButtonProps={{
            form: "copyForm",
            type: "submit",
            label: "Copy",
        }}
    >
        <Formik
            initialValues={{
                selectedUser: null,
            }}
            validationSchema={validationSchemaCopy}
            onSubmit={(values) => {
                console.log('Form Submitted:', values);
                props?.saveCopyAccountFlow(values.selectedUser?.value);
            }}
        >
            {({ values, setFieldValue, handleSubmit, errors, touched }) => (
                <form id="copyForm" onSubmit={handleSubmit}>
                    <FormControl fullWidth>
                        <Typography>Select Account</Typography>
                        {userSortedList && userSortedList.length > 0 && (
                            <Autocomplete
                                options={userSortedList}
                                getOptionLabel={(option) => option.label || ""}
                                value={values.selectedUser}
                                onChange={(event, newValue) => {
                                    setFieldValue("selectedUser", newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select"
                                        error={touched.selectedUser && Boolean(errors.selectedUser)}
                                        helperText={touched.selectedUser && errors.selectedUser ? errors.selectedUser : ''}
                                        sx={{
                                            height: "44px",
                                            width: "200px",
                                            input: {
                                                "&::placeholder": {
                                                    fontWeight: 400,
                                                    fontSize: '14px',
                                                    color: '#99A0A8 !important',
                                                    opacity: 1
                                                },
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                minHeight: "44px",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                color: params.inputProps.value === "" ? "#99A0A8" : "#212121",
                                                borderRadius: '8px',
                                                paddingLeft: '12px',
                                                paddingTop: '0px',
                                                paddingRight: '0px',
                                                paddingBottom: '0px',
                                            }
                                        }}
                                        variant="outlined"
                                    />
                                )}
                            />
                        )}
                    </FormControl>
                </form>
            )}
        </Formik>
    </OZDialog>);
};

export default CopyToAccountForm;