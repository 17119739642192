import React, { useState, useMemo } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Grid, Stack, FormGroup, FormControlLabel, Tooltip, } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OZInput from "../../../components/admin/common/OZInput";
import OZDrawer from "../../../components/admin/common/OZDrawer/OZDrawer";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import OZFCheckbox from "../../../components/admin/common/OZFCheckbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { addNewIvr, getUserLoggedIn, updateIvrDetails } from "../../../services/api-service";
import "./Ivr.css";
import OZDialog from "../../../components/admin/common/OZDialog";
import CustomizedCheckbox from "../../../components/admin/common/CustomCheckBox";
import OZSelect from "../../../components/admin/common/OZSelect";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import { checkRoles } from "../../../utils/util";
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import { IVR_FLOW_URL } from "../../../utils/properties";
import { getUserId } from "../../../services/auth-service";
import CopyToAccountForm from "./CopyToAccountForm";


const DataViewer = (viewProps) => {
  let styleViewButton = useMemo(() => ({ fontfamily: 'Roboto', fontstyle: 'normal', fontweight: '400', fontsize: '14px', lineheight: '16px', }),
    []
  );
  return (
    <div style={{ maxHeight: "60vh", overflowY: "auto", padding: "10px", width: "29rem", }}>
      <Grid container={true} spacing={2} >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12} marginTop={"0.313rem"}>
            <label className="oz-Ivr-View-Header">Flow Type *</label>
          </Grid>
          <label className="oz-Ivr-View-Value">{viewProps?.data?.type?.flowType}</label>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack direction={"row"} spacing={1} marginLeft={"3px"}>
            <FormGroup>
              <FormControlLabel
                control={
                  <CustomizedCheckbox className="oz-Ivr-View-Checkbox" checked={viewProps.data.isCustom || false} disabled></CustomizedCheckbox>
                }
                label={<Typography sx={styleViewButton}>Custom</Typography>}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <CustomizedCheckbox className="oz-Ivr-View-Checkbox" checked={viewProps.data.isTransfer || false} disabled></CustomizedCheckbox>
                }
                label={<Typography sx={styleViewButton}>Transfer</Typography>}
              />
            </FormGroup>
          </Stack>
        </Grid>
        {viewProps?.data?.isCustom === true &&
          <div className="oz-Ivr-View-AppUrl-Container" >
            <label className="oz-Ivr-View-Header">App URL *</label>
            <TextField
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#D6D6D7',
                  fontSize: '14px',
                  height: '44px',
                  marginTop: '7px'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #D6D6D7'
                },
                '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #D6D6D7'
                }
              }} disabled fullWidth value={viewProps.data.appUrl || ""}>
            </TextField>
          </div>
        }
      </Grid>
    </div>
  );
};

const IvrForm = (props) => {
  const ivrFlow = [{ type: 1, flowType: "IVR" }, { type: 2, flowType: 'ICR' }, { type: 3, flowType: 'ICR-V2' }]
  let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add;

  if (props.data) {
    ivrFlow.forEach(item => {
      if (item.type === props.data.type) {
        props.data.type = item;
      }
    });
  }
  const validationSchema = useMemo(
    () =>
      yup.object({
        flowName: yup
          .string("Enter Flow name")
          .min(2, "Flow Name should be between 2 to 50 alphanumeric characters long allows special characters like .,@,_,-,space and Name cannot start and end with Special characters.")
          .max(50, "Flow Name should be between 2 to 50 alphanumeric characters long allows special characters like .,@,_,-,space and Name cannot start and end with Special characters.")
          .matches(/^([A-Za-z0-9]((\.|@|_|-|\s|\(|\))?[A-Za-z0-9](\(|\))?)+)$/, "Flow Name should be between 2 to 50 alphanumeric characters long allows special characters like .,@,_,-,space and Name cannot start and end with Special characters.")
          .required("Flow name is required"),
        type: (yup.object()
          .nullable()
          .required("Flow Type is required")),

        isCustom: false,
        // appUrl: yup.string("Enter App URL").when("isCustom", {
        //   is: true,
        //   then: yup.string().max(250, "App Url should not be more than 250 characters").required("App Url is required")
        // }),
        appUrl: yup
          .string("Enter App URL")
          .when('isCustom', {
            is: true,
            then: yup
              .string()
              .max(250, "App URL should not be more than 250 characters")
              .nullable()
              .required("App URL is required"),
            otherwise: yup.string().notRequired().nullable()
          }),
        isTransfer: false,

      }),
    []
  );
  const [ivrMorevertIconanchorE1, setIvrMorevertIconAnchorE1] = React.useState(false);
  const [open, setState] = useState(true);
  const [ivrFormMode, setIvrFormMode] = useState(initMode);
  const anchorRef = React.useRef();
  const [openDeleteDailog, setDeleteDialog] = useState(false)
  const [openCopyAccountDailog, setCopyAccountDialog] = useState(false)
  const [valid, setValid] = useState(true);

  let styleEditButton = useMemo(() => ({
    fontWeight: '400',
    fontSize: '14px',
    color: '#3D8BF8',
  }),
    []
  );

  const viewModemorevertclick = () => {
    setTimeout(() => setIvrMorevertIconAnchorE1(anchorRef.current), 1);
  };

  const handleMenuClose = () => {
    setIvrMorevertIconAnchorE1(false);
  };

  const closeIvrForm = () => {
    props.onClose();
    setState(false);
  };

  const onClickEditButton = () => {
    setIvrFormMode(ADMIN_FORM_MODES.edit);
  };

  const onClickDiscardButton = () => {
    setIvrFormMode(ADMIN_FORM_MODES.view);
  };

  const saveCopyFlowName = () => {
    setIvrMorevertIconAnchorE1(false);
    props.ivrCallBack("saveCopyFunction", props.data.flowId, props.data)
  };

  const saveCopyAccountFlow = (accountId) => {
    setIvrMorevertIconAnchorE1(false);
    props.ivrCallBack("saveCopyAccountFunction", props.data.flowId, props.data, accountId)
  };

  function openDeleteDialog() {
    setIvrMorevertIconAnchorE1(false);
    setDeleteDialog(true)
  };

  function openCopyAccountDialog() {
    setIvrMorevertIconAnchorE1(false);
    setCopyAccountDialog(true)
  };

  const deleteIvr = () => {
    setIvrMorevertIconAnchorE1(false);
    props.ivrCallBack("deleteFunction", props.data.flowId, props.data);
    setDeleteDialog(false)
  }
  const openAppUrlInNewWindow = (e, data) => {
    try {
        const url = new URL(IVR_FLOW_URL);
        const currentApplicationHost = window.location.host;
        url.host = currentApplicationHost;
        const updatedUrl = url.toString();
      e.stopPropagation();
      let userId = getUserId();
      if (data?.type?.type === 1) {
        window.open(`${updatedUrl}/index.html?#/flows/${data.flowId}/userId/${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
        // window.open(`https://beta-ivr.ccaas.ozonetel.com/ivrdd/index.html?#/flows/${data.id}/userId/${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
      } else if ((data?.type?.type === 3)) {
        window.open(`${updatedUrl}/botv2/index.html?flowId=${data?.flowId}&userId=${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
        // window.open(`https://beta-ivr.ccaas.ozonetel.com/ivrdd/bot/index.html?#/flows/${data.id}/userId/${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
      } else {
        window.open(`${updatedUrl}/bot/index.html?#/flows/${data?.flowId}/userId/${userId}`, 'IVR Designer', 'location=1,status=1,scrollbars=1,addressbar=no,width=900,height=300')
      }
    } catch (e) {
      showErrorNotification("Failed to open url");
    }
  };

  async function addIvr(data) {
    data.type = data.type.type;
    try {
      addNewIvr(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            closeIvrForm();
            props.Ivr();
            showSuccessNotification(`IVR Flow ${data.flowName} has been added Successfully`)
          }
          else {
            showErrorNotification(resp?.Message);
            // showErrorNotification(JSON.stringify(resp.Message));
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  };

  async function updateIvrFlow(data) {
    data.type = data.type.type;
    data.id = props.data?.id;
    try {
      updateIvrDetails(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            closeIvrForm();
            props.Ivr();
            showSuccessNotification(`IVR Flow ${data.flowName} has been updated Successfully`)
          }
          else {
            // showErrorNotification(JSON.stringify(resp.Message));
            showErrorNotification(resp?.Message);
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  };

  const getValidationInViewMode = (e, value) => {
    props.data.flowName = value;
    const regex = /^([A-Za-z0-9]((\.|@|_|-|\s|\(|\))?[A-Za-z0-9](\(|\))?)+)$/;
    const isMatch = regex.test(value);

    const digitCount = value.length;

    // Check the conditions for validation
    const isValidLength = value.length >= 2 && value.length <= 50;
    const isValidDigitCount = digitCount >= 2 && digitCount <= 50;

    // Final validation check
    if (isMatch && isValidLength && isValidDigitCount) {
      setValid(true);
    } else {
      setValid(false);
    }
  }

  let buttonStyle = useMemo(
    () => ({
      borderRadius: "0.5rem",
      height: "2.75rem",
      fontSize: "0.875rem",
      fontWeight: 400,
    }),
    []
  );
  const styleMenuItemButton = useMemo(
    () => ({
      "&.MuiButtonBase-root": {
        color: '#212121',
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 400,
        height: '18px',
        lineHeight: "14px",
      },
      '&:hover': {
        borderRadius: "8px",
      },
    }),
    []
  );

  const DataForm = (props) => {
    let initData = props.data || {
      flowName: "",
      appUrl: "",
      type: null,
      isCustom: false,
      isTransfer: false,
    };
    let style1 = useMemo(() => ({
      width: '400px',
      height: "20rem",
      paddingtop: "10px"

    }), [])

    let style2 = useMemo(() => ({
      height: '68vh',
      padding: '5px'
    }), [])

    return (
      <div className="ozIvrAddForm">
        <Formik
          initialValues={initData}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            let finalData = structuredClone(values)
            if (ivrFormMode === ADMIN_FORM_MODES.add) {
              addIvr(finalData);
            }
            if (ivrFormMode !== ADMIN_FORM_MODES.add) {
              if (valid === true) {
                updateIvrFlow(finalData);
              }
            }
          }}
        >
          {(props) => (
            <form
              onSubmit={props.handleSubmit}
              id="ivrForm"
              className="ozcw-scrollbar"
              style={ivrFormMode === ADMIN_FORM_MODES.add ? style1 :
                style2}
            >
              <Grid container={true} spacing={1}>
                {(ivrFormMode === ADMIN_FORM_MODES.add) && (<Grid item xs={12} sm={12} md={12} lg={12}>
                  <OZInput
                    name="flowName"
                    label="Flow Name *"
                    placeholder="Enter Name"
                  />
                </Grid>)}
                {(ivrFormMode === ADMIN_FORM_MODES.add || ivrFormMode === ADMIN_FORM_MODES.edit) && (
                  <Grid container spacing={1} marginLeft="1px" marginTop={ivrFormMode === ADMIN_FORM_MODES.add ? "10px" : "20px"}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <OZSelect
                        name="type"
                        label="Flow Type *"
                        placeholder={"Please Select"}
                        optionLabel="flowType"
                        options={[{ type: 1, flowType: "IVR" }, { type: 2, flowType: "ICR" }, { type: 3, flowType: "ICR-V2" }]} />

                    </Grid>
                    <Grid container direction={"row"} item xs={12} sm={12} md={12} lg={12} marginLeft="4px" >
                      <OZFCheckbox
                        labelPosition="end"
                        name="isCustom"
                        label="Custom"
                      />
                      <OZFCheckbox
                        labelPosition="end"
                        name="isTransfer"
                        label="Transfer"
                      />
                    </Grid>
                    {props?.values?.isCustom === true &&
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        < OZInput
                          name="appUrl"
                          label="App URL *"
                          placeholder="App URL"
                          disabled={!props.values.isCustom}
                        />
                      </Grid>
                    }
                  </Grid>)}
              </Grid>
            </form>
          )}
        </Formik>
        {
          ivrFormMode === ADMIN_FORM_MODES.edit &&
          <Grid item xs={12} sm={6} md={4} lg={12}>
            {ivrFormMode === ADMIN_FORM_MODES.edit &&
              <div className="oz-ivr-drawer-buttons-container">
                <Button sx={buttonStyle} variant="outlined" onClick={closeIvrForm}>
                  Cancel
                </Button>
                <Button sx={{ ...buttonStyle, marginLeft: "10px" }} variant="contained" type="submit" form="ivrForm" >
                  Save
                </Button>
              </div>
            }
          </Grid>
        }
      </div >
    );
  };
  return (
    <div>
      <div>
        {ivrFormMode === ADMIN_FORM_MODES.add && (
          <OZDialog
            title="Add IVR Flow"
            open={open}
            onClose={closeIvrForm}
            saveButtonProps={{
              form: "ivrForm",
              type: 'submit',
              label: 'save'
            }}>
            {ivrFormMode === ADMIN_FORM_MODES.add && <DataForm />}
          </OZDialog>
        )}
      </div>
      {ivrFormMode !== ADMIN_FORM_MODES.add && (
        <OZDrawer
          open={open}
          formType='ivr'
          title={
            <div style={{ width: "100%" }}>
              <div className="oz-ivr-drawer-title-container">
              {/* {ivrFormMode !== ADMIN_FORM_MODES.edit && authPage(AdminPages.IVR_FLOWS_VIEW) && <img className="oz-ivr-view-morevereticon" ref={anchorRef} */}
                {ivrFormMode !== ADMIN_FORM_MODES.edit && (newAuthPages(NewAdminPages.IVR_FLOWS) || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Full') || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Edit')) && <img className="oz-ivr-view-morevereticon" ref={anchorRef}
                  src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                  alt="edit"
                  onClick={viewModemorevertclick}
                ></img>}
                {ivrFormMode !== ADMIN_FORM_MODES.edit &&
                  <Tooltip title={props?.data?.flowName}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '18px',
                        alignSelf: 'center',
                        // marginLeft: authPage(AdminPages.IVR_FLOWS_VIEW) ? "0px" : "10px",
                        marginLeft: newAuthPages(NewAdminPages.IVR_FLOWS) || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Full') || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Edit')  ? "0px" : "10px",
                        maxWidth: "400px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
                      }}
                    >
                      {props?.data?.flowName}
                    </Typography></Tooltip>}
                {ivrFormMode === ADMIN_FORM_MODES.edit &&
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        backgroundColor: '#ffff',
                        fontSize: '18px',
                        color: "#212121",
                        height: '44px',
                        '&:hover': {
                          border: "none"
                        },
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #D6D6D7'
                      },
                      '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #D6D6D7'
                      }
                    }}
                    defaultValue={props?.data?.flowName}
                    fullWidth
                    // onChange={(e) => { props.data.flowName = e.target.value }}
                    onChange={(e) => getValidationInViewMode(e, e.target.value)}
                    error={!valid}
                    helperText={!valid ? "Flow Name should be between 2 to 50 alphanumeric characters long allows special characters like .,@,_,-,space and Name cannot start and end with Special characters." : ""}
                  />
                }
                <div  >
                  <Menu
                    anchorEl={ivrMorevertIconanchorE1}
                    open={Boolean(ivrMorevertIconanchorE1)}
                    onClose={handleMenuClose}
                    sx={{
                      "& .MuiPaper-root": {
                        backgroundColor: "white",
                        width: "133px",
                      },
                      "& .MuiMenu-paper": {
                        backgroundColor: "white",
                        boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                        border: "0.0625rem solid #E6E5E6",
                        borderRadius: "12px",
                        marginLeft: "-8.9625rem",
                        marginTop: '-1.5rem'
                      },
                    }}
                  >
                    {ivrFormMode !== ADMIN_FORM_MODES.edit && (checkRoles("ROLE_ADMIN") || checkRoles("ROLE_IVR_COPY_USER")) && (<MenuItem sx={{ ...styleMenuItemButton }} onClick={saveCopyFlowName}>Save Copy</MenuItem>)}
                    {ivrFormMode !== ADMIN_FORM_MODES.edit && (checkRoles("ROLE_ADMIN")) && (<MenuItem sx={{ ...styleMenuItemButton, marginTop: "7px" }} onClick={openCopyAccountDialog}>Save Copy Account</MenuItem>)}
                    <MenuItem sx={{ ...styleMenuItemButton, marginTop: checkRoles("ROLE_ADMIN") ? "7px" : "0px" }} onClick={openDeleteDialog}>Delete</MenuItem>
                  </Menu>
                </div>
              </div>
              {openCopyAccountDailog &&
                <CopyToAccountForm saveCopyAccountFlow={saveCopyAccountFlow} setCopyAccountDialog={setCopyAccountDialog}/>
              }
              {openDeleteDailog && <DeleteDialog title={"Delete IVR Flow"} content={"This will delete this IVR Flow:" + props.data.flowName + " permanently. You cannot undo this action."} onClose={() => { setDeleteDialog(false) }
              } open={true} deleteFunction={deleteIvr} />}
              <div>
              {/* {authPage(AdminPages.IVR_FLOWS_VIEW) && */}
                {(newAuthPages(NewAdminPages.IVR_FLOWS) || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Full') || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'View')) &&
                  <Typography
                    size="small"
                    variant="text"
                    sx={{ ...styleEditButton, cursor: "pointer", marginLeft: ivrFormMode === "View" ? "10px" : "2px" }}
                    className="viewButton"
                    onClick={(e) => openAppUrlInNewWindow(e, props.data)}>
                    Design
                  </Typography>
                }
                {ivrFormMode === ADMIN_FORM_MODES.edit && (<Typography
                  size="small"
                  variant="text"
                  sx={{
                    ...styleEditButton,
                    marginLeft: "362px",
                    marginTop: "4px",
                    position: "absolute",
                    cursor: "pointer"
                  }}
                  onClick={onClickDiscardButton}
                >
                  Discard
                </Typography>)}
              </div>
            </div>
          }
          onClose={closeIvrForm}
          hideEditIcon={(newAuthPages(NewAdminPages.IVR_FLOWS) || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Full') || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Edit')) ? false : true}
          onEdit={onClickEditButton}
          mode={ivrFormMode}
        >
          {ivrFormMode !== ADMIN_FORM_MODES.edit && <DataViewer data={props.data} />}
          {ivrFormMode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}
        </OZDrawer >
      )
      }
    </div >
  );
};

export default IvrForm;
