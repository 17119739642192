import React from 'react';
import { TextField, Typography } from '@mui/material';
import { connect, getIn } from "formik";


const OZInput = connect(({ type = "text", name, label, height, disabled, formik, ...additionalProps }) => {

    const error = getIn(formik.errors, name);
    const touch = getIn(formik.touched, name);

    return (
        <React.Fragment>
            {label && <Typography htmlFor={name}
                fontSize='12px'
                fontWeight={400}
                lineHeight='14px'
                marginBottom={'6px'}
                marginLeft='1px'
                color={disabled ? '#99A0A8' : (name === "authConfig" || name === "federationUrl" || name === "folderName" || name === "bucketName" || name === "accessKey" || name === "secretKey" || name === "gcptype" || name === "projectId" || name === "PrivateId" || name === "PrivateKey" || name === "clientEmail" || name === "clientId" || name === "authUrl" || name === "tokenUrl" || name === "auth_provider_x509_curt_url" || name === "client_provider_x509_curt_url" || name === "url" || name === "port" || name === "userDn" || name === "domain" || name === "dc") ? '#47586E' : '#212121'}
            >
                {label}
            </Typography>}
            <TextField
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        backgroundColor: '#ffff',
                        // fontSize: '14px',
                        height: height || '44px',
                        // color: (name === "authConfig" || name === "federationUrl" || name === "folderName" || name === "bucketName"  || name === "accessKey" || name === "secretKey" || name === "gcptype" || name === "projectId" || name === "PrivateId" || name === "PrivateKey" || name === "clientEmail" || name === "clientId" || name === "authUrl" || name === "tokenUrl" || name === "auth_provider_x509_curt_url" || name === "client_provider_x509_curt_url" || name === "url" || name === "port" || name === "userDn" || name === "domain" || name === "dc") && "#536580",
                        fontWeight: (name === "authConfig" || name === "federationUrl" || name === "folderName" || name === "bucketName" || name === "accessKey" || name === "secretKey" || name === "gcptype" || name === "projectId" || name === "PrivateId" || name === "PrivateKey" || name === "clientEmail" || name === "clientId" || name === "authUrl" || name === "tokenUrl" || name === "auth_provider_x509_curt_url" || name === "client_provider_x509_curt_url" || name === "url" || name === "port" || name === "userDn" || name === "domain" || name === "dc") && 400,
                        fontSize: (name === "authConfig" || name === "federationUrl" || name === "folderName" || name === "bucketName" || name === "accessKey" || name === "secretKey" || name === "gcptype" || name === "projectId" || name === "PrivateId" || name === "PrivateKey" || name === "clientEmail" || name === "clientId" || name === "authUrl" || name === "tokenUrl" || name === "auth_provider_x509_curt_url" || name === "client_provider_x509_curt_url" || name === "url" || name === "port" || name === "userDn" || name === "domain" || name === "dc") ? "16px" : "14px"
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #D6D6D7'
                    },
                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        // backgroundColor: '#D6D6D7', //TODO
                        border: '1px solid #D6D6D7'
                    }
                }}
                fullWidth
                disabled={disabled}
                type={type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getIn(formik.values, name)}
                error={touch && Boolean(error) ? true : false}
                helperText={touch && error}
                name={name}
                id={name}
                {...additionalProps}
            />
        </React.Fragment>
    );
})

export default OZInput;