import React, { useMemo, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import { connect, getIn } from "formik";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Popper from "@mui/material/Popper";
import "../../../../pages/admin/admin.css";
import { Chip, Typography, Grid, Box, Button, IconButton, Divider, Tooltip } from "@mui/material";
import CustomizedCheckbox from "../CustomCheckBox";
import Avatar from '@mui/material/Avatar';
import { stringAvatar } from "../Avatar";


const CustomAutoCompleteBulkCampaign = connect(
    ({
        Datas,
        placeholder,
        formik,
        width,
        name,
        labelHeader,
        optionLabelName,
        optionId,
        mode,
        showAvatar,
        showCheckBox,
        showAvatarInChip,
        temporaryMode,
        multiple,
        onDeleteIcon,
        currentLabel,
        showBottomButton,
        buttonName,
        ...additionalProps
    }) => {

        const handleDeleteIcon = (event, currentLabel) => {
            onDeleteIcon(currentLabel);
        }

        const DropDownIcon = () => {
            return (
                // <div style={{ display: "flex", alignContent: "center", marginTop: "7px", marginRight: "4px", }}>
                //     <img
                //         src={`${process.env.PUBLIC_URL}/icons/downArrowDropDown.svg`}
                //         alt="edit"
                //     ></img>
                // </div>
                <div style={{ display: 'flex', alignContent: 'center', backgroundColor: '#FFFFFF', borderRadius: '8px', marginTop: '-7px' }}>
                    <IconButton sx={{
                        marginRight: '5px', position: 'absolute !important', right: '0', pointerEvents: 'none !important'
                    }} flexGrow={1}>
                        <img
                            style={{ marginRight: '30px', paddingTop: '7px', paddingRight: '10px' }}
                            src={`${process.env.PUBLIC_URL}/icons/downArrowDropDown.svg`}
                            alt="edit"
                        >
                        </img>
                    </IconButton>
                    <Divider sx={{ color: '#99A0A8', height: '30px', marginTop: '4px', marginLeft: '20px' }} orientation="vertical" />
                    <img
                        style={{ padding: '7px' }}
                        src={`${process.env.PUBLIC_URL}/icons/deleteIconGrey.svg`}
                        alt="edit"
                        onClick={(e) => handleDeleteIcon(e, currentLabel)}
                    >
                    </img>
                </div>
            )
        };

        const PopperMy = useMemo(
            () => (props, option) => {
                return (
                    <Popper {...props} sx={{
                        paddingTop: "8px", paddingBottom: "8px", color: "#212121",
                        "& .MuiAutocomplete-listbox": {
                            "& li": {
                                height: (name === "dispositions" ? "75px !important" : "35px !important"),
                                padding: '0px',
                                paddingLeft: (option[optionLabelName] !== "Button") ? '5px' : '0px',
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#FFFFFF !important",
                                marginLeft: "-1px",
                                maxWidth: "100% !important",
                                wordBreak: "break-all !important",
                                whiteSpace: "normal !important",
                                //list item specific styling
                            },
                            "& :hover": {
                                backgroundColor: "#FFFFFF !important"
                            },
                        },
                        '& .MuiAutocomplete-paper': {
                            boxShadow: 'none',
                            border: "1px solid #E5E5E5",
                            borderRadius: mode === "Edit" ? '0px' : "8px",
                            borderBottom: mode === "Edit" ? '0px' : "1px solid #E5E5E5",
                            borderRight: mode === "Edit" ? '0px' : "1px solid #E5E5E5",
                            borderLeft: mode === "Edit" ? '0px' : "1px solid #E5E5E5",
                            borderTop: mode === "Edit" ? "0px" : "1px solid #E5E5E5",
                            height: mode === "Edit" ? "290px" : "",
                        }, "& .MuiAutocomplete-noOptions": {
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "16px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#212121"
                        }
                    }} />
                );
            },
            [],
        );
        const selected = getIn(formik?.values, name)
        const [tempMode, setTempMode] = useState(temporaryMode ? temporaryMode : "temp")
        const selectAll = selected?.length === Datas?.length;

        const [limit, setLimit] = useState(10);
        const listRef = useRef(null);

        const [inputValue, setInputValue] = useState("");
        const [oldArray, setOldArray] = useState((selected?.length > 0) ? selected : []);

        const makeNewArray = (oldData: any) => {
            if (oldArray === null || oldArray.length < 1) {
                return oldData;
            } else {
                const newnew = oldArray.concat(oldData);
                const uniqueIds = [];
                const result = newnew.filter(obj => {
                    if (!uniqueIds.includes(obj.id)) {
                        uniqueIds.push(obj.id);
                        return true;
                    }
                    return false;
                })
                return result;
            }
        }

        function changeMode() {
            if (mode !== "Add") {
                setTempMode("Edit")
            }
        }

        const handleScroll = () => {
            const l = limit;
            setLimit(l + 5);
        }

        const handleClose = () => {
            setLimit(10);
        }

        const listItemStyle = useMemo(
            () => ({
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "16px",
                color: '#212121',
                paddingLeft: "6px"
            }),
            []
        );

        let styleFieldHeader = useMemo(
            () => ({
                display: "flex",
                fontSize: "12px",
                fontWeigth: 400,
                color: '#212121',
                lineHeight: "20px",
                paddingLeft: "5px",
            }),
            []
        );

        return (
            <div>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {labelHeader && <Typography htmlFor={name}
                        fontSize='12px'
                        fontWeight={400}
                        lineHeight='14px'
                        marginBottom={'6px'}
                        marginLeft='1px'
                        color={'#212121'}
                    >
                        {labelHeader}
                    </Typography>}
                    <Box
                        sx={(mode === "Edit" && tempMode === "Edit") ? {
                            border: "1px solid #E5E5E5",
                            borderRadius: "8px",
                            paddingTop: "8px",
                            paddingLeft: "15px",
                            paddingBottom: "8px",
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                        } : {}}
                    >
                        {mode === "Edit" && tempMode === "Edit" && <label
                            sx={mode === "Edit" && tempMode === "Edit" && {
                                color: "#212121",
                                fontWeight: 400,
                                fontSize: "14px",
                                marginLeft: "10px",
                            }}
                        >
                            {(selected?.length < 10) ? ("0" + (selected?.length > 0 ? selected?.length : "")) : selected?.length} Selected...
                        </label>
                        }
                    </Box>
                    <Box
                        sx={(mode === "Edit" && tempMode === "Edit") ? {
                            border: "1px solid #E5E5E5",
                            borderRadius: "8px",
                            paddingTop: "15px",
                            paddingLeft: "15px",
                            paddingBottom: "305px",
                            borderTop: 0,
                            paddingRight: "15px",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            height: mode === "Add" ? "30px" : "300px"
                        } : {}}
                    >
                        <Autocomplete
                            sx={{
                                '& 	.MuiAutocomplete-listbox': {
                                    scrollbarColor: 'rgb(190, 190, 190) rgb(240, 240, 240)',
                                    scrollbarWidth: 'thin',
                                },
                                "& .MuiAutocomplete - root": {
                                    "& .MuiAutocomplete-noOptions": {
                                        color: "red"
                                    }
                                },

                                //"& .MuiAutocomplete-popupIndicator": { transform: "none" },
                                "& .MuiAutocomplete-popupIndicatorOpen": { transform: "none", marginRight: "0px", padding: "0px", },
                            }}
                            popupIcon={additionalProps.popupIcon || <DropDownIcon />}
                            clearIcon={""}
                            noOptionsText={"No matching result found!"}
                            className="ozcw-scrollbar"
                            multiple={multiple}
                            size="small"
                            id={name}
                            options={Datas}
                            value={selected}
                            renderTags={(value) => {
                                return (
                                    (mode === 'Add'
                                        ? selected.map(
                                            (selectedvalues, i) =>
                                            (selectedvalues[optionLabelName] !== "Select All" && selectedvalues[optionLabelName] !== "Button" &&
                                                <div >
                                                    < Chip
                                                        avatar={showAvatarInChip ? <Avatar
                                                            style={{ width: "24px", height: "24px", fontSize: "14px", color: "#212121", marginLeft: "4px" }}
                                                            {...stringAvatar(selectedvalues[optionLabelName])}>
                                                        </Avatar> : null}
                                                        key={selectedvalues[optionLabelName] + i} label={selectedvalues[optionLabelName]} size="small" sx={{
                                                            backgroundColor: "#F5F5F6", color: "#536580",
                                                            fontSize: "14px", fontWeight: 400, lineHeight: "16px",
                                                            marginTop: "4px", marginBottom: "4px", marginLeft: "4px", display: "flex", alignSelf: "center",
                                                            "& .MuiChip-label": {
                                                                color: '#536580'
                                                            }

                                                        }} />
                                                </div>
                                            )
                                        )
                                        :
                                        (<div style={{
                                            display: "flex",
                                            alignItems: 'center',
                                            alignContent: "center",
                                        }}>
                                            <Typography sx={styleFieldHeader}>
                                                {selected.map(
                                                    (selectedvalues, index) =>
                                                        (index < 3 && ((selectedvalues[optionLabelName] + (((selected.length - 1) > index) ? ", " : ""))))
                                                )}
                                            </Typography>
                                            <Typography sx={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                color: "#212121",
                                                paddingRight: '5px',
                                                paddingLeft: "5px"
                                            }}>
                                                {(selected.length > 3) ? "..." : ""}
                                            </Typography>

                                            <Typography sx={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                color: "#99A0A8",

                                            }}>
                                                {(selected.length > 3) ? (("+" + (selected.length - 3))) : ""}
                                            </Typography>
                                        </div>))


                                )
                            }}
                            // onChange={(e, value) => {
                            //     if (multiple) {
                            //         if (value.find((option) => option.id === 0)) {
                            //             if (selected.length === Datas.length) {
                            //                 setSelectAll(false)
                            //                 formik.setFieldValue(name, []);
                            //             }
                            //             else {
                            //                 setSelectAll(true)
                            //                 formik.setFieldValue(name, Datas);
                            //             }
                            //         } else {
                            //             setSelectAll(false)
                            //             if (selected.length === Datas.length - 1) {
                            //                 setSelectAll(true)
                            //             }
                            //             formik.setFieldValue(name, value);
                            //         }
                            //     } else {
                            //         formik.setFieldValue(name, value);
                            //     }
                            //     additionalProps.getValueFromAuto(value)
                            // }}

                            onChange={(e, value) => {
                                if (multiple) {
                                    if (value.find((option) => option.id === 0)) {
                                        const filteredDatas = Datas.filter((value) => value[optionLabelName].includes(inputValue))
                                        const newArray = makeNewArray(filteredDatas);
                                        setOldArray(newArray);

                                        if (selected.length === newArray.length) {
                                            setOldArray([]);
                                            formik.setFieldValue(name, []);
                                        }
                                        else {
                                            formik.setFieldValue(name, newArray);
                                        }

                                    } else {
                                        if (selected.length === Datas.length) {
                                        }
                                        formik.setFieldValue(name, value);
                                    }
                                } else {
                                    formik.setFieldValue(name, value);
                                }
                                additionalProps.getValueFromAuto(value)
                            }}

                            disableCloseOnSelect={multiple}
                            getOptionLabel={
                                ((option) => option[optionLabelName])
                            }

                            ListboxProps={{
                                useRef: listRef,
                                onScroll: handleScroll,
                            }}

                            onClose={handleClose}

                            PopperComponent={PopperMy}
                            renderOption={(props, option, { selected }) => (
                                <Tooltip title={option[optionLabelName]}>
                                    <div style={{ bgcolor: "#FFFFFF", marginTop: option[optionLabelName] === "Button" && "10px", borderTop: option[optionLabelName] === "Button" && "1px solid #E5E5E5" }} >
                                        <li {...props} style={{ height: option[optionLabelName] === "Button" ? "32px" : "36px" }}>
                                            {(showCheckBox && (option[optionLabelName] !== "Button")) && <CustomizedCheckbox
                                                checked={(option["id"] === 0) ? selectAll : selected}
                                            />}
                                            {(showAvatar && (option[optionLabelName] !== "Button")) && (option[optionLabelName] !== "Select All") &&
                                                <Avatar style={{ width: "24px", height: "24px", fontSize: "14px", color: "#212121", marginLeft: "4px" }} {...stringAvatar(option[optionLabelName])}></Avatar>
                                            }
                                            {option[optionLabelName] === "Button" ?
                                                <Button fullWidth sx={{
                                                    fontSize: "14px",
                                                    lineHeight: "16px"
                                                    , color: "#3D8BF8", fontWeight: '400', bgcolor: "transparent"
                                                }} onClick={() => { additionalProps.bottomButtonFunction() }}>
                                                    {/* {additionalProps.buttonName} */}
                                                    {buttonName}
                                                </Button>
                                                :
                                                <Typography sx={listItemStyle} >{option[optionLabelName]}</Typography>
                                            }
                                        </li>
                                    </div>
                                </Tooltip>
                            )}
                            style={{ width: { width } }}

                            onInputChange={(e, v) => {
                                setInputValue(v);
                            }}
                            renderInput={(params) => (
                                <div style={{ display: 'flex', alignContent: 'center' }}>
                                    <TextField
                                        onClick={changeMode}
                                        sx={{
                                            minWidth: "326px",

                                            paddingRight: tempMode === "temp" ? "" : "10px",
                                            '& .MuiOutlinedInput-root': {
                                                minHeight: "30px",
                                                fontSize: "14px",
                                                color: params.inputProps.value === "" ? "#99A0A8" : "#212121",
                                                borderRadius: '8px',
                                            },

                                            '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                                                paddingTop: '0px',
                                                paddingBottom: '0px',
                                            },
                                            '& .MuiAutocomplete-input': {
                                                display: (tempMode === 'temp') && (mode !== 'Add') ? 'none' : 'block'

                                            },
                                            "& .MuiOutlinedInput-root.Mui-focused": {
                                                "& > fieldset": mode === "Add" ? {
                                                    borderBottomLeftRadius: "0px", borderBottomRightRadius: '0px', border: "1px solid #E5E5E5"
                                                } : { border: "1px solid #E5E5E5" },
                                            },
                                            "& .MuiOutlinedInput-root:hover": {
                                                "& > fieldset": {
                                                    border: "1px solid #E5E5E5"
                                                }
                                            }
                                        }}
                                        {...params} placeholder={(tempMode !== 'temp') || (mode === 'Add') ? placeholder : ""} />
                                </div>
                            )}
                            isOptionEqualToValue={(option, value) => option[optionId || optionLabelName] === value[optionId || optionLabelName]}
                            filterOptions={(options, params) => {
                                let filtered = createFilterOptions({
                                    matchFrom: 'any',
                                    limit: limit,
                                })(options, params);

                                let selectedFiltered = createFilterOptions({//for the selected list also to be filtered
                                    matchFrom: 'any',
                                    limit: limit,
                                })((selected || []), params);

                                let sortedOptions = filtered
                                let uniqueOptions = filtered

                                if (selected?.length > 0) {
                                    // sortedOptions = [...selected, ...filtered]
                                    sortedOptions = [...selectedFiltered, ...filtered]//for the selected list also to be filtered
                                    uniqueOptions = [...new Map(sortedOptions.map((item) => [item['id'], item])).values()];
                                }
                                filtered = uniqueOptions
                                let newOptions = filtered
                                if (filtered.length > 0) {
                                    if (additionalProps.showselectall) {
                                        newOptions = [{ id: 0, [optionLabelName]: "Select All" }, ...filtered]
                                    }
                                    if (showBottomButton) {
                                        newOptions.push({ id: Datas.length + 1, [optionLabelName]: "Button" })
                                    }
                                }
                                if (showBottomButton || additionalProps.showselectall) { return newOptions; }
                                else {
                                    return filtered
                                }
                            }}
                            {...additionalProps}
                        />
                    </Box>
                </Grid>

            </div >
        );
    }
);
export default CustomAutoCompleteBulkCampaign;
