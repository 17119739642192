import { Button, Grid, Typography, MenuItem, Select } from "@mui/material";
import { FieldArray } from "formik";
import CustomAutoComplete from "../../../components/admin/common/AutoComplete/CustomAutoComplete";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import React,{useMemo, useEffect, useState} from 'react';
import { showErrorNotification } from "../../../components/admin/common/NotiService";
import { ADMIN_FORM_MODES } from "../../../utils/constants";


export function UserConfiguration(props){
    // console.log("here value comin g", props);
    const [valueRepeating, setValueRepeating] = useState(false);

    useEffect(() => {
        //:::::::::::::::;this is doing for the cheking the reduntant condition for Features purpose:::::::::::::::://
        const checkFirst = checkFirstFunction(props?.formikProps?.values?.profileSetting);
        //:::::::::::::::;this is doing for the cheking the reduntant condition for Features purpose:::::::::::::::://
        const valueGot = checkMutuallyConditions(props?.formikProps?.values?.profileSetting);
        if(valueGot === true || checkFirst === true){
            props.forceStopToSave(true);
        }else{
            props.forceStopToSave(false);
        }
        
    }, [props?.formikProps?.values?.profileSetting]);


    const checkFirstFunction = (value) => {
        let newparseData = structuredClone(value);
        let flag = 0;
        const ALLOWED_CONFIG_IDS = ["1"];
    
        const convertConfigurations = (configurations) => {
            return configurations?.map(config => `${config?.id}`);
        };
    
        const transformedData = newparseData?.map(item => {
            return {
                accessLevel: item?.access || '',
                configutaions: item?.configutaions?.length !== 0 ? convertConfigurations(item?.configutaions) : []
            };
        });
    
        const allConfigs = [];
    
        for (let i = 0; i < transformedData?.length; i++) {
            const currentConfigs = transformedData[i]?.configutaions;

            // Check if the current item has access level 'dataUpload'
            if (transformedData[i].accessLevel === 'dataUpload') {
                const invalidConfig = currentConfigs.some(config => !ALLOWED_CONFIG_IDS.includes(config));
                if (invalidConfig) {
                    showErrorNotification(`For the features at index ${i + 1}, only 'Campaigns' can be selected when 'dataUpload' is chosen.`);
                    flag = 1;
                    return true;
                }
            }

            for (let config of currentConfigs) {
                if (allConfigs.includes(config)) {
                    showErrorNotification("Redundant configuration found, Please check your configurations");
                    flag = 1;
                    return true;
                }
                allConfigs.push(config);
            }
        }
    
        if (flag === 1) {
            return true;
        }
        return false;
    };

    const checkMutuallyConditions = (value) => {
        let newparseData = structuredClone(value);
    
        let flag = 0;
    
        let newConvertedData = [];
    
        const convertedData = newparseData?.map((item) => {
            return {
                accessLevel: item.access || '',
                configutaions: item.configutaions || []
            };
        });
    
        newparseData?.map((item, itemIndex) => {
            const data = {
                ...convertedData[itemIndex],
            }
            newConvertedData.push(data);
        });
    
        function convertConfigurations(configurations) {
            return configurations.map(config => {
                return `${config.id}`;
            });
        }
    
        const transformedData = newConvertedData?.map(item => {
            if (item.configutaions.length !== 0) {
                item.configutaions = convertConfigurations(item.configutaions);
            }
            return item;
        });
    
        // Logic to check mutually exclusive conditions
        for (let i = 0; i < transformedData.length; i++) {
            for (let j = i + 1; j < transformedData.length; j++) {
                const configsEqual = transformedData[j].configutaions.some(element => transformedData[i].configutaions.includes(element));
    
                if (transformedData[i].accessLevel === 'viewAccess') {
                    if (transformedData[j].accessLevel === 'viewAccess') {
                        if (configsEqual) {
                            showErrorNotification("Redundant configuration found, Please check your configurations");
                            flag = 1;
                            setValueRepeating(true);
                            return true;
                        } else {
                            // setValueRepeating(false);
                        }
                    }
                } else if (transformedData[i].accessLevel === 'editAccess') {
                    if (transformedData[j].accessLevel === 'editAccess') {
                        if (configsEqual) {
                            showErrorNotification("Redundant configuration found, Please check your configurations");
                            flag = 1;
                            setValueRepeating(true);
                            return true;
                        } else {
                            // setValueRepeating(false);
                        }
                    }
                } else if (transformedData[i].accessLevel === 'fullAccess') {
                    if (transformedData[j].accessLevel === 'fullAccess') {
                        if (configsEqual) {
                            showErrorNotification("Redundant configuration found, Please check your configurations");
                            flag = 1;
                            setValueRepeating(true);
                            return true;
                        } else {
                            // setValueRepeating(false);
                        }
                    }
                } else if (transformedData[i].accessLevel === 'dataUpload') {
                    if (transformedData[j].accessLevel === 'dataUpload') {
                        if (configsEqual) {
                            showErrorNotification("Redundant configuration found, Please check your configurations");
                            flag = 1;
                            setValueRepeating(true);
                            return true;
                        } else {
                            // setValueRepeating(false);
                        }
                    }
                }
            }
            if (flag === 1) {
                return null;
            }
        }
        if (flag === 1) {
            return true;
        }
    };
    //::::::::::::new:::::::::::://

    let selectMenuStyle = useMemo(
        () => ({
            opacity:'0.5',
            paddingLeft: '5px'
        }),
        []
    );
    const formTitleStyle = useMemo(
        () => ({
            fontWeight: '400',
            fontSize: '12px',
            color: '#47586E'
        }),
        []
    );
    let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400, marginTop: '4px' }), []);
    let removeButtonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400, color: '#536580' , marginTop: '4px'}), []);

    function canSelectAccess(config, e) {
        const targetValue = e.target.value;
        const subUserConfig = config.profileSetting;
    
        for (let i = 0; i < subUserConfig.length; i++) {
            if (subUserConfig[i].access === targetValue) {
                return false;
            }
        }
    
        return true;
    }

    const handleChange = (e, props, index, userProfileArray) => {
        const result = canSelectAccess(props, e);
        if(!result){
            showErrorNotification(`Please select another option, as ${e.target.value} option already selected`);
            return;
        }else{

            let newValue = structuredClone(props);

            const modifiedData = newValue?.profileSetting?.map((item, itemIndex) => {
                let tempArray = {};
                if(itemIndex === index){
                    const data = {
                        access : e?.target?.value,
                        configutaions : item?.configutaions,
                    }
                    tempArray = data;
                }else{
                    const data = {
                        access : item?.access,
                        configutaions : item?.configutaions,
                    }
                    tempArray = data;
                }
                return tempArray;
            });

            newValue.profileSetting[index].access = modifiedData[index]?.access;
            userProfileArray.replace(index, newValue?.profileSetting[index]);
        }
    }

    function getValidation(value){
        let flag = false;
        value?.formikProps?.values?.profileSetting.map((item, index) => {
            if(item?.access === '' || item?.configutaions?.length === 0){
                flag =  true;
            }else{
                flag = false;
            }
        })
        return flag;
    }

    function handleAddButton(list) {
        let NFlag = getValidation(props);
        let tempLen = props?.formikProps?.values?.profileSetting?.length;
        // if(tempLen >= list?.length || props?.mode === ADMIN_FORM_MODES.view || props?.formikProps?.values?.profileSetting?.length >= 4){
        if(tempLen >= 4 || NFlag || (props?.mode === ADMIN_FORM_MODES.view) ){
            return true;
        }else{
            return false;
        }
    }

    function getAccessNoteValue(type){
        if(type === 'dataUpload'){
            return 'Note: User can only upload data in Campaigns';
        }else if(type === 'viewAccess'){
            return 'Note: User Can only view data';
        }else if(type === 'editAccess'){
            return 'Note: User Can edit data';
        }else if(type === 'fullAccess'){
            return 'Note: User will have full control of the system';
        }else{
            return '';
        }
    }

    return(
        <>
            <FieldArray name="profileSetting">
                {(userProfileArray) =>
                    <Grid container display='flex' direction={'row'} >
                        <Grid item xs={12}>
                        {/* <Grid item xs={12} sx={{ width: '100%', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#FBFCFC', borderRadius: '8px 8px 0px 0px ', borderLeft: '1px solid #D6D6D7', borderTop: '1px solid #D6D6D7',  borderRight: '1px solid #D6D6D7'}}> */}
                            <Typography
                                sx={{
                                    fontStyle: 'Roboto',
                                    fontWeight: 400,
                                    fontSize: '18px',
                                    color: '#212121',
                                }} flexGrow={1}
                            >
                                Configure Access <div style={formTitleStyle}>Configure the required access to sub user profile</div>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {props?.formikProps?.values?.profileSetting?.length > 0 && props?.formikProps?.values?.profileSetting?.map((userConfig, index) => (
                                <div key={index}>
                                    {/* <div style={{ width: '100%', paddingLeft: '10px',marginTop: '-5px', paddingRight: '10px', backgroundColor: '#FBFCFC', borderRadius: '0px 0px 8px 8px',  borderLeft: '1px solid #D6D6D7', borderBottom: '1px solid #D6D6D7',  borderRight: '1px solid #D6D6D7' }}></div> */}
                                    <div style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#FBFCFC', borderRadius: '8px',  border: '1px solid #D6D6D7' }}>
                                        {/* <Grid container justifyContent="flex-end">
                                            <Grid item xs={3} sx={{ textAlign: 'right' }}>
                                                <Button
                                                    disabled={(props?.formikProps?.values?.profileSetting?.length > 1 && index > 0) ? false : handleAddButton()}
                                                    name={(props?.formikProps?.values?.profileSetting?.length > 1 && index > 0) ? 'removeButton' : 'AddButton'}
                                                    sx={(props?.formikProps?.values?.profileSetting?.length > 1 && index > 0) ? removeButtonStyle : buttonStyle}
                                                    startIcon={(props?.formikProps?.values?.profileSetting?.length > 1 && index > 0) ? (<img src={`${process.env.PUBLIC_URL}/icons/remove.svg`} alt='remove2'></img>) : <AddBoxOutlinedIcon />}
                                                    onClick={() => {
                                                        if (props?.formikProps?.values?.profileSetting?.length > 1 && index > 0) {
                                                            userProfileArray.remove(index)
                                                        }
                                                        else {
                                                            if (props?.formikProps?.values?.profileSetting?.length < listItems?.length) {
                                                                userProfileArray.push({
                                                                    "configutaions": [],
                                                                    "access": ""
                                                                })
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {(props?.formikProps?.values?.profileSetting?.length > 1 && index > 0) ? '' : 'Add'}
                                                </Button>
                                            </Grid>
                                        </Grid> */}

                                        {/* <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                                            <Divider variant="fullWidth" sx={{ borderColor: "#B2BBC6", marginTop: '8px', borderStyle: 'dashed' }} />
                                        </span> */}
                                        <div style={{display: 'flex', marginBottom: '10px'}}>
                                            <Grid container xs={12} spacing={1} direction={'row'} sx={{marginTop: '15px'}} flexGrow={1}>
                                                <Grid item xs={4}>
                                                    <CustomAutoComplete
                                                        disabled={props?.mode === ADMIN_FORM_MODES.view}
                                                        mode='Add'
                                                        limitTags={2}
                                                        name={`profileSetting.${index}.configutaions`}
                                                        labelHeader='Lists *'
                                                        placeholder={"Please Select"}
                                                        // Datas={listItem?.filter(item => item.menu === 'Configurations')}
                                                        Datas={props?.list}
                                                        optionLabelName='name'
                                                        optionId='id'
                                                        showCheckBox={true}
                                                        multiple={true}
                                                        showselectall={true}
                                                        popupIcon={null}
                                                    />
                                                </Grid>
                                                <Grid item xs={5} sx={{marginTop: '2px'}}>
                                                    <div>Access *</div>
                                                    <Select
                                                        disabled={props?.mode === ADMIN_FORM_MODES.view}
                                                        sx={{ border: '#D6D6D7', borderRadius: '8px', height: '44px', minWidth: '300px' }}
                                                        value={props?.formikProps?.values?.profileSetting[index]?.access || ''}
                                                        name={`userConfig.${index}.access`}
                                                        onChange={(e) => {handleChange(e, props?.formikProps?.values, index, userProfileArray)}}
                                                        displayEmpty
                                                        placeholder="Please Select"
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <MenuItem value='viewAccess'>View Access<span style={selectMenuStyle}></span></MenuItem>
                                                        <MenuItem value='editAccess'>Edit Access<span style={selectMenuStyle}></span></MenuItem>
                                                        <MenuItem value='fullAccess'>Full Access<span style={selectMenuStyle}></span></MenuItem>
                                                        <MenuItem value='dataUpload'>Data Upload access<span style={selectMenuStyle}></span></MenuItem>

                                                    </Select>
                                                    <div style={{fontSize: '12px', opacity: 0.4, color: '#212121'}}>{getAccessNoteValue(props?.formikProps?.values?.profileSetting[index]?.access || '')}</div>
                                                </Grid>
                                                <Grid item xs={2} sx={{marginTop: '20px'}}>
                                                    <Button
                                                        disabled={(props?.formikProps?.values?.profileSetting?.length > 1 && index > 0 && (props?.mode === ADMIN_FORM_MODES.add || props?.mode === ADMIN_FORM_MODES.edit)) ? false : handleAddButton(props?.list)}
                                                        name={(props?.formikProps?.values?.profileSetting?.length > 1 && index > 0) ? 'removeButton' : 'AddButton'}
                                                        sx={(props?.formikProps?.values?.profileSetting?.length > 1 && index > 0) ? removeButtonStyle : buttonStyle}
                                                        startIcon={(props?.formikProps?.values?.profileSetting?.length > 1 && index > 0) ? (<img src={`${process.env.PUBLIC_URL}/icons/remove.svg`} alt='remove2'></img>) : <AddBoxOutlinedIcon />}
                                                        onClick={() => {
                                                            if (props?.formikProps?.values?.profileSetting?.length > 1 && index > 0) {
                                                                userProfileArray.remove(index)
                                                            }
                                                            else {
                                                                // if (props?.formikProps?.values?.profileSetting?.length < listItem?.filter(item => item?.menu === 'Configurations')?.length) {
                                                                if (props?.formikProps?.values?.profileSetting?.length < 4) {
                                                                    userProfileArray.push({
                                                                        "configutaions": [],
                                                                        "access": ""
                                                                    })
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {(props?.formikProps?.values?.profileSetting?.length > 1 && index > 0) ? '' : 'Add'}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Grid>
                    </Grid>
                
                }

            </FieldArray>
        </>
    )
}