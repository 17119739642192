import React, { useState, useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import IconButton from "@mui/material/IconButton"
import "./ozTbAudioPlayer.css"

export default function OzTbAudioPlayer({ audio }) {
    const containerRef = useRef();
    const waveSurferRef = useRef({
        isPlaying: () => false,
    });
    const [isPlaying, toggleIsPlaying] = useState(false);
    const [currentAudioTime, setCurrentAudioTime] = useState("00:00")

    useEffect(() => {
        try {
            const waveSurfer = WaveSurfer.create({
                container: containerRef.current,
                waveColor: '#FFFFFF4D',
                barWidth: 1,
                fillParent: true,
                barMinHeight: 1,
                cursorColor: "transparent",
                backend: 'WebAudio',
                progressColor: '#FFFFFF',
                responsive: true,
                backgroundColor: "#3D8BF8",
                cursorWidth: 0,
                height: 32,
                barGap: 2,
            });
            function setAudioDurationTime() {
                if (waveSurfer.isPlaying()) {
                    const seconds = Number(waveSurfer.getCurrentTime().toFixed(1));
                    if (Number.isInteger(seconds)) {
                        const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, "0");
                        const remainingSeconds = (seconds % 60).toString().padStart(2, "0");
                        setCurrentAudioTime(`${minutes}:${remainingSeconds}`)
                    }
                }
            }
            waveSurfer.load(audio);
            waveSurfer.on('ready', () => {
                waveSurferRef.current = waveSurfer;
            });
            waveSurfer.on('audioprocess', function () {
                setAudioDurationTime();
            });
            waveSurfer.on('interaction', () => {
                setAudioDurationTime();
            });
            waveSurfer.on('finish', () => {
                toggleIsPlaying(waveSurferRef.current.isPlaying());
            });
            return () => {
                waveSurfer.destroy();
            };
        } catch (error) {
            console.error(error);
        }
    }, [audio]);

    return (
        <div className='oz-tb-audio-player-container' >
            <IconButton
                onClick={() => {
                    waveSurferRef.current.playPause();
                    toggleIsPlaying(waveSurferRef.current.isPlaying());
                }}
                className='oz-tb-audio-player-audio-buttons'
            >
                {isPlaying ? <img src={`${process.env.PUBLIC_URL}/icons/pauseaudio.svg`} alt='pause' ></img> : <img src={`${process.env.PUBLIC_URL}/icons/resumeaudio.svg`} alt='play' ></img>}
            </IconButton>

            <div className='oz-tb-audio-player-waveform-parent-container' >
                <div id='waveform' ref={containerRef} />
            </div>

            <label className='ob-tb-audio-player-current-time'>{currentAudioTime}</label>

        </div >
    );
};
