import React, { useState, useMemo } from 'react';
import { Menu, TextField, Typography, Divider, List, ListItem } from '@mui/material';
import './multiSelect.css'; // Your custom styles

const SkillComponentMultiSelect = ({
  loggedInAgentsByGroup,
  setSelectedAgents,
  selectedAgents,
  setOptions,
  options,
  searchOptions,
  handleSearch,
  searchTerm,
  mode
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleItem = (groupId, itemId) => {
    const updatedOptions = options?.map(group => ({
      ...group,
      items: group?.items?.map(item =>
        item?.id === itemId ? { ...item, checked: !item?.checked } : item
      ),
    }));
    setOptions(updatedOptions);
  };

    // Updated toggleGroup to handle "Everyone" selection
    const toggleGroup = (groupName) => {
      if (groupName === "Everyone") {
        const allChecked = options?.every(group => group?.items?.every(item => item?.checked));
        const updatedOptions = options?.map(group => ({
          ...group,
          items: group?.items?.map(item => ({ ...item, checked: !allChecked }))
        }));
        setOptions(updatedOptions);
      } else {
        const updatedOptions = options?.map(group => ({
          ...group,
          items: group?.groupName === groupName 
            ? group?.items?.map(item => ({ ...item, checked: !group?.items?.every(i => i.checked) }))
            : group?.items
        }));
        setOptions(updatedOptions);
      }
    };

  const isEveryOneSelected = useMemo(() => 
    options?.every(group => group?.items?.every(item => item?.checked)), 
    [options]
  );

  const renderSelectedAgents = () => {
    if (isEveryOneSelected) {
      return (
        <div style={{ display: "flex", alignItems: "center", marginRight: '8px' }}>
          <Typography color={"#008DFF"} fontSize={"14px"} fontWeight={400}>Everyone</Typography>
        </div>
      );
    } else if (selectedAgents.to && selectedAgents.to.length > 0) {
      return (
        <div
          className="small-scroll"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            width: mode === "Create" ? '850px' : '470px',
            overflowX: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            marginRight: "8px",
          }}
        >
          <Typography
            color={"#008DFF"}
            fontSize={"14px"}
            fontWeight={400}
          >
            {selectedAgents.to[0]}
          </Typography>
  
          {selectedAgents.to.length > 1 && (
            <>
              <Typography
                color={"#008DFF"}
                fontSize={"12px"}
                fontWeight={400}
              >
                ..
              </Typography>
              <div
                style={{
                  backgroundColor: "#008DFF",
                  borderRadius: "12px",
                  padding: "0px 8px",
                  color: "#FFFFFF",
                  fontSize: "12px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                +{selectedAgents.to.length - 1}
              </div>
            </>
          )}
        </div>
      );
    }
  
    return null;
  };  

  return (
    <div>
      <TextField
        fullWidth
        onClick={handleClick}
        placeholder={isEveryOneSelected ? "Everyone" : (selectedAgents.to.length > 0 ? "" : "Search")}
        value={isEveryOneSelected ? "" : ""}
        InputProps={{
          startAdornment: isEveryOneSelected ?
            <Typography color={"#008DFF"} fontSize={"14px"} fontWeight={400}>Everyone</Typography> :
            (selectedAgents.to && selectedAgents.to.length > 0) && renderSelectedAgents(),
        }}
      />

      {/* Menu for multi-select dropdown */}
      <Menu
        id="multi-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '40vh',
            width: '250px',
          },
        }}
      >
        <div className="multi-select-container">
          <div className="multi-select-custom-checkbox">
            <input
              id="everyone"
              type="checkbox"
              onChange={() => toggleGroup("Everyone")}
              checked={options.every(group => group.items.every(item => item.checked))}
            />
            <label htmlFor="everyone" style={{fontSize: '14px', color: '#212121', fontWeight: 600}}>Everyone</label>
          </div>

          {/* List of Groups with Agents */}
          {searchOptions?.map(group => (
            <List key={group?.groupName}>
              <ListItem>
                <input
                  type="checkbox"
                  id={group?.groupName}
                  onChange={() => toggleGroup(group?.groupName)}
                  checked={group?.items?.every(item => item?.checked)}
                />
                <label htmlFor={group?.groupName} style={{fontSize: '14px', color: '#212121', fontWeight: 600}}>{group?.groupName}</label>
              </ListItem>

              {group?.items?.map(item => (
                <ListItem key={item?.id} style={{ paddingLeft: '40px' }}>
                  <input
                    id={item?.id + group?.groupName}
                    type="checkbox"
                    checked={item?.checked}
                    onChange={() => toggleItem(group?.groupName, item?.id)}
                  />
                  <label htmlFor={item.id + group.groupName} style={{fontSize: '12px'}}>{item?.label}</label>
                </ListItem>
              ))}

              <Divider className='ozcw-multi-divider' />
            </List>
          ))}
        </div>
      </Menu>
    </div>
  );
};

export default SkillComponentMultiSelect;
