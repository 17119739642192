import React, { useMemo, useState, useEffect } from 'react';
import { Button, Stack, Typography, Grid, Tooltip, ListItemButton } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import { deletePostCallwork, getAddCallWork } from "../../../services/api-service";
import "./AddCallWork.css";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import Menu from "@mui/material/Menu";
import { showErrorNotification, showSuccessNotification, } from '../../../components/admin/common/NotiService';
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import AddCallWorkForm from "./AddCallWorkForm.jsx";
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import PopupState from 'material-ui-popup-state';

function AddCallWork() {

    const DateFormatter = (params) => {
        if (params.props) {
            const date = new Date(params.props);
            const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            return date.toLocaleString("en-GB", options);
        }
        const date = new Date(params.value);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return date.toLocaleString("en-GB", options);
    }

    const GetNameRenderer = (props) => {
        return (
            <Tooltip title={props?.value}>
                <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{props.value}</span>
            </Tooltip>
        )
    }

    const vertIconMenuStyle = useMemo(
        () => ({
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "12px",
            },
        }),
        []
    );

    const ReasonRenderer = (props) => {
        const [statusAnchorEl, setStatusAnchorEl] = React.useState(null);
        const anchorRef = React.useRef();
        const [deleteMode, setDeleteMode] = useState(false);
        const [copyForm, setCopyForm] = useState(false);

        const onMorevertIconClick = () => {
            setTimeout(() => setStatusAnchorEl(anchorRef?.current), 1);
        };

        const onMenuClose = () => {
            setStatusAnchorEl(null);
        };

        const onClickDeleteDialogue = (type, data) => {
            if (type === "Post") {
                let tempData = structuredClone(data);
                let newData = {
                    currentFlowName: tempData?.flowName,
                    ...tempData
                }
                let tempDelete = 1;
                newData.delete = tempDelete;
                deleteCallFlowByType(newData);
            }
            setDeleteMode(false);
        }

        const openMenuPopButton = (e, buttonType) => {
            setStatusAnchorEl(null);
            if (buttonType === 'copy') {
                setCopyForm(true);
            }
            else if (buttonType === 'delete') {
                setDeleteMode(true);
            }
        }

        const closeMenuPopButton = (e, buttonType) => {
            if (buttonType === 'copy') {
                setCopyForm(false);
            }
            else if (buttonType === 'delete') {
                setDeleteMode(false);
            }
        }

        return (
            <div className='ozAdminAddCallWorkContainer'>
                {// (sessionStorage.getItem('settings_PRE_POST_CALL_FLOW') === true || sessionStorage.getItem('settings_PRE_POST_CALL_FLOW') === 'true') 
                    (newAuthPages(NewAdminPages.PREPOST_PAGE) || newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'Full')) &&
                    <img className="addCallWorkMorevertIcon"
                        ref={anchorRef}
                        onClick={onMorevertIconClick}
                        src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
                    >
                    </img>
                }
                <div ref={anchorRef}>
                    <Menu anchorEl={statusAnchorEl} open={Boolean(statusAnchorEl)} onClose={onMenuClose} sx={{
                        "& .MuiPaper-root": {
                            backgroundColor: "#FFFFFF",
                            width: "133px",
                        },
                        "& .MuiMenu-paper": {
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                            border: "0.0625rem solid #E6E5E6",
                            borderRadius: "12px",
                            marginLeft: "15rem",
                            marginTop: "-0.5rem",
                        },
                    }}>
                        <PopupState variant="popover" popupId="demo-popup-menu-copy-in" >
                            {(popupState) => (
                                <React.Fragment>
                                    {/* <ListItemButton sx={{ ...vertIconMenuStyle }}
                                        onClick={(e) => openMenuPopButton(e, 'copy')}
                                    >
                                        Make a copy
                                    </ListItemButton> */}
                                    <ListItemButton sx={{ ...vertIconMenuStyle, marginTop: '8px' }}
                                        onClick={(e) => openMenuPopButton(e, 'delete')}
                                        disabled={newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'data')}
                                    >
                                        Delete
                                    </ListItemButton>

                                </React.Fragment>
                            )}
                        </PopupState>
                    </Menu>

                    {deleteMode &&
                        <DeleteDialog
                            title="Delete Call Flow"
                            content={'This will delete this Call Flow permanently. You cannot undo this action.'}
                            onClose={(e) => closeMenuPopButton(e, 'delete')}
                            open={true}
                            deleteFunction={() => onClickDeleteDialogue(props?.data?.actionType, props?.data)}
                        />
                    }
                </div>
            </div>
        );
    };

    const [addCallWorkForm, setAddCallWorkForm] = useState({ isNew: false, show: false });
    const [clickItem, setClickItem] = useState([]);
    const [gridRef, setGridRef] = useState(null);
    const [addCallWorkList, setAddCallWorkList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAddCallWorkList()
    }, []);

    const columnDefs = [
        {
            field: "flowName",
            headerName: "Call Work Name",
            cellRenderer: GetNameRenderer,
        },
        {
            field: 'updateDateTime', headerName: 'Created On', cellRenderer: DateFormatter,
            enableRowGroup: false
        },
        {
            field: "",
            headerName: "",
            cellRenderer: ReasonRenderer,
        },
    ];

    async function deleteCallFlowByType(data) {
        setLoading(true);
        try {
            deletePostCallwork(data).then(resp => {
                if (resp.Status === 'Success') {
                    showSuccessNotification(resp.Message);
                    getAddCallWorkList();
                    setLoading(false);
                } else {
                    showErrorNotification(resp.Message);
                    setLoading(false);
                }
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setLoading(false);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setLoading(false);
        }
    }

    async function getAddCallWorkList() {
        setAddCallWorkList([]);
        setLoading(true);
        try {
            getAddCallWork().then((resp) => {
                if (resp?.Status === "Fail") {
                    setAddCallWorkList([]);
                    showErrorNotification(resp?.Message);
                } else {
                    let tempData = structuredClone(resp?.Data);
                    tempData?.map((item) => {
                        item.campaignIds = JSON.parse(item?.campaignIds);
                        item.flowConfigs = JSON.parse(item?.flowConfigs);
                        return null;
                    })
                    setAddCallWorkList(tempData);
                }
                setLoading(false);
            }).catch((e) => {
                setAddCallWorkList([]);
                setLoading(false);
            });
        } catch (e) {
            setLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    };
    let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400 }), []);

    const showAddCallWorkForm = () => {
        setAddCallWorkForm({ isNew: true, show: true });
    };

    const closeAddCallWorkForm = () => {
        setAddCallWorkForm({ isNew: false, show: false });
    };

    function onClickRow(rowItem) {
        setClickItem({});
        setLoading(true);
        try {
            if (rowItem?.data) {
                let data = {
                    currentFlowName: rowItem?.data?.flowName,
                    ...rowItem.data
                }
                setClickItem(data);
                setLoading(false);
            } else {
                setClickItem({});
                setLoading(false);
            }
        } catch (e) {
            setClickItem({});
            showErrorNotification("Error in list");
            setLoading(false);
        }
        setAddCallWorkForm({ isNew: false, show: true });
    };

    return (
        <div>
            {((!addCallWorkForm.show && !addCallWorkForm.isNew)) &&
                <div className="ozAdminAddCallWorkContainer">
                    <Grid sx={{ marginBottom: "10px" }}>
                        <Typography className="oz-page-header"> Configurations </Typography>
                    </Grid>
                    <Stack direction="row" sx={{ marginBottom: "16px" }} justifyContent="space-between">
                        <Typography className="oz-page-heading"> Pre & Post Call Work </Typography>
                        {// (sessionStorage.getItem('settings_PRE_POST_CALL_FLOW') === true || sessionStorage.getItem('settings_PRE_POST_CALL_FLOW') === 'true')
                            // authPage(AdminPages.PREPOST_EDIT) &&
                            (newAuthPages(NewAdminPages.PREPOST_PAGE) || newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'Full')) &&
                            <Button
                                sx={buttonStyle}
                                variant="contained"
                                startIcon={<AddBoxOutlinedIcon />}
                                onClick={showAddCallWorkForm}
                            >
                                Add Call Work
                            </Button>
                        }
                    </Stack>
                    <OZGridToolbar
                        title='List of Pre and Post call Work'
                        searchPlaceholder='Search by name'
                        rowCount={addCallWorkList?.length}
                        dataGridRef={gridRef}
                        fileName='AddCallWork'
                    />
                    {loading ?
                        (
                            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                                <LoadingScreen />
                            </div>
                        ) :
                        (
                            <OZDataGrid
                                rowClickCallback={onClickRow}
                                height="26.3rem"
                                data={addCallWorkList}
                                columns={columnDefs}
                                setGridRef={setGridRef}
                            />
                        )
                    }
                </div >
            }
            {(addCallWorkForm.show && addCallWorkForm.isNew) && <AddCallWorkForm onClose={closeAddCallWorkForm} updatePostCallWorkForm={getAddCallWorkList} />}
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                (
                    <>
                        {(addCallWorkForm.show && !addCallWorkForm.isNew) && <AddCallWorkForm onClose={closeAddCallWorkForm} data={clickItem} updatePostCallWorkForm={getAddCallWorkList} />}
                    </>
                )
            }
        </div>
    )
};

export default AddCallWork;