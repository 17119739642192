import { createTheme } from '@mui/material/styles';

const primary = { main: '#3D8BF8' };
const secondary = { main: '#ffffff', contrast: '#526ee7' };
// const primaryLight = '#e2e8ff';
const darkShade1 = '#2d3a48';
const success = '#47be8a';
const error = '#E56B6B';
const warning = '#f6a609';

const theme = createTheme({
	palette: {
		primary: {
			main: primary.main
		},
		secondary: {
			main: secondary.main,
			contrastText: secondary.contrast
		},
		success: {
			main: success
		},
		error: {
			main: error
		},
		warning: {
			main: warning
		},
		contrastThreshold: 3,
		tonalOffset: 0.2
	},
	typography: {
		allVariants: {
			color: darkShade1
		},
		button: {
			fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
			fontWeight: 500,
			fontSize: '13px',
			lineHeight: 1.75,
			letterSpacing: '0.2px',
			textTransform: 'capitalize'
		}
	},
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true,
				color: 'primary',
				disableRipple: true
			}
		}
	}
});

export default theme;
