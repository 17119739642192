import * as React from 'react';
import { Button } from '@mui/material';
import '../Login/Login.css';
import { Link } from 'react-router-dom';

function ForgotPassword() {
	return (
		<>
			<div className="login_container">
				<div className="login-left">
					<img src={`${process.env.PUBLIC_URL}/img/ozonetel-logo.svg`} alt="Ozonetel"></img>
					<div className="login-title">Modernize your Call Center Software today!</div>
					<div className="login-sub-title">Enterprise-grade contact center suite built for fast-growing businesses</div>
				</div>
				<div className="login-right">
					<div className="forget-password-bx">
						<div className="login-form-title text-center positionRelative">
							<Link to="/login" className="prev">
								<img src={`${process.env.PUBLIC_URL}/icons/back.svg`} alt="Ozonetel"></img> Back
							</Link>{' '}
							Forgot Password
						</div>
						<img src={`${process.env.PUBLIC_URL}/icons/forget-pwd.svg`} alt="Ozonetel"></img>
						<p>
							Please confirm your mobile number
							<br />
							<span>calvin.james@ozonetel.com</span>
						</p>
						<div className="otp-bx">
							<input type="text" />
							<input type="text" />
							<input type="text" />
							<input type="text" />
						</div>

						<Button className="loginBtn">Submit</Button>
						<span className="know-cred">
							Resend OTP in <Link to="/forgot-password">01:37</Link>
						</span>
					</div>
				</div>
			</div>
		</>
	);
}

export default ForgotPassword;
