import { Card, MenuItem, Select, Theme, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import '../InternalApiUtilization/InternalApiUtilization.css'
import { getApiUtilizationData } from "../../../services/api-service";
import { WIDGET_REFRESH_INTERVAL } from "../../../utils/constants";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { SystemDateFormat } from "../../../utils/properties";

let interval: any;

const useStyles: any = makeStyles((theme: Theme) => ({
    boundButton: {
        padding: '4px !important'
    },
    filterItem: {
        fontSize: 12,
        height: 'auto',
        lineHeight: '14px',
        borderRadius: 20,
        background: 'rgba(0, 142, 255, 0.1)',
        border: '1px solid #008eff',
        padding: `4px ${theme.spacing(2)}`
    },
    itemIndicator: {
        width: 5,
        height: 5,
        borderRadius: 1,
        display: 'inline-block',
        position: 'relative',
        top: '-2px',
        marginRight: 4
    },
    filterItemText: {
        margin: 0,
        color: '#536580'
    },
    filterItemSubText: {
        color: '#99A0A8'
    },
    chipClose: {
        display: 'flex'
    },
    popoverContent: {
        minWidth: 370,
        fontSize: '12px',
        lineHeight: 2
    }
}));

function InternalApiUtilization() {
    const [graphData, setGraphData] = useState<any>([]);

    const values = [
        { key: "SummaryReport", value: "/CAServices/AgentState/SummaryReport.php", id: "1" },
    { key: "StateChange", value: "/CAServices/AgentState/StateChange.php", id: "1" },
    { key: "FwpSyncInsert", value: "/CAServices/FwpSync/FwpSyncInsert.php", id: "1" },
    { key: "hubspotLogActivity", value: "/CAServices/hubspotLogActivity.php", id: "1" },
    { key: "getCDRData", value: "/CAServices/getCDRData.php", id: "1" },
    { key: "CDRDump_pagination", value: "/CAServices/CDR/CDRDump_pagination.php", id: "1" },
    { key: "AgentDetails", value: "/CAServices/AgentDetails/AgentDetails.php", id: "1" },
    { key: "IVR", value: "/CAServices/FeedBackIVR/IVR.php", id: "1" },
    { key: "CDRDump", value: "/CAServices/CDR/CDRDump.php", id: "1" },
    { key: "scheduleCampaignData", value: "/CAServices/scheduleCampaignData/scheduleCampaignData.php", id: "1" },
    { key: "PhoneManage", value: "/CAServices/PhoneManage.php", id: "1" },
    { key: "RunningDIDs", value: "/CAServices/DID/RunningDIDs.php", id: "1" },
    { key: "CDR CallBacks", value: "/CAServices/CDR/CallBacks.php", id: "1" },
    { key: "DeleteCampaignData", value: "/CAServices/Campaign/DeleteCampaignData.php", id: "1" },
    { key: "AgentManualDial", value: "/CAServices/AgentManualDial.php", id: "1" },
    { key: "SkillWiseQueueData", value: "/CAServices/GraphicalDashBoard/SkillWiseQueueData.php", id: "1" },
    { key: "DeleteCampData", value: "/CAServices/DeleteCampData.php", id: "1" },
    { key: "hubspotScreenpop", value: "/CAServices/hubspotScreenpop.php", id: "1" },
    { key: "AgentAuthenticationV2", value: "/CAServices/AgentAuthenticationV2/index.php", id: "1" },
    { key: "Campaign Script", value: "/CAServices/Campaign/Script.php", id: "1" },
    { key: "DispositionAPIV3", value: "CAServices/Disposition/DispositionAPIV3.php", id: "1" },
    { key: "hubspotScreenpopV2", value: "/CAServices/hubspotScreenpopV2.php", id: "1" },
    { key: "PullAPI", value: "/CAServices/LeadSquare/PullAPI.php", id: "1" },
    { key: "FeedBackIVR Historical", value: "/CAServices/FeedBackIVR/Historical.php/format/json", id: "1" },
    { key: "deleteScheduleData", value: "/CAServices/scheduleCampaignData/deleteScheduleData.php", id: "1" },
    { key: "DispositionAPI", value: "/CAServices/Disposition/DispositionAPI.php", id: "1" },
    { key: "scheduleDataStatus", value: "/CAServices/scheduleCampaignData/scheduleDataStatus.php", id: "1" },
    { key: "PhoneManageV2", value: "/CAServices/PhoneManageV2.php", id: "1" },
    { key: "GetSentimentalAnalysisV3", value: "/CAServices/LCR/GetSentimentalAnalysisV3.php", id: "1" },
    { key: "DispositionAPIV2", value: "/CAServices/Disposition/DispositionAPIV2.php", id: "1" },
    { key: "PhoneManualDial", value: "/CAServices/PhoneManualDial.php", id: "1" },
    { key: "ReleaseFwpNumber", value: "/CAServices/ReleaseFwpNumber.php", id: "1" },
    { key: "GraphicalDashBoard", value: "/CAServices/GraphicalDashBoard/Data.php", id: "1" },
    { key: "CustomerInfo", value: "/CAServices/CustomerInfo/CustomerInfo.php", id: "1" },
    { key: "CA_PhoneManageV2", value: "/CAServices/CA_PhoneManage/PhoneManageV2.php", id: "1" },
    { key: "PhoneManualDial", value: "//CAServices/PhoneManualDial.php", id: "1" },
    { key: "fetchCdrByUCID", value: "/CAServices/CDR/fetchCdrByUCID.php", id: "1" },
    { key: "GetLiveCallAnalysis", value: "/CAServices/LCR/GetLiveCallAnalysis.php", id: "1" },
    { key: "getActiveAgents", value: "/CAServices/AgentDetails/getActiveAgents.php", id: "1" },
    { key: "obdevents2", value: "r=obdevents2", id: "2" },
    { key: "cloudagent", value: "r=cloudagent", id: "2" },
    { key: "credentials", value: "r=credentials", id: "2" },
    { key: "kupload", value: "r=kupload", id: "2" },
    { key: "accountPorts", value: "r=accountPorts", id: "2" },
    { key: "getAudios", value: "r=getAudios", id: "2" },
    { key: "kreport", value: "r=kreport", id: "2" },
    { key: "kalert", value: "r=kalert", id: "2" },
    { key: "kookoo", value: "r=kookoo", id: "2" },
    { key: "tts", value: "r=tts", id: "2" },
    { key: "ttsapi", value: "r=ttsapi", id: "2" },
    { key: "checkpoints", value: "r=checkpoints", id: "2" },
    { key: "playqueue", value: "r=playqueue", id: "2" },
    { key: "phonelibv3", value: "r=phonelibv3", id: "2" },
    { key: "getPhoneInfo", value: "r=getPhoneInfo", id: "2" },
    { key: "DROP_HOLD", value: "action=DROP_HOLD", id: "3" },
    { key: "HOLD", value: "action=HOLD", id: "3" },
    { key: "MUTE", value: "action=MUTE", id: "3" },
    { key: "KICK", value: "action=KICK", id: "3" },
    { key: "TRANSFER", value: "action=TRANSFER", id: "3" },
    { key: "UNHOLD", value: "action=UNHOLD", id: "3" },
    { key: "DROP", value: "action=DROP", id: "3" },
    { key: "CONFERENCE", value: "action=CONFERENCE", id: "3" }
    ]
    const [selectedApi, setSelectedApi] = useState<String>(values[0].key);
    const [reportType, setReportType] = useState<string>('status');

    const chartElement: any = useRef(null);
    const classes = useStyles();

    const options = {
        chart: {
            type: 'column',
            height: 300,
        },
        credits: { enabled: false },
        title: { text: '' },
        xAxis: {
            title: {
                text: reportType === 'status' ? 'Status Code' : (reportType === 'count' ? 'Hours' : 'Total Count'),
                style: { fontWeight: 'bold' }
            },
            crosshair: true
        },
        yAxis: {
            title: {
                text: (reportType === 'status' || reportType === 'count') ? 'Total Count' : 'Count Per Secs',
                style: { fontWeight: 'bold' }
            },
            gridLineDashStyle: 'dash'
        },
        legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'top',
            itemMarginTop: 10
        },
        tooltip: {
            formatter(this: any): any {
                return `${this.y}`;
            }
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                borderRadiusTopLeft: '50%',
                borderRadiusTopRight: '50%',
                pointWidth: 10,
                borderRadius: 5,
            }
        },
        series: graphData || [{ x: 0, y: 0 }]
    };

    const getTypeGraphData = (data: any[]) => {
        if (!data || data.length === 0) {
            return [{name: (reportType === 'status' ? 'Status-wise Count' : 'Hour-wise Count'),x:0}];
        }
        let graphData: any;

        if (reportType === 'status' || reportType === 'count') {
            let hours: any = [];
            let count: any = [];
            if (reportType === 'status') {
                [hours, count] = data?.reduce((acc, item) => {
                    acc[0].push(item.status);
                    acc[1].push(item.totalCount);
                    return acc;
                }, [[], []]);
            } else {
                [hours, count] = data?.reduce((acc, item) => {
                    acc[0].push(item.hours);
                    acc[1].push(item.totalCount);
                    return acc;
                }, [[], []]);
            }
            graphData = [{
                name: reportType === 'status' ? 'Status-wise Count' : 'Hour-wise Count',
                data: hours.map((x: any, i: any) => [x, count[i]])
            }];
        } else {
            const [hours, count1, count2, count3, count4, count5, count6] = data.reduce((acc, item) => {
                acc[0].push(item.hours);
                acc[1].push(item.count0to1Sec);
                acc[2].push(item.count1to2Sec);
                acc[3].push(item.count2to3Sec);
                acc[4].push(item.count3to4Sec);
                acc[5].push(item.count4to5Sec);
                acc[6].push(item.countGreaterThan5);
                return acc;
            }, [[], [], [], [], [], [], []]);

            const names = ['0 to 1 Sec', '1 to 2 Sec', '2 to 3 Sec', '3 to 4 Sec', '4 to 5 Sec', 'Greater Than 5'];
            const counts = [count1, count2, count3, count4, count5, count6];
            graphData = names.map((name, index) => ({
                name,
                data: hours.map((x: any, i: any) => [x, counts[index][i]])
            }));
        }
        return graphData;
    }

    const handleReportTypeChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        setReportType(newAlignment);
        setGraphData([]);
        chartElement?.current?.chart?.redraw();
    };

    const retriveApiGraphData = async () => {
        const apiName = values?.find(item => item.key === selectedApi)?.value || '';
        const apiTrakingId = values?.find(item => item.key === selectedApi)?.id || '';
        const date = moment(new Date()).format(SystemDateFormat);
        const apiResponse = await getApiUtilizationData({ fromTime: `${date} 00:00:00`, toTime: `${date} 23:59:59`, apiName: apiName, reportType: reportType, apiTrakingId: apiTrakingId });

        const graphData = getTypeGraphData(apiResponse?.reports);
        setGraphData(graphData);

        chartElement?.current?.chart?.redraw();
    };

    React.useEffect(() => {
        const resizeListener = () => {
            chartElement?.current?.chart?.reflow();
        };
        window.addEventListener('widgetResize', resizeListener);
        window.addEventListener('resize', resizeListener);
        return () => {
            clearInterval(interval);
            window.removeEventListener('widgetResize', resizeListener);
        };
    }, []);

    useEffect(() => {
        retriveApiGraphData();
        if (interval) {
            clearInterval(interval);
        }
        interval = setInterval(() => retriveApiGraphData(), WIDGET_REFRESH_INTERVAL.QUEUE_COUNTER);
    }, [selectedApi, reportType])

    React.useEffect(() => {
        retriveApiGraphData();
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            <Card className="customCard">
                <div className="card-header" style={{ display: 'flex', marginBottom: '16px', borderBottom: '1px solid #e6e5e6' }}>
                    <div className="card-title">
                        API Utilization
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Select
                            sx={{
                                height: '35px',
                                maxWidth: '250px !important',
                                border: '1px solid #D6D6D7',
                                borderRadius: '8px',
                                fontSize: '14px',
                                width: '190px',
                                marginRight: '8px'
                            }}
                            MenuProps={{
                                elevation: 0,
                                sx: { '& [aria-expanded="true"]': { backgroundColor: 'pink' } },
                                PaperProps: {
                                    sx: {
                                        marginTop: '-7px',
                                        borderRadius: '8px',
                                        maxHeight: '250px',
                                        border: '1px solid #E5E5E5'
                                    }
                                }
                            }}
                            id="api-select"
                            onChange={(event: any) => {
                                setSelectedApi(event.target.value);
                                setGraphData([]);
                                chartElement?.current?.chart?.redraw();
                            }}
                            value={selectedApi}
                        >
                            {values?.map((option: any, index: number) => {
                                if (typeof option === "object")
                                    return <MenuItem value={option['key']} key={index}>
                                        {option['key']}
                                    </MenuItem >
                                else if (typeof option === "string")
                                    return <MenuItem value={option} key={index}>
                                        {option}
                                    </MenuItem >
                                else {
                                    return <MenuItem value={option} key={index}>
                                        {option}
                                    </MenuItem >
                                }
                            })}
                        </Select>
                        <ToggleButtonGroup value={reportType} color="primary" onChange={handleReportTypeChange} exclusive aria-label="bound">
                            <ToggleButton value="status" className={classes.boundButton} aria-label="status">
                                <span>Status</span>
                            </ToggleButton>
                            <ToggleButton value="count" className={classes.boundButton} aria-label="count">
                                <span>Count</span>
                            </ToggleButton>
                            <ToggleButton value="processing" className={classes.boundButton} aria-label="processing">
                                <span>Processing</span>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <HighchartsReact ref={chartElement} highcharts={Highcharts} options={options} />
            </Card>
        </>
    );
}

export default InternalApiUtilization;