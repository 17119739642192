import React, { useState, useEffect } from "react";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import { enableIntegration, getAvailableIntegration } from "../../../services/api-service";
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import { useNavigate } from 'react-router-dom';

export default function OAuthIntegration() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState();

    async function enableIntegrations(name, scope, clientId, clientSecret, redirectUrl) {

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const loc = urlParams.get('location');

        let data = {
            authCode: code || null,
            scope: scope || null,
            clientId: clientId || null,
            clientSecret: clientSecret || null,
            location: (loc !== undefined && loc !== null && loc !== "null") ? loc : "in",
            redirectUrl: redirectUrl || null
        }
        try {
            if (data.authCode !== null && data.scope !== null && data.clientId !== null && data.clientSecret !== null && data.location !== null && data.redirectUrl !== null) {
                enableIntegration(data, name)
                    .then((resp) => {
                        if (resp.Status === "Success") {
                            setLoading(false);
                            showSuccessNotification(`${name} Integration Enabled Successfully`);
                            navigate('/admin/integrations')
                        }
                        else {
                            setLoading(false);
                            showErrorNotification(`Error in Enabling ${name} Integration`);
                            navigate('/admin/integrations')
                        }
                    })
                    .catch((e) => {
                        setLoading(false);
                        showErrorNotification(`Error in Enabling ${name} Integration`);
                        navigate('/admin/integrations')
                    });
            }
            else {
                setLoading(false);
                showErrorNotification(`Error in fetching body parameters of ${name} Integration `);
                navigate('/admin/integrations')
            }
        } catch (e) {
            setLoading(false);
            showErrorNotification(`Error in ${name} Integration Enabling`);
            navigate('/admin/integrations')
        }
    };

    async function getIntegrationsData() {
        setLoading(true);
        try {
            getAvailableIntegration()
                .then((resp) => {
                    if (resp.Status === "Success") {
                        const urlParams = new URLSearchParams(window.location.search);
                        const state = urlParams.get('state');
                        let scope = null;
                        let clientId = null;
                        let clientSecret = null;
                        let redirectUrl = null;
                        resp.Data.map((item) => {
                            if (item.name === "Hubspot") {
                                scope = item.scope;
                                clientId = item.clientId;
                                clientSecret = item.clientSecret;
                                redirectUrl = item.redirectUrl;
                            }
                            else if (item.name === state) {
                                scope = item.scope;
                                clientId = item.clientId;
                                clientSecret = item.clientSecret;
                                redirectUrl = item.redirectUrl;
                            }
                        })
                        enableIntegrations(state, scope, clientId, clientSecret, redirectUrl);
                    }
                    else {
                        setLoading(false);
                        showWarningNotification(resp.Message);
                        navigate('/admin/integrations')
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    showErrorNotification("Error in integrations list");
                    navigate('/admin/integrations')
                });
        } catch (e) {
            setLoading(false);
            showErrorNotification("Error in integrations list");
            navigate('/admin/integrations')
        }
    };

    useEffect(() => {
        getIntegrationsData()
    }, [])

    return (
        <div className="ozAdminIntegrationCardContainer">
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                <></>
            }
        </div>
    )
}