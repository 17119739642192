import React from "react";
import { FormHelperText, FormControl, } from "@mui/material";
import { connect, getIn } from "formik";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete'
import Popper from "@mui/material/Popper";
import "../../../../pages/admin/admin.css";


const DropDownIcon = () => {
    return (<img style={{ marginTop: '7px', paddingRight: "8px" }}
        src={`${process.env.PUBLIC_URL}/icons/downArrowDropDown.svg`}
        alt="edit"
    ></img>)
};

const Autocompletes = connect(
    ({
        Datas,
        PlaceHolder,
        formik,
        width,
        name,
        multiple,
        disabled,
        ...additionalprops
    }) => {
        const PopperMy = function (props) {
            return (
                <Popper {...props} sx={{
                    paddingTop: "8px", paddingBottom: "8px", color: "#212121",

                    "& .MuiAutocomplete-listbox": {

                        "& .MuiAutocomplete-option[aria-selected='true']": {
                            backgroundColor: "transparent !important",
                        }
                    },
                }} />
            );
        };

        const values = getIn(formik.values, name);
        const selected = values || null;
        const error = getIn(formik.errors, name);
        const touch = getIn(formik.touched, name);

        return (
            <Autocomplete
                autoComplete={true}
                sx={{
                    "& .css-1nmr82h-MuiInputBase-root-MuiOutlinedInput-root": {
                        borderRadius: "8px",
                        minHeight: "44px",
                        fontSize: "14px",
                        borderColor: "#E6E5E6"
                    },
                    '& 	.MuiAutocomplete-listbox': {
                        scrollbarColor: 'rgb(190, 190, 190) rgb(240, 240, 240)',
                        scrollbarWidth: 'thin'
                    },
                    "& .MuiAutocomplete-popupIndicatorOpen": { transform: "none", marginRight: "0px", padding: "0px", },
                }}
                className="ozcw-scrollbar"
                clearIcon={""}
                multiple={false}
                size="small"
                id={name}
                options={Datas}
                popupIcon={<DropDownIcon />}
                value={selected}
                disabled={disabled}
                onChange={(e, value) => {
                    formik.setFieldValue(name, value);
                }}

                getOptionLabel={
                    ((option) => option.name)
                }

                noOptionsText="No matching result found"

                PopperComponent={PopperMy}
                isOptionEqualToValue={(option, value) => option.name === value.name}

                ListboxProps={{
                    sx: {
                        maxHeight: "170px",
                    }
                }}

                renderOption={(props, option,) => (

                    <li {...props}>
                        {
                            <span style={{ marginLeft: "4px", color: "#212121", fontWeight: 400, fontSize: "14px", lineHeight: "16.41px", }}>{option.name}</span>
                        }
                    </li>
                )}
                renderInput={(params) => (
                    <>
                        <FormControl component="fieldset"
                            error={touch & Boolean(error) ? true : false}
                            fullWidth
                        >
                            <TextField {...params} placeholder={PlaceHolder} />
                            <FormHelperText>{touch & Boolean(error) ? error : null}</FormHelperText>
                        </FormControl>
                    </>
                )
                }
            />
        );
    }
);

export default Autocompletes;