import React, { useState, useMemo, useEffect } from "react";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { Button, Stack, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./SipNumber.css";
import { importBulkSipNumbers, getSipNumbers, getSipNumberById, deleteSipNumberById } from "../../../services/api-service";
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import SipNumberForm from "./SipNumberForm";
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";

const SipNumber = () => {

    const AudiourlRenderer = (props) => {
        const [sipAnchorEl, setSipAnchorEl] = useState(null);
        const anchorRef = React.useRef();
        const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);

        const onClickDelete = () => {
            setSipAnchorEl(null);
            setDeleteConfirmationDialog(true);
        };

        const onMorevertIconClick = () => {
            setTimeout(() => setSipAnchorEl(anchorRef?.current), 1);
        };

        const onMenuClose = () => {
            setSipAnchorEl(null);
        };

        const onClickDeleteDialogClose = () => {
            setDeleteConfirmationDialog(false);
        };

        const onClickDeleteDialogDelete = () => {
            deletesip(props?.data?.id);
            setDeleteConfirmationDialog(false);
        };

        const styleListItemButton = useMemo(
            () => ({
                "&.MuiButtonBase-root": {
                    color: '#212121',
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                    height: '16px',
                    lineHeight: "14px",
                },
                '&:hover': {
                    borderRadius: "4px",
                },
            }),
            []
        );

        return (
            <div className="oz-sip-noOfSkill-container">
                <label>{props?.value}</label>
                {(newAuthPages(NewAdminPages.SIP_NUMBER) || newAuthSubAdminAccess(NewAdminPages.SIP_NUMBER, 'Full')) && (
                    <img className="sipMorevertIcon"
                        onClick={onMorevertIconClick}
                        src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
                    >
                    </img>
                )}
                <div ref={anchorRef}>
                    <Menu anchorEl={sipAnchorEl} open={Boolean(sipAnchorEl)} onClose={onMenuClose} sx={{
                        maxHeight: "70vh",
                        "& .MuiPaper-root": {
                            backgroundColor: "white",
                            minWidth: "20px",
                        },
                        "& .MuiMenu-paper": {
                            backgroundColor: "white",
                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                            border: "0.0625rem solid #E6E5E6",
                            borderRadius: "12px",
                            marginLeft: '-0.3625rem',
                        },
                    }}>
                        <MenuItem onClick={onClickDelete} sx={{ ...styleListItemButton }}>Delete </MenuItem>
                    </Menu>
                    {openDeleteConfirmationDialog &&
                        <DeleteDialog title={"Delete Sip Number"} content={" This will delete this " + props?.data?.sipNumber + " sip Number permanently. You cannot undo this action."} onClose={onClickDeleteDialogClose}
                            open={true} deleteFunction={onClickDeleteDialogDelete} />
                    }
                </div>
            </div>
        );
    };

    const [sipNumberForm, setSipNumberForm] = useState({ isNew: false, show: false });
    const [sipNumberList, setSipNumberList] = useState([]);
    const [gridRef, setGridRef] = useState(null);
    const [onRowclickSipData, setOnRowclickSipData] = useState({});
    const [loading, setLoading] = useState(false)

    const showSipNumberForm = () => {
        setSipNumberForm({ isNew: true, show: true });
    };

    const closeSipNumberForm = () => {
        setSipNumberForm({ isNew: false, show: false });
        getSipNumberList();
    };

    let buttonStyle = useMemo(
        () => ({
            borderRadius: "8px",
            height: "30px",
            fontSize: "14px",
            fontWeight: 400,
            width: "149px",
            backgroundcolor: "#3D8BF8",
            lineHeight: "16.41px",
        }),
        []
    );

    useEffect(() => {
        getSipNumberList();
        localStorage.removeItem('sipNumberData');
    }, []);

    const GetNameRenderer = (props) => {
        return (
            <span style={{ color: '#3D8BF8', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{props?.value}</span>
        )
    }

    async function getSipNumberList() {
        localStorage.removeItem('sipNumberData');
        setLoading(true);
        try {
            getSipNumbers().then((resp) => {
                if (resp) {
                    setSipNumberList(resp?.Data);
                }
                setLoading(false);
            }).catch((e) => {
                setSipNumberList([]);
                setLoading(false);
            });
        } catch (e) {
            showErrorNotification("Error in sip Number list");
            setLoading(false);
        }
    };

    async function deletesip(dataId) {
        setLoading(true);
        try {
            deleteSipNumberById(dataId).then((resp) => {
                if (resp.Status === "Success") {
                    getSipNumberList();
                    showSuccessNotification(resp.Message);
                    setLoading(false);
                }
                else {
                    showErrorNotification(resp.Message);
                    setLoading(false);
                }
            }).catch((e) => {
                showErrorNotification("Error deleting sip Number");
                setLoading(false);
            });
        } catch (e) {
            showErrorNotification("Error in sip Number delete");
            setLoading(false);
        }
    };

    function onClickSampleDownload() {
        const link = document.createElement("a");
        link.download = `SipNum_upload_sample.xls`;
        link.href = `${process.env.PUBLIC_URL}/sample_files/SipNum_upload_sample.xls`;
        link.click();
    };

    const columnDefs = [
        {
            field: "sipNumber",
            headerName: "Sip Number",
            cellRenderer: GetNameRenderer,
            maxWidth: 350,
        },
        { field: "sipLocation.sipUrl", headerName: "Sip URL", cellRenderer: AudiourlRenderer },
    ];

    function onCLickRow(rowItem) {
        setLoading(true);
        setOnRowclickSipData({});
        setSipNumberForm({ isNew: false, show: true });
        try {
            getSipNumberById(rowItem.data.id).then((resp) => {
                if (resp) {
                    setOnRowclickSipData(resp.Data[0]);
                }
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                setOnRowclickSipData({});
            });
        } catch (e) {
            showErrorNotification("Error in sip Number list");
            setLoading(false);
        }
    };

    return (
        <div className="ozAdminSipNumberContainer">
            <Grid sx={{ marginBottom: "10px" }}>
                <Typography className="oz-page-header"> Configurations </Typography>
            </Grid>
            <Stack direction="row" sx={{ marginBottom: "16px" }} justifyContent="space-between">
                <Typography className="oz-page-heading">Sip Numbers</Typography>
                {(newAuthPages(NewAdminPages.SIP_NUMBER) || newAuthSubAdminAccess(NewAdminPages.SIP_NUMBER, 'Full')) && (
                    <Button
                        sx={buttonStyle}
                        variant="contained"
                        startIcon={<AddBoxOutlinedIcon />}
                        onClick={showSipNumberForm}
                    >
                        Add SIP
                    </Button>
                )}
            </Stack>
            {sipNumberForm.show && sipNumberForm.isNew && <SipNumberForm updateName={getSipNumberList} onClose={closeSipNumberForm} />}
            <OZGridToolbar
                title='List of Sip Numbers'
                searchPlaceholder='Search'
                showImport={(newAuthPages(NewAdminPages.SIP_NUMBER) || newAuthSubAdminAccess(NewAdminPages.SIP_NUMBER, 'Full') || newAuthSubAdminAccess(NewAdminPages.SIP_NUMBER, 'data'))}
                importProps={{
                    label: "Bulk Import Numbers", importFunction: importBulkSipNumbers, fileName: "SipNumber.xls", onClickSampleDownload: onClickSampleDownload
                }}
                rowCount={sipNumberList?.length}
                dataGridRef={gridRef}
                fileName='SipNumber'
                refreshGrid={getSipNumberList}
            />
            {loading ?
                (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                        <LoadingScreen />
                    </div>
                ) :
                (<>
                    <OZDataGrid
                        rowClickCallback={onCLickRow}
                        height="26.3rem"
                        importProps={{ label: "Bulk Import Numbers", importFunction: importBulkSipNumbers, fileName: "SipNumber.xls" }}
                        data={sipNumberList}
                        columns={columnDefs}
                        setGridRef={setGridRef}
                    />

                    {sipNumberForm.show && !sipNumberForm.isNew && (
                        <SipNumberForm openDialog={true} updateName={getSipNumberList} onClose={closeSipNumberForm} data={onRowclickSipData} editAccess={(newAuthPages(NewAdminPages.SIP_NUMBER) || newAuthSubAdminAccess(NewAdminPages.SIP_NUMBER, 'Full') || newAuthSubAdminAccess(NewAdminPages.SIP_NUMBER, 'Edit'))}/>
                    )}
                </>
                )
            }
        </div >
    );
};

export default SipNumber;