import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Button, IconButton, Card, CardContent, Typography, CardActions, Stack, useMediaQuery } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './carousel.css';

function CarouselPreview(data: any) {
    // console.log("here data is caoural ", data);
    const [index, setIndex] = useState(0);
    const isSmallDisplay = useMediaQuery('(max-width:700px)');
    const [chatWidth, setChatWidth] = useState(290);
    const containerDivRef = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (containerDivRef.current) {
            let width = containerDivRef.current.offsetWidth;
            if (isSmallDisplay) width = (windowWidth * 82) / 100;
            else width = 550;
            setChatWidth(width);
        }
    }, [isSmallDisplay, windowWidth]);

    const cardWidth = useMemo(() => {
        const width = Math.floor((240 / 350) * chatWidth)
        return width > 240 ? 240 : width
    }, [chatWidth]);

    const smallDisplayCardWidth = useMemo(() => (isSmallDisplay && cardWidth > 250) ? (cardWidth * 72) / 100 : cardWidth, [cardWidth, isSmallDisplay]);
    const cardItemsWidth = useMemo(() => isSmallDisplay ? smallDisplayCardWidth + 100 : chatWidth, [isSmallDisplay, smallDisplayCardWidth, chatWidth]);
    const margin = useMemo(() => (smallDisplayCardWidth > 300 ? 24 : smallDisplayCardWidth > 300 ? 20 : smallDisplayCardWidth > 200 ? 16 : 12), [smallDisplayCardWidth]);
    const cardWidthWithMargin = useMemo(() => smallDisplayCardWidth + (2 * margin), [smallDisplayCardWidth, margin]);
    const totalItems = useMemo(() => data?.data?.values?.length, [data?.data?.values?.length]);
    const flexWidth = useMemo(() => cardWidthWithMargin * totalItems, [cardWidthWithMargin, totalItems]);
    const imageDim = useMemo(() => Math.floor((150 / 230) * smallDisplayCardWidth), [smallDisplayCardWidth]);
    const btnFS = useMemo(() => ((0.6 * cardItemsWidth) / 350) + 'em', [cardItemsWidth]);
    const portionVisiblePer = 0.26 / 1.16;
    const landScapeImageMode = data?.data?.options?.length === 0;

    const calculateTransform = () => {
        const visiblePortionOfAdjacentCard = portionVisiblePer * cardWidthWithMargin;
        if (index === 0) {
            return `translateX(0px)`;
        } else if (index === totalItems - 1) {
            return `translateX(-${index * cardWidthWithMargin - visiblePortionOfAdjacentCard}px)`;
        } else {
            return `translateX(-${index * cardWidthWithMargin}px)`;
        }
    };

    const handleNext = () => index < totalItems - 1 && setIndex(index + 1);
    const handlePrev = () => index > 0 && setIndex(index - 1);

    function IconButtonRenderer({ type = 'left' }) {
        return totalItems > 1 && (
            <IconButton
                onClick={type === 'left' ? handlePrev : handleNext}
                disabled={type === 'left' ? index === 0 : (index === totalItems - 1)}
                color='primary'
                className={`ozcw-carousel-arrow ozcw-carousel-${type}-arrow`}
            >
                {type === 'left' ? <NavigateBeforeIcon fontSize="large" /> : <NavigateNextIcon fontSize='large' />}
            </IconButton>
        );
    }

    return (
        <>
            {chatWidth > 0 && (
                <>
                    <div className='ozcw-carousel-ref-container' ref={containerDivRef}></div>
                    <div className='ozcw-carousel-container' style={{ width: `${chatWidth}px` }}>
                        <IconButtonRenderer />
                        <div className='ozcw-carousel-container-cards' style={{ width: flexWidth }}>
                            <div className='ozcw-carousel-container-cards-transform-provider' style={{ transform: calculateTransform() }}>
                                {data?.data?.values?.map((item, cardIndex) => (
                                    <Card key={cardIndex} sx={{ width: cardWidth, margin: `0 ${margin}px` }} className='ozcw-carousel-container-card'>
                                        <CardContent className='ozcw-carousel-container-card-item-content' >
                                            <Stack alignItems='center'>
                                                <img
                                                    component="img"
                                                    src={item.image}
                                                    alt={item.title}
                                                    className='ozcw-carousel-image'
                                                    style={{
                                                        height: imageDim,
                                                        width: landScapeImageMode ? '100%' : '70%',
                                                    }}
                                                />
                                            </Stack>
                                        </CardContent>
                                        <Stack gap={0} alignItems='center' sx={{ userSelect: 'none' }}>
                                            <Typography sx={{ fontSize: '14px', fontWeight: '600' }} component="div">
                                                {item.title}
                                            </Typography>
                                            <Typography sx={{ fontSize: '12px' }} color="text.secondary">
                                                {item.description}
                                            </Typography>
                                        </Stack>
                                        {!landScapeImageMode && (
                                            <>
                                                <CardActions>
                                                    <Stack sx={{ width: '100%' }} gap={0.7}>
                                                        {data?.data?.options?.map((option, buttonIndex) => (
                                                            <Button
                                                                disabled={true}
                                                                size='small'
                                                                fullWidth
                                                                key={option.id}
                                                                sx={{
                                                                    fontSize: btnFS,
                                                                    color: buttonIndex % 2 === 0 ? 'white' : 'primary.main',
                                                                    backgroundColor: buttonIndex % 2 === 0 ? 'primary.main' : 'white',
                                                                }}
                                                                variant={buttonIndex % 2 === 0 ? "contained" : "outlined"}
                                                            // onClick={() => callback({ id: option.id, title: item.title && item.title.length !== 0 ? item.title : option.title })}
                                                            >
                                                                {option.title}
                                                            </Button>
                                                        ))}
                                                    </Stack>
                                                </CardActions>
                                            </>
                                        )}
                                    </Card>
                                ))}
                            </div>
                        </div>
                        <IconButtonRenderer type='right' />
                    </div>
                </>
            )}
        </>
    )
}

export default CarouselPreview;
