import React from 'react';
import { FormControl, MenuItem, Select, FormHelperText, Box, Chip, Typography, IconButton, RadioGroup, FormControlLabel, Radio, TextField, Button, Divider } from '@mui/material';
import { connect, getIn } from "formik";
import OZCheckbox from '../../OZCheckbox/OZCheckbox';

const OZSelect = connect(({ name, label, options, multiSelect, placeholder, disabled, showCheckbox, showChip, optionLabel, formik, leftradius, rightradius, small, prePost, retry }) => {
    const error = getIn(formik.errors, name);


    const values = getIn(formik.values, name);
    const [selectedOption, setSelectedOption] = React.useState(values);
    const selected = (values === 0) ? values : (values || (multiSelect ? [] : ''))
    const [isOpen, setIsOpen] = React.useState(false);


    const handleOptionChange = ({ target: { value } }) => {
        setSelectedOption(options.filter((item) => item.id === value)[0]);
        setIsOpen(true);
    }

    const handleApply = () => {
        formik.setFieldValue(name, selectedOption);
        setIsOpen(false);
    }

    const handleInputChange = ({ target: { value } }) => {
        setSelectedOption((prev) => ({ ...prev, value: value }))
        setIsOpen(true);
    }

    const getTouchedStatus = () => {
        let touched = getIn(formik.touched, name);
        if (touched) {
            if (multiSelect) {
                if (touched.length === 0) {
                    return true
                } else {
                    return touched
                }
            } else {
                return touched
            }

        } else {
            return touched
        }
    }

    const touch = getTouchedStatus()
    const customSelectedValue = (seletedList) => {
        if (optionLabel) {
            let optionselected = seletedList.map((item) => (
                item[optionLabel]
            ))
            return optionselected?.join(', ');
        }
        return seletedList?.join(', ');
    }

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const customRenderValue = (selected) => {
        const Placeholder = <Typography style={{ color: name === "category" || name === "isZip" ? '#536580' : 'black', opacity: '.4' }} aria-disabled>{placeholder}</Typography>
        if (multiSelect) {
            return selected?.length === 0 ? Placeholder : showChip ? <Box sx={{ display: "flex", overflow: "hidden", }}>
                {<div style={{ display: "flex" }}>
                    {selected.map((value, index) => (index < 2 ?
                        optionLabel ? <Chip sx={{ marginRight: '5px' }} key={value + index} label={value} /> : <Chip sx={{ marginRight: '5px' }} key={value} label={value} />
                        : ""
                    ))}
                    {selected.length > 2 && <Typography sx={{
                        display: "flex", alignSelf: "center", fontSize: "14px",
                        fontWeight: 400,
                        color: "#99A0A8",
                        paddingRight: '27px',
                    }}>{("... +" + (selected.length - 2))}</Typography>}
                </div>
                }
            </Box> : customSelectedValue(selected);
        }
        if (prePost) {
            let operator = '';
            if (selectedOption.id === 'Equal to') {
                operator = '=';
            } else if (selectedOption.id === 'Less than or equal to') {
                operator = '<=';
            } else if (selectedOption.id === 'Greater than or equal to') {
                operator = '>=';
            } else if (selectedOption.id === 'Less than') {
                operator = '<';
            } else if (selectedOption.id === 'Greater than') {
                operator = '>';
            }
            return (selectedOption.id === "" || selectedOption.id === undefined) ? Placeholder : <Box sx={{ display: "flex", overflow: "hidden", }}>
                {<div style={{ display: "flex" }}>
                    <Chip label={`${selectedOption.id} (${operator}) ${selectedOption.value || ''}`} />
                </div>
                }
            </Box>
        }
        return (selected === "" || selected === undefined) ? Placeholder : selected
    }

    const isChecked = (option) => {
        let isSelected = false;
        let values = getIn(formik.values, name);
        if (typeof values === 'undefined' || values.length === 0) {
            return isSelected
        }
        if (typeof option === 'object') {
            values.forEach(element => {
                if (element[optionLabel] === option[optionLabel]) {
                    isSelected = true;
                }
            });
        }
        else {
            isSelected = values.indexOf(option) > -1;
        }
        return isSelected;
    }

    const DropDownIcon = () => {
        return <IconButton sx={{
            marginRight: '10px', position: 'absolute !important', right: '0', pointerEvents: 'none !important'
        }}><img
            src={`${process.env.PUBLIC_URL}/icons/downArrowDropDown.svg`}
            alt="edit"
        ></img></IconButton>
    };

    const mapSelectedItemps = () => {
        try {
            let selected = (values === 0) ? values : (values || (multiSelect ? [] : ''));
            if (multiSelect) {
                if (selected.length > 0 && typeof selected[0] === 'object') {
                    let data = []
                    selected.forEach(item => {
                        data.push(item[optionLabel])
                    })
                    return data;
                } else {
                    return selected
                }
            } else {
                let value = null;
                if (typeof selected === 'object') {
                    value = selected[optionLabel]
                } else {
                    value = selected;
                }
                return value === null ? selected : value
            }
        }
        catch (e) {
            console.log('Error While mapping selected items', e);
        }
    }
    const onChangeSelection = (value) => {
        try {
            if (multiSelect) {
                if (options.length > 0 && typeof options[0] === 'object') {
                    let data = []
                    value.forEach(item => {
                        options.forEach(option => {
                            if (option[optionLabel] === item) {
                                data.push(option);
                            }
                        })
                    })
                    formik.setFieldValue(name, data);
                } else {
                    formik.setFieldValue(name, value);
                }
            } else {

                options.forEach(option => {
                    let optionType = typeof option;
                    if (optionType === 'object') {
                        if (option[optionLabel] === value) {
                            formik.setFieldValue(name, option);
                            return;
                        }
                    } else {
                        if (option === value) {
                            formik.setFieldValue(name, value);
                            return;
                        }
                    }
                });
            }
        } catch (e) {
            console.log('Error While changing selected items', e)
        }

    }
    return (
        <React.Fragment>
            <FormControl component="fieldset"
                error={touch & Boolean(error) ? true : false}
                fullWidth
            >
                {label && <Typography htmlFor={name}
                    fontSize='12px'
                    fontWeight={400}
                    lineHeight='14px'
                    marginBottom={'6px'}
                    marginLeft='1px'
                    color={disabled ? '#99A0A8' : (name === "category" || name === "isZip") ? '#47586E' : '#212121'}
                >
                    {label}
                </Typography>}
                <Select
                    sx={{
                        height: '44px',
                        maxWidth: !retry ? (rightradius ? '280px !important' : leftradius ? '170px !important' : small ? '180px !important' : null) : retry ? (rightradius ? '280px !important' : leftradius ? '200px !important' : small ? '180px !important' : null) : null, '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #D6D6D7',
                            // borderRadius: '8px',
                            borderRadius: (rightradius ? "0px 8px 8px 0px !important" : leftradius ? "8px 0px 0px 8px !important" : '8px'),
                            fontSize: '14px'
                        },
                        background: (name === "isZip") && "#ffff"
                    }}
                    open={isOpen}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    name={name}
                    disabled={disabled}
                    multiple={multiSelect || false}
                    id={name}
                    value={mapSelectedItemps()}
                    onChange={(event) => {
                        onChangeSelection(event.target.value)
                    }}
                    onBlur={formik.handleBlur}
                    displayEmpty
                    renderValue={(selected) => customRenderValue(selected)}
                    IconComponent={DropDownIcon}
                    MenuProps={{
                        elevation: 0,
                        sx: { '& [aria-expanded="true"]': { backgroundColor: 'pink' } },
                        PaperProps: {
                            sx: {
                                marginTop: '-7px',
                                borderRadius: '8px',
                                maxHeight: '250px',
                                border: '1px solid #E5E5E5'
                            }
                        }
                    }}
                >
                    {multiSelect && showCheckbox ? options.map((option, index) => {
                        if (typeof option === "object") {
                            return <MenuItem value={option[optionLabel]} label={option[optionLabel]} key={index} sx={{ '& .custom-checkbox': { marginBottom: '0px' } }}>
                                <OZCheckbox size='small' checked={isChecked(option)} />
                                {option[optionLabel]}
                            </MenuItem >
                        }
                        else if (typeof option === "string") {
                            return <MenuItem key={index} value={option} sx={{ '& .custom-checkbox': { marginBottom: '0px' } }}>
                                <OZCheckbox size='small' checked={isChecked(option)} />
                                {option}
                            </MenuItem>
                        }
                    }) : prePost ?
                        <CustomRadioGroup handleOptionChange={handleOptionChange} optionLabel={optionLabel} options={options} selectedOption={selectedOption} />
                        :
                        options?.map((option, index) => {
                            if (typeof option === "object")
                                return <MenuItem value={option[optionLabel]} label={option[optionLabel]} key={index}>
                                    {option[optionLabel]}
                                </MenuItem >
                            else if (typeof option === "string")
                                return <MenuItem value={option} label={option} key={index}>
                                    {option}
                                </MenuItem >
                            else {
                                return <MenuItem value={option} label={option} key={index}>
                                    {option}
                                </MenuItem >
                            }
                        })
                    }

                    {prePost &&
                        <div style={{ padding: '10px' }}>
                            <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                                <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginBottom: '14px' }} />
                            </span>
                            <p style={{ marginBottom: '10px' }}>Value <span style={{ fontSize: '12px', fontWeight: '400', color: '#99A0A8' }}>(In Sec)</span></p>
                            <TextField
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                        backgroundColor: '#ffff',
                                        fontSize: '14px',
                                        height: '40px',
                                        width: '120px',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #D6D6D7'
                                    },
                                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #D6D6D7'
                                    }
                                }}
                                type='number'
                                value={selectedOption.value}
                                onChange={handleInputChange}
                                placeholder="In Sec"
                                variant="outlined"
                            />
                            <Button
                                sx={{ marginTop: '60px', marginLeft: '50px', borderRadius: '8px', height: '30px', borderColor: '#5BA0FF', fontWeight: '400', width: '81px' }}
                                onClick={handleApply}
                                variant="outlined" color="primary">
                                Apply
                            </Button>
                        </div>
                    }
                </Select>
                <FormHelperText>{touch & Boolean(error) ? error : null}</FormHelperText>

            </FormControl>
        </React.Fragment>
    );
})

function CustomRadioGroup({ selectedOption, options, handleOptionChange, optionLabel }) {

    return <RadioGroup
        aria-label="option"
        name="option"
        value={selectedOption[optionLabel]}
        onChange={handleOptionChange}
    >
        {options?.map((option, index) => {
            return <FormControlLabel
                sx={{ pl: '10px' }}
                value={option[optionLabel]}
                control={<Radio size='small' />}
                label={option[optionLabel]}
            />
        })}
    </RadioGroup>
}

export default OZSelect;